import { IPayrollSettings } from "@wingspanhq/payments/dist/interfaces";

export const selectorCurrentYearFilingSettingsEnabled = (
  settings: IPayrollSettings,
  year: 2024 | 2023
) => {
  const filingSettings =
    settings.calculationSettings1099?.filingSettings?.[year as 2024];

  return filingSettings?.filingEnabled;
};
