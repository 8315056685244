import { useRouteMatch } from "react-router-dom";
import { RequestCorrectionFlow } from "./RequestCorrectionFlow";
import {
  TAX_DOCUMENTS_REQUEST_CORRECTION_PATH,
  useGoToTaxDocumentByIds,
  useGoToTaxDocumentsDashboard
} from "../../paths";

export const RouteRequestCorrection: React.FC = () => {
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;

  const goToTaxDocument = useGoToTaxDocumentByIds();
  const goToDashboard = useGoToTaxDocumentsDashboard();

  const goBack = () => {
    goToTaxDocument(taxFormId, submissionId);
  };

  return (
    <RequestCorrectionFlow
      basePath={TAX_DOCUMENTS_REQUEST_CORRECTION_PATH}
      onBack={goBack}
      onFinish={goToDashboard}
    />
  );
};
