import { useCancelCorrectionModal } from "../../../TaxCorrections/components/CorrectionFormActions";
import { useRouteMatch } from "react-router-dom";
import {
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH,
  useGoToTaxFormDetails
} from "../../paths";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";

export const useOnCancelNewCorrection = (noWarning?: boolean) => {
  const cancelCorrectionModal = useCancelCorrectionModal();
  const matchDetails = useRouteMatch<{
    taxFormId: string;
    submissionId: string;
  }>(TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH);
  const onBack = useGoBackOrToParent();
  const goToTaxFormDetails = useGoToTaxFormDetails();

  const onCancel = () => {
    if (matchDetails) {
      goToTaxFormDetails(
        matchDetails.params.taxFormId,
        matchDetails.params.submissionId
      );
    } else {
      onBack();
    }
  };

  return () => {
    if (noWarning) {
      onCancel();
      return;
    }

    cancelCorrectionModal.open({
      onCancel
    });
  };
};
