import { WSCard, WSList } from "@wingspanhq/fe-component-library";
import { RouteComponentProps } from "react-router-dom";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { WSQueries } from "../../../../../query/WSQuery";
import { FormEditRecipient } from "../../../components/FormEditRecipient";

import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";
import { useEffect, useState } from "react";
import { RecipientVerificationFailed } from "../../../components/RecipientVerificationFailed";
import { RecipientVerificationProgress } from "../../../components/RecipientVerificationProgress";
import { selectorPayerTaxFormDefaultStatus } from "../../../selectors/selectorPayerTaxFormDefaultStatus";
import { useModalUpdateStatus } from "../RouteTaxFormDetails/ModalUpdateStatus";
import { useModalCancelEdit } from "../../../components/ModalCancelEdit";
import { useTaxFormSubmissionParamsIds } from "../../../paths";
import { EditLayout } from "../../../components/EditLayout";
import { useGoBackOrToParent } from "../../../../../utils/goToParentRoute";
import { getTaxFormExtendedBySubmission } from "../../../utils/getTaxFormExtendedBySubmission";

export const RouteTaxFormEditRecipient: React.FC<RouteComponentProps> = () => {
  const { taxFormId, submissionId } = useTaxFormSubmissionParamsIds();
  const onFinish = useGoBackOrToParent();

  const queryPayerTaxForm = usePayerTaxForm(taxFormId);

  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [isVerificationFailed, setIsVerificationFailed] = useState(false);
  const cancelModal = useModalCancelEdit();
  const updateStatusModal = useModalUpdateStatus();

  const onVerificationFailed = () => {
    setVerificationInProgress(false);
    setIsVerificationFailed(true);
  };

  const onVerificationPending = () => {
    setVerificationInProgress(true);
  };

  useEffect(() => {
    if (verificationInProgress) {
      const interval = setInterval(async () => {
        const taxForm = await queryPayerTaxForm.refetch();
        if (taxForm) {
          const verificationStatus = selectorPayerTaxFormDefaultStatus(taxForm);

          if (verificationStatus === VerificationStatus.Verified) {
            onFinish();
          } else if (verificationStatus === VerificationStatus.Failed) {
            onVerificationFailed();
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [onFinish, queryPayerTaxForm, verificationInProgress]);

  return (
    <EditLayout title="Edit contractor details" onBack={onFinish}>
      <WSQueries
        queries={{
          queryPayerTaxForm
        }}
        onSuccess={({ queryPayerTaxForm }) => {
          const verificationStatus = selectorPayerTaxFormDefaultStatus(
            queryPayerTaxForm.data
          );

          if (verificationStatus === VerificationStatus.Failed) {
            onVerificationFailed();
          }
        }}
      >
        {({ queryPayerTaxFormData }) => {
          const taxForm = getTaxFormExtendedBySubmission(
            queryPayerTaxFormData,
            submissionId
          );

          return (
            <WSList gap="2XL">
              {verificationInProgress ? (
                <WSCard
                  divider
                  header="Verifying recipient taxpayer identification number"
                >
                  <RecipientVerificationProgress
                    ms={10000}
                    onComplete={onFinish}
                  />
                </WSCard>
              ) : (
                <>
                  {isVerificationFailed && (
                    <WSCard divider header="Verification failed">
                      <RecipientVerificationFailed taxForm={taxForm} />
                    </WSCard>
                  )}
                  <FormEditRecipient
                    key={`key-for-reset-${taxForm.updatedAt}`}
                    isEdit={true}
                    taxForm={taxForm}
                    onCancel={async () => {
                      const result = await cancelModal.open();
                      if (result) {
                        onFinish();
                      }
                    }}
                    onBack={async () => {
                      onFinish();
                    }}
                    onSuccess={async responseTaxForm => {
                      if (
                        responseTaxForm &&
                        responseTaxForm?.status !== taxForm.status
                      ) {
                        await updateStatusModal.open({
                          taxForm: responseTaxForm
                        });
                      }

                      const verificationStatus =
                        selectorPayerTaxFormDefaultStatus(responseTaxForm);

                      if (verificationStatus === VerificationStatus.Verified) {
                        onFinish();
                      } else if (
                        verificationStatus === VerificationStatus.Failed
                      ) {
                        onVerificationFailed();
                      } else {
                        onVerificationPending();
                      }
                    }}
                  />
                </>
              )}
            </WSList>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
