import React from "react";
import {
  toWSDateString,
  toWSMoneyString,
  WSGrid,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { DataItem } from "../../../shared/components/DataItem";
import { getYearDateRange } from "../utils/getYearDateRange";
import { getPaymentsLinkForTaxDocument } from "../utils/getPaymentsLinkForTaxDocument";
import { getEndDayByUTC, getStartDayByUTC } from "../../../utils/dates";
import { useHistory } from "react-router-dom";

type Props = {
  submission: ITaxFormSubmissionResponse;
  secureLink?: boolean;
};

export const MainTaxDocumentInfo: React.FC<Props> = ({
  submission,
  secureLink
}) => {
  const history = useHistory();
  const { clientId, year = 2024, startDate, endDate } = submission;

  return (
    <WSPanel>
      <WSGrid gutter="L">
        <WSGrid.Item span={{ s: "12", m: "6" }}>
          <WSText.Paragraph color="gray600" weight="medium">
            1099 NEC-Total
          </WSText.Paragraph>
          <WSText.Heading1 color="gray500" weight="book">
            {submission.data?.totalAmount
              ? toWSMoneyString(submission.data.totalAmount)
              : "$ --"}
          </WSText.Heading1>
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "12", m: "6" }}>
          <DataItem
            label={{
              text: "Date range",
              action: secureLink
                ? undefined
                : {
                    label: "View included payments",
                    onClick: () => {
                      const defaultRange = getYearDateRange(year);

                      history.push(
                        getPaymentsLinkForTaxDocument(
                          clientId,
                          submission.startDate || defaultRange.startDate,
                          submission.endDate || defaultRange.endDate
                        )
                      );
                    }
                  }
            }}
            value={
              startDate && endDate
                ? `${toWSDateString(
                    getStartDayByUTC(startDate),
                    "monthDate"
                  )} - ${toWSDateString(
                    getEndDayByUTC(endDate),
                    "monthDayYear"
                  )}`
                : undefined
            }
          />
        </WSGrid.Item>
      </WSGrid>
    </WSPanel>
  );
};
