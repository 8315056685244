import { WSDivider, WSElement, WSForm } from "@wingspanhq/fe-component-library";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import { AmountCorrectionForm } from "../../../TaxCorrections/components/AmountCorrectionForm";
import { TINOrNameCorrectionForm } from "../../../TaxCorrections/components/TINOrNameCorrectionForm";
import { AddressCorrectionForm } from "../../../TaxCorrections/components/AddressCorrectionForm";
import {
  CorrectionFormActions,
  useCancelCorrectionModal
} from "../../../TaxCorrections/components/CorrectionFormActions";
import { getFormValidationSchema } from "../../../TaxCorrections/utils/getFormValidationSchema";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import {
  getDefaultValuesForCorrectionsForm,
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import { PreventLeave } from "../../../../components/PreventLeave/PreventLeave";
import { getCorrectionType } from "../../../TaxCorrections/utils/getCorrectionType";
import { FormRequestCorrectionData } from "../../../TaxCorrections/types";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { useVoidOriginalTaxFormWarningModal } from "../../components/useVoidOriginalTaxFormWarningModal";
import { selectorNo1099CompanyStructure } from "../../../../shared/selectors/selectorNo1099CompanyStructure";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { useCurrentTaxDocumentsYear } from "../../utils/useCurrentTaxDocumentsYear";
import { useGoToTaxDocumentByIds } from "../../paths";
import { selectorPayeeTaxFormCountry } from "../../selectors/selectorPayeeTaxFormCountry";
import { SetupRequestCorrectionFlowProps } from "./RequestCorrectionFlow";
import { CorrectionFlowHeader } from "../../../TaxCorrections/components/CorrectionFlowHeader";

export const RouteAddCorrectionValues: React.FC<
  SetupRequestCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const history = useHistory<{
    isEdit: boolean;
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const currentYear = useCurrentTaxDocumentsYear();
  const { taxFormId, submissionId } = match.params;
  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const goToTaxDocument = useGoToTaxDocumentByIds();

  const isEdit = history.location.state?.isEdit;
  const corrections = history.location.state?.corrections ?? [];
  const correctionsFormData = history.location.state?.correctionsFormData;

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const cancelCorrectionModal = useCancelCorrectionModal();
  const voidOriginalTaxFormWarningModal = useVoidOriginalTaxFormWarningModal();

  const onCancel = () => {
    goToTaxDocument(taxFormId, submissionId);
  };

  const confirmCancel = () => {
    cancelCorrectionModal.open({
      onCancel
    });
  };

  return (
    <EditLayout title="Request correction" onBack={onBack}>
      <WSQueries queries={{ queryMember, queryTaxForm }}>
        {({ queryMemberData: member, queryTaxFormData: taxForm }) => {
          const onFormSubmit = (data: FormRequestCorrectionData) => {
            const isTaxFormIneligible = selectorNo1099CompanyStructure(
              data.taxClassification as CompanyStructure
            );

            const correctionsFormData = {
              ...data,
              ...(data.formW9Address
                ? {
                    formW9Address: {
                      ...data.formW9Address,
                      country: selectorPayeeTaxFormCountry(taxForm)
                    }
                  }
                : {})
            };

            if (isTaxFormIneligible) {
              voidOriginalTaxFormWarningModal.open({
                onContinue: () => {
                  onNext({
                    corrections,
                    correctionsFormData
                  });
                }
              });
            } else {
              onNext({
                corrections,
                correctionsFormData
              });
            }
          };

          const payerName = selectorPayeeTaxFormPayerName(taxForm);
          const originalTinData = getPayeeTINorName(taxForm);
          const country = selectorPayeeTaxFormCountry(taxForm);
          const correctionTypeToFormComponentMap: Record<string, JSX.Element> =
            {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionForm
                  taxForm={taxForm}
                  amount={taxForm.data?.totalAmount || 0}
                  payerName={payerName}
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionForm
                  taxForm={taxForm}
                  originalTinData={originalTinData}
                  identificationNumberType={
                    originalTinData?.ein ? TinType.Business : TinType.Individual
                  }
                  showRequestedValue={false}
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionForm
                  taxForm={taxForm}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                />
              )
            };
          return (
            <>
              <CorrectionFlowHeader
                year={currentYear}
                name={selectorPayeeTaxFormPayerName(taxForm)}
              />

              <WSForm
                defaultValues={
                  isEdit
                    ? correctionsFormData
                    : getDefaultValuesForCorrectionsForm(corrections, taxForm)
                }
                validationSchema={getFormValidationSchema(
                  corrections,
                  country,
                  member.profile.ssnProvided
                )}
                onSubmit={onFormSubmit}
              >
                {corrections.map((correctionType, index) => {
                  const FormComponent =
                    correctionTypeToFormComponentMap[correctionType];
                  const isLast = index === corrections.length - 1;
                  return (
                    <WSElement mb="2XL" key={correctionType}>
                      {FormComponent}
                    </WSElement>
                  );
                })}

                <WSDivider my="2XL" />

                <WSForm.Values
                  names={[
                    "necTotalAmount",
                    "amountCorrectionHelpText",
                    "identificationNumberType",
                    "ssn",
                    "ein",
                    "firstName",
                    "lastName",
                    "businessName",
                    "disregardedEntityName",
                    "taxClassification",
                    "reasonForChange",
                    "otherReason",
                    "formW9Address"
                  ]}
                >
                  {formData => {
                    const correctionTypeData = getCorrectionType(
                      taxForm,
                      formData
                    );

                    return (
                      <>
                        <PreventLeave
                          isEnabled={correctionTypeData.corrections.length > 0}
                        />

                        <CorrectionFormActions
                          mb="XL"
                          onCancel={onCancel}
                          disabled={correctionTypeData.corrections.length === 0}
                        />
                      </>
                    );
                  }}
                </WSForm.Values>
              </WSForm>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
