import { useHistory, useRouteMatch } from "react-router-dom";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import {
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { getCorrectionTypeFromCorrectionRequest } from "./getCorrectionTypeFromCorrectionRequest";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { UnchangedRecipientDetails } from "../../components/UnchangedRecipientDetails";
import {
  IPayerTaxFormResponse,
  ITaxFormCorrectionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { transformCorrectionRequestToFormData } from "./transformCorrectionRequestToFormData";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { usePayerForceSubmitTaxFormWarningModal } from "../../components/usePayerForceSubmitTaxFormWarningModal";
import { usePayerVoidOriginalTaxFormWarningModal } from "../../components/usePayerVoidOriginalTaxFormWarningModal";
import { useModalPayerRejectReason } from "../../../TaxDocumentsOld/RouteOldDashboard/components/useModalPayerRejectReason";
import { SetupRouteReviewCorrectionFlowProps } from "./RouteReviewCorrectionFlow";
import React from "react";
import { AmountCorrectionDiffPreview } from "../../../TaxCorrections/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../TaxCorrections/components/TINOrNameCorrectionDiffPreview";
import { AddressCorrectionDiffPreview } from "../../../TaxCorrections/components/AddressCorrectionDiffPreview";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";

export const RouteReviewCorrectionRequest: React.FC<
  SetupRouteReviewCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);
  const rejectionModal = useModalPayerRejectReason();
  const payerVoidOriginalTaxFormWarningModal =
    usePayerVoidOriginalTaxFormWarningModal();
  const payerForceSubmitTaxFormWarningModal =
    usePayerForceSubmitTaxFormWarningModal();

  const onContinue = (options = { isForce: 0 }) => {
    onNext({ isForce: options.isForce });
  };

  const rejectCorrectionRequest = () => {
    rejectionModal.open({
      taxFormId,
      submissionId,
      pendingCorrectionId: queryTaxForm.data?.pendingCorrectionId!
    });
  };

  const onCancel = onBack;

  const onEditUnchangedDetails = (corrections: string[]) => {
    onEdit({
      corrections,
      showRequestedValue: false
    });
  };

  const onAmountEdit = () => {
    onEdit({
      corrections: [CORRECTION_AMOUNT],
      showRequestedValue: true
    });
  };

  const onTINOrNameEdit = () => {
    onEdit({
      corrections: [CORRECTION_TIN_OR_NAME],
      showRequestedValue: true
    });
  };

  const onAddressEdit = () => {
    onEdit({
      corrections: [CORRECTION_ADDRESS],
      showRequestedValue: true
    });
  };

  const onEdit = ({
    corrections,
    showRequestedValue
  }: {
    corrections: string[];
    showRequestedValue: boolean;
  }) => {
    const taxForm = queryTaxForm.data as IPayerTaxFormResponse;
    const correctionRequest =
      taxForm?.pendingCorrection as ITaxFormCorrectionResponse;

    onNext({
      isEdit: true,
      backPath: history.location.pathname,
      corrections,
      showRequestedValue,
      correctionsFormData: transformCorrectionRequestToFormData(
        corrections,
        taxForm,
        correctionRequest
      )
    });
  };

  return (
    <EditLayout title="Review correction request">
      <WSElement>
        <WSText.Paragraph weight="medium" mb="XS">
          Review 1099-NEC correction request
        </WSText.Paragraph>
        <WSText.ParagraphSm color="gray500">
          Please review to ensure the information is accurate before proceeding
        </WSText.ParagraphSm>

        <WSDivider my="XL" />

        <WSQueries queries={{ queryTaxForm }}>
          {({ queryTaxFormData: taxForm }) => {
            const correctionRequest = taxForm?.pendingCorrection;
            if (!correctionRequest) {
              return (
                <WSMessageBox.Info
                  button={{
                    kind: "Link",
                    children: "Refresh",
                    onClick: () => {
                      window.location.reload();
                    }
                  }}
                >
                  Please refresh the web page if you can't see correction
                  details.
                </WSMessageBox.Info>
              );
            }
            const { corrections, isVoidType, isCompanyStructureChangeOnly } =
              getCorrectionTypeFromCorrectionRequest(taxForm);

            const hasUnchangedDetails =
              !corrections.includes(CORRECTION_AMOUNT) ||
              !corrections.includes(CORRECTION_TIN_OR_NAME) ||
              !corrections.includes(CORRECTION_ADDRESS);

            const correctionTypeToPreviewComponentMap: Record<
              string,
              JSX.Element
            > = {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  onEdit={onAmountEdit}
                  amount={taxForm?.data?.totalAmount ?? 0}
                  correctedAmount={correctionRequest.data?.totalAmount ?? 0}
                  comment={
                    correctionRequest.payerOwnedData?.comment ||
                    correctionRequest.payeeOwnedData?.comment ||
                    ""
                  }
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  onEdit={onTINOrNameEdit}
                  identificationNumberType={
                    correctionRequest.data?.w9Info?.ein ||
                    correctionRequest.data?.w9Info?.einLastFour
                      ? TinType.Business
                      : TinType.Individual
                  }
                  originalTinData={getPayeeTINorName(taxForm)}
                  correctedTinData={{
                    ssn: correctionRequest?.data?.w9Info?.ssn ?? "",
                    ein: correctionRequest?.data?.w9Info?.ein ?? "",
                    businessName:
                      correctionRequest?.data?.w9Info?.legalBusinessName ?? "",
                    firstName: correctionRequest?.data?.w9Info?.firstName,
                    lastName: correctionRequest?.data?.w9Info?.lastName,
                    disregardedEntityName:
                      correctionRequest?.data?.w9Info?.disregardedEntityName,
                    taxClassification: correctionRequest?.data?.w9Info
                      ?.companyStructure as CompanyStructure
                  }}
                  reasonForChange={
                    correctionRequest?.payeeOwnedData?.reason ?? ""
                  }
                  otherReason={correctionRequest?.payeeOwnedData?.reasonComment}
                  hasOtherEligibleCorrectionChanges={
                    !isCompanyStructureChangeOnly
                  }
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  onEdit={onAddressEdit}
                  originalAddress={getPayeeFormW9Address(taxForm) as any}
                  correctedAddress={{
                    addressLine1:
                      correctionRequest?.data?.w9Info?.addressLine1 ?? "",
                    addressLine2:
                      correctionRequest?.data?.w9Info?.addressLine2 ?? "",
                    city: correctionRequest?.data?.w9Info?.city ?? "",
                    state: correctionRequest?.data?.w9Info?.state ?? "",
                    postalCode:
                      correctionRequest?.data?.w9Info?.postalCode ?? "",
                    country: correctionRequest?.data?.w9Info?.country ?? ""
                  }}
                />
              )
            };
            return (
              <>
                {corrections.map(correctionType => {
                  const DiffPreviewComponent =
                    correctionTypeToPreviewComponentMap[correctionType];
                  return (
                    <WSElement mb="L" key={correctionType}>
                      {DiffPreviewComponent}
                      <WSDivider my="XL" />
                    </WSElement>
                  );
                })}

                {hasUnchangedDetails ? (
                  <>
                    <UnchangedRecipientDetails
                      onEdit={onEditUnchangedDetails}
                      taxForm={taxForm}
                      corrections={corrections}
                    />
                    <WSDivider my="XL" />
                  </>
                ) : null}

                {isVoidType ? (
                  <WSButtons forceFullWidth>
                    <WSButton.Primary
                      onClick={() =>
                        payerVoidOriginalTaxFormWarningModal.open({
                          onContinue: () => onContinue({ isForce: 0 })
                        })
                      }
                    >
                      Continue: void original filing
                    </WSButton.Primary>
                    {!isCompanyStructureChangeOnly ? (
                      <WSButton.Secondary
                        onClick={() =>
                          payerForceSubmitTaxFormWarningModal.open({
                            onContinue: () => onContinue({ isForce: 1 })
                          })
                        }
                      >
                        Continue: submit as a correction anyway
                      </WSButton.Secondary>
                    ) : null}
                    <WSButton destructive onClick={rejectCorrectionRequest}>
                      Reject request
                    </WSButton>
                    <WSButton.Tertiary onClick={onCancel}>
                      Cancel
                    </WSButton.Tertiary>
                  </WSButtons>
                ) : (
                  <WSButtons forceFullWidth>
                    <WSButton.Primary onClick={() => onContinue()}>
                      Continue to confirm
                    </WSButton.Primary>
                    <WSButton destructive onClick={rejectCorrectionRequest}>
                      Reject request
                    </WSButton>
                    <WSButton.Tertiary onClick={onCancel}>
                      Cancel
                    </WSButton.Tertiary>
                  </WSButtons>
                )}
              </>
            );
          }}
        </WSQueries>
      </WSElement>
    </EditLayout>
  );
};
