import {
  toWSDateString,
  toWSMoneyString,
  WSButton,
  WSElement,
  WSGrid,
  WSPage,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsMoment } from "@wingspanhq/utils/dist/date/wsMoment";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useInvoiceQuery } from "../../../query/payments/queries";
import { getPayrollRunTitle } from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import {
  PayablesStatusFilter,
  PayablesTable
} from "../../components/NewPayablesTable/PayablesTable";
import { PayablesSummary } from "../../components/PayablesSummary/PayablesSummary";
import { Summary } from "../../components/Summary/Summary";
import { calculateLineItemsTotal } from "../../utils";

export const PayrollRun: React.FC<
  RouteComponentProps<{
    payrollRunId: string;
  }>
> = ({ match }) => {
  useBrowserPageTitle("Payroll Runs");

  const payrollRunId = match.params.payrollRunId;
  const payrollRunQuery = useInvoiceQuery(payrollRunId);
  const history = useHistory();
  const [detailsVisible, setDetailsVisible] = useState(false);

  return (
    <WSQueries queries={{ payrollRunQuery }}>
      {({ payrollRunQuery: { data: payrollRun } }) => (
        <WSPage
          title={getPayrollRunTitle(payrollRun)}
          breadcrumb={{
            label: "Payroll runs",
            icon: "arrow-left",
            onClick: () =>
              history.push("/member/invoices/payables/payroll-runs")
          }}
          primaryButtonComponent={
            payrollRun.labels &&
            Object.keys(payrollRun.labels).find(
              key => key[0] === key[0].toUpperCase()
            )
              ? () => (
                  <WSButton.Link
                    onClick={() => {
                      setDetailsVisible(true);
                    }}
                  >
                    Show details
                  </WSButton.Link>
                )
              : undefined
          }
        >
          {detailsVisible && (
            <WSSidebar
              size="S"
              onClose={() => {
                setDetailsVisible(false);
              }}
            >
              <WSText.Heading5>Payroll run details</WSText.Heading5>
              {payrollRun.labels &&
                Object.keys(payrollRun.labels)
                  .filter(key => key[0] === key[0].toUpperCase())
                  .map(key => (
                    <WSElement mb="XL" key={key}>
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </WSText.ParagraphSm>
                      <WSText>
                        {(payrollRun.labels[key] as any) instanceof Date
                          ? toWSDateString(payrollRun.dueDate, "monthDayYear")
                          : payrollRun.labels[key]}
                      </WSText>
                    </WSElement>
                  ))}
            </WSSidebar>
          )}
          <WSElement mb="2XL">
            <WSGrid>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <Summary
                  label="Total amount"
                  value={toWSMoneyString(
                    calculateLineItemsTotal(payrollRun.lineItems)
                  )}
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <PayablesSummary
                  label="Pending disbursement"
                  filter={{
                    status: [
                      PayablesStatusFilter.ToApprove,
                      PayablesStatusFilter.Scheduled
                    ],
                    payrollRunId
                  }}
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <Summary
                  label="Total 1099s"
                  value={
                    payrollRun?.collaborators?.length ||
                    payrollRun?.labels.payableCount ||
                    0
                  }
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ xs: "6", m: "3" }}>
                <Summary
                  label="Expected pay date"
                  value={
                    payrollRun.events.estimatedDepositAt
                      ? toWSDateString(
                          wsMoment(payrollRun.events.estimatedDepositAt)
                            .nextBusinessDay()
                            .toDate(),
                          "monthDayYear"
                        )
                      : "–"
                  }
                />
              </WSGrid.Item>
            </WSGrid>
          </WSElement>

          <PayablesTable
            basePath={"/member/invoices/payables/payroll-runs/" + payrollRunId}
            visibleFilters={{
              payee: true,
              openedAtRange: true
            }}
            defaultFilters={
              payrollRunQuery.data?.collaborators &&
              payrollRunQuery.data?.collaborators.length > 0
                ? { payrollRunIdOldStack: payrollRunId }
                : {
                    payrollRunId
                  }
            }
            showHeaders
            showDatePaid
          />
        </WSPage>
      )}
    </WSQueries>
  );
};
