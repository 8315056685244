import React from "react";
import {
  WSContainer,
  WSElement,
  WSForm,
  WSGrid,
  WSInputText,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvitationAuthHeader } from "../../components/InvitationAuthHeader";
import * as Yup from "yup";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { useResendUserInvite } from "../../../../query/users/mutations/useResendUserInvite";
import { useHistory } from "react-router-dom";
import { REQUEST_INVITATION_USER_LINK_SUCCESS_PATH } from "./RouteRequestLinkIndex";

export const RouteRequestLink: React.FC = ({}) => {
  const history = useHistory();
  const [resendInvite] = useResendUserInvite();

  return (
    <WSElement>
      <InvitationAuthHeader />
      <WSContainer>
        <WSElement pt="3XL" pb="2XL">
          <WSGrid gutter="2XL">
            <WSGrid.Item span={{ s: "12", m: "2" }} />
            <WSGrid.Item span={{ s: "12", m: "8" }}>
              <WSList gap="2XL">
                <WSText.Heading1 weight="bold">
                  Request new onboarding link to sign up for Wingspan
                </WSText.Heading1>
                <WSText.ParagraphSm weight="book">
                  Enter your email below to receive a fresh link for setting up
                  your Wingspan account. <br /> <br />
                  Before You Request, Please Check:
                  <WSElement as="ul" ml="XL">
                    <WSElement as="li" my="S">
                      <b>Your Spam/Junk mail:</b> Look for an email from
                      Wingspan in your spam or junk folder.
                    </WSElement>
                    <WSElement as="li" mb="S">
                      <b>Email Address with your client:</b> Ensure the email
                      address registered with your client/inviter is up to date.
                      Check any alternate emails you might have used, or update
                      your email with your client/inviter.
                    </WSElement>
                    <WSElement as="li" mb="S">
                      <b>Correct and active email:</b> Make sure the email
                      linked to your account is correct and capable of receiving
                      emails.
                    </WSElement>
                  </WSElement>
                </WSText.ParagraphSm>
                <WSPanel noBorder colorBackground="gray50" p="L">
                  <WSText.Heading4 color="gray700">
                    Request a login link
                  </WSText.Heading4>
                  <WSForm
                    defaultValues={{
                      email: ""
                    }}
                    validationSchema={Yup.object().shape({
                      email: validatorEmail.required("Email is required")
                    })}
                    onSubmit={async values => {
                      await resendInvite(
                        { email: values.email },
                        {
                          onSuccess: () => {
                            history.replace(
                              REQUEST_INVITATION_USER_LINK_SUCCESS_PATH.replace(
                                ":email",
                                values.email
                              )
                            );
                          }
                        }
                      );
                    }}
                  >
                    <WSForm.Field
                      my="L"
                      name="email"
                      component={WSInputText}
                      componentProps={{
                        placeholder: "example@example.com",
                        type: "email",
                        autoComplete: "email"
                      }}
                    />
                    <WSForm.SubmitButton kind="Tertiary" size="S" type="submit">
                      Submit
                    </WSForm.SubmitButton>
                  </WSForm>
                </WSPanel>
                <WSText.ParagraphSm weight="book">
                  If you’ve tried to request an onboarding link, check your
                  inbox and spam, and still can’t find your invite, you’ll need
                  to reach out to your client/inviter for assistance.
                </WSText.ParagraphSm>
              </WSList>
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "12", m: "2" }} />
          </WSGrid>
        </WSElement>
      </WSContainer>
    </WSElement>
  );
};
