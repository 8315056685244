import {
  WSTable,
  WSTableCell,
  WSTableColumnSortDirection,
  WSTableProps,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  IPayableSchema,
  IPayrollSettings,
  IScheduleDate,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import React from "react";
import { getIsPayableDisputed } from "../../../query/payments/selectors";
import { IPayeeRow } from "../../../services/search";
import { calculateLineItemsTotal } from "../../utils";
import { getPayableLatestUpdate } from "../NewPayablesTable/getPayableLatestUpdate";
import { selectorPayableIcon } from "./selectorPayableIcon";

type TableItemData = {
  payeeRow?: IPayeeRow;
  payable: IPayableSchema;
  payrollRun?: IScheduleDate;
};

type Props = {
  payables: IPayableSchema[];
  payeeRows: IPayeeRow[];
  payrollSettings?: IPayrollSettings;
  showInvoiceNumber?: boolean;
  showDateRequested?: boolean;
  showDueDate?: boolean;
  showDatePaid?: boolean;
  showHeaders?: boolean;
  showLatestUpdate?: boolean;
  sortDirection?: WSTableColumnSortDirection;
  onSort?: (sortDirection: WSTableColumnSortDirection) => void;
  showRefundedLabel?: boolean;
} & Omit<WSTableProps<TableItemData>, "tableData">;

export const TablePayables: React.FC<Props> = ({
  payables,
  payeeRows,
  payrollSettings,
  showInvoiceNumber,
  showDateRequested,
  showDueDate,
  showDatePaid,
  showHeaders,
  showLatestUpdate,
  sortDirection,
  onSort,
  showRefundedLabel,
  ...tableProps
}) => {
  let data = [
    ...payables.map(payable => ({
      id: payable.payableId,
      mark:
        (payable.status === InvoiceStatus.Pending &&
          payable.client.workflowStatus === ClientWorkFlowStatus.Approved) ||
        getIsPayableDisputed(payable)
          ? ("amber400" as any)
          : undefined,
      data: {
        payable,
        payeeRow: payeeRows.find(row => row.payeeId === payable.memberId)
      }
    }))
  ];

  return (
    <WSTable<TableItemData>
      tableData={data}
      columns={[
        {
          config: {
            gridTemplateSizeMax: "2fr",
            header: showHeaders
              ? {
                  text: "Name"
                }
              : undefined
          },
          renderFunction: ({ data: { payable, payeeRow } }) => {
            if (!payeeRow || !payeeRow.user) {
              return null;
            }

            const name = wsName({
              user: payeeRow.user,
              member: payeeRow.member,
              payerOwnedData: payeeRow.payerOwnedData
            });

            return (
              <WSTableCell
                avatar={{ type: "icon", ...selectorPayableIcon(payable) }}
                text={name.getResolvedName()}
                secondaryText={name.getResolvedSecondaryName()}
              />
            );
          }
        },

        {
          config: {
            gridTemplateSizeMax: "1fr",
            justify: "start",
            hideOnScreens: showInvoiceNumber
              ? ["XS", "S"]
              : ["XS", "S", "M", "L", "XL"],
            header: showHeaders
              ? {
                  text: "Invoice number"
                }
              : undefined
          },
          renderFunction: ({ data: { payable } }) => (
            <WSTableCell text={payable?.invoiceNumber} />
          )
        },
        {
          config: {
            gridTemplateSizeMax: "1fr",
            hideOnScreens: showInvoiceNumber
              ? ["XS", "S"]
              : ["XS", "S", "M", "L", "XL"],
            header: showHeaders
              ? {
                  text: "Description"
                }
              : undefined
          },
          renderFunction: ({ data: { payable } }) => {
            return <WSTableCell text={payable.lineItems[0].description} />;
          }
        },
        {
          config: {
            gridTemplateSizeMax: "1fr",
            hideOnScreens: showDateRequested
              ? ["XS", "S"]
              : ["XS", "S", "M", "L", "XL"],
            header: showHeaders
              ? {
                  text: "Date requested"
                }
              : undefined
          },
          renderFunction: ({ data: { payable } }) =>
            payable?.status === InvoiceStatus.Pending ? (
              <WSTableCell text="Eligibility pending" />
            ) : (
              <WSTableCell
                text={toWSDateString(payable?.events.openedAt, "monthDayYear")}
              />
            )
        },
        {
          config: {
            gridTemplateSizeMax: "1fr",
            justify: "start",
            hideOnScreens: showDueDate
              ? ["XS", "S"]
              : ["XS", "S", "M", "L", "XL"],
            header: showHeaders
              ? {
                  text: "Pay date"
                }
              : undefined
          },
          renderFunction: ({ data: { payable } }) => (
            <WSTableCell
              text={toWSDateString(
                payable?.client.payDate || payable?.dueDate,
                "monthDayYear"
              )}
              secondaryText={payable?.client.payDate ? "Updated" : ""}
            />
          )
        },
        {
          config: {
            gridTemplateSizeMax: "1fr",
            hideOnScreens: showDatePaid
              ? ["XS", "S"]
              : ["XS", "S", "M", "L", "XL"],
            header: showHeaders
              ? {
                  text: "Date paid"
                }
              : undefined,
            sortDirection,
            onColumnSort: onSort
          },
          renderFunction: ({ data: { payable } }) => (
            <WSTableCell
              text={toWSDateString(payable?.events.paidAt, "monthDayYear")}
            />
          )
        },
        {
          config: {
            gridTemplateSizeMax: "1fr",
            justify: "start",
            hideOnScreens: showRefundedLabel
              ? ["XS", "S"]
              : ["XS", "S", "M", "L", "XL"],
            onColumnSort: onSort
          },
          renderFunction: ({ data: { payable } }) => (
            <WSTableCell
              pill={
                payable.events.refundedAt ? { text: "Refunded" } : undefined
              }
            />
          )
        },
        {
          config: {
            gridTemplateSizeMin: "min-content",
            gridTemplateSizeMax: "1fr",
            header: showHeaders
              ? {
                  text: "Amount"
                }
              : undefined
          },
          renderFunction: ({ data: { payable } }) => (
            <WSTableCell
              text={toWSMoneyString(
                calculateLineItemsTotal(payable.lineItems) +
                  (payable.chargedFees?.lateFee?.amount || 0)
              )}
              secondaryText={
                showLatestUpdate && payrollSettings
                  ? getPayableLatestUpdate(payable, payrollSettings)
                  : undefined
              }
            />
          )
        }
      ]}
      data-testid="payablesTable"
      {...tableProps}
    />
  );
};
