import {
  WSGrid,
  WSMarginProps,
  WSPanel,
  WSPill
} from "@wingspanhq/fe-component-library";

import React from "react";
import styles from "./styles.module.scss";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";
import { DataItem } from "../../../../shared/components/DataItem";
import { useRequestCorrectionFields } from "../../utils/useRequestCorrectionFields";

interface OriginalValuePreviewProps extends WSMarginProps {
  correctionType: string; // TODO: add enum for correction types
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  hidePill?: boolean;
}

export const OriginalValuePreview: React.FC<OriginalValuePreviewProps> = ({
  correctionType,
  taxForm,
  hidePill,
  children,
  ...other
}) => {
  const fields = useRequestCorrectionFields(taxForm, correctionType);

  return (
    <WSPanel noBorder mb="XL" className={styles.originalValue} {...other}>
      <WSGrid gutter="2XL">
        {fields.map((props, index) => (
          <WSGrid.Item
            key={`${props.label} + ${index}`}
            span={{ xs: "12", s: "6" }}
          >
            <DataItem {...props} />
          </WSGrid.Item>
        ))}
      </WSGrid>
      {hidePill ? null : (
        <WSPill
          theme="neutral"
          type="dark"
          text="Original"
          className={styles.originalBadge}
        />
      )}
    </WSPanel>
  );
};
