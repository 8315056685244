import { WSPage } from "@wingspanhq/fe-component-library";
import { MemberWorkFlowStatus } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  PayablesStatusFilter,
  PayablesTable
} from "../../components/NewPayablesTable/PayablesTable";

export const PayablesDisputed: React.FC = () => {
  const history = useHistory();
  useBrowserPageTitle("Disputed Payables");

  return (
    <WSPage
      title="Disputed Payables"
      breadcrumb={{
        label: "Payables",
        icon: "arrow-left",
        onClick: () => history.push("/member/invoices/payables")
      }}
    >
      <PayablesTable
        basePath="/member/invoices/payables/disputed"
        visibleFilters={{
          payee: true,
          openedAtRange: true
        }}
        defaultFilters={{
          status: [
            PayablesStatusFilter.ToApprove,
            PayablesStatusFilter.Scheduled
          ],
          custom: {
            "member.workflowStatus": MemberWorkFlowStatus.Disputed
          }
        }}
        showHeaders
        showDateRequested
        emptyState={{
          standart: "No unsent payables."
        }}
      />
    </WSPage>
  );
};
