import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Layout } from "../../../../../shared/components/Layout";
import { FormSupportInformation } from "../../../components/FormSupportInformation";

export const RouteEditPayerInformation: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <Layout title="1099-NEC filing">
      <FormSupportInformation onNext={onNext} onBack={onBack} />
    </Layout>
  );
};
