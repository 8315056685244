import { useDownloadPdf } from "../../../Invoices/utils";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { downloadPayeeW9 } from "../../../services/payees";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const usePayeeDownloadW9 = () => {
  const downloadPdf = useDownloadPdf();
  const xWingspanPayerId = useXWingspanPayerId();

  return useWSMutation<
    void,
    WSServiceError,
    {
      payeeId: string;
      filename: string;
    }
  >(async ({ payeeId, filename }) => {
    const blob = await downloadPayeeW9(payeeId, xWingspanPayerId);
    const url = window.URL.createObjectURL(blob);
    downloadPdf(url, `${filename}-w9-form.pdf`);
  });
};
