import {
  IPayerTaxFormResponse,
  PayeeTaxDocumentSharePermission,
  TaxFormCorrectionStatus,
  TaxFormSubmissionStatus
} from "@wingspanhq/payments/dist/interfaces";
import { ListRequestQuery } from "../../../utils/serviceHelper";
import { serviceSearch } from "../service";
import {
  ITaxFormRedactedSubmissionData,
  ITaxFormSubmissionSharing,
  TaxFormDeliveryMethod,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import {
  IMember,
  VerificationStatus
} from "@wingspanhq/users/dist/lib/interfaces/member";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces/newUser";

export type TaxFormSubmissionRow = IPayerTaxFormResponse & {
  lastCorrectionStatus: TaxFormCorrectionStatus;
  submissions: ITaxFormRedactedSubmissionData[] | ITaxFormSubmissionResponse[];
  id: string;
  taxFormSubmissionId: string;
  taxForm: IPayerTaxFormResponse;
  searchableStatus: TaxFormSearchStatus;
  tinVerification?: {
    status: VerificationStatus;
  };
  secureSharing?: ITaxFormSubmissionSharing;
  payer?: {
    member: IMember;
    user: INewUser;
  };
  yearString: string;
  correctionId?: string;

  // Payee side of correction
  hasPendingCorrectionString: "true" | "false"; // submission == taxForm.currentSubmissionId && !!taxForm.pendingCorrectionId
  isCorrectionAcceptedString: "true" | "false"; // !!submission.correctedByCorrectionId
  isCorrectionRejectedString: "true" | "false"; // !!submission.rejectedCorrectionIds.length
  // lifecycle side of correction
  isCorrectedString: "true" | "false"; // CorrectionType ! Voided || None - this submission is a result of a correction
  isGrouped: boolean;
  isSupercededString: "true" | "false"; // correctedBySubmissionId is set - this submission has been corrected, this points at the newer submission that corrects this one
  isVoidedString: "true" | "false"; // CorrectionType = Voided
};

export type TaxFormSearchStatus = TaxFormSubmissionStatus | TaxFormStatus;

export type TaxFormSubmissionFilterRequest = {
  yearString: string;
  searchString?: string;
  memberId?: string;
  clientId?:
    | string
    | {
        in?: string[];
      };
  searchableStatus?:
    | TaxFormSearchStatus
    | {
        "!="?: TaxFormSearchStatus;
        in?: TaxFormSearchStatus[];
      };

  isGroupedString?: "true" | "false";
  groupIds?: {
    in?: string[];
  };
  shareTaxDocument?:
    | PayeeTaxDocumentSharePermission
    | {
        "!="?: PayeeTaxDocumentSharePermission;
        in?: PayeeTaxDocumentSharePermission[];
      };

  "data.totalAmount"?: {
    ">"?: number;
    ">="?: number;
    "<"?: number;
    "<="?: number;
  };

  searchableTaxStatus?: { in?: string[] };
  "taxForm.exclusionReason"?: { in?: string[] };
  "data.w9Info.state"?: { in?: string[] };
  recipientSyncEnabledString?: "true" | "false";
  "data.deliveryMethod"?: TaxFormDeliveryMethod;
  hasPendingCorrectionString?: "true" | "false";
  isCorrectionAcceptedString?: "true" | "false";
  isCorrectionRejectedString?: "true" | "false";
  isCorrectedString?: "true" | "false";
  isVoidedString?: "true" | "false";
  isSupercededString?: "true" | "false";
  // [key: string]:
  //   | undefined
  //   | string
  //   | {
  //       in?: string[];
  //     }
  //   | {
  //       "!="?: string;
  //     }
  //   | {
  //       contains?: string;
  //     };
};

export type TaxFormSubmissionQuerySort = {
  searchableName?: "asc" | "desc";
  "data.totalAmount"?: "asc" | "desc";
  updatedAt?: "asc" | "desc";
};

export type TaxFormSubmissionRequest = ListRequestQuery<
  TaxFormSubmissionFilterRequest,
  TaxFormSubmissionQuerySort
>;

export const getTaxFormSubmissionRows = async (
  params?: TaxFormSubmissionRequest,
  xWingspanExpansion?: string
): Promise<TaxFormSubmissionRow[]> => {
  const response = await serviceSearch.get("/tax-form-submission-row", {
    params,
    ...(xWingspanExpansion
      ? {
          headers: {
            "x-wingspan-expansion": xWingspanExpansion
          }
        }
      : {})
  });

  return response.data;
};

export type TaxFormSubmissionRowsSummary = {
  listSize: number;
};

export const getTaxFormSubmissionRowsSummary = async (
  params?: TaxFormSubmissionRequest,
  xWingspanExpansion?: string
): Promise<TaxFormSubmissionRowsSummary> => {
  const { headers } = await serviceSearch.get(`/tax-form-submission-row`, {
    params,
    headers: {
      "X-WS-Get-List-Summary": "true",
      ...(xWingspanExpansion
        ? {
            "x-wingspan-expansion": xWingspanExpansion
          }
        : {})
    }
  });

  return {
    listSize: Number(headers["x-ws-list-size"])
  };
};
