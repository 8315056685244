import React, { useEffect, useMemo } from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Redirect, useRouteMatch } from "react-router-dom";
import { useBulkCalculate1099BatchSummary } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import { EditLayout } from "../../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import { formatNumber } from "accounting";
import {
  WSButton,
  WSFlexBox,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import { FINISH_FILING_FLOW_PATH } from "../index";
import {
  BulkStatus,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  getTaxFormRowSearchQueryDefaultFilters,
  usePaginatedTaxFormRowSearchCount
} from "../../../../../query/search/taxForm/queries/usePaginatedTaxFormRowSearch";
import { PreventLeave } from "../../../../../components/PreventLeave/PreventLeave";
import { CURRENT_YEAR } from "../../../constants/currentYear";

const POLL_TIME_IN_MS = 3000;
export const RouteSubmitting: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const queryTaxFormRowSearchCount = usePaginatedTaxFormRowSearchCount({
    filters: {
      ...getTaxFormRowSearchQueryDefaultFilters(CURRENT_YEAR),
      statuses: `(status: ${TaxFormStatus.ReadyToSubmitToIrs})`
    }
  });

  const queryBulkCalculate1099Summary = useBulkCalculate1099BatchSummary(
    bulkBatchId,
    {},
    { refetchInterval: POLL_TIME_IN_MS }
  );

  const isFinished = useMemo(() => {
    const status = queryBulkCalculate1099Summary.data?.status;
    return status === BulkStatus.Complete || status === BulkStatus.Failed;
  }, [queryBulkCalculate1099Summary.data?.status]);

  useEffect(() => {
    const total = queryBulkCalculate1099Summary.data?.totalTaxForms;
    const processed = queryBulkCalculate1099Summary.data?.processedTaxForms;
    const failed = queryBulkCalculate1099Summary.data?.failedTaxForms;

    const isSuccess = processed === total && failed === 0;

    if (isFinished) {
      onNext({ isSuccess, bulkBatchId });
    }
  }, [queryBulkCalculate1099Summary.data, bulkBatchId]);

  if (!bulkBatchId) {
    return <Redirect to={FINISH_FILING_FLOW_PATH} />;
  }

  return (
    <EditLayout title="1099-NEC Filing" noBack>
      <WSQueries
        queries={{
          queryBulkCalculate1099Summary,
          queryTaxFormRowSearchCount
        }}
      >
        {({
          queryBulkCalculate1099SummaryData,
          queryTaxFormRowSearchCountData
        }) => {
          const total =
            queryBulkCalculate1099SummaryData.totalTaxForms ||
            queryTaxFormRowSearchCountData;
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;
          const importPercent = total ? (processed / total) * 100 : 0;
          const formattedTotalItems = formatNumber(total);

          return (
            <>
              <WSText.Heading4 mb="XL">
                Wingspan is submitting {total} tax forms to the IRS for filing
              </WSText.Heading4>

              <WSFlexBox.CenterY mb="XL">
                <WSProgressBar
                  my="XL"
                  percent={importPercent}
                  text={`${processed} of ${formattedTotalItems} forms submitted`}
                />
              </WSFlexBox.CenterY>

              <WSButton.Tertiary fullWidth disabled={true}>
                Continue
              </WSButton.Tertiary>

              <WSText.ParagraphSm color="gray500" mt="XL">
                Your tax forms are now being submitted to the IRS automatically.
                Due to IRS processing limits, this may take a few hours. Feel at
                ease to close this window if needed; the submission will
                continue until completion.
              </WSText.ParagraphSm>
              <PreventLeave isEnabled={!isFinished} />
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
