import {
  WSContainer,
  WSElement,
  WSGrid,
  WSIcon,
  WSLayout,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";

interface EditLayoutProps {
  title: string;
  children: ReactNode;
  onBack?: () => void;
  onClose?: () => void;
  noBack?: boolean;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
}

export const EditLayout: React.FC<EditLayoutProps> = ({
  title,
  children,
  onBack,
  contentLeft,
  contentRight,
  noBack,
  onClose
}) => {
  useBrowserPageTitle(title);

  const goBackOrToParent = useGoBackOrToParent();
  const goBack = noBack ? undefined : onBack || goBackOrToParent;

  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium" color="gray700">
          {title}
        </WSText.ParagraphSm>
      }
      onBack={goBack}
      line
      headerRight={
        (!noBack && goBack) || onClose ? (
          <WSIcon
            block
            size="M"
            name="exit"
            onClick={onClose || goBack}
            color="gray600"
          />
        ) : undefined
      }
    >
      <WSContainer verticalPadding>
        <WSGrid>
          <WSGrid.Item span={{ m: "2", l: "2" }}>
            {contentLeft ?? null}
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "8", l: "8" }}>{children}</WSGrid.Item>
          <WSGrid.Item span={{ m: "2", l: "2" }}>
            {contentRight ?? null}
          </WSGrid.Item>
        </WSGrid>
      </WSContainer>

      <WSElement pb="3XL" pt="XL" />
    </WSLayout>
  );
};
