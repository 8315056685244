import {
  WSElement,
  WSProvider,
  WSText
} from "@wingspanhq/fe-component-library";
import "@wingspanhq/fe-component-library/dist/style.css";
import React, { useState } from "react";
import { ReactQueryDevtools } from "react-query-devtools";
import { Route, Router, Switch } from "react-router-dom";
import { Onboarder } from "../../Onboarder/Onboarder";
import "../../global.scss";
import { RequestOnboardingLink } from "../../modules/1099NECFiling/routes/RequestOnboardingLink";
import { CreateTestCollaborator } from "../../screens/CreateTestCollaborator";
import { CreateTestInvoice } from "../../screens/CreateTestInvoice";
import { CreateTestPayable } from "../../screens/CreateTestPayable";
import { CreateTestUser } from "../../screens/CreateTestUser";
import { SignInTestUser } from "../../screens/SignInTestUser";
import { SignOut } from "../../screens/SignOut";
import "../../services/payments";
import {
  pushSessionToken,
  setRequestingTokenFromURL
} from "../../services/sessionStorage";
import "../../services/users";
import { BUILD_ENV, IS_DEV_ENV } from "../../shared/constants/environment";
import { wsStore } from "../../store";
import { history } from "../../store/history";
import { HistoryListener } from "../../utils/HistoryListener";
import { WSStoreProvider } from "../../utils/WSStore";
import { CaptchaProvider } from "../Captcha";
import { Notifications } from "../Notification/Notification";
import { Main } from "./Main";
import { RoutePreviewBySecureLink } from "../../modules/TaxDocuments/routes/RoutePreviewBySecureLink";

const App: React.FC = () => {
  const [hideDevTools, setHideDevTools] = useState(false);

  const buildTarget = process.env.REACT_APP_BUILD_TARGET || "app";
  const buildDate = new Date(
    `${process.env.REACT_APP_BUILD_TIMESTAMP}`
  ).toLocaleString(undefined, { hour12: false });
  const buildCommit = process.env.REACT_APP_GIT_LATEST_COMMIT;
  const queryParams = new URLSearchParams(window.location.search);

  const token = queryParams.get("token");
  const requestingToken = queryParams.get("requestingToken");

  if (requestingToken || token) {
    if (requestingToken) {
      pushSessionToken(requestingToken);
      setRequestingTokenFromURL(requestingToken);
      queryParams.delete("requestingToken");
    } else if (
      !window.location.pathname.startsWith("/member/reset-password") &&
      window.location.pathname.startsWith("/member") &&
      token
    ) {
      pushSessionToken(token as string);
      queryParams.delete("token");
    }

    history.replace({
      search: queryParams.toString()
    });
  }
  return (
    <>
      <Router history={history}>
        <WSStoreProvider store={wsStore}>
          <HistoryListener>
            <CaptchaProvider>
              <WSProvider>
                {buildTarget === "app" ? (
                  <Switch>
                    {IS_DEV_ENV ? (
                      <Route
                        path="/create-test-user"
                        exact
                        component={CreateTestUser}
                      />
                    ) : null}
                    {IS_DEV_ENV ? (
                      <Route
                        path="/sign-in-test-user"
                        exact
                        component={SignInTestUser}
                      />
                    ) : null}
                    {IS_DEV_ENV ? (
                      <Route
                        path="/create-test-invoice"
                        exact
                        component={CreateTestInvoice}
                      />
                    ) : null}
                    {IS_DEV_ENV ? (
                      <Route
                        path="/create-test-collaborator"
                        exact
                        component={CreateTestCollaborator}
                      />
                    ) : null}
                    {IS_DEV_ENV ? (
                      <Route
                        path="/create-test-payable"
                        exact
                        component={CreateTestPayable}
                      />
                    ) : null}
                    <Route
                      path={["/sign-out", "/signout", "/log-out", "/logout"]}
                      exact
                      component={SignOut}
                    />
                    <Route
                      path="/1099/request-onboarding-link"
                      component={RequestOnboardingLink}
                    />
                    <Route
                      path="/1099/secure-link/:token"
                      component={RoutePreviewBySecureLink}
                    />
                    <Route
                      render={() => (
                        <>
                          <Notifications />
                          <Main />
                        </>
                      )}
                    />
                  </Switch>
                ) : buildTarget === "onboarder" ? (
                  <Onboarder />
                ) : (
                  <WSText>
                    Don't know build target {JSON.stringify(buildTarget)}
                    ... whatcha trying to do?
                  </WSText>
                )}
              </WSProvider>
            </CaptchaProvider>
          </HistoryListener>
        </WSStoreProvider>
      </Router>
      {IS_DEV_ENV && !hideDevTools && (
        <>
          <ReactQueryDevtools initialIsOpen={false} />
          <WSElement
            style={{
              position: "fixed",
              bottom: "2px",
              width: "100%",
              textAlign: "center"
            }}
          >
            <WSText.ParagraphSm
              color="gray400"
              onClick={() => setHideDevTools(true)}
            >
              build: {BUILD_ENV} | updated: {buildDate} | hash: {buildCommit}
            </WSText.ParagraphSm>
          </WSElement>
        </>
      )}
    </>
  );
};

export default App;
