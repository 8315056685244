import {
  WSActions,
  WSCard,
  WSForm,
  WSGrid,
  WSInfoBox,
  WSInputMask,
  WSInputText,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../../query/WSQuery";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../../query/users/mutations";
import { useMemberProfile } from "../../../../query/users/queries";
import { ErrorContextKey } from "../../../../services/platform";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";

interface SupportInformationFormValues {
  supportEmail?: string;
  supportPhoneNumber?: string;
}

export const FormSupportInformation: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const qMember = useMemberProfile(userId);

  const [updateMemberProfile, updateMemberProfileMeta] =
    useUpdateMemberProfile();
  const [confirmPayerInfo, confirmPayerInfoMeta] = useWSMutation(async data => {
    await updateMemberProfile(
      {
        memberId: userId,
        profile: {
          company: {
            supportEmail: data.supportEmail,
            supportPhoneNumber: data.supportPhoneNumber
          }
        }
      },
      {
        onSuccess: onNext
      }
    );
  });

  const onFormSubmit = async (data: SupportInformationFormValues) => {
    updateMemberProfileMeta.reset();

    await confirmPayerInfo(data);
  };

  return (
    <WSQueries queries={{ qMember }}>
      {({ qMember: { data: member } }) => {
        const { company } = member.profile;
        return (
          <WSForm<SupportInformationFormValues>
            defaultValues={{
              supportEmail: company?.supportEmail ?? "",
              supportPhoneNumber: company?.supportPhoneNumber ?? ""
            }}
            validationSchema={Yup.object().shape({
              supportEmail: validatorEmail,
              supportPhoneNumber: Yup.string()
                .matches(/^[0-9]+$/, "Should be 9 digits")
                .length(10, "Phone number should be 10 characters")
                .required("Phone number is required")
            })}
            onSubmit={onFormSubmit}
          >
            <WSCard header="Support information" divider>
              <WSList gap="XL">
                <WSInfoBox theme="info" title="Payer’s support information">
                  Providing communication methods for your contractors to
                  contact you for 1099 filing support as outlined in the{" "}
                  <a
                    href="https://docs.wingspan.app/docs/support-ownership"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support ownership
                  </a>{" "}
                  guide.
                </WSInfoBox>

                <WSGrid>
                  <WSGrid.Item span={{ m: "6" }}>
                    <WSForm.Field
                      name="supportEmail"
                      component={WSInputText}
                      componentProps={{
                        required: true,
                        label: "Support email",
                        placeholder: "Enter email",
                        type: "email"
                      }}
                    />
                  </WSGrid.Item>
                  <WSGrid.Item span={{ m: "6" }}>
                    <WSForm.Field
                      name="supportPhoneNumber"
                      component={WSInputMask}
                      componentProps={{
                        label: "Support phone number",
                        required: true,
                        slotChar: " ",
                        placeholder: "Enter phone number",
                        mask: "(999) 999-**********",
                        unmask: true,
                        autoClear: false
                      }}
                    />
                  </WSGrid.Item>
                </WSGrid>

                <WSErrorMessage
                  contextKey={ErrorContextKey.Other}
                  error={updateMemberProfileMeta.error}
                />
              </WSList>
            </WSCard>

            <WSActions
              mt="3XL"
              alignment="fill"
              buttons={[
                {
                  label: "Cancel",
                  onClick: onBack,
                  type: "button",
                  kind: "Secondary"
                },
                {
                  label: "Save changes",
                  type: "submit",
                  loading: confirmPayerInfoMeta.isLoading
                }
              ]}
            />
          </WSForm>
        );
      }}
    </WSQueries>
  );
};
