import {
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";

export type TotalsSectionItem =
  | {
      bold?: true;
      left: ReactNode;
      right: ReactNode;
    }
  | [ReactNode, ReactNode];

export type TotalsSection = {
  items: TotalsSectionItem[];
};

type Props = { sections: TotalsSection[] } & WSElementProps;

export const Totals: React.FC<Props> = ({ sections, ...elementProps }) => (
  <WSElement {...elementProps}>
    {sections.map((section, sectionIndex) => (
      <WSElement key={`section-${sectionIndex}`}>
        {section.items.map((item, itemIndex) => (
          <WSElement key={itemIndex}>
            <WSFlexBox
              wrap="nowrap"
              justify="space-between"
              alignItems="center"
              mb={itemIndex === section.items.length - 1 ? undefined : "S"}
              {...elementProps}
            >
              {Array.isArray(item) ? (
                <>
                  <WSText color="gray500" mr="2XL">
                    {item[0]}
                  </WSText>
                  <WSText color="gray500">{item[1]}</WSText>
                </>
              ) : (
                <>
                  <WSText
                    color={item.bold ? "gray700" : "gray500"}
                    mr="2XL"
                    weight={item.bold ? "medium" : undefined}
                  >
                    {item.left}
                  </WSText>
                  <WSText
                    color={item.bold ? "gray700" : "gray500"}
                    weight={item.bold ? "medium" : undefined}
                  >
                    {item.right}
                  </WSText>
                </>
              )}
            </WSFlexBox>
          </WSElement>
        ))}

        {sectionIndex < sections.length - 1 && (
          <WSDivider key={`divider-${sectionIndex}`} my="M" />
        )}
      </WSElement>
    ))}
  </WSElement>
);
