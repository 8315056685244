import {
  useIsS,
  useIsXS,
  WSElement,
  WSGrid,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import { useMemo } from "react";
import { OnboardingStep } from "../../../modules/Onboarding/types";
import { useBrowserPageTitle } from "../../hooks/useBrowserPageTitle";
import { AppFooter } from "../AppFooter";
import styles from "./index.module.scss";
import { Navigation } from "./Navigation";
import { Stepper } from "./Stepper";

export type LayoutProps = {
  title?: string;
  onClose?: () => void;
  navigation?: OnboardingStep[];
  customHeader?: React.ReactNode;
  wide?: boolean;
};

export const Layout: React.FC<LayoutProps> = ({
  title,
  onClose,
  navigation,
  wide,
  customHeader,
  children
}) => {
  useBrowserPageTitle(title);
  const isXS = useIsXS();
  const isS = useIsS();

  const hasNavigation = useMemo(
    () => navigation && navigation.length > 0,
    [navigation]
  );

  const topHeaderPadding =
    window.document
      .querySelector("[data-headerheight]")
      ?.getAttribute("data-headerheight") || "0";

  return (
    <WSElement
      className={styles.layout}
      style={{ paddingTop: topHeaderPadding + "px" }}
    >
      {customHeader ? (
        customHeader
      ) : (
        <WSElement className={styles.header}>
          <WSText.ParagraphSm my="L" weight="medium">
            {title}
          </WSText.ParagraphSm>

          {onClose && (
            <WSIcon
              onClick={onClose}
              className={styles.closeIcon}
              name="exit"
            />
          )}
        </WSElement>
      )}

      <WSElement
        className={classNames(
          styles.content,
          hasNavigation && styles.twoColumn,
          wide && styles.threeColumn
        )}
      >
        {hasNavigation ? (
          <WSGrid>
            <WSGrid.Item span={{ m: "3" }}>
              {isXS || isS ? (
                <Stepper navigation={navigation || []} mb="L" />
              ) : (
                <Navigation navigation={navigation || []} />
              )}
            </WSGrid.Item>
            <WSGrid.Item span={{ m: "9" }}>{children}</WSGrid.Item>
          </WSGrid>
        ) : (
          children
        )}
      </WSElement>
      <AppFooter showLinks={false} showAdditionalInfo={false} />
    </WSElement>
  );
};
