import { useRouteMatch } from "react-router-dom";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import {
  WSAlert,
  WSButton,
  WSDivider,
  WSElement,
  WSList,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { getCorrectionTypeFromCorrectionRequest } from "./getCorrectionTypeFromCorrectionRequest";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import React from "react";
import { AmountCorrectionDiffPreview } from "../../../TaxCorrections/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../TaxCorrections/components/TINOrNameCorrectionDiffPreview";
import { AddressCorrectionDiffPreview } from "../../../TaxCorrections/components/AddressCorrectionDiffPreview";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { UnchangedRecipientDetails } from "../../components/UnchangedRecipientDetails";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";

export const RouteViewCorrectionRequest: React.FC = () => {
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);

  const goBack = useGoBackOrToParent();

  return (
    <EditLayout title="Review correction request">
      <WSElement>
        <WSText.Paragraph weight="medium" mb="XS">
          Review 1099-NEC correction request
        </WSText.Paragraph>
        <WSText.ParagraphSm color="gray500">
          Please review to ensure the information is accurate before proceeding
        </WSText.ParagraphSm>

        <WSAlert
          theme="warning"
          icon="alert"
          title="Form 1099-NEC pending acceptance by the IRS"
          my="XL"
        >
          The contractor has requested corrections to their Form 1099-NEC. The
          form has been submitted to the IRS but has not yet been accepted (or
          rejected).
          <br />
          <br />
          Once the IRS has accepted the filing, you can review the request,
          verify the corrections, and determine whether to accept the changes
          and submit to the IRS or reject the changes.
        </WSAlert>

        <WSQueries queries={{ queryTaxForm }}>
          {({ queryTaxFormData: taxForm }) => {
            const correctionRequest = taxForm?.pendingCorrection;

            if (!correctionRequest) {
              return (
                <WSMessageBox.Info
                  button={{
                    kind: "Link",
                    children: "Refresh",
                    onClick: () => {
                      window.location.reload();
                    }
                  }}
                >
                  Please refresh the web page if you can't see correction
                  details.
                </WSMessageBox.Info>
              );
            }
            const { corrections, isVoidType, isCompanyStructureChangeOnly } =
              getCorrectionTypeFromCorrectionRequest(taxForm);

            const hasUnchangedDetails =
              !corrections.includes(CORRECTION_AMOUNT) ||
              !corrections.includes(CORRECTION_TIN_OR_NAME) ||
              !corrections.includes(CORRECTION_ADDRESS);

            const correctionTypeToPreviewComponentMap: Record<
              string,
              JSX.Element
            > = {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  amount={taxForm?.data?.totalAmount ?? 0}
                  correctedAmount={correctionRequest.data?.totalAmount ?? 0}
                  comment={
                    correctionRequest.payerOwnedData?.comment ||
                    correctionRequest.payeeOwnedData?.comment ||
                    ""
                  }
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  identificationNumberType={
                    correctionRequest.data?.w9Info?.ein ||
                    correctionRequest.data?.w9Info?.einLastFour
                      ? TinType.Business
                      : TinType.Individual
                  }
                  originalTinData={getPayeeTINorName(taxForm)}
                  correctedTinData={{
                    ssn: correctionRequest?.data?.w9Info?.ssn ?? "",
                    ein: correctionRequest?.data?.w9Info?.ein ?? "",
                    businessName:
                      correctionRequest?.data?.w9Info?.legalBusinessName ?? "",
                    firstName: correctionRequest?.data?.w9Info?.firstName,
                    lastName: correctionRequest?.data?.w9Info?.lastName,
                    disregardedEntityName:
                      correctionRequest?.data?.w9Info?.disregardedEntityName,
                    taxClassification: correctionRequest?.data?.w9Info
                      ?.companyStructure as CompanyStructure
                  }}
                  reasonForChange={
                    correctionRequest?.payeeOwnedData?.reason ?? ""
                  }
                  otherReason={correctionRequest?.payeeOwnedData?.reasonComment}
                  hasOtherEligibleCorrectionChanges={
                    !isCompanyStructureChangeOnly
                  }
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  originalAddress={getPayeeFormW9Address(taxForm) as any}
                  correctedAddress={{
                    addressLine1:
                      correctionRequest?.data?.w9Info?.addressLine1 ?? "",
                    addressLine2:
                      correctionRequest?.data?.w9Info?.addressLine2 ?? "",
                    city: correctionRequest?.data?.w9Info?.city ?? "",
                    state: correctionRequest?.data?.w9Info?.state ?? "",
                    postalCode:
                      correctionRequest?.data?.w9Info?.postalCode ?? "",
                    country: correctionRequest?.data?.w9Info?.country ?? ""
                  }}
                />
              )
            };
            return (
              <WSList gap="XL">
                {corrections.map(correctionType => {
                  const DiffPreviewComponent =
                    correctionTypeToPreviewComponentMap[correctionType];
                  return (
                    <React.Fragment key={correctionType}>
                      {DiffPreviewComponent}
                    </React.Fragment>
                  );
                })}
                <WSDivider />

                {hasUnchangedDetails ? (
                  <UnchangedRecipientDetails
                    taxForm={taxForm}
                    corrections={corrections}
                  />
                ) : null}

                <WSButton.Tertiary fullWidth onClick={goBack}>
                  Back
                </WSButton.Tertiary>
              </WSList>
            );
          }}
        </WSQueries>
      </WSElement>
    </EditLayout>
  );
};
