import {
  WSActions,
  WSCard,
  WSEmptyState,
  WSList
} from "@wingspanhq/fe-component-library";
import { EditPayeeSetupParams } from "./index";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import React from "react";
import { TinType } from "@wingspanhq/payments/dist/interfaces";

type Props = SetupRouteComponentProps<EditPayeeSetupParams>;

export const EditPayeeTaxInfoVerificationPending: React.FC<Props> = ({
  onNext,
  params
}) => {
  const tinType = params?.payee?.payerOwnedData.payeeW9Data.tinType;
  const tinTypeLabel = tinType === TinType.Individual ? "SSN" : "EIN";

  return (
    <WSList gap="2XL">
      <WSCard>
        <WSEmptyState
          type="tasks"
          title={`Contractor’s ${tinTypeLabel} verification is pending`}
          description={`The IRS is currently verifying the contractor’s ${tinTypeLabel}. Click continue for the next step`}
          orientation="horizontal"
        />
      </WSCard>
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: onNext
          }
        ]}
      />
    </WSList>
  );
};
