import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import {
  IRecipientVerificationRequest,
  IRecipientVerificationResponse
} from "@wingspanhq/payments/dist/interfaces";
import { paymentsService } from "../../../services/payments";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYEE,
  QUERY_PAYMENTS_TAXFORM_PAYEE
} from "../keys";
import { QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY } from "../../search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";
import { QUERY_TAX_FORM_SUBMISSION_ROWS } from "../../search/taxForm/queries/useTaxFormSubmissionRowsQuery";

export const useTaxFormVerifyIdentity = (
  config?: WSMutationConfig<
    IRecipientVerificationResponse,
    WSServiceError,
    {
      taxFormId: string;
    } & IRecipientVerificationRequest
  >
) =>
  useWSMutation(
    ({ taxFormId, ...other }) =>
      paymentsService.taxForm.verifyIdentity(taxFormId, other),
    {
      awaitDependencies: [
        QUERY_PAYMENTS_TAXFORM_PAYEE,
        QUERY_PAYMENTS_TAXFORM_LIST_PAYEE,
        QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY,
        QUERY_TAX_FORM_SUBMISSION_ROWS
      ],
      ...config
    }
  );
