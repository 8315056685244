import {
  IPayableSchema,
  IScheduleDate
} from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import isEmpty from "lodash/isEmpty";
import { IPayeeRow } from "../../../services/search";
import { cleanCSVData } from "../../../shared/utils/csv";
import { calculateLineItemRegularPrice } from "../../utils";
import { lineItemFieldToCSVString } from "../../utils/invoicesCSVUtils";

export type MapPayableToCSV = {
  payable: IPayableSchema;
  payeeRow?: IPayeeRow;
  payrollRun?: IScheduleDate;
};
const mapPayableTableItemData = (item: MapPayableToCSV) => {
  const { payable, payeeRow, payrollRun } = item;

  if (!payable) {
    return {};
  }

  const names =
    payeeRow && payeeRow.user
      ? wsName({
          user: payeeRow.user,
          member: payeeRow.member,
          payerOwnedData: payeeRow.payerOwnedData
        })
      : undefined;

  const payeeRowCompanyName =
    payeeRow?.payerOwnedData.payeeW9Data?.legalBusinessName ||
    payeeRow?.payeeOwnedData.payeeW9Data?.legalBusinessName ||
    payeeRow?.member.profile?.company?.legalBusinessName ||
    payeeRow?.member.profile?.company?.doingBusinessAs;

  return {
    "External Contractor ID": payeeRow?.payerOwnedData.payeeExternalId,
    "Contractor Name": names?.getResolvedName(),
    "Contractor First Name": payeeRow?.user.profile?.firstName,
    "Contractor Last Name": payeeRow?.user.profile?.lastName,
    "Contractor Preferred Name": payeeRow?.user.profile?.preferredName,
    "Contractor Company Name": payeeRowCompanyName,
    "Contractor Email": payeeRow?.user.email,
    Amount: payable.amount,
    Currency: payable.currency?.toUpperCase(),
    "Purchase Order Number": payable.metadata?.purchaseOrderNumber,
    "Due Date": payable.dueDate,
    "Check Date": payable.client?.payDate,
    "Payroll Deposit Date": payrollRun?.date,
    "Created Date": payable.createdAt,
    "Sent Date": payable?.events?.sentAt,
    "Opened Date": payable?.events?.openedAt,
    "Member Disputed Date": payable?.events?.disputedAt,
    "Invoice Declined Date": payable?.events?.clientDeclinedAt,
    // "Pre-Approved Date": TODO
    "Approved Date": payable?.events?.approvedAt,
    "Paid Date": payable?.events?.paidAt,
    "Deposited Date": payable?.events?.depositedAt,
    "Estimated Deposit Date": payable?.events?.estimatedDepositAt,
    "Cancelled Date": payable?.events?.cancelledAt,
    "Invoice Number": payable.invoiceNumber,
    "Invoice Type": payable.labels?.invoiceType,
    "Member Workflow Status": payable.member?.workflowStatus,
    "Client Workflow Status": payable.client?.workflowStatus,
    Status: payable.status,
    // "Payroll Invoice Number": TODO
    // "Payroll Invoice Amount": TODO
    "Project Name": payable.labels?.projectName,
    "Invoice PDF": payable.attachments?.invoicePdf,
    "Receipt PDF": payable.attachments?.receiptPdf,
    "Invoice Link": payable.attachments?.invoiceLink,
    "Line Items": isEmpty(payable.lineItems)
      ? undefined
      : JSON.stringify(payable.lineItems)
          // fix json string in csv
          .replace(/"/g, '""'),
    "Invoice Notes": payable.invoiceNotes,
    "Member Comment": payable.member?.comment,
    "Line Item Index": lineItemFieldToCSVString(
      (l, index) => index,
      payable.lineItems
    ),
    "Line Item Title": lineItemFieldToCSVString(
      l => l.description,
      payable.lineItems
    ),
    "Line Item Description": lineItemFieldToCSVString(
      l => l.detail,
      payable.lineItems
    ),
    "Line Item Amount": lineItemFieldToCSVString(
      l => calculateLineItemRegularPrice(l)?.toFixed(2),
      payable.lineItems
    ),
    ...(isEmpty(payable.labels)
      ? {}
      : Object.keys(payable.labels as any)
          .filter(key => !["createdBy"].includes(key))
          .reduce((acc, key) => {
            return {
              ...acc,
              [`Custom Field ${key}`]: payable.labels[key]
            };
          }, {}))
  };
};
export const mapPayablesTableToCSV = (data: Array<MapPayableToCSV>) => {
  let cleanResult = [];

  const dirtyResult = data
    .map(d => {
      try {
        return mapPayableTableItemData(d);
      } catch (error) {
        console.error(error);
      }
      return {};
    })
    .filter(d => !isEmpty(d));
  cleanResult = dirtyResult.map(d =>
    cleanCSVData(d, dirtyResult)
  ) as Array<any>;

  return cleanResult;
};
