import React, { useMemo } from "react";
import { WSMarginProps } from "../WSElement/WSElement.component";
import {
  WSActions,
  WSActionsButton,
  WSActionsMenuItem
} from "../WSActions/WSActions";
import { WSMenuIconItem, WSMenuItem } from "../common";
import { useCurrentScreenSize } from "../layout";
import sortBy from "lodash/sortBy";
import { WSButtonProps } from "../core/WSButton/WSButton.component";

export type WSPageToolbarAction = WSActionsMenuItem & {
  loading?: boolean;
  disabled?: boolean;
  buttonKind?: WSButtonProps["kind"];
  hideButtonOn?: WSActionsButton["hideOn"] | false;
  buttonOrder?: number;
  name?: string;
  hidden?: boolean;
  visible?: boolean;
};

export interface WSPageToolbarActionsProps extends WSMarginProps {
  actions?: WSPageToolbarAction[];
  className?: string;
}

export const WSPageToolbarActions: React.FC<WSPageToolbarActionsProps> = (
  props
) => {
  const currentScreenSize = useCurrentScreenSize();
  const { actions: allActions, ...elementProps } = props;

  const actions = useMemo(() => {
    return (allActions || [])
      .filter((action) => action.hidden !== true)
      .filter((action) => action.visible !== false);
  }, [allActions]);

  const menuItems: WSMenuItem[] = useMemo(() => {
    return actions.map(
      ({ buttonKind, hideButtonOn, buttonOrder, ...menuItem }) => menuItem
    );
  }, [actions]);

  const buttons: WSActionsButton[] = useMemo(() => {
    const actionButtons: WSActionsButton[] = [];

    const filteredActions = actions.filter((action) => action.buttonKind);

    sortBy(filteredActions, ({ buttonOrder = 1 }) => buttonOrder).forEach(
      ({ buttonKind, buttonOrder, hideButtonOn = ["XS"], ...action }) => {
        const buttonProps = action as WSMenuIconItem;

        const sharedProps = {
          onClick: buttonProps.onClick,
          onAsyncClick: action.onAsyncClick,
          loading: action.loading,
          label: buttonProps.label,
          icon: buttonProps.icon,
          kind: buttonKind,
          ...action
        } as WSActionsButton;

        const isVisible = Array.isArray(hideButtonOn)
          ? !hideButtonOn.includes(currentScreenSize)
          : true;

        if (buttonKind && isVisible) {
          actionButtons.push({
            ...sharedProps,
            label:
              currentScreenSize === "XS" && buttonProps.icon
                ? ""
                : (buttonProps.label as string)
          });
        }
      }
    );

    return actionButtons;
  }, [actions, currentScreenSize]);

  return actions.length ? (
    <WSActions
      alignment="right"
      menuItems={menuItems}
      buttons={buttons}
      {...elementProps}
    />
  ) : null;
};
