import { FederalTaxClassification } from "../types";

export const taxClassificationLabelMap: Record<
  FederalTaxClassification,
  string
> = {
  SoleProprietorship: "Sole proprietorship",
  LlcSingleMember: "LLC (single member)",
  CorporationS: "S-corp",
  CorporationC: "C-corp",
  Partnership: "Partnership",
  LlcCorporationS: "LLC (Taxed as S-corp)",
  LlcCorporationC: "LLC (Taxed as C-corp)",
  LlcPartnership: "LLC (Taxed as Partnership)",
  NotForProfitOrganization: "Not-for-profit organization"
};

const businessTaxClassification: FederalTaxClassification[] = [
  "SoleProprietorship",
  "LlcSingleMember",
  "CorporationS",
  "CorporationC",
  "Partnership",
  "LlcCorporationS",
  "LlcCorporationC",
  "LlcPartnership"
];

const individualTaxClassification: FederalTaxClassification[] = [
  "SoleProprietorship",
  "LlcSingleMember"
];

export const individualTaxClassificationSelectOptions =
  individualTaxClassification.map(value => ({
    label: taxClassificationLabelMap[value],
    value
  }));

export const businessTaxClassificationSelectOptions =
  businessTaxClassification.map(value => ({
    label: taxClassificationLabelMap[value],
    value
  }));
