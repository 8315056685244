import { TinType } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";
import { WSDataItemProps, WSGrid } from "@wingspanhq/fe-component-library";
import { DataItem } from "../../../shared/components/DataItem";
import { selectorAddressString } from "../../../shared/selectors/selectorAddressString";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

type Props = {
  submission: ITaxFormSubmissionResponse;
};

export const ContractorDetails: React.FC<Props> = ({ submission }) => {
  const fields = useMemo(() => {
    const w9Info = submission.data?.w9Info;
    const tinType = w9Info?.tinType;
    const name =
      tinType === TinType.Business
        ? w9Info?.legalBusinessName
        : [w9Info?.firstName, w9Info?.lastName].filter(Boolean).join(" ");

    const taxClassificationView = selectorTaxClassificationLabel(
      w9Info?.companyStructure
    );
    const addressView = selectorAddressString(w9Info);

    const tinView =
      tinType === TinType.Business
        ? `•••••${w9Info?.einLastFour} (EIN)`
        : tinType === TinType.Individual
        ? `•••••${w9Info?.ssnLastFour} (SSN)`
        : `-`;

    const result: WSDataItemProps[] = [
      {
        label: "Name",
        value: name
      },
      {
        label: "Federal tax classification",
        value:
          tinType === "Individual"
            ? "Sole proprietorship / Single member LLC"
            : taxClassificationView
      },
      {
        label: "Taxpayer identification number",
        value: tinView
      },
      {
        label: "Address",
        value: addressView
      }
    ];

    return result;
  }, [submission]);

  return (
    <WSGrid gutter="2XL">
      {fields.map((props, index) => (
        <WSGrid.Item
          key={`${props.label} + ${index}`}
          span={{ xs: "12", s: "6" }}
        >
          <DataItem {...props} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
