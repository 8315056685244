export const getCommonCurrencyCode = (data: { currency?: string }[]) => {
  if (!data.length) return;
  const currencyCode = data[0].currency;

  const isAllSameCurrency = data.every(
    invoice => (invoice.currency || "USD") === currencyCode
  );

  if (!isAllSameCurrency) return;

  return currencyCode;
};
