import {
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFlexBox,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeTaxFormResponse,
  TaxFormExclusionReason,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import { IMember } from "@wingspanhq/users";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberClientsQuery } from "../../../../query/payments/queries";
import { QUERY_USERS_ACTIVITIES } from "../../../../query/users/keys";
import { useActivities } from "../../../../query/users/queries";
import { usersService } from "../../../../services/users";
import { selectorTaxClassificationLabel } from "../../../../shared/selectors/selectorTaxClassificationLabel";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { formatPhone } from "../../../../shared/utils/formatPhone";
import { selectorPayeeTaxFormIsLatestAddressCorrection } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormIsLatestAddressCorrection";
import { selectorPayeeTaxFormPayeeCorrectionIsAccepted } from "../../../TaxDocuments/selectors/selectorPayeeTaxFormPayeeCorrectionIsAccepted";
import { selectorPayeeTaxFormPayeeCorrectionIsPending } from "../../../TaxDocuments/selectors/selectorPayeeTaxFormPayeeCorrectionIsPending";
import { selectorPayeeTaxFormPayeeCorrectionIsRejected } from "../../../TaxDocuments/selectors/selectorPayeeTaxFormPayeeCorrectionIsRejected";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import { selectorPayeeTaxFormSupportContacts } from "../../../TaxDocuments/selectors/selectorPayeeTaxFormSupportContacts";
import { getPaymentsLinkForTaxDocument } from "../getPaymentsLinkForTaxDocument";
import { useDownloadTaxDocument } from "./useDownloadTaxDocument";

export interface TaxFormPreviewProps extends WSElementProps {
  taxForm: IPayeeTaxFormResponse;
  member: IMember;
}

const ViewPaymentsButton: React.FC<{ taxForm: IPayeeTaxFormResponse }> = ({
  taxForm
}) => {
  const userId = useUserId();
  const history = useHistory();
  const queryMemberClients = useMemberClientsQuery();
  const queryActivity = useActivities(userId);
  const { openSnackbar } = useWSSnackbar();
  const [viewPayments, viewPaymentsMeta] = useWSMutation(
    async () => {
      if (!queryActivity.data?.flows.paymentsSetup?.complete) {
        await usersService.activity.update(userId, {
          flows: {
            paymentsSetup: {
              complete: true
            }
          }
        });
      }
    },
    {
      onSuccess: () => {
        history.push(
          getPaymentsLinkForTaxDocument(queryMemberClients.data || [], taxForm)
        );
      },
      onError: () => {
        openSnackbar({
          type: "warning",
          message: "Something went wrong"
        });
      },
      dependencies: [QUERY_USERS_ACTIVITIES]
    }
  );

  return (
    <WSButton.Secondary
      ml="M"
      loading={
        queryMemberClients.isLoading ||
        queryActivity.isLoading ||
        viewPaymentsMeta.isLoading
      }
      size="S"
      onClick={viewPayments}
    >
      View payments
    </WSButton.Secondary>
  );
};

export const TaxFormPreview: React.FC<TaxFormPreviewProps> = ({
  taxForm,
  member,
  ...otherProps
}) => {
  const history = useHistory();

  const now = new Date();
  const deadline = new Date(taxForm.year + 1, 0, 28, 23, 59, 59); // 29th of January
  const isBeforeDeadline = now < deadline;
  const isAfterDeadline = now > deadline;
  const secondDeadline = new Date(taxForm.year + 1, 1, 11, 23, 59, 59); // 12th of February
  const isBeforeSecondDeadline = now < secondDeadline;

  const { supportPhone, supportEmail } =
    selectorPayeeTaxFormSupportContacts(taxForm);

  const payerName = selectorPayeeTaxFormPayerName(taxForm);
  const taxClassificationLabel = selectorTaxClassificationLabel(
    taxForm?.data?.w9Info?.companyStructure
  );
  const downloadModal = useDownloadTaxDocument();

  let content: React.ReactNode = null;
  const contactPayerBlock =
    supportEmail || supportPhone ? (
      <WSMessageBox.Info noBorder mt="XL">
        <WSText.ParagraphSm weight="medium" mb="M" color="gray500">
          Contact payer support
        </WSText.ParagraphSm>
        <WSFlexBox.CenterY>
          {supportPhone && (
            <WSText.ParagraphSm color="gray500" mr="XL">
              Phone: {formatPhone(supportPhone)}
            </WSText.ParagraphSm>
          )}
          {supportEmail && (
            <WSText.ParagraphSm color="gray500">
              Email: {supportEmail}
            </WSText.ParagraphSm>
          )}
        </WSFlexBox.CenterY>
      </WSMessageBox.Info>
    ) : null;

  const payerNameBlock = useMemo(
    () =>
      taxForm.payer ? (
        <WSText weight="medium" mb="M">
          {selectorPayeeTaxFormPayerName(taxForm)}
        </WSText>
      ) : null,
    [taxForm]
  );

  const viewPaymentsButton = useMemo(
    () => <ViewPaymentsButton taxForm={taxForm} />,
    [taxForm]
  );

  const downloadTaxFormSubmissionButton = useMemo(
    () =>
      (title: string): React.ReactNode =>
        (
          <WSButton.Primary
            size="S"
            onClick={() => {
              downloadModal.open({
                taxForm,
                documentName: `1099-NEC_${taxForm.year}`
              });
            }}
          >
            {title}
          </WSButton.Primary>
        ),
    [taxForm, downloadModal]
  );

  const confirmTaxPreferencesButton = useMemo(
    () => (
      <WSButton.Primary
        size="S"
        onClick={() => {
          history.push(
            `/member/1099-filing/tax-information/verify-identity/${taxForm.taxFormId}`
          );
        }}
      >
        Confirm tax preferences
      </WSButton.Primary>
    ),
    [taxForm, history]
  );

  const viewDetailsButton = (
    <WSButton.Secondary
      size="S"
      onClick={() => {
        history.push(`${history.location.pathname}/${taxForm.taxFormId}`);
      }}
    >
      View details
    </WSButton.Secondary>
  );

  const viewDeliveryPreferencesButton = (
    <WSButton.Link
      size="S"
      disabled
      onClick={() => {
        history.push(`${history.location.pathname}/tax-preferences`);
      }}
    >
      View delivery preferences
    </WSButton.Link>
  );

  const contactPayerButton = (
    <WSButton.Primary
      size="S"
      onClick={() => {
        openInNewTab("mailto:" + supportEmail);
      }}
    >
      Contact payer
    </WSButton.Primary>
  );

  if (
    [
      TaxFormStatus.Pending,
      TaxFormStatus.NeedsAction,
      TaxFormStatus.ReadyToSubmitToIrs,
      TaxFormStatus.Excluded
    ].includes(taxForm.status) &&
    !!taxForm.recipientConfirmedW9Info &&
    isBeforeDeadline
  ) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">Your Form 1099 will be available by January 31st</WSText>
        <WSButtons>{viewDeliveryPreferencesButton}</WSButtons>

        {contactPayerBlock}
      </>
    );
  } else if (
    [
      TaxFormStatus.Pending,
      TaxFormStatus.NeedsAction,
      TaxFormStatus.ReadyToSubmitToIrs,
      TaxFormStatus.Excluded
    ].includes(taxForm.status) &&
    !taxForm.recipientConfirmedW9Info &&
    isBeforeDeadline
  ) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">Your Form 1099 will be available by January 31st</WSText>
        <WSText mb="M">
          Confirm your tax preferences to receive your Form 1099
        </WSText>
        <WSButtons>{confirmTaxPreferencesButton}</WSButtons>

        {contactPayerBlock}
      </>
    );
  } else if (
    taxForm.status === TaxFormStatus.Excluded &&
    isAfterDeadline &&
    taxForm.exclusionReason === TaxFormExclusionReason.BelowThreshold
  ) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">
          You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
          {taxForm.year} because you did not meet the $600 payment threshold.
        </WSText>
        <WSText mb="M">
          If you think there was an error, you can contact your payer to request
          a correction.
        </WSText>
        <WSButtons>
          {contactPayerButton}
          {viewPaymentsButton}
        </WSButtons>

        {contactPayerBlock}
      </>
    );
  } else if (
    taxForm.status === TaxFormStatus.Excluded &&
    isAfterDeadline &&
    taxForm.exclusionReason ===
      TaxFormExclusionReason.IneligibleCompanyStructure
  ) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">
          You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
          {taxForm.year} because your federal tax classification is{" "}
          {taxClassificationLabel}. According to IRS guidelines, corporations
          are not eligible to receive Form 1099-NEC.
        </WSText>
        <WSText mb="M">
          If this is an error, please contact your payer to request a
          correction, to reflect your accurate federal tax classification.
        </WSText>

        <WSButtons>
          {contactPayerButton}
          {viewPaymentsButton}
        </WSButtons>

        {contactPayerBlock}
      </>
    );
  } else if (
    taxForm.status === TaxFormStatus.Excluded &&
    isAfterDeadline &&
    taxForm.exclusionReason === TaxFormExclusionReason.NonUsEntity
  ) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">
          You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
          {taxForm.year} because you are not a U.S. Business or Individual.
        </WSText>

        <WSButtons>
          {contactPayerButton}
          {viewPaymentsButton}
        </WSButtons>

        {contactPayerBlock}
      </>
    );
  } else if (
    taxForm.status === TaxFormStatus.Excluded &&
    isAfterDeadline &&
    taxForm.exclusionReason === TaxFormExclusionReason.ManuallyExcluded
  ) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">
          You were not issued a Form 1099-NEC from {payerName} for tax year{" "}
          {taxForm.year}.
        </WSText>

        <WSText mb="M">
          If you think this was an error, please contact the payer for
          additional support.
        </WSText>

        <WSButtons>
          {contactPayerButton}
          {viewPaymentsButton}
        </WSButtons>

        {contactPayerBlock}
      </>
    );
  } else if (selectorPayeeTaxFormIsLatestAddressCorrection(taxForm)) {
    // latest correction was address only and was created by payee
    content = (
      <>
        {payerNameBlock}
        <WSText mb="S">
          You’re all set for now! The requested address change does not require
          a correction to the IRS for filing purposes. We’ve updated the address
          on your profile and new correction request was sent to the payer.
        </WSText>
        <WSText mb="S">
          Please contact the payer if you would like to have the tax forms
          re-mailed to your new address.
        </WSText>

        <WSText mb="S">
          <b>
            Only one (1) correction request can be made through Wingspan to your
            payer for a tax year.
          </b>
          If there are additional errors to correct after the payer's response,
          please reach out to the payer directly to resolve the issue.
        </WSText>

        <WSText mb="S">
          Your original {taxForm.year} Form 1099-NEC from {payerName} is
          available.
        </WSText>

        {taxForm.currentSubmissionId ? (
          <WSButtons>
            {downloadTaxFormSubmissionButton}
            {viewDetailsButton}
          </WSButtons>
        ) : (
          <WSButtons>{viewDetailsButton}</WSButtons>
        )}

        {contactPayerBlock}
      </>
    );
  } else if (selectorPayeeTaxFormPayeeCorrectionIsPending(taxForm)) {
    const correctionDate =
      taxForm.pendingCorrection?.events.createdAt || new Date();

    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">
          You requested a correction on {format(correctionDate, "MMMM do")}
        </WSText>

        {isBeforeSecondDeadline ? (
          <WSText mb="M">
            <b>Starting February 12th, {taxForm.year + 1}</b>, you will be
            notified when the payer accepts or rejects your correction request.
          </WSText>
        ) : (
          <WSText mb="M">
            The payer is still reviewing your correction request.
          </WSText>
        )}

        <WSText mb="M">
          <b>
            Only one (1) correction request can be made through Wingspan to your
            payer for a tax year.
          </b>
          If there are additional errors to correct after the payer's response,
          please reach out to the payer directly to resolve the issue.
        </WSText>

        <WSText mb="M">
          Your original {taxForm.year} Form 1099-NEC from {payerName} is
          available.
        </WSText>

        {taxForm.currentSubmissionId ? (
          <WSButtons>
            {downloadTaxFormSubmissionButton("Download")}
            {viewDetailsButton}
          </WSButtons>
        ) : (
          <WSButtons>{viewDetailsButton}</WSButtons>
        )}

        {contactPayerBlock}
      </>
    );
  } else if (selectorPayeeTaxFormPayeeCorrectionIsAccepted(taxForm)) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="S">
          {payerName} issued a correction to your {taxForm.year} Form 1099-NEC
          form.
        </WSText>
        <WSText mb="S">The updated form was issued to the IRS.</WSText>

        {taxForm.currentSubmissionId ? (
          <WSButtons>
            {downloadTaxFormSubmissionButton("Download Form 1099 (updated)")}
            {viewDetailsButton}
          </WSButtons>
        ) : (
          <WSButtons>{viewDetailsButton}</WSButtons>
        )}

        {contactPayerBlock}
      </>
    );
  } else if (selectorPayeeTaxFormPayeeCorrectionIsRejected(taxForm)) {
    const reason =
      taxForm.corrections?.[taxForm.corrections.length - 1].payerOwnedData
        ?.comment || "";

    content = (
      <>
        {payerNameBlock}
        <WSText mb="S">
          {payerName} rejected your correction request with reason:
        </WSText>

        <WSMessageBox.Warning noBorder mb="S">
          <WSText.ParagraphSm color="gray600">{reason}</WSText.ParagraphSm>
        </WSMessageBox.Warning>

        <WSText mb="S">Please contact the payer for additional support.</WSText>
        <WSText mb="S">
          Your original {taxForm.year} Form 1099-NEC from {payerName} is
          available.
        </WSText>

        {taxForm.currentSubmissionId ? (
          <WSButtons>
            {downloadTaxFormSubmissionButton("Download")}
            {viewDetailsButton}
          </WSButtons>
        ) : (
          <WSButtons>{viewDetailsButton}</WSButtons>
        )}

        {contactPayerBlock}
      </>
    );
  } else if (
    [
      TaxFormStatus.SubmittedToIrs,
      TaxFormStatus.AcceptedByIrs,
      TaxFormStatus.RejectedByIrs
    ].includes(taxForm.status)
  ) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">
          Your {taxForm.year} Form 1099-NEC from {payerName} is now available.
        </WSText>

        <WSText mb="M">
          Notice an error? You can request a correction from the details page.
        </WSText>

        {taxForm.currentSubmissionId ? (
          <WSButtons>
            {downloadTaxFormSubmissionButton("Download")}
            {viewDetailsButton}
          </WSButtons>
        ) : (
          <WSButtons>{viewDetailsButton}</WSButtons>
        )}

        {contactPayerBlock}
      </>
    );
  } else if (isBeforeDeadline) {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">Your Form 1099 will be available by January 31st</WSText>

        {taxForm.recipientConfirmedW9Info ? (
          <WSButtons>{viewDeliveryPreferencesButton}</WSButtons>
        ) : (
          <WSButtons>{confirmTaxPreferencesButton}</WSButtons>
        )}
        {contactPayerBlock}
      </>
    );
  } else {
    content = (
      <>
        {payerNameBlock}
        <WSText mb="M">Your Form 1099 has not yet been filed.</WSText>

        {taxForm.recipientConfirmedW9Info ? (
          <>
            <WSText mb="M">
              Once the payer has submitted the tax form for filing, it will
              become available here. Please contact the payer for additional
              support.
            </WSText>
            <WSButtons>{viewDeliveryPreferencesButton}</WSButtons>
          </>
        ) : (
          <>
            <WSText mb="M">
              Confirm your tax preferences to receive your Form 1099. Once the
              payer has submitted the tax form for filing, it will become
              available here. Please contact the payer for additional support.
            </WSText>
            <WSButtons>{confirmTaxPreferencesButton}</WSButtons>
          </>
        )}
        {contactPayerBlock}
      </>
    );
  }

  return <WSPanel {...otherProps}>{content}</WSPanel>;
};
