import { WSSelect } from "@wingspanhq/fe-component-library";
import React from "react";
import { TAX_FILING_PATH } from "../../paths";
import { useHistory, useLocation } from "react-router-dom";
import { useFocusedFeatures } from "../../../../FocusedFeatures";
import { useActivities } from "../../../../query/users/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { get1099SetupProgress } from "../../../../query/users/selectors";

type Props = {};

export const TAX_FILING_YEAR_OPTIONS = [
  { value: "2024", label: "2024", path: TAX_FILING_PATH },
  { value: "2023", label: "2023", path: "/member/1099-filing" }
] as const;

export const SelectTaxYear: React.FC<Props> = ({}) => {
  const userId = useUserId();
  const location = useLocation();
  const history = useHistory();
  const focusedFeatures = useFocusedFeatures();
  const queryActivity = useActivities(userId);

  const currentOption = TAX_FILING_YEAR_OPTIONS.find(p =>
    location.pathname.startsWith(p.path)
  );

  const defaultValue = currentOption?.value || TAX_FILING_YEAR_OPTIONS[0].value;
  const nec1099Setup = get1099SetupProgress(queryActivity.data);

  return (
    <WSSelect
      hideClearAction={true}
      mode="single"
      disabled={!queryActivity.data}
      value={defaultValue}
      onChange={newValue => {
        const newPath = TAX_FILING_YEAR_OPTIONS.find(
          p => p.value === newValue
        )?.path;
        if (newPath) {
          history.push(newPath);
        }
      }}
      options={TAX_FILING_YEAR_OPTIONS.filter(p =>
        focusedFeatures.showTaxFilingYearSwitcher && nec1099Setup?.complete
          ? true
          : p.value === defaultValue
      )}
    />
  );
};
