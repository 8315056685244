export const getExpirationLinkMessage = (expirationDate: Date) => {
  const currentDate = new Date();
  const timeDiff = expirationDate.getTime() - currentDate.getTime();
  // expiration message in days
  const days = Math.floor(timeDiff / (1000 * 3600 * 24));
  if (days > 0) {
    return `Secure link expires in ${days} days`;
  }

  // expiration message in hours
  const hours = Math.floor(timeDiff / (1000 * 3600));
  if (hours > 0) {
    return `Secure link expires in ${hours} hours`;
  }

  // expiration message in minutes
  const minutes = Math.floor(timeDiff / (1000 * 60));
  if (minutes > 0) {
    return `Secure link expires in ${minutes} minutes`;
  }

  return `Secure link has expired`;
};
