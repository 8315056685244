import {
  IPayeeTaxFormResponse,
  PayeeCorrectionReason,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
// import { FormRequestCorrectionData } from "./types";
import { FormDataAddress } from "../../../../shared/components/FormPartialAddress";
import { TinData } from "../components/TINOrNameCorrectionDiffPreview";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../constants/corrections";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { FormRequestCorrectionData } from "../components/types";

export function getPayeeFormW9Address(
  taxForm: IPayeeTaxFormResponse
): FormDataAddress {
  const address = taxForm?.data?.w9Info ?? taxForm.payee?.profile?.address;
  return {
    addressLine1: address?.addressLine1 ?? "",
    addressLine2: address?.addressLine2 ?? "",
    city: address?.city ?? "",
    state: address?.state ?? "",
    country: address?.country ?? "",
    zipCode: address?.postalCode ?? ""
  };
}

export function getPayeeTINorName(taxForm: IPayeeTaxFormResponse): TinData {
  return {
    ein: taxForm?.data?.w9Info?.ein ?? "",
    einLastFour: taxForm?.data?.w9Info?.einLastFour,
    ssn: "",
    ssnLastFour: taxForm?.data?.w9Info?.ssnLastFour,
    firstName: taxForm?.data?.w9Info?.firstName ?? "",
    lastName: taxForm?.data?.w9Info?.lastName ?? "",
    businessName: taxForm?.data?.w9Info?.legalBusinessName ?? "",
    disregardedEntityName: taxForm?.data?.w9Info?.disregardedEntityName ?? "",
    taxClassification: taxForm?.data?.w9Info
      ?.companyStructure as CompanyStructure
  };
}

export function getDefaultValuesForCorrectionsForm(
  corrections: string[],
  taxForm: IPayeeTaxFormResponse
): Partial<FormRequestCorrectionData> {
  return {
    ...(corrections.includes(CORRECTION_TIN_OR_NAME)
      ? {
          identificationNumberType: taxForm?.data?.w9Info?.ein
            ? TinType.Business
            : TinType.Individual,
          ...getPayeeTINorName(taxForm),
          reasonForChange: PayeeCorrectionReason.Mistake,
          otherReason: ""
        }
      : {}),

    ...(corrections.includes(CORRECTION_AMOUNT)
      ? {
          necTotalAmount: taxForm?.data?.totalAmount ?? 0,
          amountCorrectionHelpText: ""
        }
      : {}),

    ...(corrections.includes(CORRECTION_ADDRESS)
      ? { formW9Address: getPayeeFormW9Address(taxForm) }
      : {})
  };
}
