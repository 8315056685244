import { WSForm, WSInputText } from "@wingspanhq/fe-component-library";
import { ICompanyLinks } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { queryCache } from "react-query";
import * as Yup from "yup";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_MEMBER_PROFILE } from "../../../../../query/users/keys";
import { useMemberProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { validatorWebsite } from "../../../../../shared/validators/validatorWebsite";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { newValue: string };

type Props = {
  onClose: () => void;
  name: keyof ICompanyLinks;
  label: string;
};

export const FormEditLink: React.FC<Props> = props => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const member = await usersService.member.update(userId, {
        memberId: userId,
        profile: {
          company: {
            links: {
              [props.name]: data.newValue
            }
          }
        }
      });

      queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);
    },
    {
      dependencies: [QUERY_USERS_MEMBER_PROFILE],
      onSuccess: props.onClose
    }
  );

  const initialValue = queryMember.data?.profile.company?.links?.[props.name];

  return (
    <WSForm<FormData>
      onSubmit={mutate}
      defaultValues={{
        newValue: initialValue || ""
      }}
      validationSchema={Yup.object({
        website: validatorWebsite
      })}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        <WSForm.Field
          name="newValue"
          label={`${props.label} (optional)`}
          component={WSInputText}
          componentProps={{
            placeholder: "http://"
          }}
        />
      </LayoutFormEdit>
    </WSForm>
  );
};
