import flatten from "lodash/flatten";
import times from "lodash/times";
import { QueryConfig } from "react-query";
import {
  getPayeeRows,
  getPayeeRowsSummary,
  IPayeeRow
} from "../../../../services/search";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";
import {
  concurrentActions,
  WSServiceError
} from "../../../../utils/serviceHelper";
import { useWSQuery } from "../../../helpers";

const PAGE_SIZE = 500;

export const QUERY_ALL_PAYEE_ROWS = "QUERY_ALL_PAYEE_ROWS";

export const useQueryAllPayeeRows = (
  config?: QueryConfig<IPayeeRow[], WSServiceError>
) => {
  const xWingspanExpansion = useXWingspanExpansion();

  return useWSQuery<IPayeeRow[], WSServiceError>(
    [QUERY_ALL_PAYEE_ROWS, xWingspanExpansion],
    async () => {
      const summary = await getPayeeRowsSummary({}, xWingspanExpansion);
      const pages = Math.ceil(summary.listSize / PAGE_SIZE);

      const actions = times(pages).map(
        (_, i) => () =>
          getPayeeRows(
            {
              page: {
                size: PAGE_SIZE,
                number: i + 1
              }
            },
            xWingspanExpansion
          )
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      const payload = flatten(allPages);

      return payload;
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      ...config
    }
  );
};
