import { CalculationSettings1099Toggle } from "@wingspanhq/payments/dist/interfaces";

export const mapOffPlatformPaymentsLabel: Record<
  CalculationSettings1099Toggle,
  string
> = {
  [CalculationSettings1099Toggle.Include]:
    "Include invoices paid outside of Wingspan",
  [CalculationSettings1099Toggle.Exclude]:
    "Exclude invoices paid outside of Wingspan"
};

export const mapCardProcessingFeesLabel: Record<
  CalculationSettings1099Toggle,
  string
> = {
  [CalculationSettings1099Toggle.Include]: "Include card processing fees",
  [CalculationSettings1099Toggle.Exclude]: "Exclude card processing fees"
};

export const mapReimbursableExpensesLabel: Record<
  CalculationSettings1099Toggle,
  string
> = {
  [CalculationSettings1099Toggle.Include]: "Include reimbursable expenses",
  [CalculationSettings1099Toggle.Exclude]: "Exclude reimbursable expenses"
};
