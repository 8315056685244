import {
  WSDivider,
  WSFlexBox,
  WSForm,
  WSInputNumber,
  WSInputTextarea,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { OriginalValuePreview } from "../OriginalValuePreview";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { CORRECTION_AMOUNT } from "../../constants/corrections";

export interface AmountCorrectionFormProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  amount: number;
  payerName?: string;
  recipientName?: string;
  showRequestedValue?: boolean;
  correctedAmount?: number;
  taxFormViewer?: TaxFormViewer;
  isOverride?: boolean;
}

export const AmountCorrectionForm: React.FC<AmountCorrectionFormProps> = ({
  taxForm,
  amount,
  payerName,
  recipientName,
  taxFormViewer = TaxFormViewer.Payee,
  showRequestedValue,
  correctedAmount,
  isOverride
}) => {
  const getHelpText = () => {
    if (payerName) {
      return `Please provide specific details that will help ${payerName}, like incorrect invoice numbers, amounts or anything else that will help them correct your tax form.`;
    } else if (recipientName) {
      return `Please provide specific details that will inform ${recipientName} about the correction on their Form 1099-NEC.`;
    }
    return "";
  };

  const form = (
    <>
      <WSForm.Field
        mb="XL"
        name="necTotalAmount"
        label="What should the 1099-NEC total be?"
        component={WSInputNumber}
        componentProps={{
          currency: "USD",
          placeholder: "$ 0.00",
          maxFractionDigits: 2,
          required: true
        }}
      />

      <WSForm.Field
        label="Comments"
        name="amountCorrectionHelpText"
        component={WSInputTextarea}
        componentProps={{
          helperText: getHelpText(),
          required: true
        }}
        mb="XS"
      />
    </>
  );

  return (
    <WSPanel>
      <WSText.Paragraph mb="M" weight="medium">
        Amount correction
      </WSText.Paragraph>

      <OriginalValuePreview
        taxForm={taxForm}
        correctionType={CORRECTION_AMOUNT}
      />

      <WSText.ParagraphSm mb="XL" color="gray500">
        The total income reported is different than what was actually paid and
        needs to be corrected.
      </WSText.ParagraphSm>

      <WSDivider my="XL" />

      {showRequestedValue ? (
        <CorrectedValuePreview
          mb="M"
          taxForm={taxForm}
          taxFormViewer={taxFormViewer}
          correctionType={CORRECTION_AMOUNT}
          correctionData={{
            amountCorrectionData: {
              amount: correctedAmount ?? 0,
              comment:
                taxForm?.pendingCorrection?.payerOwnedData?.comment ||
                taxForm?.pendingCorrection?.payeeOwnedData?.comment ||
                ""
            }
          }}
        />
      ) : null}

      {isOverride ? (
        <WSPanel>
          <WSFlexBox.CenterY mb="L" justify="space-between">
            <WSText.Paragraph weight="medium">
              Amount correction
            </WSText.Paragraph>
            <WSPill type="dark" text="Overwritten by you" theme="violet" />
          </WSFlexBox.CenterY>
          {form}
        </WSPanel>
      ) : (
        form
      )}
    </WSPanel>
  );
};
