import {
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFormOld,
  WSGrid,
  WSInputMaskOld,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  CompanyStructure,
  IMember,
  INewUser
} from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS } from "../../constants/member";
import {
  SELECT_OPTIONS_US_STATE_SHORT,
  SELECT_OPTIONS_YEAR
} from "../../constants/user";
import { selectorIndustryOptions } from "../../selectors/selectorIndustryOptions";
import { FormPartialAddress } from "../FormPartialAddress";
import { selectorDefaultValues } from "./selectorDefaultValues";
import { FormData } from "./types";
import { validationSchema } from "./validationSchema";

type Props = {
  user: INewUser;
  member: IMember;
  isOnlyOneBusinessOwner?: boolean;
  skipTaxVerification?: boolean;
  onSubmit: (data: FormData) => void;
  error?: any;
  isSubmitLoading?: boolean;
};

export const Form: React.FC<Props> = ({
  user,
  member,
  isOnlyOneBusinessOwner,
  skipTaxVerification,
  onSubmit,
  error,
  isSubmitLoading
}) => (
  <WSFormOld<FormData>
    defaultValues={selectorDefaultValues(user, member, isOnlyOneBusinessOwner)}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <WSElement mb="2XL" hidden={skipTaxVerification}>
      <WSText.Heading5 mb="M">Business information (optional)</WSText.Heading5>
      <WSText.ParagraphSm mb="XL">
        You do not need to enter business information if you are not an LLC or
        Partnership.
      </WSText.ParagraphSm>

      <WSFormOld.Field
        name="iHaveEin"
        component={WSCheckboxToggle}
        componentProps={{
          label: "I have an Employer Identification Number (EIN)"
        }}
        mb="XL"
      />

      <WSFormOld.Value name="iHaveEin">
        {visible => (
          <WSElement hidden={!visible} data-testid="companySection">
            <WSFormOld.Field
              name="company.legalBusinessName"
              label="Legal business name"
              component={WSTextInput}
              mb="XL"
            />

            <WSFormOld.Field
              name="company.businessType"
              label="Business type"
              component={WSSelectOld}
              componentProps={{
                options: BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS
              }}
              mb="XL"
            />

            <WSFormOld.Field
              name="company.ein"
              label="EIN"
              component={WSInputMaskOld}
              componentProps={{
                mask: "99-9999999",
                unmask: true,
                fsExclude: true
              }}
              mb="XL"
            />

            <WSElement mb="XL">
              <WSGrid gutter="M">
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSFormOld.Field
                    name="company.stateOfIncorporation"
                    component={WSSelectOld}
                    componentProps={{
                      options: SELECT_OPTIONS_US_STATE_SHORT,
                      searchable: true
                    }}
                    label="State of incorporation"
                  />
                </WSGrid.Item>
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSFormOld.Field
                    name="company.yearOfIncorporation"
                    component={WSSelectOld}
                    componentProps={{
                      options: SELECT_OPTIONS_YEAR,
                      searchable: true
                    }}
                    label="Year of incorporation"
                  />
                </WSGrid.Item>
              </WSGrid>
            </WSElement>

            <WSFormOld.Value name="company.businessType">
              {businessType => {
                const singlePerson =
                  businessType === CompanyStructure.LlcSingleMember ||
                  businessType === CompanyStructure.SoleProprietorship ||
                  businessType === CompanyStructure.None;

                return (
                  <>
                    <WSFormOld.Field
                      name="company.legalFirstName"
                      label="Legal first name"
                      component={WSTextInput}
                      hidden={!businessType || !singlePerson}
                      mb="XL"
                    />
                    <WSFormOld.Field
                      name="company.legalLastName"
                      label="Legal last name"
                      component={WSTextInput}
                      hidden={!businessType || !singlePerson}
                      mb="XL"
                    />
                    <WSFormOld.Field
                      hidden={!businessType || singlePerson}
                      name="company.isOnlyOneBusinessOwner"
                      component={WSCheckboxToggle}
                      componentProps={{
                        label: "I am the only owner of this business"
                      }}
                      mb="2XL"
                    />
                  </>
                );
              }}
            </WSFormOld.Value>
          </WSElement>
        )}
      </WSFormOld.Value>

      <WSFormOld.Field
        mb="XL"
        name="businessName"
        label="Public business name (optional)"
        description="This is the name that will appear to your contractors & clients."
        component={WSTextInput}
      />

      <WSFormOld.Field
        name="industry"
        label="Industry"
        component={WSSelectOld}
        componentProps={{
          options: selectorIndustryOptions(user)
        }}
      />
    </WSElement>

    <WSText.Heading5 mb="XL">Address</WSText.Heading5>

    <FormPartialAddress name="businessAddress" mb="XL" />

    <WSErrorMessage mb="XL" contextKey="PaymentsSetup" error={error} />

    <WSButton mt="2XL" fullWidth loading={isSubmitLoading} name="next">
      {skipTaxVerification ? "Continue" : "Verify business information"}
    </WSButton>
  </WSFormOld>
);
