import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  TaxFormViewer as BETaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormViewer } from "../../TaxDocuments/types";

export function selectorTaxFormCorrectedValueStatusLabel(
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse,
  taxFormViewer: TaxFormViewer
) {
  const { pendingCorrection } = taxForm;

  if (taxFormViewer === TaxFormViewer.Payer) {
    if (
      pendingCorrection &&
      pendingCorrection?.ownedBy === BETaxFormViewer.payee &&
      pendingCorrection?.eventActors?.editedBy
    ) {
      return "Overwritten by you";
    } else if (pendingCorrection) {
      return "Requested";
    }
    return "Correction";
  } else {
    // taxFormViewer is Payee
    return "Requested";
  }
}
