import {
  WSElementProps,
  WSPillProps,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import {
  TaxFormCorrectionStatus,
  TaxFormSubmissionStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  TaxFormExclusionReason,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { TaxFormSearchStatus } from "../../../../services/search";

export interface FillingStatusBadgeProps
  extends FillingStatusBadgeParams,
    WSElementProps {}

type FillingStatusBadgeParams = {
  status: TaxFormSearchStatus;
  correctionStatus?: TaxFormCorrectionStatus;
  exclusionReason?: TaxFormExclusionReason;
  pendingCorrectionId?: string;

  customDescription?: string;
};

export const getFillingStatusBadgeProps = ({
  status,
  correctionStatus,
  exclusionReason,
  pendingCorrectionId,
  customDescription
}: FillingStatusBadgeParams): WSPillProps & { description?: string } => {
  if (correctionStatus === TaxFormCorrectionStatus.Pending) {
    return {
      theme: "warning",
      icon: true,
      text: "Correction requested",
      description: "Requested by recipient"
    };
  }

  let description = undefined;

  if (status === TaxFormStatus.Excluded) {
    if (exclusionReason === TaxFormExclusionReason.BelowThreshold) {
      description = "Less than $600 payment threshold";
    } else if (
      exclusionReason === TaxFormExclusionReason.IneligibleCompanyStructure
    ) {
      description = "Federal tax classification (IRS)";
    } else if (exclusionReason === TaxFormExclusionReason.ManuallyExcluded) {
      description = "Excluded by you";
    } else if (exclusionReason === TaxFormExclusionReason.NonUsEntity) {
      description = "Non-U.S. person";
    }
  }

  // else if (correctionStatus === TaxFormCorrectionStatus.Rejected) {
  //   description = "Correction not accepted";
  // } else if (correctionStatus === TaxFormCorrectionStatus.Submitted) {
  //   description = "Correction submitted to IRS";
  // }
  // else if (pendingCorrectionId) {
  //   description = "Correction requested";
  else if (customDescription) {
    description = customDescription;
  }

  switch (status) {
    case TaxFormStatus.ReadyToSubmitToIrs:
      return {
        icon: "envelope",
        theme: "blue",
        text: "Ready",
        description
      };
    case TaxFormStatus.Excluded:
      return {
        icon: "block",
        text: "Excluded",
        description
      };
    case TaxFormStatus.NeedsAction:
      return {
        theme: "warning",
        text: "Action Required",
        icon: true,
        description
      };
    case TaxFormSubmissionStatus.AcceptedByIrs:
      return {
        theme: "success",
        icon: true,
        text: "Accepted",
        description
      };
    case TaxFormSubmissionStatus.SubmittedToIrs:
      return {
        theme: "blue",
        icon: "send-mail",
        text: "Submitted",
        description
      };
    case TaxFormSubmissionStatus.RejectedByIrs:
      return {
        theme: "warning",
        icon: true,
        text: "Rejected",
        description
      };
    case TaxFormSubmissionStatus.Voided:
      return {
        theme: "violet",
        icon: "block",
        text: "Voided",
        description
      };
    case TaxFormSubmissionStatus.Corrected:
      return {
        theme: "violet",
        icon: "edit",
        text: "Corrected",
        description
      };
    default:
      return {
        icon: "time",
        text: "Pending",
        description
      };
  }
};
export const FillingStatusBadge: React.FC<FillingStatusBadgeProps> = ({
  status,
  correctionStatus,
  exclusionReason,
  pendingCorrectionId,
  customDescription,
  ...props
}) => {
  const itemProps = getFillingStatusBadgeProps({
    status,
    correctionStatus,
    exclusionReason,
    pendingCorrectionId,
    customDescription
  });

  return (
    <WSTableCell
      pill={{ ...itemProps, ...props }}
      secondaryText={itemProps.description ?? ""}
    />
  );
};
