import { useHistory } from "react-router-dom";
import { PATH_SETTINGS_PAYMENT_METHODS } from "..";
import { Layout } from "../../../../../shared/components/Layout";
import { ManagePaymentMethod } from "../../../components/payment/ManagePaymentMethod";

export const RouteManagePaymentMethod: React.FC = () => {
  const history = useHistory();

  return (
    <Layout title="Manage payment method">
      <ManagePaymentMethod
        onNext={() => {
          history.push(PATH_SETTINGS_PAYMENT_METHODS);
        }}
      />
    </Layout>
  );
};
