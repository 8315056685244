import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useWSStore } from "../../store";

export const useXWingspanExpansion = () => {
  const featureFlags = useFeatureFlags();
  const store = useWSStore();

  return featureFlags.data?.enableContractorOrgExpansion
    ? store.xWingspanExpansion
    : undefined;
};

// TODO: remove after fix
export const useNonContractorXWingspanExpansion = () => {
  const store = useWSStore();

  return store.xWingspanExpansion;
};
