import {
  IPayoutItem,
  IPayoutResponse
} from "../../../services/api/payments/payouts/types";
import { components } from "../../../services/api/payments/types";

export function isInstantPayoutItem(item: components["schemas"]["PayoutItem"]) {
  return item.sourceSubType === "InstantPayoutDrawback";
}

export function getPayoutInvoiceIds(payout: IPayoutResponse) {
  const items = [
    ...(payout.payoutItems || []),
    ...(payout.clearingItems || []),
    ...(payout.futureDebitItems || [])
  ];

  return items
    .filter(item => item.sourceType === "Invoice" && !isInstantPayoutItem(item))
    .map(i => i.sourceId);
}

export function getTotals(
  payout: IPayoutResponse,
  previousPayout?: IPayoutResponse
) {
  const previousFutureDebitItems = previousPayout?.futureDebitItems || [];
  const previousFutureDebitItemsTotal = Math.abs(
    caclulateTotal(previousFutureDebitItems)
  );

  const payoutItems = payout.payoutItems || [];
  const payoutItemsTotal = caclulateTotal(payoutItems);

  const clearingItems = payout.clearingItems || [];
  const clearingItemsTotal = caclulateTotal(clearingItems);

  const futureDebitItems = payout.futureDebitItems || [];
  const futureDebitItemsTotal = caclulateTotal(futureDebitItems);

  const netTotal = getPayoutNet(payout);

  const otherItemsTotal =
    netTotal - (previousFutureDebitItemsTotal + payoutItemsTotal);

  console.log(otherItemsTotal);

  return {
    previousFutureDebitItems,
    previousFutureDebitItemsTotal,
    payoutItems,
    payoutItemsTotal,
    clearingItems,
    clearingItemsTotal,
    futureDebitItems,
    futureDebitItemsTotal,
    otherItemsTotal,
    netTotal
  };
}

export function getPayoutNet(payout: IPayoutResponse) {
  const amount =
    (payout.currentPeriodBalance || 0) - (payout.futureDebits || 0);

  if (amount < 0) {
    return 0;
  }

  return amount;
}

function caclulateTotal(payoutItems: IPayoutItem[]) {
  return payoutItems.reduce((acc, i) => acc + i.amount, 0);
}
