import {
  WSDivider,
  WSElement,
  WSFlexBox,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";

type FlowHeaderProps = {
  name: string;
  year: number;
};

export const CorrectionFlowHeader: React.FC<FlowHeaderProps> = ({
  name,
  year
}) => {
  return (
    <WSElement pb="2XL">
      <WSFlexBox.CenterY>
        <WSText.Heading3 color="gray700" weight="medium">
          {name}
        </WSText.Heading3>
        <WSPill ml="L" text={`${year} Form 1099-NEC`} />
      </WSFlexBox.CenterY>
      <WSDivider mt="2XL" />
    </WSElement>
  );
};
