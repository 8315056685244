import { AxiosHeaderValue, AxiosRequestConfig } from "axios";

export const extendConfigWithXWingspanUserHeader = (
  config: AxiosRequestConfig,
  orgUserId?: string
): AxiosRequestConfig => {
  const customConfig: AxiosRequestConfig = { ...config };

  if (orgUserId) {
    const header = {
      "x-wingspan-user": orgUserId as AxiosHeaderValue
    };

    customConfig.headers = config.headers
      ? {
          ...customConfig.headers,
          ...header
        }
      : header;
  }

  return customConfig;
};
