import React from "react";
import { selectorAddressString } from "../../selectors/selectorAddressString";

export type AddressTextProps = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  placeholder?: string;
  separator?: string;
};

export const AddressText: React.FC<AddressTextProps> = ({
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode,
  country,
  placeholder = "",
  separator = "\n"
}) => {
  const result = selectorAddressString(
    {
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      country
    },
    false,
    separator
  );

  return <>{result ? result : placeholder}</>;
};
