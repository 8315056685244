import { copyText } from "../../../utils/copyText";

export const getExpirationDate = (tokenCreatedAt: Date) => {
  const expirationThreshold = 7 * 24 * 60 * 60 * 1000;

  const expirationDate = new Date(tokenCreatedAt);
  expirationDate.setTime(expirationDate.getTime() + expirationThreshold);

  return expirationDate;
};

export const getIsSecureLinkExpired = (tokenCreatedAt: Date) => {
  const today = new Date();

  // 1 week
  const expirationThreshold = 7 * 24 * 60 * 60 * 1000;

  const isTokenExpired =
    today.getTime() - tokenCreatedAt.getTime() > expirationThreshold;

  return isTokenExpired;
};

export const copyTaxFormSubmissionSecureLink = async (token: string) => {
  const url = `${window.location.origin}/1099/secure-link/${token}`;
  await copyText(url);
};
