import React from "react";
import { FormPayerInformation } from "../../components/FormPayerInformation";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { WSText } from "@wingspanhq/fe-component-library";

export const RouteEditPayerInformation: React.FC = () => {
  const goBack = useGoBackOrToParent();

  return (
    <EditLayout title="Payer information" onBack={goBack}>
      <WSText.Heading4 mb="M">Confirm payer information</WSText.Heading4>
      <WSText color="gray600">
        Does this information look right? If you make changes here, they will
        appear on your company profile and 1099 forms.
      </WSText>
      <FormPayerInformation onNext={goBack} onBack={goBack} isEdit={true} />
    </EditLayout>
  );
};
