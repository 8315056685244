import {
  ITaxFormW9Info,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";

const simplifyUserFileName = (name: string): string => {
  const cleanName = (name || "")
    .replace(/[^\w\s-]/g, "") // Remove special characters except hyphen
    .replace(/\s+/g, "") // Remove spaces
    .replace(/,?LLC$/, "") // Remove LLC suffix
    .replace(/,?Inc\.$/, "") // Remove Inc suffix
    .trim();

  return cleanName || "file";
};

export const getFormFileName = (data: {
  viewer: TaxFormViewer;
  year: number | string;
  payerName?: string;
  payeeW9?: ITaxFormW9Info;
}): string => {
  const { viewer, year, payerName, payeeW9 } = data;
  const payerNameClean = simplifyUserFileName(payerName || "");
  const payeeNameClean = simplifyUserFileName(
    payeeW9?.legalBusinessName ||
      [payeeW9?.firstName, payeeW9?.lastName].filter(Boolean).join("") ||
      ""
  );
  const viewerCopy = viewer === TaxFormViewer.payee ? "PayeeCopy" : "PayerCopy";

  return `1099-NEC_${year}_${payerNameClean}_${viewerCopy}_${payeeNameClean}.pdf`;
};
