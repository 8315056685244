import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { Field } from "../../../components/Field/Field";
import { selectorTaxClassificationLabel } from "../../../../../shared/selectors/selectorTaxClassificationLabel";
import { selectorPayerTaxFormDefaultTinType } from "../../../selectors/selectorPayerTaxFormDefaultTinType";
import { formatEIN } from "../../../../../shared/utils/formatEIN";
import { BannerTinStatus } from "../../../../../shared/components/BannerTinStatus";
import { AddressText } from "../../../../../shared/components/AddressText/AddressText";
import { selectorPayerTaxFormDefaultStatus } from "../../../selectors/selectorPayerTaxFormDefaultStatus";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../../TaxCorrections/constants/corrections";

export interface UnchangedRecipientDetailsProps {
  taxForm: IPayerTaxFormResponse;
  corrections: string[];
  onEdit?: () => void;
}

export const UnchangedRecipientDetails: React.FC<
  UnchangedRecipientDetailsProps
> = ({ taxForm, corrections, onEdit }) => {
  const tinStatus = selectorPayerTaxFormDefaultStatus(taxForm);
  const name = selectorTaxFormRecipientName(taxForm);
  return (
    <WSElement mb="L">
      <WSFlexBox.CenterY mb="XS" wrap="nowrap" justify="space-between">
        <WSText.Heading3 inline>Recipient details (unchanged)</WSText.Heading3>
        {onEdit && <WSButton.Link onClick={onEdit}>Edit</WSButton.Link>}
      </WSFlexBox.CenterY>
      <WSPanel>
        {!corrections.includes(CORRECTION_AMOUNT) ? (
          <Field
            mb="M"
            label="1099-NEC Total"
            value={
              <WSText color="gray500" formatMoney>
                {taxForm?.data?.totalAmount}
              </WSText>
            }
          />
        ) : null}
        {!corrections.includes(CORRECTION_TIN_OR_NAME) ? (
          <>
            <Field mb="M" label="Name" value={name} />
            <Field
              mb="M"
              label="Federal tax classification"
              value={selectorTaxClassificationLabel(
                taxForm.data.w9Info?.companyStructure
              )}
            />
            <WSElement mb="M">
              <Field
                label="Taxpayer identification number"
                value={
                  selectorPayerTaxFormDefaultTinType(taxForm) ===
                  TinType.Business
                    ? `${
                        taxForm.data.w9Info?.ein
                          ? formatEIN(taxForm.data.w9Info.ein)
                          : taxForm.data.w9Info?.einLastFour
                          ? `••• ${taxForm.data.w9Info.einLastFour}`
                          : ""
                      } (Employer Identification Number)`
                    : selectorPayerTaxFormDefaultTinType(taxForm) ===
                      TinType.Individual
                    ? `••• ${taxForm.data.w9Info?.ssnLastFour} (Social Security Number)`
                    : "-"
                }
              />
              <BannerTinStatus verificationStatus={tinStatus} mt="S" />
            </WSElement>
          </>
        ) : null}
        {!corrections.includes(CORRECTION_ADDRESS) ? (
          <Field
            label="Address"
            value={
              <WSText as="pre" color="gray500">
                <AddressText placeholder="-" {...taxForm.data.w9Info} />
              </WSText>
            }
          />
        ) : null}
      </WSPanel>
    </WSElement>
  );
};
