import {
  WSActions,
  WSControl,
  WSElement,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { TextBulleted } from "../../../../shared/components/TextBulleted";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import { AmountCorrectionDiffPreview } from "../../../TaxCorrections/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../TaxCorrections/components/TINOrNameCorrectionDiffPreview";
import { AddressCorrectionDiffPreview } from "../../../TaxCorrections/components/AddressCorrectionDiffPreview";
import { FormRequestCorrectionData } from "../../../TaxCorrections/types";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import { useCancelCorrectionModal } from "../../../TaxCorrections/components/CorrectionFormActions";
import { PreventLeave } from "../../../../components/PreventLeave/PreventLeave";
import { useTaxFormRequestCorrection } from "../../../../query/taxForm/mutations/useTaxFormRequestCorrection";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { createRequestCorrectionPayload } from "../../../TaxCorrections/utils/createRequestCorrectionPayload";
import { getCorrectionType } from "../../../TaxCorrections/utils/getCorrectionType";
import { useCurrentTaxDocumentsYear } from "../../utils/useCurrentTaxDocumentsYear";
import { useGoToTaxDocumentByIds } from "../../paths";
import { SetupRequestCorrectionFlowProps } from "./RequestCorrectionFlow";
import { CorrectionFlowHeader } from "../../../TaxCorrections/components/CorrectionFlowHeader";

export const RouteReviewCorrection: React.FC<
  SetupRequestCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const [consent, setConsent] = useState(false);
  const currentYear = useCurrentTaxDocumentsYear();

  const history = useHistory<{
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;
  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const [requestCorrection, requestCorrectionMeta] =
    useTaxFormRequestCorrection();
  const goToTaxDocument = useGoToTaxDocumentByIds();

  const corrections = history.location.state?.corrections ?? [];
  const correctionsFormData =
    (history.location.state
      ?.correctionsFormData as FormRequestCorrectionData) ?? null;

  const cancelCorrectionModal = useCancelCorrectionModal();
  const onEdit = () => {
    onNext({
      isEdit: true,
      corrections,
      correctionsFormData
    });
  };

  const onCancel = () => {
    goToTaxDocument(taxFormId, submissionId);
  };

  const onRequestCorrection = async () => {
    await requestCorrection(
      {
        taxFormId,
        requestData: createRequestCorrectionPayload(
          correctionsFormData,
          queryTaxForm?.data as IPayeeTaxFormResponse
        )
      },
      {
        onSuccess: res => {
          onNext({
            isCorrectionRequestFailed: false,
            corrections,
            correctionsFormData
          });
        },
        onError: error => {
          onNext({
            isCorrectionRequestFailed: true,
            canTryAgain: [
              500, // Internal Server Error
              502, // Bad Gateway
              503, // Service Unavailable
              504 // Gateway Timeout
            ].includes(error?.response?.status as number),
            corrections,
            correctionsFormData
          });
        }
      }
    );
  };

  const onCancelRequest = () => {
    cancelCorrectionModal.open({ onCancel });
  };

  return (
    <EditLayout title="Request correction" onBack={onBack}>
      <PreventLeave isEnabled={true} />

      <WSQueries
        queries={{
          queryTaxForm
        }}
      >
        {({ queryTaxFormData: taxForm }) => {
          const payerName = selectorPayeeTaxFormPayerName(taxForm);
          const correctionTypeData = getCorrectionType(
            taxForm,
            correctionsFormData
          );
          const correctionTypeToPreviewComponentMap: Record<
            string,
            JSX.Element
          > = {
            [CORRECTION_AMOUNT]: (
              <AmountCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                amount={taxForm?.data?.totalAmount ?? 0}
                correctedAmount={correctionsFormData.necTotalAmount}
                comment={correctionsFormData.amountCorrectionHelpText}
              />
            ),
            [CORRECTION_TIN_OR_NAME]: (
              <TINOrNameCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                originalTinData={getPayeeTINorName(taxForm)}
                correctedTinData={{
                  ssn: correctionsFormData.ssn,
                  firstName: correctionsFormData.firstName,
                  lastName: correctionsFormData.lastName,

                  ein: correctionsFormData.ein,
                  businessName: correctionsFormData.businessName,
                  disregardedEntityName:
                    correctionsFormData.disregardedEntityName,
                  taxClassification:
                    correctionsFormData.taxClassification as CompanyStructure
                }}
                reasonForChange={correctionsFormData.reasonForChange}
                otherReason={correctionsFormData.otherReason}
                identificationNumberType={
                  correctionsFormData.identificationNumberType
                }
              />
            ),
            [CORRECTION_ADDRESS]: (
              <AddressCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                originalAddress={getPayeeFormW9Address(taxForm)}
                correctedAddress={correctionsFormData.formW9Address}
              />
            )
          };

          return (
            <>
              <CorrectionFlowHeader
                year={currentYear}
                name={selectorPayeeTaxFormPayerName(taxForm)}
              />

              <WSText.Paragraph color="gray700" weight="medium" mb="S">
                Review & submit the request for correction
              </WSText.Paragraph>

              <WSText.ParagraphSm color="gray500" weight="book" mb="2XL">
                Please review your requested corrections thoroughly. You may go
                back to edit at any time prior to submitting your request.
              </WSText.ParagraphSm>

              {correctionTypeData.corrections.map(correctionType => {
                const DiffPreviewComponent =
                  correctionTypeToPreviewComponentMap[correctionType];
                return (
                  <WSElement mb="2XL" key={correctionType}>
                    {DiffPreviewComponent}
                  </WSElement>
                );
              })}

              <WSPanel mb="2XL">
                <WSText.Paragraph weight="medium" mb="M" color="gray600">
                  Important, please read:
                </WSText.Paragraph>
                <WSMessageBox.Info noBorder mb="2XL">
                  <TextBulleted color="gray500">
                    <li>
                      <b>
                        Only one (1) correction request can be made through
                        Wingspan to your payer for a tax year.
                      </b>{" "}
                      Please ensure all the information in your correction
                      request is accurate before submitting to your payer.
                    </li>
                    <li>
                      Once your request for correction has been submitted, it
                      cannot be cancelled.
                    </li>
                    <li>
                      The payer company has sole discretion to accept or reject
                      the requested change(s). They may reach out to you
                      regarding the requested change(s).
                    </li>
                    {/* {hasOnlyAmountCorrection ? null : (
                      <li>
                        Changes to taxpayer identification number (EIN or SSN),
                        name, classification, or address - once you submit the
                        correction request, your <b>Wingspan profile</b> will also
                        be updated (whether the correction request is accepted or
                        not by the payer for tax purposes) to ensure the most
                        up-to-date information is reflected in Wingspan.
                      </li>
                    )} */}
                  </TextBulleted>
                </WSMessageBox.Info>
                <WSControl
                  type="checkbox"
                  name="consent"
                  value={consent}
                  onChange={setConsent}
                  label="By checking this box, I understand that once I submit the request for correction, it cannot be cancelled and the payer company has sole discretion to accept or reject the requested change(s)."
                />
              </WSPanel>

              <WSErrorMessage
                mb="XL"
                contextKey={ErrorContextKey.NEC1099RequestCorrection}
                error={requestCorrectionMeta.error}
              />

              <WSActions
                alignment="fill"
                buttons={[
                  {
                    destructive: true,
                    onClick: onCancelRequest,
                    label: "Cancel request"
                  },
                  {
                    disabled: !consent,
                    loading: requestCorrectionMeta.isLoading,
                    onClick: onRequestCorrection,
                    label: "Request correction"
                  }
                ]}
              />
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
