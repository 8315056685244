import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSPill,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  IInvoicePayoutDestination,
  InvoicePayoutDestinationType,
  InvoiceStatus,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useInvoiceQuery,
  usePayoutSettings
} from "../../../query/payments/queries";
import { getInvoicePayoutDestinationDescription } from "../../../query/payments/selectors";
import { useMemberProfile } from "../../../query/users/queries";
import { selectorInstantPayoutDefaultFee } from "../../../shared/selectors/selectorInstantPayoutDefaultFee";
import { instantPayoutService } from "../../../shared/services/instantPayout";
import { openInNewTab } from "../../../utils/openInNewTab";
import { calculateLineItemsTotal } from "../../utils";
import { getLineItemsTotals } from "../../utils/getLineItemsTotals";
import { getPayoutMethodName, usePayoutMethods } from "../../utils/payout";
import { Totals, TotalsSection, TotalsSectionItem } from "../Totals/Totals";
import { getInvoiceTaxWithholdings } from "./getInvoiceTaxWithholdings";

type Props = {
  invoiceId: string;
  is1099Flow?: boolean;
} & WSElementProps;

export const InvoiceTotals: React.FC<Props> = ({
  invoiceId,
  is1099Flow,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryInvoice = useInvoiceQuery(invoiceId);
  const queryMember = useMemberProfile(userId);
  const queryPayoutSettings = usePayoutSettings(userId);

  const { payoutPreferences, payoutMethods } = usePayoutMethods();

  return (
    <WSElement {...elementProps}>
      <WSQueries
        queries={{
          queryInvoice,
          queryMember,
          queryPayoutSettings
        }}
      >
        {({
          queryInvoice: { data: invoice },
          queryMember: { data: member },
          queryPayoutSettings: { data: payoutSettings }
        }) => {
          const instantPayoutDefaultFee =
            selectorInstantPayoutDefaultFee(payoutSettings);

          const totalElement =
            invoice.status !== InvoiceStatus.Paid &&
            invoice.status !== InvoiceStatus.PaymentInTransit ? (
              <WSFlexBox.CenterY>
                <WSText mr="M">Total</WSText>
                <WSPill icon="time" text="Pending" />
              </WSFlexBox.CenterY>
            ) : (
              "Total"
            );

          const totalsSections: TotalsSection[] = [];

          // Line items

          const lateFee = invoice.chargedFees?.lateFee?.amount || 0;
          const lineItemsAmount = calculateLineItemsTotal(invoice.lineItems);
          const lineItemsSum = lineItemsAmount + lateFee;

          const totalsLineItemsSection = getLineItemsTotals(
            invoice.lineItems,
            is1099Flow,
            invoice.currency
          );

          if (lateFee > 0) {
            totalsLineItemsSection.items.push([
              "Late fee",
              toWSMoneyString(lateFee, "default", invoice.currency)
            ]);
          }

          totalsSections.push(totalsLineItemsSection);

          // Fees & collaborator payments

          const creditCardFee =
            invoice.creditFeeHandling?.memberPays === 100
              ? (invoice.processingFees?.creditCardFee?.amount || 0) +
                (invoice.amountDetails?.wingspanTopUp || 0)
              : 0;

          const instantPayoutFee = invoice.processingFees?.instantPayoutFee
            ?.amount
            ? invoice.processingFees.instantPayoutFee.amount
            : invoice.status !== InvoiceStatus.Paid &&
              payoutPreferences === PayoutPreferences.Instant
            ? instantPayoutService.calculateInvoiceInstantPayoutFee(
                lineItemsSum,
                payoutSettings
              )
            : 0;

          const collaboratorPayments = invoice.amountDetails
            ?.collaboratorPayments
            ? invoice.amountDetails.collaboratorPayments
            : invoice.collaborators
            ? invoice.collaborators.reduce(
                (total, collaborator) => total + collaborator.amount,
                0
              )
            : 0;

          const grossIncome =
            lineItemsSum -
            creditCardFee -
            instantPayoutFee -
            collaboratorPayments;

          if (
            creditCardFee > 0 ||
            instantPayoutFee > 0 ||
            collaboratorPayments > 0
          ) {
            const totalsFeesSection: TotalsSection = {
              items: [
                {
                  bold: true,
                  left: totalElement,
                  right: toWSMoneyString(
                    lineItemsSum,
                    "default",
                    invoice.currency
                  )
                }
              ]
            };

            if (creditCardFee) {
              totalsFeesSection.items.push([
                "Credit card fee",
                toWSMoneyString(-creditCardFee, "default", invoice.currency)
              ]);
            }

            if (instantPayoutFee) {
              if (invoice.processingFees?.instantPayoutFee?.amount) {
                totalsFeesSection.items.push([
                  `Instant payout fee (${
                    (invoice.processingFees.instantPayoutFee.percentage || 0) *
                    100
                  }%)`,
                  toWSMoneyString(
                    -invoice.processingFees.instantPayoutFee.amount,
                    "default",
                    invoice.currency
                  )
                ]);
              } else {
                totalsFeesSection.items.push([
                  `Instant payout fee (${instantPayoutDefaultFee}%)`,
                  toWSMoneyString(
                    -instantPayoutFee,
                    "default",
                    invoice.currency
                  )
                ]);
              }
            }

            if (collaboratorPayments) {
              totalsFeesSection.items.push([
                "Payment to contractors",
                toWSMoneyString(
                  -collaboratorPayments,
                  "default",
                  invoice.currency
                )
              ]);
            }

            totalsSections.push(totalsFeesSection);
          }

          let netIncome = grossIncome;

          // Tax withholdings & deductions
          const { amount: taxWithholdingAmount, rate: taxWithholdingRate } =
            getInvoiceTaxWithholdings(member, invoice, netIncome);
          netIncome -= taxWithholdingAmount;

          const deductions = invoice.deductions || [];
          deductions.forEach(deduction => {
            netIncome -= deduction.amount;
          });

          if (deductions.length > 0 || taxWithholdingAmount > 0) {
            const section: TotalsSection = {
              items: [
                {
                  bold: true,
                  left:
                    grossIncome === lineItemsSum
                      ? totalElement
                      : "Gross income",
                  right: toWSMoneyString(
                    grossIncome,
                    "default",
                    invoice.currency
                  )
                },
                ...deductions.map(d => ({
                  left: d.name,
                  right: toWSMoneyString(-d.amount, "default", invoice.currency)
                }))
              ]
            };

            if (taxWithholdingAmount > 0) {
              section.items.push({
                left: `Tax withholdings (${taxWithholdingRate}%)`,
                right: toWSMoneyString(
                  -taxWithholdingAmount,
                  "default",
                  invoice.currency
                )
              });
            }

            totalsSections.push(section);
          }

          if (invoice.events.markedPaidAt) {
            // Recorded total

            totalsSections.push({
              items: [
                {
                  bold: true,
                  left: "Recorded total",
                  right: toWSMoneyString(netIncome, "default", invoice.currency)
                }
              ]
            });
          } else {
            // Payout destinations

            const payoutDestinationsSection: TotalsSection = {
              items: [
                {
                  bold: true,
                  left: netIncome === grossIncome ? totalElement : "Net income",
                  right: toWSMoneyString(netIncome, "default", invoice.currency)
                }
              ]
            };

            if (
              invoice.payoutDestinations &&
              invoice.payoutDestinations.length > 0
            ) {
              invoice.payoutDestinations.forEach(payoutDestination => {
                const item = payoutDestinationToTotals(
                  payoutDestination,
                  invoice,
                  invoice.events.instantPayoutAt
                );

                if (item) {
                  payoutDestinationsSection.items.push(item);
                }
              });
            } else {
              if (payoutPreferences === PayoutPreferences.Instant) {
                payoutMethods.instant.active.forEach(payoutMethod => {
                  payoutDestinationsSection.items.push([
                    getPayoutMethodName(payoutMethod),
                    toWSMoneyString(
                      (netIncome * (payoutMethod.percentage || 0)) / 100,
                      "default",
                      invoice.currency
                    )
                  ]);
                });
              } else {
                payoutMethods.standard.active.forEach(payoutMethod => {
                  payoutDestinationsSection.items.push([
                    getPayoutMethodName(payoutMethod),
                    toWSMoneyString(
                      (netIncome * (payoutMethod.percentage || 0)) / 100,
                      "default",
                      invoice.currency
                    )
                  ]);
                });
              }
            }

            totalsSections.push(payoutDestinationsSection);
          }

          return <Totals sections={totalsSections} />;
        }}
      </WSQueries>
    </WSElement>
  );
};

function payoutDestinationToTotals(
  payoutDestination: IInvoicePayoutDestination,
  invoice: IInvoice,
  instantPayoutAt?: Date
): TotalsSectionItem | undefined {
  if (
    payoutDestination.destinationType === InvoicePayoutDestinationType.Payout
  ) {
    if (!!instantPayoutAt) {
      return;
    }

    return [
      "Payout",
      toWSMoneyString(payoutDestination.amount, "default", invoice.currency)
    ];
  }

  if (
    payoutDestination.destinationType === InvoicePayoutDestinationType.WeGift
  ) {
    return [
      <WSButton.Link
        rightIcon="arrow-right"
        onClick={() => {
          if (payoutDestination.destinationId)
            openInNewTab(payoutDestination.destinationId);
        }}
      >
        Redeem gift card
      </WSButton.Link>,
      null
    ];
  }

  return [
    <WSFlexBox alignItems="center">
      <WSText color="gray500">
        {getInvoicePayoutDestinationDescription(payoutDestination)}
      </WSText>
      {payoutDestination.payoutMethod === PayoutPreferences.Instant && (
        <WSIcon block name="magic" ml="M" />
      )}
    </WSFlexBox>,
    toWSMoneyString(payoutDestination.amount, "default", invoice.currency)
  ];
}
