import { useHistory } from "react-router-dom";
import { FlowSetup } from "../../../../shared/components/FlowSetup";
import {
  TAX_FILING_1099_FILING_DASHBOARD_PATH,
  TAX_FILING_1099_FILING_SUBMISSION_PATH
} from "../../paths";
import { RouteEditPayerInformation } from "./routes/RouteEditPayerInformation";
import { RouteEditStateFiling } from "./routes/RouteEditStateFiling";
import { RouteNewSubmitting } from "./routes/RouteNewSubmitting";
import { RouteSubmit } from "./routes/RouteSubmit";
import { RouteSubmitting } from "./routes/RouteSubmitting";
import { RouteSubmittingError } from "./routes/RouteSubmittingError";
import { RouteSubmittingSuccess } from "./routes/RouteSubmittingSuccess";
import { RouteSubmittingPartiallySuccess } from "./routes/RouteSubmittingPartiallySuccess";

export const RouteSubmission: React.FC = () => {
  const history = useHistory();

  const basePath = TAX_FILING_1099_FILING_SUBMISSION_PATH;
  const onBack = () => {
    history.push(TAX_FILING_1099_FILING_DASHBOARD_PATH);
  };

  return (
    <FlowSetup
      basePath={basePath}
      routes={[
        {
          path: "/submit",
          component: RouteSubmit,
          onBack,
          onNext: () => {
            history.push(basePath + `/new-submitting`);
          }
        },
        {
          path: "/edit-payer-information",
          component: RouteEditPayerInformation,
          onBack: () => {
            history.push(basePath + "/submit");
          },
          onNext: context => {
            history.push(basePath + "/submit");
          }
        },
        {
          path: "/edit-state-filing",
          component: RouteEditStateFiling,
          onBack: () => {
            history.push(basePath + "/submit");
          },
          onNext: context => {
            history.push(basePath + "/submit");
          }
        },
        {
          path: "/new-submitting",
          component: RouteNewSubmitting,
          onBack,
          onNext: props => {
            history.push(basePath + `/submitting/${props?.bulkBatchId}`);
          }
        },
        {
          path: "/submitting/:bulkBatchId",
          component: RouteSubmitting,
          exact: true,
          onBack,
          onNext: context => {
            if (context?.status === "Success") {
              history.push(
                basePath + `/submitting-success/${context?.bulkBatchId}`
              );
            } else if (context?.status === "PartialSuccess") {
              history.push(
                basePath +
                  `/submitting-partially-success/${context?.bulkBatchId}`
              );
            } else {
              history.push(
                basePath + `/submitting-error/${context?.bulkBatchId}`
              );
            }
          }
        },
        {
          path: "/submitting-error/:bulkBatchId",
          component: RouteSubmittingError,
          exact: true,
          onBack,
          onNext: context => {
            history.push(basePath + `/new-submitting`);
          }
        },
        {
          path: "/submitting-partially-success/:bulkBatchId",
          component: RouteSubmittingPartiallySuccess,
          exact: true,
          onBack,
          onNext: context => {
            history.push(basePath + `/new-submitting`);
          }
        },
        {
          path: "/submitting-success/:bulkBatchId",
          component: RouteSubmittingSuccess,
          exact: true,
          onBack,
          onNext: () => {
            onBack();
          }
        }
      ]}
    />
  );
};
