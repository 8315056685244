import {
  WSActions,
  WSCard,
  WSEmptyState,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { WSQueries } from "../../../../../query/WSQuery";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { useBulkCalculate1099BatchSummary } from "../../../../BulkImporter/query/bulkCalculation1099/queries";

import { formatNumber } from "accounting";
import { Layout } from "../../../../../shared/components/Layout";
import { openIntercom } from "../../../../../shared/utils/intercom";
import { TAX_FILING_1099_FILING_DASHBOARD_PATH } from "../../../paths";

export const RouteSubmittingError: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const queryBulkCalculate1099Summary =
    useBulkCalculate1099BatchSummary(bulkBatchId);

  return (
    <Layout title="1099-NEC Filing">
      <WSQueries
        queries={{
          queryBulkCalculate1099Summary
        }}
      >
        {({ queryBulkCalculate1099SummaryData }) => {
          const failed = queryBulkCalculate1099SummaryData.failedTaxForms;

          return (
            <>
              <WSList gap="2XL">
                <WSCard>
                  <WSEmptyState
                    type="error"
                    theme="garnet"
                    orientation="horizontal"
                    title="There was an error submitting the tax forms to the IRS"
                    description={
                      <>
                        There was an error submitting {formatNumber(failed)} tax
                        forms to the IRS. Try again and if the issue persists,
                        please <a onClick={openIntercom}>contact support</a>.
                      </>
                    }
                    buttons={[
                      {
                        label: "Try again",
                        kind: "Secondary",
                        onClick: onNext
                      }
                    ]}
                  />
                </WSCard>
                <WSActions
                  alignment="fill"
                  buttons={[
                    {
                      label: "Go to dashboard",
                      onClick: () => {
                        history.push(TAX_FILING_1099_FILING_DASHBOARD_PATH);
                      }
                    }
                  ]}
                />
              </WSList>
            </>
          );
        }}
      </WSQueries>
    </Layout>
  );
};
