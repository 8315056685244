import { usePayeeTaxForms } from "../../../query/taxForm/queries/usePayeeTaxForms";
import { useMemo } from "react";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useUserId } from "../../../query/hooks/helpers";
import { useAuthorizedScopeGroups } from "../../../shared/utils/teamUtils";
import { useFocusedFeatures } from "../../../FocusedFeatures";
import { CURRENT_YEAR } from "../../TaxFiling/constants/currentYear";

export const useIsTaxDocumentsExist = (year: number) => {
  const userId = useUserId();
  const featureFlagsQuery = useFeatureFlags();
  const focusedFeatures = useFocusedFeatures();
  const { hasAdminScope } = useAuthorizedScopeGroups();

  const queryPayerTaxForms = usePayeeTaxForms(year || CURRENT_YEAR, {
    forceFetchOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false
  });

  const isPayeeOnTaxForms = useMemo(() => {
    if (year === 2023) {
      return (
        hasAdminScope &&
        featureFlagsQuery.data?.nec1099RecipientsTaxDocuments &&
        queryPayerTaxForms.data &&
        queryPayerTaxForms.data.length > 0 &&
        queryPayerTaxForms.data.some(taxForm => taxForm.memberId === userId)
      );
    } else {
      return (
        focusedFeatures.showSideNavTaxDocumentsSettings &&
        hasAdminScope &&
        featureFlagsQuery.data?.taxDocumentsSeason2024List &&
        queryPayerTaxForms.data?.length
      );
    }
  }, [
    queryPayerTaxForms.data,
    featureFlagsQuery.data,
    userId,
    year,
    focusedFeatures
  ]);

  return !!isPayeeOnTaxForms;
};
