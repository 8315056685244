import { WSInfoBox, WSPanel, WSText } from "@wingspanhq/fe-component-library";
import { TextBulleted } from "../../../../shared/components/TextBulleted";

export type AreYouReadyForCorrectionProps = {
  isType1Correction: boolean;
  amountChanges: boolean;
  tinAddressChanges: boolean;
};

export const AreYouReadyForCorrection: React.FC<
  AreYouReadyForCorrectionProps
> = ({ isType1Correction, amountChanges, tinAddressChanges }) => {
  return (
    <WSPanel>
      <WSText.ParagraphSm weight="medium" mb="S" color="gray600">
        Are you ready to submit the correction?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm mb="S" color="gray500">
        If you click submit, this correction will be sent to the IRS. You cannot
        undo this action. If needed, another correction can be filed after the
        IRS accepts this one.
      </WSText.ParagraphSm>
      <WSInfoBox>
        <WSText.ParagraphSm mb="S" weight="medium" color="gray600">
          What to expect:
        </WSText.ParagraphSm>
        <TextBulleted color="gray500">
          {isType1Correction ? (
            <li>
              One (1) updated form will be filed to the IRS based on this
              correction
            </li>
          ) : (
            <li>
              Two (2) updated forms will be filed to the IRS based on these
              corrections
              <TextBulleted color="gray500" mt="M">
                <li>First (1st) updated form to zero out the incorrect form</li>
                <li>Second (2nd) updated form with new recipient info</li>
                {amountChanges && (
                  <li>
                    Changes to the amount are associated with the tax form only
                    and will not be updated on the contractor profile unless
                    that data is updated through bulk upload for past payables
                    by you.
                  </li>
                )}
                {tinAddressChanges && (
                  <li>
                    Changes to the Taxpayer Identification Number (EIN or SSN),
                    name, tax classification, or address - are associated with
                    the tax form only and will not be updated on the contractor
                    profile unless the W-9 information is updated and certified
                    by the contractor themselves (or you, the payer on their
                    behalf) to ensure up-to-date information for both parties.
                  </li>
                )}
              </TextBulleted>
            </li>
          )}
          <li>
            The updated {isType1Correction ? "1099" : "1099s"}, once submitted,
            will be delivered immediately to the recipient by mail or
            electronically based on their preference
          </li>
        </TextBulleted>
      </WSInfoBox>
    </WSPanel>
  );
};
