import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { useRouteMatch } from "react-router-dom";
import { useBulkCalculate1099BatchSummary } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import { EditLayout } from "../../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import {
  WSButton,
  WSButtons,
  WSFlexBox,
  WSIcon,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";

import { openIntercom } from "../../../../../shared/utils/intercom";

export const RouteSubmittingError: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const queryBulkCalculate1099Summary =
    useBulkCalculate1099BatchSummary(bulkBatchId);

  return (
    <EditLayout title="1099-NEC Filing" noBack>
      <WSQueries
        queries={{
          queryBulkCalculate1099Summary
        }}
      >
        {({ queryBulkCalculate1099SummaryData }) => {
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;
          const failed = queryBulkCalculate1099SummaryData.failedTaxForms;

          return (
            <>
              <WSFlexBox.CenterY mb="2XL"></WSFlexBox.CenterY>

              <WSText.Heading4 mb="XL">
                There was an error submitting {failed} tax forms
              </WSText.Heading4>

              <WSText weight="medium" mb="XL">
                Submission summary
              </WSText>

              <WSText.ParagraphSm>
                <WSIcon name="check-double" color="green500" mr="M" />
                {processed} tax forms submitted successfully
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mt="M">
                <WSIcon name="alert-circle" color="red500" mr="M" />
                {failed} tax forms not submitted
              </WSText.ParagraphSm>

              <WSMessageBox.Error
                mt="XL"
                noBorder
                title={`There was an error submitting ${failed} tax forms to the IRS for filing`}
              >
                Try again and if the issue persists, please{" "}
                <WSButton.Link mx="XS" onClick={openIntercom}>
                  contact support
                </WSButton.Link>
                .
              </WSMessageBox.Error>

              <WSButtons forceFullWidth mt="XL">
                <WSButton.Primary fullWidth onClick={onNext}>
                  Try again
                </WSButton.Primary>
                <WSButton.Tertiary onClick={onBack}>
                  Back to dashboard
                </WSButton.Tertiary>
              </WSButtons>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
