import {
  WSElement,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";

import { OriginalValuePreview } from "../OriginalValuePreview";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import { CORRECTION_TIN_OR_NAME } from "../../constants/corrections";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  PayeeCorrectionReason,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { selectorNo1099CompanyStructure } from "../../../../shared/selectors/selectorNo1099CompanyStructure";
import { selectorTaxClassificationLabel } from "../../../../shared/selectors/selectorTaxClassificationLabel";
import { TextBulleted } from "../../../../shared/components/TextBulleted";

export interface TinData {
  ssn: string;
  ssnLastFour?: string;
  firstName?: string;
  lastName?: string;

  ein: string;
  einLastFour?: string;
  businessName: string;
  disregardedEntityName?: string;
  taxClassification: CompanyStructure;

  tinType?: TinType;
}

export interface TINOrNameCorrectionDiffPreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  isVoidType?: boolean;
  isForce?: boolean;
  onEdit?: () => void;
  originalTinData: TinData;
  correctedTinData: TinData;
  reasonForChange: string;
  otherReason?: string;
  identificationNumberType: TinType;
  showRequestedValue?: boolean;
  showRejectOptionText?: boolean;
  hasOtherEligibleCorrectionChanges?: boolean;
  noPanel?: boolean;
}

export const TINOrNameCorrectionDiffPreview: React.FC<
  TINOrNameCorrectionDiffPreviewProps
> = ({
  taxForm,
  taxFormViewer = TaxFormViewer.Payee,
  isVoidType,
  isForce,
  originalTinData,
  correctedTinData,
  reasonForChange,
  otherReason,
  onEdit,
  identificationNumberType,
  showRequestedValue = true,
  showRejectOptionText,
  hasOtherEligibleCorrectionChanges,
  noPanel
}) => {
  return (
    <WSPanel noBorder={noPanel} p={noPanel ? "NONE" : "M"}>
      <WSText.Paragraph color="gray700" weight="medium" mb="XL">
        Taxpayer Identification Number (TIN) or name correction
      </WSText.Paragraph>

      <OriginalValuePreview
        taxForm={taxForm}
        correctionType={CORRECTION_TIN_OR_NAME}
      />

      <WSText.ParagraphSm mb="XL" color="gray500">
        The Taxpayer Identification Number (SSN or EIN) or name is incorrect.
      </WSText.ParagraphSm>

      {showRequestedValue ? (
        <WSElement mb="XL">
          <CorrectedValuePreview
            correctionType={CORRECTION_TIN_OR_NAME}
            taxForm={taxForm}
            taxFormViewer={taxFormViewer}
            onEdit={onEdit}
            correctionData={{
              tinOrNameCorrectionData: {
                ...correctedTinData,
                reasonForChange: reasonForChange as PayeeCorrectionReason,
                otherReason
              }
            }}
          />

          {/* will be shown only when isVoidType is set in RouteSubmitCorrection */}
          {isVoidType === true ? (
            <WSMessageBox.Info noBorder mt="L">
              <WSText.ParagraphSm weight="medium" mb="S" color="gray600">
                Note:
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mb="M" color="gray500">
                As a{" "}
                {selectorTaxClassificationLabel(
                  correctedTinData.taxClassification
                )}
                , the recipient is not eligible for a 1099 according to IRS
                guidelines. Please verify recipient information is accurate
                before proceeding.
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="medium" mb="M" color="gray600">
                You are choosing to submit with the following option:
                <TextBulleted ml="S" color="gray500">
                  {isForce ? (
                    <li>
                      Continue to submit a correction with only the eligible
                      changes <br />
                      (ignore the change to an ineligible tax classification)
                    </li>
                  ) : (
                    <li>
                      Continue to submit a correction to void the original
                      filing
                    </li>
                  )}
                </TextBulleted>
              </WSText.ParagraphSm>
            </WSMessageBox.Info>
          ) : taxFormViewer === TaxFormViewer.Payer &&
            originalTinData.taxClassification !==
              correctedTinData.taxClassification &&
            selectorNo1099CompanyStructure(
              correctedTinData.taxClassification
            ) ? (
            <WSMessageBox.Info noBorder mt="S">
              <WSText.ParagraphSm weight="medium" mb="S" color="gray600">
                Note:
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mb="M" color="gray500">
                As a{" "}
                {selectorTaxClassificationLabel(
                  correctedTinData.taxClassification
                )}
                , the recipient is not eligible for a 1099 according to IRS
                guidelines. Please verify recipient information is accurate
                before proceeding.
              </WSText.ParagraphSm>

              <WSText.ParagraphSm weight="medium" mb="M" color="gray600">
                You have the following options:
                <TextBulleted ml="S" color="gray500">
                  {showRejectOptionText ? (
                    <li>Reject the correction (original filing remains)</li>
                  ) : null}
                  <li>
                    Continue to submit a correction to void the original filing
                  </li>
                  {hasOtherEligibleCorrectionChanges ? (
                    <li>
                      Continue to submit a correction with only the eligible
                      changes <br />
                      (ignore the change to an ineligible tax classification)
                    </li>
                  ) : null}
                </TextBulleted>
              </WSText.ParagraphSm>
            </WSMessageBox.Info>
          ) : null}
        </WSElement>
      ) : null}
    </WSPanel>
  );
};
