import {
  WSActions,
  WSAlert,
  WSControl,
  WSControlGroup,
  WSDivider,
  WSElement,
  WSForm,
  WSFormProps,
  WSInfoBox,
  WSInputMask,
  WSInputText,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import merge from "lodash/merge";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { US_STATES_OPTIONS } from "../../../../constants/user";
import { WSFormErrorSummary } from "../../../../shared/components/WSFormErrorSummary";
import {
  SELECT_OPTIONS_CANADIAN_PROVINCES,
  SELECT_OPTIONS_YEAR
} from "../../../../shared/constants/user";
import { getInternationalTaxIdInfo } from "../../../../shared/utils/internationalTaxId";
import { WS_LINKS } from "../../../../types/wsLinks";
import {
  EmployersNumber,
  FederalTaxClassification,
  Industry
} from "../../types";
import { canadaQuebecEnterpriseNumberProps } from "../../utils/canadaQuebecEnterpriseNumber";
import { industrySelectOptions } from "../../utils/industry";
import { getTaxIdLabel, getTaxIdMask } from "../../utils/taxId";
import { FormDataAddress, FormPartialAddress } from "../FormPartialAddress";
import {
  FormDataPhoneNumber,
  FormPartialPhoneNumber
} from "../FormPartialPhoneNumber";
import { useModalWhyWeCollectInfo } from "../ModalWhyWeCollectInfo";
import { emptyValues } from "./emptyValues";
import { getValidationSchema } from "./validationSchema";
import { FormPartialFederalTaxClassification } from "../../../../shared/components/FormPartialFederalTaxClassification";

export type FormDataBusinessInformation = {
  legalBusinessName: string;
  dba: string;
  taxClassification: FederalTaxClassification | null;
  taxId: string;
  industry: Industry | null;
  email: string;
  usePersonalEmail: boolean;
  phone: FormDataPhoneNumber;
  website: string;
  noWebsite: boolean;
  numberOfEmployees: EmployersNumber;
  address: FormDataAddress;
  canadaQuebecEnterpriseNumber: string;
  stateOfIncorporation: string | null;
  yearOfIncorporation: string | null;
};

type Props = {
  defaultValues?: WSFormProps<FormDataBusinessInformation>["defaultValues"];
  onSubmit: WSFormProps<FormDataBusinessInformation>["onSubmit"];
  country: string;
  onBack?: () => void;
  show1099Message?: boolean;
  personalEmail?: string;
  mismatch?: boolean;
};

export const FormBusinessInformation: React.FC<Props> = ({
  defaultValues,
  country,
  onBack,
  onSubmit,
  show1099Message,
  personalEmail,
  mismatch
}) => {
  const modal = useModalWhyWeCollectInfo();
  const taxIdInfo = getInternationalTaxIdInfo(country, "Business");

  const mismatchProps = mismatch
    ? ({
        status: "warning",
        message: "Mismatched info"
      } as const)
    : {};

  return (
    <WSForm<FormDataBusinessInformation>
      defaultValues={merge(emptyValues, defaultValues)}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(country)}
    >
      <WSList gap="2XL" mb="2XL">
        {mismatch && (
          <WSAlert
            icon="alert"
            theme="warning"
            title="IRS TIN Mismatch Detected"
          >
            <WSInfoBox transparent>
              <p>
                The information you provided does not match IRS records. Please
                review and update:
              </p>
              <ul>
                <li>Legal Business Name</li>
                <li>Employer Identification Number (EIN)</li>
              </ul>
              <p>To ensure accuracy:</p>
              <ol>
                <li>Double-check for typos or transposed numbers</li>
                <li>
                  Verify your name/business name exactly matches IRS records
                </li>
                <li>
                  For recent name changes, ensure you've updated the Social
                  Security Administration or IRS
                </li>
                <li>
                  Confirm you're using the correct TIN type (SSN vs EIN) for
                  your situation
                </li>
              </ol>
            </WSInfoBox>
          </WSAlert>
        )}

        <WSPanel>
          <WSSectionToolbar
            title="Business information"
            button={{
              label: "Why do we collect this info?",
              onClick: modal.open
            }}
          />
          <WSText.ParagraphSm color="gray500">
            Wingspan is required to collect this information for compliance
            purposes. Your information is{" "}
            <a href={WS_LINKS.security} target="_blank" rel="noreferrer">
              certified secure
            </a>
            .
          </WSText.ParagraphSm>

          <WSDivider my="L" />

          <WSList gap="XL">
            <WSForm.Field
              name="legalBusinessName"
              label="Legal business name"
              component={WSInputText}
              componentProps={{
                required: true,
                placeholder: "Enter business name",
                fsExclude: true,
                helperText:
                  "The official registered name of the entity when it was formed",
                ...mismatchProps
              }}
            />

            <WSForm.Field
              name="dba"
              label="Doing business as (DBA) (optional)"
              component={WSInputText}
              componentProps={{
                placeholder: "Enter DBA",
                fsExclude: true,
                helperText:
                  "Additional name under which the business operates and recognized in the marketplace"
              }}
            />

            <FormPartialFederalTaxClassification
              name="taxClassification"
              hidden={country !== "US"}
              show1099Message={show1099Message}
              helperText="Business type"
            />

            {country === "US" || country === "CA" ? (
              <WSForm.Field
                key={country + "-tax-id"}
                name="taxId"
                label={getTaxIdLabel("Business", country)}
                component={WSInputMask}
                componentProps={{
                  required: true,
                  placeholder: getTaxIdMask("Business", country),
                  mask: getTaxIdMask("Business", country),
                  unmask: true,
                  fsExclude: true,
                  ...mismatchProps
                }}
              />
            ) : (
              <WSForm.Field
                key={country + "-tax-id"}
                name="taxId"
                label={taxIdInfo.name}
                component={WSInputText}
                componentProps={{
                  required: true,
                  fsExclude: true,
                  placeholder: taxIdInfo.placeholder,
                  ...mismatchProps
                }}
              />
            )}

            <WSForm.Field
              name="industry"
              label="Industry"
              component={WSSelect}
              componentProps={{
                required: true,
                helperText:
                  "If you don't see your industry, pick the one that matches best",
                options: industrySelectOptions
              }}
            />

            <WSElement>
              <WSForm.Value name="usePersonalEmail">
                {usePersonalEmail => (
                  <WSForm.Field
                    mb="S"
                    name="email"
                    label="Business email"
                    component={WSInputText}
                    componentProps={{
                      placeholder: "Enter business email",
                      required: true,
                      autoComplete: "email",
                      helperText:
                        "A public facing email to contact your business",
                      disabled: usePersonalEmail
                    }}
                  />
                )}
              </WSForm.Value>
              <WSForm.Field
                name="usePersonalEmail"
                component={WSControl}
                componentProps={{
                  type: "checkbox",
                  size: "S",
                  label: `Use my email (${personalEmail})`
                }}
              />
            </WSElement>

            <FormPartialPhoneNumber
              name="phone"
              label="Business phone number"
              required
            />

            <WSElement>
              <WSForm.Value name="noWebsite">
                {noWebsite => (
                  <WSForm.Field
                    mb="S"
                    name="website"
                    label="Business website (optional)"
                    component={WSInputText}
                    componentProps={{
                      disabled: noWebsite,
                      autoComplete: "url",
                      placeholder: "http://"
                    }}
                  />
                )}
              </WSForm.Value>

              <WSForm.Field
                name="noWebsite"
                component={WSControl}
                componentProps={{
                  type: "checkbox",
                  size: "S",
                  label: "The business does not have a website"
                }}
              />
            </WSElement>

            <WSForm.Field
              name="numberOfEmployees"
              component={WSControlGroup}
              label="How many employees do you have?"
              componentProps={{
                required: true,
                type: "radio",
                options: [
                  {
                    label: "None",
                    value: "None"
                  },
                  {
                    label: "1-10",
                    value: "1To10"
                  },
                  {
                    label: "11-50",
                    value: "11To50"
                  },
                  {
                    label: "51-250",
                    value: "51To250"
                  },
                  {
                    label: "Over 250",
                    value: "Over250"
                  }
                ]
              }}
            />
          </WSList>
        </WSPanel>

        <WSPanel>
          <WSList gap="XL">
            <FormPartialAddress
              country={country}
              name="address"
              label="Business address"
            />
            {/* Enhanced handle of address changes */}
            <AddressWatcher />

            <WSForm.Values names={["address.state"]}>
              {values => {
                const state = values["address.state"];

                const isNeqVisible = country === "CA" && state === "QC";

                return (
                  <WSForm.Field
                    hidden={!isNeqVisible}
                    name="canadaQuebecEnterpriseNumber"
                    label="Québec Enterprise Number (NEQ)"
                    component={WSInputMask}
                    componentProps={{
                      ...canadaQuebecEnterpriseNumberProps,
                      required: true
                    }}
                  />
                );
              }}
            </WSForm.Values>

            {country === "US" ? (
              <WSForm.Field
                key="US-state-of-incorporation"
                name="stateOfIncorporation"
                label="State/Territory of incorporation"
                component={WSSelect}
                componentProps={{
                  required: true,
                  internalSearch: true,
                  options: US_STATES_OPTIONS
                }}
              />
            ) : country === "CA" ? (
              <WSForm.Field
                key="CA-state-of-incorporation"
                name="stateOfIncorporation"
                label="Province of incorporation"
                component={WSSelect}
                componentProps={{
                  required: true,
                  internalSearch: true,
                  options: SELECT_OPTIONS_CANADIAN_PROVINCES
                }}
              />
            ) : null}

            {country === "CA" ? (
              <WSForm.Field
                name="yearOfIncorporation"
                label="Year of incorporation"
                component={WSSelect}
                componentProps={{
                  required: true,
                  options: SELECT_OPTIONS_YEAR
                }}
              />
            ) : null}
          </WSList>
        </WSPanel>
        <WSFormErrorSummary />
        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              onClick: onBack,
              kind: "Secondary",
              type: "button",
              visible: !!onBack
            },
            {
              label: "Continue"
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};

const AddressWatcher = () => {
  const { watch, setValue } = useFormContext();

  const state = watch("address.state");
  const stateOfIncorporation = watch("stateOfIncorporation");
  const prevStateOfIncorporation = useRef<string | null>(null);

  useEffect(() => {
    if (state && !stateOfIncorporation && !prevStateOfIncorporation.current) {
      setValue("stateOfIncorporation", state);
    }

    prevStateOfIncorporation.current = state;
  }, [setValue, state, stateOfIncorporation]);

  return null;
};
