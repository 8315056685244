import classNames from "classnames";
import React, { ReactNode, useMemo } from "react";
import { toWSDateString } from "../../../utils";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import { WSAvatar, WSAvatarProps } from "../common";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSTimeline.module.scss";

export type WSTimelineProps = {
  items: WSTimelineItemProps[];
} & WSElementProps;

export type WSTimelineItemProps = {
  date?: Date;
  content: ReactNode;
  badges?: string[];
  avatar?: WSAvatarProps;
};

export const WSTimeline: React.FC<WSTimelineProps> = ({
  items,
  className,
  ...elementProps
}) => {
  return (
    <WSElement
      className={classNames(styles.timeline, className)}
      {...elementProps}
    >
      {items.map((item, index) => (
        <WSTimelineItem key={String(index)} {...item} />
      ))}
    </WSElement>
  );
};

function getAvatarProps(props: WSTimelineItemProps): WSAvatarProps | undefined {
  const baseProps: Partial<WSAvatarProps> = {
    format: "circle",
    size: "S",
    colorBackground: "gray400"
  };

  if (!props.avatar) {
    return {
      ...baseProps,
      type: "icon",
      icon: "info-circle"
    };
  }

  return {
    ...baseProps,
    ...props.avatar
  };
}

const WSTimelineItem: React.FC<WSTimelineItemProps> = (props) => {
  const avatarProps = getAvatarProps(props);

  const badges = useMemo(() => {
    const badges = props.badges || [];

    if (props.date) {
      badges.push(toWSDateString(props.date, "monthDayYear"));
    }

    return badges;
  }, [props.badges, props.date]);

  return (
    <WSElement className={styles.item}>
      {avatarProps && <WSAvatar className={styles.avatar} {...avatarProps} />}

      <WSElement className={styles.card}>
        {badges.length > 0 && (
          <WSElement className={styles.badges}>
            {badges.map((badge) => (
              <WSText.ParagraphSm
                key={badge}
                className={styles.badge}
                color="gray400"
                singleLine
              >
                {badge}
              </WSText.ParagraphSm>
            ))}
          </WSElement>
        )}

        <WSElement className={styles.content}>
          {typeof props.content === "string" ? (
            <WSText.ParagraphSm>{props.content}</WSText.ParagraphSm>
          ) : (
            props.content
          )}
        </WSElement>
      </WSElement>
    </WSElement>
  );
};
