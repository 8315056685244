import {
  WSButton,
  WSControl,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSForm,
  WSInputPassword,
  WSInputText,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { validatorPassword } from "../../../../shared/validators/validatorPassword";
import { InvitationAuthPrivacyPolicyInfoBlock } from "../InvitationAuthPrivacyPolicyInfoBlock";
import { GoogleSignIn } from "../GoogleSignIn";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { IInvitationResponse } from "@wingspanhq/users";
import { useNotYourEmailPayer } from "../../modals/useNotYourEmailPayer";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { useNotYourEmailPayee } from "../../modals/useNotYourEmailPayee";
import { INVITATION_AUTH_SUCCESS_PATH } from "../../index";
import { IInvitation } from "@wingspanhq/users/dist/lib/interfaces";
import {
  useCreateSession,
  useIdentifyAuthentication
} from "../../../../query/users/mutations";
import { useMutationUpdateAcknowledgement } from "../../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { operations } from "../../../../services/api/onboarding/types";
import { acknowledgementVersions } from "../../../../shared/constants/acknowledgements";
import { useQueryAcknowledgement } from "../../../../query/onboarding/queries/useQueryAcknowledgement";
import { KEY_REDIRECT_PATH } from "../../../../Auth/screens/AuthSSO";

type Props = {
  organizationName: string;
  invitationData: IInvitationResponse;
  invitation: IInvitation;
};

export const InvitationAuthSignInForm: React.FC<Props> = ({
  organizationName,
  invitationData,
  invitation
}) => {
  const location = useLocation();
  const [createSession, createSessionMeta] = useCreateSession();
  const [identifyAuth, identifyAuthMeta] = useIdentifyAuthentication();

  const [disabledEmail, setDisabledEmail] = React.useState(
    !!invitationData.invitee.email
  );

  const inviterName = invitationData?.inviter?.name ?? organizationName;

  const notYourEmailPayerModal = useNotYourEmailPayer();
  const notYourEmailPayeeModal = useNotYourEmailPayee();
  const history = useHistory();

  const queryWingspanTosAcceptanceAcknowledgement = useQueryAcknowledgement(
    "WingspanTosAcceptance"
  );
  const queryWingspanPrivacyPolicyAcceptanceAcknowledgement =
    useQueryAcknowledgement("WingspanPrivacyPolicyAcceptance");
  const queryElectronicDisclosureAndConsentAcknowledgement =
    useQueryAcknowledgement("ElectronicDisclosureAndConsent");

  const [updateAcknowledgement] = useMutationUpdateAcknowledgement();

  return (
    <>
      <WSList gap="XL" p="XL">
        <WSText.Paragraph weight="medium">Sign In</WSText.Paragraph>

        <WSForm
          onSubmit={async values => {
            if (invitationData.invitee.email) {
              await createSession(values);
            } else {
              const response = await identifyAuth(
                { email: values.email },
                { throwOnError: true }
              );

              if (response?.authenticationStrategy === "SingleSignOn") {
                const redirectPath = location.hash?.slice(1);
                if (redirectPath) {
                  sessionStorage.setItem(KEY_REDIRECT_PATH, redirectPath);
                }

                window.open(response?.authenticationUrl, "_self");
              } else {
                await createSession(values);
              }
            }

            // Check if the user has not accepted the below acknowledgements
            if (
              queryWingspanTosAcceptanceAcknowledgement.data
                ?.acknowledgementStatus !== "Given" ||
              queryWingspanPrivacyPolicyAcceptanceAcknowledgement.data
                ?.acknowledgementStatus !== "Given" ||
              queryElectronicDisclosureAndConsentAcknowledgement.data
                ?.acknowledgementStatus !== "Given"
            ) {
              // set the acknowledgement for the below
              // 1. WingspanTosAcceptance
              // 2. WingspanPrivacyPolicyAcceptance
              // 3. ElectronicDisclosureAndConsent
              const consents: operations["getAcknowledgement"]["parameters"]["path"]["acknowledgementName"][] =
                [
                  "WingspanTosAcceptance",
                  "WingspanPrivacyPolicyAcceptance",
                  "ElectronicDisclosureAndConsent"
                ];
              await Promise.all(
                consents.map(async consent => {
                  updateAcknowledgement({
                    acknowledgementName: consent,
                    requestData: {
                      acknowledgementName: consent,
                      acknowledgementStatus: "Given",
                      version: acknowledgementVersions[consent]
                    }
                  });
                })
              );
            }
          }}
          defaultValues={{
            email: invitationData.invitee.email || "",
            password: "",
            remember: true
          }}
          validationSchema={Yup.object().shape({
            email: validatorEmail.required("Email is required"),
            password: validatorPassword.required("Password is required"),
            remember: Yup.boolean()
          })}
        >
          <WSForm.Field
            mb="L"
            name="email"
            label="Email"
            description={`This email was provided by ${inviterName} and will be used to create your account.`}
            component={WSInputText}
            componentProps={{
              disabled: disabledEmail,
              required: true
            }}
          />
          <WSForm.Field
            mb="L"
            name="password"
            label="Password"
            component={WSInputPassword}
            componentProps={{
              required: true
            }}
          />
          <WSForm.Field
            name="remember"
            component={WSControl}
            componentProps={{
              size: "S",
              type: "checkbox",
              label: "Stay signed in"
            }}
          />

          <InvitationAuthPrivacyPolicyInfoBlock mt="XL" mb="L" />

          {createSessionMeta.error?.message === "Captcha loading error" ? (
            <WSText color="garnet" my="XL">
              Sorry, something went wrong.{" "}
              <WSButton.Link
                type="button"
                size="M"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload and try again
              </WSButton.Link>
            </WSText>
          ) : (
            <WSErrorMessage
              my="XL"
              contextKey="Auth"
              forceShowApiErrors
              error={createSessionMeta.error || identifyAuthMeta.error}
            />
          )}

          <WSForm.SubmitButton fullWidth size="M">
            Sign in
          </WSForm.SubmitButton>
        </WSForm>
      </WSList>
      <WSDivider label="or" />

      <WSElement p="XL">
        <GoogleSignIn
          invitation={invitation}
          email={invitationData.invitee.email}
          title="Sign-in with Google"
          onFailure={() => {}}
          onSuccess={() => {
            history.replace(INVITATION_AUTH_SUCCESS_PATH);
          }}
        />
      </WSElement>

      <WSDivider />

      <WSFlexBox p="XL" justify="space-between">
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onClick={() => {
            history.push("/member/reset-password");
          }}
        >
          Forgot password?
        </WSButton.Link>

        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onClick={async () => {
            let result: boolean | null = false;
            if (invitationData.context === PlatformContextType.Payer) {
              result = await notYourEmailPayerModal.open({
                email: invitationData.invitee.email,
                isSignIn: true
              });
            } else {
              result = await notYourEmailPayeeModal.open({
                email: invitationData.invitee.email,
                userId: invitationData.invitee.userId,
                userName: invitationData.invitee.name,
                payerName: invitationData.inviter?.name,
                isSignIn: true
              });
            }

            if (result) {
              setDisabledEmail(false);
            }
          }}
        >
          Not your email?
        </WSButton.Link>
      </WSFlexBox>
    </>
  );
};
