import sortBy from "lodash/sortBy";
import {
  ITaxFormCorrectionResponse,
  TaxFormCorrectionStatus
} from "@wingspanhq/payments/dist/interfaces";
import { CorrectionType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

type GetStatusCustomDescriptionParams = {
  submissions?: ITaxFormSubmissionResponse[];
  corrections?: ITaxFormCorrectionResponse[];
  currentSubmissionId: string;
};

export const getStatusCustomDescription = ({
  submissions,
  corrections,
  currentSubmissionId
}: GetStatusCustomDescriptionParams): string | undefined => {
  if (!submissions) {
    return undefined;
  }

  const orderedSubmissions = sortBy(
    submissions,
    "events.submittedAt"
  ).reverse();

  const hasMultipleSubmissions = orderedSubmissions.length > 1;

  const hasPendingCorrections = corrections?.some(
    correction => correction.status === TaxFormCorrectionStatus.Pending
  );

  let result = undefined;

  const index = orderedSubmissions.findIndex(
    s => s.taxFormSubmissionId === currentSubmissionId
  );

  const submission = orderedSubmissions[index];
  const isLatestForm = index === 0;
  const isOriginalForm = index === orderedSubmissions.length - 1;

  // If this submission is a result of a correction, points to the TaxFormCorrection
  if (submission?.correctionId) {
    result = "Correcting form";
  }

  if (submission?.correctionType === CorrectionType.Void) {
    result = "Voided form";
  }

  if (isOriginalForm) {
    result = "Original form";
  }

  if (isOriginalForm && hasMultipleSubmissions) {
    result = "Original form (superseded)";
  }

  if (hasPendingCorrections && isLatestForm) {
    result = result + " (Correction requested)";
  }

  return result;
};
