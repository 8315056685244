import { useMemo } from "react";
import { WSDataItemProps, WSGrid } from "@wingspanhq/fe-component-library";
import { DataItem } from "../../../../shared/components/DataItem";
import { formatEIN } from "../../../../shared/utils/formatEIN";
import { formatPhone } from "../../../../shared/utils/formatPhone";
import { selectorAddressString } from "../../../../shared/selectors/selectorAddressString";
import { ITaxFormPayerDataResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

type Props = {
  payerData: ITaxFormPayerDataResponse;
};

export const PayerInformation: React.FC<Props> = ({ payerData }) => {
  const fields = useMemo(() => {
    const result: WSDataItemProps[] = [
      {
        label: "Name",
        value: payerData.name
      },
      {
        label: "Taxpayer identification number",
        value: `${formatEIN(payerData.ein)} (EIN)`
      },
      {
        label: "Payer support email",
        value: payerData.supportEmail
      },
      {
        label: "Payer support phone number",
        value: payerData.supportPhoneNumber
          ? formatPhone(payerData.supportPhoneNumber)
          : undefined
      },
      {
        label: "Address",
        value: selectorAddressString(payerData)
      }
    ];

    return result;
  }, [payerData]);

  return (
    <WSGrid gutter="2XL">
      {fields.map((props, index) => (
        <WSGrid.Item
          key={`${props.label} + ${index}`}
          span={{ xs: "12", s: "6" }}
        >
          <DataItem {...props} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
