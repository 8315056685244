import { WSCard, WSInfoBox, WSList } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { DataItem } from "../../../../shared/components/DataItem";
import { usePayrollFundingMethodsManagedByOrganization } from "../../../../userFeatures/usePayrollFundingMethodsManagedByOrganization";
import { useUserOrganizationName } from "../../../../userFeatures/useUserOrganizationName";
import { PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD } from "../../routes/RoutePayroll";
import { LabelMethod } from "../LabelMethod";

export const DefaultFundingMethod: React.FC = () => {
  const userId = useUserId();
  const queryPayrollSettings = usePayrollSettings(userId);
  const history = useHistory();
  const isManagedByOrg = usePayrollFundingMethodsManagedByOrganization();
  const organizationName = useUserOrganizationName();

  return (
    <WSCard
      divider={!isManagedByOrg}
      header={{
        label: {
          text: "Payroll funding method",
          helperText: "Default method to fund contractor payroll"
        },
        value: {
          pill: {
            theme: "neutral",
            text: "Default"
          }
        }
      }}
      actions={
        isManagedByOrg
          ? undefined
          : {
              alignment: "left",
              buttons: [
                {
                  kind: "Link",
                  label: "Manage payroll funding method",
                  onClick: () => {
                    history.push(PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD);
                  }
                }
              ]
            }
      }
    >
      <WSList gap="XL">
        {queryPayrollSettings.data?.fundingSource ? (
          <LabelMethod
            type={queryPayrollSettings.data.fundingSource.fundingSourceType}
            id={queryPayrollSettings.data.fundingSource.fundingSourceId}
          />
        ) : (
          <DataItem />
        )}

        {isManagedByOrg && (
          <WSInfoBox>
            Payroll funding method is managed by {organizationName} - contact{" "}
            {organizationName} support.
          </WSInfoBox>
        )}
      </WSList>
    </WSCard>
  );
};
