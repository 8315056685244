import {
  WSAlert,
  WSControlGroup,
  WSDivider,
  WSForm,
  WSText
} from "@wingspanhq/fe-component-library";
import { CorrectionFormActions } from "../CorrectionFormActions";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../constants/corrections";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { useState } from "react";
import { CorrectionTypeOptionOriginalValue } from "./CorrectionTypeOptionOriginalValue";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

const CORRECTION_TYPES_OPTIONS = [
  {
    label: "Amount",
    description:
      "The total income reported is different than what was actually paid and needs to be corrected.",
    value: CORRECTION_AMOUNT
  },
  {
    label: "Taxpayer identification number or name",
    description:
      "The Taxpayer Identification Number (SSN or EIN) or name is incorrect.",
    value: CORRECTION_TIN_OR_NAME
  },
  {
    label: "Address",
    description: "The address has changed or is not accurate.",
    value: CORRECTION_ADDRESS
  }
];

interface SelectCorrectionTypeFormData {
  corrections: string[];
}

interface SelectCorrectionTypeFormProps {
  taxForm: IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  onContinue: (data: SelectCorrectionTypeFormData) => void;
  onCancel: () => void;
}

export const SelectCorrectionTypeForm: React.FC<
  SelectCorrectionTypeFormProps
> = ({
  taxFormViewer = TaxFormViewer.Payee,
  taxForm,
  onContinue,
  onCancel
}) => {
  const [correctionTypes, setCorrectionTypes] = useState(
    {} as Record<string, boolean>
  );

  // convert object to array with values that are true
  const selectedCorrectionTypes = Object.keys(correctionTypes).filter(
    key => correctionTypes[key]
  );

  return (
    <WSForm<{
      corrections: Record<string, boolean>;
    }>
      defaultValues={{
        corrections: {}
      }}
      onSubmit={() => {
        onContinue({ corrections: selectedCorrectionTypes });
      }}
    >
      <WSText.Paragraph color="gray700" weight="medium" mb="S">
        {taxFormViewer === TaxFormViewer.Payee
          ? "What is the error on your Form 1099-NEC? (Select all that apply)"
          : "What is the error on the recipient’s Form 1099-NEC? (Select all that apply)"}
      </WSText.Paragraph>

      <WSText.ParagraphSm color="gray500" weight="book" mb="2XL">
        Please review to ensure the information is accurate before proceeding.
      </WSText.ParagraphSm>

      {taxFormViewer === TaxFormViewer.Payee && (
        <WSAlert
          theme="info"
          icon="info-circle"
          title="Payer’s discretion to accept corrections"
          mb="XL"
        >
          After you submit a correction request, your payer will be notified of
          the request. Ultimately it is your payer’s discretion to accept the
          corrections. You will be notified when they respond to the request. If
          they accept the request, a correction will be delivered to you based
          on your delivery preferences.
        </WSAlert>
      )}
      <WSControlGroup
        name="corrections"
        type="checkbox"
        value={correctionTypes}
        onChange={setCorrectionTypes}
        chip
        options={CORRECTION_TYPES_OPTIONS.map(
          ({ label, description, value }) => ({
            label,
            value,
            description: (
              <CorrectionTypeOptionOriginalValue
                correctionType={value}
                taxForm={taxForm}
                description={description}
              />
            )
          })
        )}
      />

      <WSDivider my="2XL" />

      <CorrectionFormActions
        mb="XL"
        onCancel={onCancel}
        disabled={selectedCorrectionTypes.length === 0}
      />
    </WSForm>
  );
};
