import { useWSModal, WSForm } from "@wingspanhq/fe-component-library";
import { CountryCode } from "libphonenumber-js";
import React from "react";
import { queryCache } from "react-query";
import * as Yup from "yup";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_MEMBER_PROFILE } from "../../../../../query/users/keys";
import { useMemberProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import {
  FormDataPhoneNumber,
  FormPartialPhoneNumber,
  phoneFormDataToE164,
  phoneNumberToFormData,
  validationSchemaPhoneNumber
} from "../../../../Onboarding/components/FormPartialPhoneNumber";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { phone: FormDataPhoneNumber };

type Props = {
  onClose: () => void;
};

export const FormEditBusinessPhoneNumber: React.FC<Props> = props => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const member = await usersService.member.update(userId, {
        memberId: userId,
        profile: {
          company: {
            phoneNumber: phoneFormDataToE164(data.phone)
          }
        }
      });

      queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);
    },
    {
      dependencies: [QUERY_USERS_MEMBER_PROFILE],
      onSuccess: props.onClose
    }
  );

  return (
    <WSForm<FormData>
      onSubmit={mutate}
      validationSchema={Yup.object({
        phone: validationSchemaPhoneNumber
      })}
      defaultValues={{
        phone: queryMember.data?.profile.company?.phoneNumber
          ? phoneNumberToFormData(
              queryMember.data.profile.company.phoneNumber,
              queryMember.data.profile.address?.country as CountryCode
            )
          : undefined
      }}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        <FormPartialPhoneNumber
          label="Business phone number"
          name="phone"
          required
        />
      </LayoutFormEdit>
    </WSForm>
  );
};

export const useModalEditBusinessPhoneNumber = () =>
  useWSModal(FormEditBusinessPhoneNumber, {
    title: "Business phone number",
    size: "S"
  });
