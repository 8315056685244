import { WSAlert, WSMarginProps } from "@wingspanhq/fe-component-library";

interface Props extends WSMarginProps {
  year: number;
  onView: () => void;
}

export const AlertGenerationInProgress: React.FC<Props> = ({
  year,
  onView,
  ...otherProps
}) => {
  return (
    <WSAlert
      theme="info"
      size="M"
      icon="info-circle"
      title={`${year} tax forms generation in progress`}
      action={{
        onClick: onView,
        text: "View progress"
      }}
      {...otherProps}
    >
      Form 1099-NECs is still being generated (or updated) for all contractors
      for {year}. Depending on the volume of forms, this could take some time.
      Once the progress is complete, the forms will appear below.
    </WSAlert>
  );
};
