import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

export const getTaxFormOrganizationName = (
  taxForm: IPayerTaxFormResponse
): string | undefined => {
  if (taxForm.payer) {
    const payer = taxForm.payer as any;

    const organizationNames = wsName({
      member: payer,
      user: payer.user
    });

    return (
      organizationNames.getResolvedName() || selectorMemberName(taxForm.payer)
    );
  }
};
