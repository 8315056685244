import {
  useIsDesktop,
  useWSModal,
  WSElement,
  WSScreen
} from "@wingspanhq/fe-component-library";
import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { ArrayField, useFormContext } from "react-hook-form";
import {
  InvoicesFormStep1Values,
  useInvoicesFormContext
} from "../InvoicesForm";
import { DesktopFields } from "./DesktopFields";
import { LineItemMobilePreview } from "./LineItemMobilePreview";
import { ModalMobileForm } from "./ModalMobileForm";
import { FormDataLineItem, LineItemQuickbooksProps } from "./types";

type Props = {
  index: number;
  data: Partial<ArrayField<FormDataLineItem, "id">>;
  onRemove?: () => void;
  onRateChange: () => void;
  onCurrencyChange: () => void;
  quickbooksProps: LineItemQuickbooksProps;
  customFields: ICustomField[];
  isCurrencyConversionActive?: boolean;
  forceMobileShow?: boolean;
};

export const LineItem: React.FC<Props> = props => {
  const isDesktop = useIsDesktop();
  const { watch, setValue, getValues } =
    useFormContext<InvoicesFormStep1Values>();
  const removeValue = watch(`lineItems[${props.index}].remove`);
  const modalMobileForm = useWSModal(ModalMobileForm);
  const { invoicingConfigForPayee } = useInvoicesFormContext();

  return (
    <WSElement mb="XL" hidden={!!removeValue}>
      <WSScreen XS S M L={props.forceMobileShow} XL={props.forceMobileShow}>
        <LineItemMobilePreview
          index={props.index}
          onEdit={() => {
            const currentData = getValues();
            modalMobileForm.open({
              data: currentData.lineItems?.[props.index],
              onSubmit: values => {
                setValue(`lineItems[${props.index}]`, values);
              },
              quickbooksProps: props.quickbooksProps,
              customFields: props.customFields,
              invoicingConfigForPayee,
              isCurrencyConversionActive: props.isCurrencyConversionActive
            });
          }}
        />
      </WSScreen>

      {/* We are only hiding (but still rendering) desktop fields on mobile UX. The reason behind that is those inputs are used as a storage. Mobile form is outside of main invoice form, so we keep rendering those desktop filds to store mobile values which come from mobile form */}
      <WSElement hidden={!isDesktop || props.forceMobileShow}>
        <DesktopFields {...props} />
      </WSElement>
    </WSElement>
  );
};
