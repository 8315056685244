import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { DataView } from "../../../../shared/components/DataView";
import { WSAlert } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { formatPhone } from "../../../../shared/utils/formatPhone";
import { TAX_FILING_1099_FILING_UPDATE_SUPPORT_INFORMATION_PATH } from "../../paths";

export const SupportInformation: React.FC<{}> = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const member = queryMember.data;

  return (
    <DataView
      loading={queryMember.isLoading}
      onEdit={() => {
        history.push(TAX_FILING_1099_FILING_UPDATE_SUPPORT_INFORMATION_PATH);
      }}
      title="Support information"
      data={[
        {
          label: "Support email",
          value: member?.profile.company?.supportEmail
        },
        {
          label: "Support phone number",
          value: member?.profile.company?.supportPhoneNumber
            ? formatPhone(member?.profile.company?.supportPhoneNumber)
            : undefined
        }
      ]}
    >
      {!member?.profile.company?.supportEmail ||
      !member?.profile.company?.supportPhoneNumber ? (
        <WSAlert theme="warning" title="Information required" icon="alert">
          This information is required for your contractors to contact you
          during tax filing season.
        </WSAlert>
      ) : null}
    </DataView>
  );
};
