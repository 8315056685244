import flatten from "lodash/flatten";
import { InfiniteQueryConfig } from "react-query";
import {
  getPayeeRows,
  IPayeeRow,
  PayeeRowsQuery
} from "../../../../services/search";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../../helpers";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";

const PAGE_SIZE = 20;

export const QUERY_PAYEE_ROWS = "QUERY_PAYEE_ROWS";

export const usePayeeRowsQuery = (
  params?: PayeeRowsQuery,
  config?: InfiniteQueryConfig<IPayeeRow[], WSServiceError>
) => {
  const size = params?.page?.size ?? PAGE_SIZE;

  const xWingspanExpansion = useXWingspanExpansion();

  const query = useWSInfiniteQuery<IPayeeRow[], WSServiceError>(
    [QUERY_PAYEE_ROWS, { params, xWingspanExpansion }],
    (_, __, pageNumber = 1) => {
      return getPayeeRows(
        {
          ...params,
          page: {
            size,
            number: pageNumber
          }
        },
        xWingspanExpansion
      );
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data) : undefined
  };
};
