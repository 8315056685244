import {
  toWSDateString,
  WSCentered,
  WSElement,
  WSEmptyState,
  WSFilter,
  WSFilters,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPageToolbarAction
} from "@wingspanhq/fe-component-library";
import React, { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useBrowserPageTitle } from "../../../../../components/BrowserPageTitle/BrowserPageTitle";
import { Tabs } from "../../../../../components/Tabs";
import { USStates } from "../../../../../constants/USstates";
import { WSQueries } from "../../../../../query/WSQuery";
import {
  TaxFormDeliveryMethod,
  TaxFormExclusionReason,
  TaxFormStatus,
  TaxFormSubmissionStatus,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import flatten from "lodash/flatten";
import {
  useAllOrganizationUsers,
  useMemberProfile
} from "../../../../../query/users/queries";
import { getUserName } from "../../../../../query/users/selectors";
import { useAuthorizedScopeGroups } from "../../../../../shared/utils/teamUtils";
import { NEC1099FilingTable } from "./NEC1099FilingTable";
import { TableNoDataMessage } from "./TableNoDataMessage";

import {
  createTaxFormSubmissionListRequest,
  FormAmendmentsFilterOption,
  FormContractorRequestFilterOption,
  GroupedTINStatus,
  ShareTaxDocumentStatus,
  TaxFormSubmissionViewFilters
} from "../../../../../query/search/taxForm/queries/filtering";
import { AlertSubmissionInProgress } from "../../../components/AlertSubmissionInProgress";
import { AlertSubmissionIsAvailable } from "../../../components/AlertSubmissionIsAvailable";

import { useTaxFormSubmissionRowsQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";
import { useTaxFormSubmissionRowsSummaryQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";
import { TaxFormSubmissionRequest } from "../../../../../services/search";
import { useUrlQuery } from "../../../../../shared/hooks/useUrlQuery";
import { useXWingspanExpansion } from "../../../../../shared/hooks/useXWingspanExpansion";
import { SelectTaxYear } from "../../../components/SelectYear";
import {
  TAX_FILING_1099_FILING_DASHBOARD_PATH,
  TAX_FILING_1099_FILING_DASHBOARD_SEARCH_PATH,
  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH,
  TAX_FILING_1099_FILING_SETTINGS_PATH,
  TAX_FILING_1099_FILING_SUBMISSION_PATH,
  useGoToGenerateTaxFormProgress,
  useGoToSubmissionTaxFormProgress
} from "../../../paths";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { AlertGenerationInProgress } from "../../../components/AlertGenerationInProgress";
import { useActiveBatchItems } from "../../../hooks/useCurrentCalculationBatchStatus";
import { BulkCalculation1099Type } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useRedirectToByKeyPress } from "../../../../../utils/useRedirectToByKeyPress";
import { KEYBOARD_SHORTCUT_SEARCH } from "../../../../../constants/keyboardShortcuts";
import { downloadCSV } from "../../../../Reports/utils/sheetjs/downloadCSV";
import { downloadAllTaxFormSubmissionRows } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsAllQuery";
import { mapTaxFormSubmissionRowsToCSV } from "../../../utils/taxFormsToCSV";
import { AlertPendingCorrectionRequests } from "../../../components/AlertPendingCorrectionRequests";

export const ROOT_PATH_ALL = `${TAX_FILING_1099_FILING_DASHBOARD_PATH}/all`;
export const ROOT_PATH_NEEDS_ACTION = `${TAX_FILING_1099_FILING_DASHBOARD_PATH}/needs-action`;
export const ROOT_PATH_READY = `${TAX_FILING_1099_FILING_DASHBOARD_PATH}/ready`;
export const ROOT_PATH_SUBMITTED = `${TAX_FILING_1099_FILING_DASHBOARD_PATH}/submitted`;
export const ROOT_PATH_EXCLUDED = `${TAX_FILING_1099_FILING_DASHBOARD_PATH}/excluded`;

export const getStatusBasedOnRoute = (
  pathname: string
): TaxFormSubmissionViewFilters["status"] => {
  switch (pathname) {
    case ROOT_PATH_NEEDS_ACTION:
      return TaxFormStatus.NeedsAction;
    case ROOT_PATH_READY:
      return TaxFormStatus.ReadyToSubmitToIrs;
    case ROOT_PATH_SUBMITTED:
      return [
        TaxFormStatus.SubmittedToIrs,
        TaxFormStatus.RejectedByIrs,
        TaxFormStatus.AcceptedByIrs
      ];
    case ROOT_PATH_EXCLUDED:
      return TaxFormStatus.Excluded;
    default:
      return undefined;
  }
};

export const taxFormStatusFilterOptions: Array<{
  name: TaxFormStatus | TaxFormSubmissionStatus;
  label: string;
}> = [
  // {
  //   name: TaxFormStatus.Pending,
  //   label: "Pending"
  // },
  {
    name: TaxFormStatus.Excluded,
    label: "Excluded"
  },
  {
    name: TaxFormStatus.NeedsAction,
    label: "Action Required"
  },
  {
    name: TaxFormStatus.ReadyToSubmitToIrs,
    label: "Ready to Submit"
  },
  {
    name: TaxFormSubmissionStatus.SubmittedToIrs,
    label: "Submitted"
  },
  {
    name: TaxFormSubmissionStatus.AcceptedByIrs,
    label: "Accepted by IRS"
  },
  {
    name: TaxFormSubmissionStatus.RejectedByIrs,
    label: "Rejected by IRS"
  }
  // {
  //   name: TaxFormSubmissionStatus.Voided,
  //   label: "Voided"
  // },
  // {
  //   name: TaxFormSubmissionStatus.Corrected,
  //   label: "Corrected"
  // }
];

export const taxFormTaxStatusFilterOptions: Array<{
  name: VerificationStatus;
  label: string;
  helperText?: string;
}> = [
  {
    name: VerificationStatus.None,
    label: "Not Provided",
    helperText: "Tax ID not provided"
  },
  {
    name: VerificationStatus.Failed,
    label: "Mismatch",
    helperText: "Tax ID and name is mismatched"
  },
  {
    name: VerificationStatus.Pending,
    label: "Pending",
    helperText: "Tax ID is being verified"
  },
  {
    name: VerificationStatus.Verified,
    label: "Verified",
    helperText: "Tax ID has been verified"
  }
];

const taxFormW9SharedFilterOptions: Array<{
  name: string;
  label: string;
  helperText?: string;
}> = [
  {
    label: "All",
    name: ""
  },
  {
    name: ShareTaxDocumentStatus.NotShared,
    label: "Not shared",
    helperText: "Tax info is not shared by contractor"
  },
  {
    name: ShareTaxDocumentStatus.Shared,
    label: "Shared",
    helperText: "Tax info is shared by contractor"
  }
];

const taxFormExclusionFilterOptions = [
  {
    name: TaxFormExclusionReason.IneligibleCompanyStructure,
    label: "Federal tax classification"
  },
  {
    name: TaxFormExclusionReason.BelowThreshold,
    label: "Less than $600 payment threshold"
  },
  {
    name: TaxFormExclusionReason.ManuallyExcluded,
    label: "Manual exclusion"
  },
  {
    name: TaxFormExclusionReason.NonUsEntity,
    label: "Non-U.S. person"
  }
];

const taxFormGroupedTinFilterOptions: Array<{
  name: string;
  label: string;
  helperText?: string;
}> = [
  {
    name: "",
    label: "All",
    helperText: "Grouped and not grouped"
  },
  {
    name: GroupedTINStatus.Grouped,
    label: "Grouped"
  },
  {
    name: GroupedTINStatus.NoGrouped,
    label: "Not grouped"
  }
];

const taxFormAmendmentsFilterOptions: Array<{
  name: string;
  label: string;
  helperText?: string;
}> = [
  {
    label: "All forms",
    name: ""
  },
  {
    name: FormAmendmentsFilterOption.Superceded,
    label: "Superseded forms",
    helperText:
      "Forms that were replaced by an updated Voided or Corrected form"
  },
  {
    name: FormAmendmentsFilterOption.Voided,
    label: "Voided forms",
    helperText: "Supersedes and voids a previous form submission"
  },
  {
    name: FormAmendmentsFilterOption.Corrected,
    label: "Corrected forms",
    helperText: "Supersedes and corrects a previous form submission"
  }
];

const taxFormContractorRequestFilterOptions: Array<{
  name: string;
  label: string;
  helperText?: string;
}> = [
  {
    label: "All forms",
    name: ""
  },
  {
    name: FormContractorRequestFilterOption.Pending,
    label: "Outstanding correction requested"
  },
  {
    name: FormContractorRequestFilterOption.Accepted,
    label: "Correction request accepted by you"
  },
  {
    name: FormContractorRequestFilterOption.Rejected,
    label: "Correction request rejected by you"
  }
];

const taxFormDeliveryMethodFilterOptions = [
  {
    label: "All",
    name: ""
  },
  {
    name: TaxFormDeliveryMethod.Electronic,
    label: "Electronic"
  },
  {
    name: TaxFormDeliveryMethod.Mail,
    label: "Mail"
  }
];

const taxFormSyncFilterOptions = [
  {
    label: "All",
    name: ""
  },
  {
    name: "true",
    label: "Synced with contractor profile"
  },
  {
    name: "false",
    label: "Disabled sync (payer overwritten)"
  }
];

const taxFormStateOptions = USStates.map(s => ({
  name: s.abbreviation,
  label: s.name
}));

const getCount = (
  query: ReturnType<typeof useTaxFormSubmissionRowsSummaryQuery>
) =>
  query.isFetched && typeof query.data?.listSize === "number"
    ? query.data?.listSize
    : undefined;

export const NEC1099FilingDashboard: React.FC<RouteComponentProps> = ({
  history
}) => {
  const currentYear = useCurrentTaxFilingYear();

  useBrowserPageTitle(`1099 Filing Dashboard ${currentYear}`);

  const { urlQuery, setUrlQuery } = useUrlQuery<
    TaxFormSubmissionViewFilters,
    "name" | "totalAmount"
  >();

  const expandOrganizationsForUserId = useXWingspanExpansion();
  const queryOrgAccounts = useAllOrganizationUsers();
  const { hasAdminScope, hasCollaboratorsScope } = useAuthorizedScopeGroups();
  const queryFeatureFlags = useFeatureFlags();

  const goToGenerationProgress = useGoToGenerateTaxFormProgress();
  const goToSubmissionProgress = useGoToSubmissionTaxFormProgress();

  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);

  const tabStatuses = useMemo(() => {
    return getStatusBasedOnRoute(history.location.pathname);
  }, [history.location.pathname]);

  const currentRequest = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(
      currentYear,
      {
        ...urlQuery.filter,
        status: tabStatuses || urlQuery.filter?.status
      },
      urlQuery.sort
    );
  }, [currentYear, tabStatuses, urlQuery.filter, urlQuery.sort]);

  const queryTaxFormSubmissionRows =
    useTaxFormSubmissionRowsQuery(currentRequest);

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const queryTaxFormRowSearchCount =
    useTaxFormSubmissionRowsSummaryQuery(currentRequest);

  const requestAll = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, {});
  }, [currentYear]);

  const queryTaxFormRowSearchCountAll =
    useTaxFormSubmissionRowsSummaryQuery(requestAll);

  const requestNeedsAction = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, {
      status: getStatusBasedOnRoute(ROOT_PATH_NEEDS_ACTION)
    });
  }, [currentYear]);

  const requestPendingCorrection = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, {
      correctionRequest: FormContractorRequestFilterOption.Pending
    });
  }, [currentYear]);

  const queryTaxFormRowSearchCountPendingCorrection =
    useTaxFormSubmissionRowsSummaryQuery(requestPendingCorrection);

  const queryTaxFormRowSearchCountNeedsAction =
    useTaxFormSubmissionRowsSummaryQuery(requestNeedsAction);

  const requestReady = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, {
      status: getStatusBasedOnRoute(ROOT_PATH_READY)
    });
  }, [currentYear]);

  const queryTaxFormRowSearchCountReady =
    useTaxFormSubmissionRowsSummaryQuery(requestReady);

  const requestSubmitted = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, {
      status: getStatusBasedOnRoute(ROOT_PATH_SUBMITTED)
    });
  }, [currentYear]);
  const queryTaxFormRowSearchCountSubmitted =
    useTaxFormSubmissionRowsSummaryQuery(requestSubmitted);

  const requestExcluded = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, {
      status: getStatusBasedOnRoute(ROOT_PATH_EXCLUDED)
    });
  }, [currentYear]);

  const queryTaxFormRowSearchCountExcluded =
    useTaxFormSubmissionRowsSummaryQuery(requestExcluded);

  const queryActiveBatchItems = useActiveBatchItems(currentYear);

  const pendingCorrectionsTaxFormsCount = useMemo(
    () => getCount(queryTaxFormRowSearchCountPendingCorrection),
    [queryTaxFormRowSearchCountPendingCorrection]
  );

  const readyTaxFormsCount = useMemo(
    () => getCount(queryTaxFormRowSearchCountReady),
    [queryTaxFormRowSearchCountReady]
  );

  const noReadyAndNoSubmitted = useMemo(
    () =>
      getCount(queryTaxFormRowSearchCountReady) === 0 &&
      getCount(queryTaxFormRowSearchCountSubmitted) === 0,
    [queryTaxFormRowSearchCountReady, queryTaxFormRowSearchCountSubmitted]
  );

  const shouldShowSubmissionAlert = useMemo(
    () => !!readyTaxFormsCount || noReadyAndNoSubmitted,
    [readyTaxFormsCount, noReadyAndNoSubmitted]
  );

  const taxFormsCount = useMemo(
    () => readyTaxFormsCount || 0,
    [readyTaxFormsCount]
  );

  const isSubmissionAvailable = useMemo(
    () =>
      !!readyTaxFormsCount &&
      readyTaxFormsCount > 0 &&
      queryFeatureFlags.data?.taxDocumentsSeason2024List,
    [queryFeatureFlags.data, readyTaxFormsCount]
  );

  useRedirectToByKeyPress(
    KEYBOARD_SHORTCUT_SEARCH,
    TAX_FILING_1099_FILING_DASHBOARD_SEARCH_PATH
  );

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryActiveBatchItems
        }}
        renderLoader={() => (
          <WSPageToolbar
            title="1099-NEC filing"
            actions={[]}
            titleAfter={<SelectTaxYear />}
          />
        )}
      >
        {({ queryActiveBatchItemsData }) => {
          const generationBatchItem = queryActiveBatchItemsData.find(
            batchItem =>
              batchItem.calculationType === BulkCalculation1099Type.Balances
          );

          const submissionBatchItem = queryActiveBatchItemsData.find(
            batchItem =>
              batchItem.calculationType === BulkCalculation1099Type.Submissions
          );

          const disableActions = !!(generationBatchItem || submissionBatchItem);

          const actions: WSPageToolbarAction[] = [
            {
              label: "Submit for filing",
              onClick: () => {
                history.push(TAX_FILING_1099_FILING_SUBMISSION_PATH);
              },
              buttonKind: "Primary",
              hidden: !isSubmissionAvailable,
              disabled: disableActions
            },
            {
              label: "Search",
              onClick: () => {
                history.push(TAX_FILING_1099_FILING_DASHBOARD_SEARCH_PATH);
              },
              icon: "search",
              buttonKind: "Tertiary",
              disabled: disableActions
            },
            {
              label: "Generate tax forms",
              onClick: () => {
                history.push(
                  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH
                );
              },
              buttonKind: isSubmissionAvailable ? undefined : "Primary",
              disabled: disableActions
            },
            {
              label: "Tax filing settings",
              onClick: () => {
                history.push(TAX_FILING_1099_FILING_SETTINGS_PATH);
              },
              visible: hasAdminScope,
              disabled: disableActions
            }
          ];

          return (
            <>
              <WSPageToolbar
                title="1099-NEC filing"
                actions={actions}
                titleAfter={<SelectTaxYear />}
              />

              <WSList gap="L" mb="L">
                {generationBatchItem ? (
                  <AlertGenerationInProgress
                    year={currentYear}
                    onView={() => {
                      goToGenerationProgress(
                        generationBatchItem.bulkCalculation1099BatchId
                      );
                    }}
                  />
                ) : null}

                {submissionBatchItem ? (
                  <AlertSubmissionInProgress
                    year={currentYear}
                    onView={() => {
                      goToSubmissionProgress(
                        submissionBatchItem.bulkCalculation1099BatchId
                      );
                    }}
                  />
                ) : null}
              </WSList>
              {submissionBatchItem ? (
                <WSEmptyState
                  hasBorder
                  orientation="horizontal"
                  type="documents"
                  title="Tax forms being submitted"
                  description="Once tax forms for contractors have been submitted to the IRS for filing, they will be updated here."
                  buttons={[
                    {
                      kind: "Secondary",
                      label: "View progress",
                      onClick: () => {
                        goToSubmissionProgress(
                          submissionBatchItem.bulkCalculation1099BatchId
                        );
                      }
                    }
                  ]}
                />
              ) : generationBatchItem ? (
                <WSEmptyState
                  hasBorder
                  orientation="horizontal"
                  type="documents"
                  title="Tax forms being generated"
                  description="Once tax forms for contractors have been generated (or updated), they will appear here."
                  buttons={[
                    {
                      kind: "Secondary",
                      label: "View progress",
                      onClick: () => {
                        goToGenerationProgress(
                          generationBatchItem.bulkCalculation1099BatchId
                        );
                      }
                    }
                  ]}
                />
              ) : (
                <WSList gap="L">
                  <Tabs
                    tabs={[
                      {
                        label: "All",
                        badge: getCount(queryTaxFormRowSearchCountAll),
                        path: ROOT_PATH_ALL
                      },
                      {
                        label: "Action Required",
                        badge: getCount(queryTaxFormRowSearchCountNeedsAction),
                        path: ROOT_PATH_NEEDS_ACTION
                      },
                      {
                        label: "Ready",
                        badge: readyTaxFormsCount,
                        path: ROOT_PATH_READY
                      },
                      {
                        label: "Submitted",
                        badge: getCount(queryTaxFormRowSearchCountSubmitted),
                        path: ROOT_PATH_SUBMITTED
                      },
                      {
                        label: "Excluded",
                        badge: getCount(queryTaxFormRowSearchCountExcluded),
                        path: ROOT_PATH_EXCLUDED
                      }
                    ]}
                  />

                  {!!pendingCorrectionsTaxFormsCount &&
                    pendingCorrectionsTaxFormsCount > 0 && (
                      <AlertPendingCorrectionRequests
                        correctionsNumber={pendingCorrectionsTaxFormsCount}
                        onView={() => {
                          history.push(
                            "/member/tax-filing/1099-filing/dashboard/all/?filter%5BcorrectionRequest%5D=Pending"
                          );
                        }}
                      />
                    )}

                  {!!shouldShowSubmissionAlert &&
                    queryFeatureFlags.data?.taxDocumentsSeason2024List && (
                      <AlertSubmissionIsAvailable
                        taxFormsCount={taxFormsCount}
                      />
                    )}

                  <WSFilters<Partial<TaxFormSubmissionViewFilters>>
                    searchable
                    values={urlQuery.filter}
                    onFilter={newFilter => {
                      setUrlQuery(prev => ({ ...prev, filter: newFilter }));
                    }}
                    info={{
                      count: queryTaxFormRowSearchCount.data?.listSize || 0
                    }}
                    primaryFilters={[
                      ...(queryOrgAccounts.data?.length
                        ? ([
                            {
                              name: "clientId",
                              title: "Organization",
                              type: "checkbox",
                              options: queryOrgAccounts.data
                                .filter(o => getUserName(o))
                                .sort((a, b) => {
                                  const aName = getUserName(a);
                                  const bName = getUserName(b);
                                  return aName.localeCompare(bName);
                                })
                                .map(orgAccount => ({
                                  label: getUserName(orgAccount),
                                  name: orgAccount.userId
                                }))
                            }
                          ] as WSFilter[])
                        : []),
                      ...(tabStatuses
                        ? []
                        : ([
                            {
                              name: "status",
                              title: "Status",
                              type: "checkbox",
                              options: taxFormStatusFilterOptions
                            }
                          ] as WSFilter[])),
                      {
                        name: "exclusionReason",
                        title: "Exclusion reason",
                        type: "checkbox",
                        options: taxFormExclusionFilterOptions
                      },
                      {
                        name: "shareTaxDocument",
                        title: "Tax info",
                        type: "radio",
                        options: taxFormW9SharedFilterOptions
                      },
                      {
                        name: "searchableTaxStatus",
                        title: "Tax ID status",
                        type: "checkbox",
                        options: taxFormTaxStatusFilterOptions
                      },
                      {
                        name: "isGroupedTin",
                        title: "Grouped TIN",
                        type: "radio",
                        options: taxFormGroupedTinFilterOptions
                      },
                      {
                        name: "formAmendments",
                        title: "Form amendments",
                        type: "radio",
                        options: taxFormAmendmentsFilterOptions
                      },
                      {
                        name: "correctionRequest",
                        title: "Contractor correction request",
                        type: "radio",
                        options: taxFormContractorRequestFilterOptions
                      },
                      {
                        name: "recipientSyncEnabledString",
                        title: "Sync",
                        type: "radio",
                        options: taxFormSyncFilterOptions
                      },
                      {
                        name: "deliveryMethod",
                        title: "Delivery method",
                        type: "radio",
                        options: taxFormDeliveryMethodFilterOptions
                      },
                      {
                        name: "totalAmount",
                        title: "1099-NEC total",
                        type: "amountrange"
                      },
                      {
                        name: "state",
                        title: "U.S. state",
                        type: "checkbox",
                        options: taxFormStateOptions
                      }
                    ]}
                  />

                  <WSElement
                    data-testid="nec1099FilingContainer"
                    shimmer={queryOrgAccounts.isLoading}
                  >
                    <WSQueries
                      queries={{
                        queryTaxFormRowSearch: queryTaxFormSubmissionRows,
                        queryMember: queryMember
                      }}
                    >
                      {({ queryTaxFormRowSearchData, queryMemberData }) => {
                        return flatten(queryTaxFormRowSearchData).length > 0 ? (
                          <NEC1099FilingTable
                            queryTaxFormSubmissions={queryTaxFormSubmissionRows}
                            organizations={queryOrgAccounts.data || []}
                            currentMember={queryMemberData}
                            headerAction={{
                              onClick: async () => {
                                setCsvDownloadLoading(true);
                                const allResults =
                                  await downloadAllTaxFormSubmissionRows(
                                    currentRequest,
                                    expandOrganizationsForUserId
                                  );
                                const data = mapTaxFormSubmissionRowsToCSV(
                                  allResults,
                                  {
                                    hideOrgAccountName:
                                      queryOrgAccounts.data?.length === 0
                                  }
                                );

                                await downloadCSV(
                                  data,
                                  `1099-NEC Tax Forms (${toWSDateString(
                                    new Date()
                                  )})`
                                );
                                setCsvDownloadLoading(false);
                              },
                              icon: "download",
                              loading: csvDownloadLoading
                            }}
                            sort={urlQuery.sort}
                            onSort={(field, direction) => {
                              setUrlQuery(prev => ({
                                ...prev,
                                sort: {
                                  field,
                                  direction
                                }
                              }));
                            }}
                          />
                        ) : (
                          <WSCentered span={{ m: "6" }}>
                            <TableNoDataMessage
                              isFiltered={
                                Object.keys(urlQuery.filter).length > 1
                              }
                            />
                          </WSCentered>
                        );
                      }}
                    </WSQueries>
                  </WSElement>
                </WSList>
              )}
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
