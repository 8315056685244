import {
  WSActions,
  WSAlert,
  WSButton,
  WSCard,
  WSEmptyState,
  WSFlexBox,
  WSIcon,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { WSQueries } from "../../../../../query/WSQuery";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { useBulkCalculate1099BatchSummary } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import { Layout } from "../../../../../shared/components/Layout";
import { openIntercom } from "../../../../../shared/utils/intercom";
import { formatNumber } from "accounting";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../../../types/wsLinks";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { ROOT_PATH_SUBMITTED } from "../../Dashboard/RouteDashboard";

export const RouteSubmittingPartiallySuccess: React.FC<
  SetupRouteComponentProps
> = ({ onBack, onNext }) => {
  const currentYear = useCurrentTaxFilingYear();
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const queryBulkCalculate1099Summary =
    useBulkCalculate1099BatchSummary(bulkBatchId);

  return (
    <Layout title="1099-NEC Filing">
      <WSQueries
        queries={{
          queryBulkCalculate1099Summary
        }}
      >
        {({ queryBulkCalculate1099SummaryData }) => {
          const failed = queryBulkCalculate1099SummaryData.failedTaxForms;
          const total = queryBulkCalculate1099SummaryData.totalTaxForms;
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;

          return (
            <>
              <WSList gap="2XL">
                <WSCard>
                  <WSEmptyState
                    type="done"
                    orientation="horizontal"
                    title="Tax forms partially submitted for filing"
                    description={
                      <>
                        You successfully submitted {formatNumber(processed)} of{" "}
                        {formatNumber(total)} tax forms to the IRS for filing.
                        Try again for the remaining tax forms not yet submitted.
                        If the issues persists, please{" "}
                        <a onClick={openIntercom}>contact support</a>.
                      </>
                    }
                    buttons={[
                      {
                        label: "Try again",
                        kind: "Secondary",
                        onClick: onNext
                      }
                    ]}
                  />
                  <WSInfoBox title="Tax forms submission summary:">
                    <WSList gap="L">
                      <WSFlexBox gap="XS" alignItems="center">
                        <WSIcon name="check-circle" color="green400" />
                        <WSText.ParagraphSm>
                          {formatNumber(processed)} tax forms submitted
                          successfully
                        </WSText.ParagraphSm>
                      </WSFlexBox>

                      <WSFlexBox gap="XS" alignItems="center">
                        <WSIcon name="alert" color="red400" />
                        <WSText.ParagraphSm>
                          {formatNumber(failed)} tax forms not submitted
                        </WSText.ParagraphSm>
                      </WSFlexBox>

                      <WSAlert
                        theme="warning"
                        icon="alert"
                        title="Error submitting tax forms"
                        action={{
                          text: "Try again",
                          onClick: onNext
                        }}
                      >
                        There was an error submitting {formatNumber(failed)} tax
                        forms to the IRS for filing. Try again and if the issue
                        persists, please{" "}
                        <a onClick={openIntercom}>contact support</a>.
                      </WSAlert>
                    </WSList>
                  </WSInfoBox>
                </WSCard>

                <WSActions
                  alignment="fill"
                  buttons={[
                    {
                      label: "Go to dashboard",
                      onClick: () => {
                        history.push(ROOT_PATH_SUBMITTED);
                      }
                    }
                  ]}
                />

                <WSCard>
                  <WSInfoBox title="Reminder" mb="L">
                    The 1099-NEC forms mentioned above will be submitted to the
                    IRS. Once submitted, each contractor will receive a copy of
                    their 1099-NEC delivered by mail and/or electronically based
                    on their preference.
                  </WSInfoBox>

                  <WSFlexBox alignItems="center">
                    <WSText.ParagraphSm color="gray500">
                      Learn more about tax filing?
                    </WSText.ParagraphSm>
                    <WSButton.Link
                      icon="file"
                      onClick={() => {
                        openInNewTab(WS_LINKS.ircForm1099NEC);
                      }}
                    >
                      1099-NEC tax filing
                    </WSButton.Link>
                  </WSFlexBox>
                </WSCard>
              </WSList>
            </>
          );
        }}
      </WSQueries>
    </Layout>
  );
};
