import { Redirect, Route, Switch } from "react-router-dom";
import { useIsTaxFilingDashboardEnabled } from "./hooks/useIsTaxFilingDashboardEnabled";
import {
  TAX_FILING_1099_FILING_DASHBOARD_PATH,
  TAX_FILING_1099_FILING_DASHBOARD_SEARCH_PATH,
  TAX_FILING_1099_FILING_DASHBOARD_TABS_PATH,
  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_DETAILS_PATH,
  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH,
  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_PROGRESS_PATH,
  TAX_FILING_1099_FILING_PREVIEW_PATH,
  TAX_FILING_1099_FILING_SETTINGS_PATH,
  TAX_FILING_1099_FILING_SUBMISSION_PATH,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_AMOUNT_EDIT_PATH,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_CONTRACTOR_EDIT_PATH,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_HISTORY,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PREVIEW_1099_NEC_PATH,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_REVIEW_CORRECTION_PATH,
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_VIEW_CORRECTION_PATH,
  TAX_FILING_1099_FILING_UPDATE_CALCULATION_SETTINGS_PATH,
  TAX_FILING_1099_FILING_UPDATE_STATE_FILING_SETTINGS_PATH,
  TAX_FILING_1099_FILING_UPDATE_SUPPORT_INFORMATION_PATH
} from "./paths";
import { NEC1099FilingDashboard } from "./routes/Dashboard/RouteDashboard";
import { RouteGenerate1099NECAmounts } from "./routes/Dashboard/RouteGenerateTaxForm";
import { RouteGenerate1099NECAmountsProgress } from "./routes/Dashboard/RouteGenerateTaxForm/RouteGenerate1099NECAmountsProgress";
import { RouteInitGenerate1099NECAmounts } from "./routes/Dashboard/RouteGenerateTaxForm/RouteInitGenerate1099NECAmounts";
import { RouteTaxFormsSearch } from "./routes/Dashboard/RouteSearch";
import { Route1099NECPreview } from "./routes/Details/Route1099NECPreview";
import { RouteTaxFormDetails } from "./routes/Details/RouteTaxFormDetails";
import { RouteTaxFormEditNEC1099Amount } from "./routes/Details/RouteTaxFormEditNEC1099Amount";
import { RouteTaxFormEditRecipient } from "./routes/Details/RouteTaxFormEditRecipient";
import { RouteTaxFormHistory } from "./routes/Details/RouteTaxFormHistory";
import { RoutePreview } from "./routes/Preview/RoutePreview";
import { RouteTaxFilingSettings } from "./routes/Settings";
import { RouteEditCalculationSettings } from "./routes/Settings/RouteEditCalculationSettings";
import { RouteEditStateFiling } from "./routes/Settings/RouteEditStateFiling";
import { RouteEditSupportInformation } from "./routes/Settings/RouteEditSupportInformation";
import { RouteSubmission } from "./routes/Submission";
import { RouteNewCorrection } from "./routes/NewCorrection";
import { RouteReviewCorrectionFlow } from "./routes/ContractorCorrectionRequest/RouteReviewCorrectionFlow";
import { RouteViewCorrectionRequest } from "./routes/ContractorCorrectionRequest/RouteViewCorrectionRequest";

export const Tax1099Filing = () => {
  const { isLoading, isEnabled } = useIsTaxFilingDashboardEnabled();

  if (isLoading) {
    return null;
  }

  return (
    <Switch>
      {isEnabled ? (
        <Redirect
          to={TAX_FILING_1099_FILING_DASHBOARD_PATH}
          from={TAX_FILING_1099_FILING_PREVIEW_PATH}
        />
      ) : null}
      <Route
        path={TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH}
        exact
        component={RouteInitGenerate1099NECAmounts}
      />
      <Route
        path={TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_PROGRESS_PATH}
        component={RouteGenerate1099NECAmountsProgress}
      />

      <Route
        path={TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_DETAILS_PATH}
        component={RouteGenerate1099NECAmounts}
      />

      <Route
        path={TAX_FILING_1099_FILING_DASHBOARD_TABS_PATH}
        component={NEC1099FilingDashboard}
      />

      <Route
        path={TAX_FILING_1099_FILING_DASHBOARD_SEARCH_PATH}
        component={RouteTaxFormsSearch}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_VIEW_CORRECTION_PATH}
        component={RouteViewCorrectionRequest}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_REVIEW_CORRECTION_PATH}
        component={RouteReviewCorrectionFlow}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH}
        component={RouteNewCorrection}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_CONTRACTOR_EDIT_PATH}
        component={RouteTaxFormEditRecipient}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_AMOUNT_EDIT_PATH}
        component={RouteTaxFormEditNEC1099Amount}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PREVIEW_1099_NEC_PATH}
        component={Route1099NECPreview}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_HISTORY}
        component={RouteTaxFormHistory}
      />

      <Route
        path={TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH}
        component={RouteTaxFormDetails}
      />

      <Route
        path={TAX_FILING_1099_FILING_UPDATE_SUPPORT_INFORMATION_PATH}
        component={RouteEditSupportInformation}
      />

      <Route
        path={TAX_FILING_1099_FILING_UPDATE_STATE_FILING_SETTINGS_PATH}
        component={RouteEditStateFiling}
      />

      <Route
        path={TAX_FILING_1099_FILING_UPDATE_CALCULATION_SETTINGS_PATH}
        component={RouteEditCalculationSettings}
      />

      <Route
        path={TAX_FILING_1099_FILING_PREVIEW_PATH}
        component={RoutePreview}
      />

      <Route
        path={TAX_FILING_1099_FILING_SETTINGS_PATH}
        component={RouteTaxFilingSettings}
      />

      <Route
        path={TAX_FILING_1099_FILING_SUBMISSION_PATH}
        component={RouteSubmission}
      />

      <Redirect
        to={TAX_FILING_1099_FILING_DASHBOARD_PATH + "/all"}
        from={TAX_FILING_1099_FILING_DASHBOARD_PATH}
      />
      {isEnabled ? (
        <Redirect to={TAX_FILING_1099_FILING_DASHBOARD_PATH} from="*" />
      ) : (
        <Redirect to={TAX_FILING_1099_FILING_PREVIEW_PATH} from="*" />
      )}
    </Switch>
  );
};
