import { WSPage } from "@wingspanhq/fe-component-library";
import { Redirect, Route, Switch } from "react-router-dom";
import { PATH_SETTINGS } from "../..";
import { RouteAdd } from "../../../PaymentMethods/routes/RouteAdd";
import { RouteAddInstant } from "../../../PaymentMethods/routes/RouteAddInstant";
import { RouteAddInstantDebitCard } from "../../../PaymentMethods/routes/RouteAddInstantDebitCard";
import { RouteAddInstantManually } from "../../../PaymentMethods/routes/RouteAddInstantManually";
import { RouteAddInstantPlaid } from "../../../PaymentMethods/routes/RouteAddInstantPlaid";
import { RouteAddInternational } from "../../../PaymentMethods/routes/RouteAddInternational";
import { RouteAddInternationalManually } from "../../../PaymentMethods/routes/RouteAddInternationalManually";
import { RouteAddStandard } from "../../../PaymentMethods/routes/RouteAddStandard";
import { RouteAddStandardManually } from "../../../PaymentMethods/routes/RouteAddStandardManually";
import { RouteAddStandardPlaid } from "../../../PaymentMethods/routes/RouteAddStandardPlaid";
import { RouteEdit } from "../../../PaymentMethods/routes/RouteEdit";
import { RouteAddPaymentMethod } from "../RoutePaymentMethods/routes/RouteAddPaymentMethod";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteManagePaymentMethod } from "./routes/RouteManagePaymentMethod";
import { PaymentMethods } from "../../../PaymentMethods/index";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";

export const PATH_SETTINGS_PAYMENT_METHODS = PATH_SETTINGS + "/payment-methods";

export const PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD =
  PATH_SETTINGS_PAYMENT_METHODS + "/manage-payment-method";
export const PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD =
  PATH_SETTINGS_PAYMENT_METHODS + "/add-payment-method";

export const RoutePaymentMethods: React.FC = () => {
  const queryFeatureFlag = useFeatureFlags();
  return (
    <WSPage>
      <Switch>
        <Route
          path={PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD}
          component={RouteManagePaymentMethod}
        />
        <Route
          path={PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD}
          component={RouteAddPaymentMethod}
        />

        {/* Old payout method routes START */}

        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add`}
          component={RouteAdd}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-standard`}
          component={RouteAddStandard}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-standard-manually`}
          component={RouteAddStandardManually}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-standard-plaid`}
          component={RouteAddStandardPlaid}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-instant`}
          component={RouteAddInstant}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-instant-manually`}
          component={RouteAddInstantManually}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-instant-debit-card`}
          component={RouteAddInstantDebitCard}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-instant-plaid`}
          component={RouteAddInstantPlaid}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/edit`}
          component={RouteEdit}
        />

        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-international`}
          component={RouteAddInternational}
        />
        <Route
          path={`${PATH_SETTINGS_PAYMENT_METHODS}/add-international-manually`}
          component={RouteAddInternationalManually}
        />

        {/* Old payout method routes END */}
        <Route
          path={PATH_SETTINGS_PAYMENT_METHODS}
          component={
            queryFeatureFlag.data?.updatedPaymentMethod
              ? RouteIndex
              : PaymentMethods
          }
        />
        <Redirect to={PATH_SETTINGS_PAYMENT_METHODS} />
      </Switch>
    </WSPage>
  );
};
