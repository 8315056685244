import { WSButton } from "@wingspanhq/fe-component-library";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { isFileUploaded, isUploadFinished } from "../../utils/bulkBatchUtils";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { BulkImportResource, BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { useConfirmImportModal } from "../ConfirmImportModal";

interface BulkBatchActionsProps {
  bulkResource: BulkImportResource;
  bulkBatchId: string;
  viewUpload: () => void;
  uploadSpreadsheet: () => void;
}

export const BulkBatchActions: React.FC<BulkBatchActionsProps> = ({
  bulkResource,
  bulkBatchId,
  viewUpload,
  uploadSpreadsheet
}) => {
  const { useBulkBatch, useBulkBatchSummary } =
    getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId);
  const qBulkBatchItems = useBulkBatchSummary(bulkBatchId);

  const confirmImportModal = useConfirmImportModal(bulkResource);

  const bulkBatch = qBulkBatch.data;

  const isEmptyBatch =
    qBulkBatchItems.data && qBulkBatchItems.data.listSize === 0;
  const canViewFlatfileUploadSession =
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileAccessToken];

  const onImport = () => {
    confirmImportModal.open({
      bulkBatchId,
      bulkResource
    });
  };

  if (!bulkBatch) return null;

  if (isEmptyBatch) {
    return isFileUploaded(bulkBatch) ? (
      <WSButton
        shimmer={qBulkBatchItems.isLoading}
        name="viewUpload"
        icon="view"
        fullWidth
        onClick={viewUpload}
      >
        View upload
      </WSButton>
    ) : (
      <WSButton
        shimmer={qBulkBatchItems.isLoading}
        name="uploadSpreadsheet"
        icon="upload"
        fullWidth
        onClick={uploadSpreadsheet}
      >
        Upload spreadsheet
      </WSButton>
    );
  }

  const isUploadDone = isUploadFinished(bulkBatch);

  return (
    <>
      {bulkBatch.status === BulkStatus.Open && !isEmptyBatch && (
        <WSButton
          name="confirmImport"
          icon="details"
          fullWidth
          onClick={onImport}
          mb="M"
        >
          {bulkResource === BulkResource.Collaborator
            ? "Import contractors"
            : "Import payables & deductions"}
        </WSButton>
      )}

      {canViewFlatfileUploadSession && (
        <WSButton.Secondary
          name={isUploadDone ? "viewUpload" : "viewUploadProgress"}
          icon={isUploadDone ? "view" : "time"}
          fullWidth
          onClick={viewUpload}
        >
          {isUploadDone ? "View upload" : "View upload progress"}
        </WSButton.Secondary>
      )}
    </>
  );
};
