import isEmpty from "lodash/isEmpty";
import { TaxFormSubmissionRow } from "../../../services/search";
import { cleanCSVData } from "../../../shared/utils/csv";
import { isNotNil } from "../../../utils";
import { selectorTaxFormRecipientName } from "../selectors/selectorTaxFormRecipientName";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { selectorMemberName } from "../../../shared/selectors/selectorMemberName";
import { selectorPayerTaxFormDefaultLastFourTaxId } from "../selectors/selectorPayerTaxFormDefaultLastFourTaxId";
import { selectorPayerTaxFormDefaultStatus } from "../selectors/selectorPayerTaxFormDefaultStatus";
import { selector1099NECTotal } from "../selectors/selector1099NECTotal";
import { getFillingStatusBadgeProps } from "../components/FillingStatusBadge";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";

export const mapTaxFormSubmissionRowToCSVRow = (
  taxFormSubmission: TaxFormSubmissionRow,
  options?: {
    hideOrgAccountName?: boolean;
  }
) => {
  let orgName = "-";

  if (!options?.hideOrgAccountName && taxFormSubmission.payer) {
    const payer = taxFormSubmission.payer;
    const names = wsName({
      member: payer.member,
      user: payer.user
    });

    orgName =
      names.getResolvedName() || selectorMemberName(taxFormSubmission.payer);
  }

  const taxIdLast4 =
    selectorPayerTaxFormDefaultLastFourTaxId(taxFormSubmission);
  const taxStatus =
    taxFormSubmission.tinVerification?.status ||
    selectorPayerTaxFormDefaultStatus(taxFormSubmission);

  const nec1099Total = selector1099NECTotal(taxFormSubmission);

  const { taxForm } = taxFormSubmission;
  const {
    data: { w9Info, deliveryMethod }
  } = taxForm;

  const filingStatusData = getFillingStatusBadgeProps({
    status: taxFormSubmission.searchableStatus,
    correctionStatus: taxFormSubmission.lastCorrectionStatus,
    exclusionReason: taxForm.exclusionReason
  });

  return {
    Name: selectorTaxFormRecipientName(taxFormSubmission),
    "Recipient Email": taxForm.memberEmail,
    "Wingspan Payee ID": taxForm.memberId,
    "External ID": taxForm.memberExternalId,
    ...(!options?.hideOrgAccountName && { "Org Account Name": orgName }),
    "Tax ID Last 4": taxIdLast4,
    "Tax ID Verification Status": taxStatus,
    "W-9 Source": w9Info?.w9Source,
    "Delivery Method": deliveryMethod,
    "1099-NEC Total": nec1099Total,
    "Is Grouped?": taxFormSubmission.isGrouped ? "Yes" : "No",
    "Filing Status": filingStatusData.text,
    "Exclusion Reason": filingStatusData.description,
    "Tax Form ID": taxFormSubmission.id,
    "Company Structure": selectorTaxClassificationLabel(
      w9Info?.companyStructure
    ),
    "Legal Business Name": w9Info?.legalBusinessName,
    "Platform Income": taxForm.data.totalAmount,
    Adjustments: taxForm.data.adjustments,
    "Payment Processing Fees": taxForm.data.paymentProcessingFees,

    "Address Line 1": w9Info?.addressLine1,
    "Address Line 2": w9Info?.addressLine2,
    City: w9Info?.city,
    State: w9Info?.state,
    "Postal Code": w9Info?.postalCode,
    Country: w9Info?.country
  };
};

export const mapTaxFormSubmissionRowsToCSV = (
  data: Array<TaxFormSubmissionRow>,
  options?: {
    hideOrgAccountName?: boolean;
  }
) => {
  let cleanResult = [];

  const dirtyResult = data
    .map(d => {
      try {
        return mapTaxFormSubmissionRowToCSVRow(d, options);
      } catch (error) {
        console.error(error);
      }
      return {};
    })
    .filter(d => !isEmpty(d) || isNotNil(d));

  cleanResult = dirtyResult.map(d =>
    cleanCSVData(d, dirtyResult)
  ) as Array<any>;

  return cleanResult;
};
