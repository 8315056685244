import {
  WSButton,
  WSButtons,
  WSFlexBox,
  WSText,
  WSGrid,
  WSCard,
  WSInfoBox
} from "@wingspanhq/fe-component-library";
import React from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import styles from "./styles.module.scss";
import { track } from "../../../utils/analytics";
import { useCreateBulkCollaboratorBatch } from "../../../modules/BulkImporter/query/bulkCollaborator/mutations";
import { CommonUseCasesPanel } from "./CommonUseCasesPanel";
import { Layout } from "../../../shared/components/Layout";
import { BulkUploadHeader } from "./BulkUploadHeader";

export interface BulkUploadCollaboratorsStep1Props {}

export interface UploadCollaborator {
  email: string;
  contractorId?: string;
  firstName: string;
  lastName: string;

  name?: string;
  businessName?: string;
  companyStructure?: string;
  ssn?: string;
  ein?: string;

  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export const BulkUploadCollaboratorsStep1: React.FC<
  BulkUploadCollaboratorsStep1Props
> = () => {
  useBrowserPageTitle("Wingspan - Bulk import contractors");
  const history = useHistory();
  const qFeatureFlags = useFeatureFlags();

  const [createBulkCollaboratorBatch, createBulkCollaboratorBatchMeta] =
    useCreateBulkCollaboratorBatch();

  const downloadExampleCollaborators = [
    {
      firstName: "Wade",
      lastName: "Warren",
      email: "wade@wingspan.app",
      phoneNumber: "",
      contractorId: ""
    }
  ];
  const csvProps = {
    filename: `Wingspan_Bulk_Upload_Contractors_Template.csv`,
    headers: [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone number", key: "phoneNumber" },
      { label: "External Contractor ID", key: "contractorId" }
    ],
    data: downloadExampleCollaborators
  };

  const title = qFeatureFlags.data?.bulkImporterCollaborator
    ? "Import contractors with a spreadsheet"
    : "Bulk upload contractors with a spreadsheet";

  const subTitle =
    "Wingspan's contractor bulk upload feature allows businesses to upload multiple contractors at once.";

  const initiateBulkUpload = async () => {
    if (qFeatureFlags.data?.bulkImporterCollaborator) {
      await createBulkCollaboratorBatch(
        {},
        {
          onSuccess: response => {
            track("Contractors Bulk Upload Started");
            history.push(
              `/member/invoices/contacts/collaborators/bulk-create/${response.bulkCollaboratorBatchId}/upload`
            );
          }
        }
      );
    } else {
      history.push(
        "/member/invoices/contacts/collaborators/bulk-create/upload"
      );
    }
  };

  return (
    <Layout customHeader={<BulkUploadHeader progress={(100 / 3) * 1} />} wide>
      {qFeatureFlags.data?.bulkImporterCollaborator ? null : (
        <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
          1 of 3
        </WSText.ParagraphSm>
      )}
      <WSGrid gutter="L">
        <WSGrid.Item span={{ m: "7" }}>
          <WSText.Heading4 mb="S">{title}</WSText.Heading4>
          <WSText.ParagraphSm color="gray500" mt="M" mb="L">
            {subTitle}
          </WSText.ParagraphSm>
          <WSCard mb="2XL">
            <WSInfoBox className={styles.elem}>
              <ol>
                <li>
                  <WSText.ParagraphSm color="gray600" weight="medium">
                    Upload a spreadsheet of contractors
                  </WSText.ParagraphSm>
                </li>
                <ul>
                  <li>
                    <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                      Upload a CSV or Excel spreadsheet of your contractors.
                    </WSText.ParagraphSm>
                  </li>
                </ul>

                <li>
                  <WSText.ParagraphSm color="gray600" weight="medium">
                    Confirm contractors information
                  </WSText.ParagraphSm>
                </li>
                <ul>
                  <li>
                    <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                      Review your imported contractors to ensure their
                      information is correct.
                    </WSText.ParagraphSm>
                  </li>
                </ul>

                <li>
                  <WSText.ParagraphSm color="gray600" weight="medium">
                    Invite contractors
                  </WSText.ParagraphSm>
                </li>
                <ul>
                  <li>
                    <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                      New contractors will be automatically invited to Wingspan.
                    </WSText.ParagraphSm>
                  </li>
                </ul>
              </ol>
            </WSInfoBox>
          </WSCard>
          <WSButtons mb="XL">
            <CSVLink
              {...csvProps}
              className={styles.csvLink}
              onClick={initiateBulkUpload}
            >
              <WSButton.Secondary fullWidth icon="download">
                Download example
              </WSButton.Secondary>
            </CSVLink>
            <WSButton
              className={styles.nextBtn}
              name="bulkImportCollaboratorsStep1"
              icon="upload"
              fullWidth
              onClick={initiateBulkUpload}
              loading={createBulkCollaboratorBatchMeta.isLoading}
            >
              Upload contractors
            </WSButton>
          </WSButtons>

          {qFeatureFlags.data?.bulkImporterCollaborator ? (
            <WSFlexBox.CenterY mb="L">
              <WSText.ParagraphSm singleLine color="gray500">
                Looking for past imports?
              </WSText.ParagraphSm>
              <WSButton.Link
                icon="details"
                rightIcon="chevron-right"
                size="M"
                name="viewRoutingNumber"
                onClick={() => history.push("/member/imports/collaborators")}
              >
                See imports
              </WSButton.Link>
            </WSFlexBox.CenterY>
          ) : null}
          <WSFlexBox.CenterY>
            <WSText.ParagraphSm singleLine color="gray500">
              Looking to bulk upload payments?
            </WSText.ParagraphSm>
            <WSButton.Link
              icon="details"
              rightIcon="chevron-right"
              size="M"
              name="viewRoutingNumber"
              onClick={() =>
                history.push("/member/invoices/payables/bulk-upload/info")
              }
            >
              Bulk upload payments
            </WSButton.Link>
          </WSFlexBox.CenterY>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "5" }}>
          <CommonUseCasesPanel />
        </WSGrid.Item>
      </WSGrid>
    </Layout>
  );
};
