import {
  toWSDateString,
  WSActions,
  WSCard,
  WSControl,
  WSControlGroup,
  WSForm,
  WSInfoBox,
  WSInputDate,
  WSInputTextarea,
  WSList
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { FlowMEChangeContext } from "..";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { openIntercom } from "../../../../../shared/utils/intercom";
import { convertDateToEndDay } from "../../../../../utils/dates";
import { PATH_SETTINGS_ACCOUNT_INFO } from "../../../../Settings/routes/RouteAccount";
import { OnboardingStep } from "../../../types";

export type ChangeType =
  | "business-structure"
  | "tax-id"
  | "tax-residency"
  | "legal-name"
  | "correction";

export type FormDataReason = {
  changeType: ChangeType;
  effectiveAt: Date;
  reason: string;
  consent: true;
};

function getValidatorEffectiveAtDate(currentEntityStartDate?: Date) {
  const todayEod = convertDateToEndDay(new Date());

  if (currentEntityStartDate) {
    currentEntityStartDate.setHours(0, 0, 0, 0);
    return Yup.date()
      .nullable()
      .min(
        currentEntityStartDate,
        `The effective date cannot be before the start date of your current entity (${toWSDateString(
          currentEntityStartDate,
          "monthDayYear"
        )}).`
      )
      .max(
        todayEod,
        "The effective date cannot be in the future. Select a valid past or current date."
      )
      .required("Please enter the effective date of your change.");
  }

  return Yup.date()
    .nullable()
    .max(todayEod, "Can not be in the future")
    .required("Please enter the effective date of your change.");
}

export const StepReason: React.FC<OnboardingStep> = props => {
  const history = useHistory();
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();
  const queryMonitoredEntity = useQueryCustomerEntity();

  const currentEntityStartDate = useMemo(
    () => queryMonitoredEntity.data?.startDate,
    [queryMonitoredEntity.data?.startDate]
  );

  return (
    <WSForm<FormDataReason>
      defaultValues={context.reason}
      validationSchema={Yup.object().shape({
        changeType: Yup.string().required("Required"),
        effectiveAt: getValidatorEffectiveAtDate(currentEntityStartDate),
        reason: Yup.string().required("Required"),
        consent: Yup.boolean().isTrue("Required").required("Required")
      })}
      onSubmit={data => {
        setContext(prev => ({ ...prev, reason: data }));
        props.onNext?.();
      }}
    >
      <WSList gap="2XL">
        <WSCard header="Key points to remember">
          <WSInfoBox>
            <ul>
              <li>
                <b>Split tax forms</b>: Occur when critical tax information
                changes during the tax year, leading to income being reported
                under different TINs.
              </li>
              <li>
                <b>Consistency of TIN</b>: If your TIN remains the same, changes
                like updating your name generally do not result in split tax
                forms.
              </li>
              <li>
                <b>Importance of accuracy</b>: Keeping your tax information
                accurate ensures compliance.
              </li>
              <li>
                <b>Re-certification</b>: Significant changes often require
                re-certifying your tax forms and acknowledgements.
              </li>
              <li>
                <b>Consult a tax professional</b>: If unsure about tax
                implications, consult a tax advisor.
              </li>
            </ul>
          </WSInfoBox>
        </WSCard>

        <WSForm.Field
          name="changeType"
          component={WSControlGroup}
          componentProps={{
            type: "radio",
            chip: true,
            options: [
              {
                label:
                  "Option 1: Business structure change (e.g., from Sole Proprietorship to LLC)",
                value: "business-structure",
                description: (
                  <>
                    <b>You have changed your business structure.</b> For
                    example, you were operating as a sole proprietorship and
                    have now formed an LLC, corporation, or partnership. This
                    change alters the legal entity under which you conduct
                    business.
                  </>
                ),
                activeDescription: (
                  <>
                    <p>
                      <b>Tax implications:</b>
                    </p>
                    <ul>
                      <li>
                        <b>New Tax Identification Number (possible):</b>{" "}
                        Changing your business structure may require you to
                        obtain a new Employer Identification Number (EIN). Same
                        TIN: If you remain a sole proprietor or a single-member
                        LLC with no employees, you might continue using your
                        Social Security Number (SSN). New TIN: Forming a
                        corporation or partnership typically requires a new EIN.
                      </li>
                      <li>
                        <b>Tax classification changes:</b> Your business income
                        may now be taxed differently, affecting self-employment
                        taxes, deductions, and reporting requirements.
                      </li>
                      <li>
                        <b>New tax forms:</b> You need to submit updated tax
                        forms reflecting your new business entity type and tax
                        classification.
                      </li>
                      <li>
                        <b>Split tax forms:</b> If the change occurs during the
                        tax year and involves a new TIN, you may receive
                        separate tax forms for each entity.
                      </li>
                    </ul>
                  </>
                )
              },
              {
                label:
                  "Option 2: Change of Tax Identification Number (TIN) (e.g., obtaining an EIN)",
                value: "tax-id",
                description: (
                  <>
                    <b>
                      You have obtained a new or updated Tax Identification
                      Number (TIN).
                    </b>{" "}
                    This could be because you applied for an EIN for your
                    business, received a new SSN, or were assigned a different
                    tax ID in your country.
                  </>
                ),
                activeDescription: (
                  <>
                    <p>
                      <b>Tax implications:</b>
                    </p>
                    <ul>
                      <li>
                        <b>Reporting under new TIN</b>: All income must be
                        reported under your new TIN. If the change occurs
                        mid-year, this may result in split tax forms.
                      </li>
                      <li>
                        <b>Avoiding backup withholding</b>: Providing an
                        accurate TIN prevents backup withholding at 24% (for
                        U.S. persons).
                      </li>
                      <li>
                        <b>IRS notification</b>: Ensure your new TIN is
                        registered with the IRS or your local tax authority.
                      </li>
                    </ul>
                  </>
                )
              },
              {
                label:
                  "Option 3: Change in tax residency or citizenship status",
                value: "tax-residency",
                description: (
                  <>
                    <b>
                      You have changed your country of tax residence or
                      citizenship.
                    </b>{" "}
                    This could be due to moving to a new country, becoming a
                    U.S. citizen or resident for tax purposes, or ceasing to be
                    one.
                  </>
                ),
                activeDescription: (
                  <>
                    <p>
                      <b>Tax implications:</b>
                    </p>
                    <ul>
                      <li>
                        <b>New tax form requirement</b>: Submit a different tax
                        form that corresponds to your new tax residency status.
                      </li>
                      <li>
                        <b>Tax withholding changes</b>: Your tax withholding
                        rates and obligations may change.
                      </li>
                      <li>
                        <b>Reporting obligations</b>:
                        <ul>
                          <li>
                            <b>U.S. persons</b>: Receive a Form 1099-NEC for
                            income reporting to the IRS.
                          </li>
                          <li>
                            <b>Non-U.S. persons</b>: May be subject to different
                            reporting requirements.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>Split tax forms</b>: If the change occurs mid-year,
                        you may receive multiple tax forms.
                      </li>
                    </ul>
                  </>
                )
              },
              {
                label: "Option 4: Change in legal name or business name",
                value: "legal-name",
                description: (
                  <b>
                    You have legally changed your personal name (e.g., due to
                    marriage) or your business has changed its legal name.
                  </b>
                ),
                activeDescription: (
                  <>
                    <p>
                      <b>Tax implications:</b>
                    </p>
                    <ul>
                      <li>
                        <b>TIN consistency</b>: If your TIN remains the same,
                        changing your name does not typically result in split
                        tax forms.
                      </li>
                      <li>
                        <b>IRS notification</b>: Update your name with the IRS
                        to prevent mismatches.
                      </li>
                      <li>
                        <b>Form updates</b>: Submit a new tax form with your
                        updated legal name.
                      </li>
                      <li>
                        <b>Avoiding mismatches</b>: Ensures accurate payment
                        processing.
                      </li>
                    </ul>
                  </>
                )
              }
            ]
          }}
        />

        <WSCard>
          <WSInfoBox title="Other types of changes" transparent>
            <p>
              <b>
                If you need to update minor personal or business information.
              </b>{" "}
              Minor updates such as address or website can be updated directly
              from your{" "}
              <a
                onClick={() => {
                  history.push(PATH_SETTINGS_ACCOUNT_INFO);
                }}
              >
                account settings
              </a>
              . Only proceed with an update request if there is a field you
              cannot update from the account settings.
            </p>
            <p>
              <b>
                If previously submitted tax information is erroneous or contains
                omitted information.
              </b>{" "}
              This may involve fixing mistakes (rather than updates) in your
              personal details, address, tax identification numbers, or business
              information to ensure accuracy and compliance with tax
              regulations. To correct error(s) please{" "}
              <a onClick={openIntercom}>contact support</a>.
            </p>
          </WSInfoBox>
        </WSCard>

        <WSCard>
          <WSList gap="2XL">
            <WSForm.Field
              label="Effect date of change"
              name="effectiveAt"
              component={WSInputDate}
              componentProps={{
                required: true,
                helperText:
                  "Indicate the date when the reported change took effect"
              }}
            />

            <WSForm.Field
              label="Details about the change"
              name="reason"
              component={WSInputTextarea}
              componentProps={{
                required: true,
                placeholder:
                  "Provide as much detail as possible regarding the reason for this change"
              }}
            />

            <WSForm.Field
              name="consent"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                size: "S",
                label:
                  "By proceeding with this request, I agree to provide accurate information, share the changes with my client(s) and promptly provide any additional documentation that may be required to verify these changes."
              }}
            />
          </WSList>
        </WSCard>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              onClick: props.onBack,
              kind: "Secondary",
              type: "button",
              visible: !!props.onBack
            },
            {
              label: "Continue"
            }
          ]}
        />

        <WSInfoBox transparent>
          <p>
            If your situation doesn't match any of the scenarios described
            above, please contact Wingspan Support for assistance. When reaching
            out, please provide:
          </p>

          <ul>
            <li>A detailed explanation of your current situation.</li>
            <li>
              Any relevant changes to your tax status, personal information, or
              business details.
            </li>
            <li>
              Specific questions or concerns you have regarding your tax
              information.
            </li>
          </ul>

          <p>
            Our support team will help you determine the appropriate steps to
            take to ensure your tax information is accurate and compliant.
          </p>
        </WSInfoBox>
      </WSList>
    </WSForm>
  );
};
