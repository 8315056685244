import { useLocation } from "react-router-dom";
import { TAX_DOCUMENTS_PATH } from "../paths";
import { OLD_TAX_DOCUMENTS_PATH } from "../../TaxDocumentsOld/paths";

export const TAX_DOCUMENTS_YEAR_OPTIONS = [
  { value: "2024", label: "2024", path: TAX_DOCUMENTS_PATH },
  { value: "2023", label: "2023", path: OLD_TAX_DOCUMENTS_PATH }
];

export const useCurrentTaxDocumentsYear = () => {
  const { pathname } = useLocation();

  const currentOption = TAX_DOCUMENTS_YEAR_OPTIONS.find(p =>
    pathname.startsWith(p.path)
  );

  const currentYear = currentOption?.value ? Number(currentOption.value) : 2024;

  return currentYear as 2023 | 2024;
};
