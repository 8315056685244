import React, { ReactNode } from "react";
import { classNames as cn } from "primereact/utils";

import { WSFlexBox } from "../layout";
import {
  WSElement,
  WSMarginProps,
  WSPaddingProps
} from "../WSElement/WSElement.component";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";

import styles from "./WSEmptyState.module.scss";
import { WSOrientation } from "../../types/utils";
import { emptyStateImages, WSEmptyStateType } from "../../emptyStates";

export interface WSEmptyStateProps extends WSMarginProps, WSPaddingProps {
  type: WSEmptyStateType;
  title: string;
  description?: string | ReactNode;
  className?: string;
  buttons?: WSButtonProps[];
  orientation?: WSOrientation;

  fullWidth?: boolean;
  size?: "S" | "M";
  hasBorder?: boolean;
  bgColor?: "transparent" | "white" | "gray";
  theme?: "neutral" | "garnet";
}

export const WSEmptyState: React.FC<WSEmptyStateProps> = ({
  type,
  orientation = "vertical",
  hasBorder,
  bgColor = "transparent",
  theme = "neutral",
  size = "M",
  fullWidth = true,

  title,
  description,
  buttons,
  className,
  ...otherProps
}) => {
  const SVG = emptyStateImages[theme][type];
  return (
    <WSElement
      px="M"
      py="L"
      className={cn(
        styles[`orientation-${orientation}`],
        styles[`bg-${bgColor}`],
        {
          [styles.border]: hasBorder,
          [styles.fullWidth]: fullWidth,
          [styles.container]: !fullWidth
        },
        className
      )}
      {...otherProps}
    >
      <WSFlexBox.Center
        direction={orientation === "vertical" ? "column" : "row"}
      >
        <SVG className={styles[`img-${size}`]} />
        <WSFlexBox
          ml="XS"
          direction="column"
          className={cn(styles.textContent, styles[`size-${size}`])}
        >
          <WSText
            kind={size === "M" ? "Heading5" : "Paragraph"}
            className={styles.wordBreak}
            mb="XS"
            color="gray600"
            weight="medium"
          >
            {title}
          </WSText>
          {description && (
            <WSText.ParagraphSm
              className={styles.description}
              align={orientation === "vertical" ? "center" : "left"}
              mb={orientation === "vertical" ? "XL" : "M"}
              color="gray600"
              weight="book"
            >
              {description}
            </WSText.ParagraphSm>
          )}
          {buttons?.map((button, index) => (
            <WSButton
              className={styles.button}
              size="M"
              key={button.label}
              {...button}
            />
          ))}
        </WSFlexBox>
      </WSFlexBox.Center>
    </WSElement>
  );
};
