import {
  IWebflowEnterprise,
  IWebflowPartner,
  IWebflowPartnerSignUpTypes
} from "@wingspanhq/cms/dist/lib/interfaces";
import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  EmailVerificationStatus,
  FreelanceType,
  IActivity,
  IClient,
  IGrantedSubscriberResponse,
  IGrowthSourceNames,
  IMember,
  ISubscription,
  PhoneVerificationStatus,
  PlatformContextType,
  SubscriptionPackage,
  SubscriptionPackageTier,
  SubscriptionStatus,
  SubscriptionTerm,
  UserStatus,
  WingspanProductAspects,
  WingspanProducts
} from "@wingspanhq/users/dist/lib/interfaces";
import { queryCache } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { CURRENT_YEAR } from "../../modules/TaxFiling/constants/currentYear";
import {
  PATH_ONBOARDING_PAYEE,
  PATH_ONBOARDING_PAYER,
  PATH_ONBOARDING_TAX_PAYEE,
  PATH_ONBOARDING_TAX_PAYER
} from "../../modules/Onboarding";
import { useSwitchingAccount } from "../../modules/SelectOrganizationAccount/utils/useSwitchingAccount";
import { QUERY_BOOKKEEPING_BANKING_ACCOUNT } from "../../query/bookkeeping/keys";
import {
  QUERY_CMS_WEBFLOW_ENTERPRISE,
  QUERY_CMS_WEBFLOW_PARTNER
} from "../../query/cms/keys";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import { QUERY_CUSTOMER_ENTITY } from "../../query/onboarding/queries/useQueryCustomerEntity";
import { QUERY_VERIFICATIONS } from "../../query/onboarding/queries/useQueryVerifications";
import {
  getCurrentEntepriseId,
  getCurrentPartnerId
} from "../../query/platform/selectors";
import { QUERY_SUBSCRIPTION_V3 } from "../../query/subscriptions/keys";
import { QUERY_TAXES_WITHHOLDING_BALANCE } from "../../query/taxes/keys";
import {
  QUERY_AUTHORIZED_ACCOUNTS,
  QUERY_USERS_ACTIVITIES,
  QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE,
  QUERY_USERS_CLIENT,
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "../../query/users/keys";
import { getCustomerEntity } from "../../services/api/onboarding/customer";
import { getVerifications } from "../../services/api/onboarding/verifications";
import bookkeepingService from "../../services/bookkeeping";
import { getWebflowEnterprise, getWebflowPartner } from "../../services/cms";
import { paymentsService } from "../../services/payments";
import { getAdminSessionToken } from "../../services/sessionStorage";
import { usersService } from "../../services/users";
import { useSetWSStore, useWSStore, WSStore } from "../../store";
import { prefetchData } from "./prefetchData";
import { QUERY_USER_LOCATION } from "../../query/user";

export const useInitMemberApp = () => {
  const history = useHistory();
  const location = useLocation();
  const userId = useUserId();
  const store = useWSStore();
  const setStore = useSetWSStore();
  const runSwitchAccount = useSwitchingAccount();

  return useWSMutation(
    async () => {
      // ----------------------------------------------------------------
      // Init data
      // ----------------------------------------------------------------

      const [
        user,
        member,
        client,
        activity,
        customerEntity,
        verificaitons,
        authorizedAccounts
      ] = await Promise.all([
        initUser(userId),
        initMember(userId),
        initClient(userId),
        initActivity(userId, store),
        initCustomerEntity(),
        initVerifications(),
        initAuthorizedAccounts(userId),
        initOrganizationAccountsExistence(userId, setStore)
      ]);

      // Init enterprise and partner
      const [enterprise, partner] = await Promise.all([
        initEnterprise(activity, store),
        initPartner(activity, store)
      ]);

      await Promise.all([initBankingAccount(), initTaxWihholdingBalance()]);

      // Init subscription
      // !!! There is no await keyword intentionally !!!
      // Subscription initialization can be done in parallel with app initialization, it should not block app initialization
      initSubscription(userId, store, partner);

      // Init location
      // !!! There is no await keyword intentionally !!!
      // Location initialization is used for the onboarding flow to autofill the country field,
      // and it should not block app initialization
      initLocation();

      // ----------------------------------------------------------------
      // Redirects
      // ----------------------------------------------------------------

      const hasPayerContext = user.contexts?.some(
        c => c.type === PlatformContextType.Payer
      );
      const hasPayeeContext = user.contexts?.some(
        c => c.type === PlatformContextType.Payee
      );

      const hasTaxPayeeContext = user.contexts?.some(
        c => c.type === PlatformContextType.TaxOnlyPayee
      );

      const hasTaxPayerContext = user.contexts?.some(
        c => c.type === PlatformContextType.TaxOnlyPayer
      );

      const isWSAdmin = !!getAdminSessionToken();

      // Users which signs up via team invite link and has access to at least one account, should be redirected to this account skipping all verifications and onboarding.
      if (authorizedAccounts.length > 0) {
        const isAccountSwitched = await runSwitchAccount({
          userId,
          accounts: authorizedAccounts,
          member: member,
          switchToFirst:
            store.signUpType === IWebflowPartnerSignUpTypes.TeamsInvite
        });

        if (isAccountSwitched) {
          return;
        }
      }

      // Redirect user to the onboarding flow if user has not completed it
      else if (
        hasPayerContext ||
        hasPayeeContext ||
        hasTaxPayeeContext ||
        hasTaxPayerContext
      ) {
        const paymentsSettings = await paymentsService.service.get();

        const isOldTaxVerified =
          paymentsSettings.verifications.taxDocumentation ===
          VerificationStatus.Verified;

        const isBankingVerificationNone =
          !verificaitons?.banking || verificaitons.banking === "None";
        const isTaxVerificationNone =
          !verificaitons?.tax || verificaitons.tax === "None";

        // Skip new onboarding if this is old user
        if (!isOldTaxVerified) {
          if (hasPayerContext && isBankingVerificationNone) {
            history.replace(PATH_ONBOARDING_PAYER);
            return;
          }

          if (hasPayeeContext && isTaxVerificationNone) {
            history.replace(PATH_ONBOARDING_PAYEE);
            return;
          }

          if (hasTaxPayeeContext && isTaxVerificationNone) {
            history.replace(PATH_ONBOARDING_TAX_PAYEE);
            return;
          }

          if (
            hasTaxPayerContext &&
            isBankingVerificationNone &&
            !activity.flows.nec1099Setup?.complete
          ) {
            history.replace(PATH_ONBOARDING_TAX_PAYER);
            return;
          }
        }
      } else {
        if (
          user.phone.status !== PhoneVerificationStatus.Verified &&
          !isWSAdmin &&
          location.pathname !== "/member/verify/email" &&
          location.pathname !== "/member/verify/phone-number" &&
          location.pathname !==
            "/member/authorize/verification/email/confirm" &&
          !location.pathname.includes("/member/onboarding")
        ) {
          history.replace("/member/verify/phone-number");
          return;
        }
      }

      // Force user to set a password if there is no password set
      if (
        user.status === UserStatus.Pending &&
        user.emailVerificationState?.status ===
          EmailVerificationStatus.Pending &&
        !user.isPasswordSet &&
        !isWSAdmin
      ) {
        history.replace("/member/onboarding/set-password");
        return;
      }

      if (
        location.pathname === "/member/sign-in" &&
        (await getShouldRedirectToTaxDocuments())
      ) {
        history.replace("/member/tax-documents");
        return;
      }

      // After signing in redirect user to the stored in the hash location
      if (location.pathname === "/member/sign-in" && location.hash) {
        history.replace(location.hash.slice(1));
        return;
      }

      // After signing in redirect user to the invoices page if user has at least one invoice
      if (
        location.pathname === "/member/sign-in" &&
        (await getHasAtLeastOneInvoice())
      ) {
        history.replace("/member/invoices");
        return;
      }

      // After regular sign in redirect user to the dashboard page
      if (location.pathname === "/member/sign-in") {
        history.replace("/member/dashboard");
        return;
      }
    },
    {
      onSuccess: prefetchData
    }
  );
};

const getShouldRedirectToTaxDocuments = async () => {
  if (!isAfterJan22AndBeforeFeb9()) {
    return false;
  }

  try {
    const taxForms = await paymentsService.taxForm.payeeList(CURRENT_YEAR);
    const confirmedTaxDocuments = taxForms.filter(
      taxForm => taxForm.recipientConfirmedW9Info
    );
    return confirmedTaxDocuments.length > 0;
  } catch {
    return false;
  }
};

const initLocation = async () => {
  try {
    const location = await usersService.user.location.get();
    queryCache.setQueryData(QUERY_USER_LOCATION, location);
  } catch (error) {
    console.error("App init: location init error", error);
  }
};

const isAfterJan22AndBeforeFeb9 = () => {
  const today = new Date();
  const jan22 = new Date(today.getFullYear(), 0, 22);
  const feb9 = new Date(today.getFullYear(), 1, 9, 23, 59, 59);

  return jan22 < today && today < feb9;
};

const initUser = async (userId: string) => {
  try {
    const user = await usersService.user.get(userId);

    queryCache.setQueryData(QUERY_USERS_USER_PROFILE, user);

    return user;
  } catch (error) {
    console.error("App init: user init error", error);
    throw error;
  }
};

const initOrganizationAccountsExistence = async (
  userId: string,
  setStore: ReturnType<typeof useSetWSStore>
) => {
  try {
    const listSize = await usersService.organization.users.listSize();
    const xWingspanExpansion = listSize ? userId : undefined;

    setStore({ xWingspanExpansion });

    queryCache.setQueryData(
      [QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE, undefined],
      listSize
    );

    return listSize;
  } catch (error) {
    return [];
  }
};

const initAuthorizedAccounts = async (userId: string) => {
  try {
    const authorizedAccounts = await usersService.authorization.accounts(
      userId
    );

    queryCache.setQueryData(
      [QUERY_AUTHORIZED_ACCOUNTS, userId],
      authorizedAccounts
    );

    return authorizedAccounts;
  } catch (error) {
    return [];
  }
};

const initMember = async (userId: string) => {
  try {
    let member: IMember;

    try {
      member = await usersService.member.get(userId, {
        headers: { "x-ws-fe-flow": "init-member" }
      });
    } catch (error) {
      if ((error as any)?.response?.status === 400) {
        const questionnaire = getMemberPreSignUpQuestionnaire();

        member = await usersService.member.create(userId, {
          userId,
          profile: {
            ...questionnaire
          }
        });
      } else throw error;
    }

    queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);

    return member;
  } catch (error) {
    console.error("App init: member init error", error);
    throw error;
  }
};

const initClient = async (userId: string) => {
  try {
    let client: IClient | undefined;

    try {
      client = await usersService.client.get(userId, {
        headers: { "x-ws-fe-flow": "init-client" }
      });
    } catch (error) {
      if ((error as any)?.response?.status === 400) {
        client = await usersService.client.create(userId, { userId });
      } else throw error;
    }

    queryCache.setQueryData(QUERY_USERS_CLIENT, client);

    return client;
  } catch (error) {
    console.error("App init: client init error", error);
  }
};

const initActivity = async (userId: string, store: WSStore) => {
  try {
    let activity = await usersService.activity.get(userId);
    if (
      store.signUpType === IWebflowPartnerSignUpTypes.CollaboratorPayments ||
      store.signUpType === IWebflowPartnerSignUpTypes.BenefitsOnly ||
      store.growthAttributionDetails
    ) {
      activity = await usersService.activity.update(userId, {
        events: {
          benefitsOnlySignup:
            store.signUpType === IWebflowPartnerSignUpTypes.BenefitsOnly
              ? true
              : undefined
        },
        context: {
          growthSource: store.growthAttributionDetails
            ?.growthSource as unknown as IGrowthSourceNames,
          growthSourceName: store.growthAttributionDetails?.growthName
        }
      });
    }

    queryCache.setQueryData(QUERY_USERS_ACTIVITIES, activity);

    return activity;
  } catch (error) {
    console.error("App init: activity init error", error);
    throw error;
  }
};

const initCustomerEntity = async () => {
  try {
    const customerEntity = await getCustomerEntity();

    queryCache.setQueryData(QUERY_CUSTOMER_ENTITY, customerEntity);

    return customerEntity;
  } catch (error) {
    if ((error as any)?.response?.status === 404) {
      return;
    }
    console.error("App init: customer entity init error", error);
    throw error;
  }
};

const initVerifications = async () => {
  try {
    const verifications = await getVerifications();

    queryCache.setQueryData(QUERY_VERIFICATIONS, verifications);

    return verifications;
  } catch (error) {
    if ((error as any)?.response?.status === 400) {
      return;
    }

    console.error("App init: verifications init error", error);
    throw error;
  }
};

const initSubscription = async (
  userId: string,
  store: WSStore,
  partner?: IWebflowPartner
) => {
  try {
    let subscription: ISubscription | undefined;
    try {
      subscription = await usersService.subscriptionV3.get(userId, {
        headers: { "x-ws-fe-flow": "init-subscription" }
      });
    } catch (e) {
      if ((e as any)?.response?.status === 404) {
      } else {
        throw e;
      }
    }

    if (store.isEmployerSignUp) {
      // Create subscription if this employer signs up
      subscription = await usersService.subscriptionV3.create(userId, {
        package: SubscriptionPackage.Professional,
        packageTier: SubscriptionPackageTier.Basic,
        discountCode: "ENTERPRISEOFFPLATFORMBILLING",
        term: SubscriptionTerm.Yearly
      });
    } else if (store.discountCode || partner?.stripeDiscountCode) {
      // Apply discount code
      subscription = await usersService.subscriptionV3.create(userId, {
        discountCode: store.discountCode ?? partner?.stripeDiscountCode
      });
    }

    // Assign subscription grant
    let grantedSubscriberList: IGrantedSubscriberResponse[] = [];
    try {
      grantedSubscriberList = await usersService.grantedSubscriber.list();
    } catch (e) {}
    const userSubscriptionGrants =
      grantedSubscriberList?.filter(sg => sg.memberId === userId) || [];

    if (userSubscriptionGrants.length > 0) {
      if (
        subscription &&
        subscription.status === SubscriptionStatus.trialing &&
        !subscription.subscriptionGrantId
      ) {
        subscription = await usersService.subscriptionV3.update(userId, {
          subscriptionGrantId: userSubscriptionGrants[0].subscriptionGrantId
        });
      } else if (!subscription) {
        subscription = await usersService.subscriptionV3.create(userId, {
          subscriptionGrantId: userSubscriptionGrants[0].subscriptionGrantId
        });
      }
    }

    if (subscription) {
      queryCache.setQueryData(QUERY_SUBSCRIPTION_V3, subscription);
    }
  } catch (error) {
    console.error("App init: subscription init error", error);
  }
};

const initEnterprise = async (activity: IActivity, store: WSStore) => {
  try {
    let enterprise: IWebflowEnterprise | undefined;
    const enterpriseId = getCurrentEntepriseId(store, activity);
    if (enterpriseId) {
      enterprise = await getWebflowEnterprise(enterpriseId);

      queryCache.setQueryData(
        [QUERY_CMS_WEBFLOW_ENTERPRISE, enterpriseId],
        enterprise
      );
    }

    return enterprise;
  } catch (error) {
    console.error("App init: enterprise init error", error);
  }
};

const initPartner = async (activity: IActivity, store: WSStore) => {
  try {
    let partner: IWebflowPartner | undefined;
    const partnerId = getCurrentPartnerId(store, activity);
    if (partnerId) {
      partner = await getWebflowPartner(partnerId);

      queryCache.setQueryData([QUERY_CMS_WEBFLOW_PARTNER, partnerId], partner);
    }

    return partner;
  } catch (error) {
    console.error("App init: partner init error", error);
  }
};

const initBankingAccount = async () => {
  try {
    const banking = await bookkeepingService.getBankingAccount();
    queryCache.setQueryData(QUERY_BOOKKEEPING_BANKING_ACCOUNT, banking);
  } catch {}
};

const initTaxWihholdingBalance = async () => {
  try {
    const taxWithholdingBalance =
      await bookkeepingService.getWithholdingBalance();
    queryCache.setQueryData(
      QUERY_TAXES_WITHHOLDING_BALANCE,
      taxWithholdingBalance
    );
  } catch {}
};

const getHasAtLeastOneInvoice = async () => {
  try {
    const invoices = await paymentsService.invoice.list();
    return invoices.length > 0;
  } catch {
    return false;
  }
};

const getMemberPreSignUpQuestionnaire = (): {
  freelanceType?: FreelanceType;
  productImportance?: WingspanProducts[];
  productAspectImportance?: WingspanProductAspects[];
} => {
  try {
    const freelanceType = window.localStorage.getItem(
      "wingspan:pre-sign-up:q1"
    );
    const productImportance = window.localStorage.getItem(
      "wingspan:pre-sign-up:q2"
    );
    const productAspectImportance = window.localStorage.getItem(
      "wingspan:pre-sign-up:q3"
    );

    return {
      freelanceType: freelanceType
        ? (JSON.parse(freelanceType) as FreelanceType)
        : undefined,
      productImportance: productImportance
        ? [JSON.parse(productImportance) as WingspanProducts]
        : undefined,
      productAspectImportance: productAspectImportance
        ? [JSON.parse(productAspectImportance) as WingspanProductAspects]
        : undefined
    };
  } catch (error) {
    console.error(
      "App init: error while parsing member pre-signup questionnaire",
      error
    );
    return {};
  }
};
