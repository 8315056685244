import {
  useIsMobile,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import {
  useCreateSession,
  useIdentifyAuthentication,
  useSignInWithGoogle
} from "../../query/users/mutations";
import { ISignInWithGoogleRequest } from "../../services/users";
import { validatorEmail } from "../../shared/validators/validatorEmail";
import { validatorPassword } from "../../shared/validators/validatorPassword";
import { WS_LINKS } from "../../types/wsLinks";
import { Divider } from "../components/Divider/Divider";
import { FooterNotes } from "../components/FooterNotes/FooterNotes";
import { GoogleAuthButton } from "../components/GoogleAuthButton/GoogleAuthButton";
import { Header } from "../components/Header/Header";
import { TextField } from "../components/TextField/TextField";
import { KEY_REDIRECT_PATH } from "./AuthSSO";
import styles from "./styles.module.scss";

type RouteProps = RouteComponentProps;
type Props = RouteProps;

export const MemberSignIn: React.FC<Props> = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const location = useLocation();

  const [signInWithGoogleError, setSignInWithGoogleError] = useState("");

  const [signIn, signInMeta] = useCreateSession();
  const [signInWithGoogle, signInWithGoogleMeta] = useSignInWithGoogle();
  const [identifyAuth, identifyAuthMeta] = useIdentifyAuthentication();

  const [{ email, remember }, setFormData] = useState({
    email: "",
    remember: true
  });

  const onGoogleLoginSuccess = (request: ISignInWithGoogleRequest) => {
    signInWithGoogle({ ...request, context: "PublicSignUp" });
  };

  const onGoogleLoginFailure = (errorRes: any) => {
    setSignInWithGoogleError(errorRes.error);
  };

  return (
    <>
      <BrowserPageTitle title="Sign In" />

      <div className={styles.base}>
        <Header
          onBack={() => {
            if (email) {
              signInMeta.reset();
              setFormData({
                email: "",
                remember: true
              });
            } else {
              history.goBack();
            }
          }}
        />
        <main className={styles.main}>
          <div className={styles.headingWrapper}>
            <WSText kind={isMobile ? "Heading5" : "Heading1"}>Sign in</WSText>
          </div>
          <div className={styles.formWrapper}>
            <GoogleAuthButton
              onLoginSuccess={onGoogleLoginSuccess}
              onLoginFailure={onGoogleLoginFailure}
            >
              Sign in with Google
            </GoogleAuthButton>

            <Divider or />

            <WSFormOld
              defaultValues={{
                email,
                password: "",
                remember
              }}
              validationSchema={Yup.object().shape({
                email: validatorEmail.required("Email is required"),
                password: email
                  ? validatorPassword.required("Password is required")
                  : Yup.string(),
                remember: Yup.boolean()
              })}
              onSubmit={async values => {
                if (email) {
                  await signIn(values);
                } else {
                  const response = await identifyAuth(
                    { email: values.email },
                    { throwOnError: true }
                  );

                  if (response?.authenticationStrategy === "SingleSignOn") {
                    const redirectPath = location.hash?.slice(1);
                    if (redirectPath) {
                      sessionStorage.setItem(KEY_REDIRECT_PATH, redirectPath);
                    }
                    window.open(response?.authenticationUrl, "_self");
                  } else {
                    setFormData({
                      email: values.email,
                      remember: values.remember
                    });
                  }
                }
              }}
            >
              <WSFormOld.Field
                mb="XL"
                name="email"
                label="Email Address"
                component={WSTextInput}
                hidden={!!email}
                componentProps={{
                  type: "email"
                }}
              />

              {email ? (
                <WSFlexBox.CenterY
                  py="M"
                  mb="XL"
                  className={styles.emailPreview}
                  wrap="nowrap"
                >
                  <WSFlexBox.Center mr="M" className={styles.userIcon}>
                    <WSIcon block name="user" color="gray400" />
                  </WSFlexBox.Center>

                  <WSElement className={styles.userDetails}>
                    <WSText singleLine>{email}</WSText>
                    <WSButton.Link
                      type="button"
                      onClick={() => {
                        signInMeta.reset();
                        setFormData({
                          email: "",
                          remember: true
                        });
                      }}
                    >
                      Not you?
                    </WSButton.Link>
                  </WSElement>
                </WSFlexBox.CenterY>
              ) : null}

              <WSFormOld.Field
                name="password"
                hidden={!email}
                component={TextField}
                componentProps={{
                  type: "password",
                  label: "Password",
                  labelLink: {
                    to: "/member/reset-password",
                    text: "Forgot password?",
                    testId: "resetPasswordLink"
                  }
                }}
              />

              <WSFormOld.Field
                my="XL"
                name="remember"
                component={WSCheckboxToggle}
                componentProps={{ label: "Stay signed in" }}
              />

              <WSErrorMessage
                my="XL"
                contextKey="Auth"
                forceShowApiErrors
                error={
                  signInMeta.error ||
                  signInWithGoogleMeta.error ||
                  signInWithGoogleError ||
                  identifyAuthMeta.error
                }
              />

              <WSFormOld.SubmitButton
                size="L"
                fullWidth
                data-testid="signInBtn"
                disabled={identifyAuthMeta.isLoading}
              >
                {email ? "Sign in" : "Next"}
              </WSFormOld.SubmitButton>
            </WSFormOld>

            <Divider />

            <WSText.Heading5 mb="XL">New here?</WSText.Heading5>
            <WSButton.Secondary
              size="L"
              fullWidth
              type="button"
              onClick={() => {
                history.push("/member/pre-sign-up");
              }}
              data-testid="signUpLink"
            >
              Create an account
            </WSButton.Secondary>

            <FooterNotes>
              By proceeding you agree to Wingspan's{" "}
              <a
                href={WS_LINKS.wingspanTosAcceptance}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>{" "}
              &{" "}
              <a
                href={WS_LINKS.wingspanPrivacyPolicyAcceptance}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . This site is protected by reCAPTCHA and the Google{" "}
              <a
                href={WS_LINKS.googlePrivacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href={WS_LINKS.googleTermOfService}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{" "}
              apply.
            </FooterNotes>
          </div>
        </main>
      </div>
    </>
  );
};
