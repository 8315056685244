import { servicePayments } from "../servicePayments";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { ListRequestQuery } from "../../utils/serviceHelper";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export type PayeeEngagementListResponse = {
  data: IPayeeEngagementListResponse;
  summary: {
    listSize: number;
  };
};

export const getPayeeEngagements = async (
  payeeId: string,
  orgPayerId: string | undefined,
  params?: ListRequestQuery
): Promise<PayeeEngagementListResponse> => {
  const { data, headers } = await servicePayments.get(
    `/payee/${payeeId}/engagement`,
    extendConfigWithXWingspanUserHeader((params as any) || {}, orgPayerId)
  );

  return { summary: { listSize: Number(headers["x-ws-list-size"]) }, data };
};
