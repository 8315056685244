import {
  useWSModal,
  WSActions,
  WSAlert,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { TextBulleted } from "../../../shared/components/TextBulleted";

interface VoidOriginalTaxFormWarningModalProps {
  onContinue(): void;

  onClose(): void;
}

const PayerVoidOriginalTaxFormWarningModal: React.FC<
  VoidOriginalTaxFormWarningModalProps
> = ({ onContinue, onClose }) => {
  return (
    <WSList gap="XL">
      <WSAlert
        theme="warning"
        icon="alert"
        title="Submit correction request to void original 1099 filing"
        mb="M"
      >
        The recipient classification is not eligible for a 1099 according to IRS
        guidelines. Please verify information request is accurate before
        proceeding.
        <WSText.ParagraphSm color="gray600" mt="L">
          You are choosing to submit with the following option:
        </WSText.ParagraphSm>
        <TextBulleted color="gray600">
          <li>Continue to submit a correction to void the original filing</li>
        </TextBulleted>
      </WSAlert>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Back to request",
            kind: "Tertiary",
            onClick: onClose
          },
          {
            label: "Continue to next step ",
            kind: "Primary",
            onClick: onContinue
          }
        ]}
      />
    </WSList>
  );
};

export const usePayerVoidOriginalTaxFormWarningModal = () =>
  useWSModal(PayerVoidOriginalTaxFormWarningModal, {
    title: "Are you sure?",
    size: "S"
  });
