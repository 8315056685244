import {
  WSActions,
  WSButton,
  WSCard,
  WSInfoBox,
  WSList,
  WSProgressBar
} from "@wingspanhq/fe-component-library";
import {
  BulkStatus,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import { formatNumber } from "accounting";
import React, { useEffect, useMemo } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { PreventLeave } from "../../../../../components/PreventLeave/PreventLeave";
import { WSQueries } from "../../../../../query/WSQuery";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Layout } from "../../../../../shared/components/Layout";
import { useBulkCalculate1099BatchSummary } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import {
  TAX_FILING_1099_FILING_DASHBOARD_PATH,
  useGoToTaxFiling
} from "../../../paths";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { useTaxFormSubmissionRowsSummaryQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";

const POLL_TIME_IN_MS = 3000;
export const RouteSubmitting: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const currentYear = useCurrentTaxFilingYear();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const goToDashboard = useGoToTaxFiling();

  const queryTaxFormRowSearchCount = useTaxFormSubmissionRowsSummaryQuery({
    filter: {
      yearString: `${currentYear}`,
      searchableStatus: TaxFormStatus.ReadyToSubmitToIrs
    }
  });

  const queryBulkCalculate1099Summary = useBulkCalculate1099BatchSummary(
    bulkBatchId,
    {},
    { refetchInterval: POLL_TIME_IN_MS }
  );

  const isFinished = useMemo(() => {
    const status = queryBulkCalculate1099Summary.data?.status;
    return status === BulkStatus.Complete || status === BulkStatus.Failed;
  }, [queryBulkCalculate1099Summary.data?.status]);

  useEffect(() => {
    const total = queryBulkCalculate1099Summary.data?.totalTaxForms || 0;
    const processed =
      queryBulkCalculate1099Summary.data?.processedTaxForms || 0;
    const failed = queryBulkCalculate1099Summary.data?.failedTaxForms || 0;

    if (isFinished && total) {
      if (processed === total && failed === 0) {
        onNext({ status: "Success", bulkBatchId });
      } else if (processed > 0 && failed > 0) {
        onNext({ status: "PartialSuccess", bulkBatchId });
      } else {
        onNext({ status: "Failed", bulkBatchId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryBulkCalculate1099Summary.data, bulkBatchId]);

  if (!bulkBatchId) {
    return <Redirect to={TAX_FILING_1099_FILING_DASHBOARD_PATH} />;
  }

  return (
    <Layout title="1099-NEC Filing">
      <WSQueries
        queries={{
          queryBulkCalculate1099Summary,
          queryTaxFormRowSearchCount
        }}
      >
        {({
          queryBulkCalculate1099SummaryData,
          queryTaxFormRowSearchCountData
        }) => {
          const total =
            queryBulkCalculate1099SummaryData.totalTaxForms ||
            queryTaxFormRowSearchCountData.listSize;
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;
          const importPercent = total ? (processed / total) * 100 : 0;
          const formattedTotal = formatNumber(total);
          const formattedProcessed = formatNumber(processed);

          return (
            <WSList gap="2XL">
              <WSCard
                header={`Wingspan is submitting ${total} tax forms to the IRS for filing`}
              >
                <WSProgressBar percent={importPercent} />
                <WSInfoBox mb="2XL">
                  {formattedProcessed} of {formattedTotal} Form 1099-NECs
                  submitted
                </WSInfoBox>

                <WSInfoBox transparent>
                  If you close this window while tax form submission is in
                  progress, it will appear on your filing dashboard until the
                  process is complete.{" "}
                  <WSButton.Link onClick={goToDashboard}>
                    Minimize progress and return to filing dashboard.
                  </WSButton.Link>
                </WSInfoBox>
              </WSCard>

              <WSActions
                alignment="fill"
                buttons={[
                  {
                    label: "Continue",
                    disabled: true
                  }
                ]}
              />

              <PreventLeave isEnabled={!isFinished} />
            </WSList>
          );
        }}
      </WSQueries>
    </Layout>
  );
};
