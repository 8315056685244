import {
  toWSMoneyString,
  useModalContext,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useWSQuery } from "../../../query/helpers";
import {
  getMemberAcceptedPayables,
  getPayables,
  getPayablesTotalAmount
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { paymentsService } from "../../../services/payments";
import { getAllEntries } from "../../../utils/serviceHelper";
import {
  BulkPreApproveModal,
  PAYABLES_BULK_PREAPPROVE
} from "../../components/NewPayablesTable/BulkPreApproveModal";
import {
  PayablesFilters,
  PayablesStatusFilter,
  PayablesTable,
  usePayablesQuery
} from "../../components/NewPayablesTable/PayablesTable";
import { PayableActionBlock } from "../../components/PayableActionBlock/PayableActionBlock";

const DEFAULT_FILTERS: PayablesFilters = {
  status: [PayablesStatusFilter.ToApprove],
  custom: {
    "client.workflowStatus": {
      notIn: [
        ClientWorkFlowStatus.PreApproved,
        ClientWorkFlowStatus.Approved,
        ClientWorkFlowStatus.PaymentInitiated,
        ClientWorkFlowStatus.Funded
      ]
    }
  }
};

export const PayablesDualStageToPreApprove: React.FC = () => {
  useBrowserPageTitle("Payables To Pre-Approve");
  const history = useHistory();
  const draftPayablesQuery = usePayablesQuery({
    status: [PayablesStatusFilter.Draft]
  });

  const toPreApprovePayablesQuery = useWSQuery(
    "PAYABLES_TO_PRE_APPROVE",
    async () => {
      const payablesToApprove = getPayables(
        await getAllEntries(
          async (...args) => {
            return (await paymentsService.payable.list(...(args as any))).data;
          },
          {
            status: {
              in: [
                InvoiceStatus.Open,
                InvoiceStatus.Overdue,
                InvoiceStatus.Pending
              ]
            },
            "client.workflowStatus": {
              notIn: [
                ClientWorkFlowStatus.PreApproved,
                ClientWorkFlowStatus.Approved,
                ClientWorkFlowStatus.PaymentInitiated,
                ClientWorkFlowStatus.Funded
              ]
            },
            "labels.invoiceType": {
              "!=": "approvedInvoicesPayment"
            },
            "labels.creationSource": {
              "!=": "personalPaylink"
            }
          }
        )
      );

      return {
        summary: {
          listSize: payablesToApprove.length,
          totalValue: getPayablesTotalAmount(payablesToApprove)
        },
        data: payablesToApprove
      };
    },
    {
      staleTime: Infinity,
      refetchOnMount: false
    }
  );

  const { openModal } = useModalContext();
  const [isPreApprovalInProgress, setIsPreApprovalInProgress] = useState(false);

  const preApproveAll = async () => {
    setIsPreApprovalInProgress(true);
    const payablesToPreApprove = toPreApprovePayablesQuery.data?.data || [];

    openModal(PAYABLES_BULK_PREAPPROVE, {
      data: payablesToPreApprove.map(payable => ({
        payable: {
          payableId: payable.payableId
        }
      })),
      onFinish: () => {
        setIsPreApprovalInProgress(false);
      }
    });
  };

  return (
    <>
      <WSText.Heading1>To Pre-Approve</WSText.Heading1>
      <WSText color="gray600" mb="2XL">
        All payables and requests that are not yet scheduled to be paid.
      </WSText>

      <BulkPreApproveModal />

      <WSElement mb="2XL">
        <WSQueries
          queries={{ draftPayablesQuery, toPreApprovePayablesQuery }}
          renderErrors={() => null}
        >
          {({
            draftPayablesQuery: {
              data: { summary: draftPayablesSummary }
            },
            toPreApprovePayablesQuery: {
              data: {
                data: payablesToApprove,
                summary: toPreApprovePayablesSummary
              }
            }
          }) => (
            <>
              {toPreApprovePayablesSummary.listSize > 1 && (
                <PayableActionBlock
                  mb="XL"
                  icon="dollar-circle"
                  rightButton={{
                    children: `Pre-approve ${toPreApprovePayablesSummary.listSize} payments`,
                    loading: isPreApprovalInProgress,
                    onClick: preApproveAll
                  }}
                  onClick={preApproveAll}
                >
                  <WSText color="gray500" mb="XS">
                    <WSText as="b" inline color="gray700">
                      Pending pre-approval:
                    </WSText>{" "}
                    {toPreApprovePayablesSummary.listSize} payables totaling{" "}
                    {toWSMoneyString(toPreApprovePayablesSummary.totalValue)}.
                  </WSText>

                  <WSText color="gray500">
                    {getMemberAcceptedPayables(payablesToApprove).length} have
                    been accepted
                  </WSText>
                </PayableActionBlock>
              )}

              {draftPayablesSummary.listSize > 0 && (
                <PayableActionBlock
                  onClick={() => {
                    history.push("/member/invoices/payables/draft");
                  }}
                  icon="alert-circle"
                  contentButton={{
                    children: "Manage draft payables"
                  }}
                >
                  <WSText color="gray500">
                    <WSText as="b" color="gray700">
                      You have {draftPayablesSummary.listSize} draft payable
                      {draftPayablesSummary.listSize > 1 ? "s" : ""}.
                    </WSText>
                  </WSText>
                </PayableActionBlock>
              )}
            </>
          )}
        </WSQueries>
      </WSElement>

      <PayablesTable
        bulkActions={["preapprove", "delete"]}
        visibleFilters={{
          payee: true,
          collaboratorReviewStatus: true,
          openedAtRange: true
        }}
        basePath="/member/invoices/payables/to-preapprove"
        defaultFilters={DEFAULT_FILTERS}
        showHeaders
        showInvoiceNumber
        showDateRequested
        showDueDate
        emptyState={{
          firstTime:
            "Every requested, scheduled, and paid payable will display here.",
          standart:
            "You're all caught up! Nothing is waiting for your pre-approval."
        }}
      />
    </>
  );
};
