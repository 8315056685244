import { WSPage, WSPageToolbar } from "@wingspanhq/fe-component-library";
import { Redirect, Route, Switch } from "react-router-dom";
import { PATH_SETTINGS } from "../..";
import { Tabs } from "../../../../components/Tabs";
import { RouteAddFundingMethod } from "./routes/RouteAddFundingMethod";
import { RouteManageFundingMethod } from "./routes/RouteManageFundingMethod";
import { RouteMethod } from "./routes/RouteMethod";
import { RoutePayables } from "./routes/RoutePayables";
import { RouteSchedule } from "./routes/RouteSchedule";

export const PATH_SETTINGS_PAYROLL = PATH_SETTINGS + "/payroll";
export const PATH_SETTINGS_PAYROLL_METHOD = PATH_SETTINGS_PAYROLL + "/funding";
export const PATH_SETTINGS_PAYROLL_SCHEDULE =
  PATH_SETTINGS_PAYROLL + "/schedule";
export const PATH_SETTINGS_PAYROLL_PAYABLES =
  PATH_SETTINGS_PAYROLL + "/payables";

export const PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD =
  PATH_SETTINGS_PAYROLL + "/manage-funding-method";
export const PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD =
  PATH_SETTINGS_PAYROLL + "/add-funding-method";

export const RoutePayroll: React.FC = () => {
  return (
    <WSPage>
      <WSPageToolbar title="Send payments settings" />

      <Tabs
        mb="2XL"
        tabs={[
          {
            label: "Payroll funding method",
            path: PATH_SETTINGS_PAYROLL_METHOD
          },
          {
            label: "Payroll schedule",
            path: PATH_SETTINGS_PAYROLL_SCHEDULE
          },
          {
            label: "Payables settings",
            path: PATH_SETTINGS_PAYROLL_PAYABLES
          }
        ]}
      />

      <Switch>
        <Route path={PATH_SETTINGS_PAYROLL_METHOD} component={RouteMethod} />
        <Route
          path={PATH_SETTINGS_PAYROLL_SCHEDULE}
          component={RouteSchedule}
        />
        <Route
          path={PATH_SETTINGS_PAYROLL_PAYABLES}
          component={RoutePayables}
        />

        <Route
          path={PATH_SETTINGS_PAYROLL_MANAGE_FUNDING_METHOD}
          component={RouteManageFundingMethod}
        />
        <Route
          path={PATH_SETTINGS_PAYROLL_ADD_FUNDING_METHOD}
          component={RouteAddFundingMethod}
        />

        <Redirect to={PATH_SETTINGS_PAYROLL_METHOD} />
      </Switch>
    </WSPage>
  );
};
