import {
  WSActionsProps,
  WSCard,
  WSDataItemProps,
  WSGrid,
  WSList,
  WSPillProps
} from "@wingspanhq/fe-component-library";
import { WSCardHeaderProps } from "@wingspanhq/fe-component-library/dist/lib/components/WSCard/WSCardHeader";
import React, { useMemo } from "react";
import { DataItem } from "./DataItem";
import { WSGridProps } from "@wingspanhq/fe-component-library/dist/lib/components/layout/WSGrid/WSGrid.types";

type Props = {
  title?: string;
  onEdit?: () => void;
  pill?: WSPillProps;
  header?: WSCardHeaderProps;
  actions?: WSActionsProps;
} & DataViewGridProps;

export const DataView: React.FC<Props> = ({
  header,
  loading,
  pill,
  title,
  onEdit,
  actions,
  data,
  fullWidth,
  children,
  ...otherProps
}) => {
  const headerView = useMemo(() => {
    if (header) {
      return header;
    }

    if (!title && !onEdit && !pill) {
      return;
    }

    if (onEdit || pill) {
      return {
        label: title,
        value: {
          pill: pill,
          action:
            !loading && onEdit
              ? {
                  label: "Edit",
                  onClick: onEdit
                }
              : undefined
        }
      };
    }

    return title;
  }, [header, onEdit, pill, title]);

  return (
    <WSCard divider header={headerView} actions={actions}>
      <WSList gap="2XL">
        <DataViewGrid
          data={data}
          loading={loading}
          fullWidth={fullWidth}
          {...otherProps}
        />
        {children}
      </WSList>
    </WSCard>
  );
};

interface DataItemProps extends WSDataItemProps {
  fullWidth?: boolean;
}

interface DataViewGridProps extends WSGridProps {
  data: (DataItemProps | React.ReactNode | null)[];
  fullWidth?: boolean;
  loading?: boolean;
}

export const DataViewGrid: React.FC<DataViewGridProps> = ({
  data,
  fullWidth,
  loading,
  ...otherProps
}) => (
  <WSGrid gutter="XL" {...otherProps}>
    {data.filter(Boolean).map((item, colIndex) => {
      if (!item) {
        return null;
      } else if (React.isValidElement(item)) {
        return (
          <WSGrid.Item shimmer={loading} key={String(colIndex)} span="12">
            {item}
          </WSGrid.Item>
        );
      } else if (typeof item === "object" && "label" in item) {
        return (
          <WSGrid.Item
            shimmer={loading}
            key={String(colIndex) + item.label}
            span={fullWidth || item.fullWidth ? "12" : { m: "6" }}
          >
            <DataItem {...item} />
          </WSGrid.Item>
        );
      }
    })}
  </WSGrid>
);
