import React from "react";
import {
  useWSModal,
  WSActions,
  WSAlert,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";

const ModalBeforeEdit: React.FC<{
  onClose(result: boolean): void;
}> = ({ onClose }) => {
  return (
    <WSList gap="XL">
      <WSText.ParagraphSm color="gray500">
        Are you sure you want to edit Form 1099-NEC details for this contractor?
      </WSText.ParagraphSm>

      <WSAlert
        theme="info"
        icon="info-circle"
        title="Manually edit tax form only"
      >
        Manually editing details on the 1099-NEC form will{" "}
        <WSText.ParagraphSm inline weight="medium">
          disable automatically synced updates from the contractor profile{" "}
        </WSText.ParagraphSm>
        to the tax form including taxpayer and payment information. Please be
        aware that if the contractor already shared their tax info with you,
        they will receive a copy of the submitted form(s) with your edits for
        their records. You can always undo your edits to resume automatic
        updates later (Any manual edits will be lost when you revert to synced
        information).
      </WSAlert>

      <WSInfoBox>
        If you’re looking to update taxpayer information on the contractor
        profile (and automatically sync to this tax form) click cancel and go to
        the contractor profile to edit.
      </WSInfoBox>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue to edit tax form",
            kind: "Primary",
            onClick: () => {
              onClose(true);
            }
          },
          {
            label: "Cancel",
            kind: "Secondary",
            onClick: () => {
              onClose(false);
            }
          }
        ]}
      />
    </WSList>
  );
};

export const useModalBeforeEdit = () =>
  useWSModal(ModalBeforeEdit, {
    title: "Edit Form 1099-NEC details",
    size: "S"
  });
