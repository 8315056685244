import { useMemo, useState } from "react";
import { useCurrentTaxFilingYear } from "../utils/useCurrentTaxFilingYear";
import {
  createTaxFormSubmissionListRequest,
  getTaxFormSubmissionViewDefaultFilters
} from "../../../query/search/taxForm/queries/filtering";
import { TaxFormSubmissionRequest } from "../../../services/search";
import { useTaxFormSubmissionRowsSummaryQuery } from "../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../query/payments/queries";
import { selectorCurrentYearFilingSettingsEnabled } from "../selectors/selectorCurrentYearFilingSettingsEnabled";

export const useIsTaxFilingDashboardEnabled = () => {
  const currentYear = useCurrentTaxFilingYear();

  const userId = useUserId();

  const defaultFilters = getTaxFormSubmissionViewDefaultFilters();
  const [count, setCount] = useState<number>();
  const [filingEnabled, setFilingEnabled] = useState<boolean>();

  const currentRequest = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, defaultFilters);
  }, []);

  usePayrollSettings(userId, {
    onSuccess(settings) {
      setFilingEnabled(
        selectorCurrentYearFilingSettingsEnabled(settings, currentYear) || false
      );
    },
    onError() {
      setFilingEnabled(false);
    }
  });

  useTaxFormSubmissionRowsSummaryQuery(currentRequest, {
    onSuccess: data => {
      setCount(data.listSize || 0);
    },
    onError: () => {
      setCount(0);
    }
  });

  return {
    isLoading: count === undefined || filingEnabled === undefined,
    isEnabled: count && filingEnabled
  };
};
