import {
  useWSModal,
  WSCard,
  WSDataItem,
  WSForm,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { TaxFormDeliveryMethod } from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { useTaxFormResendEmail } from "../../../../../query/taxForm/mutations/useTaxFormResendEmail";

import { selectorAddressString } from "../../../../../shared/selectors/selectorAddressString";
import {
  FormDataAddress,
  FormPartialAddress
} from "../../../../Onboarding/components/FormPartialAddress";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";

type FormDataAddressValidated = FormDataAddress & { state: string };

const ResendMailCopy: React.FC<{
  taxForm: IPayeeTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [resendCopy, resendCopyMeta] = useTaxFormResendEmail();
  const [isEdit, setIsEdit] = React.useState(false);
  const [address, setAddress] = React.useState<FormDataAddressValidated>({
    addressLine1: taxForm.data?.w9Info?.addressLine1 || "",
    addressLine2: taxForm.data?.w9Info?.addressLine2 || "",
    city: taxForm.data?.w9Info?.city || "",
    state: taxForm.data?.w9Info?.state || "",
    postalCode: taxForm.data?.w9Info?.postalCode || ""
  });

  if (isEdit) {
    return (
      <WSForm<{ address: FormDataAddressValidated }>
        defaultValues={{ address }}
        onSubmit={data => {
          setAddress(data.address);
          setIsEdit(false);
        }}
      >
        <WSCard
          header="Edit delivery address"
          divider
          actions={{
            alignment: "fill",
            buttons: [
              {
                kind: "Secondary",
                label: "Cancel",
                onClick: () => {
                  setIsEdit(false);
                }
              },
              {
                label: "Save changes"
              }
            ]
          }}
        >
          <WSList gap="M">
            <WSInfoBox>
              Note: editing the delivery address only updates the address for a
              one-off tax form delivery. To update the address associated with
              the contractor account, the contractor must update their address
              on Wingspan.
            </WSInfoBox>

            <FormPartialAddress
              label="Personal address"
              country="US"
              name="address"
            />
          </WSList>
        </WSCard>
      </WSForm>
    );
  } else {
    return (
      <WSCard
        header="Re-mail physical forms"
        divider
        actions={{
          alignment: "fill",
          buttons: [
            {
              kind: "Secondary",
              label: "Cancel",
              onClick: () => {
                onClose(false);
              }
            },
            {
              label: "Confirm & send",
              loading: resendCopyMeta.isLoading,
              onClick: () => {
                resendCopy(
                  {
                    taxFormId: taxForm.taxFormId,
                    deliveryMethod: TaxFormDeliveryMethod.Mail,
                    address: {
                      ...address,
                      country: "US"
                    }
                  },
                  {
                    onSuccess() {
                      onClose?.(true);
                    }
                  }
                );
              }
            }
          ]
        }}
      >
        <WSList gap="M">
          <WSText.ParagraphSm color="gray600">
            Please confirm that you want to re-send an electronic copy of the
            contractor’s 1099-NEC tax forms to the following email:
          </WSText.ParagraphSm>

          <WSPanel p="L">
            <WSList gap="M">
              <WSDataItem
                label="Name"
                value={selectorTaxFormRecipientName(taxForm)}
              />
              <WSDataItem
                label={{
                  text: "Address",
                  action: {
                    label: "Edit",
                    onClick: () => {
                      setIsEdit(true);
                    }
                  },
                  actionPosition: "left"
                }}
                value={selectorAddressString(address)}
              />
            </WSList>
          </WSPanel>

          <WSErrorMessage error={resendCopyMeta.error} contextKey="Other" />
        </WSList>
      </WSCard>
    );
  }
};

export const useModalResendMailCopy = () =>
  useWSModal(ResendMailCopy, {
    size: "S",
    style: {
      padding: 0,
      border: 8
    }
  });
