import { useHistory, useRouteMatch } from "react-router-dom";
import { useBulkPayableBatch } from "../../../../query/payments/queries";
import {
  BulkStatus,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";
import { SetupImportPaymentsComponentProps } from "./index";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { BulkBatchImportProgress } from "../../../BulkImporter/components/BulkBatchImportProgress";
import { BulkResource } from "../../../BulkImporter/types";

export const RouteImportPaymentsProgress: React.FC<
  SetupImportPaymentsComponentProps
> = ({ onNext }) => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const qPayableBulkBatch = useBulkPayableBatch(bulkBatchId);

  const onContinue = async (bulkBatch: IBulkPayableBatch) => {
    onNext({
      bulkPayableBatch: bulkBatch
    });
  };

  return (
    <EditLayout title="Batch upload payments">
      <WSQueries
        queries={{
          qPayableBulkBatch
        }}
      >
        {({ qPayableBulkBatchData: payableBulkBatch }) => {
          return (
            <>
              <WSText.Heading4 mb="XL">
                Batch upload recipients with a spreadsheet
              </WSText.Heading4>
              <BulkBatchImportProgress
                batchId={bulkBatchId}
                bulkResource={BulkResource.Payable}
              />
              <WSButton
                onClick={() => onContinue(payableBulkBatch)}
                fullWidth
                name="recipientsBatchImportDone"
                disabled={
                  ![BulkStatus.Complete, BulkStatus.Failed].includes(
                    payableBulkBatch.status
                  )
                }
              >
                Continue
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
