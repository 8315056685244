import {
  IBulkCollaboratorBatch,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";
import { wsName, WSNameParams } from "@wingspanhq/utils/dist/name/wsName";

/**
 *
 * @param bulkBatch Bulk (payable|collaborator|...) batch
 * @returns string full name of the user who imported the line items
 */
export function getBatchCreatorName(
  bulkBatch: IBulkPayableBatch | IBulkCollaboratorBatch
) {
  const name = wsName({
    user: bulkBatch.client.user as WSNameParams["user"]
  });

  return name.getResolvedName(true);
}
