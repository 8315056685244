import {
  WSElementProps,
  WSPillProps,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { TaxFormCorrectionStatus } from "@wingspanhq/payments/dist/interfaces";
import { TaxFormStatus } from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";

export interface FillingStatusBadgeProps extends WSElementProps {
  status: TaxFormStatus;
  correctionStatus?: TaxFormCorrectionStatus;
}

export const getFillingStatusBadgeProps = (
  status: TaxFormStatus,
  correctionStatus?: TaxFormCorrectionStatus
): WSPillProps & { description?: string } => {
  if (correctionStatus === TaxFormCorrectionStatus.Pending) {
    return {
      theme: "warning",
      icon: true,
      text: "Correction requested",
      description: "Requested by recipient"
    };
  }

  let description = undefined;

  if (correctionStatus === TaxFormCorrectionStatus.Rejected) {
    description = "Correction not accepted";
  } else if (correctionStatus === TaxFormCorrectionStatus.Submitted) {
    description = "Correction submitted to IRS";
  }

  switch (status) {
    case TaxFormStatus.ReadyToSubmitToIrs:
      return {
        icon: "envelope",
        theme: "blue",
        text: "Ready",
        description
      };
    case TaxFormStatus.Excluded:
      return {
        icon: "block",
        text: "Excluded",
        description
      };
    case TaxFormStatus.NeedsAction:
      return {
        theme: "warning",
        text: "Action Required",
        icon: true,
        description
      };
    case TaxFormStatus.AcceptedByIrs:
      return {
        theme: "success",
        icon: true,
        text: "Accepted",
        description
      };
    case TaxFormStatus.SubmittedToIrs:
      return {
        theme: "blue",
        icon: "send-mail",
        text: "Submitted",
        description
      };
    case TaxFormStatus.RejectedByIrs:
      return {
        theme: "warning",
        icon: true,
        text: "Rejected",
        description
      };
    default:
      return {
        icon: "time",
        text: "Pending",
        description
      };
  }
};
export const FillingStatusBadge: React.FC<FillingStatusBadgeProps> = ({
  status,
  correctionStatus,
  ...props
}) => {
  const itemProps = getFillingStatusBadgeProps(status, correctionStatus);

  return (
    <WSTableCell
      pill={{ ...itemProps, ...props }}
      text={itemProps.description}
    />
  );
};
