import {
  WSActions,
  WSAlert,
  WSCard,
  WSEmptyState,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { WS_LINKS } from "../../../../types/wsLinks";
import { EditPayeeSetupParams } from "./index";

type Props = SetupRouteComponentProps<EditPayeeSetupParams>;

export const EditPayeeTaxInfoMismatch: React.FC<Props> = ({
  onBack,
  onNext
}) => {
  return (
    <WSList gap="2XL">
      <WSCard>
        <WSAlert theme="warning" icon="alert" title="IRS mismatch">
          The taxpayer information provided contains mismatched information with
          IRS records.
        </WSAlert>

        <WSEmptyState
          type="search"
          title="IRS mismatch found"
          description={
            <>
              <p>
                Your edits have been saved but the taxpayer information contains
                mismatched information with IRS records.
              </p>
              <br />
              <p>
                To keep the mismatched information, click continue to return to
                the updated detail page.
              </p>
              <br />
              <p>
                To edit again and re-verify, click the “Edit contractor
                information” and follow the recommended steps.
              </p>
            </>
          }
          orientation="horizontal"
          buttons={[
            {
              kind: "Secondary",
              label: "Edit contractor information",
              onClick: onBack
            }
          ]}
        />

        <WSInfoBox title="Reminder">
          <ul>
            <li>
              As the payer, you have sole discretion to decide what information
              to submit and file with the IRS. It is your responsibility to
              ensure the information is accurate.
            </li>
            <li>
              If you proceed with mismatched information and overwrite status to
              “Ready” in order to submit for filing, this may result in a
              “B-notice”.{" "}
              <a
                href={WS_LINKS.taxFillingStatusOverwrites}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about status overwrites.
              </a>
            </li>
          </ul>
        </WSInfoBox>
      </WSCard>
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: onNext
          }
        ]}
      />
    </WSList>
  );
};
