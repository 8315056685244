import get from "lodash/get";
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem";
import { TieredMenu, TieredMenuProps } from "primereact/tieredmenu";
import { classNames } from "primereact/utils";
import React, { useImperativeHandle, useMemo, useRef } from "react";
import { toCamelCase } from "../../../../utils";
import { WSElement } from "../../WSElement/WSElement.component";
import {
  WSIcon,
  WSIconName,
  WSIconProps
} from "../../core/WSIcon/WSIcon.component";
import { WSText, WSTextProps } from "../../core/WSText/WSText.component";
import { useIsMobile, WSFlexBox } from "../../layout";
import { WSAvatar, WSAvatarProps } from "../WSAvatar/WSAvatar.component";
import { WSDivider } from "../../WSDivider/WSDivider";
import { WSSidebar } from "../WSSidebar/WSSidebar.component";
import styles from "./WSMenu.module.scss";

interface WSMenuSharedItem {
  name?: string;
  hidden?: boolean;
  visible?: boolean;
}

export type WSMenuCommandEvent = MenuItemCommandEvent;
export type WSMenuCommand = (event: WSMenuCommandEvent) => void;

export interface WSMenuBasicItem extends WSMenuSharedItem {
  label: string | React.ReactNode;
  labelProps?: WSTextProps;
  onClick?: WSMenuCommand;
  helpText?: string | React.ReactNode;
  helpTextProps?: WSTextProps;
  items?: WSMenuItem[];
  destructive?: boolean;
  separator?: never;
  groupLabel?: never;
  info?: never;
  disabled?: boolean;
}

export interface WSMenuIconItem extends WSMenuBasicItem {
  icon?: WSIconName;
  iconProps?: {
    color?: WSIconProps["color"];
  };
  avatar?: never;
}

export interface WSMenuAvatarItem extends WSMenuBasicItem {
  icon?: never;
  iconProps?: never;
  avatar?: WSAvatarProps;
}

export interface WSMenuSeparatorItem extends WSMenuSharedItem {
  separator: true;
  groupLabel?: never;
  info?: never;
}

export interface WSMenuGroupLabelItem extends WSMenuSharedItem {
  groupLabel: string;
  separator?: never;
  info?: never;
}

export interface WSMenuInfoItem extends WSMenuSharedItem {
  info: string | React.ReactNode;
  separator?: never;
  groupLabel?: never;
}

export type WSMenuItem =
  | WSMenuSeparatorItem
  | WSMenuGroupLabelItem
  | WSMenuIconItem
  | WSMenuAvatarItem
  | WSMenuInfoItem;

export interface WSMenuProps {
  popup?: boolean;
  appendTo?: TieredMenuProps["appendTo"];
  onBlur?: TieredMenuProps["onBlur"];
  name?: string;
  title?: string;
  items: WSMenuItem[];
  width?: number | string;
}

export type WSMenuRef = TieredMenu;

function mapItem(item: WSMenuItem, index: number): MenuItem {
  if ("info" in item) {
    return {
      disabled: true,
      template: () => {
        return (
          <WSElement
            className={styles.item}
            key={`${index} - ${item.name}`}
            data-testid={item.name}
            pt="S"
          >
            <WSDivider mb="S" />

            {typeof item.info === "string" ? (
              <WSText.ParagraphXs p="M" color="gray400">
                {item.info}
              </WSText.ParagraphXs>
            ) : (
              item.info
            )}
          </WSElement>
        );
      }
    };
  }

  if ("groupLabel" in item) {
    return {
      disabled: true,
      template: () => {
        return (
          <WSElement
            className={styles.item}
            key={`${index} - ${item.name}`}
            data-testid={item.name}
            pt="S"
          >
            <WSDivider mb="S" />
            <WSText.Caption p="M" color="gray600" weight="medium">
              {item.groupLabel}
            </WSText.Caption>
          </WSElement>
        );
      }
    };
  }

  if ("separator" in item && item.separator === true) {
    return {
      disabled: true,
      template: () => {
        return (
          <WSElement
            key={`${index} - ${item.name}`}
            className={styles.item}
            py="S"
          >
            <WSDivider />
          </WSElement>
        );
      }
    };
  }

  const hasNested = !!item.items?.length;

  return {
    disabled: item.disabled,
    template: (_item, itemProps) => {
      const mainColor = item.destructive
        ? "red400"
        : item.disabled
        ? "gray400"
        : "gray600";

      return (
        <WSElement
          key={`${index} - ${item.label}`}
          data-testid={item.name}
          p="M"
          onClick={_item.disabled ? undefined : itemProps.onClick}
          className={classNames(styles.item, {
            [styles.destructive]: item.destructive
          })}
        >
          <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
            <WSElement>
              <WSFlexBox.CenterY>
                {item.icon ? (
                  <WSIcon
                    size="S"
                    mr="S"
                    color={mainColor}
                    name={item.icon}
                    {...item.iconProps}
                  />
                ) : null}
                {item.avatar ? (
                  <WSAvatar
                    size="S"
                    mr="S"
                    color={mainColor}
                    {...item.avatar}
                  />
                ) : null}
                {typeof item.label === "string" ? (
                  <WSText.ParagraphSm color={mainColor} {...item.labelProps}>
                    {item.label}
                  </WSText.ParagraphSm>
                ) : (
                  item.label
                )}
              </WSFlexBox.CenterY>

              {typeof item.helpText === "string" ? (
                <WSText.ParagraphXs
                  ml={item.icon ? "XL" : "NONE"}
                  color={mainColor}
                  mt="XS"
                  {...item.helpTextProps}
                >
                  {item.helpText}
                </WSText.ParagraphXs>
              ) : (
                item.helpText
              )}
            </WSElement>
            {hasNested ? (
              <WSIcon name="caret-right" size="S" color="gray500" />
            ) : null}
          </WSFlexBox.CenterY>
        </WSElement>
      );
    },
    command: item.onClick,
    items: item.items ? item.items.map(mapItem) : undefined
  };
}

export const WSMenu = React.forwardRef<WSMenuRef, WSMenuProps>(
  function WSMenuComponent(props, ref) {
    const isMobile = useIsMobile();
    const {
      title,
      items,
      name,
      width,
      popup = true,
      ...otherMenuProps
    } = props;
    const [isVisible, setIsVisible] = React.useState(false);
    const [parentItemPath, setParentItemPath] = React.useState("");

    useImperativeHandle(
      ref,
      () => {
        return {
          toggle(event: React.MouseEvent<HTMLElement>) {
            setIsVisible((prevState) => !prevState);
          }
        } as WSMenuRef;
      },
      [isMobile]
    );

    const visibleItems = useMemo<MenuItem[]>(() => {
      const selectedItems: WSMenuBasicItem[] =
        get(items, parentItemPath)?.items || items;

      return selectedItems
        .map((item, index) => ({
          ...item,
          name: item.name
            ? item.name
            : toCamelCase(
                name || "",
                "menu",
                typeof item.label === "string" ? item.label : "custom" + index,
                "item"
              )
        }))
        .filter((item) => item.hidden !== true)
        .filter((item) => item.visible !== false)
        .map(mapItem);
    }, [parentItemPath, items, name]);

    const parentItem = useMemo<WSMenuBasicItem | undefined>(() => {
      return get(items, parentItemPath) as WSMenuBasicItem;
    }, [parentItemPath, items]);

    const onHide = useMemo(() => {
      return () => {
        setIsVisible(false);
        setTimeout(setParentItemPath, 300, "");
      };
    }, [setIsVisible]);

    return isMobile ? (
      <WSSidebar
        className={styles.sidebar}
        visible={isVisible}
        position="bottom"
        onClose={onHide}
        header={title}
        contentElementProps={{ p: "NONE", colorBackground: "white" }}
        size="AUTO"
      >
        <WSElement py="S">
          {parentItem ? (
            <WSFlexBox.CenterY
              p="M"
              onClick={() => {
                setParentItemPath((prevState) =>
                  prevState.substring(0, prevState.lastIndexOf("."))
                );
              }}
            >
              <WSIcon size="S" name="arrow-left" color="gray600" mr="S" />
              <WSText.Caption color="gray600" weight="medium">
                {parentItem.label}
              </WSText.Caption>
            </WSFlexBox.CenterY>
          ) : null}
          {visibleItems.map((item, index) =>
            typeof item.template === "function"
              ? item.template(item, {
                  onClick(event: React.MouseEvent<HTMLElement>) {
                    if (item.items?.length) {
                      setParentItemPath(
                        parentItemPath
                          ? parentItemPath + "." + index
                          : index.toString()
                      );
                    } else {
                      item.command?.({
                        originalEvent: event,
                        item
                      });
                      onHide();
                    }
                  }
                } as any)
              : null
          )}
        </WSElement>
      </WSSidebar>
    ) : (
      <TieredMenu
        model={visibleItems}
        popup={popup}
        autoZIndex={false}
        baseZIndex={1100}
        onHide={onHide}
        ref={ref}
        data-testid={name ? toCamelCase(name, "menu") : undefined}
        style={{ width }}
        {...otherMenuProps}
      />
    );
  }
);

WSMenu.displayName = "WSMenu";

export interface WSMenuTriggerProps extends WSMenuProps {
  isSameWidthAsTrigger?: boolean;
  renderTrigger: (props: {
    ref: React.RefObject<HTMLElement>;
    onToggle: (
      event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>
    ) => void;
  }) => React.ReactNode;
}

export const WSMenuTrigger: React.FC<WSMenuTriggerProps> = ({
  renderTrigger,
  isSameWidthAsTrigger,
  ...props
}) => {
  const menu = useRef<WSMenuRef | null>(null);
  const trigger = useRef<HTMLElement | null>(null);

  return (
    <>
      <WSMenu
        width={isSameWidthAsTrigger ? trigger.current?.clientWidth : undefined}
        {...props}
        ref={menu}
      />
      {renderTrigger({
        onToggle: (event) => {
          menu.current?.toggle(event);
        },
        ref: trigger
      })}
    </>
  );
};

WSMenuTrigger.displayName = "WSMenuTrigger";
