import React from "react";
import {
  useWSModal,
  WSActions,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { IRedactedTaxFormW9Info } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { renderValue } from "./getTimelineItem";

const LABELS: Record<keyof IRedactedTaxFormW9Info, string> = {
  ein: "EIN",
  companyStructure: "Company structure",
  city: "City",
  state: "State",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  country: "Country",
  tinType: "TIN type",
  dob: "Date of birth",
  disregardedEntityName: "Disregarded entity name",
  einLastFour: "EIN last four",
  exemptFatcaCode: "Exempt FATCA code",
  exemptPayeeCode: "Exempt payee code",
  firstName: "First name",
  lastName: "Last name",
  legalBusinessName: "Legal business name",
  postalCode: "Postal code",
  ssnLastFour: "SSN last four",
  w9Source: ""
};

const getLabel = (key: keyof IRedactedTaxFormW9Info) => {
  return LABELS[key];
};

const ModalReviewChanges: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const recipientChanges = (
    Object.keys(taxForm.unsyncedRecipientData?.w9Info || {}) as Array<
      keyof IRedactedTaxFormW9Info
    >
  )
    .filter(key => LABELS[key])
    .filter(
      key =>
        taxForm.data.w9Info?.[key] !==
        taxForm.unsyncedRecipientData?.w9Info?.[key]
    );

  return (
    <WSList gap="XL" mt="XS">
      <WSText.ParagraphSm color="gray500">
        The recipient has made the following updates to their tax information
        which are no longer synced because of manual edits to this form. Do you
        want to revert to the original (your overwritten edits will be lost)?
      </WSText.ParagraphSm>

      <WSInfoBox>
        Automatically synced updates from the contractor profile will be resumed
        after you revert. You cannot undo a reversion. You will be able to make
        manual edits again after the reversion.
      </WSInfoBox>

      {recipientChanges.length > 0 && (
        <WSPanel>
          <WSText.ParagraphSm color="gray700" weight="book">
            Manual edits will be lost ({recipientChanges.length}):
          </WSText.ParagraphSm>
          <WSList gap="S" mt="M">
            {recipientChanges.map((key, index) => {
              return (
                <React.Fragment key={key}>
                  <WSGrid my="S">
                    <WSGrid.Item span="5">
                      <WSText.ParagraphSm color="gray500">
                        {getLabel(key)}
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm color="gray700" mt="S">
                        <s>{renderValue(key, taxForm.data.w9Info?.[key])}</s>
                      </WSText.ParagraphSm>
                    </WSGrid.Item>
                    <WSGrid.Item span="2">
                      <WSFlexBox.CenterY style={{ height: "100%" }}>
                        <WSIcon name="arrow-right" size="S" color="gray500" />
                      </WSFlexBox.CenterY>
                    </WSGrid.Item>
                    <WSGrid.Item span="5">
                      <WSText.ParagraphSm color="gray500">
                        {getLabel(key)}:
                      </WSText.ParagraphSm>
                      <WSText.ParagraphXs color="gray400">
                        (reverted)
                      </WSText.ParagraphXs>
                      <WSText.ParagraphSm color="gray700" mt="S">
                        {renderValue(
                          key,
                          taxForm.unsyncedRecipientData?.w9Info?.[key]
                        )}
                      </WSText.ParagraphSm>
                    </WSGrid.Item>
                  </WSGrid>
                </React.Fragment>
              );
            })}
          </WSList>
        </WSPanel>
      )}

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Confirm & continue",
            kind: "Primary",
            onClick: () => {
              onClose(true);
            }
          },
          {
            label: "Cancel",
            kind: "Secondary",
            onClick: () => {
              onClose(false);
            }
          }
        ]}
      />
    </WSList>
  );
};

export const useModalReviewChanges = () =>
  useWSModal(ModalReviewChanges, {
    title: "Revert to synced contractor details",
    size: "S"
  });
