import React from "react";
import { queryCache } from "react-query";

import { FlatfileError } from "@flatfile/sdk/dist/errors/FlatfileError";
import { PartialRejection } from "@flatfile/sdk";

import api from "@flatfile/api";
import { useFlatfile, useListener } from "@flatfile/react";

import { WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import {
  BulkPayableStatus,
  CustomFieldResourceType,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";

import { WSQueries } from "../../../../query/WSQuery";
import { BulkBatchImportStatus } from "../../components/BulkBatchImportStatus";

import styles from "./styles.module.scss";
import { useFlatfile as useWSFlatfile } from "../../../../hooks/useFlatfile";
import { pullSessionToken } from "../../../../services/sessionStorage";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import {
  handleFlatfileCSVChunk,
  processFlatfileChunk
} from "../../utils/flatfileBulkPayable";

import { flatfileConfig, flatfileTheme } from "../../utils/flatfileThemeConfig";
import useSnackbar from "../../../../hooks/useSnackbar";
import { BulkResource } from "../../types";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useFlatfileErrorModal } from "../../components/FlatfileError/useFlatfileErrorModal";
import { useHistory } from "react-router-dom";
import { BulkImportError } from "../../components/BulkImportError";

import {
  QUERY_BULK_PAYABLE_BATCH,
  QUERY_BULK_PAYABLE_BATCH_SUMMARY
} from "../../query/bulkPayable/keys";
import FlatfileListener, { FlatfileEvent } from "@flatfile/listener";
import { isUploadInProgress } from "../../utils/bulkBatchUtils";
import { BulkBatchUploadProgress } from "../../components/BulkBatchUploadProgress";
import { useConfirmImportModal } from "../../components/ConfirmImportModal";
import { BulkBatchActions } from "../../components/BulkBatchActions";
import { PAYABLES_ACTION_SLUG } from "../../constants";

interface BulkPayableOpenBatchHeaderProps {
  bulkBatchId: string;
  bulkResource: BulkResource;
}

export function BulkPayableOpenBatchHeader({
  bulkBatchId,
  bulkResource
}: BulkPayableOpenBatchHeaderProps) {
  const history = useHistory();
  const { warning } = useSnackbar();
  const { useBulkBatch, useBulkBatchSummary, useUpdateBulkBatch } =
    getBulkQueriesByResource(bulkResource);

  const qBulkBatch = useBulkBatch(bulkBatchId);
  const qBulkBatchItems = useBulkBatchSummary(bulkBatchId);
  const qFeatureFlags = useFeatureFlags();

  const qLineItemsCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const [updateBulkPayableBatch, updateBulkPayableBatchMeta] =
    useUpdateBulkBatch();

  const payableStatus = qBulkBatch.data?.labels
    .payableStatus as BulkPayableStatus;

  // Flatfile Platform

  const onCloseFlatfileModal = () => {
    queryCache.invalidateQueries([QUERY_BULK_PAYABLE_BATCH, bulkBatchId]);
    queryCache.invalidateQueries(QUERY_BULK_PAYABLE_BATCH_SUMMARY);
  };

  const { openPortal, closePortal } = useFlatfile({
    onClose: onCloseFlatfileModal
  });

  const confirmImportModal = useConfirmImportModal(BulkResource.Payable);

  useListener((listener: FlatfileListener) => {
    listener.on("space:created", (event: FlatfileEvent) => {
      const { spaceId } = event.context;
      console.log("spaceId", spaceId);
    });

    listener.on(
      "job:outcome-acknowledged",
      { job: `workbook:${PAYABLES_ACTION_SLUG}` },
      async event => {
        const { spaceId } = event.context;
        console.log(
          `[job:outcome-acknowledged]:workbook:${PAYABLES_ACTION_SLUG} -- spaceId`,
          spaceId
        );

        const space = await api.spaces.get(spaceId);

        // close the flatfile portal if there are no rejections
        const { data: workbookSheets } = await api.sheets.list({
          workbookId: space.data.metadata?.wsInternalWorkbookId
        });
        const rejectionSheet = workbookSheets.find(s =>
          s.slug.includes("rejections")
        );
        if (rejectionSheet) {
          const rejectionRecords = rejectionSheet.recordCounts?.total;
          if (rejectionRecords === 0) {
            closePortal();
          }
        }
      }
    );
  });

  // Flatfile error handling
  const flatfileErrorModal = useFlatfileErrorModal();

  const onFlatfileError = (error: FlatfileError) => {
    flatfileErrorModal.open({
      error,

      batchId: bulkBatchId,
      bulkResource,
      onStartNewImport: bulkBatch => {
        const newBulkBatchId = (bulkBatch as IBulkPayableBatch)
          .bulkPayableBatchId;

        updateBulkPayableBatch(
          {
            id: newBulkBatchId,
            data: {
              labels: {
                payableStatus
              }
            }
          },
          {
            onSuccess: () => {
              history.push(`/member/imports/payables/${newBulkBatchId}`);
            }
          }
        );
      }
    });
  };

  // Flatfile V3 initialization

  const EMBED_ID = process.env
    .REACT_APP_FLATFILE_PAYMENTS_PAYABLES_EMBED_ID as string;

  const flatfile = useWSFlatfile({
    embedId: EMBED_ID,
    onError: onFlatfileError
  });

  const lineItemsCustomFields = qLineItemsCustomFields.data || [];

  const importDataV3 = async () => {
    flatfile.requestDataFromUser({
      ...flatfileConfig,
      theme: {
        ...flatfileTheme
      },
      onInit: ({ session }) => {
        session.updateEnvironment({
          payableStatus,
          isProdEnv: IS_PRODUCTION_ENV,
          authToken: pullSessionToken(),
          bulkBatchId
        });

        session.on("close", () => {
          // Just refetch the batch items query to update the batch details view
          // so that user won't upload the spreadsheet again in the same batch.
          qBulkBatchItems.refetch();
        });
      },
      customFields: lineItemsCustomFields.map(({ key, name }) => {
        return {
          field: key,
          label: name,
          // FIX: boolean fields are not supported in Flatfile
          type: "string"
        };
      }),
      /**
       * Flatfile streams the uploaded spreadsheet data as chunks of size 1000 (default) to onData
       * callback function where we can process the spreadsheet data (like making an external API call)
       * and reject any spreadsheet row(s) if there are any failures during processing.
       */
      onData: async (chunk, next) => {
        const processChunkFn = qFeatureFlags.data?.improveBulkImportConcurrency
          ? processFlatfileChunk
          : handleFlatfileCSVChunk;
        const rejections = await processChunkFn(
          chunk,
          bulkBatchId,
          payableStatus,
          lineItemsCustomFields
        );
        next(new PartialRejection(rejections));
      },
      onComplete: async payload => {
        const csvRecords = await payload.data();
        const csvLineItems = csvRecords.rows
          .filter(row => row.valid)
          .map(row => row.data);

        // ------------- Flatfile's Duplicate Row issue Debug Statements ----------------------
        console.group("Bulk Import Payables");
        console.log("CSV Line items", csvLineItems);
        console.groupEnd();
        // ------------- Flatfile's Duplicate Row issue Debug Statements ----------------------

        if (csvLineItems.length === 0) {
          warning("Sorry, Looks like the uploaded spreadsheet is empty!");
          return;
        }
        await updateBulkPayableBatch({
          id: bulkBatchId,
          data: {
            labels: {
              flatfileBatchId: payload.batchId
            }
          }
        });
      }
    });
  };

  // status: Open & batch is empty

  const uploadSpreadsheet = () => {
    if (qFeatureFlags.data?.flatfilePlatform) {
      openPortal();
    } else {
      importDataV3();
    }
  };

  // status: Open & batch has some batch items

  const viewUpload = () => {
    openPortal();
  };

  return (
    <WSQueries queries={{ qBulkBatch, qBulkBatchItems, qFeatureFlags }}>
      {({ qBulkBatchData: bulkBatch, qFeatureFlagsData: featureFlags }) => {
        const isEmptyBatch =
          qBulkBatchItems.data && qBulkBatchItems.data.listSize === 0;
        const filename = getUploadedFilename(bulkBatch, BulkResource.Payable, {
          length: 30
        });
        return (
          <>
            <WSFlexBox direction="column">
              <WSFlexBox.CenterY
                className={styles.header}
                justify="space-between"
                mb="XL"
              >
                <WSText
                  weight="medium"
                  data-testid="bulkBatchFilename"
                  shimmer={
                    qBulkBatchItems.isLoading ||
                    updateBulkPayableBatchMeta.isLoading
                  }
                  title={getUploadedFilename(bulkBatch, BulkResource.Payable)}
                >
                  {filename ||
                    (isEmptyBatch
                      ? "File not uploaded"
                      : "Unnamed Payable Batch")}
                </WSText>
                <BulkBatchImportStatus
                  batchId={bulkBatchId}
                  bulkBatch={bulkBatch}
                  bulkResource={bulkResource}
                />
              </WSFlexBox.CenterY>

              {isUploadInProgress(bulkBatch) && (
                <BulkBatchUploadProgress
                  bulkBatchId={bulkBatchId}
                  bulkResource={BulkResource.Payable}
                />
              )}

              <WSText.ParagraphSm color="gray500" mb="XL">
                {isEmptyBatch && !isUploadInProgress(bulkBatch)
                  ? "This import is empty. You can upload a spreadsheet of payables to continue, or delete the import."
                  : isUploadInProgress(bulkBatch)
                  ? "A spreadsheet is being uploaded. Once the upload is complete, the upload can be imported as payables."
                  : "A spreadsheet is uploaded and ready to be imported as payables. Imported payables with the same contractor and due date as existing payables will be merged."}
              </WSText.ParagraphSm>

              <BulkBatchActions
                bulkResource={BulkResource.Payable}
                bulkBatchId={bulkBatchId}
                viewUpload={viewUpload}
                uploadSpreadsheet={uploadSpreadsheet}
              />

              {isEmptyBatch ? null : (
                <BulkImportError
                  mt="XL"
                  bulkResource={bulkResource}
                  batchId={bulkBatchId}
                />
              )}
            </WSFlexBox>
          </>
        );
      }}
    </WSQueries>
  );
}
