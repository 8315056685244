import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { FlatfileProvider, Space } from "@flatfile/react";
import {
  BulkPayableStatus,
  BulkStatus,
  IBulkPayableBatch,
  ICollaboratorGroupResponse
} from "@wingspanhq/payments/dist/interfaces";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

import { WSQueries } from "../../../../query/WSQuery";

import { DefaultHeader } from "./DefaultHeader";
import { BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkPayableOpenBatchHeader } from "../../routes/BulkImportPayableBatchDetails/BulkPayableOpenBatchHeader";
import { BulkCollaboratorOpenBatchHeader } from "../../routes/BulkImportCollaboratorBatchDetails/BulkCollaboratorOpenBatchHeader";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { flatfileProviderConfig } from "../../utils/flatfileThemeConfig";
import { isUploadInProgress } from "../../utils/bulkBatchUtils";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  BULK_IMPORT_CONTRACTORS_NAMESPACE,
  BULK_IMPORT_PAYABLES_NAMESPACE
} from "../../constants";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { themeConfig } from "../../../../shared/flatfile/themeConfig";
import { useNonContractorXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";
import { hybridEncrypt } from "@wingspanhq/utils/dist/crypto";
import { pullSessionToken } from "../../../../services/sessionStorage";
import { useCollaboratorGroupQuery } from "../../../../query/payments/queries";
import { useEngagementQuery } from "../../../../query/engagements/queries/useEngagementQuery";
import { useUserProfile } from "../../../../query/users/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { selectorIsEngagementsActive } from "../../../../shared/selectors/selectorIsEngagementsActive";
import { selectorIsImpersonatedWingspanAdmin } from "../../../../FocusedFeatures/Dashbaord/selectors/selectorIsImpersonatedWingspanAdmin";

interface BulkBatchDetailsHeaderProps {
  bulkResource: BulkResource;
}

export function BulkBatchDetailsHeader({
  bulkResource
}: BulkBatchDetailsHeaderProps) {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId);
  const qFeatureFlags = useFeatureFlags();
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const xWingspanExpansion = useNonContractorXWingspanExpansion();

  // encrypt the API token for storage in the metadata
  const publicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY as string;
  const originalPublicKey = publicKey.replace(/\\n/g, "\n");

  const apiTokenEncrypted = useMemo(
    () => hybridEncrypt(originalPublicKey, pullSessionToken() as string),
    []
  );

  // We will deprecate the collaboratorGroupId in the future
  const collaboratorGroupId = qBulkBatch.data?.labels
    .collaboratorGroupId as string;
  const engagementId = qBulkBatch.data?.labels.engagementId as string;

  const qCollaboratorGroup = useCollaboratorGroupQuery(
    collaboratorGroupId as string,
    {
      enabled: !!collaboratorGroupId
    }
  );
  const qEngagement = useEngagementQuery(engagementId, {
    enabled: !!engagementId
  });

  const collaboratorGroup =
    qCollaboratorGroup.data as ICollaboratorGroupResponse;
  const selectedEngagement = qEngagement.data;

  const payableStatus = qBulkBatch.data?.labels
    .payableStatus as BulkPayableStatus;

  let OpenBatchHeader = BulkPayableOpenBatchHeader;
  let spaceConfig = {
    name: "Payables Upload",
    namespace: BULK_IMPORT_PAYABLES_NAMESPACE
  };
  if (bulkResource === BulkResource.Collaborator) {
    OpenBatchHeader = BulkCollaboratorOpenBatchHeader;
    spaceConfig = {
      name: "Contractors Upload",
      namespace: BULK_IMPORT_CONTRACTORS_NAMESPACE
    };
  }

  const isImpersonatedWingspanAdmin = selectorIsImpersonatedWingspanAdmin();
  return (
    <WSQueries queries={{ qBulkBatch, qFeatureFlags, queryUser }}>
      {({
        qBulkBatchData: bulkBatch,
        qFeatureFlagsData: featureFlags,
        queryUserData: user
      }) => {
        const ffProviderProps = (bulkBatch.labels[
          BATCH_LABELS_WS_KEYS.flatfileAccessToken
        ] as string)
          ? {
              accessToken: bulkBatch.labels[
                BATCH_LABELS_WS_KEYS.flatfileAccessToken
              ] as string
            }
          : {
              publishableKey: process.env
                .REACT_APP_FLATFILE_PUBLISHABLE_KEY as string
            };
        return (
          <FlatfileProvider
            // Do not set key prop if upload is in progress
            {...(isUploadInProgress(bulkBatch)
              ? {}
              : {
                  key:
                    ffProviderProps.accessToken ??
                    ffProviderProps.publishableKey
                })}
            config={{
              debug: !IS_PRODUCTION_ENV,
              ...flatfileProviderConfig
            }}
            {...ffProviderProps}
          >
            {featureFlags.flatfilePlatform && (
              <Space
                {...(bulkBatch.labels.flatfileBatchId
                  ? { id: bulkBatch.labels.flatfileBatchId as string }
                  : {})}
                config={{
                  ...spaceConfig,

                  // set batch item Id
                  // set api token to use it on listener
                  metadata: {
                    // custom data
                    apiToken: apiTokenEncrypted,
                    customHeaders: xWingspanExpansion
                      ? {
                          "x-wingspan-expansion": xWingspanExpansion
                        }
                      : {},
                    bulkBatchId,

                    isEngagementsEnabled:
                      selectorIsEngagementsActive(user) &&
                      featureFlags.flatfileEngagements,

                    ...(collaboratorGroup && {
                      selectedCollaboratorGroupId:
                        collaboratorGroup.collaboratorGroupId,
                      selectedCollaboratorGroupName: collaboratorGroup.name
                    }),

                    ...(selectedEngagement && {
                      selectedEngagementName: selectedEngagement.name,
                      selectedEngagementId: selectedEngagement.engagementId
                    }),

                    ...(payableStatus && {
                      payableStatus
                    }),

                    // user info
                    userInfo: {
                      userId: bulkBatch.clientId,
                      name: getBatchCreatorName(bulkBatch as IBulkPayableBatch)
                    },

                    // part of configuration
                    sidebarConfig: {
                      // show sidebar for impersonated wingspan admin
                      showSidebar: isImpersonatedWingspanAdmin
                    },
                    ...themeConfig
                  }
                }}
              />
            )}
            {bulkBatch.status === BulkStatus.Open ? (
              <OpenBatchHeader
                bulkBatchId={bulkBatchId}
                bulkResource={bulkResource}
              />
            ) : (
              <DefaultHeader
                bulkBatchId={bulkBatchId}
                bulkResource={bulkResource}
              />
            )}
          </FlatfileProvider>
        );
      }}
    </WSQueries>
  );
}
