import { TaxFormSubmissionStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  useGoToTaxFormReviewCorrection,
  useGoToTaxFormViewCorrection
} from "../../TaxFiling/paths";

export const useGoToReviewContractorCorrection = () => {
  const goToReviewCorrection = useGoToTaxFormReviewCorrection();
  const goToViewCorrection = useGoToTaxFormViewCorrection();

  return (
    status: TaxFormSubmissionStatus,
    taxFormId: string,
    submissionId: string
  ) => {
    if (
      [
        TaxFormSubmissionStatus.Corrected,
        TaxFormSubmissionStatus.AcceptedByIrs
      ].includes(status)
    ) {
      goToReviewCorrection(taxFormId, submissionId);
    } else {
      goToViewCorrection(taxFormId, submissionId);
    }
  };
};
