import { useHistory, useRouteMatch } from "react-router-dom";
import { SetupRouteComponentProps } from "../../../shared/components/FlowSetup";
import { RequestCorrectionFlowOld } from "./RequestCorrectionFlowOld";

import { OLD_TAX_DOCUMENTS_PATH } from "../paths";

export const RouteRequestCorrectionOld: React.FC<SetupRouteComponentProps> = ({
  onNext,
  onBack
}) => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;

  const goBack = () => {
    if (onBack) {
      onBack();
      return;
    }
    history.push(`${OLD_TAX_DOCUMENTS_PATH}/${taxFormId}`);
  };

  return (
    <RequestCorrectionFlowOld
      basePath={`${OLD_TAX_DOCUMENTS_PATH}/:taxFormId/request-correction`}
      onBack={goBack}
      onFinish={onNext}
    />
  );
};
