import {
  WSActions,
  WSActionsButton,
  WSDivider,
  WSFlexBox,
  WSList,
  WSPanel,
  WSPill,
  WSPillProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { formatPhone } from "../../../shared/utils/formatPhone";
import { openInNewTab } from "../../../shared/utils/openInNewTab";

export type GetTaxFormCardButton = () => WSActionsButton | null;

export type TaxFormCardProps = {
  buttons?: GetTaxFormCardButton[];
  content: React.ReactNode;
  pill?: WSPillProps;
  payerName: string;
  supportEmail?: string;
  supportPhone?: string;
};

export const TaxFormCardView: React.FC<TaxFormCardProps> = ({
  pill,
  buttons,
  content,
  payerName,
  supportEmail,
  supportPhone
}) => {
  return (
    <WSPanel>
      <WSList gap="XL">
        <WSFlexBox justify="space-between">
          <WSText.Paragraph weight="medium">{payerName}</WSText.Paragraph>
          {pill && <WSPill {...pill} />}
        </WSFlexBox>
        <WSList gap="L">{content}</WSList>
        {buttons?.length ? (
          <>
            <WSDivider />
            <WSActions
              sortButtons={false}
              alignment="fill"
              buttons={
                buttons
                  .map(getButton => getButton())
                  .filter(Boolean) as WSActionsButton[]
              }
              orientation={{ xs: "vertical", s: "horizontal" }}
            />
          </>
        ) : null}

        {supportEmail || supportPhone ? (
          <WSPanel noBorder colorBackground="gray50" px="L" py="M">
            <WSText.ParagraphSm weight="medium" mb="XS" color="gray500">
              Payer support contact:
            </WSText.ParagraphSm>

            {supportPhone && (
              <WSText.ParagraphSm color="gray500">
                Phone: {formatPhone(supportPhone)}
              </WSText.ParagraphSm>
            )}
            {supportEmail && (
              <WSText.ParagraphSm color="gray500">
                Email:{" "}
                <WSText.ParagraphSm
                  inline
                  color="gray500"
                  weight="medium"
                  onClick={() => openInNewTab(`mailto:${supportEmail}`)}
                >
                  {supportEmail}
                </WSText.ParagraphSm>
              </WSText.ParagraphSm>
            )}
          </WSPanel>
        ) : null}
      </WSList>
    </WSPanel>
  );
};
