import {
  IPayeeEngagementResponse,
  RequirementEmbeddedStatus
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const updateEngagementRequirement = async (
  payeeId: string,
  engagementId: string,
  requirementId: string,
  request: { status: RequirementEmbeddedStatus | null },
  orgPayerId: string | undefined
): Promise<IPayeeEngagementResponse> => {
  const { data } = await servicePayments.patch(
    `/payee/${payeeId}/engagement/${engagementId}/requirement/${requirementId}`,
    request,
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );
  return data;
};
