import React from "react";
import { EditLayout } from "../../components/EditLayout";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { FormSupportInformation } from "../../components/FormSupportInformation";

export const RouteEditSupportInformation: React.FC = () => {
  const goBack = useGoBackOrToParent();

  return (
    <EditLayout title="Tax filing settings" onBack={goBack}>
      <FormSupportInformation onNext={goBack} onBack={goBack} />
    </EditLayout>
  );
};
