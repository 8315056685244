import {
  WSElement,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  PayablesStatusFilter,
  PayablesTable
} from "../../components/NewPayablesTable/PayablesTable";
import { PayablesSummary } from "../../components/PayablesSummary/PayablesSummary";

export const PayablesDrafts: React.FC = () => {
  const history = useHistory();
  useBrowserPageTitle("Draft Payables");

  return (
    <WSPage
      title="Draft Payables"
      breadcrumb={{
        label: "Payables to approve",
        icon: "arrow-left",
        onClick: () => history.push("/member/invoices/payables/to-approve")
      }}
    >
      <WSText color="gray600" mb="2XL">
        Draft/unsent payables
      </WSText>

      <WSElement mb="2XL">
        <WSGrid>
          <WSGrid.Item span={{ xs: "2" }}>
            <PayablesSummary
              label="Total amount"
              filter={{
                status: [PayablesStatusFilter.Draft]
              }}
              displayAmount
            />
          </WSGrid.Item>
          <WSGrid.Item span={{ xs: "2" }}>
            <PayablesSummary
              label="Total 1099s"
              filter={{
                status: [PayablesStatusFilter.Draft]
              }}
            />
          </WSGrid.Item>
        </WSGrid>
      </WSElement>

      <PayablesTable
        bulkActions={["open", "delete"]}
        basePath="/member/invoices/payables/draft"
        visibleFilters={{
          payee: true,
          openedAtRange: true
        }}
        defaultFilters={{
          status: [PayablesStatusFilter.Draft]
        }}
        showHeaders
        showDateRequested
        emptyState={{
          standart: "No unsent payables."
        }}
      />
    </WSPage>
  );
};
