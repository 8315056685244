import { servicePayments } from "../servicePayments";
import { IPayeeResendInviteResponse } from "@wingspanhq/payments/dist/interfaces";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const resendInvite = async (
  payeeId: string,
  orgPayerId?: string
): Promise<IPayeeResendInviteResponse> => {
  const { data } = await servicePayments.post(
    `/payee/${payeeId}/resend-invite`,
    {},
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );

  return data;
};
