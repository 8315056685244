import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_PAYEE } from "../../payee/keys";
import {
  QUERY_PAYEE_ENGAGEMENT,
  QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
  QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE
} from "../keys";
import {
  IPayeeEngagementCreateRequest,
  IPayeeEngagementResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { createPayeeEngagement } from "../../../services/payeeEngagement/createPayeeEngagement";
import { QUERY_PAYEE_ROWS } from "../../search/payee/queries/usePayeeRowsQuery";
import { QUERY_PAYEE_ROWS_SUMMARY } from "../../search/payee/queries/usePayeeRowsSummaryQuery";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const useCreatePayeeEngagement = (
  payeeId: string,
  config?: QueryConfig<IPayeeEngagementResponse, WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId();

  return useWSMutation(
    (request: IPayeeEngagementCreateRequest) =>
      createPayeeEngagement(payeeId, request, xWingspanPayerId),
    {
      ...config,
      dependencies: [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
        QUERY_PAYEE,
        QUERY_PAYEE_ROWS,
        QUERY_PAYEE_ROWS_SUMMARY
      ],
      awaitDependencies: [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
        QUERY_PAYEE
      ]
    }
  );
};
