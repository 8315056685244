import {
  WSActions,
  WSCard,
  WSFlexBox,
  WSForm,
  WSIcon,
  WSInfoBox,
  WSInputNumber,
  WSList,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { RouteComponentProps } from "react-router-dom";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { WSQueries } from "../../../../../query/WSQuery";
import React from "react";
import { useTaxFormSubmissionParamsIds } from "../../../paths";
import { EditLayout } from "../../../components/EditLayout";
import { useGoBackOrToParent } from "../../../../../utils/goToParentRoute";
import { usePayerTaxFormUpdate } from "../../../../../query/taxForm/mutations/useTaxFormUpdate";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";
import * as Yup from "yup";
import { useModalUpdateStatus } from "../RouteTaxFormDetails/ModalUpdateStatus";
import { getTaxFormExtendedBySubmission } from "../../../utils/getTaxFormExtendedBySubmission";

type FormValues = {
  amount: number;
};

export const RouteTaxFormEditNEC1099Amount: React.FC<
  RouteComponentProps
> = () => {
  const { taxFormId, submissionId } = useTaxFormSubmissionParamsIds();
  const onFinish = useGoBackOrToParent();

  const queryPayerTaxForm = usePayerTaxForm(taxFormId);
  const updateStatusModal = useModalUpdateStatus();

  const [updateTaxForm, updateTaxFormMeta] = usePayerTaxFormUpdate();

  return (
    <EditLayout title="Edit contractor details" onBack={onFinish}>
      <WSQueries
        queries={{
          queryPayerTaxForm
        }}
      >
        {({ queryPayerTaxFormData }) => {
          const taxForm = getTaxFormExtendedBySubmission(
            queryPayerTaxFormData,
            submissionId
          );

          const defaultAmount = selector1099NECTotal(taxForm);

          return (
            <WSForm<FormValues>
              defaultValues={{
                amount: defaultAmount
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number()
                  .notOneOf([defaultAmount])
                  .required("Amount is required")
              })}
              onSubmit={async formData => {
                await updateTaxForm(
                  {
                    taxFormId,
                    requestData: {
                      data: {
                        totalAmount: formData.amount
                      }
                    }
                  },
                  {
                    onSuccess: async responseTaxForm => {
                      if (
                        responseTaxForm &&
                        responseTaxForm?.status !== taxForm.status
                      ) {
                        await updateStatusModal.open({
                          taxForm: responseTaxForm
                        });
                      }

                      onFinish();
                    }
                  }
                );
              }}
            >
              <WSList gap="2XL">
                <WSCard
                  divider
                  header="Edit contractor details on Form 1099-NEC"
                >
                  <WSList gap="2XL">
                    <WSInfoBox>
                      Manually editing details on the 1099-NEC form will disable
                      automatic updates from the contractor profile to the tax
                      form including taxpayer and payment information. You can
                      always undo your edits to resume automatic updates later
                      (Any manual edits will be lost when you revert to synced
                      information).
                    </WSInfoBox>

                    <WSList gap="L">
                      <WSFlexBox.CenterY justify="space-between">
                        <WSText.Paragraph weight="medium" color="gray600">
                          1099-NEC Total
                        </WSText.Paragraph>
                        {taxForm.recipientSyncEnabled ? (
                          <WSPill text="Original" badge />
                        ) : null}
                      </WSFlexBox.CenterY>
                      <WSFlexBox.CenterY>
                        <WSText.Heading1
                          formatMoney
                          color="gray500"
                          weight="book"
                        >
                          {defaultAmount}
                        </WSText.Heading1>
                        {taxForm.groupSize ? (
                          <WSFlexBox.CenterY ml="M">
                            <WSIcon
                              block
                              name="group"
                              color="gray400"
                              size="XS"
                            />
                            <WSText.ParagraphSm ml="XS" color="gray400">
                              Grouped forms
                            </WSText.ParagraphSm>
                          </WSFlexBox.CenterY>
                        ) : null}
                      </WSFlexBox.CenterY>
                    </WSList>
                  </WSList>
                </WSCard>
                <WSCard
                  divider
                  header={{
                    label: {
                      text: "1099-NEC total change"
                    },
                    value: {
                      pill: {
                        text: "Overwritten by you",
                        theme: "violet",
                        badge: true
                      }
                    }
                  }}
                >
                  <WSForm.Field
                    name="amount"
                    label="What should the adjusted 1099-NEC total be?"
                    component={WSInputNumber}
                    componentProps={{
                      maxFractionDigits: 2,
                      required: true,
                      icon: "dollar",
                      placeholder: "0.00",
                      min: 0,
                      helperText: `The contractor will see the net adjustment amount in addition to their on platform payments, if applicable.`
                    }}
                  />
                </WSCard>

                <WSActions
                  alignment="fill"
                  buttons={[
                    {
                      label: "Cancel",
                      onClick: onFinish,
                      type: "button",
                      kind: "Secondary"
                    },
                    {
                      label: "Save changes",
                      type: "submit",
                      loading: updateTaxFormMeta.isLoading
                    }
                  ]}
                />
              </WSList>
            </WSForm>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
