import { WSTableColumnSortDirection } from "@wingspanhq/fe-component-library";
import {
  PayeeTaxDocumentSharePermission,
  TaxFormDeliveryMethod,
  TaxFormExclusionReason,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  TaxFormSearchStatus,
  TaxFormSubmissionFilterRequest,
  TaxFormSubmissionQuerySort,
  TaxFormSubmissionRequest
} from "../../../../services/search";
import {
  UrlQueryFilter,
  UrlQuerySort
} from "../../../../shared/hooks/useUrlQuery";

export interface TaxFormSubmissionViewFilters extends UrlQueryFilter {
  searchString?: string;
  memberId?: string;
  clientId?: string | string[];
  status?: TaxFormSearchStatus | TaxFormSearchStatus[];
  isGroupedTin?: GroupedTINStatus;
  groupIds?: string[];
  shareTaxDocument?: ShareTaxDocumentStatus;
  totalAmount?: {
    from?: number;
    to?: number;
  };
  searchableTaxStatus?: VerificationStatus;
  exclusionReason?: TaxFormExclusionReason;
  state?: string | string[];
  recipientSyncEnabledString?: "true" | "false";
  deliveryMethod?: TaxFormDeliveryMethod;
  formAmendments?: string | string[];
  correctionRequest?: FormContractorRequestFilterOption;
}

const DEFAULT_FILTERS: TaxFormSubmissionViewFilters = {};

export const getTaxFormSubmissionViewDefaultFilters =
  (): TaxFormSubmissionViewFilters => DEFAULT_FILTERS;

export enum ShareTaxDocumentStatus {
  Shared = "Shared",
  NotShared = "NotShared"
}

export enum GroupedTINStatus {
  Grouped = "Grouped",
  NoGrouped = "NoGrouped"
}

export enum FormAmendmentsFilterOption {
  Superceded = "Superceded",
  Voided = "Voided",
  Corrected = "Corrected"
}

export enum FormContractorRequestFilterOption {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected"
}

export const createTaxFormSubmissionListRequest = (
  year: number,
  filters: TaxFormSubmissionViewFilters,
  sort?: UrlQuerySort<"name" | "totalAmount">,
  page?: TaxFormSubmissionRequest["page"]
): TaxFormSubmissionRequest => {
  const filter: TaxFormSubmissionFilterRequest = {
    yearString: `${year}`,
    searchString: filters.searchString,
    memberId: filters.memberId,
    clientId: filters.clientId
      ? {
          in: Array.isArray(filters.clientId)
            ? filters.clientId
            : [filters.clientId]
        }
      : undefined,
    searchableStatus: filters.status
      ? {
          in: Array.isArray(filters.status) ? filters.status : [filters.status]
        }
      : undefined,
    isGroupedString: filters.isGroupedTin
      ? filters.isGroupedTin === GroupedTINStatus.Grouped
        ? "true"
        : filters.isGroupedTin === GroupedTINStatus.NoGrouped
        ? "false"
        : undefined
      : undefined,
    groupIds: filters.groupIds
      ? {
          in: Array.isArray(filters.groupIds)
            ? filters.groupIds
            : [filters.groupIds]
        }
      : undefined,
    shareTaxDocument: {
      in:
        filters.shareTaxDocument === ShareTaxDocumentStatus.NotShared
          ? [
              PayeeTaxDocumentSharePermission.Decline,
              PayeeTaxDocumentSharePermission.Unspecified
            ]
          : filters.shareTaxDocument === ShareTaxDocumentStatus.Shared
          ? [PayeeTaxDocumentSharePermission.Allow]
          : undefined
    },
    "data.totalAmount": filters.totalAmount
      ? {
          ">=": filters.totalAmount.from,
          "<=": filters.totalAmount.to
        }
      : undefined,
    searchableTaxStatus: filters.searchableTaxStatus
      ? {
          in: Array.isArray(filters.searchableTaxStatus)
            ? filters.searchableTaxStatus
            : [filters.searchableTaxStatus]
        }
      : undefined,
    "taxForm.exclusionReason": filters.exclusionReason
      ? {
          in: Array.isArray(filters.exclusionReason)
            ? filters.exclusionReason
            : [filters.exclusionReason]
        }
      : undefined,
    "data.w9Info.state": filters.state
      ? {
          in: Array.isArray(filters.state) ? filters.state : [filters.state]
        }
      : undefined,
    recipientSyncEnabledString: filters.recipientSyncEnabledString
      ? filters.recipientSyncEnabledString
      : undefined,
    "data.deliveryMethod": filters.deliveryMethod
      ? filters.deliveryMethod
      : undefined,
    hasPendingCorrectionString:
      filters.correctionRequest === FormContractorRequestFilterOption.Pending
        ? "true"
        : undefined,
    isCorrectionAcceptedString:
      filters.correctionRequest === FormContractorRequestFilterOption.Accepted
        ? "true"
        : undefined,
    isCorrectionRejectedString:
      filters.correctionRequest === FormContractorRequestFilterOption.Rejected
        ? "true"
        : undefined,
    isCorrectedString: filters?.formAmendments?.includes(
      FormAmendmentsFilterOption.Corrected
    )
      ? "true"
      : undefined,
    isVoidedString: filters.formAmendments?.includes(
      FormAmendmentsFilterOption.Voided
    )
      ? "true"
      : undefined,
    isSupercededString: filters.formAmendments?.includes(
      FormAmendmentsFilterOption.Superceded
    )
      ? "true"
      : undefined
  };

  const sortRequest = getSortRuquest(sort);

  return {
    filter,
    sort: sortRequest,
    page: page || {
      size: 10
    }
  };
};

const sortMap: Record<WSTableColumnSortDirection, "asc" | "desc" | undefined> =
  {
    ascending: "asc",
    descending: "desc",
    none: undefined
  };

function getSortRuquest(
  urlQuerySort?: UrlQuerySort<"name" | "totalAmount">
): TaxFormSubmissionQuerySort {
  if (urlQuerySort?.field === "name") {
    return {
      searchableName: sortMap[urlQuerySort.direction]
    };
  }

  if (urlQuerySort?.field === "totalAmount") {
    return {
      "data.totalAmount": sortMap[urlQuerySort.direction]
    };
  }

  return {
    updatedAt: "desc"
  };
}
