import {
  TaxFormEventType,
  TaxFormNeedsActionReason
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { toWSDateString } from "@wingspanhq/fe-component-library";

export const NEEDS_ACTION_REASONS: Record<
  TaxFormNeedsActionReason,
  {
    title: string;
    helperText: React.ReactNode;
    getMessage(taxForm: IPayerTaxFormResponse): React.ReactNode;
  }
> = {
  [TaxFormNeedsActionReason.InvalidTinVerificationStatus]: {
    helperText: "Unverified TIN",
    title: "Action Required: unverified TIN",
    getMessage: () => <>Resolve the TIN verification for 1099 filing</>
  },
  [TaxFormNeedsActionReason.IncompleteW9]: {
    helperText: "Incomplete W-9",
    title: "Action Required: missing information",
    getMessage: () => <>Complete the required information for 1099 filing</>
  },

  [TaxFormNeedsActionReason.ManuallySet]: {
    helperText: "Manually set",
    title: "Action Required: updated manually",
    getMessage: taxForm => {
      const event = taxForm.eventHistory
        .filter(
          event =>
            event.eventType === TaxFormEventType.PayerManuallyUpdatedStatus
        )
        .pop();

      if (event?.eventActor) {
        return (
          <>
            Filing status updated to Action Required by{" "}
            {selectorMemberName(event.eventActor)} on{" "}
            {toWSDateString(event.timestamp, "monthDayYear")}.
          </>
        );
      } else {
        return <>Filing status updated to Action Required manually.</>;
      }
    }
  }
};
