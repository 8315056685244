import { useUserId } from "../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { useModalEditAddress } from "../../../Settings/routes/RouteAccount/components/FormEditAddress";
import { DataView } from "../../../../shared/components/DataView";
import { openIntercom } from "../../../../shared/utils/intercom";
import { selectorAddressString } from "../../../../shared/selectors/selectorAddressString";
import { selectorTINVerificationStatusAlert } from "../../../../shared/selectors/selectorTINVerificationStatusAlert";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerifications } from "../../../../query/onboarding/queries/useQueryVerifications";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorAccountType } from "../../../../shared/selectors/selectorAccountType";

export const BusinessInformation: React.FC<{}> = () => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity();
  const modalEditAddress = useModalEditAddress();
  const queryUser = useUserProfile(userId);
  const queryVerifications = useQueryVerifications();

  const member = queryMember.data;

  return (
    <WSQueries
      queries={{
        queryMember,
        // queryMonitoredEntity,
        queryUser
        // queryVerifications
      }}
    >
      {({
        queryMemberData,
        // queryMonitoredEntityData,
        // queryVerificationsData,
        queryUserData
      }) => {
        const accountType = selectorAccountType(
          queryMonitoredEntity.data,
          queryMemberData
        );

        const verificationStatus = queryVerifications.data?.tax;

        const nameLabel =
          accountType === "Business" ? "Legal business name" : "Full name";
        const nameValue =
          accountType === "Business"
            ? member?.profile.company?.legalBusinessName
            : [
                queryUserData.profile.firstName,
                queryUserData.profile.lastName
              ].join(" ");

        const tinPostfix = accountType === "Business" ? " (EIN)" : " (SSN)";
        const tinValue =
          accountType === "Business" && member?.profile.company?.taxId
            ? member?.profile.company?.taxId
            : "•••••••••";

        return (
          <DataView
            loading={queryMember.isLoading || queryMonitoredEntity.isLoading}
            title="Business information"
            data={[
              {
                label: {
                  text: nameLabel,
                  actionPosition: "left",
                  action: {
                    label: "Request update",
                    onClick: () => {
                      openIntercom();
                    }
                  }
                },
                value: nameValue
              },
              {
                label: {
                  text: "Taxpayer identification number",
                  actionPosition: "left",
                  action: {
                    label: "Request update",
                    onClick: () => {
                      openIntercom();
                    }
                  }
                },
                value: tinValue + tinPostfix,
                alert: selectorTINVerificationStatusAlert(verificationStatus),
                fsExclude: true
              },
              {
                label: {
                  text: "Business address",
                  actionPosition: "left",
                  action: {
                    label: "Edit",
                    onClick: () => {
                      modalEditAddress.open();
                    }
                  }
                },
                value: selectorAddressString(member?.profile.address, true),
                fsExclude: true
              }
            ]}
          />
        );
      }}
    </WSQueries>
  );
};
