import { useMemo } from "react";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../constants/corrections";
import { selectorAddressString } from "../../../shared/selectors/selectorAddressString";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces/address";

import { ICorrectionData } from "../types";

export function useRequestCorrectionCorrectedFields(
  correctionData: ICorrectionData,
  correctionType: string
) {
  const {
    amountCorrectionData,
    addressCorrectionData,
    tinOrNameCorrectionData
  } = correctionData;
  const fields = useMemo(() => {
    if (
      ![CORRECTION_AMOUNT, CORRECTION_ADDRESS, CORRECTION_TIN_OR_NAME].includes
    ) {
      throw new Error(`Invalid correction type: ${correctionType}`);
    }

    if (correctionType === CORRECTION_AMOUNT) {
      return [
        {
          label: "1099-NEC total",
          // TODO: format amount
          value: amountCorrectionData?.amount
        }
      ];
    } else if (correctionType === CORRECTION_ADDRESS) {
      const address = addressCorrectionData?.address;
      const addressString = selectorAddressString({
        ...(address as IAddress)
      });
      return [
        {
          label: "Address",
          value: addressString
        }
      ];
    } else if (correctionType === CORRECTION_TIN_OR_NAME) {
      const correctedTinData = tinOrNameCorrectionData;
      const identificationNumberType = correctedTinData?.ein
        ? TinType.Business
        : TinType.Individual;
      return [
        ...(identificationNumberType === TinType.Business
          ? [
              {
                label: "Business name",
                value: correctedTinData?.businessName ?? "-"
              }
            ]
          : [
              {
                label: "Name",
                value:
                  [correctedTinData?.firstName, correctedTinData?.lastName]
                    .filter(Boolean)
                    .join(" ") ?? "-"
              }
            ]),
        {
          label: "Federal tax classification",
          value: selectorTaxClassificationLabel(
            correctedTinData?.taxClassification
          )
        },
        {
          label: "Taxpayer identification number",
          value:
            identificationNumberType === TinType.Business
              ? `${correctedTinData?.ein ?? "-"} (EIN)`
              : "••••••••• (SSN)"
        }
      ];
    }

    return [];
  }, [correctionType, correctionData]);

  return fields;
}
