import {
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSInputNumberOld,
  WSText,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import { OriginalValuePreview } from "../OriginalValuePreview";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { TaxFormViewer } from "../../../types";

export interface AmountCorrectionFormProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  amount: number;
  payerName?: string;
  recipientName?: string;
  showRequestedValue?: boolean;
  correctedAmount?: number;
  taxFormViewer?: TaxFormViewer;
}

export const AmountCorrectionForm: React.FC<AmountCorrectionFormProps> = ({
  taxForm,
  amount,
  payerName,
  recipientName,
  taxFormViewer = TaxFormViewer.Payee,
  showRequestedValue,
  correctedAmount
}) => {
  return (
    <>
      <WSText.Heading5 mb="M">Amount correction</WSText.Heading5>
      <WSText.ParagraphSm mb="XL" color="gray500">
        The income reported is more or less than what was actually paid
      </WSText.ParagraphSm>

      <OriginalValuePreview>
        <WSFlexBox direction="column">
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            1099-NEC Total
          </WSText.ParagraphSm>
          <WSText.ParagraphSm color="gray600" strike formatMoney>
            {amount}
          </WSText.ParagraphSm>
        </WSFlexBox>
      </OriginalValuePreview>

      {showRequestedValue ? (
        <WSElement mb="M">
          <CorrectedValuePreview
            taxForm={taxForm}
            taxFormViewer={taxFormViewer}
          >
            <WSFlexBox direction="column">
              <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
                Add1099-NEC Totalress
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="medium" color="gray600" formatMoney>
                {correctedAmount}
              </WSText.ParagraphSm>
            </WSFlexBox>
          </CorrectedValuePreview>
        </WSElement>
      ) : null}

      <WSText weight="medium" mb="XS">
        What should the 1099-NEC total be?
      </WSText>
      <WSFormOld.Field
        mb="XL"
        name="necTotalAmount"
        component={WSInputNumberOld}
        componentProps={{
          mode: "currency",
          currency: "USD",
          placeholder: "$ 0.00"
        }}
      />
      <WSText weight="medium" mb="XS">
        Comments
      </WSText>
      <WSFormOld.Field
        name="amountCorrectionHelpText"
        component={WSTextArea}
        mb="XS"
      />
      {payerName ? (
        <WSText.ParagraphSm mb="2XL" color="gray400">
          Please provide specific details that will help {payerName}, like
          incorrect invoice numbers, amounts or anything else that will help
          them correct your tax form.
        </WSText.ParagraphSm>
      ) : recipientName ? (
        <WSText.ParagraphSm mb="2XL" color="gray400">
          Please provide specific details that will inform {recipientName} about
          the correction on their Form 1099-NEC.
        </WSText.ParagraphSm>
      ) : null}
    </>
  );
};
