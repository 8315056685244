import { InfiniteQueryConfig } from "react-query";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../../helpers";
import {
  getTaxFormSubmissionRows,
  TaxFormSubmissionRequest,
  TaxFormSubmissionRow
} from "../../../../services/search";
import flatten from "lodash/flatten";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";

export const QUERY_TAX_FORM_SUBMISSION_ROWS = "QUERY_TAX_FORM_SUBMISSION_ROWS";

export const useTaxFormSubmissionRowsQuery = (
  params?: TaxFormSubmissionRequest,
  config?: InfiniteQueryConfig<TaxFormSubmissionRow[], WSServiceError>
) => {
  const size = params?.page?.size ?? 10;

  const xWingspanExpansion = useXWingspanExpansion();

  const query = useWSInfiniteQuery<TaxFormSubmissionRow[], WSServiceError>(
    [QUERY_TAX_FORM_SUBMISSION_ROWS, { params, xWingspanExpansion, size }],
    (_, __, pageNumber = 1) => {
      return getTaxFormSubmissionRows(
        {
          ...params,
          page: {
            size,
            number: pageNumber
          }
        },
        xWingspanExpansion
      );
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data) : undefined
  };
};
