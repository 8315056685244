import { useFocusedFeatures } from "../../../FocusedFeatures";
import { New } from "../New";
import { SideNavRootLinkOption } from "../SideNavRootLinkOption";
import { selectorCountOfVisibleNotifications } from "../../../shared/selectors/selectorCountOfVisibleNotifications";
import { useIsMobile, WSFlexBox } from "@wingspanhq/fe-component-library";
import styles from "../styles.module.scss";
import { CollapsibleMenu } from "../CollapsibleMenu";
import { SideNavMenuItem } from "../SideNavMenuItem";
import { plural } from "../../../shared/utils/plural";
import * as React from "react";
import { useNotifications } from "../../../query/notifications/queries/useNotifications";
import { useCustomization } from "../../../modules/customization";
import { useShouldPauseAccount } from "../../../shared/hooks/useShouldPauseAccount";

import { TAX_FILING_1099_FILING_PATH } from "../../../modules/TaxFiling/paths";

export enum ExpandableSideNavMenuOptions {
  GetPaid = "GetPaid",
  SendPayments = "SendPayments",
  FinancialBenefits = "FinancialBenefits",
  LifestyleBenefits = "LifestyleBenefits",
  Reporting = "Reporting",
  TaxFiling = "TaxFilings",
  DataAndIntegrations = "DataAndIntegrations",
  Settings = "Settings"
}

export type DefaultNavProps = {
  onExpand: (value: ExpandableSideNavMenuOptions) => void;
  isSideNavOpened: boolean;
  setIsDrawerOpened: (v: boolean) => void;
  setIsSideNavOpened: (v: boolean) => void;
  expandedItems: ExpandableSideNavMenuOptions[];
};

export const DefaultNav: React.FC<DefaultNavProps> = ({
  onExpand,
  expandedItems,
  setIsDrawerOpened,
  isSideNavOpened,
  setIsSideNavOpened
}) => {
  const isMobile = useIsMobile();
  const focusedFeatures = useFocusedFeatures();
  const queryNotifications = useNotifications();
  const { terminology } = useCustomization();

  const shouldPauseAccountTaxes = useShouldPauseAccount("taxes");
  const shouldPauseAccountWallet = useShouldPauseAccount("wallet");

  return (
    <>
      {focusedFeatures.showSideNavNew && (
        <New
          isSideNavOpened={isSideNavOpened}
          setIsSideNavOpened={isMobile ? setIsDrawerOpened : setIsSideNavOpened}
        />
      )}

      <SideNavRootLinkOption
        isVisible={focusedFeatures.showSideNavGettingStarted}
        to="/member/dashboard"
        testId="memberGettingStarted"
        label="Getting started"
        icon="start"
        isSideNavOpened={isSideNavOpened}
        count={
          queryNotifications.data &&
          selectorCountOfVisibleNotifications(queryNotifications.data)
        }
      />

      <WSFlexBox direction="column" className={styles.topNavItems} mt="M">
        <CollapsibleMenu
          isVisible={focusedFeatures.showSideNavGetPaid}
          isSideNavOpened={isSideNavOpened}
          icon="in"
          label="Get paid"
          isExpanded={expandedItems.includes(
            ExpandableSideNavMenuOptions.GetPaid
          )}
          onClick={() => onExpand(ExpandableSideNavMenuOptions.GetPaid)}
        >
          <SideNavMenuItem
            linkTo="/member/invoices"
            testId="paymentsInvoicing"
            isVisible={true}
            label={plural(terminology().getPaidInvoice)}
            exact
          />

          <SideNavMenuItem
            linkTo="/member/payers"
            testId="clients"
            isVisible={true}
            label={plural(terminology().getPaidClient)}
          />
        </CollapsibleMenu>

        <CollapsibleMenu
          isVisible={focusedFeatures.showSideNavSendPayments}
          isSideNavOpened={isSideNavOpened}
          icon="out"
          label="Send payments"
          isExpanded={expandedItems.includes(
            ExpandableSideNavMenuOptions.SendPayments
          )}
          onClick={() => onExpand(ExpandableSideNavMenuOptions.SendPayments)}
        >
          <SideNavMenuItem
            linkTo="/member/invoices/payables"
            testId="paymentsPayables"
            isVisible={focusedFeatures.showSideNavPayables}
            label={plural(terminology().sendPaymentsPayable)}
          />
          <SideNavMenuItem
            linkTo="/member/payees"
            testId="collaborators"
            isVisible={focusedFeatures.showSideNavPayees}
            label={plural(terminology().sendPaymentsContractor)}
          />
        </CollapsibleMenu>

        <CollapsibleMenu
          isVisible={focusedFeatures.showSideNavFinancialBenefits}
          isSideNavOpened={isSideNavOpened}
          icon="card"
          label="Financial benefits"
          isExpanded={expandedItems.includes(
            ExpandableSideNavMenuOptions.FinancialBenefits
          )}
          onClick={() =>
            onExpand(ExpandableSideNavMenuOptions.FinancialBenefits)
          }
        >
          <SideNavMenuItem
            linkTo="/member/wallet"
            testId="myFinancesWallet"
            isVisible={!shouldPauseAccountWallet}
            label="Wingspan wallet"
          />

          <SideNavMenuItem
            linkTo="/member/bookkeeping"
            testId="myFinancesIncomeAndExpenses"
            isVisible={true}
            label="Income & expenses"
          />

          <SideNavMenuItem
            linkTo="/member/taxes"
            testId="myFinancesTaxWithholdings"
            isVisible={!shouldPauseAccountTaxes}
            label="Tax withholdings"
          />
        </CollapsibleMenu>

        <CollapsibleMenu
          isVisible={focusedFeatures.showSideNavLifestyleBenefits}
          isSideNavOpened={isSideNavOpened}
          icon="heart"
          label="Lifestyle benefits"
          isExpanded={expandedItems.includes(
            ExpandableSideNavMenuOptions.LifestyleBenefits
          )}
          onClick={() =>
            onExpand(ExpandableSideNavMenuOptions.LifestyleBenefits)
          }
        >
          <SideNavMenuItem
            linkTo="/member/benefits/additional-coverage/all"
            testId="benefits-additional-coverage"
            isVisible={true}
            label="Insurance"
          />

          <SideNavMenuItem
            linkTo="/member/benefits/my-benefits"
            testId="benefits-my-benefits"
            isVisible={true}
            label="Premium perks"
          />

          <SideNavMenuItem
            linkTo="/member/knowledge-center"
            testId="knowledgeCenter"
            isVisible={true}
            label="Knowledge center"
            exact={true}
          />
        </CollapsibleMenu>

        <SideNavRootLinkOption
          isVisible={focusedFeatures.showSideNavReports}
          to="/member/reports"
          testId="reports"
          label="Reporting"
          icon="report"
          isSideNavOpened={isSideNavOpened}
        />

        <CollapsibleMenu
          isVisible={focusedFeatures.showSideNavTaxFillingSection}
          isSideNavOpened={isSideNavOpened}
          icon="paperclip"
          label="Tax filing"
          isExpanded={expandedItems.includes(
            ExpandableSideNavMenuOptions.TaxFiling
          )}
          onClick={() => onExpand(ExpandableSideNavMenuOptions.TaxFiling)}
        >
          <SideNavMenuItem
            linkTo={TAX_FILING_1099_FILING_PATH}
            testId="NEC1099Filing"
            isVisible={true}
            label="1099 Filing"
          />
        </CollapsibleMenu>
      </WSFlexBox>
    </>
  );
};
