import { WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { PayablesTable } from "../../components/NewPayablesTable/PayablesTable";

export const PayablesAll: React.FC = () => {
  useBrowserPageTitle("All Payables");

  return (
    <>
      <WSText.Heading1 mb="M">All Payables</WSText.Heading1>
      <WSText color="gray600" mb="2XL">
        Every requested, scheduled, and paid payable.
      </WSText>

      <PayablesTable
        basePath="/member/invoices/payables/all"
        visibleFilters={{
          status: true,
          payee: true,
          openedAtRange: true,
          paidAtRange: true,
          bulkBatch: true
        }}
        showHeaders
        showInvoiceNumber
        showDateRequested
        showDueDate
        showDatePaid
        emptyState={{
          firstTime:
            "Every requested, scheduled, and paid payable will display here.",
          standart: "No payables."
        }}
      />
    </>
  );
};
