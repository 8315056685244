import {
  WSButton,
  WSButtons,
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSPill,
  WSRadioInputGroup,
  WSText,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { TaxFormStatus } from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { usePayerTaxFormUpdate } from "../../../../../query/taxForm/mutations/useTaxFormUpdate";
import { selectorIsW9FormDataComplete } from "../../../selectors/selectorIsW9FormDataComplete";
import { EXCLUSION_REASONS } from "./exclusionReasons";

const OPTIONS = [
  {
    label: (
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="gray600">Excluded</WSText.ParagraphSm>
        <WSPill icon="block" text="Excluded" />
      </WSFlexBox.CenterY>
    ),
    value: TaxFormStatus.Excluded
  },
  {
    label: (
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="blue500">Ready</WSText.ParagraphSm>
        <WSPill theme="blue" icon="envelope" text="Ready" />
      </WSFlexBox.CenterY>
    ),
    value: TaxFormStatus.ReadyToSubmitToIrs
  },
  {
    label: (
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="amber400">
          Action Required
        </WSText.ParagraphSm>
        <WSPill theme="warning" icon text="Action Required" />
      </WSFlexBox.CenterY>
    ),
    value: TaxFormStatus.NeedsAction
  }
];

const ModalUpdateStatus: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [status, setStatus] = React.useState(taxForm.status);
  const [updateTaxForm, updateTaxFormMeta] = usePayerTaxFormUpdate();

  const recommendedOption =
    OPTIONS.find(o => o.value === taxForm.recommendedPreFilingStatus) ||
    OPTIONS.find(o => o.value === taxForm.status) ||
    OPTIONS[0];

  const [showManual, setShowManual] = React.useState(
    recommendedOption.value !== taxForm.status
  );

  const otherOptions = OPTIONS.filter(
    o => o.value !== recommendedOption.value
  ).filter(option => {
    if (option.value === TaxFormStatus.ReadyToSubmitToIrs) {
      // User can't change status to ReadyToSubmitToIrs if totalAmount is 0 or form data is incomplete
      if (
        taxForm.data.totalAmount &&
        taxForm.data.totalAmount > 0 &&
        taxForm.data.w9Info &&
        selectorIsW9FormDataComplete(taxForm.data.w9Info)
      ) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  });

  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        A change in the recipient information has resulted in the following
        status change:
      </WSText>
      {status === TaxFormStatus.Excluded && taxForm.exclusionReason ? (
        <WSMessageBox.Warning
          mt="XL"
          title={EXCLUSION_REASONS[taxForm.exclusionReason].title}
          noBorder
        >
          <WSText color="gray600">
            {EXCLUSION_REASONS[taxForm.exclusionReason].getMessage(taxForm)}
          </WSText>
        </WSMessageBox.Warning>
      ) : null}

      <WSText weight="medium" mt="XL">
        Recommended
      </WSText>
      <WSRadioInputGroup
        mt="M"
        name="status"
        value={status}
        optionHasBorder
        onChange={(e: any) => {
          setStatus(e?.target?.value as TaxFormStatus);
        }}
        options={[recommendedOption]}
      />
      <WSButton.Link
        mt="XL"
        size="S"
        rightIcon={showManual ? "chevron-up" : "chevron-down"}
        onClick={() => setShowManual(!showManual)}
      >
        Manually overwrite status
      </WSButton.Link>
      <WSElement hidden={!showManual}>
        <WSRadioInputGroup
          mt="XL"
          name="status"
          value={status}
          optionHasBorder
          onChange={(e: any) => {
            setStatus(e?.target?.value as TaxFormStatus);
          }}
          options={otherOptions}
        />
      </WSElement>
      <WSErrorMessage
        contextKey="NEC1099DetailsUpdate"
        my="XL"
        error={updateTaxFormMeta.error}
      />
      <WSButtons mt="2XL" forceFullWidth>
        <WSButton.Primary
          onAsyncClick={async () => {
            if (taxForm.status !== status) {
              await updateTaxForm(
                {
                  taxFormId: taxForm.taxFormId,
                  requestData: { status, data: {} }
                },
                {
                  throwOnError: true
                }
              );
            }
            onClose(true);
          }}
        >
          Confirm
        </WSButton.Primary>
        <WSButton.Secondary onClick={() => onClose(false)}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useModalUpdateStatus = () =>
  useWSModal(ModalUpdateStatus, {
    title: "Update Status",
    size: "S"
  });
