import {
  WSButton,
  WSButtons,
  WSInputNumberOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { SetupParams } from ".";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../../query/helpers";
import { paymentsService } from "../../../../services/payments";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { CURRENT_YEAR } from "../../constants/currentYear";

export const RouteNecAmount: React.FC<
  SetupRouteComponentProps<SetupParams>
> = ({ params, onNext, onBack }) => {
  const [amount, setAmount] = useState<number>();

  const taxFormId = params?.taxForm?.taxFormId;

  const [submit, submitMeta] = useWSMutation(
    async () => {
      if (!taxFormId) {
        throw new Error("No tax form id provided");
      }

      await paymentsService.taxForm.update(
        taxFormId,
        {
          payerConfirmedW9Info: true,
          data: {
            totalAmount: amount
          }
        },
        {
          skipEventHistory: true
        }
      );
    },
    {
      onSuccess: onNext
    }
  );

  return (
    <EditLayout title="Add recipient" onBack={onBack}>
      <WSText.Heading4>
        Non-employee compensation ({CURRENT_YEAR})
      </WSText.Heading4>

      <WSText.ParagraphSm color="gray500" mt="M">
        Please enter the amount of non-employee compensation for {CURRENT_YEAR}.
        The amount entered should be in USD.
      </WSText.ParagraphSm>

      <WSText weight="medium" mt="XL">
        Non-employee compensation (USD)
      </WSText>

      <WSInputNumberOld
        mt="M"
        mode="currency"
        currency="USD"
        value={amount}
        placeholder="Enter amount"
        onChange={event => setAmount(event.value || 0)}
      />

      <WSText.ParagraphSm color="gray500" mt="M">
        Note: The payment amount for {CURRENT_YEAR} will appear in the NEC box
        on the 1099-NEC. Recipients won't have access to a detailed transaction
        breakdown for {CURRENT_YEAR}. If they need detailed data, consider
        uploading payment separately.
      </WSText.ParagraphSm>

      <WSText weight="medium" mt="XL">
        Important reminder
      </WSText>

      <WSText.ParagraphSm color="gray500" mt="M">
        Always ensure the information provided aligns with the regulatory
        requirements. This form should be used for U.S. persons or entities
        only. An individual refers to a U.S. citizen or permanent resident,
        while non-individual entities must be established or incorporated within
        the U.S.
      </WSText.ParagraphSm>

      <WSErrorMessage
        contextKey="NEC1099AddRecipient"
        error={submitMeta.error}
        mt="XL"
      />

      <WSButtons mt="XL" forceFullWidth>
        <WSButton.Primary
          disabled={!amount}
          onClick={submit}
          loading={submitMeta.isLoading}
        >
          Continue
        </WSButton.Primary>
        <WSButton.Link onClick={onNext}>Skip for now</WSButton.Link>
      </WSButtons>

      <WSText.ParagraphSm color="gray500" mt="XL">
        If you skip adding the non-employee compensation (USD) for this
        recipient now, you can import payments later using the Batch import
        payments action on the 1099 dashboard.
      </WSText.ParagraphSm>
    </EditLayout>
  );
};
