import {
  useWSModal,
  WSActions,
  WSAlert,
  WSList
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";

const ModalRecipientCorrectionExists: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(): void;
  onReviewCorrectionRequest(): void;
}> = ({ taxForm, onClose, onReviewCorrectionRequest }) => {
  return (
    <WSList gap="XL">
      <WSAlert
        theme="warning"
        icon="alert"
        title="Correction requested by recipient"
      >
        The recipient has requested corrections to their Form 1099-NEC. You must
        first resolve the request before starting a new correction.
      </WSAlert>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label:
              taxForm.status === TaxFormStatus.AcceptedByIrs
                ? "Review correction request"
                : "View correction request",
            onClick: onReviewCorrectionRequest,
            kind: "Primary"
          },
          {
            label: "Back",
            onClick: onClose,
            kind: "Secondary"
          }
        ]}
      />
    </WSList>
  );
};

export const useRecipientCorrectionExistsModal = () =>
  useWSModal(ModalRecipientCorrectionExists, {
    title: "Important, please read:",
    size: "S"
  });
