import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const getPayee = async (
  id: string,
  orgPayerId?: string
): Promise<IPayeeResponse> => {
  const { data } = await servicePayments.get(
    `/payee/${id}`,
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );

  return data;
};
