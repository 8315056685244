import { WSPillProps } from "@wingspanhq/fe-component-library";
import { IInvoiceRow, InvoiceRowStatus } from "../../service";

export const getInvoiceStatusPillProps = (
  invoice: IInvoiceRow
): WSPillProps => {
  switch (invoice.status) {
    case InvoiceRowStatus.AwaitingClient:
      return {
        theme: "neutral",
        icon: "time",
        text: "Awaiting client"
      };

    case InvoiceRowStatus.ActionRequired:
      return {
        theme: "warning",
        icon: true,
        text: "Action required"
      };

    case InvoiceRowStatus.InProgress:
      return {
        theme: "blue",
        icon: "send-mail",
        text: "In-progress"
      };

    case InvoiceRowStatus.Deposited:
      if (!!invoice.events.markedPaidAt) {
        return {
          theme: "neutral",
          icon: "paperclip",
          text: "Off-platform"
        };
      } else {
        return {
          theme: "success",
          icon: true,
          text: "Deposited"
        };
      }
    case InvoiceRowStatus.Draft:
    default:
      return {
        theme: "neutral",
        icon: "draft",
        text: "Draft"
      };
  }
};
