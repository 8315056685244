import { IPayeeResponse, TinType } from "@wingspanhq/payments/dist/interfaces";
import { getPayeeNames } from "../selectors/getPayeeNames";
import { useMemo } from "react";
import { useEditEmail } from "./EditEmail/useEditEmail";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { isPayeeProvidedInfo } from "../routes/RoutePayeeDetails/utils";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";
import {
  WSAlertProps,
  WSDataItemProps,
  WSGrid
} from "@wingspanhq/fe-component-library";
import { selectorCountryName } from "../../../shared/selectors/selectorCountryName";
import { DataItem } from "../../../shared/components/DataItem";
import { getLast4 } from "../../../shared/utils/getLast4";
import { wsName, WSNameParams } from "@wingspanhq/utils/dist/name/wsName";
import { selectorPayeeDefaultTinType } from "../selectors/selectorPayeeDefaultTinType";

type Props = {
  payee: IPayeeResponse;
  editable?: boolean;
};

const getAlertByStatus = (
  status?: "Failed" | "Pending" | "Verified" | "None"
): WSAlertProps | undefined => {
  switch (status) {
    case "Verified":
      return {
        theme: "success",
        icon: "check-circle",
        title: "IRS verified"
      };
    case "Pending":
      return {
        theme: "info",
        icon: "time",
        title: "IRS verification pending"
      };
    case "Failed":
      return {
        theme: "warning",
        icon: "alert",
        title: "IRS mismatch"
      };
    default:
      return undefined;
  }
};

export const ContractorInformation: React.FC<Props> = ({ payee, editable }) => {
  const editEmail = useEditEmail();
  const featureFlags = useFeatureFlags();

  const fields = useMemo(() => {
    if (isPayeeProvidedInfo(payee)) {
      const names = wsName({
        user: payee.user,
        member: payee.member
      } as WSNameParams);

      const payeeW9Data = payee.payeeOwnedData?.payeeW9Data;
      const tinType = selectorPayeeDefaultTinType(payee);

      const result: WSDataItemProps[] = [
        {
          label: "Name",
          value: names.getResolvedName()
        },
        ...(tinType === TinType.Business && payeeW9Data?.disregardedEntityName
          ? [
              {
                label: "Disregarded entity name",
                value: payeeW9Data?.disregardedEntityName
              }
            ]
          : []),
        {
          label: "Country of residence",
          value: payeeW9Data?.country
            ? selectorCountryName(payeeW9Data?.country)
            : undefined
        },
        {
          label: "Federal tax classification",
          value:
            tinType === "Individual"
              ? "Sole proprietorship / Single member LLC"
              : payeeW9Data?.companyStructure
              ? selectorTaxClassificationLabel(payeeW9Data?.companyStructure)
              : undefined
        },
        {
          label: "Taxpayer identification number",
          value:
            tinType === "Individual"
              ? payeeW9Data?.ssn
                ? "•••••" + getLast4(payeeW9Data?.ssn)
                : undefined
              : payeeW9Data?.ein
              ? "•••••" + getLast4(payeeW9Data?.ein)
              : undefined,
          alert: getAlertByStatus(payeeW9Data?.payeeTinVerification?.status)
        },
        {
          label: "Email",
          value: names.email
        },
        {
          label: "Address",
          value:
            [
              payeeW9Data?.addressLine1,
              payeeW9Data?.addressLine2,
              [
                payeeW9Data?.city,
                payeeW9Data?.state,
                payeeW9Data?.postalCode
              ].join(" ")
            ]
              .filter(Boolean)
              .join("\n")
              .trim() || undefined
        }
      ];

      return result;
    } else {
      const names = getPayeeNames(payee);
      const payerOwnedData = payee.payerOwnedData;
      const payeeW9Data = payerOwnedData.payeeW9Data;

      const tinType = selectorPayeeDefaultTinType(payee);
      const country = payeeW9Data.country;

      const name =
        (tinType === "Individual"
          ? [payeeW9Data.firstName, payeeW9Data.lastName]
              .filter(Boolean)
              .join(" ") || payerOwnedData.payeeName
          : payeeW9Data.legalBusinessName || payerOwnedData.payeeCompanyName) ||
        names.getResolvedName();

      const result: WSDataItemProps[] = [
        {
          label: "Name",
          value: name !== names.email ? name : undefined
        },
        ...(tinType === TinType.Business && payeeW9Data.disregardedEntityName
          ? [
              {
                label: "Disregarded entity name",
                value: payeeW9Data.disregardedEntityName
              }
            ]
          : []),
        {
          label: "Country of residence",
          value: country ? selectorCountryName(country) : undefined
        },
        {
          label: "Federal tax classification",
          value:
            tinType === "Individual"
              ? "Sole proprietorship / Single member LLC"
              : payeeW9Data.companyStructure
              ? selectorTaxClassificationLabel(payeeW9Data.companyStructure)
              : undefined
        },
        {
          label: "Taxpayer identification number",
          value:
            tinType === "Individual"
              ? payeeW9Data.ssn
                ? "•••••" + getLast4(payeeW9Data.ssn)
                : undefined
              : payeeW9Data.ein
              ? "•••••" + getLast4(payeeW9Data.ein)
              : undefined,
          alert: getAlertByStatus(payeeW9Data?.payeeTinVerification?.status)
        },
        {
          label: {
            text: "Email",
            action:
              editable &&
              payee.payerCapabilities?.canUpdatePayeeEmail &&
              featureFlags.data?.editContractorEmail
                ? {
                    label: "Edit",
                    onClick: () => {
                      editEmail.run({
                        email: names.email,
                        payeeId: payee.payeeId
                      });
                    }
                  }
                : undefined
          },
          value: names.email
        },
        {
          label: "Address",
          value:
            [
              payeeW9Data.addressLine1,
              payeeW9Data.addressLine2,
              [
                payeeW9Data.city,
                payeeW9Data.state,
                payeeW9Data.postalCode
              ].join(" ")
            ]
              .filter(Boolean)
              .join("\n")
              .trim() || undefined
        }
      ];

      return result;
    }
  }, [payee]);

  return (
    <WSGrid gutter="2XL">
      {fields.map((props, index) => (
        <WSGrid.Item
          key={`${props.label} + ${index}`}
          span={{ xs: "12", s: "6" }}
        >
          <DataItem {...props} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
