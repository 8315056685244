import { useHistory, useLocation } from "react-router-dom";

export const useGoTo = (persistSearch = true) => {
  const history = useHistory();
  const location = useLocation();

  return (path: string, searchParams?: Record<string, string | number>) => {
    if (searchParams) {
      const search = new URLSearchParams(location.search);
      Object.entries(searchParams).forEach(([key, value]) => {
        search.set(key, value.toString());
      });

      history.push({
        pathname: path,
        search: search.toString()
      });
      return;
    }

    history.push({
      pathname: path,
      ...(persistSearch ? { search: location.search } : null)
    });
  };
};
