import React from "react";
import classNames from "classnames";

import { WSText, WSTextKindsType } from "../../core/WSText/WSText.component";
import {
  WSElement,
  WSElementColorsType,
  WSElementProps
} from "../../WSElement/WSElement.component";
import {
  WSIcon,
  WSIconName,
  WSIconSize
} from "../../core/WSIcon/WSIcon.component";
import styles from "./WSAvatar.component.module.scss";
import { WSBadge, WSBadgeProps } from "../WSBadge/WSBadge.component";
import { WSImage } from "../../core/WSImage/WSImage.component";

import WingspanAvatar from "../../../assets/images/wingspan/avatar-logo.svg";
import { FLAG_FILES, WSFlag } from "../../../flags";

export type WSAvatarSize = "S" | "M" | "L";
export type WSAvatarType = "text" | "image" | "icon" | "country" | "wingspan";
export type WSAvatarForm = "circle" | "square";

export interface WSAvatarBasicProps extends WSElementProps {
  type: WSAvatarType;
  size?: WSAvatarSize;
}

export type WSAvatarBadgeProps = Omit<WSBadgeProps, "size">;

export interface WSAvatarExtendedProps extends WSAvatarBasicProps {
  badge?: boolean;
  badgeProps?: WSAvatarBadgeProps;
  format?: WSAvatarForm;
}

export interface WSAvatarTextProps extends WSAvatarExtendedProps {
  type: "text";
  text: string;
}

export interface WSAvatarImageProps extends WSAvatarExtendedProps {
  type: "image";
  image: string;
}

export interface WSAvatarIconProps extends WSAvatarExtendedProps {
  type: "icon";
  icon: WSIconName;
}

export interface WSAvatarCountryProps extends WSAvatarExtendedProps {
  type: "country";
  country: WSFlag;
}

export interface WSAvatarWingspanProps extends WSAvatarExtendedProps {
  type: "wingspan";
}

export type WSAvatarProps =
  | WSAvatarTextProps
  | WSAvatarIconProps
  | WSAvatarCountryProps
  | WSAvatarImageProps
  | WSAvatarWingspanProps;

const AVATAR_SIZE_DEFAULT: WSAvatarSize = "M";
const AVATAR_FORMAT_DEFAULT: WSAvatarForm = "square";
const AVATAR_BACKGROUND_DEFAULT: WSAvatarProps["colorBackground"] = "gray500";
const AVATAR_COLOR_DEFAULT: WSElementColorsType = "white";
const AVATAR_BADGE_THEME_DEFAULT: WSBadgeProps["theme"] = "amber";

const getBaseClassName = (props: WSAvatarProps) => {
  const {
    size = AVATAR_SIZE_DEFAULT,
    format = AVATAR_FORMAT_DEFAULT,
    className
  } = props as WSAvatarExtendedProps;

  return classNames(className, styles.avatar, {
    [styles[`size-${size}`]]: size,
    [styles[`format-${format}`]]: format
  });
};

interface AvatarWrapperType extends WSElementProps {
  badge?: WSAvatarExtendedProps["badge"];
  badgeProps?: WSAvatarExtendedProps["badgeProps"];
}

const AvatarWrapper = React.forwardRef<HTMLDivElement, AvatarWrapperType>(
  function AvatarWrapper(props, ref) {
    const {
      children,
      badge,
      badgeProps,
      colorBackground = AVATAR_BACKGROUND_DEFAULT,
      ...restProps
    } = props;
    const { theme = AVATAR_BADGE_THEME_DEFAULT, icon, number } =
      badgeProps || {};

    return (
      <WSElement ref={ref} {...restProps}>
        {badge && icon ? (
          <WSBadge
            className={styles.badgeIcon}
            size="S"
            theme={theme}
            icon={icon}
          />
        ) : badge && number ? (
          <WSBadge
            className={styles.badgeNumber}
            size="S"
            theme={theme}
            number={number}
          />
        ) : badge ? (
          <WSBadge className={styles.badge} size="XS" theme={theme} />
        ) : null}
        <WSElement
          className={styles.avatarContent}
          colorBackground={colorBackground}
        >
          {children}
        </WSElement>
      </WSElement>
    );
  }
);

export const WSAvatar = Object.assign(
  React.forwardRef<HTMLDivElement, WSAvatarProps>(function WSAvatar(
    props,
    ref
  ) {
    const {
      type,
      size = AVATAR_SIZE_DEFAULT,
      className,
      ...otherProps
    } = props;
    const baseClassName = getBaseClassName(props);

    switch (type) {
      case "text": {
        const {
          text = "",
          color = AVATAR_COLOR_DEFAULT,
          ...restProps
        } = otherProps as Omit<WSAvatarTextProps, "type" | "size">;
        const textKind: WSTextKindsType =
          size === "S"
            ? "Caption"
            : size === "M"
            ? "ParagraphXs"
            : "ParagraphSm";
        const className = classNames(baseClassName, styles.text);

        return (
          <AvatarWrapper ref={ref} className={className} {...restProps}>
            <WSText
              kind={textKind}
              className={styles.innerText}
              color={color}
              weight="medium"
            >
              {text.substring(0, 2).toUpperCase()}
            </WSText>
          </AvatarWrapper>
        );
      }
      case "image": {
        const { image, ...restProps } = otherProps as Omit<
          WSAvatarImageProps,
          "type" | "size"
        >;
        const className = classNames(baseClassName, styles.image);

        return (
          <AvatarWrapper ref={ref} className={className} {...restProps}>
            <WSImage className={styles.img} placeholder="Image" src={image} />
          </AvatarWrapper>
        );
      }

      case "icon": {
        const {
          icon,
          color = AVATAR_COLOR_DEFAULT,
          ...restProps
        } = otherProps as Omit<WSAvatarIconProps, "type" | "size">;
        const className = classNames(baseClassName, styles.icon);
        const iconSize: WSIconSize =
          size === "S" ? "XS" : size === "M" ? "S" : "M";

        return (
          <AvatarWrapper ref={ref} className={className} {...restProps}>
            <WSIcon
              className={styles.icon}
              name={icon}
              color={color}
              size={iconSize}
            />
          </AvatarWrapper>
        );
      }
      case "country": {
        const { country, ...restProps } = otherProps as Omit<
          WSAvatarCountryProps,
          "type" | "size"
        >;
        const className = classNames(baseClassName, styles.country);

        return (
          <AvatarWrapper ref={ref} className={className} {...restProps}>
            <WSImage
              src={FLAG_FILES[country]}
              placeholder=""
              className={styles.countryImage}
            />
          </AvatarWrapper>
        );
      }
      default: {
        const className = classNames(baseClassName, styles.wingspan);

        return (
          <AvatarWrapper
            ref={ref}
            className={className}
            colorBackground={undefined}
            {...otherProps}
          >
            <WingspanAvatar />
          </AvatarWrapper>
        );
      }
    }
  }),
  {
    Wingspan: React.forwardRef<
      HTMLDivElement,
      Omit<WSAvatarWingspanProps, "type">
    >((props, ref) => <WSAvatar ref={ref} type="wingspan" {...props} />),
    Country: React.forwardRef<
      HTMLDivElement,
      Omit<WSAvatarCountryProps, "type">
    >((props, ref) => <WSAvatar ref={ref} type="country" {...props} />),
    Image: React.forwardRef<HTMLDivElement, Omit<WSAvatarImageProps, "type">>(
      (props, ref) => <WSAvatar ref={ref} type="image" {...props} />
    ),
    Icon: React.forwardRef<HTMLDivElement, Omit<WSAvatarIconProps, "type">>(
      (props, ref) => <WSAvatar ref={ref} type="icon" {...props} />
    ),
    Text: React.forwardRef<HTMLDivElement, Omit<WSAvatarTextProps, "type">>(
      (props, ref) => <WSAvatar ref={ref} type="text" {...props} />
    )
  }
);
