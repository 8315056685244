import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { LayoutW9 } from "../../../components/LayoutW9";
import {
  WSFormOld,
  WSInputMaskOld,
  WSMessageBox,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  companyNameValidator,
  validatorFirstName,
  validatorLastName
} from "../../../../../utils/validators";
import * as Yup from "yup";
import { WSQueries } from "../../../../../query/WSQuery";

import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import {
  IRecipientVerificationResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { useTaxFormVerifyIdentity } from "../../../../../query/taxForm/mutations/useTaxFormVerifyIdentity";
import { usePayeeTaxForm } from "../../../../../query/taxForm/queries/usePayeeTaxForm";
import { selectorPayeeTaxFormPayerCompanyName } from "../../../../TaxFiling/selectors/selectorPayeeTaxFormPayerCompanyName";
import { selectorPayeeTaxFormPayerContact } from "../../../selectors/selectorPayeeTaxFormPayerContact";
import { useWSStore } from "../../../../../store";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";

export const MAX_FAILURE_COUNT = 5;

const isItMaximumFailureCount = (failureCount?: number) => {
  return (failureCount || 0) >= MAX_FAILURE_COUNT;
};

export const RouteVerifyYourIdentityById: React.FC<
  SetupRouteComponentProps
> = ({ onBack, onNext }) => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const queryTaxForm = usePayeeTaxForm(match.params.taxFormId);

  const wsStore = useWSStore();

  const [verifyIdentity, verifyIdentityMeta] = useTaxFormVerifyIdentity();

  const onSuccess = (response: IRecipientVerificationResponse) => {
    if (response.recipientVerification?.verified) {
      onNext();
    } else if (
      isItMaximumFailureCount(response.recipientVerification?.failureCount)
    ) {
      history.push(
        `/member/1099-filing/tax-information/${response.taxFormId}/locked`
      );
    }
  };

  const onError = () => {
    queryTaxForm.refetch();
  };

  return (
    <LayoutW9
      title="Verify your identity"
      description="For your security, we'll ask a few questions to verify your identity."
    >
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData }) => {
          const failureCount =
            queryTaxFormData.recipientVerification?.failureCount || 0;
          const isIndividual =
            queryTaxFormData.recipientVerification?.tinType ===
            TinType.Individual;
          const noVerificationData =
            queryTaxFormData.recipientVerification?.verified === undefined;

          const { taxFormId, payerPayeeId } = queryTaxFormData;

          const companyName =
            selectorPayeeTaxFormPayerCompanyName(queryTaxFormData) ||
            wsStore.signUp1099?.companyName ||
            "the company who paid you";
          const [payerName, supportEmail] =
            selectorPayeeTaxFormPayerContact(queryTaxFormData);

          if (isItMaximumFailureCount(failureCount)) {
            return (
              <Redirect
                to={`/member/1099-filing/tax-information/${taxFormId}/locked`}
              />
            );
          }

          if (
            queryTaxFormData.recipientVerification?.verified === true ||
            queryTaxFormData.recipientVerification?.verified === undefined
          ) {
            if (
              queryTaxFormData.recipientReadyToSubmitW9 ||
              queryTaxFormData.recipientConfirmedW9Info
            ) {
              return (
                <Redirect to="/member/1099-filing/tax-information/confirm-preferences" />
              );
            } else {
              return (
                <Redirect to="/member/1099-filing/tax-information/personal-info" />
              );
            }
          }

          return (
            <>
              {failureCount > 0 && (
                <WSMessageBox.Error>
                  <WSText weight="medium" color="red500">
                    This information does not match what we have on file
                  </WSText>
                  <WSText.ParagraphSm mt="M" color="gray500">
                    Ensure that your information exactly matches the information
                    previously provided to {companyName}.
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm mt="XS" color="gray500">
                    Please reach out to{" "}
                    <a
                      href={`mailto:${supportEmail}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {payerName || "the payer"}
                    </a>{" "}
                    at {companyName} for assistance.
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm
                    mt="M"
                    color="gray500"
                    underline
                    onClick={() =>
                      openInNewTab("https://www.wingspan.app/support")
                    }
                  >
                    Need more help?
                  </WSText.ParagraphSm>
                </WSMessageBox.Error>
              )}
              {isIndividual ? (
                <WSFormOld
                  defaultValues={{
                    firstName: "",
                    lastName: "",
                    ssnLastFour: ""
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: validatorFirstName.required("Required"),
                    lastName: validatorLastName.required("Required"),
                    ssnLastFour: Yup.string()
                      .length(4, "Must be 4 digits")
                      .required("Required")
                  })}
                  onSubmit={async values => {
                    await verifyIdentity(
                      {
                        taxFormId,
                        payerPayeeId,
                        tinType: TinType.Individual,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        tinLast4: values.ssnLastFour
                      },
                      {
                        onSuccess,
                        onError
                      }
                    );
                  }}
                >
                  <WSText weight="medium" mt="XL">
                    What is your full legal name, as it appears to {companyName}
                    ?
                  </WSText>
                  <WSFormOld.Field
                    name="firstName"
                    componentProps={{ placeholder: "Legal first name" }}
                    component={WSTextInput}
                    mt="M"
                  />
                  <WSFormOld.Field
                    name="lastName"
                    componentProps={{ placeholder: "Legal last name" }}
                    component={WSTextInput}
                    mt="M"
                  />
                  <WSText weight="medium" mt="XL">
                    What are the last 4 digits of your SSN?
                  </WSText>
                  <WSFormOld.Field
                    mt="M"
                    name="ssnLastFour"
                    component={WSInputMaskOld}
                    componentProps={{
                      placeholder: "SSN",
                      mask: "****",
                      unmask: true,
                      fsExclude: true
                    }}
                  />
                  {noVerificationData ? (
                    <WSErrorMessage
                      my="XL"
                      error={verifyIdentityMeta.error}
                      contextKey="NEC1099Onboarding"
                    />
                  ) : null}

                  <WSFormOld.SubmitButton mt="2XL" fullWidth>
                    Continue
                  </WSFormOld.SubmitButton>
                </WSFormOld>
              ) : (
                <WSFormOld
                  defaultValues={{
                    legalBusinessName: "",
                    ein: ""
                  }}
                  validationSchema={Yup.object().shape({
                    legalBusinessName:
                      companyNameValidator.required("Required"),
                    ein: Yup.string()
                      .length(4, "Must be 4 digits")
                      .required("Required")
                  })}
                  onSubmit={async values => {
                    await verifyIdentity(
                      {
                        taxFormId,
                        payerPayeeId,
                        tinType: TinType.Business,
                        legalBusinessName: values.legalBusinessName,
                        tinLast4: values.ein
                      },
                      {
                        onSuccess,
                        onError
                      }
                    );
                  }}
                >
                  <WSText weight="medium" mt="XL">
                    What is your legal business name, as it appears to{" "}
                    {companyName}?
                  </WSText>
                  <WSFormOld.Field
                    name="legalBusinessName"
                    componentProps={{ placeholder: "Legal business name" }}
                    component={WSTextInput}
                    mt="M"
                  />
                  <WSText weight="medium" mt="XL">
                    What are the last 4 digits of your EIN?
                  </WSText>
                  <WSFormOld.Field
                    mt="M"
                    name="ein"
                    component={WSInputMaskOld}
                    componentProps={{
                      placeholder: "EIN",
                      mask: "****",
                      unmask: true,
                      fsExclude: true
                    }}
                  />

                  {noVerificationData ? (
                    <WSErrorMessage
                      my="XL"
                      error={verifyIdentityMeta.error}
                      contextKey="NEC1099Onboarding"
                    />
                  ) : null}

                  <WSFormOld.SubmitButton mt="2XL" fullWidth>
                    Continue
                  </WSFormOld.SubmitButton>
                </WSFormOld>
              )}
            </>
          );
        }}
      </WSQueries>
    </LayoutW9>
  );
};
