import React, { useEffect } from "react";
import {
  useWSModal,
  WSActions,
  WSEmptyState,
  WSInfoBox,
  WSList,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { useTaxFormResync } from "../../../../../query/taxForm/mutations/useTaxFormResync";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";

const ModalRevertChanges: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [resync, resyncMeta] = useTaxFormResync();
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    resync(taxForm.taxFormId, {
      onSuccess: () => {
        setProgress(100);
      },
      onError: () => {
        setProgress(100);
      }
    });
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => {
        if (p >= 80) {
          clearInterval(interval);
        }

        return p + 10;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (resyncMeta.isSuccess) {
    return (
      <WSList gap="XL">
        <WSEmptyState
          title="Reverted to synced contractor details"
          description="Form 1099-NEC details for this contractor have been reverted successfully to synced data from the contractor profile."
          type="done"
          orientation="horizontal"
          size="S"
        />

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Done",
              kind: "Primary",
              onClick() {
                onClose(true);
              }
            }
          ]}
        />
      </WSList>
    );
  }

  return (
    <>
      <WSProgressBar mt="XL" noAnimation={false} percent={progress} />

      <WSList gap="XL">
        <WSErrorMessage
          error={resyncMeta.error}
          contextKey="NEC1099AddRecipient"
        />

        <WSInfoBox>
          Your changes are being reverted. This could take a minute.
        </WSInfoBox>

        <WSText.ParagraphXs color="gray500">
          Do not close this window while reversion is in progress.
        </WSText.ParagraphXs>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Continue",
              disabled: true,
              kind: "Tertiary"
            }
          ]}
        />
      </WSList>
    </>
  );
};

export const useModalRevertingChanges = () =>
  useWSModal(ModalRevertChanges, {
    title: "Revert to synced contractor details",
    size: "S"
  });
