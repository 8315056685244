import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { SetupParams } from ".";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../../query/helpers";
import { paymentsService } from "../../../../services/payments";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";

export const RouteCertifyW9: React.FC<
  SetupRouteComponentProps<SetupParams>
> = ({ onNext, onBack, params }) => {
  const [consent, setConsent] = useState(false);

  const taxFormId = params?.taxForm?.taxFormId;

  const [submit, submitMeta] = useWSMutation<boolean>(
    async () => {
      if (!taxFormId) {
        throw new Error("No tax form id provided");
      }

      await paymentsService.taxForm.update(
        taxFormId,
        {
          payerConfirmedW9Info: true,
          data: {}
        },
        {
          skipEventHistory: true
        }
      );
      return true;
    },
    {
      onSuccess: onNext
    }
  );

  return (
    <EditLayout title="Add recipient" onBack={onBack}>
      <WSText.Heading4>Certify W-9 accuracy</WSText.Heading4>

      <WSText.ParagraphSm color="gray500" mt="M">
        By using Wingspan’s services you agree that the information provided
        Form W-9 is accurate and that you certify the provided information as
        follows:
      </WSText.ParagraphSm>

      <WSMessageBox.Info mt="XL" size="Large">
        <WSText.ParagraphSm color="gray500">
          I confirm the recipient’s accuracy on the W-9 form. By selecting this:
        </WSText.ParagraphSm>
        <WSElement as="ul" pl="XL">
          <WSElement as="li">
            <WSText.ParagraphSm color="gray500" mt="M">
              The taxpayer identification number is validated or in process
            </WSText.ParagraphSm>
          </WSElement>
          <WSElement as="li">
            <WSText.ParagraphSm color="gray500" mt="M">
              The recipient isn’t subject to IRS backup withholding
            </WSText.ParagraphSm>
          </WSElement>
          <WSElement as="li">
            <WSText.ParagraphSm color="gray500" mt="M">
              The recipient is a qualified U.S. citizen or entity
            </WSText.ParagraphSm>
          </WSElement>
          <WSElement as="li">
            <WSText.ParagraphSm color="gray500" mt="M">
              Entered FATCA code(s) signify accurate FATCA reporting exemption
            </WSText.ParagraphSm>
          </WSElement>
        </WSElement>
        <WSText.ParagraphSm color="gray500" mt="M">
          The Internal Revenue Service does not require your consent to any
          provision of this document other than the certifications required to
          avoid backup withholding.”
        </WSText.ParagraphSm>
      </WSMessageBox.Info>

      <WSCheckboxToggle
        mt="2XL"
        name="consent"
        value={consent}
        onChange={setConsent}
        label="By providing the information on this form, I hereby certify, to the best of my knowledge, that the information provided is complete and correct"
      />

      <WSErrorMessage
        contextKey="NEC1099AddRecipient"
        error={submitMeta.error}
        mt="XL"
      />

      <WSButtons mt="XL" forceFullWidth>
        <WSButton.Primary
          loading={submitMeta.isLoading}
          disabled={!consent}
          onClick={submit}
        >
          Continue
        </WSButton.Primary>
      </WSButtons>
    </EditLayout>
  );
};
