import { WSElement, WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { AddressText } from "../../../../../shared/components/AddressText/AddressText";
import {
  FormDataAddress,
  FormPartialAddress
} from "../../../../../shared/components/FormPartialAddress";
import { TaxFormViewer } from "../../../types";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import { OriginalValuePreview } from "../OriginalValuePreview";

export interface AddressCorrectionFormProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  originalAddress: FormDataAddress;
  showRequestedValue?: boolean;
  correctedAddress?: FormDataAddress;
}

export const AddressCorrectionForm: React.FC<AddressCorrectionFormProps> = ({
  taxForm,
  taxFormViewer = TaxFormViewer.Payee,
  originalAddress,
  showRequestedValue,
  correctedAddress
}) => {
  return (
    <>
      <WSText.Heading5 mb="M">Address correction</WSText.Heading5>
      <WSText.ParagraphSm mb="XL" color="gray500">
        {taxFormViewer === TaxFormViewer.Payee
          ? "My address is not accurate."
          : "The recipient's address is not accurate."}
      </WSText.ParagraphSm>

      <OriginalValuePreview>
        <WSFlexBox direction="column">
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            Address
          </WSText.ParagraphSm>
          <WSText.ParagraphSm color="gray600" strike>
            <AddressText
              {...originalAddress}
              postalCode={originalAddress.zipCode}
            />
          </WSText.ParagraphSm>
        </WSFlexBox>
      </OriginalValuePreview>

      {showRequestedValue ? (
        <WSElement mb="XL">
          <CorrectedValuePreview
            taxForm={taxForm}
            taxFormViewer={taxFormViewer}
          >
            <WSFlexBox direction="column">
              <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
                Address
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="medium" color="gray600">
                <AddressText
                  {...correctedAddress}
                  postalCode={correctedAddress?.zipCode}
                />
              </WSText.ParagraphSm>
            </WSFlexBox>
          </CorrectedValuePreview>
        </WSElement>
      ) : null}

      <WSText weight="medium" mb="XS">
        {taxFormViewer === TaxFormViewer.Payee
          ? "Your business address"
          : "Recipient's business address"}
      </WSText>
      <FormPartialAddress mb="XS" name="formW9Address" />
      <WSText.ParagraphSm mb="2XL" color="gray400">
        {taxFormViewer === TaxFormViewer.Payee
          ? "Your form 1099 will be mailed to this address."
          : "The recipient's form 1099 will be mailed to this address."}
      </WSText.ParagraphSm>
    </>
  );
};
