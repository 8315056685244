import {
  useIsMobile,
  WSButton,
  WSFlexBox,
  WSFormOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { UserType } from "@wingspanhq/users/dist/lib/interfaces";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { useRequestPasswordReset } from "../../query/users/mutations";
import { useResetPassword } from "../../shared/hooks/useResetPassword";
import { validatorEmail } from "../../shared/validators/validatorEmail";
import { validatorPassword } from "../../shared/validators/validatorPassword";
import { WS_LINKS } from "../../types/wsLinks";
import { FooterNotes } from "../components/FooterNotes/FooterNotes";
import { Header } from "../components/Header/Header";
import { TextField } from "../components/TextField/TextField";
import styles from "./styles.module.scss";

type RouteProps = RouteComponentProps<{ code: string }>;

type Props = RouteProps;

export const MemberResetPassword: React.FC<Props> = ({
  history,
  location: { search }
}) => {
  const isMobile = useIsMobile();

  const [hasResetToken, setHasResetToken] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [token, setToken] = useState<string>("");

  const [resetPassword, resetPasswordMeta] =
    useRequestPasswordReset("/member/sign-in");
  const reset = useResetPassword(userId, token, "/member/sign-in");

  const loading = resetPasswordMeta.isLoading || reset.isLoading;
  const formError = resetPasswordMeta.error || reset.error;

  const checkForToken = () => {
    let { token: tokenParam, userId: userIdParam } = parse(search);
    if (typeof userIdParam === "string" && typeof tokenParam === "string") {
      setUserId(userIdParam);
      setToken(tokenParam);
      setHasResetToken(true);
    } else {
      setUserId("");
      setToken("");
      setHasResetToken(false);
    }
  };

  useEffect(checkForToken, [search]);

  return (
    <>
      {hasResetToken ? (
        <>
          <BrowserPageTitle title="Reset Password" />
          <div className={styles.base}>
            <Header onBack={history.goBack} />
            <main className={styles.main}>
              <div className={styles.headingWrapper}>
                <WSText kind={isMobile ? "Heading5" : "Heading1"}>
                  Reset password
                </WSText>
              </div>
              <div className={styles.formWrapper}>
                <WSFormOld
                  defaultValues={{ password: "" }}
                  validationSchema={Yup.object().shape({
                    password: validatorPassword.required("Password is required")
                  })}
                  onSubmit={values => {
                    reset.reset(values.password);
                  }}
                >
                  <WSFormOld.Field
                    key="password"
                    mb="XL"
                    name="password"
                    component={TextField}
                    componentProps={{
                      type: "password",
                      label: "New Password"
                    }}
                  />

                  <WSErrorMessage
                    my="XL"
                    contextKey="PasswordReset"
                    error={formError}
                    forceShowApiErrors
                  />

                  <WSFlexBox.Center direction="column">
                    <WSButton
                      type="submit"
                      loading={loading}
                      data-testid="submit"
                    >
                      Reset Password
                    </WSButton>

                    <WSButton.Link
                      mt="XL"
                      type="button"
                      onClick={() => {
                        history.push("/member/reset-password");
                      }}
                    >
                      Problems? Request another password reset.
                    </WSButton.Link>
                  </WSFlexBox.Center>
                </WSFormOld>
              </div>
            </main>
          </div>
        </>
      ) : (
        <>
          <BrowserPageTitle title="Reset password" />
          <div className={styles.base}>
            <Header onBack={history.goBack} />
            <main className={styles.main}>
              <div className={styles.headingWrapper}>
                <WSText kind={isMobile ? "Heading5" : "Heading1"}>
                  Reset password
                </WSText>
              </div>
              <div className={styles.formWrapper}>
                <WSFormOld
                  defaultValues={{
                    email: ""
                  }}
                  validationSchema={Yup.object().shape({
                    email: validatorEmail.required("Email is required")
                  })}
                  onSubmit={values => {
                    resetPassword({
                      ...values,
                      userType: UserType.Member
                    });
                  }}
                >
                  <WSFormOld.Field
                    key="email"
                    mb="M"
                    name="email"
                    component={TextField}
                    componentProps={{
                      type: "email",
                      label: "Email Address"
                    }}
                  />

                  <WSErrorMessage
                    my="XL"
                    contextKey="PasswordReset"
                    error={formError}
                    forceShowApiErrors
                  />

                  <WSFlexBox.Center direction="column">
                    <WSButton
                      type="submit"
                      loading={loading}
                      fullWidth
                      data-testid="submit"
                    >
                      Send reset instructions
                    </WSButton>

                    <WSButton.Link
                      mt="XL"
                      type="button"
                      onClick={history.goBack}
                    >
                      Back to sign in
                    </WSButton.Link>
                  </WSFlexBox.Center>
                  <FooterNotes>
                    This site is protected by reCAPTCHA and the Google{" "}
                    <a href={WS_LINKS.googlePrivacyPolicy}>Privacy Policy</a>{" "}
                    and{" "}
                    <a href={WS_LINKS.googleTermOfService}>Terms of Service</a>{" "}
                    apply.
                  </FooterNotes>
                </WSFormOld>
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};
