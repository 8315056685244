import { WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  PayablesStatusFilter,
  PayablesTable
} from "../../components/NewPayablesTable/PayablesTable";

export const PayablesPaid: React.FC = () => {
  useBrowserPageTitle("Paid Payables");

  return (
    <>
      <WSText.Heading1 mb="M">Paid</WSText.Heading1>
      <WSText color="gray600" mb="2XL">
        The full history of payments sent.
      </WSText>

      <PayablesTable
        basePath="/member/invoices/payables/paid"
        visibleFilters={{
          payee: true,
          paidAtRange: true
        }}
        defaultFilters={{
          status: [PayablesStatusFilter.Paid]
        }}
        showHeaders
        showInvoiceNumber
        showDatePaid
        emptyState={{
          firstTime: "All of your sent payables will be shown here.",
          standart: "No paid payables."
        }}
      />
    </>
  );
};
