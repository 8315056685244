import {
  useWSModal,
  WSCard,
  WSInfoBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerifications } from "../../../../query/onboarding/queries/useQueryVerifications";
import { Flow } from "../../../../shared/components/Flow";
import { LocationStateAlert } from "../../../../shared/hooks/useLocationStateAlert";
import { FormDataAccountType } from "../../components/FormAccountType";
import { FormDataBusinessInformation } from "../../components/FormBusinessInformation";
import { FormDataPersonalInformation } from "../../components/FormPersonalInformation";
import { FormDataRepresentative } from "../../components/FormRepresentative";
import { getSteps } from "./getSteps";
import { FormDataBusinessLegalName } from "./steps/StepImmaterialUpdateBusinessLegalName";
import { FormDataIndividualLegalName } from "./steps/StepImmaterialUpdateIndividualLegalName";
import { FormDataReason } from "./steps/StepReason";

export type FlowMEChangeContext = {
  reason?: FormDataReason;
  accountType?: FormDataAccountType;
  personalInformation?: FormDataPersonalInformation;
  businessInformation?: FormDataBusinessInformation;
  representative?: FormDataRepresentative;
  individualLegalName?: FormDataIndividualLegalName;
  businessLegalName?: FormDataBusinessLegalName;
  nameChanged?: boolean;
  newMEcreated?: boolean;
};

export const RouteChange: React.FC = () => {
  const queryMonitoredEntity = useQueryCustomerEntity();
  const queryVerifications = useQueryVerifications();
  const history = useHistory<LocationStateAlert>();
  const modalInfo = useWSModal(ModalAabandon, {
    size: "S",
    style: { padding: 0, borderRadius: 8 }
  });

  const initialVerificationStatus = useMemo(
    () => queryVerifications.data?.tax,
    // Check this only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const goBack = useCallback(() => {
    if (queryMonitoredEntity.data?.type === "Business") {
      history.push("/member/settings/account/business");
    } else {
      history.push("/member/settings/account/personal");
    }
  }, [history, queryMonitoredEntity.data?.type]);

  const onNext = useCallback(() => {
    if (queryVerifications.data?.tax === "Verified") {
      history.push("/member/dashboard", {
        alert: {
          theme: "success",
          icon: "check-circle",
          title: "Taxpayer information updated",
          children:
            "Your taxpayer information was successfully updated in the account settings."
        }
      });
      return;
    }

    history.push("/member/dashboard");
  }, [history, queryVerifications.data?.tax]);

  return (
    <Flow
      basePath="/member/onboarding/change"
      steps={context =>
        getSteps(
          context,
          queryMonitoredEntity.data,
          initialVerificationStatus,
          queryVerifications.data?.tax
        )
      }
      onBack={(context: FlowMEChangeContext) => {
        if (!!context.reason) {
          modalInfo.open({
            onContinue: goBack
          });
        } else {
          goBack();
        }
      }}
      onNext={onNext}
    />
  );
};

const ModalAabandon: React.FC<{
  onClose: () => void;
  onContinue: () => void;
}> = props => (
  <WSCard
    divider
    header="Exit update request"
    actions={{
      alignment: "fill",
      buttons: [
        {
          kind: "Secondary",
          label: "Cancel",
          onClick: props.onClose
        },
        {
          destructive: true,
          label: "Exit without changes",
          onClick: props.onContinue
        }
      ]
    }}
  >
    <WSText.ParagraphSm mb="L" color="gray600">
      Are you sure you want to exit update request for verified fields?
    </WSText.ParagraphSm>
    <WSInfoBox>Any unsaved changes not submitted will be lost.</WSInfoBox>
  </WSCard>
);
