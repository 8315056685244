import { endOfYear, startOfYear } from "date-fns";
import {
  IStatementsRequestQuery,
  StatementStatus
} from "../../../services/api/banking/statements/types";
import {
  StatementQuerySort,
  StatementsFilters
} from "../../../modules/Statements/routes/RouteStatements";
import { UrlQuerySort } from "../../../shared/hooks/useUrlQuery";
import { WSTableColumnSortDirection } from "@wingspanhq/fe-component-library";

export const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc"
} as const;

export type SortOrderType = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];

export const createRequest = (
  filters: StatementsFilters,
  type: string,
  sort?: UrlQuerySort<keyof StatementQuerySort>
): IStatementsRequestQuery => {
  const filter = {
    status:
      type === StatementStatus.Archived
        ? StatementStatus.Archived
        : StatementStatus.Available,

    ...(type === StatementStatus.Archived
      ? {}
      : {
          startDate: { ">=": startOfYear(new Date(Number(type), 0)) },
          endDate: { "<=": endOfYear(new Date(Number(type), 0)) }
        }),
    internalAccountType: filters.accountType
      ? {
          in: Array.isArray(filters.accountType)
            ? filters.accountType
            : [filters.accountType]
        }
      : undefined
  };

  const sortRequest = getSortRequest(sort);

  return {
    filter,
    sort: sortRequest
  };
};

export type StatementsQuerySort = {
  internalAccountType?: SortOrderType;
  startDate?: SortOrderType;
  // accountNumber?: SortOrderType;
};

const sortMap: Record<WSTableColumnSortDirection, SortOrderType | undefined> = {
  ascending: SORT_ORDER.ASC,
  descending: SORT_ORDER.DESC,
  none: undefined
};

function getSortRequest(
  urlQuerySort?: UrlQuerySort<keyof StatementQuerySort>
): StatementsQuerySort {
  if (urlQuerySort?.field === "internalAccountType") {
    return {
      internalAccountType: sortMap[urlQuerySort.direction]
    };
  }

  if (urlQuerySort?.field === "startDate") {
    return {
      startDate: sortMap[urlQuerySort.direction]
    };
  }

  // if (urlQuerySort?.field === "accountNumber") {
  //   return {
  //     accountNumber: sortMap[urlQuerySort.direction]
  //   };
  // }

  return {
    startDate: SORT_ORDER.DESC
  };
}
