import { WSDivider, WSPanel, WSText } from "@wingspanhq/fe-component-library";
import { CORRECTION_AMOUNT } from "../../constants/corrections";

import { OriginalValuePreview } from "../OriginalValuePreview";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";

export interface AmountCorrectionDiffPreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  onEdit?: () => void;
  amount: number;
  correctedAmount: number;
  comment: string;
}

export const AmountCorrectionDiffPreview: React.FC<
  AmountCorrectionDiffPreviewProps
> = ({ taxForm, taxFormViewer, amount, correctedAmount, comment, onEdit }) => {
  return (
    <WSPanel>
      <WSText.Paragraph mb="M" weight="medium">
        Amount correction
      </WSText.Paragraph>

      <OriginalValuePreview
        taxForm={taxForm}
        correctionType={CORRECTION_AMOUNT}
      />

      <WSText.ParagraphSm mb="XL" color="gray500">
        The total income reported is different than what was actually paid and
        needs to be corrected.
      </WSText.ParagraphSm>

      <WSDivider my="XL" />

      <CorrectedValuePreview
        taxForm={taxForm}
        correctionType={CORRECTION_AMOUNT}
        taxFormViewer={taxFormViewer}
        onEdit={onEdit}
        correctionData={{
          amountCorrectionData: {
            amount: correctedAmount,
            comment
          }
        }}
      />
    </WSPanel>
  );
};
