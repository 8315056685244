import { wsMoment } from "@wingspanhq/utils/dist/date/wsMoment";
import { Moment } from "moment/moment";

export const ADD_TO_CALENDAR_LINK =
  "https://calendar.google.com/calendar/u/0/r?cid=c_qp3r24vlib3i7geaolra0hqr7k@group.calendar.google.com";

const DATE_FORMAT = "MMM DD, YYYY";

export const getIRSQuarters = (year = wsMoment().year()) => {
  return [
    {
      range: [`Jan 1, ${year}`, `Mar 31, ${year}`],
      dueRange: [`Jan 15, ${year}`, `Apr 15, ${year}`],
      dueDate: `Apr 15, ${year}`,
      quarter: 1
    },
    {
      range: [`Apr 1, ${year}`, `May 31, ${year}`],
      dueRange: [`Apr 15, ${year}`, `Jun 15, ${year}`],
      dueDate: `Jun 15, ${year}`,
      quarter: 2
    },
    {
      range: [`Jun 1, ${year}`, `Aug 31, ${year}`],
      dueRange: [`Jun 15, ${year}`, `Sep 15, ${year}`],
      dueDate: `Sep 15, ${year}`,
      quarter: 3
    },
    {
      range: [`Sep 1, ${year}`, `Dec 31, ${year}`],
      dueRange: [`Sep 15, ${year}`, `Jan 15, ${year + 1}`],
      dueDate: `Jan 15, ${year + 1}`,
      quarter: 4
    }
  ] as const;
};

export const getInternalProcessingDeadline = (
  quarterDueDate: string,
  format: string
): Moment => {
  // Subtract 4 business days (excluding weekends and holidays) from the IRS due date and set the deadline to 9:00 AM EST.
  return wsMoment(quarterDueDate, format)
    .businessSubtract(4)
    .hour(9)
    .minute(0)
    .second(0);
};

// Show previous year quarters for Jan
const checkPreviousInternalYearQuarter = (): boolean => {
  const currentDate = wsMoment();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();

  if (currentMonth !== 0) return false;

  const internalProcessingDeadlineForPreviousYear =
    getInternalProcessingDeadline(
      getIRSQuarters(currentYear - 1)[3].dueDate,
      DATE_FORMAT
    );

  return currentDate.isBefore(internalProcessingDeadlineForPreviousYear);
};

export const getCurrentIRSYear = () => {
  const currentDate = wsMoment();
  const currentYear = currentDate.year();

  if (
    currentDate.month() === 0 &&
    currentDate.isBefore(
      wsMoment(getIRSQuarters(currentYear - 1)[3].dueDate, DATE_FORMAT)
    )
  ) {
    // Show previous year quarters for Jan
    return currentYear - 1;
  }

  return currentYear;
};

// The difference between IRS and InternalProcessingDeadline is 4 business days.
export const getInternalProcessingDeadlineYear = () => {
  const currentDate = wsMoment();
  const currentYear = currentDate.year();
  const isPreviousYearQuarter = checkPreviousInternalYearQuarter();

  if (isPreviousYearQuarter) {
    // Show previous year quarters for Jan
    return currentYear - 1;
  }

  return currentYear;
};

export const CURRENT_TAX_DOCUMENTS_YEAR = getCurrentIRSYear();

export const getCurrentIRSQuarter = () => {
  const currentDate = wsMoment();
  const currentYear = currentDate.year();
  const isPreviousYearQuarter = checkPreviousInternalYearQuarter();

  if (isPreviousYearQuarter) {
    // Show previous year quarters for Jan
    return getIRSQuarters(currentYear - 1)[3];
  }

  const quarters = getIRSQuarters(currentYear);

  return (
    quarters.find(quarter => {
      if (
        currentDate.isAfter(
          getInternalProcessingDeadline(quarter.dueRange[0], DATE_FORMAT)
        ) &&
        currentDate.isSameOrBefore(
          getInternalProcessingDeadline(quarter.dueRange[1], DATE_FORMAT)
        )
      ) {
        return true;
      }
      return false;
    }) || quarters[0]
  );
};
