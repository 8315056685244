import { useLocation } from "react-router-dom";
import { TAX_FILING_YEAR_OPTIONS } from "../components/SelectYear";

export const useCurrentTaxFilingYear = () => {
  const { pathname } = useLocation();

  const currentOption = TAX_FILING_YEAR_OPTIONS.find(p =>
    pathname.startsWith(p.path)
  );

  const currentYear = currentOption?.value ? Number(currentOption.value) : 2024;

  return currentYear as 2023 | 2024;
};
