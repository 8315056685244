import {
  useWSModal,
  WSActions,
  WSControl,
  WSDivider,
  WSForm,
  WSInputTextarea,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useTaxFormRejectCorrection } from "../../../../query/taxForm/mutations/useTaxFormRejectCorrection";
import { ErrorContextKey } from "../../../../services/platform";
import { useGoToTaxFormDetails } from "../../../TaxFiling/paths";

const PayerRejectReason: React.FC<{
  taxFormId: string;
  submissionId: string;
  pendingCorrectionId: string;
  onClose(): void;
}> = ({ onClose, taxFormId, submissionId, pendingCorrectionId }) => {
  const goToTaxFormDetails = useGoToTaxFormDetails();
  const [rejectCorrection, rejectCorrectionMeta] =
    useTaxFormRejectCorrection(taxFormId);

  return (
    <WSList gap="XL">
      <WSText.Paragraph weight="medium">
        Please describe your reason
      </WSText.Paragraph>
      <WSForm
        defaultValues={{
          includeInternalNote: false,
          details: "",
          teamMemberDetails: ""
        }}
        validationSchema={Yup.object().shape({
          includeInternalNote: Yup.boolean(),
          details: Yup.string().required("Required"),
          teamMemberDetails: Yup.string().when("includeInternalNote", {
            is: true,
            then: Yup.string().required("Required")
          })
        })}
        onSubmit={values => {
          rejectCorrection(
            {
              correctionId: pendingCorrectionId,
              requestData: {
                payerOwnedData: {
                  comment: values.details ?? "",
                  commentInternal: values.teamMemberDetails ?? ""
                }
              }
            },
            {
              onSuccess: response => {
                goToTaxFormDetails(taxFormId, submissionId);
              }
            }
          );
        }}
      >
        <WSList gap="XL">
          <WSForm.Field
            name="details"
            component={WSInputTextarea}
            componentProps={{
              placeholder:
                "Provide details about your reason for rejecting the request for correction."
            }}
          />
          <WSForm.Field
            name="includeInternalNote"
            component={WSControl}
            componentProps={{
              size: "S",
              type: "checkbox",
              label: "Include an internal note for your team members"
            }}
          />

          <WSForm.Value name="includeInternalNote">
            {includeInternalNote => (
              <WSForm.Field
                mb="XL"
                name="teamMemberDetails"
                hidden={!includeInternalNote}
                component={WSInputTextarea}
                componentProps={{
                  placeholder:
                    "Provide details about your reason for rejecting the request for correction for your team members."
                }}
              />
            )}
          </WSForm.Value>

          <WSErrorMessage
            error={rejectCorrectionMeta.error}
            contextKey={ErrorContextKey.NEC1099PayerRejectCorrection}
          />

          <WSText.ParagraphXs color="gray500">
            The recipient will be notified about the rejection for correction
            and the reason you provided.
          </WSText.ParagraphXs>

          <WSDivider />

          <WSForm.Values
            names={["includeInternalNote", "details", "teamMemberDetails"]}
          >
            {({ includeInternalNote, details, teamMemberDetails }) => {
              const isValid = includeInternalNote
                ? !!details && !!teamMemberDetails
                : !!details;

              return (
                <WSActions
                  alignment="fill"
                  buttons={[
                    {
                      label: "Reject correction",
                      kind: "Primary",
                      type: "submit",
                      disabled: !isValid,
                      loading: rejectCorrectionMeta.isLoading
                    },
                    {
                      label: "Cancel",
                      kind: "Tertiary",
                      type: "button",
                      onClick: onClose
                    }
                  ]}
                />
              );
            }}
          </WSForm.Values>
        </WSList>
      </WSForm>
    </WSList>
  );
};

export const useModalPayerRejectReason = () =>
  useWSModal(PayerRejectReason, {
    title: "Reason for rejection",
    size: "S"
  });
