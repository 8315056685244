import {
  WSElement,
  WSEmptyState,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";

export const UnavailableOrgWidget: React.FC<{ title: string }> = ({
  title
}) => (
  <WSElement>
    <WSSectionToolbar title={title} />
    <WSPanel py="XL">
      <WSEmptyState
        title=""
        fullWidth
        type="tasks"
        size="S"
        orientation="horizontal"
        theme="neutral"
        description="This widget is only available to manage from the sub-organization account the contractor belongs to. "
      />
    </WSPanel>
  </WSElement>
);
