import React from "react";

import { WSText } from "@wingspanhq/fe-component-library";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";

import { OriginalValuePreview } from "../OriginalValuePreview";

interface Props {
  taxForm: IPayeeTaxFormResponse | IPayerTaxFormResponse;
  correctionType: string;
  description: string;
}

export const CorrectionTypeOptionOriginalValue: React.FC<Props> = ({
  correctionType,
  taxForm,
  description
}) => {
  return (
    <>
      <OriginalValuePreview
        correctionType={correctionType}
        hidePill
        taxForm={taxForm}
        mb="XL"
      />
      <WSText.ParagraphSm color="gray500" weight="medium">
        {description}
      </WSText.ParagraphSm>
    </>
  );
};
