import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { FormStateFiling } from "../../../components/FormStateFiling";
import { EditLayout } from "../../../../TaxCorrections/components/EditLayout";

export const RouteEditStateFiling: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <EditLayout title="State filing" onBack={onBack}>
      <FormStateFiling onNext={onNext} onBack={onBack} isEdit={true} />
    </EditLayout>
  );
};
