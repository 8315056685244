import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Layout } from "../../../../../shared/components/Layout";
import { FormStateFiling } from "../../../components/FormStateFiling";

export const RouteEditStateFiling: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <Layout title="1099-NEC filing">
      <FormStateFiling onNext={onNext} onBack={onBack} />
    </Layout>
  );
};
