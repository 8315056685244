import {
  toWSDateString,
  toWSMoneyString,
  useModalContext,
  WSElement,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  InvoiceStatus,
  MemberWorkFlowStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useWSQuery } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../query/payments/queries";
import {
  getMemberAcceptedPayables,
  getPayables,
  getPayablesTotalAmount
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { paymentsService } from "../../../services/payments";
import { getAllEntries } from "../../../utils/serviceHelper";
import { BulkUnapproveModal } from "../../components/NewPayablesTable/BulkUnapproveModal";
import {
  PayablesStatusFilter,
  PayablesTable,
  usePayablesQuery
} from "../../components/NewPayablesTable/PayablesTable";
import { PayableActionBlock } from "../../components/PayableActionBlock/PayableActionBlock";
import { PayablesSummary } from "../../components/PayablesSummary/PayablesSummary";
import { Summary } from "../../components/Summary/Summary";
import { DateRangeFilter } from "../../utils";
import { getNextPayrollCutOffDate } from "../../utils/payables";
import { usePayablesToApproveQuery } from "./PayablesToApprove";
import { PAYROLL_SETTINGS_MODAL } from "./PayrollSettingsModal";

export const PayablesDashboard: React.FC = () => {
  useBrowserPageTitle("Payables Dashboard");
  const userId = useUserId();
  const history = useHistory();

  const scheduledPayablesQuery = usePayablesQuery({
    status: [PayablesStatusFilter.Scheduled]
  });

  const toApprovePayablesQuery = usePayablesToApproveQuery();

  const disputedPayablesQuery = usePayablesQuery(
    {
      status: [PayablesStatusFilter.ToApprove, PayablesStatusFilter.Scheduled],
      custom: {
        "member.workflowStatus": MemberWorkFlowStatus.Disputed
      }
    },
    {
      staleTime: Infinity,
      refetchOnMount: false
    }
  );

  const qPayrollSettings = usePayrollSettings(userId, {
    retry: false
  });

  const { openModal } = useModalContext();

  const openPayablesQuery = useWSQuery(
    "OPEN_PAYABLES",
    async () => {
      const payablesToApprove = getPayables(
        await getAllEntries(
          async (...args) => {
            return (await paymentsService.payable.list(...(args as any))).data;
          },
          {
            status: InvoiceStatus.Open,
            "labels.invoiceType": {
              "!=": "approvedInvoicesPayment"
            },
            "labels.creationSource": {
              "!=": "personalPaylink"
            }
          }
        )
      );

      return {
        summary: {
          listSize: payablesToApprove.length,
          totalValue: getPayablesTotalAmount(payablesToApprove)
        },
        data: payablesToApprove
      };
    },
    {
      staleTime: Infinity,
      refetchOnMount: false
    }
  );

  const overduePayablesQuery = useWSQuery(
    "OVERDUE_PAYABLES",
    async () => {
      const payablesToApprove = getPayables(
        await getAllEntries(
          async (...args) => {
            return (await paymentsService.payable.list(...(args as any))).data;
          },
          {
            status: InvoiceStatus.Overdue,
            "labels.invoiceType": {
              "!=": "approvedInvoicesPayment"
            },
            "labels.creationSource": {
              "!=": "personalPaylink"
            }
          }
        )
      );

      return {
        summary: {
          listSize: payablesToApprove.length,
          totalValue: getPayablesTotalAmount(payablesToApprove)
        },
        data: payablesToApprove
      };
    },
    {
      staleTime: Infinity,
      refetchOnMount: false
    }
  );

  return (
    <>
      <WSElement mb="2XL">
        <WSElement mb="2XL">
          <WSGrid>
            <WSGrid.Item span={{ xs: "6", s: "4", m: "3" }}>
              <Summary
                label="Open"
                color="amber300"
                value={toWSMoneyString(
                  openPayablesQuery.data?.summary.totalValue
                )}
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ xs: "6", s: "4", m: "3" }}>
              <Summary
                label="Overdue"
                color="red300"
                value={toWSMoneyString(
                  overduePayablesQuery.data?.summary.totalValue
                )}
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ xs: "8", s: "4", m: "3" }}>
              <PayablesSummary
                label="Paid (last 30 days)"
                filter={{
                  status: [PayablesStatusFilter.Paid],
                  paidAtRange: DateRangeFilter.PastMonth
                }}
                color="green300"
                displayAmount
              />
            </WSGrid.Item>
          </WSGrid>
        </WSElement>

        <WSQueries
          queries={{
            scheduledPayablesQuery,
            toApprovePayablesQuery,
            disputedPayablesQuery,
            qPayrollSettings
          }}
          renderErrors={() => null}
        >
          {({
            scheduledPayablesQuery: {
              data: { summary: scheduledPayablesSummary }
            },
            toApprovePayablesQuery: {
              data: {
                data: payablesToApprove,
                summary: toApprovePayablesSummary
              }
            },
            disputedPayablesQuery: {
              data: { summary: disputedPayablesSummary }
            },
            qPayrollSettings: { data: payrollSettings }
          }) => (
            <>
              <WSElement mb="3XL">
                {scheduledPayablesSummary.listSize > 0 && (
                  <PayableActionBlock
                    mb="M"
                    icon="calendar"
                    onClick={() => openModal(PAYROLL_SETTINGS_MODAL)}
                    contentButton={{
                      children: "Manage payroll settings"
                    }}
                  >
                    <WSText.ParagraphSm>
                      <WSText.ParagraphSm as="b" inline>
                        Next payroll is{" "}
                        {toWSDateString(
                          getNextPayrollCutOffDate(payrollSettings),
                          "monthDate"
                        )}
                      </WSText.ParagraphSm>
                      . {scheduledPayablesSummary.listSize} payable
                      {scheduledPayablesSummary.listSize > 1 ? "s" : ""} with a
                      total of{" "}
                      {toWSMoneyString(scheduledPayablesSummary.totalValue)}{" "}
                      across future payrolls.
                    </WSText.ParagraphSm>
                  </PayableActionBlock>
                )}

                {toApprovePayablesSummary.listSize > 0 && (
                  <PayableActionBlock
                    mb="M"
                    icon="dollar-circle"
                    contentButton={{
                      children: "Review & bulk approve"
                    }}
                    onClick={() => {
                      history.push("/member/invoices/payables/to-approve");
                    }}
                  >
                    <WSText.ParagraphSm color="gray500" mb="XS">
                      <WSText.ParagraphSm as="b" inline color="gray700">
                        Payments to approve!
                      </WSText.ParagraphSm>{" "}
                      {toApprovePayablesSummary.listSize} payable
                      {toApprovePayablesSummary.listSize > 1 ? "s" : ""}{" "}
                      totaling{" "}
                      {toWSMoneyString(toApprovePayablesSummary.totalValue)}.
                    </WSText.ParagraphSm>

                    <WSText.ParagraphSm color="gray500">
                      {getMemberAcceptedPayables(payablesToApprove).length} have
                      been accepted
                    </WSText.ParagraphSm>
                  </PayableActionBlock>
                )}

                {disputedPayablesSummary.listSize > 0 && (
                  <PayableActionBlock
                    onClick={() => {
                      history.push("/member/invoices/payables/disputed");
                    }}
                    icon="alert-circle"
                    color="amber400"
                    colorBackground="amber50"
                    contentButton={{
                      children: "Manage disputed payables"
                    }}
                  >
                    <WSText.ParagraphSm color="gray500">
                      <WSText.ParagraphSm as="b" color="gray700">
                        You have {disputedPayablesSummary.listSize} disputed
                        payable
                        {disputedPayablesSummary.listSize > 1 ? "s" : ""} that
                        need your attention.
                      </WSText.ParagraphSm>
                    </WSText.ParagraphSm>
                  </PayableActionBlock>
                )}
              </WSElement>
            </>
          )}
        </WSQueries>
      </WSElement>

      <BulkUnapproveModal />

      <PayablesTable
        showHeaders
        title="Recent payable activity"
        basePath="/member/invoices/payables/dashboard"
        pagination={{ limit: 5, disableInfiniteScroll: true }}
        showLatestUpdate
        emptyState={{
          firstTime: "New and recently changed payables will display here.",
          standart:
            "Nothing to see here! New and recently changed payables display here."
        }}
      />
    </>
  );
};
