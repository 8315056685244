import { IRedactedTaxFormW9Info } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { formatEIN } from "../../../shared/utils/formatEIN";

export const selectorTINVerificationStatusDataView = (
  tinType?: "Individual" | "Business",
  w9Info?: IRedactedTaxFormW9Info
): string | undefined => {
  return tinType === "Business"
    ? w9Info?.ein
      ? `${formatEIN(w9Info.ein)} (EIN)`
      : w9Info?.einLastFour
      ? `••• ${w9Info.einLastFour} (EIN)`
      : undefined
    : tinType === "Individual"
    ? w9Info?.ssnLastFour
      ? `••• ${w9Info?.ssnLastFour} (SSN)`
      : undefined
    : undefined;
};
