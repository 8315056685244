import {
  toWSMoneyString,
  useWSModal,
  WSButton,
  WSDivider,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import {
  useBankingAccount,
  useBankingBalance
} from "../../../query/bookkeeping/queries";
import { ModalAccountDetails } from "./ModalAccountDetails";

export const AvailableBalance: React.FC = () => {
  const queryBankingAccount = useBankingAccount();
  const queryBankingBalance = useBankingBalance();

  const history = useHistory();
  const modalAccountDetails = useWSModal(ModalAccountDetails, {
    size: "S"
  });
  const availableBalance = queryBankingBalance.data?.available || 0;
  const currentBalance = queryBankingBalance.data?.balance || 0;
  const pendingAmounts = queryBankingBalance.data?.hold || 0;

  return (
    <WSPanel>
      <WSFlexBox.CenterY justify="space-between" mb="XL">
        <WSText weight="medium" color="gray600">
          Available balance
        </WSText>

        {queryBankingAccount.data?.numbers?.account ? (
          <WSButton.Link
            size="M"
            name="viewRoutingNumber"
            onClick={() => modalAccountDetails.open()}
          >
            {`(...${queryBankingAccount.data.numbers.account.slice(
              queryBankingAccount.data.numbers.account.length - 4
            )})→`}
          </WSButton.Link>
        ) : null}
      </WSFlexBox.CenterY>

      <WSText.Heading1 weight="book" color="gray500" mb="XL">
        {toWSMoneyString(availableBalance)}
      </WSText.Heading1>

      <WSFlexBox.CenterY justify="space-between" mb="M">
        <WSText.ParagraphSm color="gray500">Current balance</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray600">
          {toWSMoneyString(currentBalance)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="gray500">Pending amounts</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500">
          {toWSMoneyString(pendingAmounts)}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSDivider type="expand" my="XL" />

      <WSButton.Secondary
        size="M"
        fullWidth
        onClick={() => {
          history.push("/member/transfer?balance=wallet");
        }}
      >
        Transfer
      </WSButton.Secondary>
    </WSPanel>
  );
};
