import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { useRouteMatch } from "react-router-dom";

import { EditLayout } from "../../../TaxCorrections/components/EditLayout";

import { SelectCorrectionTypeForm } from "../../../TaxCorrections/components/SelectCorrectionTypeForm";

import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import { useCurrentTaxDocumentsYear } from "../../utils/useCurrentTaxDocumentsYear";
import { useGoToTaxDocumentByIds } from "../../paths";
import { openIntercom } from "../../../../shared/utils/intercom";
import { SetupRequestCorrectionFlowProps } from "./RequestCorrectionFlow";
import { CorrectionFlowHeader } from "../../../TaxCorrections/components/CorrectionFlowHeader";

interface SelectCorrectionTypeFormData {
  corrections: string[];
}

export const RouteSelectCorrectionType: React.FC<
  SetupRequestCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const currentYear = useCurrentTaxDocumentsYear();
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;
  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const goToTaxDocument = useGoToTaxDocumentByIds();

  const onFormSubmit = (data: SelectCorrectionTypeFormData) => {
    onNext(data.corrections);
  };

  return (
    <EditLayout title="Request correction" onBack={onBack}>
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const onCancel = () => {
            goToTaxDocument(taxFormId, submissionId);
          };
          return (
            <>
              <CorrectionFlowHeader
                year={currentYear}
                name={selectorPayeeTaxFormPayerName(taxForm)}
              />

              <SelectCorrectionTypeForm
                taxForm={taxForm}
                onCancel={onCancel}
                onContinue={onFormSubmit}
              />

              <WSText.ParagraphSm color="gray500" weight="book" inline>
                Have another issue with the 1099?{" "}
                <WSButton.Link
                  size="S"
                  rightIcon="chevron-right"
                  onClick={openIntercom}
                >
                  Contact support
                </WSButton.Link>
              </WSText.ParagraphSm>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
