import { Redirect, Route, Switch } from "react-router-dom";
import { RouteDetails } from "./routes/RouteDetails";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteRequestCorrection } from "./routes/RequestCorrectionFlow/RouteRequestCorrection";
import {
  TAX_DOCUMENTS_DETAILS_PATH,
  TAX_DOCUMENTS_PATH,
  TAX_DOCUMENTS_REQUEST_CORRECTION_PATH,
  TAX_DOCUMENTS_ROOT_PATH
} from "./paths";
import { OLD_TAX_DOCUMENTS_PATH } from "../TaxDocumentsOld/paths";
import { OldTaxDocuments } from "../TaxDocumentsOld";

export const NewTaxDocuments: React.FC<{}> = () => {
  return (
    <Switch>
      <Route
        path={TAX_DOCUMENTS_REQUEST_CORRECTION_PATH}
        component={RouteRequestCorrection}
      />
      <Route path={TAX_DOCUMENTS_DETAILS_PATH} component={RouteDetails} />
      <Route path="/" component={RouteIndex} />

      <Redirect from="*" to="/" />
    </Switch>
  );
};

export const TaxDocuments: React.FC<{}> = () => {
  return (
    <Switch>
      <Route path={OLD_TAX_DOCUMENTS_PATH} component={OldTaxDocuments} />
      <Route path={TAX_DOCUMENTS_PATH} component={NewTaxDocuments} />

      <Redirect from={TAX_DOCUMENTS_ROOT_PATH} to={TAX_DOCUMENTS_PATH} />
      <Redirect from="*" to={TAX_DOCUMENTS_PATH} />
    </Switch>
  );
};
