import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import isEmpty from "lodash/isEmpty";
import { IPayeeRow } from "../../../services/search";
import { cleanCSVData } from "../../../shared/utils/csv";

export type DeductionTableItem = {
  deduction: IDeductionResponse;
  collaborator?: ICollaboratorSchema;
  payeeRow?: IPayeeRow;
};
const mapDeductionTableItemData = (tableItem: DeductionTableItem) => {
  const { deduction, payeeRow } = tableItem;

  if (!deduction) {
    return {};
  }

  const names =
    payeeRow && payeeRow.user
      ? wsName({
          user: payeeRow.user,
          member: payeeRow.member,
          payerOwnedData: payeeRow.payerOwnedData
        })
      : undefined;

  const payeeRowCompanyName =
    payeeRow?.payerOwnedData.payeeW9Data?.legalBusinessName ||
    payeeRow?.payeeOwnedData.payeeW9Data?.legalBusinessName ||
    payeeRow?.member.profile?.company?.legalBusinessName ||
    payeeRow?.member.profile?.company?.doingBusinessAs;

  return {
    "Created Date": deduction.createdAt,
    "Start Date": deduction.startDate,
    "External Contractor ID": payeeRow?.payerOwnedData.payeeExternalId,
    "Contractor Name": names?.getResolvedName(),
    "Contractor First Name": payeeRow?.user.profile?.firstName,
    "Contractor Last Name": payeeRow?.user.profile?.lastName,
    "Contractor Preferred Name": payeeRow?.user.profile?.preferredName,
    "Contractor Company Name": payeeRowCompanyName,
    "Contractor Email": payeeRow?.user.email,
    "Deduction Status": deduction.status,
    "Deduction Type": deduction.type,
    "Deducted Payable IDs": deduction.application
      .map(a => a.payableId)
      .join(", "),
    "Deduction ID": deduction.deductionId,
    "Deduction Template ID": deduction.deductionTemplateId,
    Amount: deduction.amount,
    "Amount Deducted": deduction.application
      .map(a => a.amountDeducted)
      .reduce((a, b) => a + b, 0),
    Currency: deduction.currency,
    Priority: deduction.priority,
    "Deduction Name": deduction.name
  };
};
export const mapDeductionsTableToCSV = (data: Array<DeductionTableItem>) => {
  let cleanResult = [];

  const dirtyResult = data
    .map(d => {
      try {
        return mapDeductionTableItemData(d);
      } catch (error) {
        console.error(error);
      }
      return {};
    })
    .filter(d => !isEmpty(d));

  cleanResult = dirtyResult.map(d =>
    cleanCSVData(d, dirtyResult)
  ) as Array<any>;

  return cleanResult;
};
