import { IMember } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorIsDomesticMember = (member: IMember) =>
  isCountryUS(member.profile.address?.country);

export function isCountryUS(country?: string) {
  if (!country) return true;

  return country === "US";
}
