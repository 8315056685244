import {
  toWSDateString,
  toWSMoneyString,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import {
  InvoiceStatus,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import { calculateLineItemsTotal } from "../../utils";
import { getPayableLatestUpdate } from "./getPayableLatestUpdate";
import { PayablesFilters, PayablesTableItemData } from "./PayablesTable";
import { PayableNameCell } from "./newColumns";

export const getColumns = (props: {
  showInvoiceNumber?: boolean;
  showDateRequested?: boolean;
  showDueDate?: boolean;
  showDatePaid?: boolean;
  showDateExpectedPay?: boolean;
  showLatestUpdate?: boolean;
  payrollSettings?: IPayrollSettings;
  sendPaymentsPaidAtLabel?: string;
  filters: PayablesFilters;
  setFilters: (newFilters: PayablesFilters) => void;
  currencyCode?: string;
}): WSTableColumn<PayablesTableItemData>[] => [
  {
    config: {
      gridTemplateSizeMax: "2fr",
      header: {
        text: "Name"
      }
    },
    renderFunction: ({ data: { payable } }) => {
      return <PayableNameCell payable={payable} />;
    }
  },

  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showInvoiceNumber
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Invoice number"
      }
    },
    renderFunction: ({ data: { payable } }) => (
      <WSTableCell text={payable?.invoiceNumber} />
    )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showInvoiceNumber
        ? ["XS", "S", "M", "L"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Description"
      }
    },
    renderFunction: ({ data: { payable } }) => {
      if (!payable?.lineItems[0]?.description) {
        return null;
      }

      return <WSTableCell text={payable.lineItems[0].description} />;
    }
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDateRequested
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Opened date"
      }
    },
    renderFunction: ({ data: { payable } }) =>
      payable?.status === InvoiceStatus.Pending ? (
        <WSTableCell text="Eligibility pending" />
      ) : (
        <WSTableCell
          text={toWSDateString(payable?.events.openedAt, "monthDayYear")}
        />
      )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDueDate
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Due date"
      }
    },
    renderFunction: ({ data: { payable } }) => (
      <WSTableCell
        text={toWSDateString(
          payable?.client.payDate || payable?.dueDate,
          "monthDayYear"
        )}
        secondaryText={payable?.client.payDate ? "Updated" : ""}
      />
    )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDatePaid
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: props.sendPaymentsPaidAtLabel
      },
      sortDirection:
        props.filters.sort?.["events.paidAt"] === "asc"
          ? "ascending"
          : "descending",
      onColumnSort(direction) {
        return props.setFilters({
          sort: {
            "events.paidAt": direction === "ascending" ? "asc" : "desc"
          }
        });
      }
    },
    renderFunction: ({ data: { payable } }) => (
      <WSTableCell
        text={toWSDateString(payable?.events.paidAt, "monthDayYear")}
      />
    )
  },
  {
    config: {
      gridTemplateSizeMax: "1fr",
      hideOnScreens: props.showDateExpectedPay
        ? ["XS", "S"]
        : ["XS", "S", "M", "L", "XL"],
      header: {
        text: "Expected pay date"
      }
    },
    renderFunction: ({ data: { payrollRun } }) => (
      <WSTableCell text={toWSDateString(payrollRun?.date, "monthDayYear")} />
    )
  },
  {
    config: {
      gridTemplateSizeMin: "min-content",
      gridTemplateSizeMax: "1fr",
      header: {
        text: `Amount ${props.currencyCode ? `(${props.currencyCode})` : ""}`
      }
    },
    renderFunction: ({ data: { payable } }) => {
      if (!payable) {
        return null;
      }

      return (
        <WSTableCell
          text={toWSMoneyString(
            calculateLineItemsTotal(payable.lineItems) +
              (payable.chargedFees?.lateFee?.amount || 0),
            "default",
            payable.currency
          )}
          secondaryText={
            props.showLatestUpdate && props.payrollSettings
              ? getPayableLatestUpdate(payable, props.payrollSettings)
              : undefined
          }
        />
      );
    }
  }
];
