import { servicePayments } from "../servicePayments";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const getPayeeEngagement = async (
  payeeId: string,
  engagementId: string,
  orgPayerId: string | undefined
): Promise<IPayeeEngagementResponse> => {
  const { data } = await servicePayments.get(
    `/payee/${payeeId}/engagement/${engagementId}`,
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );

  return data;
};
