import {
  WSAlert,
  WSBreadcrumbsItem,
  WSButton,
  WSGrid,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPill,
  WSTabs
} from "@wingspanhq/fe-component-library";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { Payments } from "./Payments";
import { Summary } from "../../components/Summary";
import React from "react";
import { useGoToTaxDocumentsDashboard } from "../../paths";
import { selectorPayeeTaxFormCanRequestCorrection } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormCanRequestCorrection";
import { useRequestCorrectionWarningModal } from "../RequestCorrectionFlow/RequestCorrectionWarningModal/useRequestCorrectionWarningModal";
import { TaxDocumentsDetailsFAQ } from "../../components/TaxDocumentsDetailsFAQ";
import { usePayeeTaxFormSubmission } from "../../../../query/taxFormSubmissions/queries/usePayeeTaxFormSubmission";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { useTaxFormDownloadDocument } from "../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { downloadFileFromBlob } from "../../../../utils/files";
import { MainTaxDocumentInfo } from "../../components/MainTaxDocumentInfo";
import { getFormFileName } from "../../../TaxFiling/utils/formFileName";

export const RouteDetails: React.FC<
  RouteComponentProps<{
    taxFormId: string;
    submissionId: string;
  }>
> = ({
  match: {
    params: { taxFormId, submissionId }
  }
}) => {
  const history = useHistory();
  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const queryTaxFormSubmission = usePayeeTaxFormSubmission(
    taxFormId,
    submissionId
  );
  const goToDashboard = useGoToTaxDocumentsDashboard();
  const requestCorrectionWarningModal = useRequestCorrectionWarningModal();
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();

  return (
    <WSPage>
      <WSQueries queries={{ queryTaxForm, queryTaxFormSubmission }}>
        {({ queryTaxFormData, queryTaxFormSubmissionData }) => {
          const submission = queryTaxFormSubmissionData;
          const payerName = submission.payerData.name;

          const { clientId, year = 2024 } = queryTaxFormSubmissionData;
          const isCurrentSubmission =
            queryTaxFormData.currentSubmissionId === submissionId;

          const isCorrectionAvailable =
            selectorPayeeTaxFormCanRequestCorrection(queryTaxFormData);

          const isRequestCorrectionButtonVisible =
            isCorrectionAvailable && isCurrentSubmission;
          const isRequestCorrectionAlertVisible =
            !isRequestCorrectionButtonVisible;

          const breadcrumbs: WSBreadcrumbsItem[] = [
            {
              label: "Tax Documents",
              onClick: goToDashboard
            },
            {
              label: payerName
            }
          ];

          return (
            <>
              <WSPageToolbar
                breadcrumbs={breadcrumbs}
                title={payerName}
                titleAfter={
                  <WSList.Horizontal gap="M">
                    <WSPill text="2024 Form 1099-NEC" theme="neutral" />
                    <WSButton.Link
                      size="S"
                      onClick={() => {
                        history.push(`/member/payers/${clientId}`);
                      }}
                    >
                      View client
                    </WSButton.Link>
                  </WSList.Horizontal>
                }
                actions={[
                  {
                    label: "Request correction",
                    buttonKind: "Tertiary",
                    visible: isRequestCorrectionButtonVisible,
                    onClick() {
                      requestCorrectionWarningModal.open({
                        taxFormId,
                        submissionId
                      });
                    }
                  },
                  {
                    label: "Download",
                    buttonKind: "Secondary",
                    loading: downloadDocumentMeta.isLoading,
                    onClick: async () => {
                      await downloadDocument(
                        {
                          taxFormId,
                          submissionId,
                          viewer: TaxFormViewer.payee
                        },
                        {
                          onSuccess(pdf) {
                            const formPayeeFileName = getFormFileName({
                              viewer: TaxFormViewer.payee,
                              year: submission.year || 2024,
                              payerName:
                                submission.payerData.disregardedEntityName ??
                                submission.payerData.name,
                              payeeW9: submission.data?.w9Info
                            });

                            downloadFileFromBlob(pdf, formPayeeFileName);
                          },
                          async onError(err) {
                            const data = err.response?.data as any as Blob;
                            if (data) {
                              const rawError = JSON.parse(await data.text());
                              console.log(rawError);
                            }
                          }
                        }
                      );
                    }
                  }
                ]}
              />
              {isRequestCorrectionAlertVisible && (
                <WSAlert
                  theme="info"
                  icon="info-circle"
                  title="Correction request limit reached"
                  mb="XL"
                >
                  A correction has already been requested for this form. Only
                  one (1) correction request can be made through Wingspan to
                  your payer for a tax year. If there are additional errors to
                  correct further, please reach out to the payer directly to
                  resolve the issue.
                </WSAlert>
              )}
              <WSGrid gutter="2XL">
                <WSGrid.Item span={{ s: "12", m: "8" }}>
                  <WSList gap="2XL">
                    <MainTaxDocumentInfo submission={submission} />
                    <WSTabs
                      tabs={[
                        {
                          label: "Summary",
                          render: () => <Summary submission={submission} />
                        },
                        {
                          label: "Payments",
                          render: () => (
                            <Payments
                              clientId={clientId}
                              year={year}
                              payerName={payerName}
                            />
                          )
                        }
                      ]}
                    />
                  </WSList>
                </WSGrid.Item>
                <WSGrid.Item span={{ s: "12", m: "4" }}>
                  <WSList gap="2XL">
                    <TaxDocumentsDetailsFAQ />
                  </WSList>
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
