const currencyFormatterCache: Record<string, Intl.NumberFormat> = {};

const createCurrencyFormatter = (currencyCode?: string) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode || "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

const getCurrencyFormatter = (
  currencyCode: string = "USD"
): Intl.NumberFormat => {
  if (!currencyFormatterCache[currencyCode]) {
    currencyFormatterCache[currencyCode] = createCurrencyFormatter(
      currencyCode
    );
  }
  return currencyFormatterCache[currencyCode];
};

export const WSMoneyFormats = ["default", "bookkeeping", "taxes"] as const;
export type WSMoneyFormatsType = typeof WSMoneyFormats[number];

export type WSMoneyObject = {
  toString: () => string;
  formattedValue: string;
  value: number;
};

export const toWSMoney = (
  value: any,
  type: WSMoneyFormatsType = "default",
  currencyCode?: string
): WSMoneyObject => {
  try {
    // Normalize -0 to 0 (removes the negative sign from -0),
    // but leaves other negative numbers (e.g., -1 remains -1) unchanged
    value = Number(value.toString());

    if (Number.isNaN(value)) {
      throw new Error("Formatter toWSMoney handled invalid value");
    }
  } catch {
    value = 0.0;
  }

  let formattedValue = getCurrencyFormatter(currencyCode || "USD").format(
    value
  );
  if (value > 0 && type !== "default") {
    formattedValue = `+${formattedValue}`;
  }

  return {
    toString: () => formattedValue,
    formattedValue,
    value
  };
};

export const toWSMoneyString = (
  value: any,
  type: WSMoneyFormatsType = "default",
  currencyCode?: string
): string => toWSMoney(value, type, currencyCode).formattedValue;
