import * as Yup from "yup";
import { EntityType } from "../../modules/Onboarding/types";
import { IS_PRODUCTION_ENV } from "../constants/environment";
import {
  INTERNATIONAL_TAX_ID_VALIDATIONS,
  TEST_TAX_IDS
} from "../constants/internationalTaxId";
import { createGenericValidation } from "../utils/internationalTaxId";

export function validateInternationalTaxIdentifier(
  country: string,
  type: EntityType,
  taxId: string
): { isValid: boolean; errorMessage?: string } {
  // Skip validation in non-production environments and test values
  if (!IS_PRODUCTION_ENV) {
    if (TEST_TAX_IDS.includes(taxId)) {
      return { isValid: true };
    }
  }

  const countryValidations =
    INTERNATIONAL_TAX_ID_VALIDATIONS[country] ||
    createGenericValidation(country);
  const validation = countryValidations[type];

  if (validation?.regex.test(taxId)) {
    return { isValid: true };
  } else {
    return { isValid: false, errorMessage: validation?.errorMessage };
  }
}

export const validatorInternationalTaxId = (
  fieldName: string,
  type: EntityType,
  meCountry?: string
) =>
  Yup.string().test(fieldName, "Not valid", function (formTaxIdValue, context) {
    // This validator should not check for value presence
    if (!formTaxIdValue) {
      return true;
    }

    const _country = meCountry || context.parent?.country;
    const taxIdValidation = validateInternationalTaxIdentifier(
      _country,
      type,
      formTaxIdValue
    );
    if (!taxIdValidation.isValid) {
      return this?.createError({ message: taxIdValidation.errorMessage });
    }
    return true;
  });
