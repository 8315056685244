import { IExternalDestination } from "../../../services/api/payments/payouts/types";

export const getDestinationName = (
  destination: IExternalDestination
): string => {
  const { institutionName, name, mask } = destination;

  if (!institutionName && !name && !mask) {
    switch (destination.destinationType) {
      case "DestinationType_Card":
        return "Debit Card";
      case "DestinationType_WeGift":
        return "WeGift Gift Card";

      case "DestinationType_Account":
      default:
        return "Bank Account";
    }
  }

  let details = `${institutionName || ""} ${name || ""}`.trim();
  if (mask) {
    details += ` (...${mask})`;
  }

  return details;
};
