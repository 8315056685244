import { useIsDesktop, WSScreen } from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import {
  ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH,
  EngagementRequirementsSettingsIndex
} from "../../modules/EngagementRequirementsSettings";
import { RouteAccount } from "../../modules/Settings/routes/RouteAccount";
import {
  PATH_SETTINGS_PAYMENT_METHODS,
  RoutePaymentMethods
} from "../../modules/Settings/routes/RoutePaymentMethods";
import {
  PATH_SETTINGS_PAYROLL,
  RoutePayroll
} from "../../modules/Settings/routes/RoutePayroll";
import { TeamsIndex } from "../../modules/Teams/routes";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useIsEngagementsActive } from "../../shared/hooks/useIsEngagementsActive";
import { AccountTab } from "./AccountTab";
import { BillingSettings } from "./BillingSettings";
import { BusinessInfoTab } from "./BusinessInfoTab";
import { Developer } from "./Developer";
import { EmailSettings } from "./EmailSettings";
import { EmailSettingsAddSnippet } from "./EmailSettingsAddSnippet";
import { IntegrationsTab } from "./Integrations/IntegrationsTab";
import { IntegrationsQuickbooksSyncActivities } from "./Integrations/quickbooks/IntegrationsQuickbooksSyncActivities";
import { QuickbooksSetupStep1 } from "./Integrations/quickbooks/QuickbooksSetupStep1";
import { QuickbooksSetupStep2 } from "./Integrations/quickbooks/QuickbooksSetupStep2";
import { QuickbooksSetupStep3 } from "./Integrations/quickbooks/QuickbooksSetupStep3";
import { QuickbooksSetupStep4 } from "./Integrations/quickbooks/QuickbooksSetupStep4";
import { QuickbooksSetupStep5 } from "./Integrations/quickbooks/QuickbooksSetupStep5";
import { QuickbooksSetupStep6 } from "./Integrations/quickbooks/QuickbooksSetupStep6";
import { QuickbooksSetupStep7 } from "./Integrations/quickbooks/QuickbooksSetupStep7";
import { QuickbooksSetupStep8 } from "./Integrations/quickbooks/QuickbooksSetupStep8";
import { NotificationsTab } from "./NotificationsTab";
import { Payroll } from "./Payroll";
import { PersonalInformationTab } from "./PersonalInformationTab";
import { TaxProfileTab } from "./TaxProfileTab";

export type SettingsProps = RouteComponentProps;

export const SettingsMain: React.FC<SettingsProps> = ({
  location: { hash }
}) => {
  const isDesktop = useIsDesktop();
  const isEngagementsActive = useIsEngagementsActive();
  const queryFeatureFlag = useFeatureFlags();

  useEffect(() => {
    const timer = setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);

      element?.scrollIntoView();
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [hash]);

  return (
    <Switch>
      <Route
        path="/member/settings/account"
        component={
          queryFeatureFlag.data?.newAccountSettings ? RouteAccount : AccountTab
        }
      />
      <Route path={PATH_SETTINGS_PAYROLL} component={RoutePayroll} />
      <Route
        path="/member/settings/personal-info"
        component={PersonalInformationTab}
      />
      <Route
        path="/member/settings/business-info"
        component={BusinessInfoTab}
      />

      {isEngagementsActive ? (
        <Route
          path={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
          component={EngagementRequirementsSettingsIndex}
        />
      ) : null}
      <Route
        path={[
          "/member/settings/email-settings/add-snippet",
          "/member/settings/email-settings/edit-snippet"
        ]}
        component={EmailSettingsAddSnippet}
      />
      <Route path="/member/settings/email-settings" component={EmailSettings} />
      {/* Integrations */}
      <Redirect
        path="/member/settings/integrations/quickbooks/setup"
        exact
        to="/member/settings/integrations/quickbooks/setup/step/1"
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/1"
        exact
        component={QuickbooksSetupStep1}
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/2"
        exact
        component={QuickbooksSetupStep2}
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/3"
        exact
        component={QuickbooksSetupStep3}
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/4"
        exact
        component={QuickbooksSetupStep4}
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/5"
        exact
        component={QuickbooksSetupStep5}
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/6"
        exact
        component={QuickbooksSetupStep6}
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/7"
        exact
        component={QuickbooksSetupStep7}
      />
      <Route
        path="/member/settings/integrations/quickbooks/setup/step/8"
        exact
        component={QuickbooksSetupStep8}
      />
      <Route
        path="/member/settings/integrations/quickbooks/history"
        component={IntegrationsQuickbooksSyncActivities}
      />
      <Route path="/member/settings/integrations" component={IntegrationsTab} />

      {/* Everythings else */}

      <Route path="/member/settings/billing" component={BillingSettings} />
      <Route path="/member/settings/payroll" component={Payroll} />

      <Route path="/member/settings/tax-profile" component={TaxProfileTab} />
      <Route
        path="/member/settings/notifications"
        component={NotificationsTab}
      />
      <Route path="/member/settings/team" component={TeamsIndex} />
      <Route
        path="/member/settings/tokens"
        exact={!isDesktop}
        component={Developer}
      />
      <Route
        path={PATH_SETTINGS_PAYMENT_METHODS}
        component={RoutePaymentMethods}
      />

      <WSScreen.Desktop>
        <Redirect from="/member/settings" exact to="/member/settings/account" />
      </WSScreen.Desktop>
    </Switch>
  );
};
