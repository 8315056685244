import React from "react";
import { WSList, WSPanel, WSText } from "@wingspanhq/fe-component-library";
import { ContractorDetails } from "./ContractorDetails";
import { PayerInformation } from "../routes/RouteDetails/PayerInformation";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

type Props = {
  submission: ITaxFormSubmissionResponse;
};

export const Summary: React.FC<Props> = ({ submission }) => {
  return (
    <WSList gap="2XL">
      <WSList gap="M">
        <WSText weight="medium" inline>
          Contractor details
        </WSText>
        <WSPanel>
          <WSList gap="2XL">
            <ContractorDetails submission={submission} />
          </WSList>
        </WSPanel>
      </WSList>
      <WSList gap="M">
        <WSText weight="medium" inline>
          Payer’s contact information
        </WSText>
        <WSPanel>
          <WSList gap="2XL">
            <PayerInformation payerData={submission.payerData} />
          </WSList>
        </WSPanel>
      </WSList>
    </WSList>
  );
};
