import {
  WSText,
  useWSModal,
  WSElementProps,
  detachLayoutProps,
  WSElement,
  WSActions
} from "@wingspanhq/fe-component-library";

export interface CorrectionFormActionsProps extends WSElementProps {
  onCancel: () => void;
  disabled?: boolean;
}

export const CorrectionFormActions: React.FC<CorrectionFormActionsProps> = ({
  onCancel,
  disabled,
  ...other
}) => {
  const { layoutProps } = detachLayoutProps(other);
  const cancelCorrectionModal = useCancelCorrectionModal();
  const confirmCancel = () => {
    cancelCorrectionModal.open({
      onCancel
    });
  };
  return (
    <WSActions
      {...layoutProps}
      alignment="fill"
      buttons={[
        {
          label: "Cancel",
          kind: "Tertiary",
          onClick: confirmCancel,
          type: "button"
        },
        {
          kind: "Primary",
          label: "Continue",
          disabled,
          type: "submit"
        }
      ]}
    />
  );
};

interface CancelCorrectionModalProps {
  onClose(): void;
  onCancel: () => void;
}

const CancelCorrectionModal: React.FC<CancelCorrectionModalProps> = ({
  onCancel,
  onClose
}) => {
  const handleCancel = () => {
    onCancel();
  };
  return (
    <>
      <WSElement colorBackground="gray50" p="XL" mb="XL">
        <WSText.ParagraphSm>
          Are you sure you want to cancel the correction request? None of the
          information will be saved if you choose to cancel.
        </WSText.ParagraphSm>
      </WSElement>
      <WSActions
        alignment="fill"
        buttons={[
          {
            kind: "Tertiary",
            label: "Back to request",
            onClick: onClose
          },
          {
            label: "Cancel request",
            destructive: true,
            onClick: handleCancel
          }
        ]}
      />
    </>
  );
};

export function useCancelCorrectionModal() {
  return useWSModal(CancelCorrectionModal, {
    title: "Cancel request",
    size: "S"
  });
}
