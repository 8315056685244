import { servicePayments } from "../servicePayments";

export const downloadTaxFormBySecureLink = async (
  token: string
): Promise<Blob> => {
  const { data } = await servicePayments.get(
    `/tax-form/${token}/secure-link/download`,
    {
      responseType: "blob"
    }
  );
  return data;
};
