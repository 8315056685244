import {
  WSActions,
  WSElement,
  WSFlexBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { BannerBankingVerification } from "./BannerBankingVerification";

export const PendingState: React.FC = () => {
  const history = useHistory();
  const queryVerifications = useQueryVerifications();

  return (
    <WSList gap="2XL">
      {queryVerifications.data?.banking === "Verified" ? (
        <WSElement colorBackground="gray50" p="XL">
          <WSFlexBox wrap="nowrap" mb="XL">
            <WSElement>
              <WSText weight="medium" color="gray600" mb="S">
                Wingspan Wallet
              </WSText>
              <WSText.ParagraphSm color="gray500" mb="S">
                Add funds to a business bank account with Wingspan. Use virtual
                cards to perform transactions, make payments and so much more.
              </WSText.ParagraphSm>
              <WSText.ParagraphSm color="gray500">
                To get started, provide verification documents to ensure
                compliance and the secure handling of your funds.
              </WSText.ParagraphSm>
            </WSElement>
          </WSFlexBox>

          <WSActions
            alignment="fill"
            buttons={[
              {
                label: "Get started",
                onClick: () => {
                  history.push("/member/activate-banking");
                }
              }
            ]}
          />
        </WSElement>
      ) : (
        <BannerBankingVerification />
      )}

      <WSElement colorBackground="gray50" p="XL">
        <WSText weight="medium" color="gray600" mb="XL">
          Reasons people love Wingspan Wallet:
        </WSText>

        <WSElement colorBackground="white" p="XL" mb="M">
          <WSText weight="medium" color="gray600" mb="M">
            Rewards on your spending
          </WSText>
          <WSText.ParagraphSm color="gray500">
            Earn 1% cash back on all purchases made with your Wingspan Visa®
            Debit Card. Receive a $50 gift card when you spend $1,000 in the
            first 30 days after sign up.
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement colorBackground="white" p="XL" mb="M">
          <WSText weight="medium" color="gray600" mb="M">
            Business banking made better
          </WSText>
          <WSText.ParagraphSm color="gray500">
            Receive your payouts 1 day sooner. No account, transaction, minimum
            balance or hidden fees. Virtual debit card via Apple Pay and Google
            Pay
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement colorBackground="white" p="XL" mb="M">
          <WSText weight="medium" color="gray600" mb="M">
            Save big on your taxes with write-offs
          </WSText>
          <WSText.ParagraphSm color="gray500">
            Automatic write-offs of business expenses, fully integrated with
            Wingspan Income & Expenses
          </WSText.ParagraphSm>
        </WSElement>
      </WSElement>
    </WSList>
  );
};
