import { Redirect, Route, Switch } from "react-router-dom";
import {
  OLD_TAX_DOCUMENTS_DETAILS_PATH,
  OLD_TAX_DOCUMENTS_PREFERENCES_PATH,
  OLD_TAX_DOCUMENTS_REQUEST_CORRECTION_PATH
} from "./paths";
import { RouteTaxPreferencesOld } from "./RouteOldDashboard/RouteTaxPreferencesOld";
import { RouteRequestCorrectionOld } from "./RouteOldDashboard/RouteRequestCorrectionOld";
import { RouteDetailsOld } from "./RouteOldDashboard/RouteDetailsOld";
import { RouteIndexOld } from "./RouteOldDashboard/RouteIndexOld";

export const OldTaxDocuments: React.FC<{}> = () => {
  return (
    <Switch>
      <Route
        path={OLD_TAX_DOCUMENTS_PREFERENCES_PATH}
        component={RouteTaxPreferencesOld}
      />

      <Route
        path={OLD_TAX_DOCUMENTS_REQUEST_CORRECTION_PATH}
        component={RouteRequestCorrectionOld}
      />

      <Route
        path={OLD_TAX_DOCUMENTS_DETAILS_PATH}
        component={RouteDetailsOld}
      />

      <Route path="/" component={RouteIndexOld} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};
