import cn from "classnames";
import React, { ReactNode, useMemo, useRef } from "react";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import { WSField, WSFieldProps } from "../WSField/WSField";
import { WSInfoBox } from "../WSInfoBox/WSInfoBox";
import { WSTooltip } from "../WSTooltip/WSTooltip";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSText, WSTextKindsType } from "../core/WSText/WSText.component";
import styles from "./WSControl.module.scss";

const DEFAULT_SIZE: WSControlSize = "M";

export type WSControlType = "checkbox" | "radio" | "switch";
export type WSControlSize = "S" | "M";

export interface WSControlPropsBase
  extends Omit<
    WSElementProps<HTMLInputElement>,
    "name" | "value" | "onChange"
  > {
  name?: string;
  disabled?: boolean;
  size?: WSControlSize;
  label?: string;
  helperText?: string;
  tooltip?: React.ReactNode;
  chip?: boolean;
  description?: ReactNode;
  // Will be visible only if control is active
  activeDescription?: ReactNode;
  status?: WSFieldProps["status"];
  message?: WSFieldProps["message"];
}

export interface WSControlPropsRegular extends WSControlPropsBase {
  type: "checkbox" | "radio" | "switch";
  value: boolean;
  onChange: (
    value: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export interface WSControlPropsMultistepCheckbox extends WSControlPropsBase {
  type: "multistep-checkbox";
  value: boolean | "indeterminate";
  onChange: (
    value: boolean | "indeterminate",
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export type WSControlProps =
  | WSControlPropsRegular
  | WSControlPropsMultistepCheckbox;

const controlSizeLableKindMap: Record<WSControlSize, WSTextKindsType> = {
  S: "ParagraphSm",
  M: "Paragraph"
};

export const WSControl = React.forwardRef<HTMLInputElement, WSControlProps>(
  (
    {
      type,
      size = DEFAULT_SIZE,
      chip,
      name,
      value,
      onChange,
      disabled,
      label,
      helperText,
      tooltip,
      className,
      description,
      activeDescription,
      status,
      message,
      ...elementProps
    },
    ref
  ) => {
    const tooltipIconRef = useRef<HTMLSpanElement>(null);
    const isActive = useMemo(() => value !== false, [value]);

    return (
      <WSElement
        as="label"
        className={cn(
          styles.controlWrapper,
          styles[type],
          styles["size-" + size],
          chip && styles.chip,
          isActive && styles.active,
          disabled && styles.disabled,
          type === "multistep-checkbox" &&
            value === "indeterminate" &&
            styles.indeterminate,
          className
        )}
        {...elementProps}
      >
        <WSElement className={cn(styles.control)}>
          {type === "multistep-checkbox" ? (
            <input
              ref={ref}
              className={styles.input}
              type="checkbox"
              name={name}
              checked={value === "indeterminate" ? true : value}
              disabled={disabled}
              onChange={(event) => {
                let newValue: WSControlPropsMultistepCheckbox["value"] = false;

                if (value === false) {
                  newValue = "indeterminate";
                } else if (value === "indeterminate") {
                  newValue = true;
                }

                onChange?.(newValue, event);
              }}
            />
          ) : (
            <input
              ref={ref}
              className={styles.input}
              type={type === "switch" ? "checkbox" : type}
              name={name}
              checked={value}
              disabled={disabled}
              onChange={(event) => {
                onChange?.(event.target.checked, event);
              }}
            />
          )}
          <WSElement className={styles.box} />

          <WSElement>
            {label && (
              <WSText
                kind={controlSizeLableKindMap[size]}
                className={styles.label}
              >
                {label}
              </WSText>
            )}

            {helperText && (
              <WSText.ParagraphXs className={styles.helperText}>
                {helperText}
              </WSText.ParagraphXs>
            )}
          </WSElement>

          {tooltip && (
            <>
              <WSTooltip target={tooltipIconRef}>{tooltip}</WSTooltip>
              <WSIcon
                className={styles.tooltipIcon}
                ref={tooltipIconRef}
                size="S"
                color="gray500"
                name="info-circle-fill"
              />
            </>
          )}
        </WSElement>

        {description && <WSInfoBox transparent>{description}</WSInfoBox>}

        {isActive && activeDescription && (
          <WSInfoBox>{activeDescription}</WSInfoBox>
        )}

        {(status || message) && (
          <WSField as="div" status={status} message={message} />
        )}
      </WSElement>
    );
  }
);
