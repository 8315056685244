import {
  WSActions,
  WSCard,
  WSElement,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import { ProgressBar } from "../../../../MemberOnboarding/components/ProgressBar/ProgressBar";
import { EditPayeeSetupParams } from "./index";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import React, { useCallback, useEffect } from "react";
import {
  TinType,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import { usePayeeQuery } from "../../../../query/payee/queries/usePayeeQuery";

type Props = SetupRouteComponentProps<EditPayeeSetupParams>;

export const EditPayeeTaxInfoVerification: React.FC<Props> = ({
  onNext,
  params
}) => {
  const payee = params?.payee;
  const payeeId = payee?.payeeId;
  const tinType = payee?.payerOwnedData.payeeW9Data?.tinType;

  const queryPayee = usePayeeQuery(payeeId!, {
    enabled: !!payeeId
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      const payeeData = await queryPayee.refetch();

      if (payeeData) {
        const verificationStatus =
          payeeData.payerOwnedData.payeeW9Data.payeeTinVerification?.status;

        if (verificationStatus === "Verified") {
          onNext({ status: "Verified" });
        } else if (verificationStatus === "Failed") {
          onNext({ status: "Failed" });
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [onNext, queryPayee]);

  const onComplete = useCallback(() => {
    onNext({
      status: VerificationStatus.Pending
    });
  }, []);

  const tinTypeLabel =
    tinType === TinType.Individual
      ? "Social Security Number (SSN)"
      : "Employer Identification Number (EIN)";

  return (
    <WSList gap="2XL">
      <WSCard header={`Verifying contractor’s ${tinTypeLabel}`}>
        <WSElement>
          <ProgressBar mb="XL" ms={20000} onComplete={onComplete} />

          <WSInfoBox mb="M">
            We are verifying the contractor’s {tinTypeLabel} with the IRS. This
            can take up to 10 seconds.
          </WSInfoBox>
        </WSElement>
      </WSCard>
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            disabled: true
          }
        ]}
      />
    </WSList>
  );
};
