import {
  toWSMoneyString,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";

import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import styles from "./DeductionsHistoryList.module.scss";
import { usePayableQuery } from "../../screens/payables/PayableDetails";
import { useMemberProfile } from "../../../query/users/queries";
import { getMemberName } from "../../../shared/utils/teamUtils";

type Props = WSElementProps & {
  deduction: IDeductionResponse;
};

export const PayableLink: React.FC<{ payableId: string }> = ({ payableId }) => {
  const history = useHistory();
  const qPayable = usePayableQuery(payableId);

  return (
    <WSQueries
      queries={{
        qPayable
      }}
      renderErrors={() => null}
      renderLoader={() => (
        <WSText.ParagraphSm inline shimmer>
          #11111
        </WSText.ParagraphSm>
      )}
    >
      {({ qPayable }) => (
        <WSText.ParagraphSm
          color="blue500"
          inline
          onClick={() =>
            history.push(
              `/member/invoices/payables/all/${qPayable.data.payableId}`
            )
          }
        >
          #{qPayable.data.invoiceNumber}
        </WSText.ParagraphSm>
      )}
    </WSQueries>
  );
};
export const MemberName: React.FC<{ memberId: string }> = ({ memberId }) => {
  const history = useHistory();
  const qMember = useMemberProfile(memberId);

  return (
    <WSQueries
      queries={{
        qMember
      }}
      renderErrors={() => null}
      renderLoader={() => (
        <WSText.ParagraphSm inline shimmer>
          Member
        </WSText.ParagraphSm>
      )}
    >
      {({ qMember }) => (
        <WSText.ParagraphSm inline color="gray600" as="b">
          {getMemberName(qMember.data)}
        </WSText.ParagraphSm>
      )}
    </WSQueries>
  );
};

export const PayableHistoryItem: React.FC<{
  payableId: string;
  amountDeducted: number;
}> = ({ payableId, amountDeducted }) => {
  const history = useHistory();
  const qPayable = usePayableQuery(payableId);

  return (
    <WSQueries
      queries={{
        qPayable
      }}
      renderErrors={() => "-"}
      renderLoader={() => (
        <WSElement shimmer>
          <WSText weight="medium" mb="XS" formatDate="monthDayYear">
            Loading
          </WSText>
          <WSText.ParagraphSm color="gray500">-</WSText.ParagraphSm>
        </WSElement>
      )}
    >
      {({ qPayable }) => (
        <>
          <WSText weight="medium" mb="XS" formatDate="monthDayYear">
            {qPayable.data.events.paidAt}
          </WSText>
          <WSText.ParagraphSm color="gray500">
            {`${toWSMoneyString(
              amountDeducted,
              "default",
              qPayable.data.currency
            )} deducted from payable `}
            <WSText.ParagraphSm
              color="blue500"
              inline
              onClick={() =>
                history.push(
                  `/member/invoices/payables/all/${qPayable.data.payableId}`
                )
              }
            >
              #{qPayable.data.invoiceNumber}
            </WSText.ParagraphSm>
          </WSText.ParagraphSm>
        </>
      )}
    </WSQueries>
  );
};

export const DeductionsHistoryList: React.FC<Props> = ({
  deduction,
  ...elementProps
}) => {
  const history = useHistory();

  return (
    <WSElement mb="2XL" {...elementProps}>
      <WSText weight="medium" mt="2XL" mb="M">
        History
      </WSText>
      <>
        {(deduction.application || []).map(
          (application, index, applications) => {
            const isSuccess =
              index === 0 &&
              deduction.amount ===
                applications
                  .filter(a => a.disbursementId)
                  .map(a => a.amountDeducted)
                  .reduce((a, b) => a + b, 0);

            return (
              <WSElement
                key={`${index}_key`}
                className={classNames(styles.item, {
                  [styles.success]: isSuccess,
                  [styles.filled]: !isSuccess
                })}
                mb="XL"
              >
                <WSElement className={styles.icon} />
                <PayableHistoryItem
                  payableId={application.payableId}
                  amountDeducted={application.amountDeducted}
                />
              </WSElement>
            );
          }
        )}

        <WSElement className={classNames(styles.item, styles.filled)} mb="XL">
          <WSText weight="medium" mb="XS" formatDate="monthDayYear">
            {deduction.startDate}
          </WSText>
          <WSText.ParagraphSm color="gray500">
            Scheduled start date
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement className={classNames(styles.item)} mb="XL">
          <WSText weight="medium" mb="XS" formatDate="monthDayYear">
            {deduction.createdAt}
          </WSText>
          <WSText.ParagraphSm color="gray500">
            {toWSMoneyString(deduction.amount, "default", deduction.currency) +
              " "}
            deduction created by <MemberName memberId={deduction.memberId} />
          </WSText.ParagraphSm>
        </WSElement>
      </>
    </WSElement>
  );
};
