import { WSTableCell } from "@wingspanhq/fe-component-library";
import { BulkStatus, IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";
import {
  isUploadFinished,
  isUploadInProgress
} from "../../utils/bulkBatchUtils";

export function BulkBatchImportStatus({
  batchId,
  bulkBatch,
  bulkResource
}: {
  batchId: string;
  bulkBatch: IBulkBatch;
  bulkResource: BulkResource;
}) {
  const { useBulkBatchSummary } = getBulkQueriesByResource(bulkResource);
  const status = bulkBatch?.status;

  const qBulkBatchItems = useBulkBatchSummary(batchId, undefined, {
    enabled: status === BulkStatus.Open
  });
  const isLoading = qBulkBatchItems.isLoading || qBulkBatchItems.isFetching;

  const getStatusText = () => {
    if (isUploadInProgress(bulkBatch)) {
      return "Uploading";
    } else if (qBulkBatchItems.data && qBulkBatchItems.data.listSize === 0) {
      return "Empty";
    }
    return isUploadFinished(bulkBatch) ? "Awaiting import" : "Incomplete";
  };

  switch (status) {
    case BulkStatus.Open:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            text: getStatusText()
          }}
        />
      );

    case BulkStatus.Pending:
    case BulkStatus.Processing:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            theme: "blue",
            text: "Importing"
          }}
        />
      );

    case BulkStatus.Failed:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            theme: "warning",
            icon: true,
            text: "Action Required"
          }}
        />
      );

    case BulkStatus.Complete:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            theme: "success",
            text: "Complete"
          }}
        />
      );

    default:
      return null;
  }
}
