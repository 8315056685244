import {
  useWSModal,
  WSCard,
  WSDataItem,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { TaxFormDeliveryMethod } from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { useTaxFormResendEmail } from "../../../../../query/taxForm/mutations/useTaxFormResendEmail";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";

const ResendElectronicCopy: React.FC<{
  taxForm: IPayeeTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [resendElectronicCopy, resendElectronicCopyMeta] =
    useTaxFormResendEmail();

  return (
    <WSCard
      header="Re-send electronic copy"
      divider
      actions={{
        alignment: "fill",
        buttons: [
          {
            kind: "Secondary",
            label: "Cancel",
            onClick: () => {
              onClose(false);
            }
          },
          {
            label: "Confirm & send",
            loading: resendElectronicCopyMeta.isLoading,
            onClick: () => {
              resendElectronicCopy(
                {
                  taxFormId: taxForm.taxFormId,
                  deliveryMethod: TaxFormDeliveryMethod.Electronic
                },
                {
                  onSuccess() {
                    onClose?.(true);
                  }
                }
              );
            }
          }
        ]
      }}
    >
      <WSList gap="M">
        <WSText.ParagraphSm color="gray600">
          Please confirm that you want to re-send an electronic copy of the
          contractor’s 1099-NEC tax forms to the following email:
        </WSText.ParagraphSm>

        <WSPanel p="L">
          <WSList gap="M">
            <WSDataItem
              label="Name"
              value={selectorTaxFormRecipientName(taxForm)}
            />
            <WSDataItem label="Email" value={taxForm.payee?.user.email} />
          </WSList>
        </WSPanel>

        <WSErrorMessage
          error={resendElectronicCopyMeta.error}
          contextKey="Other"
        />
      </WSList>
    </WSCard>
  );
};

export const useModalResendElectronicCopy = () =>
  useWSModal(ResendElectronicCopy, {
    size: "S",
    style: {
      padding: 0,
      borderRadius: 8
    }
  });
