import {
  WSActions,
  WSButton,
  WSCard,
  WSFlexBox,
  WSForm,
  WSGrid,
  WSInfoBox,
  WSInputMask,
  WSList,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdatePayrollSettings } from "../../../../query/payments/mutations";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { ErrorContextKey } from "../../../../services/platform";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import {
  IStateTaxId,
  stateTaxIdMapToArray,
  TAX_ID_REQUIRED_STATES
} from "../../utils/stateTaxIdMapToArray";

const StateTaxIdField: React.FC = () => {
  const { control } = useFormContext();
  const {
    fields: stateTaxIds,
    append,
    remove
  } = useFieldArray<IStateTaxId>({
    name: "stateTaxIds",
    control
  });

  const onAddState = () => {
    append({
      state: "",
      taxId: ""
    });
  };

  const onRemoveState = (index: number) => {
    remove(index);
  };

  return (
    <WSList gap="XL">
      {stateTaxIds.map((stateTaxId, index) => {
        return (
          <WSGrid key={stateTaxId.id}>
            <WSGrid.Item span={{ m: "6" }}>
              <WSForm.Field
                defaultValue={stateTaxId.state}
                name={`stateTaxIds[${index}].state`}
                component={WSSelect}
                componentProps={{
                  options: TAX_ID_REQUIRED_STATES,
                  placeholder: "Select"
                }}
                label="State"
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ m: "5" }}>
              <WSForm.Field
                defaultValue={stateTaxId.taxId}
                label="Tax ID number"
                name={`stateTaxIds[${index}].taxId`}
                component={WSInputMask}
                componentProps={{
                  mask: "99-9999999",
                  unmask: true,
                  fsExclude: true
                }}
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ m: "1" }}>
              <WSFlexBox.CenterY style={{ height: "100%" }}>
                <WSButton.Link
                  icon="minus-circle"
                  onClick={() => onRemoveState(index)}
                >
                  Remove
                </WSButton.Link>
              </WSFlexBox.CenterY>
            </WSGrid.Item>
          </WSGrid>
        );
      })}
      <WSActions
        alignment="left"
        buttons={[
          {
            name: "addState",
            icon: "plus-circle",
            label: "Add state",
            onClick: onAddState,
            type: "button",
            kind: "Link"
          }
        ]}
      />
    </WSList>
  );
};

interface StateFilingFormValues {
  stateTaxIds: IStateTaxId[];
}

export const FormStateFiling: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const qMemberProfile = useMemberProfile(userId);
  const qPayrollSettings = usePayrollSettings(userId);

  const [updatePayrollSettings, updatePayrollSettingsMeta] =
    useUpdatePayrollSettings(userId);

  const onFormSubmit = async (data: { stateTaxIds: IStateTaxId[] }) => {
    const stateTaxIdMap = (data.stateTaxIds || []).reduce((acc: any, item) => {
      if (item.state && item.taxId) {
        acc[item.state] = item.taxId;
      }
      return acc;
    }, {});

    await updatePayrollSettings(
      {
        calculationSettings1099: {
          stateTaxId: stateTaxIdMap
        }
      },
      {
        onSuccess: onNext
      }
    );
  };

  return (
    <WSQueries queries={{ qMemberProfile, qPayrollSettings }}>
      {({
        qMemberProfile: { data: member },
        qPayrollSettings: { data: payrollSettings }
      }) => {
        const { address } = member.profile;
        const userStateTaxIds = stateTaxIdMapToArray(
          payrollSettings.calculationSettings1099?.stateTaxId ?? {}
        );
        let defaultStateTaxIds = [{ state: "", taxId: "" }];
        if (userStateTaxIds.length > 0) {
          defaultStateTaxIds = userStateTaxIds;
        } else if (
          TAX_ID_REQUIRED_STATES.map(state => state.value).includes(
            address?.state ?? ""
          )
        ) {
          defaultStateTaxIds = [{ state: address?.state ?? "", taxId: "" }];
        }
        const defaultValues = {
          stateTaxIds: defaultStateTaxIds
        };

        return (
          <WSForm<StateFilingFormValues>
            mb="XL"
            validationSchema={Yup.object().shape({
              stateTaxIds: Yup.array().of(
                Yup.object().shape({
                  state: Yup.string(),
                  taxId: Yup.string().when("state", (state?: string) => {
                    if (state) {
                      return Yup.string().required("Required");
                    }
                    return Yup.string();
                  })
                })
              )
            })}
            onSubmit={onFormSubmit}
            defaultValues={defaultValues}
          >
            <WSCard header="State filing settings" divider>
              <WSList gap="XL">
                <WSInfoBox theme="info" title="Consult a tax advisor">
                  Wingspan advises you to consult with a tax professional to
                  choose the most suitable filing options for your business
                </WSInfoBox>

                <WSText.ParagraphSm color="gray600">
                  Some states require a state Tax ID number to file 1099-NECs at
                  the state level. If you would like to submit any 1099-NECs for
                  state filing, add the applicable states and withholding or
                  registration ID numbers below.
                </WSText.ParagraphSm>

                <StateTaxIdField />

                <WSErrorMessage
                  mt="XL"
                  contextKey={ErrorContextKey.Other}
                  error={updatePayrollSettingsMeta.error}
                />
              </WSList>
            </WSCard>
            <WSActions
              mt="3XL"
              alignment="fill"
              buttons={[
                {
                  label: "Cancel",
                  onClick: onBack,
                  type: "button",
                  kind: "Secondary"
                },
                {
                  label: "Save changes",
                  type: "submit",
                  loading: updatePayrollSettingsMeta.isLoading
                }
              ]}
            />
          </WSForm>
        );
      }}
    </WSQueries>
  );
};
