import {
  WSActions,
  WSCard,
  WSEmptyState,
  WSList
} from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SetupNewCorrectionProps } from "./index";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { TaxDocumentsDetailsFAQ } from "../../../TaxDocuments/components/TaxDocumentsDetailsFAQ";
import React from "react";
import { useGoToTaxFormDetails } from "../../paths";

export const RouteSubmitCorrectionSuccess: React.FC<
  SetupNewCorrectionProps
> = () => {
  const history = useHistory<{ submissionId: string }>();
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId } = match.params;

  const goToTaxFormDetails = useGoToTaxFormDetails();

  return (
    <EditLayout
      noBack
      title="Start new correction"
      contentRight={<TaxDocumentsDetailsFAQ mb="L" />}
    >
      <WSList gap="2XL">
        <WSCard>
          <WSEmptyState
            type="done"
            orientation="horizontal"
            title="Corrections submitted to the IRS"
            description="We have submitted your correction to the IRS. You can track the acceptance or rejection status of the submission on your tax filing dashboard."
          />
        </WSCard>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Continue",
              onClick: () => {
                goToTaxFormDetails(
                  taxFormId,
                  history.location.state?.submissionId!
                );
              }
            }
          ]}
        />
      </WSList>
    </EditLayout>
  );
};
