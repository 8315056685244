import "@typeform/embed/build/css/popup.css";

import {
  WSAlert,
  WSButton,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TinType,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useLayoutEffect, useRef, useState } from "react";
import { TextBulleted } from "../../../shared/components/TextBulleted";
import { openInNewTab } from "../../../shared/utils/openInNewTab";

type Props = {
  taxForm: IPayerTaxFormResponse;
} & WSElementProps;

export const RecipientVerificationFailed: React.FC<Props> = ({
  taxForm,
  ...elementProps
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const ref = useRef<HTMLElement>(null);

  const isSsn =
    taxForm.data.w9Info?.tinType === TinType.Individual &&
    taxForm.tinVerification?.ssn?.status === VerificationStatus.Failed;

  useLayoutEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <WSElement {...elementProps} ref={ref}>
      {isExpanded ? (
        isSsn ? (
          <WSAlert
            theme="error"
            icon="alert"
            title="We could not match the Social Security Number with IRS records"
          >
            <TextBulleted as="ol">
              <li>
                <b>Double-Check Recipient Information</b>
                <br />
                Confirm the business name, legal name, and SSN matches exactly
                what appears on the recipient’s business’s SSN confirmation
                document (either IRS form 147c or IRS form CP-575).
              </li>
              <li>
                <b>Contact the IRS</b>
                <br />
                Contact the IRS Business and Specialty Tax Line to confirm the
                SSN and business name on file.
                <br />
                <WSButton.Link
                  type="button"
                  onClick={() => {
                    openInNewTab("tel:+18008294933");
                  }}
                >
                  +1 800-829-4933
                </WSButton.Link>
              </li>
              <li>
                <b>Recent Business Changes?</b>
                <br />
                If the business name or structure has changed, the recipient
                will need to fill out IRS Form 8822-B. Re-enter recipient
                taxpayer information once they have received update confirmation
                from the IRS.
              </li>
              <li>
                <b>Need more help?</b>
                <br />
                Review our guide on how to handle TIN verification{" "}
                <WSButton.Link
                  type="button"
                  onClick={() =>
                    openInNewTab(
                      "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
                    )
                  }
                >
                  errors here to get more help
                </WSButton.Link>
                .
              </li>
            </TextBulleted>
          </WSAlert>
        ) : (
          <WSAlert
            theme="error"
            icon="alert"
            title="We could not match the Employee Identification Number with IRS records"
          >
            <TextBulleted as="ol">
              <li>
                <b>Double-Check Recipient Information</b>
                <br />
                Confirm the business name, legal name, and EIN matches exactly
                what appears on the recipient’s business’s EIN confirmation
                document (either IRS form 147c or IRS form CP-575).
              </li>
              <li>
                <b>Contact the IRS</b>
                <br />
                Contact the IRS Business and Specialty Tax Line to confirm the
                EIN and business name on file.
                <br />
                <WSButton.Link
                  type="button"
                  onClick={() => {
                    openInNewTab("tel:+18008294933");
                  }}
                >
                  +1 800-829-4933
                </WSButton.Link>
              </li>
              <li>
                <b>Recent Business Changes?</b>
                <br />
                If the business name or structure has changed, the recipient
                will need to fill out IRS Form 8822-B. Re-enter recipient
                taxpayer information once they have received update confirmation
                from the IRS.
              </li>
              <li>
                <b>Need more help?</b>
                <br />
                Review our guide on how to handle TIN verification{" "}
                <WSButton.Link
                  type="button"
                  onClick={() =>
                    openInNewTab(
                      "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
                    )
                  }
                >
                  errors here to get more help
                </WSButton.Link>
                .
              </li>
            </TextBulleted>
          </WSAlert>
        )
      ) : (
        <WSAlert
          theme="error"
          icon="alert"
          title={
            isSsn
              ? "We could not match the Social Security Number with IRS records"
              : "We could not match the Employee Identification Number with IRS records"
          }
        />
      )}

      <WSText.ParagraphSm color="gray500" mt="M">
        As the filer, you may choose to proceed with a mismatched or unverified
        TIN. The mismatch error will remain here for your reference.{" "}
        <WSButton.Link
          size="S"
          onClick={() => {
            setIsExpanded(prev => !prev);
          }}
        >
          {isExpanded ? "Minimize" : "Expand"} alert
        </WSButton.Link>
      </WSText.ParagraphSm>
    </WSElement>
  );
};
