import { servicePayments } from "../servicePayments";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

export const getTaxFormSubmission = async (
  taxFormId: string,
  submissionId: string
): Promise<ITaxFormSubmissionResponse> => {
  const { data } = await servicePayments.get(
    `/tax-form/${taxFormId}/submission/${submissionId}`
  );
  return data;
};
