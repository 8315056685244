import React from "react";
import {
  WSControlGroup,
  WSElement,
  WSElementProps,
  WSForm,
  WSGrid,
  WSInfoBox,
  WSInputMask,
  WSInputText,
  WSMessageBox,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { getSsnInputProps } from "../../utils/getSsnInputProps";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import {
  BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS,
  INDIVIDUAL_COMPANY_STRUCTURE_SELECT_OPTIONS
} from "../../constants/member";
import { selectorTaxClassificationLabel } from "../../selectors/selectorTaxClassificationLabel";
import { selectorNo1099CompanyStructure } from "../../selectors/selectorNo1099CompanyStructure";
import { TaxFormViewer } from "../../../modules/TaxDocuments/types";

export type FormPartialIdentificationNumberProps = {
  name: string;
  ssnProvided: boolean;
  isTaxFormCorrection?: boolean;
  taxFormViewer?: TaxFormViewer;
} & WSElementProps;

export const FormPartialIdentificationNumber: React.FC<
  FormPartialIdentificationNumberProps
> = ({
  name,
  ssnProvided,
  isTaxFormCorrection,
  taxFormViewer = TaxFormViewer.Payee,
  ...elementProps
}) => {
  return (
    <WSElement {...elementProps}>
      <WSElement mb="2XL">
        <WSText weight="medium" mb="M">
          Identification number
        </WSText>
        <WSForm.Field
          mb="XL"
          name={name}
          component={WSControlGroup}
          componentProps={{
            chip: true,
            type: "radio",
            options: [
              {
                label: "Social Security Number (SSN)",
                // description:
                //   "Ideal for individuals, sole proprietors without a formal business structure. This is your personal 9-digit number.",
                value: TinType.Individual
              },
              {
                label: "Employer Identification Number (EIN)",
                // description:
                //   "Choose this if you’re representing a legally registered business. If you’ve set up an LLC, Corporation, or Partnership, you likely have an EIN.",
                value: TinType.Business
              }
            ]
          }}
        />

        <WSForm.Value name={name}>
          {identificationNumberType => (
            <React.Fragment key={identificationNumberType}>
              {identificationNumberType === TinType.Individual ? (
                <WSForm.Field
                  name="ssn"
                  label="Social Security Number"
                  component={WSInputMask}
                  componentProps={{
                    ...getSsnInputProps({
                      showPlaceholder: true,
                      isValueProvided: ssnProvided
                    }),
                    required: true
                  }}
                />
              ) : (
                <WSForm.Field
                  name="ein"
                  label="Employer Identification Number"
                  component={WSInputMask}
                  componentProps={{
                    required: true,
                    mask: "99-9999999",
                    placeholder: "Employer Identification Number",
                    unmask: true,
                    fsExclude: true
                  }}
                />
              )}
            </React.Fragment>
          )}
        </WSForm.Value>
      </WSElement>

      <WSForm.Value name={name}>
        {identificationNumberType =>
          identificationNumberType === TinType.Individual ? (
            <React.Fragment key={identificationNumberType}>
              <WSGrid gutter="M" mb="2XL">
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSForm.Field
                    name="firstName"
                    label="Legal first name"
                    component={WSInputText}
                    componentProps={{
                      tooltip:
                        "Your name should match exactly with what's listed on your federal tax return to avoid discrepancies with the IRS.",
                      placeholder: "First name"
                    }}
                  />
                </WSGrid.Item>
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSForm.Field
                    name="lastName"
                    label="Legal last name"
                    component={WSInputText}
                    componentProps={{
                      tooltip:
                        "Your name should match exactly with what's listed on your federal tax return to avoid discrepancies with the IRS.",
                      placeholder: "Last name"
                    }}
                  />
                </WSGrid.Item>
              </WSGrid>

              <WSMessageBox.Info noBorder mt="S">
                <WSText.ParagraphSm color="gray500" weight="book">
                  Federal tax classification
                </WSText.ParagraphSm>
                <WSText.ParagraphXs color="gray400" weight="book" mb="S">
                  Note: This is default classification for individual account
                  types that operate with an SSN.
                </WSText.ParagraphXs>
                <WSText.ParagraphSm weight="book" color="gray700">
                  Sole proprietorship / Single member LLC
                </WSText.ParagraphSm>
              </WSMessageBox.Info>
            </React.Fragment>
          ) : (
            <React.Fragment key={identificationNumberType}>
              <WSGrid gutter="M" mb="2XL">
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSForm.Field
                    name="businessName"
                    label="Business name"
                    component={WSInputText}
                    componentProps={{
                      required: true,
                      tooltip:
                        "Your name should match exactly with what's listed on your federal tax return to avoid discrepancies with the IRS."
                    }}
                  />
                </WSGrid.Item>
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSForm.Field
                    name="disregardedEntityName"
                    label="Business name/disregarded entity (optional)"
                    component={WSInputText}
                  />
                </WSGrid.Item>
              </WSGrid>

              <WSElement mb="2XL">
                <WSForm.Value name="identificationNumberType">
                  {identificationNumberType => (
                    <WSForm.Field
                      key={identificationNumberType}
                      name="taxClassification"
                      label="Federal tax classification"
                      component={WSSelect}
                      componentProps={{
                        required: true,
                        options:
                          identificationNumberType === TinType.Business
                            ? BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS
                            : INDIVIDUAL_COMPANY_STRUCTURE_SELECT_OPTIONS
                      }}
                    />
                  )}
                </WSForm.Value>

                <WSForm.Value name="taxClassification">
                  {taxClassification =>
                    selectorNo1099CompanyStructure(taxClassification) ? (
                      isTaxFormCorrection ? (
                        <WSInfoBox
                          mt="L"
                          title={`${selectorTaxClassificationLabel(
                            taxClassification
                          )}: no 1099-NEC form will be issued`}
                        >
                          {taxFormViewer === TaxFormViewer.Payer ? (
                            <>
                              <WSText.ParagraphSm mb="M" color="gray500">
                                As a{" "}
                                {selectorTaxClassificationLabel(
                                  taxClassification
                                )}
                                , you're not eligible for a 1099 according to
                                IRS guidelines. Instead, Wingspan will provide a
                                summary of your payments for the tax year to
                                assist you during tax season. If this doesn't
                                align with your business structure, update your
                                details promptly.
                              </WSText.ParagraphSm>
                            </>
                          ) : (
                            <>
                              <WSText.ParagraphSm mb="M" color="gray500">
                                As a{" "}
                                {selectorTaxClassificationLabel(
                                  taxClassification
                                )}
                                , you're not eligible for a 1099 according to
                                IRS guidelines. Please verify this is accurate
                                before proceeding.
                              </WSText.ParagraphSm>

                              <WSText.ParagraphSm mb="M" color="gray500">
                                If the payer accepts and files this correction
                                with the IRS, it will void the original 1099
                                filing.
                              </WSText.ParagraphSm>
                            </>
                          )}
                        </WSInfoBox>
                      ) : (
                        <WSInfoBox
                          title="No 1099-NEC form will be issued"
                          mt="L"
                        >
                          <WSText.ParagraphSm mb="M" color="gray500">
                            As a{" "}
                            {selectorTaxClassificationLabel(taxClassification)},
                            you're not eligible for a 1099 according to IRS
                            guidelines. Instead, Wingspan will provide a summary
                            of your payments for the tax year to assist you
                            during tax season. If this doesn't align with your
                            business structure, update your details promptly.
                          </WSText.ParagraphSm>
                        </WSInfoBox>
                      )
                    ) : null
                  }
                </WSForm.Value>
              </WSElement>
            </React.Fragment>
          )
        }
      </WSForm.Value>
    </WSElement>
  );
};
