import { WSAlertProps } from "@wingspanhq/fe-component-library";
import { PropsWithChildren, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type LocationStateAlert = { alert?: PropsWithChildren<WSAlertProps> };

export function useLocationStateAlert() {
  const history = useHistory();
  const location = useLocation<LocationStateAlert>();

  return useMemo(() => {
    if (!location.state?.alert) {
      return;
    }

    return {
      ...location.state.alert,
      onDismiss: () => {
        // Clear location state
        history.replace(location.pathname);
      }
    };
  }, [history, location.pathname, location.state?.alert]);
}
