import { WSAlert, WSMarginProps } from "@wingspanhq/fe-component-library";

interface Props extends WSMarginProps {
  correctionsNumber: number;
  onView: () => void;
}

export const AlertPendingCorrectionRequests: React.FC<Props> = ({
  correctionsNumber,
  onView,
  ...otherProps
}) => {
  return (
    <WSAlert
      theme="info"
      icon="info-circle"
      title="New correction requests from contractors"
      action={{
        onClick: onView,
        text: "View correction requests"
      }}
      {...otherProps}
    >
      You have {correctionsNumber} total correction requests from contractors to
      review and respond to.
    </WSAlert>
  );
};
