import { statementsService } from "./statementsService";
import { IStatementsRequestQuery, IStatementListResponse } from "./types";

export const getStatements = async (
  query?: IStatementsRequestQuery
): Promise<IStatementListResponse> => {
  const { data } = await statementsService.get("/internal-account-statements", {
    params: query
  });

  return data;
};
