import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSMessageBox,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { TaxFormStatus } from "@wingspanhq/payments/dist/interfaces";
import { TinType } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router";
import { WSQueries } from "../../../../../query/WSQuery";
import { useUserId } from "../../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../../query/payments/queries";
import {
  getTaxFormRowSearchQueryDefaultFilters,
  usePaginatedTaxFormRowSearchCount
} from "../../../../../query/search/taxForm/queries/usePaginatedTaxFormRowSearch";
import { useMemberProfile } from "../../../../../query/users/queries";
import { AddressText } from "../../../../../shared/components/AddressText/AddressText";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { selectorMemberCompany } from "../../../../../shared/selectors/selectorMemberCompany";
import { formatEIN } from "../../../../../shared/utils/formatEIN";
import { formatPhone } from "../../../../../shared/utils/formatPhone";
import { EditLayout } from "../../../../TaxCorrections/components/EditLayout";
import { Field } from "../../../components/Field/Field";
import { selectorMemberDefaultTinType } from "../../../selectors/selectorMemberDefaultTinType";
import { stateTaxIdMapToArray } from "../../../utils/stateTaxIdMapToArray";
import { CURRENT_YEAR } from "../../../constants/currentYear";

export const RouteSubmit: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const history = useHistory();
  const userId = useUserId();
  const queryMemberProfile = useMemberProfile(userId);
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryTaxFormRowSearchCount = usePaginatedTaxFormRowSearchCount({
    filters: {
      ...getTaxFormRowSearchQueryDefaultFilters(CURRENT_YEAR),
      statuses: `(status: ${TaxFormStatus.ReadyToSubmitToIrs})`
    }
  });

  const [isAgree, setIsAgree] = React.useState(false);
  const [showStates, setShowStates] = React.useState(false);

  return (
    <EditLayout title="1099-NEC Filing" onBack={onBack}>
      <WSText.Heading4>Ready to file?</WSText.Heading4>

      <WSText mt="M">
        Here’s what we have. Please review to ensure the information is accurate
        before submitting to the IRS.
      </WSText>
      <WSQueries
        queries={{
          queryMemberProfile,
          queryTaxFormRowSearchCount,
          queryPayrollSettings
        }}
      >
        {({
          queryMemberProfileData: member,
          queryTaxFormRowSearchCountData,
          queryPayrollSettingsData
        }) => {
          const companyName = selectorMemberCompany(member);
          const userStateTaxIds = stateTaxIdMapToArray(
            queryPayrollSettingsData.calculationSettings1099?.stateTaxId ?? {}
          );

          return (
            <>
              <WSFlexBox.CenterY justify="space-between" mt="2XL" mb="M">
                <WSText.Heading5>Payer information</WSText.Heading5>
                <WSButton.Link
                  onClick={() => {
                    history.push(
                      "/member/1099-filing/filing/edit-payer-information"
                    );
                  }}
                >
                  Edit
                </WSButton.Link>
              </WSFlexBox.CenterY>
              <WSPanel>
                <Field
                  mb="XL"
                  label="Name"
                  value={member.profile.company?.legalBusinessName || "-"}
                />
                <Field
                  mb="XL"
                  label="Address"
                  value={
                    <WSText as="pre" color="gray500">
                      <AddressText {...member.profile.address} />
                    </WSText>
                  }
                />
                <Field
                  mb="XL"
                  label="Support email address"
                  value={
                    member.profile.company?.supportEmail
                      ? member.profile.company?.supportEmail
                      : "-"
                  }
                />
                <Field
                  mb="XL"
                  label="Support phone number"
                  value={
                    member.profile.company?.supportPhoneNumber
                      ? formatPhone(member.profile.company?.supportPhoneNumber)
                      : "-"
                  }
                />
                <Field
                  mb="NONE"
                  label="Taxpayer identification number"
                  value={
                    selectorMemberDefaultTinType(member) === TinType.Business
                      ? member.profile.company?.taxId
                        ? `${formatEIN(
                            member.profile.company?.taxId
                          )} (Employer Identification Number)`
                        : "-"
                      : selectorMemberDefaultTinType(member) ===
                          TinType.Individual && member.profile.ssnProvided
                      ? `********* (Social Security Number)`
                      : "-"
                  }
                />
              </WSPanel>

              <WSText.Heading5 mt="2XL">Filing details</WSText.Heading5>
              <WSText mt="M">
                Only forms with filing status “Ready” will be submitted. We
                recommend reviewing forms with statuses “Action Required” and
                “Excluded” to ensure they have the correct information before
                filing.
              </WSText>

              {queryTaxFormRowSearchCountData === 0 ? (
                <WSMessageBox.Warning
                  mt="M"
                  title="No forms ready to submit to the IRS"
                  noBorder
                >
                  <WSText.ParagraphSm color="gray500" mt="M">
                    At least one (1) form with filing status “Ready” is required
                    to submit for filing. Please review the forms and ensure the
                    information is accurate and return to file when the forms
                    are in “Ready” status.
                  </WSText.ParagraphSm>
                </WSMessageBox.Warning>
              ) : null}

              <WSPanel mt="M">
                <Field
                  mb="XL"
                  label="Federal filings"
                  value={
                    <WSFlexBox.CenterY>
                      <WSText mr="M" color="gray500">
                        {queryTaxFormRowSearchCountData}
                      </WSText>
                      <WSPill theme="blue" icon="envelope" text="Ready" />
                    </WSFlexBox.CenterY>
                  }
                />

                <Field
                  mb="NONE"
                  label="State filings"
                  value={
                    <>
                      <WSFlexBox.CenterY>
                        <WSText mr="M" color="gray500">
                          {queryTaxFormRowSearchCountData}
                        </WSText>
                        <WSPill theme="blue" icon="envelope" text="Ready" />
                      </WSFlexBox.CenterY>

                      <WSFlexBox.CenterY justify="space-between" mt="XL">
                        <WSFlexBox.CenterY>
                          <WSText weight="medium">
                            State-specific filing details
                          </WSText>
                          <WSIcon
                            ml="M"
                            name={showStates ? "chevron-up" : "chevron-down"}
                            size="XS"
                            color="gray500"
                            onClick={() => {
                              setShowStates(!showStates);
                            }}
                          />
                        </WSFlexBox.CenterY>

                        <WSButton.Link
                          onClick={() => {
                            history.push(
                              "/member/1099-filing/filing/edit-state-filing"
                            );
                          }}
                        >
                          Edit
                        </WSButton.Link>
                      </WSFlexBox.CenterY>
                      {showStates ? (
                        <WSElement>
                          {userStateTaxIds.map((stateTaxId, index) => (
                            <WSFlexBox.CenterY
                              key={`${stateTaxId.taxId}_${index}`}
                              mt="M"
                              justify="space-between"
                            >
                              <WSText color="gray500" style={{ flex: 1 }}>
                                {stateTaxId.stateLabel}
                              </WSText>
                              <WSText color="gray500" style={{ flex: 1 }}>
                                {stateTaxId.taxId}
                              </WSText>
                            </WSFlexBox.CenterY>
                          ))}
                        </WSElement>
                      ) : null}
                    </>
                  }
                />
              </WSPanel>

              {queryTaxFormRowSearchCountData > 0 ? (
                <>
                  <WSDivider my="XL" />
                  <WSMessageBox.Info>
                    <WSText weight="medium">
                      Ready to submit 1099-NEC forms for filing with the IRS?
                    </WSText>
                    <WSText.ParagraphSm color="gray600" mt="M">
                      If you click submit, the forms listed above will be sent
                      to the IRS. You cannot undo this action. If you notice an
                      error on a form after filing, you can fix it with a
                      correction after you receive the form back from the IRS.
                    </WSText.ParagraphSm>
                  </WSMessageBox.Info>
                  <WSCheckboxToggle
                    mt="2XL"
                    name="agree"
                    value={isAgree}
                    onChange={setIsAgree}
                    label={`Under penalties of perjury, I declare that to the best of my knowledge. ${companyName} attempted to obtain the payee's U.S. TIN and other identifying information under IRS Publication 1281. As a result, the information contained in the taxpayer's electronic forms 1099 file is, to the best of my knowledge and belief, true, correct and complete.
`}
                  />

                  <WSButtons mt="2XL" forceFullWidth>
                    <WSButton disabled={!isAgree} onClick={onNext}>
                      Submit tax forms to the IRS
                    </WSButton>
                    <WSButton.Secondary onClick={onBack}>
                      Cancel
                    </WSButton.Secondary>
                  </WSButtons>
                  <WSText.ParagraphSm mt="XL">
                    The 1099-NEC forms mentioned above will be submitted to the
                    IRS. By the IRS submission deadline of January 31st, each
                    recipient will receive a copy of their 1099-NEC delivered by
                    mail or electronically based on their preference. This
                    allows sufficient time for the IRS to accept or reject the
                    submissions and prevents the potential high volume of back
                    and forth that can occur thereafter.
                  </WSText.ParagraphSm>
                </>
              ) : (
                <WSButtons mt="2XL" forceFullWidth>
                  <WSButton.Tertiary onClick={onBack}>Back</WSButton.Tertiary>
                </WSButtons>
              )}
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
