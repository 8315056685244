import { WSPillProps, WSTableCell } from "@wingspanhq/fe-component-library";
import React from "react";

import {
  TaxFormW9Source,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import { selectorPayerTaxFormDefaultLastFourTaxId } from "../../selectors/selectorPayerTaxFormDefaultLastFourTaxId";
import { TaxFormSubmissionRow } from "../../../../services/search";
import { selectorPayerTaxFormDefaultStatus } from "../../selectors/selectorPayerTaxFormDefaultStatus";

interface PillProps {
  props: Omit<WSPillProps, "text">;
  textSuffix: string;
}

function getBadgeProps(taxStatus?: VerificationStatus): PillProps {
  switch (taxStatus) {
    case VerificationStatus.Verified:
      return {
        props: {
          theme: "success",
          icon: "check"
        },
        textSuffix: "Match"
      };
    case VerificationStatus.Failed:
      return {
        props: {
          theme: "warning",
          icon: "alert-circle"
        },
        textSuffix: "Mismatch"
      };
    default:
      return {
        props: {
          theme: "neutral",
          icon: "time"
        },
        textSuffix: "Pending"
      };
  }
}

export interface TaxIDProps {
  taxFormSubmission: TaxFormSubmissionRow;
}

export const TaxID: React.FC<TaxIDProps> = ({ taxFormSubmission }) => {
  const taxIdLast4 =
    selectorPayerTaxFormDefaultLastFourTaxId(taxFormSubmission);
  const taxStatus =
    taxFormSubmission.tinVerification?.status ||
    selectorPayerTaxFormDefaultStatus(taxFormSubmission);

  const secondaryTexts: string[] = [];

  const { props, textSuffix } = getBadgeProps(taxStatus);

  if (!taxIdLast4) {
    return <WSTableCell text="--" />;
  }

  if (taxFormSubmission.data.w9Info?.w9Source) {
    secondaryTexts.push("W-9");
  }

  if (taxFormSubmission.data.w9Info?.w9Source === TaxFormW9Source.Payee) {
    secondaryTexts.push("Contractor shared");
  }

  if (taxFormSubmission.data.w9Info?.w9Source === TaxFormW9Source.Payer) {
    secondaryTexts.push("Provided by you");
  }

  if (taxFormSubmission.data.w9Info?.w9Source === TaxFormW9Source.Overwrite) {
    secondaryTexts.push("Overwritten by you");
  }

  return (
    <WSTableCell
      pill={{
        ...(props as any),
        text: ["•••", taxIdLast4, textSuffix].filter(Boolean).join(" ")
      }}
      secondaryText={secondaryTexts.join(" • ")}
    />
  );
};
