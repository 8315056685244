import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { servicePayments } from "../servicePayments";

export const createTaxFormSubmissionSecureLink = async (
  taxFormId: string,
  submissionId: string
): Promise<ITaxFormSubmissionResponse> => {
  const { data } = await servicePayments.post(
    `/tax-form/${taxFormId}/submission/${submissionId}/secure-link`
  );
  return data;
};
