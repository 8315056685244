import { useHistory } from "react-router-dom";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { DataView } from "../../../../shared/components/DataView";
import {
  mapCardProcessingFeesLabel,
  mapOffPlatformPaymentsLabel,
  mapReimbursableExpensesLabel
} from "../../constants/lables";
import { TAX_FILING_1099_FILING_UPDATE_CALCULATION_SETTINGS_PATH } from "../../paths";
import { WSAlert } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";

export const CalculationInformation: React.FC<{}> = () => {
  const userId = useUserId();
  const history = useHistory();

  const qPayrollSettings = usePayrollSettings(userId);

  const payrollSettings = qPayrollSettings?.data;

  const noInfoProvided = useMemo(
    () =>
      payrollSettings?.calculationSettings1099?.offPlatformPayments ===
        undefined &&
      payrollSettings?.calculationSettings1099?.cardProcessingFees ===
        undefined &&
      payrollSettings?.calculationSettings1099?.reimbursableExpenses ===
        undefined,
    [
      payrollSettings?.calculationSettings1099?.cardProcessingFees,
      payrollSettings?.calculationSettings1099?.offPlatformPayments,
      payrollSettings?.calculationSettings1099?.reimbursableExpenses
    ]
  );

  return (
    <DataView
      loading={qPayrollSettings.isLoading}
      fullWidth
      onEdit={() =>
        history.push(TAX_FILING_1099_FILING_UPDATE_CALCULATION_SETTINGS_PATH)
      }
      title="Calculation settings"
      data={[
        {
          label: {
            text: "Off-platform payments"
          },
          value: payrollSettings?.calculationSettings1099?.offPlatformPayments
            ? mapOffPlatformPaymentsLabel[
                payrollSettings.calculationSettings1099.offPlatformPayments
              ]
            : undefined
        },
        {
          label: {
            text: "Card processing fees"
          },
          value: payrollSettings?.calculationSettings1099?.cardProcessingFees
            ? mapCardProcessingFeesLabel[
                payrollSettings.calculationSettings1099.cardProcessingFees
              ]
            : undefined
        },
        {
          label: {
            text: "Reimbursable expenses"
          },
          value: payrollSettings?.calculationSettings1099?.reimbursableExpenses
            ? mapReimbursableExpensesLabel[
                payrollSettings.calculationSettings1099.reimbursableExpenses
              ]
            : undefined
        }
      ]}
    >
      {noInfoProvided && (
        <WSAlert theme="warning" title="Information required" icon="alert">
          This information is required to generate tax forms for the current
          filing year.
        </WSAlert>
      )}
    </DataView>
  );
};
