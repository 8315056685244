import React from "react";
import { FormCalculationSettings } from "../../components/FormCalculationSettings";
import { EditLayout } from "../../components/EditLayout";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";

export const RouteEditCalculationSettings: React.FC = () => {
  const goBack = useGoBackOrToParent();
  return (
    <EditLayout title="Tax filing settings" onBack={goBack}>
      <FormCalculationSettings onNext={goBack} onBack={goBack} />
    </EditLayout>
  );
};
