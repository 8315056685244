import { getEndDayByUTC, getStartDayByUTC } from "../../../utils/dates";
import { toURLQuerySearch } from "../../../shared/hooks/useUrlQuery";

export const getPaymentsLinkForTaxDocument = (
  clientId: string,
  startDate: Date,
  endDate: Date
) => {
  const isUTCDate = (date: Date) => {
    return date.toISOString().includes("T");
  };

  const localStartDate = isUTCDate(startDate)
    ? getStartDayByUTC(startDate)
    : startDate;
  const localEndDate = isUTCDate(endDate) ? getEndDayByUTC(endDate) : endDate;

  const searchParams = toURLQuerySearch({
    clientId,
    customPaidDateRange: [localStartDate, localEndDate],
    paidDateRange: "Custom",
    navBack: 1
  });

  return `/member/invoices?${searchParams}`;
};
