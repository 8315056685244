import {
  toWSDateString,
  toWSMoneyString,
  WSAlert,
  WSButton,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSList,
  WSPanel,
  WSPill,
  WSPillProps,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { TaxFormStatus } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";
import { getFillingStatusBadgeProps } from "../../../components/FillingStatusBadge";
import { EXCLUSION_REASONS } from "./exclusionReasons";
import { selectorPayerTaxFormIsLatestAddressCorrection } from "../../../selectors/selectorPayerTaxFormIsLatestAddressCorrection";
import React from "react";
import { selectorTaxFormIsEditable } from "../../../selectors/selectorTaxFormIsEditable";
import { useModalBeforeEdit } from "./ModalBeforeEdit";
import { useModalUpdateStatus } from "./ModalUpdateStatus";
import {
  useGoToTaxFormEditAmount,
  useGoToTaxFormEditContractor,
  useTaxFormSubmissionParamsIds
} from "../../../paths";
import { NEEDS_ACTION_REASONS } from "./needsActionReasons";
import { mapTaxFormStatusesToSearchableStatus } from "../../../utils/mapTaxFormStatusesToSearchableStatus";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import {
  InfoTooltip,
  InfoTooltipProps
} from "../../../../../shared/components/InfoTooltip/InfoTooltip";
import { selectorPayerTaxFormDefaultStatus } from "../../../selectors/selectorPayerTaxFormDefaultStatus";
import { VerificationStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useGoToPayeeDetails } from "../../../../Payees/utils";
import { useXWingspanExpansion } from "../../../../../shared/hooks/useXWingspanExpansion";
import { getEndDayByUTC, getStartDayByUTC } from "../../../../../utils/dates";
import { selectorTaxFormIsInPostFilingState } from "../../../selectors/selectorTaxFormIsInPostFilingState";
import { usePayeeQuery } from "../../../../../query/payee/queries/usePayeeQuery";
import { WSQueries } from "../../../../../query/WSQuery";
import { EmptyComponent } from "../../../../../shared/components/EmptyComponent";
import {
  GeneralRequirementType,
  RequirementStatus
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { getStatusCustomDescription } from "../../../../TaxCorrections/utils/getStatusCustomDescription";

export type StatusItem = {
  label: string;
  value?: string;
  tooltip?: InfoTooltipProps;
  pill?: WSPillProps;
  actionTitle?: string;
  helperText?: React.ReactNode;
  onClick?(): void;
  hidden?: boolean;
};

type Props = {
  taxForm: IPayerTaxFormResponse;
  submission: ITaxFormSubmissionResponse;
  openRevertModal: () => void;
};

type AlertNeedActionProps = {
  submissionId: string;
  taxForm: IPayerTaxFormResponse;
};

const AlertNeedAction: React.FC<AlertNeedActionProps> = ({
  submissionId,
  taxForm
}) => {
  const queryPayee = usePayeeQuery(taxForm.memberId);
  const goToTaxFormEditContractor = useGoToTaxFormEditContractor();
  const tinStatus = selectorPayerTaxFormDefaultStatus(taxForm);

  return (
    <WSQueries
      queries={{ queryPayee }}
      renderErrors={EmptyComponent}
      renderLoader={EmptyComponent}
    >
      {({ queryPayeeData }) => {
        const contractorIsVerified = !!queryPayeeData.requirements?.find(
          requirement =>
            requirement.requirementType ===
              GeneralRequirementType.TaxVerification &&
            requirement.status === RequirementStatus.Complete
        );

        const taxFormTINIsNotVerified =
          !tinStatus ||
          [VerificationStatus.Failed, VerificationStatus.None].includes(
            tinStatus
          );

        if (contractorIsVerified && taxFormTINIsNotVerified) {
          return (
            <WSAlert
              title="Action required: TIN mismatch"
              theme="warning"
              icon="alert"
              action={{
                text: "Edit contractor details on tax form",
                onClick() {
                  goToTaxFormEditContractor(taxForm.taxFormId, submissionId);
                }
              }}
            >
              The TIN could not be matched with IRS records. This contractor's
              tax ID was previously verified manually through a payer request or
              manual document verification by Wingspan's operations team for a
              past year. During 1099 generation re-verification, we discovered
              this tax ID is not matched with IRS records. Please manually enter
              the contractor's correct tax information on the form or update the
              filing status to “Ready” to proceed with the existing information.
            </WSAlert>
          );
        }

        return null;
      }}
    </WSQueries>
  );
};

const Statuses: React.FC<{ items: StatusItem[] }> = ({ items }) => {
  return (
    <WSGrid>
      {items
        .filter(item => item.hidden !== true)
        .map((item, index) => (
          <WSGrid.Item span={{ xs: "12", s: "6" }} key={index}>
            <WSFlexBox.CenterY gap="S">
              <WSText.ParagraphSm color="gray500">
                {item.label}
              </WSText.ParagraphSm>
              {item.tooltip ? <InfoTooltip {...item.tooltip} /> : null}
              {item.onClick ? (
                <WSButton.Link size="S" onClick={item.onClick}>
                  {item.actionTitle}
                </WSButton.Link>
              ) : null}
            </WSFlexBox.CenterY>
            {item.value ? (
              <WSText.ParagraphSm mt="XS" color="gray700">
                {item.value}
              </WSText.ParagraphSm>
            ) : null}
            {item.pill ? <WSPill mt="XS" {...item.pill} /> : null}
            {item.helperText ? (
              <WSText.ParagraphXs mt="XS" color="gray500">
                {item.helperText}
              </WSText.ParagraphXs>
            ) : null}
          </WSGrid.Item>
        ))}
    </WSGrid>
  );
};

export const MainInfo: React.FC<Props> = ({
  taxForm,
  submission,
  openRevertModal
}) => {
  const isEditable = selectorTaxFormIsEditable(taxForm);

  const { taxFormId, submissionId } = useTaxFormSubmissionParamsIds();
  const goToEditAmount = useGoToTaxFormEditAmount();
  const goToPayeeDetails = useGoToPayeeDetails();
  const goToTaxFormEditContractor = useGoToTaxFormEditContractor();
  const isOrganizationExist = useXWingspanExpansion();

  const beforeEditModal = useModalBeforeEdit();
  const updateStatusModal = useModalUpdateStatus();

  const isPostFiling = selectorTaxFormIsInPostFilingState(taxForm);

  const isSyncedWithPreviousEntity =
    taxForm.hasConnectedEntity && !taxForm.isCurrentEntity;

  const dateRangeView =
    taxForm.startDate && taxForm.endDate
      ? `${toWSDateString(
          getStartDayByUTC(taxForm.startDate),
          "monthDate"
        )} - ${toWSDateString(getEndDayByUTC(taxForm.endDate), "monthDayYear")}`
      : undefined;

  const filingStatusProps = getFillingStatusBadgeProps({
    status: mapTaxFormStatusesToSearchableStatus(
      taxForm.status,
      submission.status
    ),
    customDescription: getStatusCustomDescription({
      submissions: taxForm.submissions,
      corrections: taxForm.corrections,
      currentSubmissionId: submissionId
    })
  });

  const statusItems: StatusItem[] = [
    {
      label: "Date range",
      value: dateRangeView,
      tooltip: {
        tooltipProps: {
          title: "Date range"
        },
        text: `Payments received within this date range are included in the 1099-NEC total of this form. If tax forms are split due to entity changes, the date range will be updated to reflect the effective date of the split.`
      }
    },
    {
      label: "Filing status",
      actionTitle: "Edit",
      onClick:
        taxForm.status !== TaxFormStatus.Pending && isEditable
          ? async () => {
              if (taxForm.recipientSyncEnabled) {
                const response = await beforeEditModal.open();
                if (!response) {
                  return;
                }
              }

              await updateStatusModal.open({
                taxForm
              });
            }
          : undefined,
      pill: filingStatusProps,
      helperText:
        taxForm.status === TaxFormStatus.Excluded && taxForm.exclusionReason
          ? EXCLUSION_REASONS[taxForm.exclusionReason]?.helperText
          : taxForm.status === TaxFormStatus.NeedsAction &&
            taxForm.needsActionReason
          ? NEEDS_ACTION_REASONS[taxForm.needsActionReason]?.helperText
          : filingStatusProps.description
    },
    {
      label: "Contractor info",
      actionTitle: "Revert",
      onClick:
        !taxForm.recipientSyncEnabled && isEditable
          ? openRevertModal
          : undefined,
      pill: {
        text:
          taxForm.recipientSyncEnabled &&
          taxForm.data.w9Info?.w9Source === "Payee"
            ? "Provided by contractor"
            : taxForm.recipientSyncEnabled &&
              taxForm.data.w9Info?.w9Source === "Payer"
            ? "Provided by you"
            : !taxForm.recipientSyncEnabled &&
              taxForm.data.w9Info?.w9Source === "Payer"
            ? "Overwritten by you"
            : !taxForm.recipientSyncEnabled &&
              taxForm.data.w9Info?.w9Source === "Payee"
            ? "Overwritten by contractor"
            : taxForm.recipientSyncEnabled
            ? "Provided"
            : "Overwritten",
        theme: taxForm.recipientSyncEnabled ? "neutral" : "violet"
      },
      hidden: isPostFiling
    },
    {
      label: "Sync",
      actionTitle: "Revert",
      onClick:
        !taxForm.recipientSyncEnabled && isEditable
          ? openRevertModal
          : undefined,
      pill: {
        badge: true,
        theme: taxForm.recipientSyncEnabled ? "success" : "violet",
        text: taxForm.recipientSyncEnabled ? "Sync enabled" : "Sync disabled"
      },
      hidden: isPostFiling
    }
  ];

  const totalAmount = selector1099NECTotal(taxForm);

  return (
    <WSPanel>
      <WSList gap="2XL">
        <WSGrid gutter={{ xs: "L", s: "XL" }}>
          <WSGrid.Item span={{ s: "12", m: "6" }}>
            <WSPanel
              noBorder
              colorBackground="gray50"
              style={{ height: "100%" }}
            >
              <WSSectionToolbar
                title="1099-NEC total"
                pill={
                  totalAmount === undefined
                    ? {
                        icon: "time",
                        text: "Pending"
                      }
                    : !isPostFiling && !taxForm.recipientSyncEnabled
                    ? {
                        theme: "violet",
                        badge: true,
                        text: "Sync disabled"
                      }
                    : undefined
                }
                button={{
                  label: "Edit",
                  size: "S",
                  disabled: !(
                    taxForm.status !== TaxFormStatus.Pending && isEditable
                  ),
                  onClick:
                    taxForm.status !== TaxFormStatus.Pending && isEditable
                      ? async () => {
                          if (taxForm.recipientSyncEnabled) {
                            const response = await beforeEditModal.open();
                            if (!response) {
                              return;
                            }
                          }

                          goToEditAmount(taxFormId, submissionId);
                        }
                      : undefined
                }}
              />
              <WSText.Heading1 color="gray500" weight="book">
                {totalAmount ? toWSMoneyString(totalAmount) : "$ --"}
              </WSText.Heading1>

              {totalAmount ? (
                <WSElement pt="M">
                  <WSList gap="M">
                    {taxForm.data.platformIncome ? (
                      <WSFlexBox.CenterY justify="space-between">
                        <WSText.ParagraphSm color="gray500">
                          Wingspan platform
                        </WSText.ParagraphSm>
                        <WSText.ParagraphSm color="gray600">
                          {toWSMoneyString(taxForm.data.platformIncome)}
                        </WSText.ParagraphSm>
                      </WSFlexBox.CenterY>
                    ) : null}
                    {taxForm.data.adjustments ? (
                      <WSFlexBox.CenterY justify="space-between">
                        <WSText.ParagraphSm color="gray500">
                          Adjustments
                        </WSText.ParagraphSm>
                        <WSText.ParagraphSm color="gray600">
                          {toWSMoneyString(taxForm.data.adjustments)}
                        </WSText.ParagraphSm>
                      </WSFlexBox.CenterY>
                    ) : null}
                    {taxForm.data.paymentProcessingFees ? (
                      <WSFlexBox.CenterY justify="space-between">
                        <WSText.ParagraphSm color="gray500">
                          Payment processing fees
                        </WSText.ParagraphSm>
                        <WSText.ParagraphSm color="gray600">
                          {toWSMoneyString(taxForm.data.paymentProcessingFees)}
                        </WSText.ParagraphSm>
                      </WSFlexBox.CenterY>
                    ) : null}
                  </WSList>
                </WSElement>
              ) : null}
            </WSPanel>
          </WSGrid.Item>
          <WSGrid.Item span={{ s: "12", m: "6" }}>
            <Statuses items={statusItems} />
          </WSGrid.Item>
        </WSGrid>

        <WSList gap="L">
          {!isPostFiling && taxForm.recipientSyncEnabled === false ? (
            <WSAlert
              theme="info"
              title="Sync disabled"
              icon="block"
              action={{
                text: "Revert to synced",
                onClick: openRevertModal
              }}
            >
              Automatic updates that are synced with the contractor profile are
              disabled for this 1099 because of manual edits. Please be aware
              that if the contractor already shared their tax info with you,
              they will receive a copy of the submitted form(s) with your edits
              for their records. You can always revert edits to sync the status,
              amount and contractor details with the data associated with the
              contractor profile.
            </WSAlert>
          ) : null}

          {selectorPayerTaxFormIsLatestAddressCorrection(taxForm) ? (
            <WSAlert theme="info" title="Information updated by recipient">
              The recipient updated their address. Since their State did not
              change, no correction needs to be filed with the IRS. If the
              recipient contacts you to re-mail their tax forms, their updated
              address will be used.
            </WSAlert>
          ) : null}

          {isSyncedWithPreviousEntity ? (
            <WSAlert
              theme="info"
              title="Synced with previous entity"
              icon="info-circle"
              action={{
                text: "Edit contractor details on tax form",
                onClick() {
                  goToTaxFormEditContractor(taxFormId, submissionId);
                }
              }}
            >
              Taxpayer information is synced with an existing previous entity
              that operated during the date range of {dateRangeView}, including
              tax info verification statuses. The information on this tax form
              (previous entity) might differ from the current contractor profile
              (current entity) due to the entity change occurring in the
              following year. Please manually enter the contractor's correct tax
              information on the form or update the filing status to “Ready” to
              proceed with the existing information.
            </WSAlert>
          ) : taxForm.status === TaxFormStatus.NeedsAction ? (
            <AlertNeedAction taxForm={taxForm} submissionId={submissionId} />
          ) : null}

          {taxForm.unsyncedUpdateAvailable ? (
            <WSAlert
              theme="info"
              title="Recipient has made updates that are out of sync"
              icon="alert-circle"
              action={{
                text: "View details",
                onClick: openRevertModal
              }}
            >
              Automatic updates are disabled for this 1099 because of manual
              edits. You can always revert edits to recalculate status, amount
              and recipient details.
            </WSAlert>
          ) : null}

          {taxForm.status === TaxFormStatus.Excluded &&
          taxForm.exclusionReason ? (
            <WSAlert
              theme="info"
              icon="info-circle"
              title={EXCLUSION_REASONS[taxForm.exclusionReason].title}
            >
              {EXCLUSION_REASONS[taxForm.exclusionReason].getMessage(taxForm)}
            </WSAlert>
          ) : null}

          {taxForm.status === TaxFormStatus.NeedsAction &&
          selectorPayerTaxFormDefaultStatus(taxForm) ===
            VerificationStatus.Pending ? (
            <WSAlert
              theme="info"
              icon="time"
              title="Verifying: IRS verification pending"
              action={{
                text: "View contractor profile",
                onClick: () => {
                  const payerId =
                    isOrganizationExist && taxForm.payer
                      ? taxForm.clientId
                      : undefined;
                  goToPayeeDetails(taxForm.memberId, payerId);
                }
              }}
            >
              We're waiting for the IRS to verify this TIN. Check back in 24
              hours.
            </WSAlert>
          ) : taxForm.status === TaxFormStatus.NeedsAction &&
            taxForm.needsActionReason ? (
            <WSAlert
              theme="warning"
              icon="alert"
              title={NEEDS_ACTION_REASONS[taxForm.needsActionReason].title}
            >
              {NEEDS_ACTION_REASONS[taxForm.needsActionReason].getMessage(
                taxForm
              )}
            </WSAlert>
          ) : null}
        </WSList>
      </WSList>
    </WSPanel>
  );
};
