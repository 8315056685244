import {
  toWSDate,
  useIsDesktop,
  useIsMobile,
  useModalContext,
  WSButton,
  WSCopyText,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  EmailVerificationStatus,
  IActivityContext,
  INewUser,
  IRedactedUser,
  ISubscription,
  NextgenSubscriptionTerm,
  PhoneVerificationStatus,
  SubscriptionStatus,
  SubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces";
import cn from "classnames";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ChooseAccountForPaymentMethodsModal } from "../../Invoices/components/ChooseAccountForPaymentMethodsModal/ChooseAccountForPaymentMethodsModal";
import { createPPL } from "../../PersonalPayLink/utils";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useMemberClientsQuery } from "../../query/payments/queries";
import {
  useCreateOrUpdateSubscriptionV3,
  useUpdateNextGenSubscription
} from "../../query/subscriptions/mutations";
import {
  useGetPaymentMethod,
  useGrantedSubscriberListQuery,
  useNextGenSubscriptionPlans,
  useNextGenSubscriptions,
  useSubscriptionPackages,
  useSubscriptionV3Query
} from "../../query/subscriptions/queries";
import {
  useActivities,
  useClientQuery,
  useUserById,
  useUserProfile
} from "../../query/users/queries";
import { selectorHasDocumentsToSign } from "../../shared/selectors/selectorHasDocumentsToSign";
import { formatPhone } from "../../shared/utils/formatPhone";
import { getAuthorizedAccountName } from "../../shared/utils/teamUtils";
import { useShowMembershipSettings } from "../../userFeatures/useShowMembershipSettings";
import { isUnderWriterMember } from "../../utils/growthAttribution";
import { CancelMembershipModal } from "../components/CancelMembershipModal/CancelMembershipModal";
import { DefaultSettingItem } from "../components/DefaultSettingItem/DefaultSettingItem.component";
import { SUBSCRIPTION_PACKAGES_ADDONS } from "../components/MembershipPackages/subscriptionPackages";
import { MembershipPaymentMethod } from "../components/MembershipPaymentMethod/MembershipPaymentMethod";
import { NextGenSubscriptionPaymentMethod } from "../components/NextGenSubscriptionPaymentMethod/NextGenSubscriptionPaymentMethod";
import { CancelNextGenSubscriptionsModal } from "../components/NextGenSubscriptionPlans/CancelNextGenSubscriptionsModal";
import { ChooseAddPaymentMethodFlowModal } from "../components/PaymentMethodsList/ChooseAddPaymentMethodFlowModal";
import { SavedPaymentMethodCreditCardModal } from "../components/PaymentMethodsList/SavedPaymentMethodCreditCardModal";
import { SettingsLoader } from "../components/SettingsLoader/SettingsLoader";
import { SettingsSections } from "../components/SettingsSections/SettingsSections.component";
import { useWarningChangeEmailModal } from "../components/WarningChangeEmailModal";
import {
  getCurrentPackage,
  hasSubscription,
  isProfessionalSubscription,
  isSubscriptionCanceled,
  PackageTierTerm
} from "../utils/subscriptionUtils";
import styles from "./AccountTab.module.scss";
import mainStyles from "./SettingsMain.module.scss";

export interface AccountTabProps extends RouteComponentProps {}

export const AccountTab: React.FC<AccountTabProps> = props => {
  const userId = useUserId();
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const useUserProfileQuery = useUserProfile(userId);
  const activitiesQuery = useActivities(userId);
  const { openModal, closeModal } = useModalContext();
  const warningChangeEmailModal = useWarningChangeEmailModal();
  const memberClientsQuery = useMemberClientsQuery();

  const showMembershipSettings = useShowMembershipSettings();

  const sections = [
    {
      title: "Account basics",
      render() {
        return (
          <WSQueries
            queries={{ useUserProfileQuery }}
            renderLoader={() => <SettingsLoader />}
          >
            {({ useUserProfileQuery: { data: userData } }) => {
              return (
                <>
                  <DefaultSettingItem
                    field="personalPayLink"
                    label="Personal Pay link"
                    valueRenderer={() => {
                      return userData?.tag ? (
                        <WSCopyText
                          isURL
                          shareTitle="Personal Pay link"
                          data={createPPL(userData.tag)}
                        />
                      ) : (
                        <WSElement className={styles.pplSetupMsg}>
                          <WSText.ParagraphSm mr="XS">
                            Personal pay link is not enabled.
                          </WSText.ParagraphSm>
                          <WSButton.Link
                            onClick={() =>
                              props.history.push("/member/personal-pay-link")
                            }
                          >
                            Set up direct deposit to enable personal pay link.
                          </WSButton.Link>
                        </WSElement>
                      );
                    }}
                    value={userData?.tag || ""}
                  />

                  <DefaultSettingItem
                    readonly
                    label="Email address"
                    value={userData?.email || ""}
                    field="email"
                    action="Edit"
                    onClick={async () => {
                      if (
                        memberClientsQuery?.data &&
                        selectorHasDocumentsToSign(memberClientsQuery.data)
                      ) {
                        const result = await warningChangeEmailModal.open();

                        if (!result) {
                          return;
                        }
                      }

                      history.push("/member/verify/email?edit=1");
                    }}
                    valueRenderer={(email: INewUser["email"]) => {
                      return (
                        <WSFlexBox.CenterY>
                          <WSText.ParagraphSm>{email}</WSText.ParagraphSm>
                          {userData?.emailVerificationState?.status ===
                          EmailVerificationStatus.Verified ? (
                            <WSPill
                              ml={isDesktop ? "XS" : "NONE"}
                              mt={isDesktop ? "NONE" : "XS"}
                              text="Verified"
                              theme="success"
                            />
                          ) : (
                            <WSButton.Link
                              ml="XS"
                              onClick={() =>
                                history.push({
                                  pathname: "/member/verify/email",
                                  state: {
                                    email
                                  }
                                })
                              }
                            >
                              Verify
                            </WSButton.Link>
                          )}
                        </WSFlexBox.CenterY>
                      );
                    }}
                  />
                  <DefaultSettingItem
                    readonly
                    formContainerClassName={styles.formContainerClassName}
                    label="Phone"
                    value={
                      userData?.phone.number
                        ? userData?.phone.number.slice(-10)
                        : ""
                    }
                    valueRenderer={(phone: INewUser["phone"]["number"]) => {
                      return (
                        <WSFlexBox.CenterY>
                          <WSText.ParagraphSm
                            className={cn({
                              [mainStyles.emptyStateValue]: !phone
                            })}
                            data-testid="formattedPhoneNumber"
                          >
                            {phone
                              ? formatPhone(phone.slice(-10))
                              : "Phone number is not added yet"}
                          </WSText.ParagraphSm>
                          {phone &&
                          userData?.phone.status ===
                            PhoneVerificationStatus.Verified ? (
                            <WSPill
                              ml={isDesktop ? "XS" : "NONE"}
                              mt={isDesktop ? "NONE" : "XS"}
                              text="Verified"
                              theme="success"
                            />
                          ) : phone ? (
                            <WSButton.Link
                              ml="XS"
                              onClick={() =>
                                history.push({
                                  pathname: "/member/verify/phone-number",
                                  state: {
                                    phoneNumber: phone
                                  }
                                })
                              }
                            >
                              Verify
                            </WSButton.Link>
                          ) : null}
                        </WSFlexBox.CenterY>
                      );
                    }}
                    field="phone"
                    action={userData?.phone.number ? "Edit" : "Add"}
                    onClick={() => {
                      window.sessionStorage.setItem(
                        "OTP_FLOW_NEXT_URL",
                        "/member/settings/account"
                      );
                      history.push("/member/verify/phone-number?edit=1");
                    }}
                  />
                  <DefaultSettingItem
                    label="Password"
                    value={userData?.isPasswordSet}
                    valueType="secret"
                    valueRenderer={(value: boolean) => {
                      return (
                        <WSText.ParagraphSm
                          className={cn({
                            [styles.emptyStateValue]: !value
                          })}
                        >
                          {value ? "*********" : "Password is not added yet"}
                        </WSText.ParagraphSm>
                      );
                    }}
                    field="password"
                    action={userData?.isPasswordSet ? "Edit" : "Add"}
                  />
                </>
              );
            }}
          </WSQueries>
        );
      }
    }
  ];

  if (showMembershipSettings) {
    sections.push({
      title: "Membership",
      render() {
        return isUnderWriterMember(
          activitiesQuery.data?.context as IActivityContext
        ) ? (
          <NextGenMembershipSettingSection />
        ) : (
          <MembershipSettingSection />
        );
      }
    });
  }

  return (
    <WSPage title="Account">
      <WSGrid>
        <WSGrid.Item span={{ m: "10" }}>
          <WSElement className={mainStyles.contentPane} {...props}>
            <ChooseAccountForPaymentMethodsModal
              onSuccess={() => {
                history.push("/member/settings/account");
              }}
            />
            <ChooseAddPaymentMethodFlowModal />
            <SavedPaymentMethodCreditCardModal />
            <SettingsSections
              title="Account"
              parentPathName="/member/settings/account"
              sections={sections}
            />
          </WSElement>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};

export const NextGenMembershipSettingSection: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const userId = useUserId();
  const featureFlagsQuery = useFeatureFlags();
  const clientQuery = useClientQuery(userId);
  const nextGenSubscriptionPlansQuery = useNextGenSubscriptionPlans(userId);
  const nextGenSubscriptionsQuery = useNextGenSubscriptions(userId);

  const [updateNextGenSubscription, updateNextGenSubscriptionMeta] =
    useUpdateNextGenSubscription(userId);

  return (
    <WSQueries
      queries={{
        nextGenSubscriptionPlansQuery,
        nextGenSubscriptionsQuery,
        featureFlagsQuery,
        clientQuery
      }}
      renderLoader={() => <SettingsLoader />}
    >
      {({
        nextGenSubscriptionPlansQuery: { data: nextGenSubscriptionPlans },
        nextGenSubscriptionsQuery: { data: nextGenSubscriptions },
        clientQuery: { data: client }
      }) => {
        const freePlan = nextGenSubscriptionPlans.find(
          plan =>
            plan.prices[NextgenSubscriptionTerm.Monthly].amount === 0 &&
            plan.prices[NextgenSubscriptionTerm.Yearly].amount === 0
        );
        const monthlySubscriptions = nextGenSubscriptions.filter(
          nextGenSub => nextGenSub.term === NextgenSubscriptionTerm.Monthly
        );
        return (
          <WSElement>
            <WSFlexBox direction="column">
              {monthlySubscriptions.length > 0 && (
                <WSFlexBox.CenterY
                  py="XS"
                  px="M"
                  justify="space-between"
                  className={styles.savingTip}
                  mb="XL"
                >
                  <WSText color="gray600">
                    Switch to annual billing and save 33%
                  </WSText>
                  <WSButton.Link
                    name="updateAnnualBilling"
                    loading={updateNextGenSubscriptionMeta.isLoading}
                    onClick={async () => {
                      await Promise.all(
                        monthlySubscriptions.map(
                          async nextGenSub =>
                            await updateNextGenSubscription({
                              subscriptionId: nextGenSub.subscriptionId,
                              term: NextgenSubscriptionTerm.Yearly
                            })
                        )
                      );
                    }}
                  >
                    Update
                  </WSButton.Link>
                </WSFlexBox.CenterY>
              )}
              <WSFlexBox justify="space-between" className={styles.membership}>
                {nextGenSubscriptions.length > 0 ? (
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan(s)
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {nextGenSubscriptions.map(nextGenSub => (
                        <WSText.ParagraphSm
                          color={
                            nextGenSub.cancelAtPeriodEnd ? "gray400" : "gray600"
                          }
                          mb="XS"
                          key={nextGenSub.subscriptionId}
                        >
                          {nextGenSub.plan.name} ({nextGenSub.term}){" "}
                          {nextGenSub.cancelAtPeriodEnd
                            ? `-- will be canceled on ${toWSDate(
                                nextGenSub.currentPeriodEnd,
                                "monthDayYear"
                              )}`
                            : ""}
                        </WSText.ParagraphSm>
                      ))}
                    </WSText.ParagraphSm>
                  </WSElement>
                ) : (
                  // Individual is a FREE subscription
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {freePlan ? freePlan.name : "Free plan"}
                    </WSText.ParagraphSm>
                  </WSElement>
                )}
                <WSFlexBox.CenterY
                  direction="column"
                  alignItems={isMobile ? "flex-start" : "flex-end"}
                  mt={isMobile ? "XL" : "NONE"}
                  className={styles.btnContainer}
                >
                  <WSButton
                    name="gotoPlans"
                    mb="M"
                    onClick={() =>
                      history.push("/member/USA/subscription-plans")
                    }
                  >
                    {nextGenSubscriptions.length > 0
                      ? "Change plan"
                      : "Explore plans"}
                  </WSButton>
                </WSFlexBox.CenterY>
              </WSFlexBox>
              {updateNextGenSubscriptionMeta.error ? (
                <WSText.ParagraphSm weight="medium" mt="M" color="garnet">
                  {String(
                    updateNextGenSubscriptionMeta.error?.response?.data?.error
                  )}
                </WSText.ParagraphSm>
              ) : null}
            </WSFlexBox>
            <CancelMembershipModal />
            <CancelNextGenSubscriptionsModal />
            <NextGenSubscriptionPaymentMethod
              paymentMethod={client.profile.subscriptionPaymentMethod}
            />
          </WSElement>
        );
      }}
    </WSQueries>
  );
};

export const MembershipSettingSection: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { openModal } = useModalContext();

  const userId = useUserId();
  const qSubscription = useSubscriptionV3Query(userId);
  const qPaymentMethod = useGetPaymentMethod();
  const qSubscriptionPackages = useSubscriptionPackages();
  const qGrantedSubscriberList = useGrantedSubscriberListQuery();
  const appliedSubscriptionGrant = (qGrantedSubscriberList.data ?? []).find(
    sg => sg.subscriptionGrantId === qSubscription.data?.subscriptionGrantId
  );
  const qPublicUser = useUserById(
    appliedSubscriptionGrant?.grantorId as string,
    {
      enabled: !!appliedSubscriptionGrant?.grantorId
    }
  );

  const [createOrUpdateSubscription, createOrUpdateSubscriptionMeta] =
    useCreateOrUpdateSubscriptionV3(userId);

  return (
    <WSQueries
      queries={{ qSubscriptionPackages }}
      renderLoader={() => <SettingsLoader />}
    >
      {({ qSubscriptionPackages: { data: nextGenSubscriptionPlans } }) => {
        const subscription = qSubscription.data as ISubscription;
        const currentPackage = getCurrentPackage(
          subscription
        ) as PackageTierTerm;
        let subscriptionWithAmount = null;
        if (subscription) {
          const pkg = SUBSCRIPTION_PACKAGES_ADDONS.find(
            sub =>
              sub.package === currentPackage?.package &&
              sub.tier === currentPackage.packageTier
          );
          const termData =
            nextGenSubscriptionPlans[currentPackage.package][
              currentPackage.packageTier
            ][currentPackage.term];
          subscriptionWithAmount = {
            ...termData,
            ...pkg
          };
        }
        return (
          <WSElement>
            <WSFlexBox direction="column">
              {subscription &&
                subscription.subscriptionGrantId &&
                qPublicUser.isSuccess && (
                  <WSFlexBox.CenterY
                    py="XS"
                    px="M"
                    justify="space-between"
                    className={styles.savingTip}
                    mb="XL"
                  >
                    <WSText color="gray600">
                      🎁 Paid membership sponsored by{" "}
                      {getAuthorizedAccountName(
                        qPublicUser.data?.member,
                        qPublicUser.data?.user as INewUser | IRedactedUser
                      )}
                    </WSText>
                  </WSFlexBox.CenterY>
                )}
              {subscription &&
                !subscription.subscriptionGrantId &&
                currentPackage.term === SubscriptionTerm.Monthly && (
                  <WSFlexBox.CenterY
                    py="XS"
                    px="M"
                    justify="space-between"
                    className={styles.savingTip}
                    mb="XL"
                  >
                    <WSText color="gray600">
                      Switch to annual billing and save 33%
                    </WSText>
                    <WSButton.Link
                      name="updateAnnualBilling"
                      loading={createOrUpdateSubscriptionMeta.isLoading}
                      onClick={async () => {
                        createOrUpdateSubscriptionMeta.reset();
                        await createOrUpdateSubscription({
                          package: currentPackage.package,
                          packageTier: currentPackage.packageTier,
                          term: SubscriptionTerm.Yearly
                        });
                      }}
                    >
                      Update
                    </WSButton.Link>
                  </WSFlexBox.CenterY>
                )}
              <WSFlexBox justify="space-between" className={styles.membership}>
                {subscription && subscriptionWithAmount ? (
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {subscriptionWithAmount.title}
                    </WSText.ParagraphSm>

                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionTerm"
                    >
                      {(function () {
                        if (
                          subscription &&
                          subscription.subscriptionGrantId &&
                          qPublicUser.isSuccess
                        ) {
                          return `Sponsored by ${getAuthorizedAccountName(
                            qPublicUser.data?.member,
                            qPublicUser.data?.user as INewUser | IRedactedUser
                          )}`;
                        } else if (
                          currentPackage.term === SubscriptionTerm.Monthly
                        ) {
                          return "Billed monthly";
                        }
                        return "Billed annually";
                      })()}
                    </WSText.ParagraphSm>
                  </WSElement>
                ) : (
                  // Individual is a FREE subscription
                  <WSElement>
                    <WSText.ParagraphSm
                      color="gray500"
                      mt="XL"
                      data-testid="subscriptionPackageLabel"
                    >
                      Plan
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm
                      weight="medium"
                      color="gray700"
                      mt="XL"
                      data-testid="subscriptionPackage"
                    >
                      {SUBSCRIPTION_PACKAGES_ADDONS[0].title}
                    </WSText.ParagraphSm>
                  </WSElement>
                )}
                <WSFlexBox.CenterY
                  direction="column"
                  alignItems={isMobile ? "flex-start" : "flex-end"}
                  mt={isMobile ? "XL" : "NONE"}
                  className={styles.btnContainer}
                >
                  {subscription && !isProfessionalSubscription(subscription) ? (
                    <WSButton
                      name="upgradeSubscription"
                      mb="M"
                      onClick={async () => {
                        history.push("/member/membership-plans");
                      }}
                      icon="reward"
                      loading={createOrUpdateSubscriptionMeta.isLoading}
                    >
                      Explore plans
                    </WSButton>
                  ) : (
                    <WSButton
                      name="gotoPlans"
                      mb="M"
                      onClick={() => history.push("/member/membership-plans")}
                    >
                      {hasSubscription(subscription)
                        ? "Change plan"
                        : "Explore plans"}
                    </WSButton>
                  )}
                  {subscription && !isSubscriptionCanceled(subscription) ? (
                    <WSButton.Link
                      name="cancelSubscription"
                      onClick={() => openModal("cancel-subscription")}
                    >
                      Cancel membership
                    </WSButton.Link>
                  ) : null}
                </WSFlexBox.CenterY>
              </WSFlexBox>
              {createOrUpdateSubscriptionMeta.error ? (
                <WSText.ParagraphSm weight="medium" mt="M" color="garnet">
                  {String(
                    createOrUpdateSubscriptionMeta.error?.response?.data?.error
                  )}
                </WSText.ParagraphSm>
              ) : null}
            </WSFlexBox>
            <CancelMembershipModal />
            <WSDivider my="L" />
            <MembershipPaymentMethod card={qPaymentMethod.data} />

            {subscription &&
              subscriptionWithAmount &&
              !subscription.subscriptionGrantId && (
                <WSFlexBox justify="space-between" alignItems="center">
                  <WSElement>
                    <WSText.ParagraphSm color="gray500" mt="XL">
                      Billing
                    </WSText.ParagraphSm>

                    {!subscription.cancelAtPeriodEnd &&
                      subscription.status !== SubscriptionStatus.canceled && (
                        <WSText.ParagraphSm color="gray500" mt="M">
                          Your next bill is for{" "}
                          <WSText.ParagraphSm
                            weight="medium"
                            color="gray700"
                            inline
                          >
                            ${subscription.nextBillingAmount} on{" "}
                            <WSText.ParagraphSm
                              weight="medium"
                              inline
                              formatDate="monthDayYear"
                            >
                              {subscription.currentPeriodEnd}
                            </WSText.ParagraphSm>
                            .
                          </WSText.ParagraphSm>
                        </WSText.ParagraphSm>
                      )}
                  </WSElement>
                  <WSButton.Link
                    onClick={() =>
                      history.push("/member/membership-billing-history")
                    }
                  >
                    View billing history
                  </WSButton.Link>
                </WSFlexBox>
              )}
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
