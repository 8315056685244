import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";

import logoWingspan from "../../../../assets/images/partners/wLogo.png";
import { parse } from "date-fns";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { getUserAddressValue } from "../../utils/getUserAddress";
import { pdfHeaderInfo } from "./constants";
import {
  IStatementEntryResponse,
  IStatementResponse
} from "../../../../services/api/banking/statements/types";
import { IMember } from "@wingspanhq/users";
import {
  toWSDateString,
  toWSMoney,
  WSDateFormatsType
} from "@wingspanhq/fe-component-library";
import { formatStatementsMoney } from "../../utils/formatStatementsMoney";
import { BankingInternalAccount } from "../../../../services/api/banking/internalAccounts";
import { accountTypeToHumanReadable } from "../../utils/accountTypeToHumanReadable";
import { getValidDescription } from "../../utils/getValidDescription";

enum StatementEntryDirection {
  Debit = "Debit",
  Credit = "Credit"
}

Font.register({
  family: "Centra No2",
  fonts: [
    {
      src: require("../../../../assets/fonts/CentraNo2-Book.woff"),
      fontWeight: 400,
      fontStyle: "normal"
    },
    {
      src: require("../../../../assets/fonts/CentraNo2-Medium.woff"),
      fontWeight: 500,
      fontStyle: "normal"
    }
  ]
});

const borderColorGrey = "#ddd";
const bgColorGrey = "#F9FAFB";

const dateFormat: WSDateFormatsType = "monthDayYear";
const dateParseFormat = "yyyy-MM-dd";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    lineHeight: 1.9,
    fontFamily: "Centra No2"
  },
  text: {
    fontSize: 10
  },
  textBold: {
    fontSize: 10,
    fontWeight: 500
  },
  head: {
    display: "flex",
    flexDirection: "row",
    padding: 20,
    width: "100%",
    border: `1px solid ${borderColorGrey}`,
    borderRadius: 8,
    gap: 60
  },
  headSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 40
  },
  headInfoSection: {
    maxWidth: "35%",
    display: "flex",
    flexDirection: "column"
  },
  logo: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 10
  },
  sectionRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "space-between"
  },
  subsectionRow: {
    display: "flex",
    flexDirection: "column"
  },
  accountInfo: {
    display: "flex",
    flexDirection: "row",
    gap: 15
  },
  customerInformation: {
    maxWidth: "20%"
  },
  summarySection: {
    marginTop: 30,
    display: "flex",
    maxWidth: 250,
    flexDirection: "column"
  },
  summarySectionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  sectionTitle: {
    fontSize: 10,
    fontWeight: 600
  },
  tableSection: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column"
  },
  divider: {
    width: "100%",
    height: 1,
    borderTop: `1px solid ${borderColorGrey}`,
    marginTop: 10,
    marginBottom: 10
  },
  table: {
    width: "100%",
    borderRadius: 4,
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    overflow: "hidden",
    lineHeight: 1.2 // line height of the table rows
  },
  tableRowFirst: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    border: `1px solid ${borderColorGrey}`,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  tableRow: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    borderBottom: `1px solid ${borderColorGrey}`,
    borderRight: `1px solid ${borderColorGrey}`,
    borderLeft: `1px solid ${borderColorGrey}`
  },
  tableRowLast: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderBottom: `1px solid ${borderColorGrey}`,
    borderRight: `1px solid ${borderColorGrey}`,
    borderLeft: `1px solid ${borderColorGrey}`
  },
  tableCell: {
    padding: 8,
    textAlign: "left",
    flexBasis: "15%",
    flexWrap: "nowrap",
    fontSize: 8,
    flexGrow: 1
  },
  tableCellDescription: {
    padding: 8,
    textAlign: "left",
    flexBasis: "40%",
    flexWrap: "nowrap",
    fontSize: 8,
    flexGrow: 1
  },
  grayRaw: {
    backgroundColor: `${bgColorGrey}`
  },
  textAlignR: {
    textAlign: "right"
  },
  marginTop: {
    marginTop: "30px"
  },
  pageNumbers: {
    position: "absolute",
    bottom: 10,
    right: 50,
    textAlign: "center",
    fontSize: 10,
    color: `${borderColorGrey}`
  },
  displayB: {
    display: "block" as any
  }
});

export type StatementPDFDocumentProps = {
  statement: IStatementResponse;
  member: IMember;
  internalAccount: BankingInternalAccount;
};

const TableHeader = () => {
  return (
    <View
      style={{
        ...styles.tableRowFirst,
        ...styles.grayRaw
      }}
      wrap={false}
    >
      <Text style={{ ...styles.textBold, ...styles.tableCell }}>Date</Text>
      <Text style={{ ...styles.textBold, ...styles.tableCellDescription }}>
        Description
      </Text>
      <Text style={{ ...styles.textBold, ...styles.tableCell }}>
        Withdrawal
      </Text>
      <Text style={{ ...styles.textBold, ...styles.tableCell }}>Deposit</Text>
      <Text
        style={{
          ...styles.textBold,
          ...styles.tableCell,
          ...styles.textAlignR
        }}
      >
        Balance
      </Text>
    </View>
  );
};

export const StatementPDFDocument: React.FC<StatementPDFDocumentProps> = ({
  statement,
  member,
  internalAccount
}) => {
  const userName = wsName({
    user: member.user,
    member: member
  }).getResolvedName();
  const userAddress = getUserAddressValue(member.profile.address!);
  const wingspanAddress = getUserAddressValue(pdfHeaderInfo.wingspan.address);
  const statementEntries = (statement.entries ||
    []) as IStatementEntryResponse[];

  return (
    <Document>
      <Page size="A4" wrap style={styles.page}>
        {/*//* Head Section Start */}
        {/* <View style={styles.head}>
          <View style={styles.headSection}>
            <Image style={{ width: 80, height: 20 }} src={logoLead} />
            <View style={styles.headInfoSection}>
              <Text style={{ ...styles.textBold, ...styles.displayB }}>
                {pdfHeaderInfo.leadBank.name}
              </Text>
              <Text style={styles.text}>{pdfHeaderInfo.leadBank.address}</Text>
              <Text style={styles.text}>{pdfHeaderInfo.leadBank.phone}</Text>
            </View>
          </View>
          <View style={styles.headSection}>
            <Image style={{ width: 55, height: 20 }} src={logoWingspan} />
            <View style={styles.headInfoSection}>
              <Text style={{ ...styles.textBold, ...styles.displayB }}>
                {pdfHeaderInfo.wingspan.name}
              </Text>
              <Text style={styles.text}>{pdfHeaderInfo.wingspan.address}</Text>
              <Text style={styles.text}>{pdfHeaderInfo.wingspan.phone}</Text>
            </View>
          </View>
        </View> */}
        <View style={styles.head}>
          <View style={styles.headSection}>
            <Image style={{ width: 117, height: 18 }} src={logoWingspan} />
            <View style={styles.headInfoSection}>
              <Text style={{ ...styles.text, ...styles.displayB }}>
                {wingspanAddress.firstLine}
              </Text>
              {wingspanAddress.secondLine && (
                <Text style={{ ...styles.text, ...styles.displayB }}>
                  {wingspanAddress.secondLine}
                </Text>
              )}
              <Text style={{ ...styles.text, ...styles.displayB }}>
                {wingspanAddress.thirdLine}
              </Text>
            </View>
            <View style={styles.headInfoSection}>
              <Text style={{ ...styles.text, ...styles.displayB }}>
                {pdfHeaderInfo.wingspan.email}
              </Text>
              <Text style={{ ...styles.text, ...styles.displayB }}>
                {pdfHeaderInfo.wingspan.phone}
              </Text>
            </View>
          </View>
        </View>
        {/*//* Head Section End */}

        <View style={styles.sectionRow}>
          <View style={styles.customerInformation}>
            <Text style={{ ...styles.text, ...styles.displayB }}>
              {userName}
            </Text>
            <Text style={styles.text}>{userAddress.firstLine}</Text>
            {userAddress.secondLine && (
              <Text style={styles.text}>{userAddress.secondLine}</Text>
            )}
            <Text style={styles.text}>{userAddress.thirdLine}</Text>
          </View>

          <View style={styles.accountInfo}>
            <View style={styles.subsectionRow}>
              <Text style={styles.text}>Statement of Account</Text>
              <Text style={styles.text}>Statement Date</Text>
              <Text style={styles.text}>Account Type:</Text>
              <Text style={styles.text}>Account Number:</Text>
              <Text style={styles.text}>Statement Period:</Text>
            </View>
            <View style={styles.subsectionRow}>
              <Text
                style={styles.text}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
              />
              <Text style={styles.text}>
                {toWSDateString(new Date(), dateFormat)}
              </Text>
              <Text style={styles.text}>
                {accountTypeToHumanReadable(statement.internalAccountType)}
              </Text>
              <Text style={styles.text}>
                {internalAccount.numbers?.account}
              </Text>
              <Text style={styles.text}>
                {toWSDateString(
                  parse(statement.startDateString, dateParseFormat, new Date()),
                  dateFormat
                )}
                {" - "}
                {toWSDateString(
                  parse(statement.endDateString, dateParseFormat, new Date()),
                  dateFormat
                )}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.summarySection}>
          <View style={styles.summarySectionRow}>
            <Text style={styles.sectionTitle}>Summary</Text>
          </View>
          <View style={styles.summarySectionRow}>
            <Text style={styles.text}>Opening Balance:</Text>
            <Text style={{ ...styles.text, ...styles.textBold }}>
              {toWSMoney(statement?.openingBalance).formattedValue}
            </Text>
          </View>
          <View style={styles.summarySectionRow}>
            <Text style={styles.text}>Total Withdrawals:</Text>
            <Text style={styles.text}>
              {toWSMoney(statement?.totalWithdrawals).formattedValue}
            </Text>
          </View>
          <View style={styles.summarySectionRow}>
            <Text style={styles.text}>Total Deposits:</Text>
            <Text style={styles.text}>
              {toWSMoney(statement?.totalDeposits).formattedValue}
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.summarySectionRow}>
            <Text style={styles.text}>Ending Balance:</Text>
            <Text style={{ ...styles.text, ...styles.textBold }}>
              {toWSMoney(statement?.endingBalance).formattedValue}
            </Text>
          </View>
        </View>

        {/*//* Table Start */}
        <View style={styles.tableSection}>
          <Text style={styles.sectionTitle}>Transactions</Text>

          <View style={styles.table}>
            <View fixed render={({ pageNumber }) => <TableHeader />}></View>
            {/*//* Table Body Start */}
            {statementEntries.map(
              (transaction: IStatementEntryResponse, i: number) => {
                const isLastEl = i === statementEntries.length - 1;
                const isEven = i % 2 === 0;

                return (
                  <View
                    style={{
                      ...(isLastEl ? styles.tableRowLast : styles.tableRow),
                      ...(isEven ? {} : styles.grayRaw)
                    }}
                    wrap={false}
                  >
                    <Text style={styles.tableCell}>
                      {toWSDateString(
                        parse(
                          transaction.localDateString,
                          dateParseFormat,
                          new Date()
                        ),
                        dateFormat
                      )}
                    </Text>
                    <Text style={styles.tableCellDescription}>
                      {getValidDescription(transaction.description)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {transaction.direction === StatementEntryDirection.Debit
                        ? `${formatStatementsMoney(transaction.amount)}`
                        : ""}
                    </Text>
                    <Text style={styles.tableCell}>
                      {transaction.direction === StatementEntryDirection.Credit
                        ? formatStatementsMoney(transaction.amount)
                        : ""}
                    </Text>
                    <Text style={{ ...styles.tableCell, ...styles.textAlignR }}>
                      {formatStatementsMoney(transaction.balance)}
                    </Text>
                  </View>
                );
              }
            )}
            {/*//* Table Body End */}
          </View>
        </View>
        {/*//* Table End */}

        {/*//*Last Page Footer Start */}
        <View style={styles.marginTop}>
          <Text style={styles.text}>
            Wingspan is a financial technology company, not a bank. Banking
            services are provided by Lead Bank, Member FDIC.
          </Text>
        </View>
        {/*//* Last Page  End */}

        {/*//*Every Page Footer Start */}
        <Text
          style={{ ...styles.pageNumbers, ...styles.text }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
      {/*//*Every Page Footer Start */}
    </Document>
  );
};
