import {
  toWSDateString,
  WSAlert,
  WSButton,
  WSContainer,
  WSDivider,
  WSElement,
  WSEmptyState,
  WSGrid,
  WSLayout,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { Summary } from "../../components/Summary";
import React from "react";
import { MainTaxDocumentInfo } from "../../components/MainTaxDocumentInfo";
import { useTaxFormBySecureLink } from "../../../../query/taxFormSubmissions/queries/useTaxFormBySecureLink";
import { LogoApp } from "../../../../shared/components/LogoApp";
import { getExpirationDate } from "../../../TaxFiling/utils/secureLink";
import { downloadFileFromBlob } from "../../../../utils/files";
import { useDownloadTaxFormBySecureLink } from "../../../../query/taxFormSubmissions/mutations/useDownloadTaxFormBySecureLink";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { getFormFileName } from "../../../TaxFiling/utils/formFileName";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

export const RoutePreviewBySecureLink: React.FC<
  RouteComponentProps<{
    token: string;
  }>
> = ({
  match: {
    params: { token }
  }
}) => {
  const history = useHistory();
  const queryTaxFormSubmission = useTaxFormBySecureLink(token);
  const [download, downloadMeta] = useDownloadTaxFormBySecureLink();

  return (
    <WSPage>
      <WSLayout line headerCenter={<LogoApp />}>
        <WSContainer>
          <WSQueries
            queries={{ queryTaxFormSubmission }}
            renderErrors={() => (
              <WSEmptyState
                theme="garnet"
                title="Link is expired or invalid"
                type="error"
                description="We couldn't find the tax form you're looking for. Please check the link or contact the payer for assistance."
              />
            )}
          >
            {({ queryTaxFormSubmissionData }) => {
              const submission = queryTaxFormSubmissionData;
              const payerName = submission.payerData.name;
              const expirationDateView = toWSDateString(
                getExpirationDate(submission.secureSharing?.createdAt!),
                "monthDayYear"
              );

              return (
                <>
                  <WSAlert
                    my="XL"
                    title="Payer provided secure share link"
                    theme="info"
                    icon="info-circle"
                  >
                    The payer shared this page via a secure share link and
                    expire by {expirationDateView}. If you were invited by the
                    payer to access Wingspan, sign-up for an account to take
                    additional actions such as requesting a correction. If you
                    need additional support from the payer such as a new
                    Wingspan sign-up link or to correct an error for you, please
                    reach out to the payer directly.
                  </WSAlert>
                  <WSPageToolbar
                    title={payerName}
                    titleAfter={
                      <WSPill
                        text={`${submission.year} Form 1099-NEC`}
                        theme="neutral"
                      />
                    }
                    actions={[
                      {
                        label: "Download PDF",
                        buttonKind: "Secondary",

                        loading: downloadMeta.isLoading,
                        onClick: async () => {
                          await download(
                            {
                              token
                            },
                            {
                              onSuccess(pdf) {
                                const formPayeeFileName = getFormFileName({
                                  viewer: TaxFormViewer.payee,
                                  year: submission.year || 2024,
                                  payerName:
                                    submission.payerData
                                      .disregardedEntityName ??
                                    submission.payerData.name,
                                  payeeW9: submission.data?.w9Info
                                });

                                downloadFileFromBlob(pdf, formPayeeFileName);
                              },
                              async onError(err) {
                                const data = err.response?.data as any as Blob;
                                if (data) {
                                  const rawError = JSON.parse(
                                    await data.text()
                                  );
                                  console.log(rawError);
                                }
                              }
                            }
                          );
                        }
                      }
                    ]}
                  />

                  <WSGrid gutter="2XL">
                    <WSGrid.Item span={{ s: "12", m: "8" }}>
                      <WSList gap="2XL">
                        <MainTaxDocumentInfo
                          submission={submission}
                          secureLink={true}
                        />
                        <Summary submission={submission} />
                      </WSList>
                    </WSGrid.Item>
                    <WSGrid.Item span={{ s: "12", m: "4" }}>
                      <WSElement>
                        <WSText.ParagraphSm color="gray400">
                          Already have an account?
                        </WSText.ParagraphSm>
                        <WSDivider my="M" />
                        <WSButton.Link
                          size="S"
                          onClick={() =>
                            openInNewTab(
                              history.createHref({
                                pathname: "/member/sign-in"
                              })
                            )
                          }
                          rightIcon="open-tab"
                        >
                          Sign-in to Wingspan
                        </WSButton.Link>
                      </WSElement>
                    </WSGrid.Item>
                  </WSGrid>
                </>
              );
            }}
          </WSQueries>
        </WSContainer>
      </WSLayout>
    </WSPage>
  );
};
