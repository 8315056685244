import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../../TaxCorrections/constants/corrections";
import { isNotNil } from "../../../../../utils";
import { CorrectionType } from "@wingspanhq/payments/dist/interfaces";

export function getCorrectionTypeFromCorrectionRequest(
  taxForm: IPayerTaxFormResponse
) {
  const { pendingCorrection: correctionRequest } = taxForm;
  const corrections = [];

  // type1 correction
  if (isNotNil(correctionRequest?.data?.totalAmount)) {
    corrections.push(CORRECTION_AMOUNT);
  }

  // type2 correction -- tin or name change
  if (
    correctionRequest?.data?.w9Info?.ssn ||
    correctionRequest?.data?.w9Info?.ssnLastFour ||
    correctionRequest?.data?.w9Info?.firstName ||
    correctionRequest?.data?.w9Info?.lastName ||
    correctionRequest?.data?.w9Info?.ein ||
    correctionRequest?.data?.w9Info?.einLastFour ||
    correctionRequest?.data?.w9Info?.legalBusinessName ||
    correctionRequest?.data?.w9Info?.disregardedEntityName ||
    correctionRequest?.data?.w9Info?.companyStructure
  ) {
    corrections.push(CORRECTION_TIN_OR_NAME);
  }

  // type2 correction -- address change
  if (
    correctionRequest?.data?.w9Info?.addressLine1 ||
    correctionRequest?.data?.w9Info?.addressLine2 ||
    correctionRequest?.data?.w9Info?.city ||
    correctionRequest?.data?.w9Info?.state ||
    correctionRequest?.data?.w9Info?.postalCode ||
    correctionRequest?.data?.w9Info?.country
  ) {
    corrections.push(CORRECTION_ADDRESS);
  }

  // void correction -- invalid company structure ?+ some type2 fields
  const {
    totalAmount,
    w9Info: { companyStructure = undefined, ...restW9Info } = {}
  } = correctionRequest?.data ?? {};
  const isVoidType = correctionRequest?.correctionType === CorrectionType.Void;
  const isCompanyChangeOnly =
    !!companyStructure &&
    totalAmount === undefined &&
    Object.keys(restW9Info ?? {}).length === 0;

  return {
    corrections,
    isVoidType,
    isCompanyStructureChangeOnly: isVoidType && isCompanyChangeOnly
  };
}
