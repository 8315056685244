import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSList,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { ContractorDetails } from "./ContractorDetails";
import { ContactInformation } from "./ContactInformation";

type Props = {
  taxForm: IPayerTaxFormResponse;
  onEdit?: () => void;
  onRevert?: () => void;
};

export const RecipientDetails: React.FC<Props> = ({
  taxForm,
  onEdit,
  onRevert
}) => {
  return (
    <WSList gap="2XL">
      <WSElement>
        <WSFlexBox.CenterY mb="XL" wrap="nowrap" justify="space-between">
          <WSElement>
            <WSText weight="medium" inline>
              Contractor details
            </WSText>
            {taxForm.recipientSyncEnabled === false ? (
              <WSPill ml="M" text="Edited by you" theme={"violet"} />
            ) : null}
          </WSElement>
          {onEdit && <WSButton.Link onClick={onEdit}>Edit</WSButton.Link>}
        </WSFlexBox.CenterY>
        <WSPanel>
          <WSList gap="2XL">
            <ContractorDetails taxForm={taxForm} />
          </WSList>
        </WSPanel>
      </WSElement>

      <WSElement>
        <WSText weight="medium" mb="XL">
          Contact information
        </WSText>
        <WSPanel>
          <ContactInformation taxForm={taxForm} />
        </WSPanel>
      </WSElement>
    </WSList>
  );
};
