import {
  WSButton,
  WSFlexBox,
  WSIconName,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";
import React from "react";
import { useHistory } from "react-router-dom";

const Links: React.FC<{
  links: {
    label: string;
    action: string;
    onClick: () => void;
    icon: WSIconName;
  }[];
}> = ({ links }) => {
  return (
    <WSList gap="M">
      {links.map(({ label, action, onClick, icon }) => (
        <WSFlexBox.CenterY key={action}>
          <WSText.ParagraphSm color="gray500">{label}</WSText.ParagraphSm>
          <WSButton.Link
            size="S"
            icon={icon}
            rightIcon="chevron-right"
            onClick={onClick}
          >
            {action}
          </WSButton.Link>
        </WSFlexBox.CenterY>
      ))}
    </WSList>
  );
};

export const GenerateTaxFormsInfo: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <WSList gap="XL">
      <WSInfoBox title="Reminder">
        <ul>
          <li>
            Tax forms are generated for all contractors but forms can only be
            filed when the eligibility criteria for tax filing is met.
          </li>
          <li>
            When tax forms are generated, please check that all intended
            contractors have been added (or uploaded) successfully and ready for
            tax filing.
          </li>
          <li>
            Tax form details including taxpayer and payments information can be
            updated until the form is filed.
          </li>
          <li>
            New tax forms will be generated for newly added contractors without
            tax forms. Existing tax forms with sync enabled, will be updated
            with any new changes since the last sync.
          </li>
        </ul>
      </WSInfoBox>

      <Links
        links={[
          {
            label: "Learn more about tax filing?",
            action: "1099-NEC tax filing",
            onClick: () => {
              openInNewTab(
                "https://docs.wingspan.app/docs/1099-nec-tax-filing-overview"
              );
            },
            icon: "upload"
          },
          {
            label: "Looking for a contractor?",
            action: "See contractors",
            onClick: () => {
              history.push("/member/payees");
            },
            icon: "user"
          },
          {
            label: "Looking to bulk upload contractors?",
            action: "Bulk upload contractors",
            onClick: () => {
              history.push("/member/imports/collaborators");
            },
            icon: "upload"
          },
          {
            label: "Looking to bulk upload payments?",
            action: "Bulk upload payments",
            onClick: () => {
              history.push("/member/imports/payables");
            },
            icon: "upload"
          }
        ]}
      />
    </WSList>
  );
};
