import {
  toWSDateString,
  useWSModal,
  WSActions,
  WSAlert,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import {
  copyTaxFormSubmissionSecureLink,
  getExpirationDate
} from "../../../utils/secureLink";
import { useCreateTaxFormSubmissionSecureLink } from "../../../../../query/taxFormSubmissions/mutations/useCreateTaxFormSubmissionSecureLink";
import useSnackbar from "../../../../../hooks/useSnackbar";

const GenerateSecureLink: React.FC<{
  taxFormId: string;
  submissionId: string;
  onClose(result: boolean): void;
}> = ({ onClose, taxFormId, submissionId }) => {
  const [createSecureLink, createSecureLinkMeta] =
    useCreateTaxFormSubmissionSecureLink();

  const { success } = useSnackbar();

  return (
    <WSList gap="L">
      <WSText.ParagraphSm color="gray600">
        Are you sure you want to generate a secure link?
      </WSText.ParagraphSm>

      <WSAlert title="Secure link reminders:" icon="info-circle" theme="info">
        <TextBulleted>
          <li>
            The secure link will expire in 7 days (by{" "}
            {toWSDateString(getExpirationDate(new Date()), "monthDate")}).
          </li>
          <li>
            The secure link will share a page with sensitive information, please
            ensure you are sending it to the intended recipient.
          </li>
          <li>
            The secure link is view only and allows the recipient to download a
            PDF of the form.
          </li>
        </TextBulleted>
      </WSAlert>

      <WSInfoBox>
        If you’re looking to remind the contractor to sign-up or sign-in to
        Wingspan to view their forms, please contact them directly. Advanced
        actions such as correction requests are available through the
        contractor’s Wingspan account only.
      </WSInfoBox>
      <WSErrorMessage error={createSecureLinkMeta.error} contextKey="Other" />
      <WSActions
        alignment="fill"
        buttons={[
          {
            kind: "Primary",
            onAsyncClick: async () => {
              await createSecureLink(
                {
                  taxFormId,
                  submissionId
                },
                {
                  throwOnError: true,
                  onSuccess(submission) {
                    copyTaxFormSubmissionSecureLink(
                      submission.secureSharing?.token!
                    );
                    success("Secure link copied to clipboard");
                  }
                }
              );
              onClose(true);
            },
            label: "Confirm"
          },
          {
            kind: "Secondary",
            label: "Cancel",
            onClick: () => onClose(false)
          }
        ]}
      />
    </WSList>
  );
};

export const useModalGenerateSecureLink = () =>
  useWSModal(GenerateSecureLink, {
    size: "S",
    title: "Generate secure share link"
  });
