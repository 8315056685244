import {
  IPayeeTaxFormResponse,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormCurrentCorrection = (
  taxForm: IPayeeTaxFormResponse
) => {
  return taxForm.corrections?.find(correction => {
    const isOwnedByPayee = correction.ownedBy === TaxFormViewer.payee;
    const isCreatedByPayee =
      correction.eventActors.createdBy === taxForm.memberId;

    return isOwnedByPayee || isCreatedByPayee;
  });
};
