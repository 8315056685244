import {
  useIsDesktop,
  useIsMobile,
  useIsTablet,
  useWSModal,
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSIcon,
  WSText,
  WSTooltip
} from "@wingspanhq/fe-component-library";
import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import { formatMoney } from "accounting";
import React, { useEffect, useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Overlay } from "../../../../components/Overlay";
import { RefreshModal } from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import {
  InvoicesFormStep1Values,
  useInvoicesFormContext
} from "../InvoicesForm";
import { LineItem } from "./LineItem";
import { ModalMobileForm } from "./ModalMobileForm";
import { FormDataLineItem } from "./types";
import { useQuickbooksProps } from "./useQuickbooksProps";
import { calculateAmount, calculateTotalAmount } from "./utils";
import { setLocalStorageCurrency } from "./currencyConversionHelpers";

export type LineItemsSectionValues = {
  lineItems: FormDataLineItem[];
};

type Props = {
  isIntegrationQuickbooksItems?: boolean;
  isIntegrationQuickbooksAccounts?: boolean;
  disabled?: boolean;
  isCurrencyConversionActive?: boolean;
  forceMobileShow?: boolean;
  customFields?: ICustomField[];
} & WSElementProps;

export const LineItemsSection: React.FC<Props> = ({
  isIntegrationQuickbooksAccounts,
  isIntegrationQuickbooksItems,
  disabled,
  customFields = [],
  isCurrencyConversionActive,
  forceMobileShow,
  ...elementProps
}) => {
  const { control, setValue, getValues } =
    useFormContext<InvoicesFormStep1Values>();
  const {
    fields: lineItems,
    append,
    remove
  } = useFieldArray<FormDataLineItem>({
    control,
    name: "lineItems"
  });

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const modalMobileForm = useWSModal(ModalMobileForm);
  const { invoicingConfigForPayee } = useInvoicesFormContext();

  const tooltipIconRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (isMobile || isTablet || forceMobileShow) {
      const values = getValues();

      if (!values.lineItems?.[0]?.totalCost) {
        remove(0);
      }
    }
  }, [isMobile, isTablet, getValues, remove, forceMobileShow]);

  const quickbooksProps = useQuickbooksProps({
    isIntegrationQuickbooksItems,
    isIntegrationQuickbooksAccounts
  });

  return (
    <WSElement {...elementProps}>
      {disabled && <Overlay white overParent />}
      <RefreshModal />
      <WSElement mb="XS">
        <WSGrid gutter="M">
          <WSGrid.Item
            span={{ xs: "6", s: isCurrencyConversionActive ? "6" : "7" }}
          >
            <WSText.ParagraphSm color="gray500">Description</WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item
            span={{ xs: "6", s: isCurrencyConversionActive ? "6" : "5" }}
          >
            <WSFlexBox.CenterY wrap="nowrap">
              <WSText.ParagraphSm color="gray500" mr="S">
                Amount {isCurrencyConversionActive && "(USD)"}
              </WSText.ParagraphSm>
              {isCurrencyConversionActive && (
                <>
                  <WSIcon
                    ref={tooltipIconRef}
                    size="S"
                    color="gray500"
                    name="info-circle-fill"
                  />
                  <WSTooltip
                    dark
                    target={tooltipIconRef}
                    title="Invoicing is only available in U.S. Dollars (USD). Please enter the amount in USD or we’ll convert another currency to USD based on Google Finance’s latest exchange rates, subject to change."
                  />
                </>
              )}
            </WSFlexBox.CenterY>
          </WSGrid.Item>
        </WSGrid>
      </WSElement>
      <WSDivider mb="M" />
      {lineItems.map((lineItem, index) => (
        <LineItem
          key={lineItem.id}
          data={lineItem}
          index={index}
          isCurrencyConversionActive={isCurrencyConversionActive}
          forceMobileShow={forceMobileShow}
          onRemove={
            lineItems.length > 1
              ? () => {
                  setValue(`lineItems[${index}].remove`, "1");
                }
              : undefined
          }
          onRateChange={() => {
            const values = getValues();

            if (values.lineItems[index].rate !== "fixed") {
              setValue(
                `lineItems[${index}].totalCost`,
                calculateAmount(values.lineItems[index])
              );
            }
          }}
          onCurrencyChange={() => {
            const values = getValues();
            if (values.lineItems[index]?.currency === "USD") {
              setValue(`lineItems[${index}].conversionAmount`, 0);
            } else {
              setValue(
                `lineItems[${index}].conversionAmount`,
                values.lineItems[index].conversionAmount ||
                  values.lineItems[index].totalCost
              );
            }
            setValue(
              `lineItems[${index}].currency`,
              values.lineItems[index]?.currency || "USD"
            );
            setLocalStorageCurrency(values.lineItems[index]?.currency || "USD");
          }}
          quickbooksProps={quickbooksProps}
          customFields={customFields}
        />
      ))}
      <WSButton.Link
        size="M"
        mt="M"
        type="button"
        icon="plus-circle"
        onClick={() => {
          if (isDesktop && !forceMobileShow) {
            append({});
          } else {
            modalMobileForm.open({
              onSubmit: values => {
                append(values);
              },
              quickbooksProps,
              isCurrencyConversionActive,
              customFields: customFields,
              invoicingConfigForPayee
            });
          }
        }}
        name="addLineItem"
      >
        Add item
      </WSButton.Link>
      <WSFormOld.Error name="lineItems" />
      <WSDivider mt="M" mb="M" />
      <WSFormOld.Value name="lineItems">
        {(lineItems: FormDataLineItem[]) => {
          const total = calculateTotalAmount(lineItems);

          return (
            <>
              <WSElement>
                <WSGrid gutter="M">
                  <WSGrid.Item span={{ xs: "6", s: "7" }}>
                    <WSText.ParagraphSm color="gray500">
                      Amount due
                    </WSText.ParagraphSm>
                  </WSGrid.Item>
                  <WSGrid.Item span={{ xs: "6", s: "5" }}>
                    <WSText.Heading5 data-testid="totalAmount">
                      {total ? formatMoney(total) : "$—.——"}
                    </WSText.Heading5>
                  </WSGrid.Item>
                </WSGrid>
              </WSElement>
            </>
          );
        }}
      </WSFormOld.Value>
    </WSElement>
  );
};
