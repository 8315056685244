import { QueryConfig } from "react-query";
import {
  getEnabledAnonymousExperiments,
  getEnabledExperiments
} from "../services/experiments";
import { EnabledExperimentsResponse } from "../types/experiments";
import { WSServiceError } from "../utils/serviceHelper";
import { useWSQuery } from "./helpers";

export const QUERY_ANONYMOUS_EXPERIMENTS = "QUERY_ANONYMOUS_EXPERIMENTS";
export const QUERY_ENABLED_EXPERIMENTS = "QUERY_ENABLED_EXPERIMENTS";

export const useAnonymousExperiments = (
  queryConfig?: QueryConfig<EnabledExperimentsResponse, WSServiceError>
) => {
  return useWSQuery(
    QUERY_ANONYMOUS_EXPERIMENTS,
    async () => {
      try {
        return await getEnabledAnonymousExperiments();
      } catch {
        return [];
      }
    },
    {
      ...queryConfig,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
};

export const useEnabledExperiments = (
  queryConfig?: QueryConfig<EnabledExperimentsResponse, unknown>
) => {
  return useWSQuery(
    QUERY_ENABLED_EXPERIMENTS,
    async () => {
      try {
        return await getEnabledExperiments();
      } catch {
        return [];
      }
    },
    {
      ...queryConfig,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
};
