import { WSPanel, WSText } from "@wingspanhq/fe-component-library";

import { OriginalValuePreview } from "../OriginalValuePreview";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { CORRECTION_ADDRESS } from "../../constants/corrections";
import { IAddress } from "@wingspanhq/users/dist/lib";

export interface AddressCorrectionDiffPreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  onEdit?: () => void;
  originalAddress: IAddress;
  correctedAddress: IAddress;
}

export const AddressCorrectionDiffPreview: React.FC<
  AddressCorrectionDiffPreviewProps
> = ({ taxForm, taxFormViewer, originalAddress, correctedAddress, onEdit }) => {
  return (
    <WSPanel>
      <WSText.Paragraph mb="M" weight="medium">
        Address correction
      </WSText.Paragraph>
      <WSText.ParagraphSm mb="XL" color="gray500">
        {taxFormViewer === TaxFormViewer.Payee
          ? "My address is not accurate."
          : "The recipient's address is not accurate."}
      </WSText.ParagraphSm>

      <OriginalValuePreview
        taxForm={taxForm}
        correctionType={CORRECTION_ADDRESS}
      />

      <CorrectedValuePreview
        correctionType={CORRECTION_ADDRESS}
        taxForm={taxForm}
        taxFormViewer={taxFormViewer}
        onEdit={onEdit}
        correctionData={{
          addressCorrectionData: {
            address: correctedAddress
          }
        }}
      />
    </WSPanel>
  );
};
