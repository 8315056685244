import {
  toWSDateString,
  WSCard,
  WSGrid,
  WSInfoBox,
  WSList,
  WSPage,
  WSPageToolbar,
  WSText,
  WSTimeline
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useGoToTaxForm1099NECPreview,
  useGoToTaxFormDetails,
  useTaxFormSubmissionParamsIds
} from "../../paths";
import { getTaxFormExtendedBySubmission } from "../../utils/getTaxFormExtendedBySubmission";
import { getTimeLineList } from "./RouteTaxFormDetails/getTimelineItem";
import { useBreadcrumbs } from "./useBreadcrumbs";

export const RouteTaxFormHistory: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const { taxFormId, submissionId } = useTaxFormSubmissionParamsIds();
  const goToPreview = useGoToTaxForm1099NECPreview();
  const queryPayerTaxForm = usePayerTaxForm(taxFormId);
  const goToDetails = useGoToTaxFormDetails();

  const timelineItems = useMemo(() => {
    if (!queryPayerTaxForm.data) {
      return [];
    }

    const timelineItemsRawFiltered = getTimeLineList(queryPayerTaxForm.data);
    return timelineItemsRawFiltered;
  }, [queryPayerTaxForm.data]);

  const detailsBreadcrumbs = useBreadcrumbs(queryPayerTaxForm.data);

  const breadcrumbs = useMemo(() => {
    if (!detailsBreadcrumbs || detailsBreadcrumbs.length === 0) return;

    const updated = [...detailsBreadcrumbs];

    updated[updated.length - 1].onClick = () => {
      goToDetails(taxFormId, submissionId);
    };

    updated.push({
      label: "1099 history"
    });

    return updated;
  }, [detailsBreadcrumbs, goToDetails, submissionId, taxFormId]);

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryPayerTaxForm
        }}
      >
        {({ queryPayerTaxFormData }) => {
          const taxForm = getTaxFormExtendedBySubmission(
            queryPayerTaxFormData,
            submissionId
          );

          return (
            <>
              <WSPageToolbar
                breadcrumbs={breadcrumbs}
                title="1099 history"
                actions={[
                  {
                    label: "View Form 1099-NEC",
                    buttonKind: "Secondary",
                    onClick() {
                      goToPreview(taxFormId, submissionId);
                    }
                  }
                ]}
              />

              <WSGrid gutter="2XL">
                <WSGrid.Item span={{ m: "8" }}>
                  <WSList gap="2XL">
                    <WSCard
                      divider
                      header={{
                        label: {
                          text: "History"
                        }
                      }}
                    >
                      <WSTimeline items={timelineItems} />
                    </WSCard>

                    <WSText.ParagraphXs color="gray400" align="center">
                      All available history events shown
                    </WSText.ParagraphXs>

                    <WSInfoBox>
                      Note: Form 1099-NEC history events only exist from the tax
                      form generation date onward:{" "}
                      {toWSDateString(taxForm.createdAt, "monthDayYear")}.
                    </WSInfoBox>
                  </WSList>
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
