import {
  toWSMoneyString,
  WSButton,
  WSCard,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSInfoBox,
  WSList,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo } from "react";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import {
  createTaxFormSubmissionListRequest,
  getTaxFormSubmissionViewDefaultFilters
} from "../../../../../query/search/taxForm/queries/filtering";
import { TaxFormSubmissionRequest } from "../../../../../services/search";
import { useTaxFormSubmissionRowsQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";
import { WSQueries } from "../../../../../query/WSQuery";
import { EmptyComponent } from "../../../../../shared/components/EmptyComponent";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";
import { useGoToTaxFormDetails } from "../../../paths";

type Props = {
  taxForm: IPayerTaxFormResponse;
};

export const GroupedForms: React.FC<Props> = ({ taxForm }) => {
  const { groupSize, canViewAllFormsInGroup, isGrouped, groupIds } = taxForm;

  const currentYear = useCurrentTaxFilingYear();
  const goToTaxFormDetails = useGoToTaxFormDetails();

  const defaultFilters = getTaxFormSubmissionViewDefaultFilters();

  const currentRequest = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, {
      ...defaultFilters,
      groupIds
    });
  }, [groupIds]);

  const queryTaxFormSubmissionRows = useTaxFormSubmissionRowsQuery(
    currentRequest,
    {
      enabled: isGrouped && !!groupIds
    }
  );

  return (
    <WSQueries
      queries={{ queryTaxFormSubmissionRows }}
      renderLoader={EmptyComponent}
      renderErrors={EmptyComponent}
    >
      {({ queryTaxFormSubmissionRowsData }) => {
        const otherCount = groupSize ? groupSize - 1 : 0;
        const combinedTotal = canViewAllFormsInGroup
          ? queryTaxFormSubmissionRowsData.reduce((acc, taxFormSubmission) => {
              const total =
                selector1099NECTotal(taxFormSubmission?.taxForm) || 0;

              return acc + total;
            }, 0)
          : 0;

        return (
          <WSCard
            divider
            header={{
              label: {
                text: "Grouped forms"
              }
            }}
          >
            <WSList gap="XL">
              <WSInfoBox>
                This form is grouped with {otherCount} other form
                {otherCount > 1 ? "s" : ""} with the same Taxpayer
                Identification Number (TIN).
                {canViewAllFormsInGroup ? null : (
                  <> Only forms you have permissions to are shown here.</>
                )}
              </WSInfoBox>

              {queryTaxFormSubmissionRowsData.map(taxFormSubmission => {
                const name = selectorTaxFormRecipientName(taxFormSubmission);
                const nec1099Total = selector1099NECTotal(
                  taxFormSubmission.taxForm
                );

                return (
                  <WSElement key={taxFormSubmission.taxFormId}>
                    <WSText.ParagraphSm color="gray500" mb="XS">
                      {name}
                    </WSText.ParagraphSm>
                    <WSFlexBox.CenterY>
                      <WSText.ParagraphSm>
                        {nec1099Total ? toWSMoneyString(nec1099Total) : "$ --"}
                      </WSText.ParagraphSm>
                      <WSButton.Link
                        icon="view"
                        size="S"
                        onClick={() => {
                          goToTaxFormDetails(
                            taxFormSubmission.taxFormId,
                            taxFormSubmission.taxFormSubmissionId
                          );
                        }}
                      >
                        View
                      </WSButton.Link>
                    </WSFlexBox.CenterY>
                  </WSElement>
                );
              })}

              {combinedTotal ? (
                <>
                  <WSDivider />
                  <WSElement>
                    <WSText.ParagraphSm color="gray500" mb="XS">
                      Combined 1099-NEC total
                    </WSText.ParagraphSm>
                    <WSFlexBox.CenterY justify="space-between">
                      <WSText.ParagraphSm>
                        {toWSMoneyString(combinedTotal)}
                      </WSText.ParagraphSm>
                      {combinedTotal > 600 ? (
                        <WSPill text="Over $600 threshold" theme="neutral" />
                      ) : null}
                    </WSFlexBox.CenterY>
                  </WSElement>
                </>
              ) : null}
            </WSList>
          </WSCard>
        );
      }}
    </WSQueries>
  );
};
