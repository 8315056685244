import {
  WSActions,
  WSAlert,
  WSButton,
  WSCard,
  WSControl,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSInfoBox,
  WSList,
  WSSectionToolbar,
  WSTable,
  WSTableCell,
  WSText
} from "@wingspanhq/fe-component-library";
import { TaxFormStatus } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useUserId } from "../../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../../query/payments/queries";
import { useMemberProfile } from "../../../../../query/users/queries";
import { DataView } from "../../../../../shared/components/DataView";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Layout } from "../../../../../shared/components/Layout";
import { formatEIN } from "../../../../../shared/utils/formatEIN";
import { formatPhone } from "../../../../../shared/utils/formatPhone";
import { stateTaxIdMapToArray } from "../../../utils/stateTaxIdMapToArray";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { useTaxFormSubmissionRowsSummaryQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";
import {
  TAX_FILING_1099_FILING_SUBMISSION_EDIT_PAYER_INFO_PATH,
  TAX_FILING_1099_FILING_SUBMISSION_EDIT_STATE_FILING_PATH
} from "../../../paths";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";

export const RouteSubmit: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const history = useHistory();
  const userId = useUserId();
  const currentYear = useCurrentTaxFilingYear();
  const queryMemberProfile = useMemberProfile(userId);
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryTaxFormRowSearchCount = useTaxFormSubmissionRowsSummaryQuery({
    filter: {
      yearString: `${currentYear}`,
      searchableStatus: TaxFormStatus.ReadyToSubmitToIrs
    }
  });

  const [consent, setConsent] = useState(false);

  const member = queryMemberProfile.data;

  const taxFormsCount = queryTaxFormRowSearchCount.data?.listSize;

  const userStateTaxIds = stateTaxIdMapToArray(
    queryPayrollSettings.data?.calculationSettings1099?.stateTaxId ?? {}
  );

  const companyName = wsName({
    member: member,
    user: member?.user!
  }).getResolvedName();

  return (
    <Layout title="1099-NEC filing">
      <WSList gap="2XL">
        <WSElement>
          <WSSectionToolbar title="Ready to file?" />
          <WSText.ParagraphSm color="gray500">
            Here’s what we have. Please review to ensure the information is
            accurate before submitting to the IRS.
          </WSText.ParagraphSm>
        </WSElement>

        <DataView
          title="Business information"
          data={[
            {
              label: "Legal business name",
              value: member?.profile.company?.legalBusinessName
            },
            {
              label: "Employer Identification Number (EIN)",
              value: member?.profile.company?.taxId
                ? formatEIN(member.profile.company?.taxId)
                : undefined
            },
            {
              label: "Business email",
              value: member?.profile.company?.supportEmail
            },
            {
              label: "Business address",
              value: member?.profile.address
                ? [
                    member.profile.address.addressLine1,
                    member.profile.address.addressLine2,
                    [
                      member.profile.address.city,
                      member.profile.address.state,
                      member.profile.address.postalCode
                    ].join(" ")
                  ]
                    .filter(Boolean)
                    .join("\n")
                : undefined
            }
          ]}
        />

        <DataView
          header={{
            label: "Support information",
            value: {
              action: {
                label: "Edit",
                onClick: () => {
                  history.push(
                    TAX_FILING_1099_FILING_SUBMISSION_EDIT_PAYER_INFO_PATH
                  );
                }
              }
            }
          }}
          data={[
            {
              label: "Support email",
              value: member?.profile.company?.supportEmail
            },
            {
              label: "Support phone number",
              value: member?.profile.company?.supportPhoneNumber
                ? formatPhone(member.profile.company?.supportPhoneNumber)
                : "-"
            }
          ]}
        />

        <WSElement>
          <WSSectionToolbar title="Filing details" />
          <WSText.ParagraphSm color="gray500">
            Only forms with filing status “Ready” will be submitted. We
            recommend reviewing forms with statuses “Action required” and
            “Excluded” to ensure they have the correct information before
            filing.
          </WSText.ParagraphSm>
        </WSElement>

        {taxFormsCount === 0 && (
          <WSAlert
            theme="warning"
            icon="alert"
            title="No forms ready to submit to the IRS"
          >
            At least one (1) form with filing status “Ready” is required to
            submit for filing. Please review the forms and ensure the
            information is accurate and return to file when the forms are in
            “Ready” status.
          </WSAlert>
        )}

        <DataView
          data={[
            {
              label: "Federal filings",
              value: taxFormsCount,
              pill: {
                theme: "blue",
                icon: "envelope",
                text: "Ready"
              }
            },
            {
              label: "State filings",
              value: taxFormsCount,
              pill: {
                theme: "blue",
                icon: "envelope",
                text: "Ready"
              }
            }
          ]}
        >
          <WSElement>
            <WSFlexBox wrap="nowrap" justify="space-between">
              <WSText.ParagraphSm color="gray500">
                State-specific filing details
              </WSText.ParagraphSm>
              <WSButton.Link
                size="S"
                onClick={() => {
                  history.push(
                    TAX_FILING_1099_FILING_SUBMISSION_EDIT_STATE_FILING_PATH
                  );
                }}
              >
                Edit
              </WSButton.Link>
            </WSFlexBox>

            <WSDivider mt="S" mb="L" />

            {userStateTaxIds.length > 0 ? (
              <WSTable
                tableData={userStateTaxIds.map(stateTaxId => ({
                  id: stateTaxId.state,
                  data: stateTaxId
                }))}
                columns={[
                  {
                    renderFunction: data => (
                      <WSTableCell text={data.data.stateLabel} />
                    )
                  },

                  {
                    renderFunction: data => (
                      <WSTableCell text={data.data.taxId} />
                    )
                  }
                ]}
              />
            ) : (
              <WSText.ParagraphSm color="gray600">N/A</WSText.ParagraphSm>
            )}
          </WSElement>
        </DataView>

        {taxFormsCount === 0 ? (
          <WSActions
            alignment="fill"
            buttons={[
              {
                onClick: onBack,
                label: "Back",
                kind: "Secondary"
              }
            ]}
          />
        ) : (
          <>
            <WSCard>
              <WSText.ParagraphSm weight="medium" mb="M">
                Ready to submit 1099-NEC forms for filing with the IRS?
              </WSText.ParagraphSm>

              <WSInfoBox mb="2XL">
                <p>
                  If you click submit, the forms listed above will be sent to
                  the IRS. You cannot undo this action. If you notice an error
                  on a form after filing, you can fix it with a correction after
                  you receive the form back from the IRS.
                </p>
                <p>
                  The 1099-NEC forms mentioned above will be submitted to the
                  IRS. Once submitted, each contractor will receive a copy of
                  their 1099-NEC delivered by mail and/or electronically based
                  on their preference.
                </p>
              </WSInfoBox>

              <WSControl
                type="checkbox"
                value={consent}
                onChange={setConsent}
                size="S"
                label={`Under penalties of perjury, I declare that to the best of my knowledge. ${companyName} attempted to obtain the payee's U.S. TIN and other identifying information under IRS Publication 1281. As a result, the information contained in the taxpayer's electronic forms 1099 file is, to the best of my knowledge and belief, true, correct and complete.`}
              />
            </WSCard>

            <WSActions
              alignment="fill"
              buttons={[
                {
                  onClick: onBack,
                  label: "Cancel",
                  kind: "Secondary"
                },
                {
                  onClick: onNext,
                  label: "Submit tax forms to the IRS",
                  disabled: !consent
                }
              ]}
            />
          </>
        )}
      </WSList>
    </Layout>
  );
};
