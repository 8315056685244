import { useHistory } from "react-router-dom";
import {
  PATH_SETTINGS_PAYMENT_METHODS,
  PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD,
  PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD
} from "..";
import { Layout } from "../../../../../shared/components/Layout";
import { FlowAddPaymentMethod } from "../../../components/payment/FlowAddPaymentMethod";

export const RouteAddPaymentMethod: React.FC = () => {
  const history = useHistory();
  return (
    <Layout title="Add payment method">
      <FlowAddPaymentMethod
        basePath={PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD}
        onBack={() => {
          history.push(PATH_SETTINGS_PAYMENT_METHODS_MANAGE_PAYMENT_METHOD);
        }}
        onNext={() => {
          history.push(PATH_SETTINGS_PAYMENT_METHODS);
        }}
      />
    </Layout>
  );
};
