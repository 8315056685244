import {
  toWSMoney,
  toWSMoneyString,
  WSAvatar,
  WSCard,
  WSDivider,
  WSFlexBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { PAYOUTS_PATH } from "../..";
import { IPayoutResponse } from "../../../../services/api/payments/payouts/types";
import {
  avatarBankAccount,
  avatarBankCard,
  avatarWallet
} from "../../../../shared/constants/avatars";
import { getDestinationName } from "../../utils/getDestinationName";
import { getPayoutNet } from "../../utils/utils";

export interface PayoutAccountsPanelProps {
  payout: IPayoutResponse;
}

export const PayoutAccountsPanel: React.FC<PayoutAccountsPanelProps> = ({
  payout
}) => {
  const history = useHistory();

  const uniqueExternalTransfers = useMemo(
    () =>
      uniqBy(
        (payout.externalFundsTransfers || []).filter(
          transfer => !!transfer.destination?.destinationId
        ),
        transfer => transfer.destination?.destinationId
      ),
    [payout.externalFundsTransfers]
  );

  const disbursementTotal = useMemo(() => getPayoutNet(payout), [payout]);

  if (uniqueExternalTransfers.length === 0 && !payout?.bankingFundsTransfer) {
    return null;
  }

  return (
    <WSCard
      divider
      header={{
        label: "Payout accounts",
        value: {
          action: {
            label: "View details",
            onClick: () => {
              history.push(
                `${PAYOUTS_PATH}/${payout.payoutId}/account-details`
              );
            }
          }
        }
      }}
    >
      <WSList gap="L" mb="L">
        {uniqueExternalTransfers.map(({ destination, amount }) => (
          <WSFlexBox
            wrap="nowrap"
            key={destination!.destinationId}
            justify="space-between"
            gap="M"
          >
            <WSFlexBox wrap="nowrap" alignItems="center" gap="M">
              {destination?.destinationType === "DestinationType_Card" ? (
                <WSAvatar {...avatarBankCard} />
              ) : (
                <WSAvatar {...avatarBankAccount} />
              )}
              <WSText.ParagraphSm color="gray500">
                {getDestinationName(destination!)}
              </WSText.ParagraphSm>
            </WSFlexBox>
            <WSText.ParagraphSm color="gray700">
              {toWSMoney(amount).formattedValue}
            </WSText.ParagraphSm>
          </WSFlexBox>
        ))}

        {payout?.bankingFundsTransfer && (
          <WSFlexBox justify="space-between" gap="M">
            <WSFlexBox alignItems="center" gap="M">
              <WSAvatar {...avatarWallet} />
              <WSText.ParagraphSm>
                <a onClick={() => history.push("/member/wallet")}>
                  Wingspan Wallet
                </a>
              </WSText.ParagraphSm>
            </WSFlexBox>

            <WSText.ParagraphSm color={"gray700"}>
              {toWSMoney(payout?.bankingFundsTransfer?.amount).formattedValue}
            </WSText.ParagraphSm>
          </WSFlexBox>
        )}

        {payout?.taxWithholdingTransfer && (
          <WSFlexBox justify="space-between" gap="M">
            <WSFlexBox alignItems="center" gap="M">
              <WSAvatar {...avatarWallet} />

              <WSText.ParagraphSm>
                <a
                  onClick={() => {
                    history.push("/member/taxes");
                  }}
                >
                  Tax Withholdings
                </a>
              </WSText.ParagraphSm>
            </WSFlexBox>

            <WSText.ParagraphSm color="gray700">
              {toWSMoneyString(payout.taxWithholdingTransfer.amount)}
            </WSText.ParagraphSm>
          </WSFlexBox>
        )}
      </WSList>

      <WSDivider mb="L" />
      <WSFlexBox justify="space-between">
        <WSText.ParagraphSm color={"gray500"} mr="2XL">
          Disbursement total
        </WSText.ParagraphSm>
        <WSText.ParagraphSm weight={"bold"} color={"gray700"}>
          {toWSMoney(disbursementTotal).formattedValue}
        </WSText.ParagraphSm>
      </WSFlexBox>
    </WSCard>
  );
};
