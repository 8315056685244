import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { useRouteMatch } from "react-router-dom";

import { SetupStartNewCorrectionFlowProps } from "./index";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { EditLayout } from "../../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import { CURRENT_YEAR } from "../../../constants/currentYear";
import { SelectCorrectionTypeForm } from "../../../../TaxCorrections/components/SelectCorrectionTypeForm";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { TaxFormViewer } from "../../../../TaxDocuments/types";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";
import { useGoToTaxDocumentByIds } from "../../../../TaxDocuments/paths";

interface SelectCorrectionTypeFormData {
  corrections: string[];
}

export const RouteSelectCorrectionType: React.FC<
  SetupStartNewCorrectionFlowProps
> = ({ onNext }) => {
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const goToTaxDocuments = useGoToTaxDocumentByIds();
  const { taxFormId, submissionId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);

  const onFormSubmit = (data: SelectCorrectionTypeFormData) => {
    onNext(data.corrections);
  };

  const onCancel = () => {
    goToTaxDocuments(taxFormId, submissionId);
  };

  return (
    <EditLayout title="Start new correction">
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => (
          <>
            <WSText.Heading2 mb="L">
              {selectorTaxFormRecipientName(taxForm)} {CURRENT_YEAR} Form
              1099-NEC
            </WSText.Heading2>
            <SelectCorrectionTypeForm
              taxForm={taxForm}
              taxFormViewer={TaxFormViewer.Payer}
              onCancel={onCancel}
              onContinue={onFormSubmit}
            />

            <WSButton.Link
              rightIcon="chevron-right"
              onClick={() =>
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8892926-requesting-corrections-for-unsupported-1099-nec-error-types"
                )
              }
            >
              There is another issue with my Form 1099
            </WSButton.Link>
          </>
        )}
      </WSQueries>
    </EditLayout>
  );
};
