import { QueryConfig } from "react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { resendInvite } from "../../../services/payees/resendInvite";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { IPayeeResendInviteResponse } from "@wingspanhq/payments/dist/interfaces";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const useResendPayeeInvite = (
  config?: QueryConfig<IPayeeResendInviteResponse, WSServiceError>
) => {
  const { openSnackbar } = useWSSnackbar();
  const xWingspanPayerId = useXWingspanPayerId();

  return useWSMutation(
    (payeeId: string) => resendInvite(payeeId, xWingspanPayerId),
    {
      onSuccess: () => {
        openSnackbar({
          message: "Invitation email resent to contractor",
          type: "success"
        });
      },
      onError: () => {
        openSnackbar({
          message: "Failed to send invitation to contractor",
          type: "error"
        });
      },
      ...config
    }
  );
};
