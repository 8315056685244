import { WSFlexBox, WSText } from "@wingspanhq/fe-component-library";

import { OriginalValuePreview } from "../OriginalValuePreview";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
// import { TaxFormViewer } from "../../../../types";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { TaxFormViewer } from "../../../types";

export interface AmountCorrectionDiffPreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  onEdit?: () => void;
  amount: number;
  correctedAmount: number;
  comment: string;
}

export const AmountCorrectionDiffPreview: React.FC<
  AmountCorrectionDiffPreviewProps
> = ({ taxForm, taxFormViewer, amount, correctedAmount, comment, onEdit }) => {
  return (
    <>
      <WSText.Heading5 mb="M">Amount correction</WSText.Heading5>
      <WSText.ParagraphSm mb="XL" color="gray500">
        The income reported is more or less than what was actually paid
      </WSText.ParagraphSm>

      <OriginalValuePreview>
        <WSFlexBox direction="column">
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            1099-NEC Total
          </WSText.ParagraphSm>
          <WSText.ParagraphSm color="gray600" strike formatMoney>
            {amount}
          </WSText.ParagraphSm>
        </WSFlexBox>
      </OriginalValuePreview>

      <CorrectedValuePreview
        taxForm={taxForm}
        taxFormViewer={taxFormViewer}
        onEdit={onEdit}
      >
        <WSFlexBox direction="column">
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            1099-NEC Total
          </WSText.ParagraphSm>
          <WSText.ParagraphSm
            weight="medium"
            color="gray600"
            mb="3XL"
            formatMoney
          >
            {correctedAmount}
          </WSText.ParagraphSm>

          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            Comment
          </WSText.ParagraphSm>
          <WSText.ParagraphSm weight="medium" color="gray600" mb="XL">
            {comment}
          </WSText.ParagraphSm>
        </WSFlexBox>
      </CorrectedValuePreview>
    </>
  );
};
