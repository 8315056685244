import {
  useWSModal,
  WSActions,
  WSAlert,
  WSCard,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { PayoutPreferences } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { MethodType } from ".";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_PAYMENT_CARDS } from "../../../../query/paymentCards/keys";
import { QUERY_PAYOUT_SETTINGS_DEBIT_CARDS } from "../../../../query/payments/keys";
import {
  usePayoutSettings,
  usePayrollSettings
} from "../../../../query/payments/queries";
import {
  QUERY_USERS_ACCOUNTS,
  QUERY_USERS_CLIENT
} from "../../../../query/users/keys";
import { useClientQuery } from "../../../../query/users/queries";
import { paymentsService } from "../../../../services/payments";
import { usersService } from "../../../../services/users";
import { useMethodLabelProps } from "../LabelMethod/useAccountDisplayData";

type Props = {
  onClose: () => void;
  id: string;
  type: MethodType;
};

export const ModalRemove: React.FC<Props> = props => {
  const userId = useUserId();
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryClient = useClientQuery(userId);

  const isPayrollFundingMethod = useMemo(
    () =>
      queryPayrollSettings.data?.fundingSource?.fundingSourceId === props.id,
    [props.id, queryPayrollSettings.data?.fundingSource?.fundingSourceId]
  );

  const isPaymentMethod = useMemo(
    () =>
      queryClient.data?.profile.defaultPaymentMethod.accountId === props.id ||
      queryClient.data?.profile.defaultPaymentMethod.paymentMethodId ===
        props.id,
    [
      props.id,
      queryClient.data?.profile.defaultPaymentMethod.accountId,
      queryClient.data?.profile.defaultPaymentMethod.paymentMethodId
    ]
  );

  const isPayoutStandardMethod = useMemo(
    () =>
      queryPayoutSettings.data?.payoutDestinations.find(
        d => d.payoutMethod === PayoutPreferences.Standard
      )?.destinationId === props.id,
    [props.id, queryPayoutSettings.data?.payoutDestinations]
  );

  const isPayoutInstantMethod = useMemo(
    () =>
      queryPayoutSettings.data?.payoutDestinations.find(
        d => d.payoutMethod === PayoutPreferences.Instant
      )?.destinationId === props.id,
    [props.id, queryPayoutSettings.data?.payoutDestinations]
  );

  const usage = useMemo(() => {
    const list: string[] = [];
    if (isPaymentMethod) {
      list.push("payment method");
    }

    if (isPayoutInstantMethod) {
      list.push("instant payout method");
    }

    if (isPayoutStandardMethod) {
      list.push("standard payout method");
    }

    if (isPayrollFundingMethod) {
      list.push("payroll funding method");
    }

    return list;
  }, [
    isPaymentMethod,
    isPayoutInstantMethod,
    isPayoutStandardMethod,
    isPayrollFundingMethod
  ]);

  const [mutate, meta] = useWSMutation(
    async () => {
      switch (props.type) {
        case "Account":
          await usersService.account.delete(props.id);
          return;
        case "CreditCard":
          const client = await usersService.client.get(userId);
          await usersService.client.update(userId, {
            clientId: userId,
            profile: {
              savedPaymentMethods: (
                client.profile.savedPaymentMethods || []
              ).map(pm => (pm.paymentMethodId === props.id ? null : {}))
            }
          });
          return;
        case "CheckbookCard":
          await paymentsService.payoutSettings.debitCard.delete(
            userId,
            props.id
          );
          return;
        case "PaymentCard":
          await paymentsService.paymentCard.delete(props.id);
      }
    },
    {
      onSuccess: props.onClose,
      dependencies: [
        QUERY_USERS_ACCOUNTS,
        QUERY_USERS_CLIENT,
        QUERY_PAYOUT_SETTINGS_DEBIT_CARDS,
        QUERY_PAYMENT_CARDS
      ]
    }
  );

  const { label } = useMethodLabelProps(props.id, props.type);

  return (
    <WSList gap="XL">
      <WSText.ParagraphSm color="gray600">
        Are you sure you want to remove this account?
      </WSText.ParagraphSm>

      <WSCard
        header={{
          label
        }}
      />

      {usage.length > 0 && (
        <WSAlert icon="alert" title="Account in use" theme="warning">
          This bank account is currently used as a {usage.join(", ")}. If you
          remove it, please select another account to set as the default(s).
        </WSAlert>
      )}

      <WSErrorMessage contextKey="AddPaymentMethod" error={meta.error} />

      <WSActions
        buttons={[
          {
            kind: "Secondary",
            label: "Cancel",
            onClick: props.onClose
          },
          {
            destructive: true,
            label: "Remove",
            onClick: mutate,
            loading: meta.isLoading
          }
        ]}
      />
    </WSList>
  );
};

export const useModalRemove = () =>
  useWSModal(ModalRemove, {
    title: "Remove account",
    size: "S"
  });
