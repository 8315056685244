import React, { useState } from "react";
import {
  WSActions,
  WSButton,
  WSCard,
  WSControl,
  WSControlGroup,
  WSElement,
  WSField,
  WSForm,
  WSGrid,
  WSInfoBox,
  WSInputMask,
  WSInputText,
  WSList,
  WSPanel,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  PayeeUpdateRequest,
  useUpdatePayee
} from "../../../../../query/payee/mutations/useUpdatePayee";
import { COUNTRY_OPTIONS } from "../../../../../constants/user";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { FormPartialAddress } from "../../../../Onboarding/components/FormPartialAddress";
import { getValidationSchema } from "./getValidationSchema";
import { getDefaultValues } from "./getDefaultValues";
import { isTaxInfoAdded, isVerificationFailed } from "./utils";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { EditPayeeSetupParams } from "../index";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { FormPartialCompanyStructure } from "../../../../../shared/components/FormPartialCompanyStructure";

type FormValues = ReturnType<typeof getDefaultValues>;

export const FormEditPayeeInformation: React.FC<
  SetupRouteComponentProps<EditPayeeSetupParams>
> = ({ onBack, onNext, params }) => {
  const payee = params?.payee!;

  const [updatePayee, updatePayeeMeta] = useUpdatePayee();

  const [addTaxInfo, setAddTaxInfo] = useState(isTaxInfoAdded(payee));
  const [agree, setAgree] = useState(false);

  const mismatchProps = isVerificationFailed(payee)
    ? ({
        message: "Mismatch info",
        status: "warning"
      } as const)
    : {};

  return (
    <WSForm<FormValues>
      defaultValues={getDefaultValues(payee)}
      validationSchema={getValidationSchema(addTaxInfo)}
      onSubmit={async formData => {
        const request: PayeeUpdateRequest = {
          payeeId: payee.payeeId,
          payerOwnedData: {
            payeeName: addTaxInfo
              ? formData.tinType === TinType.Business
                ? ""
                : `${formData.individual?.firstName} ${formData.individual?.lastName}`.trim()
              : formData.name,
            payeeCompanyName: addTaxInfo
              ? formData.tinType === TinType.Business
                ? formData.business?.legalBusinessName
                : ""
              : formData.companyName
          }
        };

        if (addTaxInfo) {
          request.payerOwnedData.payeeW9Data =
            formData.tinType === TinType.Business
              ? {
                  firstName: "",
                  lastName: "",
                  ssn: "",
                  tinType: formData.tinType,
                  ein: formData.business.ein,
                  legalBusinessName: formData.business.legalBusinessName,
                  disregardedEntityName:
                    formData.business.disregardedEntityName,
                  companyStructure: formData.business.companyStructure,
                  ...formData.address
                }
              : {
                  ein: "",
                  legalBusinessName: "",
                  disregardedEntityName: "",
                  tinType: formData.tinType,
                  ssn: formData.individual.ssn,
                  firstName: formData.individual.firstName,
                  lastName: formData.individual.lastName,
                  ...formData.address
                };
        }

        await updatePayee(request, {
          onSuccess: () => {
            if (addTaxInfo) {
              onNext({ status: "TaxInfo" });
            } else {
              onNext({ status: "Info" });
            }
          }
        });
      }}
    >
      <WSList gap="2XL">
        <WSCard header="Edit contractor information" divider>
          <WSList mt="L" gap="2XL">
            {addTaxInfo ? null : (
              <>
                <WSForm.Field
                  name="name"
                  label="Contractor full name (optional)"
                  description="Contractor’s personal or business email"
                  component={WSInputText}
                  componentProps={{
                    placeholder: "Full name"
                  }}
                />
                <WSForm.Field
                  name="companyName"
                  label="Contractor company (optional)"
                  component={WSInputText}
                  componentProps={{
                    placeholder: "Company name"
                  }}
                />
              </>
            )}

            <WSInfoBox>
              <b>Contractor invites</b>: After adding a contractor, they will be
              invited by the email provided to sign-up for a Wingspan account in
              order to receive payments from you.
              <br /> <br />
              <b>Contractor taxpayer information</b>: The contractor will be
              asked to verify and certify their taxpayer information when they
              onboard which is used for tax reporting purposes. Optionally, if
              the payer has the contractor’s W-9 info (U.S. only), they can
              provide taxpayer information on behalf of the contractor but the
              information will be replaced once they sign-up and verify the
              information themselves. Names should match exactly what’s listed
              on the federal tax return to avoid discrepancies with the IRS.
              When W-9 information is provided, it will replace the optional
              fields above)
              <br /> <br />
              <b>Backup withholding not supported</b>: Filings that report
              federal or state tax withholding amounts cannot be processed.
              <br /> <br />
              {addTaxInfo ? null : (
                <WSButton.Link
                  mt="L"
                  size="S"
                  icon="plus"
                  onClick={() => setAddTaxInfo(true)}
                >
                  Add contractor taxpayer info
                </WSButton.Link>
              )}
            </WSInfoBox>
            {addTaxInfo ? (
              <>
                <WSForm.Field
                  name="address.country"
                  component={WSSelect}
                  label="Country of tax residency"
                  description="Only U.S. taxpayer information supported currently"
                  componentProps={{
                    disabled: true,
                    internalSearch: true,
                    options: COUNTRY_OPTIONS
                  }}
                />
                <WSForm.Field
                  name="tinType"
                  component={WSControlGroup}
                  label="How does the contractor operate?"
                  componentProps={{
                    type: "radio",
                    chip: true,
                    options: [
                      {
                        label: "Individual - Social Security Number (SSN)",
                        value: TinType.Individual,
                        tooltip:
                          "Your personal information will be used on invoices and tax documentation"
                      },
                      {
                        label:
                          "Business - Employer Identification Number (EIN)",
                        value: TinType.Business,
                        tooltip:
                          "Your entity information will be used on invoices and tax documentation"
                      }
                    ]
                  }}
                />
                <WSForm.Value name="tinType">
                  {tinType => {
                    if (tinType === TinType.Individual) {
                      return (
                        <React.Fragment key={tinType}>
                          <WSForm.Field
                            name="individual.ssn"
                            label="Social Security Number (SSN)"
                            component={WSInputMask}
                            componentProps={{
                              placeholder: "Social Security Number",
                              mask: "999-99-9999",
                              autoComplete: "off",
                              required: true,
                              fsExclude: true,
                              unmask: true,
                              ...mismatchProps
                            }}
                          />

                          <WSGrid gutter="2XL">
                            <WSGrid.Item span={{ s: "12", m: "6" }}>
                              <WSForm.Field
                                name="individual.firstName"
                                label="Legal first name"
                                component={WSInputText}
                                componentProps={{
                                  required: true,
                                  placeholder: "First name",
                                  ...mismatchProps
                                }}
                              />
                            </WSGrid.Item>
                            <WSGrid.Item span={{ s: "12", m: "6" }}>
                              <WSForm.Field
                                name="individual.lastName"
                                label="Legal last name"
                                component={WSInputText}
                                componentProps={{
                                  required: true,
                                  placeholder: "Last name",
                                  ...mismatchProps
                                }}
                              />
                            </WSGrid.Item>
                          </WSGrid>

                          <WSPanel noBorder colorBackground="gray50" p="L">
                            <WSList gap="S">
                              <WSText.ParagraphXs
                                weight="medium"
                                color="gray500"
                              >
                                Federal tax classification
                              </WSText.ParagraphXs>
                              <WSText.ParagraphXs color="gray400">
                                Note: This is default classification for
                                individual account type that operates with an
                                SSN
                              </WSText.ParagraphXs>
                              <WSText.ParagraphSm color="gray600">
                                Sole proprietorship / Single member LLC
                              </WSText.ParagraphSm>
                            </WSList>
                          </WSPanel>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={tinType}>
                          <WSForm.Field
                            name="business.ein"
                            label="Employer Identification Number (EIN)"
                            component={WSInputMask}
                            componentProps={{
                              placeholder: "Employer Identification Number",
                              mask: "99-9999999",
                              required: true,
                              autoComplete: "off",
                              fsExclude: true,
                              unmask: true,
                              ...mismatchProps
                            }}
                          />
                          <WSGrid gutter="2XL">
                            <WSGrid.Item span={{ s: "12", m: "6" }}>
                              <WSForm.Field
                                name="business.legalBusinessName"
                                label="Legal business name"
                                component={WSInputText}
                                componentProps={{
                                  placeholder: "Business name",
                                  required: true,
                                  ...mismatchProps
                                }}
                              />
                            </WSGrid.Item>
                            <WSGrid.Item span={{ s: "12", m: "6" }}>
                              <WSForm.Field
                                name="business.disregardedEntityName"
                                label="Disregarded entity name (optional)"
                                component={WSInputText}
                                componentProps={{
                                  placeholder: "Entity name"
                                }}
                              />
                            </WSGrid.Item>
                          </WSGrid>

                          <FormPartialCompanyStructure
                            show1099Message
                            name="business.companyStructure"
                          />
                        </React.Fragment>
                      );
                    }
                  }}
                </WSForm.Value>
              </>
            ) : null}

            <WSErrorMessage error={updatePayeeMeta.error} contextKey="Other" />
          </WSList>
        </WSCard>
        {addTaxInfo ? (
          <>
            <WSCard header="Address" divider>
              <WSList mt="L" gap="L">
                <WSForm.Values names={["address.country", "tinType"]}>
                  {props => (
                    <>
                      <WSField
                        label={`${
                          props.tinType === TinType.Individual
                            ? "Personal"
                            : "Business"
                        } address`}
                      />
                      <FormPartialAddress
                        name="address"
                        country={props["address.country"]}
                      />
                    </>
                  )}
                </WSForm.Values>
              </WSList>
            </WSCard>

            <WSCard header="Certify contractor’s taxpayer information (W-9) is accurate">
              <WSList gap="XL">
                <WSText.ParagraphSm color="gray600">
                  By using Wingspan’s services you agree that the information
                  provided on the Form W-9 is accurate and that you certify the
                  provided information as follows:
                </WSText.ParagraphSm>

                <WSInfoBox>
                  By submitting, you certify, under penalties of perjury, that:
                  <WSElement as="ul">
                    <WSElement as="li" mt="M">
                      I have received a completed and signed Form W-9 from the
                      contractor, in which they have certified that the
                      information provided is true, correct, and complete.
                    </WSElement>
                    <WSElement as="li" mt="M">
                      I acknowledge that this information will be used for tax
                      reporting purposes and understand that it is my
                      responsibility to ensure accurate taxpayer identification
                      information is collected.
                    </WSElement>
                    <WSElement as="li" mt="M">
                      The number shown on this form is the contractor’s correct
                      taxpayer identification number (or a number to be issued
                      to the contractor); and
                    </WSElement>
                    <WSElement as="li" mt="M">
                      The contractor is not subject to backup withholding
                      because: (a) they are exempt from backup withholding, or
                      (b) they have not been notified by the Internal Revenue
                      Service (IRS) that they are subject to backup withholding
                      as a result of a failure to report all interest or
                      dividends, or (c) the IRS has notified the contractor that
                      they are no longer subject to backup withholding; and
                    </WSElement>
                    <WSElement as="li" mt="M">
                      The contractor is a U.S. citizen or other U.S. person
                      (defined below); and
                    </WSElement>
                    <WSElement as="li" mt="M">
                      No FATCA code applies as this form relates to an account
                      maintained in the United States.
                    </WSElement>
                  </WSElement>
                </WSInfoBox>

                <WSControl
                  type="checkbox"
                  size="S"
                  label="By providing the information on this form, I hereby certify, to the best of my knowledge, that the information provided is complete and correct."
                  onChange={() => setAgree(agree => !agree)}
                  value={agree}
                />
              </WSList>
            </WSCard>
          </>
        ) : null}
        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Cancel",
              onClick: onBack,
              type: "button",
              kind: "Secondary"
            },
            {
              label: "Save changes",
              type: "submit",
              disabled: addTaxInfo ? !agree : false,
              loading: updatePayeeMeta.isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
