import {
  WSCheckboxGroup,
  WSFormOld,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { TwoLineLabel } from "../TwoLineLabel";
import { CorrectionFormActions } from "../CorrectionFormActions";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../constants/corrections";
import { TaxFormViewer } from "../../../types";

const CORRECTION_TYPES_OPTIONS = [
  {
    label: "Amount",
    description:
      "The income reported is more or less than what was actually paid.",
    value: CORRECTION_AMOUNT
  },
  {
    label: "Taxpayer identification number or name",
    description:
      "The Taxpayer Identification Number (SSN or EIN) or name is incorrect.",
    value: CORRECTION_TIN_OR_NAME
  },
  {
    label: "Address",
    description: "My address is not accurate.",
    value: CORRECTION_ADDRESS
  }
];

interface SelectCorrectionTypeFormData {
  corrections: string[];
}

interface SelectCorrectionTypeFormProps {
  taxFormViewer?: TaxFormViewer;
  onContinue: (data: SelectCorrectionTypeFormData) => void;
  onCancel: () => void;
}

export const SelectCorrectionTypeForm: React.FC<
  SelectCorrectionTypeFormProps
> = ({ taxFormViewer = TaxFormViewer.Payee, onContinue, onCancel }) => {
  return (
    <WSFormOld
      defaultValues={{
        corrections: []
      }}
      validationSchema={Yup.object().shape({
        corrections: Yup.array(Yup.string().required())
          .required("Required")
          .min(1)
      })}
      onSubmit={onContinue}
    >
      <WSFormOld.Field name="corrections" mb="XL">
        <WSText weight="medium" mb="XL">
          {taxFormViewer === TaxFormViewer.Payee
            ? "What is the error on your Form 1099-NEC? (Select all that apply)"
            : "What is the error on the recipient’s Form 1099-NEC? (Select all that apply)"}
        </WSText>
        <WSFormOld.Input
          render={props => (
            <>
              <WSCheckboxGroup
                {...props}
                options={CORRECTION_TYPES_OPTIONS.map(
                  ({ label, description, value }) => ({
                    label: (
                      <TwoLineLabel label={label} description={description} />
                    ),
                    value
                  })
                )}
              />
              <WSFormOld.Error />
            </>
          )}
        />
      </WSFormOld.Field>

      <CorrectionFormActions mb="XL" onCancel={onCancel} />
    </WSFormOld>
  );
};
