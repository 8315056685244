import { useWSModal, WSCard, WSList } from "@wingspanhq/fe-component-library";
import { useCallback } from "react";
import { queryCache } from "react-query";
import { QUERY_FILE_MEMBER_PUBLIC_FILE } from "../../../../../query/files/keys";
import { useGetMemberPublicFile } from "../../../../../query/files/queries";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_MEMBER_PROFILE } from "../../../../../query/users/keys";
import { useMemberProfile } from "../../../../../query/users/queries";
import { filesService } from "../../../../../services/files";
import { usersService } from "../../../../../services/users";
import { DataViewGrid } from "../../../../../shared/components/DataView";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { FormEditLink } from "../components/FormEditLink";

type Props = {};

export const RouteMarketing: React.FC<Props> = () => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const modalEditLinkedIn = useWSModal(FormEditLink, {
    size: "S",
    title: "LinkedIn"
  });

  const modalEditInstagram = useWSModal(FormEditLink, {
    size: "S",
    title: "Instagram URL"
  });

  const modalEditX = useWSModal(FormEditLink, {
    size: "S",
    title: "X URL"
  });

  const modalEditOtherWebsite = useWSModal(FormEditLink, {
    size: "S",
    title: "Other website"
  });

  const queryLogo = useGetMemberPublicFile(
    queryMember.data?.profile.logoFileId || "",
    {
      enabled: queryMember.data?.profile.logoFileId
    }
  );

  const [uploadLogo, uploadLogoMeta] = useWSMutation<
    void,
    WSServiceError,
    FormData
  >(async data => {
    const files = await filesService.member.public.create(data);

    const file = files[0];
    const fileId = file.fileId;

    const member = await usersService.member.update(userId, {
      memberId: userId,
      profile: {
        logoFileId: fileId
      }
    });

    queryCache.setQueryData([QUERY_FILE_MEMBER_PUBLIC_FILE, fileId], file);
    queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);
  });

  const handleLogoEdit = useCallback(() => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.click();

    fileInput.onchange = (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      uploadLogoMeta.reset();
      if (event.target && event.target.files && event.target.files) {
        const logoFile = event.target.files[0];
        const data = new FormData();
        data.append("file", logoFile);
        uploadLogo(data);
      }
    };
  }, []);

  if (!queryMember.data) {
    return null;
  }

  const member = queryMember.data;

  return (
    <WSCard header="Marketing" divider>
      <WSList gap="L">
        <DataViewGrid
          data={[
            {
              label: {
                text: "Logo",
                actionPosition: "left",
                action: {
                  label: member.profile.logoFileId ? "Edit" : "Add",
                  onClick: handleLogoEdit
                }
              },
              value: queryLogo.data?.url,
              type: "image"
            }
          ]}
        />
        <DataViewGrid
          data={[
            {
              label: {
                text: "LinkedIn",
                actionPosition: "left",
                action: {
                  label: "Edit",
                  onClick: () => {
                    modalEditLinkedIn.open({
                      name: "linkedInUrl",
                      label: "LinkedIn"
                    });
                  }
                }
              },
              value: member.profile.company?.links?.linkedInUrl
            },
            {
              label: {
                text: "Instagram URL",
                actionPosition: "left",
                action: {
                  label: "Edit",
                  onClick: () => {
                    modalEditInstagram.open({
                      name: "instagramUrl",
                      label: "Instagram URL"
                    });
                  }
                }
              },
              value: member.profile.company?.links?.instagramUrl
            },
            {
              label: {
                text: "X URL",
                actionPosition: "left",
                action: {
                  label: "Edit",
                  onClick: () => {
                    modalEditX.open({
                      name: "twitterUrl",
                      label: "X URL"
                    });
                  }
                }
              },
              value: member.profile.company?.links?.twitterUrl
            },
            {
              label: {
                text: "Other website",
                actionPosition: "left",
                action: {
                  label: "Edit",
                  onClick: () => {
                    modalEditOtherWebsite.open({
                      name: "otherUrl",
                      label: "Other website"
                    });
                  }
                }
              },
              value: member.profile.company?.links?.otherUrl
            }
          ]}
        />
      </WSList>
    </WSCard>
  );
};
