import {
  WSPage,
  WSPageToolbar,
  WSPageToolbarAction
} from "@wingspanhq/fe-component-library";
import { FrequencyAndScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { INVOICING_PATH } from "../..";
import { KEYBOARD_SHORTCUT_SEARCH } from "../../../../constants/keyboardShortcuts";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { plural } from "../../../../shared/utils/plural";
import { useRedirectToByKeyPress } from "../../../../utils/useRedirectToByKeyPress";
import { useCustomization } from "../../../customization";
import { DashboardTabs } from "../../components/DashboardTabs";
import { useQueryInvoiceTemplateRowsSummary } from "../../queries/useQueryInvoiceTemplateRowsSummary";
import { RouteDeposited } from "./routes/RouteDeposited";
import { RouteDrafts } from "./routes/RouteDrafts";
import { RouteInProgress } from "./routes/RouteInProgress";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteOutstanding } from "./routes/RouteOutstanding";

export const RouteDashboard: React.FC = () => {
  const { terminology } = useCustomization();

  useBrowserPageTitle("Invoicing");
  const history = useHistory();
  const featureFlags = useFeatureFlags();
  const queryInvoiceTemplateRowsSummary = useQueryInvoiceTemplateRowsSummary({
    filter: {
      status: {
        in: [
          FrequencyAndScheduleStatus.Active,
          FrequencyAndScheduleStatus.Draft
        ]
      }
    }
  });

  const showManageSeries = useMemo(
    () => !!queryInvoiceTemplateRowsSummary.data?.listSize,
    [queryInvoiceTemplateRowsSummary.data?.listSize]
  );

  useRedirectToByKeyPress(KEYBOARD_SHORTCUT_SEARCH, `${INVOICING_PATH}/search`);

  const pageActions = useMemo(() => {
    const actions: WSPageToolbarAction[] = [
      {
        label: "Search",
        icon: "search",
        buttonKind: "Tertiary",
        onClick: () => history.push(`${INVOICING_PATH}/search`),
        hideButtonOn: false
      },
      {
        label: "Create invoice",
        icon: "plus",
        buttonKind: "Primary",
        onClick: () => history.push(INVOICING_PATH + "/create")
      }
    ];

    if (showManageSeries) {
      actions.unshift({
        buttonKind: "Tertiary",
        label: "Scheduled invoices",
        onClick: () => history.push(INVOICING_PATH + "/scheduled")
      });
    }
    if (featureFlags.data?.showNewPayouts) {
      actions.push({
        label: "Payouts (Beta)",
        onClick: () => history.push(INVOICING_PATH + "/payouts")
      });
    }

    return actions;
  }, [featureFlags.data?.showNewPayouts, history, showManageSeries]);

  return (
    <WSPage>
      <WSPageToolbar
        title={plural(terminology().getPaidInvoice)}
        actions={pageActions}
      />

      <DashboardTabs mb="L" />

      <Switch>
        <Route path={INVOICING_PATH + "/drafts"} component={RouteDrafts} />
        <Route
          path={INVOICING_PATH + "/outstanding"}
          component={RouteOutstanding}
        />
        <Route
          path={INVOICING_PATH + "/in-progress"}
          component={RouteInProgress}
        />
        <Route
          path={INVOICING_PATH + "/deposited"}
          component={RouteDeposited}
        />
        <Route path={INVOICING_PATH} component={RouteIndex} />
      </Switch>
    </WSPage>
  );
};
