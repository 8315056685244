import { FAQ } from "../../../../shared/components/FAQ";
import React from "react";
import {
  WSMarginProps,
  WSPaddingProps
} from "@wingspanhq/fe-component-library/dist/lib/components/WSElement/WSElement.component";

export const TaxFilingFAQ: React.FC<WSMarginProps & WSPaddingProps> = props => (
  <FAQ
    {...props}
    mb="2XL"
    sections={[
      {
        title: "About 1099-NEC tax filing",
        links: [
          {
            label: "What is the 1099 tax filing process on Wingspan?",
            url: "https://docs.wingspan.app/docs/1099-nec-tax-filing-overview"
          }
        ]
      },
      {
        title: "Guidelines for a successful tax filing season",
        links: [
          {
            label:
              "How should I communicate with contractors about 1099 filing?",
            url: "https://docs.wingspan.app/docs/communication-guide"
          },
          {
            label: "What are some contractor FAQs I can publish?",
            url: "https://docs.wingspan.app/docs/contractor-faqs-template"
          },
          {
            label: "What is the contractor experience to access tax forms?",
            url: "https://docs.wingspan.app/docs/contractor-experience-walkthrough"
          },
          {
            label: "What are my responsibilities as a payer?",
            url: "https://docs.wingspan.app/docs/responsibilities-support"
          },
          {
            label: "What are the limitations of filing support at Wingspan?",
            url: "https://docs.wingspan.app/docs/limitations-of-wingspan-filing-service"
          },
          {
            label: "What are other common payer FAQs?",
            url: "https://docs.wingspan.app/docs/payer-faqs"
          },
          {
            label: "What is new for 2024 filing?",
            url: "https://docs.wingspan.app/docs/whats-new-for-2024"
          }
        ]
      },
      {
        title: "Preparing for 1099 filing",
        links: [
          {
            label: "How do I get setup for 1099 filing?",
            url: "https://docs.wingspan.app/docs/setup-1099-filing"
          },
          {
            label: "How should I communicate to my contractors?",
            url: "https://docs.wingspan.app/docs/communication-guide"
          },
          {
            label: "How do I bulk upload contractors into Wingspan?",
            url: "https://docs.wingspan.app/docs/bulk-uploading-contractors-into-wingspan"
          },
          {
            label: "How do I bulk upload off-platform payments into Wingspan?",
            url: "https://docs.wingspan.app/docs/bulk-uploading-off-platform-payments"
          },
          {
            label: "How do I add individual contractors to Wingspan?",
            url: "https://docs.wingspan.app/docs/add-individual-contractors"
          },
          {
            label: "How do I manage my team’s permissions?",
            url: "https://docs.wingspan.app/docs/team-permissions-for-1099-management"
          }
        ]
      },
      {
        title: "Generating & managing tax forms",
        links: [
          {
            label: "How do I generate tax forms?",
            url: "https://docs.wingspan.app/docs/generating-1099-amounts-statuses"
          },
          {
            label: "What are the 1099 statuses on Wingspan?",
            url: "https://docs.wingspan.app/docs/understanding-1099-statuses-2024"
          },
          {
            label: "How is recipient information determined on 1099 forms?",
            url: "https://docs.wingspan.app/docs/recipient-information-determination-logic-for-1099-forms-202"
          },
          {
            label: "How do I update tax forms?",
            url: "https://docs.wingspan.app/docs/updating-tax-forms-2024"
          }
        ]
      },
      {
        title: "Filing 1099s and corrections",
        links: [
          {
            label: "How do I file and delivery 1099 forms?",
            url: "https://docs.wingspan.app/docs/file-deliver-1099s"
          },
          {
            label: "How do I correct 1099 forms?",
            url: "https://docs.wingspan.app/docs/correct-tax-forms-2024"
          },
          {
            label: "How do I manage state filings?",
            url: "https://docs.wingspan.app/docs/state-filing"
          }
        ]
      }
    ]}
  />
);
