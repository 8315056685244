import {
  WSActions,
  WSElement,
  WSInfoBox,
  WSList,
  WSPanel,
  WSProgressBar,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { isCountryUS } from "../../../../shared/selectors/selectorIsDomesticMember";
import { ConfirmationDisclosures } from "../../components/ConfirmationDisclosures";
import { MismatchInfoBox } from "../../components/MismatchInfoBox";
import { ReviewAccountType } from "../../components/ReviewAccountType";
import { ReviewBusinessInformation } from "../../components/ReviewBusinessInformation";
import { ReviewPerson } from "../../components/ReviewPerson";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { useMissingData } from "../../hooks/useMissingData";
import { OnboardingModuleCreateAccount, OnboardingStep } from "../../types";
import { CreateAccountContext } from "./types";
import { useMutationSubmit } from "./useMutationSubmit";

export const Review: React.FC<OnboardingStep> = ({ onBack, onNext }) => {
  const history = useHistory();
  const createAcccountPath = history.location.pathname.replace("/review", "");
  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;

  const verificationLevel =
    onboardingModule?.options?.verificationLevel || "Tax";
  const includeBeneficialOwners = verificationLevel === "Banking";
  const missingFields = useMissingData(verificationLevel);

  const [context] = useOnboardingContext<CreateAccountContext>();
  const [submit, meta] = useMutationSubmit({
    onSuccess: ({ status, missingData }) => {
      const shouldGoBackToEditing =
        status === "UpdateRequired" &&
        missingData.requiredFields &&
        missingData.requiredFields?.length > 0;

      if (shouldGoBackToEditing) {
        if (context.accountType?.type === "Individual") {
          history.push(createAcccountPath + "/personal-information");
        } else {
          history.push(createAcccountPath + "/business-information");
        }
      } else {
        onNext?.();
      }
    }
  });
  const [consent, setConsent] = useState(false);
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  const isInternational = useMemo(() => {
    const country =
      context.accountType?.type === "Individual"
        ? context.personalInformation?.person.country
        : context.accountType?.country;
    return !isCountryUS(country);
  }, [
    context.accountType?.country,
    context.accountType?.type,
    context.personalInformation?.person.country
  ]);

  if (meta.isLoading) {
    return (
      <WSList gap="2XL">
        <WSPanel>
          <WSSectionToolbar title="Verifying your Tax ID" />
          <ProgressBar time={30000} />
          <WSInfoBox>
            We're verifying your Tax ID. You can continue with other tasks then
            come back to this tab - we'll save your progress.
          </WSInfoBox>
        </WSPanel>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Continue",
              disabled: true
            }
          ]}
        />

        <WSText.ParagraphXs color="gray400">
          The information on this page is required to setup your Wingspan
          account, if you exit or leave without completing this step, you will
          return to this page the next time you sign-in
        </WSText.ParagraphXs>
      </WSList>
    );
  }

  return (
    <WSList gap="2XL">
      {missingFields?.completeOnboardingToken && (
        <MismatchInfoBox
          footprintToken={missingFields?.completeOnboardingToken}
        />
      )}

      <WSList gap="L">
        <WSElement>
          <WSSectionToolbar title="Confirm account information" />
          <WSText.ParagraphSm mt="L" color="gray500">
            Please review to ensure the information is accurate before
            submitting
          </WSText.ParagraphSm>
        </WSElement>

        {context.accountType && (
          <ReviewAccountType
            formData={context.accountType}
            onEdit={() => {
              history.push(createAcccountPath + "/account-type");
            }}
          />
        )}

        {context.accountType?.type === "Individual" ? (
          <ReviewPerson
            type="Individual"
            title="Personal information"
            formData={context.personalInformation.person}
            showOwnershipStake={includeBeneficialOwners}
            onEdit={() => {
              history.push(createAcccountPath + "/personal-information");
            }}
          />
        ) : (
          <>
            {context.businessInformation && (
              <ReviewBusinessInformation
                formData={context.businessInformation}
                country={context.accountType?.country || "US"}
                onEdit={() => {
                  history.push(createAcccountPath + "/business-information");
                }}
                personalEmail={queryUser.data?.email}
              />
            )}

            {context.representative && (
              <ReviewPerson
                type="Representative"
                title="Authorized representative information (You)"
                formData={context.representative.representative}
                showOwnershipStake={includeBeneficialOwners}
                onEdit={() => {
                  history.push(createAcccountPath + "/representative");
                }}
              />
            )}

            {includeBeneficialOwners &&
              (context.representative?.beneficialOwners || []).map(
                (beneficialOwner, index) => (
                  <ReviewPerson
                    type="BeneficialOwner"
                    key={String(index)}
                    title={`Beneficial owner (${index + 1})`}
                    formData={beneficialOwner}
                    onEdit={() => {
                      history.push(createAcccountPath + "/representative");
                    }}
                    showOwnershipStake
                  />
                )
              )}
          </>
        )}
        <WSPanel>
          <ConfirmationDisclosures
            consent={consent}
            setConsent={setConsent}
            verificationLevel={onboardingModule?.options?.verificationLevel}
            isInternational={isInternational}
          />
        </WSPanel>
      </WSList>

      <WSErrorMessage contextKey="MonitoredEntity" error={meta.error} />

      <WSActions
        alignment="fill"
        buttons={[
          { label: "Back", onClick: onBack, kind: "Secondary" },
          {
            label: "Confirm & continue",
            disabled: !consent,
            onClick: () => {
              submit();
            }
          }
        ]}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};

const DEFAULT_TIME = 10000;
const DEFAULT_REFRESH_INTERVAL = 250;

const ProgressBar: React.FC<{
  time?: number;
  refreshInterval?: number;
}> = props => {
  const [progress, setProgress] = useState(0);

  const time = useMemo(() => props.time || DEFAULT_TIME, [props.time]);
  const refreshInterval = useMemo(
    () => props.refreshInterval || DEFAULT_REFRESH_INTERVAL,
    [props.refreshInterval]
  );

  const step = useMemo(
    () => Math.round(100 / (time / refreshInterval)),
    [refreshInterval, time]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => {
        if (p >= 90) {
          clearInterval(interval);
        }

        return p + step;
      });
    }, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval, step]);

  return <WSProgressBar percent={progress} />;
};
