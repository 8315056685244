import classNames from "classnames";
import React, { useMemo } from "react";
import { WSAlert, WSAlertProps } from "../WSAlert/WSAlert";
import { WSDivider } from "../WSDivider/WSDivider";
import { WSElement } from "../WSElement/WSElement.component";
import { Label, WSDataItemLabelProps } from "./Label";
import styles from "./styles.module.scss";
import { Value, WSDataItemValueProps } from "./Value";

const DEFAULT_ALERT_SIZE = "S";

export type WSDataItemProps = {
  label?: WSDataItemLabelProps | string;
  className?: string;
  inline?: boolean;
  divider?: boolean;
  alert?: WSAlertProps;
} & WSDataItemValueProps;

export const WSDataItem: React.FC<WSDataItemProps> = (props) => {
  const labelProps = useMemo(() => {
    if (typeof props.label === "string") {
      return {
        text: props.label
      };
    }

    return props.label;
  }, [props.label]);

  return (
    <WSElement className={props.className}>
      <WSElement
        className={classNames(
          styles.dataItem,
          props.inline && styles.inline,
          props.divider && styles.divider
        )}
      >
        <Label {...labelProps} />

        {props.divider && !props.inline && <WSDivider />}

        <Value
          formatter={props.formatter}
          value={props.value}
          type={props.type}
          formatterOptions={props.formatterOptions}
          pill={props.pill}
          avatar={props.avatar}
          icon={props.icon}
          copy={props.copy}
          fsExclude={props.fsExclude}
          action={props.action}
        />
      </WSElement>

      {props.alert && (
        <WSAlert
          size={DEFAULT_ALERT_SIZE}
          className={styles.alert}
          {...props.alert}
        />
      )}
    </WSElement>
  );
};
