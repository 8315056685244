import React from "react";
import {
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";

type Props = WSElementProps & {};

export const DebitCashbackProgram: React.FC<Props> = props => {
  return (
    <WSElement {...props}>
      <WSText weight="medium" mb="L">
        Debit Cashback Program
      </WSText>

      <WSElement colorBackground="gray50" p="2XL">
        <WSText color="gray500" mb="XL">
          <WSText color="gray600" weight="medium" inline>
            Cash Back Program:
          </WSText>{" "}
          1.00% cash back on all debit card purchases, so long as average daily
          balance during monthly period is above $100
        </WSText>

        <WSText color="gray500" mb="XL">
          <WSText color="gray600" weight="medium" inline>
            Bonus Rewards:
          </WSText>{" "}
          Receive a $50 gift card, redeemable at 200+ businesses, when you spend
          $1,000 or more in your first 30 days after signing up for the Wingspan
          debit card. Expires 3/31/2025
        </WSText>

        <WSText color="gray500" mb="XL">
          <WSText color="gray600" weight="medium" inline>
            Program Start Date:
          </WSText>{" "}
          1/1/2025
        </WSText>

        <WSText color="gray500" mb="XL">
          <WSText color="gray600" weight="medium" inline>
            Expiration Date of Program:
          </WSText>{" "}
          12/31/2025, future programs to be announced
        </WSText>
      </WSElement>
    </WSElement>
  );
};
