import { QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_ENGAGEMENT_LIST_ALL } from "../keys";
import times from "lodash/times";
import flatten from "lodash/flatten";
import { getEngagements } from "../../../services/engagements";
import { IEngagement } from "@wingspanhq/payments/dist/interfaces";
import {
  EngagementListQueryParams,
  mapEngagementsFilters
} from "./useEngagementListQuery";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const useEngagementsListAllQuery = (
  params?: EngagementListQueryParams,
  config?: QueryConfig<IEngagement[], WSServiceError>
) => {
  const { sort, filter, payeeId } = params || {};

  const xWingspanPayerId = useXWingspanPayerId();

  return useWSQuery<IEngagement[], WSServiceError>(
    [QUERY_ENGAGEMENT_LIST_ALL, params, xWingspanPayerId],
    async () => {
      const pageSize = 100;
      const {
        summary: { listSize },
        data
      } = await getEngagements(
        {
          filter: mapEngagementsFilters(filter),
          page: { size: pageSize, number: 1 },
          sort
        },
        xWingspanPayerId
      );

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          getEngagements(
            {
              filter,
              page: { size: pageSize, number: i + 2 },
              sort
            },
            xWingspanPayerId
          )
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
