const FIRST_YEAR_OF_NEW_STATEMENTS = 2024;
export const ARCHIVED_STATEMENTS = "Archived";

function getYearsList(startYear: number): number[] {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}

const statementsYearList = getYearsList(FIRST_YEAR_OF_NEW_STATEMENTS);

export const MAIN_FILTER_OPTIONS = [
  ...statementsYearList
    .reverse()
    .map(year => ({ value: String(year), label: String(year) })),
  { value: ARCHIVED_STATEMENTS, label: ARCHIVED_STATEMENTS }
];

export const LAST_STATEMENT_YEAR = MAIN_FILTER_OPTIONS[0].value;
