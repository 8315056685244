import { servicePayments } from "../servicePayments";
import {
  IPayeeEmailChangeRequest,
  IPayeeEmailChangeResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";
import { PayeeEmailUpdateRequest } from "./getPayeeEmailUpdate";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const updatePayeeEmailUpdate = async (
  { payeeId, targetEmail }: PayeeEmailUpdateRequest,
  orgPayerId?: string
): Promise<IPayeeEmailChangeResponse> => {
  const request: IPayeeEmailChangeRequest = {
    targetEmail
  };

  const { data } = await servicePayments.post(
    `/payee/${payeeId}/email-update`,
    request,
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );

  return data;
};
