import { useHistory, useLocation } from "react-router-dom";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { OLD_TAX_DOCUMENTS_PATH } from "../TaxDocumentsOld/paths";

export const TAX_DOCUMENTS_ROOT_PATH = "/member/tax-documents";

export const TAX_DOCUMENTS_PATH = TAX_DOCUMENTS_ROOT_PATH + "/2024";
export const TAX_DOCUMENTS_DETAILS_PATH =
  TAX_DOCUMENTS_PATH + "/:taxFormId/:submissionId";
export const TAX_DOCUMENTS_REQUEST_CORRECTION_PATH =
  TAX_DOCUMENTS_DETAILS_PATH + "/request-correction";

const useGoToTaxDocumentSubPath = (path: string) => {
  const history = useHistory();

  return (taxFormId: string, submissionId: string) => {
    const url = path
      .replace(":taxFormId", taxFormId)
      .replace(":submissionId", submissionId);

    history.push(url);
  };
};

export const useGoToTaxDocumentByIds = () =>
  useGoToTaxDocumentSubPath(TAX_DOCUMENTS_DETAILS_PATH);

export const useGoToTaxDocumentByTaxForm = () => {
  const redirect = useGoToTaxDocumentSubPath(TAX_DOCUMENTS_DETAILS_PATH);

  return (taxForm: IPayeeTaxFormResponse) => {
    const submission = taxForm.submissions?.[0];

    redirect(
      taxForm.taxFormId,
      taxForm.currentSubmissionId || submission?.taxFormSubmissionId || ""
    );
  };
};

export const useGoToRequestCorrection = () =>
  useGoToTaxDocumentSubPath(TAX_DOCUMENTS_REQUEST_CORRECTION_PATH);

export const useGoToTaxDocumentsDashboard = () => {
  const history = useHistory();
  const location = useLocation();

  return () => {
    if (location.pathname.startsWith(TAX_DOCUMENTS_PATH)) {
      history.push(TAX_DOCUMENTS_PATH);
    } else {
      history.push(OLD_TAX_DOCUMENTS_PATH);
    }
  };
};
