import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { PayeeEmailUpdateRequest } from "../../../services/payees/getPayeeEmailUpdate";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";
import { updatePayeeEmailUpdate } from "../../../services/payees/updatePayeeEmailUpdate";
import { QUERY_PAYEE, QUERY_PAYEES_LIST } from "../keys";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const useConfirmPayeeEmailUpdate = (
  config?: QueryConfig<IPayeeEmailChangeResponse, WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId();

  return useWSMutation(
    (request: PayeeEmailUpdateRequest) =>
      updatePayeeEmailUpdate(request, xWingspanPayerId),
    {
      awaitDependencies(response) {
        return [
          [QUERY_PAYEE, response.sourcePayeeId],
          [QUERY_PAYEE, response.targetPayeeId]
        ];
      },
      dependencies: [QUERY_PAYEES_LIST],
      ...config
    }
  );
};
