import {
  TaxFormStatus,
  TaxFormSubmissionStatus
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormSearchStatus } from "../../../services/search";

export function mapTaxFormStatusesToSearchableStatus(
  taxFormStatus: TaxFormStatus,
  taxFormSubmissionStatus: TaxFormSubmissionStatus
): TaxFormSearchStatus {
  if (taxFormSubmissionStatus === TaxFormSubmissionStatus.Created) {
    return taxFormStatus;
  }
  if (taxFormSubmissionStatus === TaxFormSubmissionStatus.PendingFiling) {
    return TaxFormSubmissionStatus.SubmittedToIrs;
  }

  return taxFormSubmissionStatus;
}
