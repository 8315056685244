import { useWSMutation, WSMutationConfig } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { downloadTaxFormBySecureLink } from "../../../services/taxFormSubmissions";

export const useDownloadTaxFormBySecureLink = (
  config?: WSMutationConfig<Blob, WSServiceError, { token: string }>
) =>
  useWSMutation(({ token }) => downloadTaxFormBySecureLink(token), {
    dependencies: [],
    ...config
  });
