import { WSList, WSText } from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RelatedSubmissions } from "../../../../TaxCorrections/components/RelatedSubmissions";
import { useGoToTaxFormDetails } from "../../../paths";

type Props = {
  taxForm: IPayerTaxFormResponse;
  submissionId: string;
};

export const RelatedForms: React.FC<Props> = ({ taxForm, submissionId }) => {
  const goToTaxForm = useGoToTaxFormDetails();
  if (!taxForm.submissions?.length || taxForm.submissions.length === 1) {
    return null;
  }

  return (
    <WSList gap="L">
      <WSText.Paragraph weight="medium">Related tax forms</WSText.Paragraph>
      <RelatedSubmissions
        viewer={TaxFormViewer.payer}
        taxForm={taxForm}
        currentSubmissionId={submissionId}
        redirectTo={(taxFormId, submissionId) =>
          goToTaxForm(taxFormId, submissionId)
        }
      />
    </WSList>
  );
};
