import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";

export const getDefaultValues = (payee: IPayeeResponse) => {
  const payerOwnedData = payee.payerOwnedData;
  const payeeW9Data = payerOwnedData.payeeW9Data || {};

  return {
    name: payerOwnedData.payeeName || "",
    companyName: payerOwnedData.payeeCompanyName || "",
    tinType: payeeW9Data.tinType
      ? payeeW9Data.tinType
      : payeeW9Data.ein
      ? TinType.Business
      : TinType.Individual,

    individual: {
      firstName: payeeW9Data.firstName,
      lastName: payeeW9Data.lastName,
      ssn: payeeW9Data.ssn
    },
    business: {
      legalBusinessName: payeeW9Data.legalBusinessName,
      disregardedEntityName: payeeW9Data.disregardedEntityName,
      ein: payeeW9Data.ein,
      companyStructure: payeeW9Data.companyStructure
    },
    address: {
      addressLine1: payeeW9Data.addressLine1,
      addressLine2: payeeW9Data.addressLine2,
      city: payeeW9Data.city,
      state: payeeW9Data.state,
      postalCode: payeeW9Data.postalCode,
      country: payeeW9Data.country || "US"
    }
  };
};
