import {
  toWSMoneyString,
  WSControl,
  WSElement,
  WSGrid,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { RouteComponentProps } from "react-router-dom";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { WSQueries } from "../../../../../query/WSQuery";
import React from "react";
import { useTaxFormSubmissionParamsIds } from "../../../paths";
import { EditLayout } from "../../../components/EditLayout";
import { useGoBackOrToParent } from "../../../../../utils/goToParentRoute";
import { DataView } from "../../../../../shared/components/DataView";
import { selectorAddressString } from "../../../../../shared/selectors/selectorAddressString";
import { selectorPayerTaxFormDefaultTinType } from "../../../selectors/selectorPayerTaxFormDefaultTinType";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";
import { getTaxFormExtendedBySubmission } from "../../../utils/getTaxFormExtendedBySubmission";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { WS_LINKS } from "../../../../../types/wsLinks";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";

export const Route1099NECPreview: React.FC<RouteComponentProps> = () => {
  const currentYear = useCurrentTaxFilingYear();
  const { taxFormId, submissionId } = useTaxFormSubmissionParamsIds();
  const onFinish = useGoBackOrToParent();

  const queryPayerTaxForm = usePayerTaxForm(taxFormId);

  return (
    <EditLayout title="1099-NEC preview" wide onBack={onFinish}>
      <WSQueries
        queries={{
          queryPayerTaxForm
        }}
      >
        {({ queryPayerTaxFormData }) => {
          const taxForm = getTaxFormExtendedBySubmission(
            queryPayerTaxFormData,
            submissionId
          );

          const payerDataPreview = taxForm.payerDataPreview;
          const payerName = payerDataPreview?.name;
          const payerAddress = selectorAddressString(payerDataPreview);

          const contractorTINType = selectorPayerTaxFormDefaultTinType(taxForm);
          const contractorFirstNameLastName =
            taxForm.data.w9Info?.firstName && taxForm.data.w9Info?.lastName
              ? `${taxForm.data.w9Info?.firstName} ${taxForm.data.w9Info?.lastName}`.toUpperCase()
              : undefined;
          const contractorLegalBusinessName =
            taxForm.data.w9Info?.legalBusinessName?.toUpperCase();

          const contractorCompanyStructure =
            taxForm.data.w9Info?.companyStructure;

          const hasBothNames =
            contractorFirstNameLastName && contractorLegalBusinessName;

          const isSinglePerson =
            contractorCompanyStructure === CompanyStructure.LlcSingleMember ||
            contractorCompanyStructure === CompanyStructure.SoleProprietorship;

          const isBusinessWithDifferentNames =
            contractorTINType === TinType.Business &&
            contractorLegalBusinessName !== contractorFirstNameLastName;

          let contractorName;

          if (hasBothNames && isSinglePerson) {
            contractorName = `${contractorLegalBusinessName} ${contractorFirstNameLastName}`;
          } else if (hasBothNames && isBusinessWithDifferentNames) {
            contractorName = `${contractorLegalBusinessName}\n${contractorFirstNameLastName}`;
          } else if (contractorTINType === TinType.Business) {
            contractorName = contractorLegalBusinessName;
          } else {
            contractorName = contractorFirstNameLastName;
          }

          const contractorTIN =
            contractorTINType === TinType.Individual
              ? taxForm.data.w9Info?.ssnLastFour
                ? `XXXXX${taxForm.data.w9Info?.ssnLastFour}`
                : undefined
              : taxForm.data.w9Info?.ein;
          const contractorLocalAddress =
            taxForm.data.w9Info?.addressLine1 ||
            taxForm.data.w9Info?.addressLine2
              ? [
                  taxForm.data.w9Info?.addressLine1,
                  taxForm.data.w9Info?.addressLine2
                ]
                  .filter(Boolean)
                  .join(", ")
              : undefined;

          const contractorCityStateZip =
            taxForm.data.w9Info?.city ||
            taxForm.data.w9Info?.state ||
            taxForm.data.w9Info?.postalCode
              ? [
                  taxForm.data.w9Info?.city,
                  taxForm.data.w9Info?.state,
                  taxForm.data.w9Info?.postalCode
                ]
                  .filter(Boolean)
                  .join(", ")
              : undefined;

          const total = selector1099NECTotal(taxForm);
          const nonEmployeeCompensation = toWSMoneyString(total);
          const federalIncomeTaxWithheld = toWSMoneyString(0);
          const stateTaxWithheld = toWSMoneyString(0);
          const stateIncome = toWSMoneyString(total);
          const filingState =
            [payerDataPreview?.filingState].filter(Boolean).join("\n") || "N/A";

          return (
            <WSList gap="2XL">
              <WSGrid gutter="2XL">
                <WSGrid.Item span="12">
                  <WSSectionToolbar title="Nonemployee Compensation" />

                  <DataView
                    data={[
                      {
                        label: "OMB No. 1545-0116",
                        value: "Form 1099-NEC"
                      },
                      {
                        label: "For calendar year",
                        value: taxForm.year
                      }
                    ]}
                  />
                </WSGrid.Item>

                <WSGrid.Item span={{ s: "12", m: "6" }}>
                  <WSGrid gutter="2XL">
                    <WSGrid.Item span={{ s: "6", m: "6" }}>
                      <WSPanel py="L">
                        <WSControl
                          label="VOID"
                          size="S"
                          value={false}
                          type="checkbox"
                          onChange={Boolean}
                          disabled
                        />
                      </WSPanel>
                    </WSGrid.Item>
                    <WSGrid.Item span={{ s: "6", m: "6" }}>
                      <WSPanel py="L">
                        <WSControl
                          label="CORRECTED"
                          size="S"
                          value={false}
                          type="checkbox"
                          onChange={Boolean}
                          disabled
                        />
                      </WSPanel>
                    </WSGrid.Item>
                    <WSGrid.Item span={{ s: "12" }}>
                      <DataView
                        data={[
                          {
                            label:
                              "PAYER’S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.",
                            value: [payerName, payerAddress]
                              .filter(Boolean)
                              .join("\n")
                              .toUpperCase(),
                            fullWidth: true
                          },
                          {
                            label: "PAYER’S TIN",
                            value: payerDataPreview?.ein.toUpperCase()
                          },
                          {
                            label: "RECIPIENT’S TIN",
                            value: contractorTIN?.toUpperCase()
                          },
                          {
                            label: "RECIPIENT’S name",
                            value: contractorName?.toUpperCase()
                          },
                          {
                            label: "Street address (including apt. no.)",
                            value: contractorLocalAddress?.toUpperCase(),
                            fullWidth: true
                          },
                          {
                            label:
                              "City or town, state or province, country, and ZIP or foreign postal code",
                            value: contractorCityStateZip?.toUpperCase(),
                            fullWidth: true
                          },
                          <WSElement>
                            <WSText.ParagraphSm color="gray500">
                              Account number (see instructions)
                            </WSText.ParagraphSm>
                            <WSText.ParagraphSm color="gray300">
                              (Generated when submitted to IRS)
                            </WSText.ParagraphSm>
                          </WSElement>
                        ]}
                      />
                    </WSGrid.Item>
                  </WSGrid>
                </WSGrid.Item>
                <WSGrid.Item span={{ s: "12", m: "6" }}>
                  <WSList gap="2XL">
                    <DataView
                      fullWidth={true}
                      data={[
                        {
                          label: "1. Nonemployee compensation",
                          value: nonEmployeeCompensation
                        },
                        <WSPanel py="L">
                          <WSControl
                            label="2. Payer made direct sales totaling $5,000 or more of consumer products to contractor for resale"
                            size="S"
                            value={false}
                            type="checkbox"
                            onChange={Boolean}
                            disabled
                          />
                        </WSPanel>,
                        <WSPanel py="L" colorBackground="gray50">
                          <WSText.ParagraphSm color="gray300">
                            3. --
                          </WSText.ParagraphSm>
                        </WSPanel>,
                        {
                          label: "4. Federal income tax withheld",
                          value: federalIncomeTaxWithheld
                        },
                        {
                          label: "5. State tax withheld",
                          value: stateTaxWithheld
                        },
                        {
                          label: "6. State",
                          value: filingState
                        },
                        {
                          label: "7. State income",
                          value: stateIncome
                        }
                      ]}
                    />
                  </WSList>
                </WSGrid.Item>
                <WSGrid.Item span={{ s: "12" }}>
                  <WSText.ParagraphXs color="gray500">
                    This preview is based on Form 1099-NEC (Rev. 1-{currentYear}
                    ) from the Department of the Treasury - Internal Revenue
                    Service (
                    <a
                      target="_blank"
                      href={WS_LINKS.ircForm1099NEC}
                      rel="noreferrer"
                    >
                      www.irs.gov/Form1099NEC
                    </a>
                    )
                  </WSText.ParagraphXs>
                </WSGrid.Item>
              </WSGrid>
            </WSList>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
