import { servicePayments } from "../servicePayments";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const downloadPayeeW9 = async (
  payeeId: string,
  orgPayerId?: string
): Promise<Blob> => {
  const { data: blob } = await servicePayments.get(
    `/payee/${payeeId}/download-w9`,
    extendConfigWithXWingspanUserHeader(
      {
        responseType: "blob"
      },
      orgPayerId
    )
  );

  return blob;
};
