import {
  WSCard,
  WSElement,
  WSElementProps,
  WSEmptyState,
  WSPanel
} from "@wingspanhq/fe-component-library";
import { AccountStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryPaymentsCardsDebit } from "../../../../query/paymentCards/queries/useQueryPaymentsCardsDebit";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCards
} from "../../../../query/payments/queries";
import { useAccounts, useMemberProfile } from "../../../../query/users/queries";
import { selectorIsDomesticMember } from "../../../../shared/selectors/selectorIsDomesticMember";
import { PATH_SETTINGS_PAYMENT_METHODS } from "../../../Settings/routes/RoutePaymentMethods";
import { DefaultPayoutMehotd } from "../../../PaymentMethods/components/GetPaid/DefaultPayoutMehotd";
import { NotificationBanner } from "../../../PaymentMethods/components/NotificationBanner";

type Props = WSElementProps;

export const DefaultPayoutMethod: React.FC<Props> = ({ ...elementProps }) => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();
  const queryAccounts = useAccounts();
  const queryPayoutSettingsDebitCards = usePayoutSettingsDebitCards(userId);
  const queryPaymentCards = useQueryPaymentsCardsDebit();
  const queryMember = useMemberProfile(userId);
  const history = useHistory();

  return (
    <WSElement {...elementProps}>
      <NotificationBanner mb="XL" />

      <WSQueries
        queries={{
          queryPayoutSettings,
          queryMember,
          queryAccounts,
          queryPayoutSettingsDebitCards,
          queryPaymentCards
        }}
      >
        {({
          queryPayoutSettings: { data: payoutSettings },
          queryMember: { data: member },
          queryAccounts: { data: accounts },
          queryPayoutSettingsDebitCards: { data: debitCards },
          queryPaymentCards: { data: paymentCards }
        }) => {
          const hasAvailablePayoutMethods =
            accounts.some(
              account =>
                account.status !== AccountStatus.Inactive &&
                (account.canBeUsedFor?.payouts || account.canBeUsedFor?.rtp)
            ) ||
            debitCards.length > 0 ||
            paymentCards.length > 0 ||
            !!queryBankingAccount.data;

          const hasPayoutMethod =
            !!payoutSettings.payoutDestinations?.length ||
            !!queryBankingAccount.data;

          return (
            <WSCard
              divider={true}
              header={{
                label: {
                  text: "Payout method",
                  helperText: "Default method to receive payments from clients"
                },
                value: {
                  pill: {
                    theme: "neutral",
                    text: "Default",
                    badge: true
                  }
                }
              }}
              actions={{
                alignment: "left",
                buttons: [
                  {
                    kind: "Link",
                    label: "Manage payout method",

                    onClick: () => {
                      history.push(`${PATH_SETTINGS_PAYMENT_METHODS}/edit`);
                    }
                  }
                ]
              }}
            >
              <WSPanel noBorder p={"NONE"} data-testid="activePayoutMethod">
                {hasPayoutMethod ? (
                  <DefaultPayoutMehotd
                    payoutSettings={payoutSettings}
                    internalAccount={queryBankingAccount.data}
                  />
                ) : (
                  <WSEmptyState
                    type="card"
                    title="No payout method added"
                    orientation="horizontal"
                    description="Add a default payout method to get paid."
                    size="S"
                    fullWidth
                    buttons={[
                      {
                        label: "Add payout method",
                        kind: "Secondary",
                        onClick: () => {
                          if (selectorIsDomesticMember(member)) {
                            history.push(
                              `${PATH_SETTINGS_PAYMENT_METHODS}/add`
                            );
                          } else {
                            history.push(
                              `${PATH_SETTINGS_PAYMENT_METHODS}/add-international`
                            );
                          }
                        }
                      }
                    ]}
                  />
                )}
              </WSPanel>
            </WSCard>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
