import {
  WSActions,
  WSCard,
  WSControlGroup,
  WSForm,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";

import { CalculationSettings1099Toggle } from "@wingspanhq/payments/dist/interfaces";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdatePayrollSettings } from "../../../../query/payments/mutations";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { ErrorContextKey } from "../../../../services/platform";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import {
  mapCardProcessingFeesLabel,
  mapOffPlatformPaymentsLabel,
  mapReimbursableExpensesLabel
} from "../../constants/lables";

interface CalculationSettingsFormValues {
  offPlatformPayments: CalculationSettings1099Toggle;
  cardProcessingFees: CalculationSettings1099Toggle;
  reimbursableExpenses: CalculationSettings1099Toggle;
}

export const FormCalculationSettings: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();

  const [updatePayrollSettings, updatePayrollSettingsMeta] =
    useUpdatePayrollSettings(userId);
  const qPayrollSettings = usePayrollSettings(userId);

  const onFormSubmit = async (data: CalculationSettingsFormValues) => {
    await updatePayrollSettings(
      {
        calculationSettings1099: {
          offPlatformPayments: data.offPlatformPayments,
          cardProcessingFees: data.cardProcessingFees,
          reimbursableExpenses: data.reimbursableExpenses
        }
      },
      {
        onSuccess: onNext
      }
    );
  };
  return (
    <WSQueries queries={{ qPayrollSettings }}>
      {({ qPayrollSettings: { data: payrollSettings } }) => {
        const { calculationSettings1099 } = payrollSettings;
        return (
          <WSForm<CalculationSettingsFormValues>
            defaultValues={{
              offPlatformPayments: calculationSettings1099?.offPlatformPayments,
              cardProcessingFees: calculationSettings1099?.cardProcessingFees,
              reimbursableExpenses:
                calculationSettings1099?.reimbursableExpenses
            }}
            validationSchema={Yup.object().shape({
              offPlatformPayments: Yup.string().required("Required"),
              cardProcessingFees: Yup.string().required("Required"),
              reimbursableExpenses: Yup.string().required("Required")
            })}
            onSubmit={onFormSubmit}
          >
            <WSCard header="Calculation settings" divider>
              <WSList gap="XL">
                <WSInfoBox title="Consult a tax advisor" theme="info">
                  Wingspan advises you to consult with a tax professional to
                  choose the most suitable calculation options for your business
                </WSInfoBox>
                <WSForm.Field
                  name="offPlatformPayments"
                  component={WSControlGroup}
                  componentProps={{
                    label: "Off-platform payments",
                    type: "radio",
                    disabled:
                      calculationSettings1099?.offPlatformPayments ===
                      CalculationSettings1099Toggle.Include,
                    chip: true,
                    options: [
                      {
                        label:
                          mapOffPlatformPaymentsLabel[
                            CalculationSettings1099Toggle.Include
                          ],
                        value: CalculationSettings1099Toggle.Include
                      },
                      {
                        label:
                          mapOffPlatformPaymentsLabel[
                            CalculationSettings1099Toggle.Exclude
                          ],
                        value: CalculationSettings1099Toggle.Exclude
                      }
                    ]
                  }}
                />
                <WSForm.Field
                  name="cardProcessingFees"
                  component={WSControlGroup}
                  componentProps={{
                    type: "radio",
                    label: "Card processing fees",
                    chip: true,
                    options: [
                      {
                        label:
                          mapCardProcessingFeesLabel[
                            CalculationSettings1099Toggle.Include
                          ],
                        value: CalculationSettings1099Toggle.Include
                      },
                      {
                        label:
                          mapCardProcessingFeesLabel[
                            CalculationSettings1099Toggle.Exclude
                          ],
                        value: CalculationSettings1099Toggle.Exclude
                      }
                    ]
                  }}
                />
                <WSForm.Field
                  name="reimbursableExpenses"
                  component={WSControlGroup}
                  componentProps={{
                    chip: true,
                    label: "Reimbursable expenses",
                    type: "radio",
                    options: [
                      {
                        label:
                          mapReimbursableExpensesLabel[
                            CalculationSettings1099Toggle.Include
                          ],
                        value: CalculationSettings1099Toggle.Include
                      },
                      {
                        label:
                          mapReimbursableExpensesLabel[
                            CalculationSettings1099Toggle.Exclude
                          ],
                        value: CalculationSettings1099Toggle.Exclude
                      }
                    ]
                  }}
                />
                <WSErrorMessage
                  contextKey={ErrorContextKey.Other}
                  error={updatePayrollSettingsMeta.error}
                />
              </WSList>
            </WSCard>
            <WSActions
              mt="3XL"
              alignment="fill"
              buttons={[
                {
                  label: "Cancel",
                  onClick: onBack,
                  type: "button",
                  kind: "Secondary"
                },
                {
                  label: "Save changes",
                  type: "submit",
                  loading: updatePayrollSettingsMeta.isLoading
                }
              ]}
            />
          </WSForm>
        );
      }}
    </WSQueries>
  );
};
