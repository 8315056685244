import {
  WSDivider,
  WSElement,
  WSFlexBox,
  WSForm,
  WSInputTextarea,
  WSPanel,
  WSPill,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { FormPartialIdentificationNumber } from "../../../../shared/components/FormPartialIdentificationNumber";
import { Tooltip } from "../../../../components/Tooltip";
import {
  CORRECTION_REASON_OPTIONS,
  correctionReasonMap
} from "../../constants/corrections";
import {
  TinData,
  TINOrNameCorrectionDiffPreview
} from "../TINOrNameCorrectionDiffPreview";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  PayeeCorrectionReason,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { selectorPayeeTaxFormPayerName } from "../../selectors/selectorPayeeTaxFormPayerName";

export interface TINOrNameCorrectionFormProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  originalTinData: TinData;
  taxFormViewer?: TaxFormViewer;
  showRequestedValue?: boolean;

  correctedTinData?: TinData;
  reasonForChange?: string;
  otherReason?: string;
  identificationNumberType?: TinType;
  isOverride?: boolean;
}

export const TINOrNameCorrectionForm: React.FC<
  TINOrNameCorrectionFormProps
> = ({
  taxForm,
  originalTinData,
  taxFormViewer = TaxFormViewer.Payee,
  showRequestedValue,
  correctedTinData,
  reasonForChange,
  otherReason,
  identificationNumberType,
  isOverride
}) => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const payerName = selectorPayeeTaxFormPayerName(taxForm);
  return (
    <WSQueries queries={{ queryMember }}>
      {({ queryMemberData: member }) => (
        <WSPanel>
          <TINOrNameCorrectionDiffPreview
            noPanel={true}
            taxForm={taxForm}
            originalTinData={originalTinData}
            correctedTinData={correctedTinData!}
            reasonForChange={reasonForChange!}
            identificationNumberType={identificationNumberType!}
            otherReason={otherReason}
            showRequestedValue={showRequestedValue}
          />

          <WSDivider my="XL" />

          {isOverride ? (
            <WSPanel>
              <WSFlexBox.CenterY mb="L" justify="space-between">
                <WSText.Paragraph weight="medium">
                  Taxpayer Identification Number (TIN) or name correction
                </WSText.Paragraph>
                <WSPill type="dark" text="Overwritten by you" theme="violet" />
              </WSFlexBox.CenterY>
              <FormPartialIdentificationNumber
                name="identificationNumberType"
                ssnProvided={!!member.profile.ssnProvided}
                isTaxFormCorrection={true}
                taxFormViewer={taxFormViewer}
                mb="L"
              />
            </WSPanel>
          ) : (
            <FormPartialIdentificationNumber
              name="identificationNumberType"
              ssnProvided={!!member.profile.ssnProvided}
              isTaxFormCorrection={true}
              taxFormViewer={taxFormViewer}
              mb="L"
            />
          )}

          {taxFormViewer === TaxFormViewer.Payer ? null : (
            <>
              <WSElement mb="2XL">
                <WSText.ParagraphSm color="gray500" weight="book" mb="XS">
                  Why are you requesting a change? *{" "}
                  <Tooltip placement="right">
                    <WSText weight="medium" color="gray100" mb="M">
                      Reasons for change
                    </WSText>
                    <WSText.ParagraphSm as="ol" color="gray100">
                      {Object.keys(correctionReasonMap).map((reason, index) => (
                        <WSText.ParagraphSm as="li" mb="XS" color="gray100">
                          <WSText.ParagraphSm
                            weight="medium"
                            color="gray100"
                            inline={reason !== PayeeCorrectionReason.Other}
                          >
                            {`${index + 1}. ${
                              CORRECTION_REASON_OPTIONS.find(
                                ro => ro.value === reason
                              )?.label
                            }:`}
                          </WSText.ParagraphSm>{" "}
                          {correctionReasonMap[reason as PayeeCorrectionReason]}
                        </WSText.ParagraphSm>
                      ))}
                    </WSText.ParagraphSm>
                  </Tooltip>
                </WSText.ParagraphSm>
                <WSForm.Field
                  name="reasonForChange"
                  component={WSSelect}
                  componentProps={{
                    required: true,
                    placeholder: "Select reason",
                    options: CORRECTION_REASON_OPTIONS
                  }}
                />
              </WSElement>
              <WSForm.Value name="reasonForChange">
                {reasonForChange => (
                  <WSElement
                    mb="2XL"
                    hidden={reasonForChange !== PayeeCorrectionReason.Other}
                  >
                    <WSForm.Field
                      label="Comments"
                      name="otherReason"
                      component={WSInputTextarea}
                      componentProps={{
                        required: true,
                        helperText: `Please provide specific details that will help ${payerName} to correct your tax form.`,
                        placeholder:
                          "Provide specific details about the correction."
                      }}
                    />
                  </WSElement>
                )}
              </WSForm.Value>
            </>
          )}
        </WSPanel>
      )}
    </WSQueries>
  );
};
