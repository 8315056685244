import {
  WSButton,
  WSCard,
  WSTimeline,
  WSTimelineItemProps
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo } from "react";
import {
  useGoToTaxFormHistory,
  useTaxFormSubmissionParamsIds
} from "../../../paths";
import { getTimeLineList } from "./getTimelineItem";

type Props = {
  taxForm: IPayerTaxFormResponse;
};

export const HistorySection: React.FC<Props> = ({ taxForm }) => {
  const { taxFormId, submissionId } = useTaxFormSubmissionParamsIds();
  const goToHistory = useGoToTaxFormHistory();

  const timelineItems = useMemo(() => {
    const timeline: WSTimelineItemProps[] = getTimeLineList(taxForm);

    const recent = timeline.slice(0, 5);

    if (timeline.length > 5) {
      recent.push({
        content: (
          <WSButton.Link
            onClick={() => {
              goToHistory(taxFormId, submissionId);
            }}
          >
            View all history and details
          </WSButton.Link>
        )
      });
    }

    return recent;
  }, [goToHistory, submissionId, taxForm, taxFormId]);

  const isHistoryExist = !!timelineItems.length;

  if (!isHistoryExist) {
    return null;
  }

  return (
    <WSCard
      divider
      header={{
        label: {
          text: "Recent history"
        }
      }}
    >
      <WSTimeline items={timelineItems} />
    </WSCard>
  );
};
