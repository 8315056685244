import {
  IPayerTaxFormResponse,
  ITaxFormW9Info,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { useWSMutation } from "../../../../query/helpers";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYER,
  QUERY_PAYMENTS_TAXFORM_PAYER
} from "../../../../query/taxForm/keys";
import { paymentsService } from "../../../../services/payments";
import { INDIVIDUAL_COMPANY_STRUCTURE_SELECT_OPTIONS } from "../../../../shared/constants/member";
import { getChangedData } from "../../../../utils/getChangedData";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { selectorPayerTaxFormDefaultTinType } from "../../selectors/selectorPayerTaxFormDefaultTinType";
import { FormTaxpayerInformation } from "../FormTaxpayerInformation";
import { FormTaxpayerInformationData } from "../FormTaxpayerInformation/types";

type Props = {
  taxForm: IPayerTaxFormResponse;
  onSuccess?: (taxForm: IPayerTaxFormResponse) => void;
  onBack?: (skipConfirm?: boolean) => void;
  onCancel?: () => void;
  isEdit?: boolean;
  skipEventHistory?: boolean;
};

export const FormEditRecipient: React.FC<Props> = ({
  taxForm,
  onSuccess,
  isEdit,
  onCancel,
  onBack,
  skipEventHistory
}) => {
  const [submit, submitMeta] = useWSMutation<
    IPayerTaxFormResponse,
    WSServiceError,
    FormTaxpayerInformationData
  >(
    async data => {
      const newW9Info: ITaxFormW9Info = {
        tinType: data.identificationNumberType,
        ein:
          data.ein && data.identificationNumberType === TinType.Business
            ? data.ein
            : undefined,
        ssn:
          data.ssn && data.identificationNumberType === TinType.Individual
            ? data.ssn
            : undefined,
        firstName: data.firstName,
        lastName: data.lastName,
        legalBusinessName: data.businessName,
        disregardedEntityName: data.optionalBusinessName
          ? data.optionalBusinessName
          : undefined,
        companyStructure: data.taxClassification,
        addressLine1: data.address.addressLine1,
        addressLine2: data.address.addressLine2,
        city: data.address.city,
        state: data.address.state,
        postalCode: data.address.zipCode,
        country: data.address.country,
        exemptFatcaCode: data.exemptFatcaReportingCode
          ? data.exemptFatcaReportingCode
          : undefined,
        exemptPayeeCode: data.exemptPayeeCode ? data.exemptPayeeCode : undefined
      };

      return await paymentsService.taxForm.update(
        taxForm.taxFormId,
        {
          payerConfirmedW9Info: false,
          data: {
            w9Info: getChangedData(taxForm.data?.w9Info ?? {}, newW9Info)
          }
        },
        {
          skipEventHistory
        }
      );
    },
    {
      onSuccess,
      dependencies: [
        [QUERY_PAYMENTS_TAXFORM_PAYER, taxForm.taxFormId],
        QUERY_PAYMENTS_TAXFORM_LIST_PAYER
      ]
    }
  );

  return (
    <FormTaxpayerInformation
      onSubmit={submit}
      onBack={onBack}
      onCancel={onCancel}
      error={submitMeta.error}
      isEdit={isEdit}
      isLoading={submitMeta.isLoading}
      isSSNProvided={!!taxForm.data.w9Info?.ssnLastFour}
      defaultValues={{
        identificationNumberType:
          selectorPayerTaxFormDefaultTinType(taxForm) || TinType.Individual,
        ein: taxForm.data.w9Info?.ein || "",
        ssn: "",
        firstName: taxForm.data.w9Info?.firstName || "",
        lastName: taxForm.data.w9Info?.lastName || "",
        businessName: taxForm.data.w9Info?.legalBusinessName || "",
        optionalBusinessName: taxForm.data.w9Info?.disregardedEntityName || "",
        taxClassification:
          taxForm.data.w9Info?.companyStructure ||
          INDIVIDUAL_COMPANY_STRUCTURE_SELECT_OPTIONS[0].value,
        address: {
          addressLine1: taxForm.data.w9Info?.addressLine1 || "",
          addressLine2: taxForm.data.w9Info?.addressLine2 || "",
          city: taxForm.data.w9Info?.city || "",
          state: taxForm.data.w9Info?.state || "",
          zipCode: taxForm.data.w9Info?.postalCode || "",
          country: taxForm.data.w9Info?.country || ""
        },
        exemptFatcaReportingCodeVisible: !!taxForm.data.w9Info?.exemptFatcaCode,
        exemptFatcaReportingCode: taxForm.data.w9Info?.exemptFatcaCode || "",
        exemptPayeeCodeVisible: !!taxForm.data.w9Info?.exemptPayeeCode,
        exemptPayeeCode: taxForm.data.w9Info?.exemptPayeeCode || ""
      }}
    />
  );
};
