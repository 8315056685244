import { servicePayments } from "../servicePayments";
import {
  IPayeeEngagementCreateRequest,
  IPayeeEngagementResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export const createPayeeEngagement = async (
  payeeId: string,
  request: IPayeeEngagementCreateRequest,
  orgPayerId: string | undefined
): Promise<IPayeeEngagementResponse> => {
  const { data } = await servicePayments.post(
    `/payee/${payeeId}/engagement`,
    request,
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );

  return data;
};
