import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { useMemo } from "react";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../constants/corrections";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "./getDefaultValuesForCorrectionsForm";
import { selectorAddressString } from "../../../shared/selectors/selectorAddressString";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces/address";
import { toWSMoneyString } from "@wingspanhq/fe-component-library";

export function useRequestCorrectionFields(
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse,
  correctionType: string
) {
  const fields = useMemo(() => {
    if (
      ![CORRECTION_AMOUNT, CORRECTION_ADDRESS, CORRECTION_TIN_OR_NAME].includes
    ) {
      throw new Error(`Invalid correction type: ${correctionType}`);
    }

    if (!taxForm) {
      throw new Error("Tax form is required");
    }

    if (correctionType === CORRECTION_AMOUNT) {
      return [
        {
          label: "1099-NEC total",
          value: toWSMoneyString(taxForm.data?.totalAmount)
        }
      ];
    } else if (correctionType === CORRECTION_ADDRESS) {
      const address = getPayeeFormW9Address(taxForm);
      const addressString = selectorAddressString({
        ...(address as IAddress)
      });
      return [
        {
          label: "Address",
          value: addressString
        }
      ];
    } else if (correctionType === CORRECTION_TIN_OR_NAME) {
      const originalTinData = getPayeeTINorName(taxForm);
      const identificationNumberType = originalTinData?.tinType;
      return [
        ...(identificationNumberType === TinType.Business
          ? [
              {
                label: "Business name",
                value: originalTinData.businessName ?? "-"
              }
            ]
          : [
              {
                label: "Name",
                value:
                  [originalTinData.firstName, originalTinData.lastName]
                    .filter(Boolean)
                    .join(" ") ?? "-"
              }
            ]),
        {
          label: "Federal tax classification",
          value: selectorTaxClassificationLabel(
            originalTinData.taxClassification
          )
        },
        {
          label: "Taxpayer identification number",
          value:
            identificationNumberType === TinType.Business
              ? `${originalTinData.ein ?? "-"} (EIN)`
              : "••••••••• (SSN)"
        }
      ];
    }

    return [];
  }, [correctionType, taxForm]);

  return fields;
}
