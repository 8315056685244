import { useHistory, useRouteMatch } from "react-router-dom";
import { TaxFormSubmissionViewFilters } from "../../query/search/taxForm/queries/filtering";
import { toURLQuerySearch } from "../../shared/hooks/useUrlQuery";

export const TAX_FILING_PATH = "/member/tax-filing";
export const TAX_FILING_1099_FILING_PATH = TAX_FILING_PATH + "/1099-filing";
export const TAX_FILING_1099_FILING_DASHBOARD_PATH =
  TAX_FILING_1099_FILING_PATH + "/dashboard";
export const TAX_FILING_1099_FILING_DASHBOARD_SEARCH_PATH =
  TAX_FILING_1099_FILING_DASHBOARD_PATH + "/search";

export const TAX_FILING_1099_FILING_DASHBOARD_TABS_PATH =
  TAX_FILING_1099_FILING_DASHBOARD_PATH +
  "/:path(all|needs-action|ready|submitted|excluded)";

export const TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH =
  TAX_FILING_1099_FILING_PATH + "/generate-tax-forms";

export const TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_DETAILS_PATH =
  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH + "/:bulkBatchId";

export const TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_PROGRESS_PATH =
  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_DETAILS_PATH + "/progress";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH =
  TAX_FILING_1099_FILING_PATH + "/details/:taxFormId/:submissionId";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH =
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH + "/new-correction";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_REVIEW_CORRECTION_PATH =
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH + "/review-correction";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_VIEW_CORRECTION_PATH =
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH + "/view-correction";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_CONTRACTOR_EDIT_PATH =
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH + "/edit-contractor";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_AMOUNT_EDIT_PATH =
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH + "/edit-amount";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_HISTORY =
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH + "/history";

export const TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PREVIEW_1099_NEC_PATH =
  TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH + "/preview-1099-nec";

export const TAX_FILING_1099_FILING_PREVIEW_PATH =
  TAX_FILING_1099_FILING_PATH + "/preview";
export const TAX_FILING_1099_FILING_SETTINGS_PATH =
  TAX_FILING_1099_FILING_PATH + "/settings";
export const TAX_FILING_1099_FILING_UPDATE_SUPPORT_INFORMATION_PATH =
  TAX_FILING_1099_FILING_SETTINGS_PATH + "/support-information";
export const TAX_FILING_1099_FILING_UPDATE_STATE_FILING_SETTINGS_PATH =
  TAX_FILING_1099_FILING_SETTINGS_PATH + "/state-filing";
export const TAX_FILING_1099_FILING_UPDATE_CALCULATION_SETTINGS_PATH =
  TAX_FILING_1099_FILING_SETTINGS_PATH + "/calculation";

export const TAX_FILING_1099_FILING_SUBMISSION_PATH =
  TAX_FILING_1099_FILING_PATH + "/submission";
export const TAX_FILING_1099_FILING_SUBMISSION_EDIT_PAYER_INFO_PATH =
  TAX_FILING_1099_FILING_SUBMISSION_PATH + "/edit-payer-information";
export const TAX_FILING_1099_FILING_SUBMISSION_EDIT_STATE_FILING_PATH =
  TAX_FILING_1099_FILING_SUBMISSION_PATH + "/edit-state-filing";

export const useGoToTaxFormDetails = () => {
  const goTo = useGoToTaxFormSubPath(
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PATH
  );

  return (taxFormId: string, submissionId: string) => {
    goTo(taxFormId, submissionId);
  };
};

const useGoToTaxFormSubPath = (path: string) => {
  const history = useHistory();

  return (taxFormId: string, submissionId: string) => {
    const url = path
      .replace(":taxFormId", taxFormId)
      .replace(":submissionId", submissionId);

    history.push(url);
  };
};

export const useTaxFormSubmissionParamsIds = (): {
  taxFormId: string;
  submissionId: string;
} => {
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;

  return {
    taxFormId,
    submissionId
  };
};

export const useGoToTaxFormReviewCorrection = () =>
  useGoToTaxFormSubPath(
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_REVIEW_CORRECTION_PATH
  );

export const useGoToTaxFormViewCorrection = () =>
  useGoToTaxFormSubPath(
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_VIEW_CORRECTION_PATH
  );

export const useGoToTaxFormNewCorrection = () =>
  useGoToTaxFormSubPath(
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH
  );

export const useGoToTaxFormEditContractor = () =>
  useGoToTaxFormSubPath(
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_CONTRACTOR_EDIT_PATH
  );

export const useGoToTaxForm1099NECPreview = () =>
  useGoToTaxFormSubPath(
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_PREVIEW_1099_NEC_PATH
  );

export const useGoToTaxFormHistory = () =>
  useGoToTaxFormSubPath(TAX_FILING_1099_FILING_TAX_FORM_DETAILS_HISTORY);

export const useGoToTaxFormEditAmount = () =>
  useGoToTaxFormSubPath(
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_AMOUNT_EDIT_PATH
  );

export const useGoToGenerateTaxFormDetails = () => {
  const history = useHistory();

  return (bulkBatchId: string) => {
    history.push(
      TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_DETAILS_PATH.replace(
        ":bulkBatchId",
        bulkBatchId
      )
    );
  };
};

export const useGoToGenerateTaxFormProgress = () => {
  const history = useHistory();

  return (bulkBatchId: string) => {
    history.push(
      TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_PROGRESS_PATH.replace(
        ":bulkBatchId",
        bulkBatchId
      )
    );
  };
};

export const useGoToSubmissionTaxFormProgress = () => {
  const history = useHistory();

  return (bulkBatchId: string) => {
    history.push(
      `${TAX_FILING_1099_FILING_SUBMISSION_PATH}/submitting/${bulkBatchId}`
    );
  };
};

export const useGoToTaxFiling = () => {
  const history = useHistory();

  return () => {
    history.push(TAX_FILING_PATH);
  };
};

export const useGoToTaxFilingDashboard = () => {
  const history = useHistory();

  return (filters?: TaxFormSubmissionViewFilters) => {
    history.push({
      pathname: TAX_FILING_1099_FILING_DASHBOARD_PATH + "/all",
      search: filters ? toURLQuerySearch(filters) : ""
    });
  };
};
