import { useWSMutation, WSMutationConfig } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { createTaxFormSubmissionSecureLink } from "../../../services/taxFormSubmissions";
import { QUERY_PAYMENTS_TAXFORM_PAYER } from "../../taxForm/keys";
import { QUERY_TAX_FORM_SUBMISSION_ROWS } from "../../search/taxForm/queries/useTaxFormSubmissionRowsQuery";

export const useCreateTaxFormSubmissionSecureLink = (
  config?: WSMutationConfig<
    ITaxFormSubmissionResponse,
    WSServiceError,
    { taxFormId: string; submissionId: string }
  >
) =>
  useWSMutation(
    ({ taxFormId, submissionId }) =>
      createTaxFormSubmissionSecureLink(taxFormId, submissionId),
    {
      dependencies: [
        QUERY_PAYMENTS_TAXFORM_PAYER,
        QUERY_TAX_FORM_SUBMISSION_ROWS
      ],
      ...config
    }
  );
