import {
  useIsMobile,
  WSElement,
  WSScreen
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRefDimensions } from "../../utils/useRefDimensions";
import { AccountIsPausedModal } from "../AccountIsPausedModal/AccountIsPausedModal";
import { BackToAdminAccountBanner } from "../BackToAdminAccountBanner/BackToAdminAccountBanner";
import { BackToRequestingUserAccountBanner } from "../BackToRequestingUserAccountBanner/BackToRequestingUserAccountBanner";
import { MembershipUpgradeModal } from "../Membership/MembershipUpgradeModal";
import { SideNav } from "../SideNav/SideNav.component";
import { SubscriptionBanner } from "../SubscriptionBanner/SubscriptionBanner.component";
import styles from "./styles.module.scss";
import { SideNavHeader } from "../SideNav/SideNavHeader";
import { AppFooter } from "../../shared/components/AppFooter";

export const BaseAppLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);
  const [isSideNavOpened, setIsSideNavOpened] = React.useState(false);
  const isMobile = useIsMobile();

  const headerRef = useRef<HTMLElement | null>(null);
  const headerDimensions = useRefDimensions(headerRef);

  useEffect(() => {
    if (isDrawerOpened) {
      document.body.classList.add(styles.bodyOverflowHidden);
    } else {
      document.body.classList.remove(styles.bodyOverflowHidden);
    }
  }, [isDrawerOpened]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (
      searchParams.get("showModal") === "referrals" ||
      searchParams.get("bigHappyFamily") === "hellYeah"
    ) {
      setIsDrawerOpened(true);
    }
  }, [location]);

  return (
    <>
      <AccountIsPausedModal />
      <MembershipUpgradeModal />

      <WSElement
        className={styles.container}
        key="container"
        data-navigationisopen={isMobile ? isDrawerOpened : isSideNavOpened}
        data-testid="appContainer"
        data-headerheight={headerDimensions.height}
      >
        <WSElement ref={headerRef} className={styles.header} key="header">
          <WSScreen.Mobile>
            <SideNavHeader
              isDrawerOpened={isDrawerOpened}
              setIsDrawerOpened={setIsDrawerOpened}
            />
          </WSScreen.Mobile>

          <BackToAdminAccountBanner />
          <BackToRequestingUserAccountBanner />
          <SubscriptionBanner />
        </WSElement>

        <SideNav
          isDrawerOpened={isDrawerOpened}
          setIsDrawerOpened={setIsDrawerOpened}
          isSideNavOpened={isSideNavOpened}
          setIsSideNavOpened={setIsSideNavOpened}
          desktopTopPadding={headerDimensions.height}
        />

        <WSElement
          as="main"
          className={styles.main}
          key="main"
          style={{
            paddingTop: headerDimensions.height
          }}
        >
          <WSElement className={styles.content} key="content">
            {children}
          </WSElement>
          <AppFooter />
        </WSElement>
      </WSElement>
    </>
  );
};
