import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useUrlQueryFilters } from "../../../../../utils/router";
import {
  WSCentered,
  WSFilters,
  WSPage
} from "@wingspanhq/fe-component-library";
import { SearchPanel } from "../../../../Invoicing/components/SearchPanel";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { useBrowserPageTitle } from "../../../../../components/BrowserPageTitle/BrowserPageTitle";
import { TAX_FILING_1099_FILING_DASHBOARD_PATH } from "../../../paths";
import { WSQueries } from "../../../../../query/WSQuery";
import { NEC1099FilingTable } from "../RouteDashboard/NEC1099FilingTable";
import { TableNoDataMessage } from "../RouteDashboard/TableNoDataMessage";
import { useAllOrganizationUsers } from "../../../../../query/users/queries";
import {
  createTaxFormSubmissionListRequest,
  getTaxFormSubmissionViewDefaultFilters,
  TaxFormSubmissionViewFilters
} from "../../../../../query/search/taxForm/queries/filtering";
import { TaxFormSubmissionRequest } from "../../../../../services/search";
import { useTaxFormSubmissionRowsQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";
import { useTaxFormSubmissionRowsSummaryQuery } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";

export const RouteTaxFormsSearch: React.FC = () => {
  const history = useHistory();

  const currentYear = useCurrentTaxFilingYear();
  useBrowserPageTitle(`1099 Filing Dashboard ${currentYear}`);

  const defaultFilters = getTaxFormSubmissionViewDefaultFilters();

  const { filters, setFilters } =
    useUrlQueryFilters<TaxFormSubmissionViewFilters>(defaultFilters);

  const queryOrgAccounts = useAllOrganizationUsers();

  const enabled = !!filters.searchString;

  const currentRequest = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(currentYear, filters, {});
  }, [filters.searchString]);

  const queryTaxFormSubmissionRows = useTaxFormSubmissionRowsQuery(
    currentRequest,
    {
      enabled
    }
  );

  const queryTaxFormRowSearchCount = useTaxFormSubmissionRowsSummaryQuery(
    currentRequest,
    {
      enabled
    }
  );

  return (
    <WSPage>
      <SearchPanel
        mb="L"
        context="tax-filing"
        onBack={() => {
          history.push(TAX_FILING_1099_FILING_DASHBOARD_PATH);
        }}
        value={filters.searchString || ""}
        onSearch={newValue => {
          setFilters({
            ...filters,
            searchString: newValue
          });
        }}
        onClear={() => {
          setFilters({
            ...filters,
            searchString: undefined
          });
        }}
        placeholder="Search tax forms"
      />

      {filters.searchString && (
        <WSQueries queries={{ queryTaxFormSubmissionRows, queryOrgAccounts }}>
          {({ queryTaxFormSubmissionRowsData, queryOrgAccountsData }) => {
            return (
              <>
                <WSFilters
                  my="L"
                  values={{}}
                  onFilter={() => {}}
                  info={{
                    count: queryTaxFormRowSearchCount.data?.listSize ?? 0
                  }}
                />
                {queryTaxFormSubmissionRowsData.length > 0 ? (
                  <NEC1099FilingTable
                    queryTaxFormSubmissions={queryTaxFormSubmissionRows}
                    organizations={queryOrgAccountsData}
                  />
                ) : (
                  <WSCentered span={{ m: "6" }}>
                    <TableNoDataMessage search={filters.searchString} />
                  </WSCentered>
                )}
              </>
            );
          }}
        </WSQueries>
      )}
    </WSPage>
  );
};
