import { servicePayments } from "../servicePayments";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";
import qs from "qs";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export type PayeeEmailUpdateRequest = {
  payeeId: string;
  targetEmail: string;
};

export const getPayeeEmailUpdate = async (
  { payeeId, targetEmail }: PayeeEmailUpdateRequest,
  orgPayerId?: string
): Promise<IPayeeEmailChangeResponse> => {
  const { data } = await servicePayments.get(
    `/payee/${payeeId}/email-update?${qs.stringify({ targetEmail })}`,
    extendConfigWithXWingspanUserHeader({}, orgPayerId)
  );

  return data;
};
