import {
  WSFilterValue,
  WSTableColumnSortDirection
} from "@wingspanhq/fe-component-library";
import QueryString from "qs";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isSerializedDate } from "../../utils/serviceHelper";

export interface UrlQueryFilter
  extends Partial<Record<string, WSFilterValue>> {}

export interface UrlQuerySort<Field extends string = string> {
  field: Field;
  direction: WSTableColumnSortDirection;
}

export interface UrlQuery<
  Filter extends UrlQueryFilter = UrlQueryFilter,
  SortField extends string = string
> {
  filter: Filter;
  sort?: UrlQuerySort<SortField>;
}

export const toURLQuerySearch = (obj: any) => {
  return QueryString.stringify(obj);
};

export function useUrlQuery<
  Filter extends UrlQueryFilter = UrlQueryFilter,
  SortField extends string = string
>() {
  const history = useHistory();
  const location = useLocation();

  const urlQuery: UrlQuery<Filter, SortField> = useMemo(() => {
    const parsed = QueryString.parse(location.search.slice(1), {
      // synced with BE limit https://github.com/wingspanHQ/routing/pull/96/files
      arrayLimit: 200,
      decoder: function (str, defaultDecoder, charset) {
        const decodedValue = defaultDecoder(str, defaultDecoder, charset);
        if (isSerializedDate(decodedValue)) {
          return new Date(decodedValue);
        } else {
          return decodedValue;
        }
      }
    });

    if (!parsed.filter) {
      parsed.filter = {};
    }

    return parsed as unknown as UrlQuery<Filter, SortField>;
  }, [location.search]);

  const setUrlQuery = useCallback(
    (
      newQueryOrFn:
        | UrlQuery<Filter, SortField>
        | ((prev: UrlQuery<Filter, SortField>) => UrlQuery<Filter, SortField>)
    ) => {
      let newQuery: UrlQuery<Filter, SortField>;

      if (typeof newQueryOrFn === "function") {
        newQuery = newQueryOrFn(urlQuery);
      } else {
        newQuery = newQueryOrFn;
      }

      history.push({
        search: toURLQuerySearch(newQuery)
      });
    },
    [history, urlQuery]
  );

  return {
    urlQuery,
    setUrlQuery
  };
}
