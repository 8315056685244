import get from "lodash/get";
import React, { ComponentProps } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { WSElementProps } from "../WSElement/WSElement.component";
import { WSInputText } from "../WSInputText/WSInputText";

export interface WSFormFieldProps<Component extends React.ElementType>
  extends WSElementProps {
  name: string;
  label?: string;
  description?: string;
  component: Component;
  componentProps?: Omit<
    ComponentProps<Component>,
    "name" | "value" | "onChange"
  >;
  onChange?: (newValue: any) => void;
}

type InputComponent = typeof WSInputText;

export const WSFormField = <Component extends React.ElementType>({
  name,
  label,
  description,
  component,
  componentProps,
  onChange: onChangeProp,
  defaultValue,
  ...elementProps
}: WSFormFieldProps<Component>) => {
  const { control, errors } = useFormContext();

  const Input = component as InputComponent;
  const error = get(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ ref, ...props }) => (
        <Input
          ref={ref}
          label={label}
          helperText={description}
          {...props}
          {...componentProps}
          {...elementProps}
          status={error ? "error" : componentProps?.status}
          message={error ? error.message : componentProps?.message}
          data-error={error ? error.message : undefined}
          onChange={(value, ...otherArgs) => {
            props.onChange(value);
            onChangeProp?.(value);
          }}
        />
      )}
    />
  );
};
