import { WSElement, WSInfoBox, WSText } from "@wingspanhq/fe-component-library";
import { ProgressBar } from "../../../MemberOnboarding/components/ProgressBar/ProgressBar";

type Props = {
  ms: number;
  onComplete: () => void;
};

export const RecipientVerificationProgress: React.FC<Props> = ({
  ms,
  onComplete
}) => {
  return (
    <WSElement>
      <ProgressBar mt="L" mb="XL" ms={ms} onComplete={onComplete} />

      <WSInfoBox mb="M">
        We are confirming the recipient TIN with the IRS. This can take up to 10
        seconds.
      </WSInfoBox>

      <WSText.ParagraphSm color="gray400">
        Do not close this window while confirmation is in progress.
      </WSText.ParagraphSm>
    </WSElement>
  );
};
