import {
  WSActions,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";

import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import { useGoToRequestCorrection } from "../../../paths";

interface RequestCorrectionWarningModalProps {
  onClose: () => void;
  taxFormId: string;
  submissionId: string;
}

export const RequestCorrectionWarningModal: React.FC<
  RequestCorrectionWarningModalProps
> = ({ taxFormId, submissionId, onClose }) => {
  const goToRequestCorrection = useGoToRequestCorrection();

  const onContinue = () => {
    onClose();
    goToRequestCorrection(taxFormId, submissionId);
  };

  return (
    <>
      <WSText.Paragraph weight="medium" mb="M">
        Important, please read:
      </WSText.Paragraph>
      <WSMessageBox.Info noBorder mb="XL">
        <TextBulleted color="gray500" mb="2XL">
          <li>
            Only one (1) correction request can be made through Wingspan to your
            payer for a tax year. Please ensure all the information in your
            correction request is accurate before submitting to your payer.
          </li>
          <li>
            Once your request for correction has been submitted, it cannot be
            cancelled.
          </li>
          <li>
            The payer company has sole discretion to accept or reject the
            requested change(s). They may reach out to you regarding the
            requested change(s).
          </li>
          <li>
            Changes to Taxpayer Identification Number (EIN or SSN), name, tax
            classification, or address - once you submit the correction request,
            you will be prompted to optionally update your Wingspan profile
            (whether the correction request is accepted or not by the payer for
            tax purposes) to ensure the most up-to-date information is reflected
            in Wingspan.
          </li>
        </TextBulleted>
      </WSMessageBox.Info>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            kind: "Primary",
            onClick: onContinue
          },
          {
            label: "Cancel",
            kind: "Tertiary",
            onClick: onClose
          }
        ]}
      />
    </>
  );
};
