import {
  WSDivider,
  WSElement,
  WSFlexBox,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";

import { TaxFormViewer } from "../../../TaxDocuments/types";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import { OriginalValuePreview } from "../OriginalValuePreview";
import { CORRECTION_ADDRESS } from "../../constants/corrections";
import { FormPartialAddress } from "../../../Onboarding/components/FormPartialAddress";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";

export interface AddressCorrectionFormProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  originalAddress: IAddress;
  showRequestedValue?: boolean;
  correctedAddress?: IAddress;
  isOverride?: boolean;
}

export const AddressCorrectionForm: React.FC<AddressCorrectionFormProps> = ({
  taxForm,
  taxFormViewer = TaxFormViewer.Payee,
  originalAddress,
  showRequestedValue,
  correctedAddress,
  isOverride
}) => {
  return (
    <WSPanel>
      <WSText.Paragraph mb="M" weight="medium">
        Address correction
      </WSText.Paragraph>

      <OriginalValuePreview
        mb="XL"
        taxForm={taxForm}
        correctionType={CORRECTION_ADDRESS}
      />

      <WSText.ParagraphSm mb="XL" color="gray500">
        {taxFormViewer === TaxFormViewer.Payee
          ? "The address has changed or is not accurate."
          : "The recipient's address is not accurate."}
      </WSText.ParagraphSm>

      <WSDivider my="XL" />

      {showRequestedValue ? (
        <WSElement mb="XL">
          <CorrectedValuePreview
            correctionType={CORRECTION_ADDRESS}
            taxForm={taxForm}
            taxFormViewer={taxFormViewer}
            correctionData={{
              addressCorrectionData: {
                address: correctedAddress!
              }
            }}
          />
        </WSElement>
      ) : null}

      {isOverride ? (
        <WSPanel>
          <WSFlexBox.CenterY mb="L" justify="space-between">
            <WSText.Paragraph weight="medium">
              Address correction
            </WSText.Paragraph>
            <WSPill type="dark" text="Overwritten by you" theme="violet" />
          </WSFlexBox.CenterY>
          <FormPartialAddress
            name="formW9Address"
            label={
              taxFormViewer === TaxFormViewer.Payee
                ? "Your business address"
                : "Recipient's business address"
            }
            country={originalAddress.country}
          />
        </WSPanel>
      ) : (
        <FormPartialAddress
          name="formW9Address"
          label={
            taxFormViewer === TaxFormViewer.Payee
              ? "Your business address"
              : "Recipient's business address"
          }
          country={originalAddress.country}
        />
      )}
    </WSPanel>
  );
};
