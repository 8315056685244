import React from "react";

import { WSButton } from "@wingspanhq/fe-component-library";
import { useHistory, useLocation } from "react-router-dom";
import { PlatformErrorsMapping } from "../services/platform";
import { getFullStorySessionId } from "./fullStory";

import { openIntercom, openIntercomMessage } from "../shared/utils/intercom";

export const FSButton: React.FC = ({ children }) => {
  return (
    <WSButton.Link
      mx="XS"
      onClick={() => {
        const fsId = getFullStorySessionId();

        fsId
          ? openIntercomMessage(
              `Hi there! I just got an error #${fsId}. Could you help me with it?\n\n`
            )
          : openIntercom();
      }}
      type="button"
    >
      {children}
    </WSButton.Link>
  );
};

export const LinkButton: React.FC<{ to: string }> = ({ to, children }) => {
  const history = useHistory();

  return (
    <WSButton.Link
      mx="XS"
      onClick={() => {
        history.push(to);
      }}
      type="button"
    >
      {children}
    </WSButton.Link>
  );
};

export const GoogleSignUpInviteCodeButton: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const inviteCodeHash = "#invite-code";
  const to = location.pathname.includes("sign-in")
    ? `/member/sign-up${inviteCodeHash}`
    : inviteCodeHash;
  return (
    <WSButton.Link
      mx="XS"
      onClick={() => {
        history.push(to);
      }}
      type="button"
    >
      {children}
    </WSButton.Link>
  );
};

export const platformErrors: Partial<PlatformErrorsMapping> = {
  UploadFile: {
    wsErrors: {
      AlreadyExists: {
        FilenameAlreadyExists: "Bummer, dude!",
        Other: "Sorry, file already exists!"
      },
      MaxSizeExceeded: {
        MaxFileSizeExceeded: "Sorry, files have a maximum size of 100MB"
      },
      Other: (
        <>
          Sorry, something went wrong during the upload, please try again or{" "}
          <FSButton>contact our support</FSButton>
        </>
      )
    }
  },
  BrowsingFiles: {
    wsErrors: {
      InternalServerError:
        "Sorry, our file vault appears to be down. Please reload!"
    }
  },
  DownloadFile: {
    wsErrors: {
      NotFound: {
        FileNotFound:
          "Sorry, we seem to have misplaced the file you're trying to download"
      }
    }
  },
  SettingMemberLogo: {
    wsErrors: {
      AlreadyExists: {
        FilenameAlreadyExists: "Please change the filename on your image file"
      }
    }
  },
  VerifyPhone: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Phone number is not valid",
        message: (
          <>Phone number is not valid. Please enter a valid phone number.</>
        )
      },
      {
        pattern: "Max send attempts reached",
        message: "You have reached the maximum number of send attempts."
      },
      {
        pattern: "SMS is not supported by landline phone number",
        message: "SMS is not supported by landline phone number."
      },
      {
        pattern: "The verification code or phone you've entered is incorrect",
        message: "The verification code or phone you've entered is incorrect"
      }
    ]
  },
  CreateOtpSession: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "The verification code or phone you've entered is incorrect",
        message: (
          <>
            That code was invalid. Please try again. If this persists please{" "}
            <FSButton>contact our support</FSButton>
          </>
        )
      }
    ]
  },
  PendingWithholding: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "has no payout account",
        message: (
          <>
            You don't currently have a payout account. Please{" "}
            <LinkButton to="/member/settings/payment-methods">
              update your payout
            </LinkButton>{" "}
            account to withdraw.
          </>
        )
      },
      {
        pattern: "Please reconnect your linked account",
        message: (
          <>
            Please try to reconnect an deposit account in settings. If this
            persists please <FSButton>contact our support</FSButton>
          </>
        )
      },
      {
        pattern: "No linked accounts available for deposit",
        message: (
          <>
            No linked accounts available for deposit. Please try to link an
            deposit account first. If this persists please{" "}
            <FSButton>contact our support</FSButton>
          </>
        )
      }
    ]
  },
  Auth: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Sorry! We can't find that email address",
        message: "Sorry! We can't find that email address."
      },
      {
        pattern: "User for email already exists",
        message:
          "An account with this email already exists. Please log in or use the password recovery feature if you've forgotten your password."
      },
      {
        pattern:
          "Sorry! The password you entered does not match what we have on file.",
        message: (
          <>
            Sorry! Looks like this email is already registered, but the password
            you submitted is invalid. Please use
            <LinkButton to="/member/reset-password">reset password</LinkButton>
            if necessary.
          </>
        )
      },
      {
        pattern: "Wingspan is invite only",
        message: (
          <>
            Sorry! Wingspan is an invite only platform. If you have an invite
            code, or think this is an error, please
            <GoogleSignUpInviteCodeButton>
              try another way
            </GoogleSignUpInviteCodeButton>
            to access.
          </>
        )
      },
      {
        pattern: "The invite code provided is invalid or has expired",
        message: (
          <>
            Sorry! That invite code is invalid or expired. Please
            <LinkButton to="#help-me">try another way</LinkButton>
            to access.
          </>
        )
      },
      {
        pattern: "Email does not match the invitation email",
        message: (
          <>
            The email address you entered doesn't match our records. Please
            check that you're using the exact email address provided in your
            invitation. If you continue to have trouble, click 'Not your email?'
            below for assistance.
          </>
        )
      }
    ]
  },
  InvoiceForm: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Can't calculate the total amount.",
        message:
          "Can't calculate the total amount. Please try to complete all Rate fields"
      },
      {
        pattern:
          "Invoices submitted to this client will be declined automatically",
        message:
          "This client does not accept inbound invoices. Please reach out to them for assistance."
      },
      {
        pattern: "does not allow invoice submissions",
        message: "Client does not allow invoice submissions."
      }
    ]
  },
  UpdateSeriesInvoice: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "updating Completed scheduleDate not possible",
        message: "Sorry! You can't update completed invoice."
      }
    ]
  },
  BankCard: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Card number not valid",
        message: "Card number not valid"
      },
      {
        pattern: "Only Mastercard and Visa supported",
        message: "Only Mastercard and Visa supported"
      },
      {
        pattern: "Debit Card creation failed for member",
        message: (
          <>
            This card is not valid. Either the information is incorrect, it is
            not a debit card, or you've already added it. Please check and try
            again. If this persists please{" "}
            <FSButton>contact our support</FSButton>
          </>
        )
      },
      {
        pattern: "Your card was declined",
        message: "Your card was declined"
      }
    ]
  },
  Deduction: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Cannot delete a deduction that has been partially applied",
        message: "Cannot delete a deduction that has been partially applied"
      }
    ]
  },
  VerifyEmail: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Email is already in use",
        message: "Email is already in use"
      }
    ]
  },
  PayInvoice: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Error accepting card information: Your card was declined.",
        message:
          "Your card was declined. Please try again or contact your bank."
      },
      {
        pattern:
          "Your payment method was declined while charging for subscription",
        message:
          "Your card was declined. Please try again or contact your bank."
      },
      {
        pattern: "The bank returned the decline code `insufficient_funds`.",
        message: "Insufficient funds."
      },
      {
        pattern: "The bank returned the decline code",
        message: "Your card was declined."
      },
      {
        pattern: "Cannot pay invoice, account",
        message:
          "The referenced account is not active, please select a new one."
      },
      {
        pattern: "Counterparty couldn't be created for invoice",
        message: (
          <>
            Your account numbers are not good to complete the payment. Please,{" "}
            <FSButton>contact our support</FSButton>
          </>
        )
      },
      {
        pattern: "Counterparty and Token couldn't be created for invoice",
        message: (
          <>
            Your account numbers are not good to complete the payment. Please,{" "}
            <FSButton>contact our support</FSButton>
          </>
        )
      },
      {
        pattern: "Paid invoices cannot be updated.",
        message: "Invoice is already paid."
      },
      {
        pattern: "Oops! Some of your credit card info is missing or incorrect.",
        message: "Oops! Some of your credit card info is missing or incorrect."
      },
      {
        pattern: "Account with ID (.*) does not exist or cannot be used",
        message: "This account does not exist or cannot be used."
      }
    ]
  },
  NEC1099TaxDocumentsDownload: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Unauthorized",
        message: "There is a mismatch with our records. Please try again."
      }
    ]
  },
  OffCyclePayroll: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: /Unit account .* does not have enough balance to transfer/g,
        message:
          "Unable to proceed with the off-cycle payroll due to insufficient account balance."
      }
    ]
  },
  Wallet: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Couldn't create card",
        message: (
          <>
            Unable to create card, please <FSButton>contact support</FSButton>
          </>
        )
      }
    ]
  },
  PasswordReset: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Session does not exist",
        message: "Your password reset link has expired"
      }
    ]
  },
  PaymentsSetup: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern: "Account .* cannot be used for instant payouts",
        message: "This bank account does not currently support instant payouts."
      }
    ]
  },
  MonitoredEntity: {
    wsErrors: {},
    errorRegexs: [
      {
        pattern:
          "No valid changes were provided. Ensure you are providing a different tax ID",
        message:
          "No valid changes were provided. Ensure you are providing a different tax ID."
      }
    ]
  }
};
