import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";

export const isTaxInfoAdded = (payee: IPayeeResponse) =>
  !!(
    payee.payerOwnedData.payeeW9Data.tinType ||
    payee.payerOwnedData.payeeW9Data.ssn ||
    payee.payerOwnedData.payeeW9Data.ein
  );

export const isVerificationFailed = (payee: IPayeeResponse) =>
  payee.payerOwnedData.payeeW9Data.payeeTinVerification?.status === "Failed";
