import React, { useEffect } from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { EditLayout } from "../../../../TaxCorrections/components/EditLayout";
import {
  WSButton,
  WSButtons,
  WSLoader,
  WSMessageBox
} from "@wingspanhq/fe-component-library";
import {
  useCreateBulk1099SubmitBatchItem,
  useCreateBulkCalculate1099Batch,
  useUpdateBulkCalculate1099Batch
} from "../../../../BulkImporter/query/bulkCalculation1099/mutations";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { CURRENT_YEAR } from "../../../constants/currentYear";
import { IBulkCalculation1099ItemCreate } from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";
import { BulkCalculation1099Type } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { queryCache } from "react-query";
import {
  QUERY_SEARCH_TAX_FORM_ROWS,
  QUERY_SEARCH_TAX_FORM_ROWS_COUNT
} from "../../../../../query/search/taxForm/keys";
import { useUserId } from "../../../../../query/hooks/helpers";

import { openIntercom } from "../../../../../shared/utils/intercom";

export const RouteNewSubmitting: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const [retryCount, setRetryCount] = React.useState(0);
  const [isProcessing, setIsProcessing] = React.useState(true);

  const [createBulk1099CalculateBatch, createBulk1099CalculateBatchMeta] =
    useCreateBulkCalculate1099Batch();

  const [createBulk1099SubmitBatchItem, createBulk1099SubmitBatchItemMeta] =
    useCreateBulk1099SubmitBatchItem();

  const [updateBulkCalculate1099Batch, updateBulkCalculate1099BatchMeta] =
    useUpdateBulkCalculate1099Batch();

  useEffect(() => {
    setIsProcessing(true);
    const onError = () => {
      setIsProcessing(false);
    };

    createBulk1099CalculateBatch(
      {
        labels: {
          filingYear: `${CURRENT_YEAR}`
        }
      },
      {
        throwOnError: true,
        onSuccess: async bulkBatch => {
          const bulkBatchId = bulkBatch.bulkCalculation1099BatchId;

          const batchItemsRequest: IBulkCalculation1099ItemCreate = {
            clientId: userId,
            year: CURRENT_YEAR,
            calculationType: BulkCalculation1099Type.Submissions
          };
          await createBulk1099SubmitBatchItem(
            {
              batchId: bulkBatchId,
              ...batchItemsRequest
            },
            {
              onSuccess: async () => {
                await updateBulkCalculate1099Batch(
                  {
                    id: bulkBatchId,
                    data: {
                      status: BulkStatus.Pending
                    }
                  },
                  {
                    onSuccess: () => {
                      // invalidate query for tax form search
                      queryCache.invalidateQueries(QUERY_SEARCH_TAX_FORM_ROWS);
                      queryCache.invalidateQueries(
                        QUERY_SEARCH_TAX_FORM_ROWS_COUNT
                      );
                      onNext({ bulkBatchId });
                    },
                    onError
                  }
                );
              },
              onError
            }
          );
        },
        onError
      }
    );
  }, [userId, CURRENT_YEAR, retryCount]);

  const error =
    createBulk1099CalculateBatchMeta.error ||
    createBulk1099SubmitBatchItemMeta.error ||
    updateBulkCalculate1099BatchMeta.error;

  const isLoading =
    createBulk1099CalculateBatchMeta.isLoading ||
    createBulk1099SubmitBatchItemMeta.isLoading ||
    updateBulkCalculate1099BatchMeta.isLoading;

  return (
    <EditLayout title="1099-NEC Filing" noBack>
      {isProcessing || isLoading ? (
        <WSLoader.Spinner />
      ) : (
        <>
          <WSErrorMessage
            contextKey="NEC1099BulkCalculate1099"
            error={error}
            mt="XL"
          />
          <WSMessageBox.Error
            mt="XL"
            noBorder
            title={`There was an error submitting tax forms to the IRS for filing`}
          >
            Try again and if the issue persists, please{" "}
            <WSButton.Link mx="XS" onClick={openIntercom}>
              contact support
            </WSButton.Link>
            .
          </WSMessageBox.Error>

          <WSButtons forceFullWidth mt="XL">
            <WSButton.Primary
              fullWidth
              onClick={() => {
                setRetryCount(retryCount + 1);
              }}
            >
              Try again
            </WSButton.Primary>
            <WSButton.Tertiary onClick={onBack}>
              Back to dashboard
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </EditLayout>
  );
};
