import { WSForm, WSInfoBox, WSSelect } from "@wingspanhq/fe-component-library";
import { selectorNo1099CompanyStructure } from "../../selectors/selectorNo1099CompanyStructure";
import React from "react";
import { BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS } from "../../constants/member";
import { selectorTaxClassificationLabel } from "../../selectors/selectorTaxClassificationLabel";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces";

export type FormPartialCompanyStructureProps = {
  name: string;
  show1099Message?: boolean;
  hidden?: boolean;
  helperText?: string;
};

export const FormPartialCompanyStructure: React.FC<
  FormPartialCompanyStructureProps
> = ({ name, show1099Message, hidden, helperText }) => {
  return (
    <>
      <WSForm.Field
        name={name}
        hidden={hidden}
        component={WSSelect}
        label="Federal tax classficcation"
        componentProps={{
          required: true,
          helperText: helperText,
          options: BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS
        }}
      />

      <WSForm.Value name={name}>
        {(companyStructure: CompanyStructure) =>
          !hidden &&
          show1099Message &&
          selectorNo1099CompanyStructure(companyStructure) ? (
            <WSInfoBox title="1099-NEC form may not be issued">
              As a {selectorTaxClassificationLabel(companyStructure)}, you may
              not be eligible for a 1099 per IRS guidelines. Your payer has
              discretion in issuing 1099s based on your tax classification.
              Wingspan will provide an annual payment summary for tax purposes.
              If this doesn't align with your business structure, consider
              updating your federal tax classification.
            </WSInfoBox>
          ) : null
        }
      </WSForm.Value>
    </>
  );
};
