import { selector1099NECTotal } from "../selectors/selector1099NECTotal";
import { toWSMoneyString, WSTableCell } from "@wingspanhq/fe-component-library";
import React from "react";
import { TaxFormSubmissionRow } from "../../../services/search";

export type NEC1099TotalCellProps = {
  taxFormSubmission: TaxFormSubmissionRow;
};

export const NEC1099TotalCell: React.FC<NEC1099TotalCellProps> = ({
  taxFormSubmission
}) => {
  const nec1099Total = selector1099NECTotal(taxFormSubmission);
  let secondaryText;

  if (taxFormSubmission.isGrouped) {
    secondaryText = "Grouped";
  }

  return Number.isFinite(nec1099Total) ? (
    <WSTableCell
      text={toWSMoneyString(nec1099Total)}
      secondaryText={secondaryText}
    />
  ) : (
    <WSTableCell text="--" />
  );
};
