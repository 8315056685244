import { WSAlert } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { TAX_FILING_1099_FILING_SUBMISSION_PATH } from "../paths";

type Props = {
  taxFormsCount: number;
};

export const AlertSubmissionIsAvailable: React.FC<Props> = props => {
  const history = useHistory();

  return (
    <WSAlert
      theme="info"
      icon="info-circle"
      title="1099-NECs submit for filing is available"
      action={{
        onClick: () => {
          history.push(TAX_FILING_1099_FILING_SUBMISSION_PATH);
        },
        text: "Submit for filing"
      }}
    >
      There are {props.taxFormsCount} Form 1099-NECs in the “Ready” status. Only
      forms with filing status “Ready” will be submitted. We recommend reviewing
      forms with statuses “Action required” and “Excluded” to ensure they have
      the correct information before filing.
    </WSAlert>
  );
};
