import {
  WSActions,
  WSButton,
  WSCard,
  WSEmptyState,
  WSFlexBox,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { formatNumber } from "accounting";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { WSQueries } from "../../../../../query/WSQuery";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Layout } from "../../../../../shared/components/Layout";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";
import { useBulkCalculate1099BatchSummary } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import { WS_LINKS } from "../../../../../types/wsLinks";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { ROOT_PATH_SUBMITTED } from "../../Dashboard/RouteDashboard";

export const RouteSubmittingSuccess: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const currentYear = useCurrentTaxFilingYear();
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const queryBulkCalculate1099Summary =
    useBulkCalculate1099BatchSummary(bulkBatchId);

  return (
    <Layout title="1099-NEC Filing">
      <WSQueries
        queries={{
          queryBulkCalculate1099Summary
        }}
      >
        {({ queryBulkCalculate1099SummaryData }) => {
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;

          return (
            <WSList gap="2XL">
              <WSCard>
                <WSEmptyState
                  type="done"
                  orientation="horizontal"
                  title="Tax forms successfully submitted for filing"
                  description={`You successfully submitted ${formatNumber(
                    processed
                  )} tax forms to the IRS for filing`}
                />
              </WSCard>

              <WSActions
                alignment="fill"
                buttons={[
                  {
                    label: "Go to dashboard",
                    onClick: () => {
                      history.push(ROOT_PATH_SUBMITTED);
                    }
                  }
                ]}
              />

              <WSCard>
                <WSInfoBox title="Reminder" mb="L">
                  The 1099-NEC forms mentioned above will be submitted to the
                  IRS. Once submitted, each contractor will receive a copy of
                  their 1099-NEC delivered by mail and/or electronically based
                  on their preference.
                </WSInfoBox>

                <WSFlexBox alignItems="center">
                  <WSText.ParagraphSm color="gray500">
                    Learn more about tax filing?
                  </WSText.ParagraphSm>
                  <WSButton.Link
                    icon="file"
                    onClick={() => {
                      openInNewTab(WS_LINKS.ircForm1099NEC);
                    }}
                  >
                    1099-NEC tax filing
                  </WSButton.Link>
                </WSFlexBox>
              </WSCard>
            </WSList>
          );
        }}
      </WSQueries>
    </Layout>
  );
};
