import {
  toWSMoneyString,
  useModalContext,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSIcon,
  WSInputNumberOld,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import { formatMoney } from "accounting";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ArrayField, useFormContext } from "react-hook-form";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { FormPartialCustomFields } from "../../../../shared/components/FormPartialCustomFields";
import styles from "./index.module.scss";
import { FormDataLineItem, LineItemQuickbooksProps } from "./types";
import { useInvoicesFormContext } from "../InvoicesForm";
import { useGetCurrencyRate } from "../../../../query/payments/mutations";
import {
  ConvertedValue,
  CurrencyStatus,
  getLocalStorageCurrency
} from "./currencyConversionHelpers";
import { debounce } from "lodash";
import { SUPPORTED_CONVERSION_CURRENCIES } from "../../../../shared/constants/currency";

type Props = {
  index: number;
  data: Partial<ArrayField<FormDataLineItem, "id">>;
  onRemove?: () => void;
  onRateChange: () => void;
  onCurrencyChange: () => void;
  quickbooksProps: LineItemQuickbooksProps;
  customFields: ICustomField[];
  isCurrencyConversionActive?: boolean;
};

export const DesktopFields: React.FC<Props> = ({
  index,
  data,
  onRemove,
  onRateChange,
  onCurrencyChange,
  quickbooksProps,
  isCurrencyConversionActive,
  customFields
}) => {
  const { openModal } = useModalContext();
  const { register, watch, setValue } = useFormContext();
  const { invoicingConfigForPayee } = useInvoicesFormContext();
  const predefinedLineItems =
    invoicingConfigForPayee?.allowOnlyPredefinedLineItems?.value ?? [];

  const [currencyRate, currencyRateMeta] = useGetCurrencyRate();

  const rate = watch(`lineItems[${index}].rate`);
  const remove = watch(`lineItems[${index}].remove`);
  const memberClientId = watch("client.memberClientId");
  const currency = watch(`lineItems[${index}].currency`);
  const totalCost = watch(`lineItems[${index}].totalCost`);
  const conversionAmount = watch(`lineItems[${index}].conversionAmount`);
  const discountType = watch(`lineItems[${index}].discount.type`);

  const getCurrencyRate = useCallback(
    debounce((amount?: number) => {
      if (currency === "USD" || currency === undefined) return;
      if (!amount) {
        setValue(`lineItems[${index}].totalCost`, 0);
        setValue(`lineItems[${index}].conversionAmount`, 0);
        return;
      }

      const request = {
        destinationCurrency: "USD",
        sourceCurrency: currency,
        sourceAmount: amount || 0
      };

      currencyRate(request, {
        onSuccess: data => {
          setValue(`lineItems[${index}].totalCost`, data.destinationAmount);
        },
        onError: () => {
          setValue(`lineItems[${index}].totalCost`, 0);
        }
      });
    }, 300),
    [currency]
  );
  const localStorageCurrency = useMemo(() => getLocalStorageCurrency(), []);

  useEffect(() => {
    if (!isConversionVisible) return;

    getCurrencyRate(conversionAmount);
  }, [conversionAmount, currency]);

  const [rateVisible, setRateVisible] = useState(
    rate !== undefined && rate !== "fixed"
  );
  const [discountVisible, setDiscountVisible] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [customFieldsVisible, setCustomFieldsVisible] = useState(
    customFields.length > 0
  );

  const isConversionVisible = isCurrencyConversionActive
    ? currency !== "USD" && currency !== undefined
    : false;

  const hasPredefinedLineItems =
    invoicingConfigForPayee?.allowOnlyPredefinedLineItems?.enabled &&
    predefinedLineItems.length > 0;

  const isAmountInputHidden =
    rate === "hourly" || rate === "quantity" || rate === "other";
  const isDiscountAmount = discountType === "amount";
  return (
    <>
      <WSGrid gutter="M">
        <WSGrid.Item
          span={{ xs: "6", s: isCurrencyConversionActive ? "6" : "7" }}
        >
          {hasPredefinedLineItems ? (
            <WSFormOld.Field
              key={`lineItems[${index}].description-dropdown-${memberClientId}`}
              name={`lineItems[${index}].description`}
              component={WSSelectOld}
              defaultValue={data?.description || ""}
              componentProps={{
                options: predefinedLineItems.map(li => ({
                  label: li.description,
                  value: li.description
                }))
              }}
            />
          ) : (
            <WSFormOld.Field
              key={`lineItems[${index}].description-textInput-${memberClientId}`}
              name={`lineItems[${index}].description`}
              component={WSTextInput}
              defaultValue={data?.description || ""}
            />
          )}
        </WSGrid.Item>
        <WSGrid.Item
          span={{ xs: "6", s: isCurrencyConversionActive ? "6" : "5" }}
        >
          <WSFlexBox.CenterY
            wrap="nowrap"
            className={styles.totalCostFieldContainer}
          >
            <WSGrid gutter="none" className={styles.fullWidth}>
              {isAmountInputHidden || !isCurrencyConversionActive ? null : (
                <WSGrid.Item span={{ xs: "2", s: "2" }}>
                  <WSFormOld.Field
                    name={`lineItems[${index}].currency`}
                    component={WSSelectOld}
                    hidden={
                      rate === "hourly" ||
                      rate === "quantity" ||
                      rate === "other"
                    }
                    componentProps={{
                      onChange: () => {
                        onCurrencyChange();
                      },
                      searchable: true,
                      options: SUPPORTED_CONVERSION_CURRENCIES
                    }}
                    className={styles.rateField}
                    defaultValue={currency || localStorageCurrency || "USD"}
                  />
                </WSGrid.Item>
              )}
              <WSGrid.Item
                span={{
                  xs:
                    isAmountInputHidden || !isCurrencyConversionActive
                      ? "12"
                      : "10",
                  s:
                    isAmountInputHidden || !isCurrencyConversionActive
                      ? "12"
                      : "10"
                }}
                className={styles.totalCostFieldContainer}
              >
                <WSFlexBox.CenterY
                  wrap="nowrap"
                  className={styles.totalCostFieldContainer}
                >
                  <WSElement
                    className={styles.totalCostField}
                    mr={onRemove || isConversionVisible ? "S" : undefined}
                  >
                    <WSFormOld.Field
                      key={currency + "conversionAmount"}
                      name={`lineItems[${index}].conversionAmount`}
                      component={WSInputNumberOld}
                      hidden={isAmountInputHidden || !isConversionVisible}
                      componentProps={{
                        mode: "currency",
                        currency: currency || localStorageCurrency || "USD"
                      }}
                      defaultValue={conversionAmount || 0}
                    />
                    <WSFormOld.Field
                      key={currency + "totalCost"}
                      name={`lineItems[${index}].totalCost`}
                      hidden={isAmountInputHidden || isConversionVisible}
                      component={WSInputNumberOld}
                      componentProps={{
                        mode: "currency",
                        currency: "USD"
                      }}
                      defaultValue={
                        totalCost || data?.totalCost || (null as any)
                      }
                    />
                    {isAmountInputHidden && (
                      <WSText.Heading5>
                        <WSFormOld.Value name={`lineItems[${index}].totalCost`}>
                          {totalCost => formatMoney(totalCost)}
                        </WSFormOld.Value>
                      </WSText.Heading5>
                    )}
                  </WSElement>

                  {isConversionVisible && !isAmountInputHidden && (
                    <ConvertedValue
                      onRemove={onRemove}
                      isLoading={currencyRateMeta.isLoading}
                      exchangeRate={currencyRateMeta.data?.exchangeRate}
                      totalCost={totalCost}
                    />
                  )}

                  {onRemove && (
                    <WSIcon
                      block
                      name="minus-circle"
                      onClick={() => {
                        onRemove();
                      }}
                      color="blue400"
                      data-testid={`lineItem${index}RemoveButton`}
                    />
                  )}
                </WSFlexBox.CenterY>
              </WSGrid.Item>
            </WSGrid>
          </WSFlexBox.CenterY>
          {isConversionVisible && !isAmountInputHidden && (
            <CurrencyStatus
              getCurrencyRate={getCurrencyRate}
              currencyRateMeta={currencyRateMeta}
              currency={currency}
              isHideStatus={!conversionAmount || !totalCost}
            />
          )}
        </WSGrid.Item>
      </WSGrid>

      <WSElement mt="M">
        <WSGrid gutter="M" className={styles.fullWidth}>
          <WSGrid.Item
            span={{ xs: "6", s: isCurrencyConversionActive ? "6" : "7" }}
          >
            <WSElement
              style={{ display: rateVisible ? "block" : "none" }}
              mb="M"
            >
              <WSFlexBox>
                <WSFormOld.Field
                  label="Rate"
                  name={`lineItems[${index}].rate`}
                  component={WSSelectOld}
                  componentProps={{
                    onChange: onRateChange,
                    options: [
                      {
                        value: "fixed",
                        label: "Fixed"
                      },
                      {
                        value: "hourly",
                        label: "Hourly"
                      },
                      {
                        value: "quantity",
                        label: "Quantity"
                      }
                    ]
                  }}
                  mr="M"
                  className={styles.rateField}
                  defaultValue={data?.rate || "fixed"}
                />
                <input
                  type="hidden"
                  ref={register()}
                  name={`lineItems[${index}].remove`}
                  defaultValue={remove || ""}
                />
                <WSFormOld.Value name={`lineItems[${index}].rate`}>
                  {rate => {
                    return (
                      <>
                        <WSFormOld.Field
                          label="Custom Rate"
                          hidden={rate !== "other"}
                          name={`lineItems[${index}].customUnit`}
                          component={WSTextInput}
                          componentProps={{
                            onChange: onRateChange
                          }}
                          mr="M"
                          className={styles.customUnitField}
                          defaultValue={data?.customUnit || ""}
                        />

                        <WSFormOld.Value
                          name={`lineItems[${index}].customUnit`}
                        >
                          {customUnit => {
                            const unit =
                              rate === "hourly"
                                ? "Hour"
                                : rate === "quantity"
                                ? "Unit"
                                : customUnit
                                ? customUnit
                                : "Item";

                            return rate !== "fixed" ? (
                              <>
                                <WSFormOld.Field
                                  label={`Amount per ${unit}`}
                                  name={`lineItems[${index}].costPerUnit`}
                                  component={WSInputNumberOld}
                                  componentProps={{
                                    onChange: onRateChange,
                                    mode: "currency",
                                    currency: "USD"
                                  }}
                                  mr="M"
                                  className={styles.costPerUnitField}
                                  defaultValue={
                                    data?.costPerUnit || (null as any)
                                  }
                                />

                                {rate === "hourly" ? (
                                  <>
                                    <WSFormOld.Field
                                      label="Hours"
                                      name={`lineItems[${index}].quantity`}
                                      component={WSInputNumberOld}
                                      componentProps={{
                                        onChange: onRateChange,
                                        min: 0
                                      }}
                                      className={styles.quantityField}
                                      defaultValue={
                                        data.quantity || (null as any)
                                      }
                                      mr="M"
                                    />

                                    <WSFormOld.Field
                                      label="Minutes"
                                      name={`lineItems[${index}].minutes`}
                                      component={WSInputNumberOld}
                                      componentProps={{
                                        onChange: onRateChange,
                                        min: 0,
                                        max: 60
                                      }}
                                      className={styles.quantityField}
                                      defaultValue={
                                        data.minutes || (null as any)
                                      }
                                    />
                                  </>
                                ) : (
                                  <WSFormOld.Field
                                    label={`${unit}(s)`}
                                    name={`lineItems[${index}].quantity`}
                                    component={WSInputNumberOld}
                                    componentProps={{
                                      onChange: onRateChange,
                                      min: 0
                                    }}
                                    className={styles.quantityField}
                                    defaultValue={
                                      data.quantity || (null as any)
                                    }
                                  />
                                )}
                              </>
                            ) : null;
                          }}
                        </WSFormOld.Value>
                      </>
                    );
                  }}
                </WSFormOld.Value>
              </WSFlexBox>

              {invoicingConfigForPayee?.allowLineItemReimbursableExpenses
                ?.enabled ? (
                <WSFormOld.Field
                  mt="M"
                  name={`lineItems[${index}].reimbursableExpense`}
                  component={WSCheckboxToggle}
                  componentProps={{ label: "Reimbursable expense" }}
                  defaultValue={(data?.reimbursableExpense || false) as any}
                />
              ) : null}
            </WSElement>

            <WSButton.Link
              size="M"
              key={rateVisible ? "Hide rate details" : "Show rate details"}
              name={`lineItems${index}ToggleRate`}
              onClick={() => {
                setRateVisible(oldValue => !oldValue);
              }}
              type="button"
            >
              {rateVisible ? "Hide rate details" : "Show rate details"}
            </WSButton.Link>

            {quickbooksProps.isQuickbooksAccount ||
            quickbooksProps.isQuickbooksItem ? (
              <>
                <WSElement
                  style={{ display: showIntegrations ? "block" : "none" }}
                >
                  <WSFormOld.Field
                    key={
                      quickbooksProps.quickbooksOptions
                        .map(v => v.value)
                        .join("") +
                      quickbooksProps.quickbooksDefaultAccountId +
                      quickbooksProps.quickbooksDefaultItemId
                    }
                    label={
                      quickbooksProps.isQuickbooksItem
                        ? "Quickbooks item"
                        : "Quickbooks expense account"
                    }
                    name={
                      quickbooksProps.isQuickbooksItem
                        ? `lineItems[${index}].integration.quickbooks.itemId`
                        : `lineItems[${index}].integration.quickbooks.expenseAccountId`
                    }
                    component={WSSelectOld}
                    componentProps={{
                      options: quickbooksProps.quickbooksOptions,
                      searchable: true,
                      placeholderActions: [
                        {
                          label: "Resync QBO Items",
                          icon: "refresh-v",
                          callback() {
                            openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                              entity: quickbooksProps.isQuickbooksItem
                                ? QUICKBOOKS_ENTITY.ITEMS
                                : QUICKBOOKS_ENTITY.EXPENSES
                            });
                          }
                        }
                      ]
                    }}
                    mt="M"
                    className={styles.quickbooksField}
                    defaultValue={
                      quickbooksProps.isQuickbooksItem
                        ? data?.integration?.quickbooks?.itemId ||
                          quickbooksProps.quickbooksDefaultItemId
                        : data?.integration?.quickbooks?.expenseAccountId ||
                          quickbooksProps.quickbooksDefaultAccountId
                    }
                  />
                </WSElement>
                <WSButton.Link
                  size="M"
                  key={
                    showIntegrations ? "Hide integrations" : "Show integrations"
                  }
                  name={`lineItems${index}ToggleIntegrations`}
                  onClick={() => {
                    setShowIntegrations(v => !v);
                  }}
                  type="button"
                  mb="M"
                  mt="M"
                >
                  {showIntegrations ? "Hide integrations" : "Show integrations"}
                </WSButton.Link>
              </>
            ) : null}

            <FormPartialCustomFields
              hidden={!customFieldsVisible}
              mt="XL"
              name={`lineItems[${index}].customFields`}
              fields={customFields}
              defaultValues={data.customFields}
            />

            {customFields.length > 0 && (
              <WSButton.Link
                key={customFieldsVisible ? "Hide" : "Show"}
                mt="XS"
                name={`lineItems${index}ToggleCusmomFields`}
                onClick={() => {
                  setCustomFieldsVisible(prev => !prev);
                }}
                type="button"
              >
                {customFieldsVisible ? "Hide" : "Show"} custom fields
              </WSButton.Link>
            )}
          </WSGrid.Item>
          <WSGrid.Item
            span={{ xs: "6", s: isCurrencyConversionActive ? "6" : "5" }}
          >
            <WSElement
              style={{ display: discountVisible ? "block" : "none" }}
              mb="M"
            >
              <WSFormOld.Label>Discount</WSFormOld.Label>
              <WSFlexBox wrap="nowrap" mb="XL">
                <WSGrid gutter="none" className={styles.fullWidth}>
                  {isDiscountAmount && isCurrencyConversionActive && (
                    <WSGrid.Item span={{ xs: "2", s: "2" }}>
                      <WSSelectOld
                        searchable={false}
                        value={"USD"}
                        name="USD"
                        error={false}
                        options={[{ value: "USD", label: "USD" }]}
                      />
                    </WSGrid.Item>
                  )}
                  <WSGrid.Item
                    span={{
                      xs: isDiscountAmount ? "5" : "7",
                      s: isDiscountAmount ? "5" : "7"
                    }}
                  >
                    <WSFormOld.Value name={`lineItems[${index}].discount.type`}>
                      {type => {
                        if (type === "percentage") {
                          return (
                            <WSFormOld.Field
                              key="percentage"
                              name={`lineItems[${index}].discount.percentage`}
                              component={WSInputNumberOld}
                              componentProps={{
                                suffix: "%",
                                min: 0
                              }}
                              mr="M"
                              defaultValue={
                                data?.discount?.percentage || (null as any)
                              }
                            />
                          );
                        } else if (type === "amount") {
                          return (
                            <WSFormOld.Field
                              key="amount"
                              className={styles.smallField}
                              name={`lineItems[${index}].discount.amount`}
                              component={WSInputNumberOld}
                              componentProps={{
                                mode: "currency",
                                currency: "USD"
                              }}
                              mr="M"
                              defaultValue={
                                data?.discount?.amount || (null as any)
                              }
                            />
                          );
                        } else {
                          return null;
                        }
                      }}
                    </WSFormOld.Value>
                  </WSGrid.Item>

                  <WSGrid.Item
                    span={{
                      xs: "5",
                      s: "5"
                    }}
                  >
                    <WSFormOld.Field
                      className={styles.discountTypeField}
                      name={`lineItems[${index}].discount.type`}
                      component={WSSelectOld}
                      componentProps={{
                        options: [
                          {
                            value: "percentage",
                            label: "% of item"
                          },
                          {
                            value: "amount",
                            label: "fixed amount"
                          }
                        ]
                      }}
                      defaultValue={data?.discount?.type || "percentage"}
                    />
                  </WSGrid.Item>
                </WSGrid>
              </WSFlexBox>

              <WSFormOld.Field
                label="Discount description"
                name={`lineItems[${index}].discount.description`}
                component={WSTextInput}
                defaultValue={data?.discount?.description || ""}
              />
            </WSElement>

            {invoicingConfigForPayee?.allowLineItemDiscounts?.enabled ? (
              <WSFormOld.Value name={`lineItems[${index}].discount`}>
                {discount =>
                  discountVisible ? (
                    <WSButton.Link
                      size="M"
                      key="discount-visible"
                      name={`lineItems${index}ApplyDiscount`}
                      onClick={() => {
                        setDiscountVisible(false);
                      }}
                      type="button"
                    >
                      Apply discount
                    </WSButton.Link>
                  ) : discount &&
                    discount.type === "amount" &&
                    !!discount.amount ? (
                    <WSText>
                      {toWSMoneyString(discount.amount)} discount applied.{" "}
                      <WSButton.Link
                        name={`lineItems${index}ChangeDiscount`}
                        onClick={() => {
                          setDiscountVisible(true);
                        }}
                        type="button"
                      >
                        Change
                      </WSButton.Link>
                    </WSText>
                  ) : discount &&
                    discount.type === "percentage" &&
                    !!discount.percentage ? (
                    <WSText>
                      {discount.percentage}% discount applied.{" "}
                      <WSButton.Link
                        size="M"
                        name={`lineItems${index}ChangeDiscount`}
                        onClick={() => {
                          setDiscountVisible(true);
                        }}
                        type="button"
                      >
                        Change
                      </WSButton.Link>
                    </WSText>
                  ) : (
                    <WSButton.Link
                      size="M"
                      key="discount-hidden"
                      name={`lineItems${index}ApplyDiscount`}
                      onClick={() => {
                        setDiscountVisible(true);
                      }}
                      type="button"
                    >
                      Apply discount
                    </WSButton.Link>
                  )
                }
              </WSFormOld.Value>
            ) : null}
          </WSGrid.Item>
        </WSGrid>
      </WSElement>
    </>
  );
};
