import {
  WSActions,
  WSCard,
  WSControlGroup,
  WSField,
  WSForm,
  WSGrid,
  WSInfoBox,
  WSInputMask,
  WSInputText,
  WSList,
  WSPanel,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

import { WSServiceError } from "../../../../utils/serviceHelper";
import { getValidationSchema } from "./getValidationSchema";
import { FormTaxpayerInformationData } from "./types";
import { COUNTRY_OPTIONS } from "../../../../constants/user";
import { FormPartialAddress } from "../../../Onboarding/components/FormPartialAddress";
import { FormPartialCompanyStructure } from "../../../../shared/components/FormPartialCompanyStructure";

export const FormTaxpayerInformation: React.FC<{
  warning?: React.ReactNode;
  isEdit?: boolean;
  onCancel?: () => void;
  error: WSServiceError | null;
  isLoading: boolean;
  defaultValues: FormTaxpayerInformationData;
  onSubmit: (data: FormTaxpayerInformationData) => void;
  isSSNProvided?: boolean;
  onBack?: () => void;
}> = ({
  defaultValues,
  onBack,
  onSubmit,
  warning,
  error,
  isLoading,
  onCancel,
  isSSNProvided
}) => {
  const mismatchProps = error ? {} : {};

  return (
    <WSForm<FormTaxpayerInformationData>
      defaultValues={defaultValues}
      validationSchema={getValidationSchema(isSSNProvided)}
      onSubmit={onSubmit}
    >
      <WSList gap="2XL">
        <WSCard header="Edit contractor details on Form 1099-NEC" divider>
          <WSList gap="2XL">
            {warning}

            <WSInfoBox>
              Manually editing details on the 1099-NEC form will disable
              automatic updates from the contractor profile to the tax form
              including taxpayer and payment information. You can always undo
              your edits to resume automatic updates later (Any manual edits
              will be lost when you revert to synced information).
            </WSInfoBox>

            <WSForm.Field
              name="address.country"
              component={WSSelect}
              label="Country of tax residency"
              description="Only U.S. taxpayer information supported currently"
              componentProps={{
                disabled: true,
                internalSearch: true,
                options: COUNTRY_OPTIONS
              }}
            />

            <WSForm.Field
              name="identificationNumberType"
              component={WSControlGroup}
              label="How does the contractor operate?"
              componentProps={{
                type: "radio",
                chip: true,
                options: [
                  {
                    label: "Individual - Social Security Number (SSN)",
                    value: TinType.Individual
                  },
                  {
                    label: "Business - Employer Identification Number (EIN)",
                    value: TinType.Business
                  }
                ]
              }}
            />

            <WSForm.Value name="identificationNumberType">
              {tinType => {
                if (tinType === TinType.Individual) {
                  return (
                    <React.Fragment key={tinType}>
                      <WSForm.Field
                        name="ssn"
                        label="Social Security Number (SSN)"
                        component={WSInputMask}
                        componentProps={{
                          placeholder: isSSNProvided
                            ? "•••••••••"
                            : "Social Security Number",
                          mask: "999-99-9999",
                          autoComplete: "off",
                          required: true,
                          fsExclude: true,
                          unmask: true,
                          ...mismatchProps
                        }}
                      />

                      <WSGrid gutter="2XL">
                        <WSGrid.Item span={{ s: "12", m: "6" }}>
                          <WSForm.Field
                            name="firstName"
                            label="Legal first name"
                            component={WSInputText}
                            componentProps={{
                              required: true,
                              placeholder: "First name",
                              ...mismatchProps
                            }}
                          />
                        </WSGrid.Item>
                        <WSGrid.Item span={{ s: "12", m: "6" }}>
                          <WSForm.Field
                            name="lastName"
                            label="Legal last name"
                            component={WSInputText}
                            componentProps={{
                              required: true,
                              placeholder: "Last name",
                              ...mismatchProps
                            }}
                          />
                        </WSGrid.Item>
                      </WSGrid>

                      <WSPanel noBorder colorBackground="gray50" p="L">
                        <WSList gap="S">
                          <WSText.ParagraphXs weight="medium" color="gray500">
                            Federal tax classification
                          </WSText.ParagraphXs>
                          <WSText.ParagraphXs color="gray400">
                            Note: This is default classification for individual
                            account type that operates with an SSN
                          </WSText.ParagraphXs>
                          <WSText.ParagraphSm color="gray600">
                            Sole proprietorship / Single member LLC
                          </WSText.ParagraphSm>
                        </WSList>
                      </WSPanel>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={tinType}>
                      <WSForm.Field
                        name="ein"
                        label="Employer Identification Number (EIN)"
                        component={WSInputMask}
                        componentProps={{
                          placeholder: "Employer Identification Number",
                          mask: "99-9999999",
                          required: true,
                          autoComplete: "off",
                          fsExclude: true,
                          unmask: true,
                          ...mismatchProps
                        }}
                      />
                      <WSGrid gutter="2XL">
                        <WSGrid.Item span={{ s: "12", m: "6" }}>
                          <WSForm.Field
                            name="businessName"
                            label="Legal business name"
                            component={WSInputText}
                            componentProps={{
                              placeholder: "Business name",
                              required: true,
                              ...mismatchProps
                            }}
                          />
                        </WSGrid.Item>
                        <WSGrid.Item span={{ s: "12", m: "6" }}>
                          <WSForm.Field
                            name="optionalBusinessName"
                            label="Disregarded entity name (optional)"
                            component={WSInputText}
                            componentProps={{
                              placeholder: "Entity name"
                            }}
                          />
                        </WSGrid.Item>
                      </WSGrid>

                      <FormPartialCompanyStructure
                        show1099Message
                        name="taxClassification"
                      />
                    </React.Fragment>
                  );
                }
              }}
            </WSForm.Value>

            <WSErrorMessage mb="2XL" contextKey="Other" error={error} />
          </WSList>
        </WSCard>

        <WSCard header="Address" divider>
          <WSList mt="L" gap="L">
            <WSForm.Values
              names={["address.country", "identificationNumberType"]}
            >
              {props => (
                <>
                  <WSField
                    label={`${
                      props.identificationNumberType === TinType.Individual
                        ? "Personal"
                        : "Business"
                    } address`}
                  />
                  <FormPartialAddress
                    name="address"
                    country={props["address.country"]}
                  />

                  <WSText.ParagraphSm color="gray400">
                    Your form 1099 will be mailed to this address.
                  </WSText.ParagraphSm>
                </>
              )}
            </WSForm.Values>
          </WSList>
        </WSCard>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Cancel",
              onClick: onCancel || onBack,
              type: "button",
              kind: "Secondary"
            },
            {
              label: "Save changes",
              type: "submit",
              loading: isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
