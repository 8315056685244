import React, { useState } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSInputNumber } from "../WSInputNumber/WSInputNumber";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import styles from "./WSFilters.module.scss";
import {
  FilterChangeHandler,
  WSFilterAmountRange,
  WSFilterAmountRangeValue
} from "./types";

type Props = {
  value?: WSFilterAmountRangeValue;
  onChange: FilterChangeHandler;
} & Omit<WSFilterAmountRange, "type">;

export const FilterFieldAmountRange: React.FC<Props> = ({
  name,
  value,
  onChange
}) => {
  const [fromInternal, setFromInternal] = useState(value?.from || null);
  const [toInternal, setToInternal] = useState(value?.to || null);

  return (
    <WSElement className={styles.amountRange}>
      <WSInputNumber
        label="From"
        name="from"
        value={fromInternal}
        placeholder=""
        icon="dollar"
        onChange={(newValue) => {
          setFromInternal(newValue);
          if (newValue === null && toInternal === null) {
            onChange(name, undefined);
          } else {
            onChange(name, {
              from: newValue || undefined,
              to: toInternal || undefined
            });
          }
        }}
      />
      <WSIcon name="minus" mt="XL" color="gray500" />
      <WSInputNumber
        label="To"
        name="to"
        value={toInternal}
        placeholder=""
        icon="dollar"
        onChange={(newValue) => {
          setToInternal(newValue);
          if (fromInternal === null && newValue === null) {
            onChange(name, undefined);
          } else {
            onChange(name, {
              from: fromInternal || undefined,
              to: newValue || undefined
            });
          }
        }}
      />
    </WSElement>
  );
};
