import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { selectorTaxClassificationLabel } from "../../../../../shared/selectors/selectorTaxClassificationLabel";
import { WSDataItemProps, WSGrid } from "@wingspanhq/fe-component-library";
import { DataItem } from "../../../../../shared/components/DataItem";
import { selectorPayerTaxFormDefaultStatus } from "../../../selectors/selectorPayerTaxFormDefaultStatus";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { selectorPayerTaxFormDefaultTinType } from "../../../selectors/selectorPayerTaxFormDefaultTinType";
import { selectorTINVerificationStatusAlert } from "../../../../../shared/selectors/selectorTINVerificationStatusAlert";
import { selectorTINVerificationStatusDataView } from "../../../selectors/selectorTINVerificationStatusDataView";
import { selectorAddressString } from "../../../../../shared/selectors/selectorAddressString";

type Props = {
  taxForm: IPayerTaxFormResponse;
};

export const ContractorDetails: React.FC<Props> = ({ taxForm }) => {
  const fields = useMemo(() => {
    const submissionData = taxForm.data;
    const payeeW9Data = submissionData?.w9Info;
    const tinStatus = selectorPayerTaxFormDefaultStatus(taxForm);
    const name = selectorTaxFormRecipientName(taxForm);
    const tinType = selectorPayerTaxFormDefaultTinType(taxForm);

    const result: WSDataItemProps[] = [
      {
        label: "Name",
        value: name
      },
      {
        label: "Federal tax classification",
        value:
          tinType === "Individual"
            ? "Sole proprietorship / Single member LLC"
            : payeeW9Data?.companyStructure
            ? selectorTaxClassificationLabel(payeeW9Data?.companyStructure)
            : undefined
      },
      {
        label: "Taxpayer identification number",
        value: selectorTINVerificationStatusDataView(tinType, payeeW9Data),
        alert: selectorTINVerificationStatusAlert(tinStatus)
      },
      {
        label: "Address",
        value: selectorAddressString(payeeW9Data)
      }
    ];

    return result;
  }, [taxForm]);

  return (
    <WSGrid gutter={{ xs: "L", s: "XL", m: "2XL" }}>
      {fields.map((props, index) => (
        <WSGrid.Item
          key={`${props.label} + ${index}`}
          span={{ xs: "12", s: "6" }}
        >
          <DataItem {...props} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
