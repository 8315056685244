import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import { useCreateBulkCollaboratorBatch } from "../../../BulkImporter/query/bulkCollaborator/mutations";
import { track } from "../../../../utils/analytics";

export const RouteInitEditImportRecipients: React.FC = ({}) => {
  const history = useHistory();
  const [createBulkCollaboratorBatch] = useCreateBulkCollaboratorBatch();

  useEffect(() => {
    createBulkCollaboratorBatch(
      {},
      {
        onSuccess: bulkBatch => {
          track("1099 Filing Edit Recipients Bulk Upload Started");
          history.replace(
            `/member/1099-filing/batch-add-recipients/${bulkBatch.bulkCollaboratorBatchId}`
          );
        }
      }
    );
  }, []);

  return (
    <EditLayout title="Import recipients">
      <OverlaySpinner />
    </EditLayout>
  );
};
