import * as Yup from "yup";
import { getValidationSchema as getValidationSchemaPerson } from "../FormPartialPerson/validationSchema";

export const getValidationSchema = (meCountry: string) =>
  Yup.object().shape({
    person: getValidationSchemaPerson("Individual", {
      showOwnershipStake: false,
      requireTaxId: true,
      meCountry
    })
  });
