import {
  WSElement,
  WSFormOld,
  WSInputNumberOld,
  WSSelectOld
} from "@wingspanhq/fe-component-library";
import { FC, useCallback, useEffect } from "react";
import { useGetCurrencyRate } from "../../../../query/payments/mutations";
import { debounce } from "lodash";
import {
  ConvertedValue,
  CurrencyStatus,
  setLocalStorageCurrency
} from "./currencyConversionHelpers";
import { useFormContext } from "react-hook-form";
import { SUPPORTED_CONVERSION_CURRENCIES } from "../../../../shared/constants/currency";

interface MobileCurrencyDetailsProps {
  rateVisible: boolean;
  isCurrencyConversionActive: boolean;
  isConversionVisible: boolean;
}

export const MobileCurrencyDetails: FC<MobileCurrencyDetailsProps> = ({
  rateVisible,
  isCurrencyConversionActive,
  isConversionVisible
}: any) => {
  const { getValues, watch, setValue } = useFormContext();
  const [currencyRate, currencyRateMeta] = useGetCurrencyRate();

  const totalCost = watch("totalCost");
  const currency = watch("currency");
  const conversionAmount = watch("conversionAmount");

  const onCurrencyChange = () => {
    const values = getValues();
    // if (values.currency === "USD") {
    //   setValue("conversionAmount", 0);
    // } else {
    //   setValue("conversionAmount", values.conversionAmount || values.totalCost);
    // }
    setValue("conversionAmount", 0);
    setValue("totalCost", 0);

    setValue("currency", values.currency);
    setLocalStorageCurrency(values?.currency || "USD");
  };

  const getCurrencyRate = useCallback(
    debounce((amount?: number) => {
      if (currency === "USD" || currency === undefined) return;
      if (!amount) {
        setValue("totalCost", 0);
        setValue("conversionAmount", 0);
        return;
      }

      const request = {
        destinationCurrency: "USD",
        sourceCurrency: currency,
        sourceAmount: amount || 0
      };

      currencyRate(request, {
        onSuccess: data => {
          setValue("totalCost", data.destinationAmount);
        },
        onError: () => {
          setValue("totalCost", 0);
        }
      });
    }, 300),
    [currency]
  );

  useEffect(() => {
    if (!isConversionVisible) return;
    getCurrencyRate(conversionAmount);
  }, [conversionAmount, currency]);

  return (
    <>
      <WSElement mb="M">
        <WSFormOld.Field
          name="currency"
          label="Amount"
          component={WSSelectOld}
          hidden={rateVisible || !isCurrencyConversionActive}
          componentProps={{
            onChange: currency => {
              onCurrencyChange();
            },
            searchable: true,
            options: SUPPORTED_CONVERSION_CURRENCIES
          }}
          defaultValue={currency || "USD"}
        />
      </WSElement>

      <WSElement>
        <WSFormOld.Field
          key={currency + "conversionAmount"}
          name="conversionAmount"
          component={WSInputNumberOld}
          hidden={rateVisible || !isConversionVisible}
          componentProps={{
            mode: "currency",
            currency: currency || "USD"
          }}
          defaultValue={conversionAmount || 0}
        />
      </WSElement>

      {isConversionVisible && !rateVisible && (
        <CurrencyStatus
          getCurrencyRate={getCurrencyRate}
          currencyRateMeta={currencyRateMeta}
          currency={currency || "USD"}
          isHideStatus={!conversionAmount || !totalCost}
        />
      )}

      <WSElement mb={"M"}></WSElement>

      {isConversionVisible && !rateVisible && (
        <ConvertedValue
          isLoading={currencyRateMeta.isLoading}
          exchangeRate={currencyRateMeta.data?.exchangeRate}
          totalCost={totalCost || 0}
        />
      )}
    </>
  );
};
