import * as Yup from "yup";
import {
  BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS,
  INDIVIDUAL_COMPANY_STRUCTURE_SELECT_OPTIONS
} from "../constants/member";

export const validatorIndividualCompanyStructure = Yup.string().oneOf(
  INDIVIDUAL_COMPANY_STRUCTURE_SELECT_OPTIONS.map(option => option.value)
);

export const validatorBusinessCompanyStructure = Yup.string().oneOf(
  BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS.map(option => option.value)
);
