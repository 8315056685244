import {
  useWSModal,
  WSActions,
  WSControl,
  WSDivider,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";

export interface CardActivatedProps {
  onClose: () => void;
  onSubmit: () => void;
}

const CardReceived: React.FC<CardActivatedProps> = ({ onSubmit, onClose }) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <WSList gap="XL" mt="L">
      <WSDivider />

      <WSText.ParagraphSm weight="book" color="gray600">
        Confirm that you’ve received your physical card in the mail before
        proceeding to activate the card.
      </WSText.ParagraphSm>
      <WSPanel p="XL" colorBackground="gray50" noBorder>
        <WSText.ParagraphSm weight="book" color="gray600">
          Note: to ensure security, only activate the card after you’ve received
          the physical card in the mail.
        </WSText.ParagraphSm>
      </WSPanel>
      <WSControl
        type="checkbox"
        value={confirm}
        onChange={setConfirm}
        label="By checking this box, I confirm that I have received my physical card in the mail."
        size="S"
      />
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            type: "submit",
            kind: "Primary",
            disabled: !confirm,
            onClick: () => {
              onSubmit();
              onClose();
            }
          }
        ]}
      />
    </WSList>
  );
};

export function useConfirmReceivingCardModal() {
  return useWSModal(CardReceived, {
    title: "Activate physical card",
    size: "S"
  });
}
