import {
  toWSDateString,
  WSGrid,
  WSInfoBox,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPageToolbarAction,
  WSText
} from "@wingspanhq/fe-component-library";
import { FC, useMemo } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { PAYOUTS_PATH } from "../..";
import { usePayoutQuery } from "../../../../query/payouts/queries/usePayoutQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { PayoutAccountDetails } from "../../components/PayoutAccountDetails";
import { PayoutAccountsPanel } from "../../components/PayoutAccountsPanel";
import { PayoutInfoPanel } from "../../components/PayoutInfoPanel";
import { usePayoutsBetaModal } from "../../components/PayoutsBetaModal/usePayoutsBetaModal";
import { PayoutSummaryPanel } from "../../components/PayoutSummaryPanel";

export const RoutePayoutDetails: FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ payoutId: string }>();
  const queryPayout = usePayoutQuery(match.params.payoutId);

  const payoutsBetaModal = usePayoutsBetaModal();
  const pageActions = useMemo(() => {
    const actions: WSPageToolbarAction[] = [
      {
        label: "Learn more",
        buttonKind: "Secondary",
        onClick: () => {
          payoutsBetaModal.open();
        },
        hideButtonOn: false
      }
    ];

    return actions;
  }, [payoutsBetaModal]);

  return (
    <>
      <Route
        path={`${PAYOUTS_PATH}/:payoutId/account-details`}
        component={PayoutAccountDetails}
      />
      <WSPage>
        <WSQueries
          queries={{
            queryPayout
          }}
        >
          {({ queryPayoutData: payout }) => {
            return (
              <>
                <WSPageToolbar
                  actions={pageActions}
                  breadcrumbs={[
                    {
                      label: "Payouts (Beta)",
                      onClick: () => {
                        history.push(PAYOUTS_PATH);
                      }
                    },
                    {
                      label: `Payout (${toWSDateString(
                        payout.createdAt,
                        "monthDayYear"
                      )})`
                    }
                  ]}
                  title={toWSDateString(payout.createdAt, "monthDayYear")}
                  titleAfter={<WSText color="gray500">Wingspan Payout</WSText>}
                />

                <WSInfoBox mb="XL">
                  Reminder: Payouts may contain payments for multiple invoices.
                  Click on the individual invoice to view detailed invoice
                  information.
                </WSInfoBox>

                <WSGrid gutter="L">
                  <WSGrid.Item span={{ m: "6" }}>
                    <PayoutInfoPanel payout={payout} />
                  </WSGrid.Item>

                  <WSGrid.Item span={{ m: "6" }}>
                    <WSList gap="L">
                      <PayoutSummaryPanel payout={payout} />

                      <PayoutAccountsPanel payout={payout} />
                    </WSList>
                  </WSGrid.Item>
                </WSGrid>
              </>
            );
          }}
        </WSQueries>
      </WSPage>
    </>
  );
};
