import React, { useMemo } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { FilterFieldAmountRange } from "./FilterFieldAmountRange";
import { FilterFieldCheckbox } from "./FilterFieldCheckbox";
import { FilterFieldDateRage } from "./FilterFieldDateRage";
import { FilterFieldInput } from "./FilterFieldInput";
import { FilterFieldRadio } from "./FilterFieldRadio";
import { FilterHeader } from "./FilterHeader";
import styles from "./WSFilters.module.scss";
import {
  FilterChangeHandler,
  WSFilter,
  WSFilterAmountRangeValue,
  WSFilterDateRangeValue,
  WSFilterMultiValue,
  WSFilterSingleValue,
  WSFilterValue
} from "./types";

export type FilterProps = {
  value?: WSFilterValue;
  expanded?: boolean;
  onExpandedChange?: () => void;
  onChange: FilterChangeHandler;
} & WSFilter;

export const Filter: React.FC<FilterProps> = (props) => {
  const { name, type, value, onChange } = props;

  const optionsVisible = useMemo(() => {
    if (type === "divider") {
      return false;
    }

    return props.nonExpandable ? true : props.expanded;
    // Disabling the rule because casing props to any (props as any).options. Without it TS would throw an error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expanded, props.nonExpandable, (props as any).options, type]);

  return (
    <WSElement className={styles.filter}>
      <FilterHeader {...props} />

      {optionsVisible && (
        <WSElement className={styles.filterField}>
          {type === "checkbox" ? (
            <FilterFieldCheckbox
              name={name}
              options={props.options || []}
              value={value as WSFilterMultiValue | undefined}
              onChange={onChange}
            />
          ) : type === "radio" ? (
            <FilterFieldRadio
              name={name}
              options={props.options || []}
              value={value as WSFilterSingleValue | undefined}
              onChange={onChange}
            />
          ) : type === "daterange" ? (
            <FilterFieldDateRage
              name={name}
              options={props.options || []}
              value={value as WSFilterDateRangeValue | undefined}
              onChange={onChange}
            />
          ) : type === "input" ? (
            <FilterFieldInput
              name={name}
              value={value as WSFilterSingleValue | undefined}
              onChange={onChange}
              placeholder={props.placeholder}
            />
          ) : type === "amountrange" ? (
            <FilterFieldAmountRange
              name={name}
              value={value as WSFilterAmountRangeValue | undefined}
              onChange={onChange}
            />
          ) : null}
        </WSElement>
      )}
    </WSElement>
  );
};
