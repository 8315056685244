import {
  createTaxFormSubmissionListRequest,
  getTaxFormSubmissionViewDefaultFilters
} from "../../../../query/search/taxForm/queries/filtering";
import React, { useMemo } from "react";
import {
  TaxFormSubmissionRequest,
  TaxFormSubmissionRow
} from "../../../../services/search";
import { useHistory } from "react-router-dom";
import { useTaxFormSubmissionRowsQuery } from "../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { EmptyComponent } from "../../../../shared/components/EmptyComponent";
import {
  WSList,
  WSSectionToolbar,
  WSTable
} from "@wingspanhq/fe-component-library";
import { FillingStatusBadge } from "../../../TaxFiling/components/FillingStatusBadge";
import { NEC1099TotalCell } from "../../../TaxFiling/components/NEC1099TotalCell";
import {
  useGoToTaxFilingDashboard,
  useGoToTaxFormDetails
} from "../../../TaxFiling/paths";
import { CURRENT_YEAR } from "../../../TaxFiling/constants/currentYear";

type TaxFilingWidgetProps = {
  payeeId: string;
};

export const TaxFilingWidget: React.FC<TaxFilingWidgetProps> = ({
  payeeId
}) => {
  const history = useHistory();
  const defaultFilters = getTaxFormSubmissionViewDefaultFilters();
  const goToTaxFormDetails = useGoToTaxFormDetails();

  const goToDashboard = useGoToTaxFilingDashboard();

  const currentRequest = useMemo((): TaxFormSubmissionRequest => {
    return createTaxFormSubmissionListRequest(
      CURRENT_YEAR,
      {
        ...defaultFilters,
        memberId: payeeId
      },
      undefined,
      {
        size: 4
      }
    );
  }, [defaultFilters]);

  const queryTaxFormSubmissionRows = useTaxFormSubmissionRowsQuery(
    currentRequest,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  return (
    <WSQueries
      queries={{ queryTaxFormSubmissionRows }}
      renderLoader={EmptyComponent}
      renderErrors={EmptyComponent}
    >
      {({ queryTaxFormSubmissionRowsData }) => {
        const list = queryTaxFormSubmissionRowsData
          .map(taxForm => {
            return {
              id: taxForm.taxFormSubmissionId,
              data: taxForm
            };
          })
          .slice(0, 4);

        if (!list.length) {
          return null;
        }

        return (
          <WSList gap="M">
            <WSSectionToolbar
              title={`Tax Filing ${CURRENT_YEAR}`}
              button={{
                label: "View all",
                onClick: () => {
                  goToDashboard({ memberId: payeeId });
                }
              }}
            />

            <WSTable<TaxFormSubmissionRow>
              onRowClick={({ data }) => {
                goToTaxFormDetails(data.taxFormId, data.taxFormSubmissionId);
              }}
              columns={[
                {
                  config: {
                    gridTemplateSizeMax: "1fr",
                    header: {
                      text: "1099-NEC Total"
                    }
                  },
                  renderFunction: ({ data }) => {
                    return <NEC1099TotalCell taxFormSubmission={data} />;
                  }
                },
                {
                  config: {
                    gridTemplateSizeMax: "1fr",
                    header: {
                      text: "Filing Status"
                    }
                  },
                  renderFunction: ({ data }) => {
                    return (
                      <FillingStatusBadge
                        status={data.searchableStatus}
                        correctionStatus={data.lastCorrectionStatus}
                      />
                    );
                  }
                }
              ]}
              tableData={list}
            />
          </WSList>
        );
      }}
    </WSQueries>
  );
};
