import { WSTableCell } from "@wingspanhq/fe-component-library";
import { selectorTaxFormRecipientName } from "../selectors/selectorTaxFormRecipientName";
import { TaxFormSubmissionRow } from "../../../services/search";

export const NameCell: React.FC<{
  taxFormSubmission: TaxFormSubmissionRow;
}> = ({ taxFormSubmission }) => {
  const externalId = taxFormSubmission.taxForm.memberExternalId;
  const name = selectorTaxFormRecipientName(taxFormSubmission) || "--";

  const initials = name
    .split(" ")
    .map(n => n.charAt(0))
    .join("");

  return (
    <WSTableCell
      avatar={{
        text: initials,
        color: "white",
        type: "text"
      }}
      truncationOptions={{
        text: {
          allowTwoLines: true
        }
      }}
      text={name}
      secondaryText={externalId}
    />
  );
};
