import {
  IPayeeTaxFormResponse,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";
import { selectorTaxFormIsInPostFilingState } from "../../1099NECFiling/selectors/selectorTaxFormIsInPostFilingState";

export const selectorPayeeTaxFormCanRequestCorrection = (
  taxForm: IPayeeTaxFormResponse
) => {
  const isPostFiling = selectorTaxFormIsInPostFilingState(taxForm);

  if (taxForm.corrections?.length) {
    // no existing corrections from payee
    return (
      isPostFiling &&
      !taxForm.corrections.some(correction => {
        const isOwnedByPayee = correction.ownedBy === TaxFormViewer.payee;
        const isCreatedByPayee =
          correction.eventActors.createdBy === taxForm.memberId;

        return isOwnedByPayee || isCreatedByPayee;
      })
    );
  }

  return isPostFiling;
};
