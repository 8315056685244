import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { getEngagements } from "../../../services/engagements";
import {
  EngagementListQueryParams,
  mapEngagementsFilters
} from "./useEngagementListQuery";
import { QUERY_ENGAGEMENT_LIST_SIZE } from "../keys";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const useEngagementListSizeQuery = (
  params?: EngagementListQueryParams,
  config?: QueryConfig<number, WSServiceError>
) => {
  const { sort, filter, payeeId } = params || {};

  const xWingspanPayerId = useXWingspanPayerId();

  return useWSQuery<number, WSServiceError>(
    [QUERY_ENGAGEMENT_LIST_SIZE, params, xWingspanPayerId],
    async () => {
      const { summary } = await getEngagements(
        {
          filter: mapEngagementsFilters(filter),
          page: { size: 1, number: 1 },
          sort
        },
        xWingspanPayerId
      );

      return summary.listSize;
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
