import {
  WSButton,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  BulkStatus,
  CustomFieldResourceType
} from "@wingspanhq/payments/dist/interfaces";
import queryString from "qs";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useRouteMatch } from "react-router-dom";
import LabelValueList from "../../../../components/LabelValueList";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useCollaboratorGroupQuery } from "../../../../query/payments/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { PayeeRowsQuery } from "../../../../services/search";
import { isFalsy, isTruthy } from "../../../../utils";
import { BulkBatchDetailsHeader } from "../../components/BulkBatchDetailsHeader/BulkBatchDetailsHeader";
import { BulkBatchDetailsLayout } from "../../components/BulkBatchDetailsLayout/BulkBatchDetailsLayout";
import { BulkBatchProcessingErrors } from "../../components/BulkBatchProcessingErrors";
import { DeleteBulkBatch } from "../../components/DeleteBulkBatch";
import {
  useAllBulkCollaboratorBatchItems,
  useBulkCollaboratorBatch,
  useBulkCollaboratorBatchSummary
} from "../../query/bulkCollaborator/queries";
import { BulkResource } from "../../types";
import { getBatchDetailsLabelValueColors } from "../../utils/getBatchDetailsLabelValueColors";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { getCollaboratorBatchDetailsItems } from "./getCollaboratorBatchDetailsItems";
import { getCollaboratorBatchSummaryItems } from "./getCollaboratorBatchSummaryItems";
import { prepareCollaboratorCSVFile } from "./prepareCollaboratorCSVFile";
import { prepareCSVCollaboratorFromBatchItem } from "./prepareCSVCollaboratorFromBatchItem";
import { useEngagementQuery } from "../../../../query/engagements/queries/useEngagementQuery";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";

export function BulkImportCollaboratorBatchDetails() {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const history = useHistory();
  const { bulkBatchId } = match.params;
  const [isBatchProcessed, setIsBatchProcessed] = useState(false);

  const qBulkCollaboratorBatch = useBulkCollaboratorBatch(bulkBatchId);
  const qBulkCollaboratorBatchSummary =
    useBulkCollaboratorBatchSummary(bulkBatchId);
  const queryAllFailedBulkCollaboratorBatchItems =
    useAllBulkCollaboratorBatchItems(
      bulkBatchId,
      {
        filter: {
          status: BulkStatus.Failed
        }
      },
      {
        enabled:
          (qBulkCollaboratorBatch.data?.statistics?.itemsFailed || 0) > 0 ||
          qBulkCollaboratorBatch.data?.status === BulkStatus.Failed
      }
    );
  const isEngagementsActive = useIsEngagementsActive();

  const collaboratorGroupId = qBulkCollaboratorBatch.data?.labels
    .collaboratorGroupId as string;

  const qCollaboratorGroup = useCollaboratorGroupQuery(
    collaboratorGroupId as string,
    {
      enabled: !!collaboratorGroupId
    }
  );

  const engagementId = qBulkCollaboratorBatch.data?.labels
    .engagementId as string;
  const queryEngagement = useEngagementQuery(engagementId, {
    enabled: !!engagementId && isEngagementsActive
  });

  const labelValueColors = getBatchDetailsLabelValueColors(
    qBulkCollaboratorBatch.data?.status
  );

  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];

  const collaboratorBatchDetailsItems = useMemo(
    () =>
      getCollaboratorBatchDetailsItems(
        qBulkCollaboratorBatch.data,
        qCollaboratorGroup.data,
        queryEngagement.data,
        isEngagementsActive,
        {
          ...labelValueColors
        }
      ),
    [
      qBulkCollaboratorBatch.data,
      qCollaboratorGroup.data,
      queryEngagement.data,
      labelValueColors
    ]
  );

  const collaboratorBatchSummaryItems = useMemo(
    () =>
      getCollaboratorBatchSummaryItems(qBulkCollaboratorBatchSummary.data, {
        ...labelValueColors
      }),
    [qBulkCollaboratorBatchSummary.data, labelValueColors]
  );

  useEffect(() => {
    if (
      !isBatchProcessed &&
      qBulkCollaboratorBatch.data?.status &&
      [BulkStatus.Complete, BulkStatus.Failed].includes(
        qBulkCollaboratorBatch.data?.status
      )
    ) {
      setIsBatchProcessed(true);
      qBulkCollaboratorBatchSummary.refetch();
    }
  }, [
    qBulkCollaboratorBatch.data?.status,
    qBulkCollaboratorBatchSummary,
    isBatchProcessed,
    setIsBatchProcessed
  ]);

  const isEmptyBatch =
    qBulkCollaboratorBatchSummary.data &&
    qBulkCollaboratorBatchSummary.data.collaboratorsCount === 0;
  return (
    <BulkBatchDetailsLayout
      title="Import details"
      onBack={() => {
        history.push("/member/imports/collaborators");
      }}
    >
      <WSQueries
        queries={{ qBulkCollaboratorBatch, qBulkCollaboratorBatchSummary }}
      >
        {({ qBulkCollaboratorBatch: { data: bulkCollaboratorBatch } }) => {
          const isBatchUploadFinished = [
            BulkStatus.Complete,
            BulkStatus.Failed
          ].includes(bulkCollaboratorBatch.status);
          const failedCSVCollaborators = (
            queryAllFailedBulkCollaboratorBatchItems.data ?? []
          ).map(failedBatchItem =>
            prepareCSVCollaboratorFromBatchItem(
              failedBatchItem,
              collaboratorCustomFields,
              [],
              { includeErrors: true }
            )
          );
          const errorsCSVFileProps = prepareCollaboratorCSVFile(
            `Errors_${getUploadedFilename(
              bulkCollaboratorBatch,
              BulkResource.Collaborator
            )}`,
            collaboratorCustomFields,
            failedCSVCollaborators,
            { includeErrors: true }
          );
          return (
            <>
              {/* header section */}
              <BulkBatchDetailsLayout.Card mb="2XL">
                <BulkBatchDetailsHeader
                  bulkResource={BulkResource.Collaborator}
                />
              </BulkBatchDetailsLayout.Card>

              {/* errors  */}
              {failedCSVCollaborators.length > 0 ? (
                <BulkBatchDetailsLayout.Card mb="2XL">
                  <WSFlexBox.CenterY mb="XL" justify="space-between">
                    <WSFlexBox.CenterY>
                      <WSText
                        weight="medium"
                        color={labelValueColors.labelColor}
                      >
                        Errors
                      </WSText>
                      <WSIcon
                        ml="XS"
                        name="alert-circle"
                        color="red500"
                        size="XS"
                      />
                    </WSFlexBox.CenterY>
                    <CSVLink {...errorsCSVFileProps}>
                      <WSButton.Link icon="download" size="S">
                        {`Errors_${getUploadedFilename(
                          bulkCollaboratorBatch,
                          BulkResource.Collaborator,
                          {
                            length: 30
                          }
                        )}`}
                      </WSButton.Link>
                    </CSVLink>
                  </WSFlexBox.CenterY>
                  <BulkBatchProcessingErrors
                    bulkResource={BulkResource.Collaborator}
                  />
                </BulkBatchDetailsLayout.Card>
              ) : null}

              {/* collaborators import summary section */}
              {isFalsy(isEmptyBatch) ? (
                <BulkBatchDetailsLayout.Card mb="2XL">
                  <WSFlexBox.CenterY
                    mb="XL"
                    justify={
                      isTruthy(isBatchUploadFinished)
                        ? "space-between"
                        : "flex-start"
                    }
                  >
                    <WSText weight="medium">Contractors</WSText>
                    {isTruthy(isBatchUploadFinished) ? (
                      <WSButton.Link
                        name="collaboratorsViewAll"
                        rightIcon="arrow-right"
                        size="S"
                        onClick={() => {
                          history.push(
                            `/member/payees?${queryString.stringify({
                              bulkBatchId: [
                                bulkCollaboratorBatch.bulkCollaboratorBatchId
                              ]
                            } as PayeeRowsQuery)}`
                          );
                        }}
                      >
                        See all
                      </WSButton.Link>
                    ) : null}
                    {isFalsy(isBatchUploadFinished) && isFalsy(isEmptyBatch) ? (
                      <WSIcon
                        block
                        ml="XS"
                        name="time"
                        color={labelValueColors.labelColor}
                      />
                    ) : null}
                  </WSFlexBox.CenterY>
                  <LabelValueList
                    rowSpace="S"
                    items={collaboratorBatchSummaryItems}
                  />
                </BulkBatchDetailsLayout.Card>
              ) : null}

              {/* details section */}
              <BulkBatchDetailsLayout.Card mb="2XL">
                <WSText weight="medium" mb="XL">
                  Details
                </WSText>
                <LabelValueList
                  rowSpace="S"
                  items={collaboratorBatchDetailsItems}
                />
              </BulkBatchDetailsLayout.Card>

              {/* footer section */}
              {bulkCollaboratorBatch.status === BulkStatus.Open ? (
                <BulkBatchDetailsLayout.Card mb="2XL">
                  <DeleteBulkBatch
                    bulkBatchId={bulkCollaboratorBatch.bulkCollaboratorBatchId}
                    isEmptyBatch={isEmptyBatch}
                    bulkResource={BulkResource.Collaborator}
                  />
                </BulkBatchDetailsLayout.Card>
              ) : null}
            </>
          );
        }}
      </WSQueries>
    </BulkBatchDetailsLayout>
  );
}
