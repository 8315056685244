import { FAQ } from "../../../shared/components/FAQ";
import React from "react";
import {
  WSMarginProps,
  WSPaddingProps
} from "@wingspanhq/fe-component-library/dist/lib/components/WSElement/WSElement.component";

export const TaxDocumentsDetailsFAQ: React.FC<
  WSMarginProps & WSPaddingProps
> = props => (
  <FAQ
    {...props}
    mb="2XL"
    sections={[
      {
        title: "About the form",
        links: [
          {
            label: "What is the 1099-NEC Form?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_962b6bc6c0"
          },
          {
            label: "When will I receive my 1099-NEC?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_348c9b2970"
          },
          {
            label: "Is it available electronically?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions?q=electron#h_0bc0865f71"
          }
        ]
      },
      {
        title: "Payments",
        links: [
          {
            label: "What amounts are included?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_08831d4825"
          },
          {
            label: "Why is the amount different than I expected?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_b93d20c425"
          }
        ]
      },
      {
        title: "Corrections & amendments",
        links: [
          {
            label: "What if there’s an error on the form?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_748f87fef0"
          },
          {
            label: "Can I get a corrected 1099-NEC?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          },
          {
            label: "What if I never receive my 1099-NEC?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          }
        ]
      },
      {
        title: "Misc",
        links: [
          {
            label: "What should I do if I stop contracting mid-year?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          },
          {
            label: "Is this form reported to the IRS?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          },
          {
            label: "What happens if I dispute the amount?",
            url: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          }
        ]
      }
    ]}
  />
);
