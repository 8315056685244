import {
  WSButton,
  WSElement,
  WSElementColorsType,
  WSFlexBox,
  WSGrid,
  WSMarginProps,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";

import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  PayeeCorrectionReason
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { selectorTaxFormCorrectedValueStatusLabel } from "../../selectors/selectorTaxFormCorrectedValueStatusLabel";
import { DataItem } from "../../../../shared/components/DataItem";

import { TaxFormViewer } from "../../../TaxDocuments/types";
import {
  CORRECTION_AMOUNT,
  CORRECTION_REASON_OPTIONS,
  CORRECTION_TIN_OR_NAME,
  correctionReasonMap
} from "../../constants/corrections";
import { useRequestCorrectionCorrectedFields } from "../../utils/useRequestCorrectionCorrectedFields";
import { ICorrectionData } from "../../types";
import { selectorTaxFormCorrectionIsOverwrittenByUser } from "../../selectors/selectorTaxFormCorrectionIsOverwrittenByUser";
import { WSPillTheme } from "@wingspanhq/fe-component-library/dist/lib/components/common/WSPill/types";

import styles from "../OriginalValuePreview/styles.module.scss";

interface CorrectedValuePreviewProps extends WSMarginProps {
  correctionType: string; // TODO: add enum for correction types

  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  onEdit?: () => void;

  correctionData: ICorrectionData;
}

export const CorrectedValuePreview: React.FC<CorrectedValuePreviewProps> = ({
  correctionType,
  taxForm,
  taxFormViewer = TaxFormViewer.Payee,
  children,
  onEdit,
  correctionData,
  ...other
}) => {
  const onClickEdit = () => {
    onEdit?.();
  };

  const correctionValueStatus = selectorTaxFormCorrectedValueStatusLabel(
    taxForm,
    taxFormViewer
  );

  const pillType: WSPillTheme = selectorTaxFormCorrectionIsOverwrittenByUser(
    taxForm,
    taxFormViewer
  )
    ? "violet"
    : "warning";

  const backgroundColor: WSElementColorsType =
    selectorTaxFormCorrectionIsOverwrittenByUser(taxForm, taxFormViewer)
      ? "violet50"
      : "amber50";

  const fields = useRequestCorrectionCorrectedFields(
    correctionData,
    correctionType
  );

  const editable = typeof onEdit === "function";

  const renderAmountCorrectionHelpText = () => {
    if (correctionType !== CORRECTION_AMOUNT) {
      return null;
    }
    const { amountCorrectionData } = correctionData;
    return (
      <WSElement mt="XL">
        <DataItem label="Comment" value={amountCorrectionData?.comment} />
      </WSElement>
    );
  };

  const renderReasonForTINOrNameCorrection = () => {
    const { tinOrNameCorrectionData } = correctionData;

    const reasonLabel = CORRECTION_REASON_OPTIONS.find(
      o => o.value === tinOrNameCorrectionData?.reasonForChange
    )?.label;

    if (!reasonLabel || correctionType !== CORRECTION_TIN_OR_NAME) {
      return null;
    }

    return (
      <WSElement mt="XL">
        <WSText.ParagraphSm weight="book" color="gray400" mb="XS">
          Reason
        </WSText.ParagraphSm>
        <WSText.ParagraphSm weight="medium" color="gray700" inline>
          {reasonLabel}:{" "}
          <WSText.ParagraphSm weight="book" color="gray700" inline>
            {tinOrNameCorrectionData?.reasonForChange ===
            PayeeCorrectionReason.Other
              ? tinOrNameCorrectionData?.otherReason
              : correctionReasonMap[
                  tinOrNameCorrectionData?.reasonForChange as PayeeCorrectionReason
                ]}
          </WSText.ParagraphSm>
        </WSText.ParagraphSm>
      </WSElement>
    );
  };

  return (
    <WSPanel p="L" colorBackground={backgroundColor} noBorder {...other}>
      <WSElement>
        {children}
        <WSGrid gutter="2XL">
          {fields.map((props, index) => (
            <WSGrid.Item
              key={`${props.label} + ${index}`}
              span={{ xs: "12", s: "6" }}
            >
              <DataItem {...props} />
            </WSGrid.Item>
          ))}
        </WSGrid>
        {renderAmountCorrectionHelpText()}
        {renderReasonForTINOrNameCorrection()}
      </WSElement>

      <WSFlexBox.CenterY
        justify="flex-end"
        wrap="nowrap"
        className={styles.originalBadge}
      >
        {editable ? (
          <WSButton.Link onClick={onClickEdit} mr="M">
            Edit
          </WSButton.Link>
        ) : null}
        <WSPill
          truncate={false}
          theme={pillType}
          type="dark"
          text={correctionValueStatus}
        />
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};
