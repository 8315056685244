import {
  useWSModal,
  WSActions,
  WSAlert,
  WSDivider,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";

interface VoidOriginalTaxFormWarningModalProps {
  onContinue(): void;

  onClose(): void;
}

const VoidOriginalTaxFormWarningModal: React.FC<
  VoidOriginalTaxFormWarningModalProps
> = ({ onContinue, onClose }) => {
  return (
    <WSList gap="XL">
      <WSAlert
        theme="warning"
        icon="alert"
        title="Submit correction request to void original 1099 filing"
        mb="M"
      >
        As an S-Corporation, you are not eligible for a 1099 according to IRS
        guidelines. Please verify this is accurate before proceeding.
      </WSAlert>

      <WSInfoBox theme="info">
        If the payer accepts and files this correction with the IRS, it will
        void the original 1099 filing.
      </WSInfoBox>

      <WSDivider />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Back to request",
            kind: "Tertiary",
            onClick: onClose
          },
          {
            label: "Continue to next step ",
            kind: "Primary",
            onClick: onContinue
          }
        ]}
      />
    </WSList>
  );
};

export const useVoidOriginalTaxFormWarningModal = () =>
  useWSModal(VoidOriginalTaxFormWarningModal, {
    title: "Are you sure?",
    size: "S"
  });
