import { WSCard } from "@wingspanhq/fe-component-library";
import { IActivityContext } from "@wingspanhq/users/dist/lib/interfaces";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useActivities } from "../../../../../query/users/queries";
import {
  MembershipSettingSection,
  NextGenMembershipSettingSection
} from "../../../../../Settings/screens/AccountTab";
import { isUnderWriterMember } from "../../../../../utils/growthAttribution";

type Props = {};

export const RouteMembership: React.FC<Props> = () => {
  const userId = useUserId();
  const queryActivity = useActivities(userId);

  if (!queryActivity.data) {
    return null;
  }

  return (
    <WSCard header="Membership" divider>
      {isUnderWriterMember(queryActivity.data?.context as IActivityContext) ? (
        <NextGenMembershipSettingSection />
      ) : (
        <MembershipSettingSection />
      )}
    </WSCard>
  );
};
