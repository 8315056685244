import { useEffect } from "react";
import { useSetWSStore } from "../store";

const RECAPTCHA_V3_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY || "";

declare global {
  interface Window {
    grecaptcha: any;
    wingspanReCaptchaOnLoad: Function;
  }
}

const scriptDataId = "wingspan-recaptcha-script";

export const useLoadCaptcha = () => {
  const setStore = useSetWSStore();

  useEffect(() => {
    const langCode = "en";
    if (document.querySelector(`script[data-id="${scriptDataId}"]`) === null) {
      // create the load handler
      window.wingspanReCaptchaOnLoad = () => {
        console.log("Captcha script loaded");
        setStore({
          captchaScriptIsLoaded: true
        });
      };

      // load the script!
      const script: HTMLScriptElement = document.createElement("script");
      script.setAttribute("data-id", scriptDataId);
      script.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_V3_SITE_KEY}&onload=wingspanReCaptchaOnLoad&hl=${langCode}`
      );
      script.setAttribute("async", "true");
      script.setAttribute("defer", "true");
      document.body.appendChild(script);

      // hide the captcha logo
      const styleDataId: string = "wingspan-recaptcha-style";
      if (document.querySelector(`style[data-id="${styleDataId}"]`) === null) {
        const style: HTMLStyleElement = document.createElement("style");
        style.innerHTML = ".grecaptcha-badge{display: none;}";
        style.setAttribute("data-id", styleDataId);
        document.body.appendChild(style);
      }
    }
  }, []);
};
