import {
  useWSModal,
  WSActions,
  WSAlert,
  WSButton,
  WSElement,
  WSFlexBox,
  WSInfoBox,
  WSList,
  WSPill,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { usePayerTaxFormUpdate } from "../../../../../query/taxForm/mutations/useTaxFormUpdate";
import { selectorIsW9FormDataComplete } from "../../../selectors/selectorIsW9FormDataComplete";
import { EXCLUSION_REASONS } from "./exclusionReasons";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";

const OPTIONS = [
  {
    label: (
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="gray600">Excluded</WSText.ParagraphSm>
        <WSPill icon="block" text="Excluded" />
      </WSFlexBox.CenterY>
    ),
    value: TaxFormStatus.Excluded
  },
  {
    label: (
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="blue500">Ready</WSText.ParagraphSm>
        <WSPill theme="blue" icon="envelope" text="Ready" />
      </WSFlexBox.CenterY>
    ),
    value: TaxFormStatus.ReadyToSubmitToIrs
  },
  {
    label: (
      <WSFlexBox.CenterY justify="space-between">
        <WSText.ParagraphSm color="amber400">
          Action Required
        </WSText.ParagraphSm>
        <WSPill theme="warning" icon text="Action Required" />
      </WSFlexBox.CenterY>
    ),
    value: TaxFormStatus.NeedsAction
  }
];

const AlertTINMismatch: React.FC = () => (
  <WSAlert title="About TIN/Name Mismatches" theme="warning" icon="alert">
    While you can override a tax form to "Ready" status even if there is a
    TIN/name mismatch flagged by the IRS verification system, please be aware
    that this may result in receiving a CP2100 or CP2100A notice (also known as
    a "B-Notice") from the IRS. These notices are issued when the IRS identifies
    a discrepancy between the name and Taxpayer Identification Number (TIN)
    reported on the 1099 form. Wingspan is not responsible for any B-Notices
    received by payers. The payer assumes full responsibility for any penalties
    or follow-up actions required by the IRS in these cases.
    <WSButton.Link
      size="S"
      onClick={() =>
        openInNewTab(
          "https://docs.wingspan.app/docs/updating-tax-forms-2024#allowed-status-updates"
        )
      }
    >
      Learn more about status overwrites
    </WSButton.Link>
  </WSAlert>
);

const AlertIRSVerification: React.FC = () => (
  <WSAlert title="IRS verification pending" theme="info" icon="time">
    The IRS has not yet verified the provided Taxpayer Identification Number
    (TIN). If there is a TIN/name mismatch flagged by the IRS verification
    system, please be aware that this may result in receiving a CP2100 or
    CP2100A notice (also known as a "B-Notice") from the IRS. Wingspan is not
    responsible for any B-Notices received by payers. The payer assumes full
    responsibility for any penalties or follow-up actions required by the IRS in
    these cases. To wait for the IRS verification to complete, cancel to keep
    the existing status.
  </WSAlert>
);

const ModalUpdateStatus: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [status, setStatus] = React.useState(taxForm.status);
  const [updateTaxForm, updateTaxFormMeta] = usePayerTaxFormUpdate();

  const recommendedOption =
    OPTIONS.find(o => o.value === taxForm.recommendedPreFilingStatus) ||
    OPTIONS.find(o => o.value === taxForm.status) ||
    OPTIONS[0];

  const [showManual, setShowManual] = React.useState(
    recommendedOption.value !== taxForm.status
  );

  const otherOptions = OPTIONS.filter(
    o => o.value !== recommendedOption.value
  ).filter(option => {
    if (option.value === TaxFormStatus.ReadyToSubmitToIrs) {
      // User can't change status to ReadyToSubmitToIrs if totalAmount is 0 or form data is incomplete
      if (
        taxForm.data.totalAmount &&
        taxForm.data.totalAmount > 0 &&
        taxForm.data.w9Info &&
        selectorIsW9FormDataComplete(taxForm.data.w9Info)
      ) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  });

  let reasonAlert = null;

  return (
    <WSList gap="XL">
      <WSInfoBox color="gray600">
        Please review and confirm status changes or cancel to keep the existing
        status.
      </WSInfoBox>

      <WSText weight="medium">Recommended</WSText>
      <WSRadioInputGroup
        name="status"
        value={status}
        optionHasBorder
        onChange={(e: any) => {
          setStatus(e?.target?.value as TaxFormStatus);
        }}
        options={[recommendedOption]}
      />
      {status === TaxFormStatus.Excluded && taxForm.exclusionReason ? (
        <WSAlert
          theme="info"
          icon="info-circle"
          title={EXCLUSION_REASONS[taxForm.exclusionReason].title}
        >
          {EXCLUSION_REASONS[taxForm.exclusionReason].getMessage(taxForm)}
        </WSAlert>
      ) : status === TaxFormStatus.Pending ? (
        <AlertIRSVerification />
      ) : status === TaxFormStatus.RejectedByIrs ? (
        <AlertTINMismatch />
      ) : null}

      <WSElement>
        <WSButton.Link
          size="S"
          rightIcon={showManual ? "chevron-up" : "chevron-down"}
          onClick={() => setShowManual(!showManual)}
        >
          Manually overwrite status
        </WSButton.Link>
      </WSElement>
      <WSElement hidden={!showManual}>
        <WSRadioInputGroup
          name="status"
          value={status}
          optionHasBorder
          onChange={(e: any) => {
            setStatus(e?.target?.value as TaxFormStatus);
          }}
          options={otherOptions}
        />
      </WSElement>
      <WSErrorMessage
        contextKey="NEC1099DetailsUpdate"
        error={updateTaxFormMeta.error}
      />

      <WSActions
        alignment="fill"
        buttons={[
          {
            kind: "Primary",
            onAsyncClick: async () => {
              if (taxForm.status !== status) {
                await updateTaxForm(
                  {
                    taxFormId: taxForm.taxFormId,
                    requestData: { status, data: {} }
                  },
                  {
                    throwOnError: true
                  }
                );
              }
              onClose(true);
            },
            label: "Confirm"
          },
          {
            kind: "Secondary",
            label: "Cancel",
            onClick: () => onClose(false)
          }
        ]}
      />
    </WSList>
  );
};

export const useModalUpdateStatus = () =>
  useWSModal(ModalUpdateStatus, {
    title: "Update Status",
    size: "S"
  });
