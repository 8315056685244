import React from "react";
import sortBy from "lodash/sortBy";
import {
  IPayeeTaxFormResponse,
  TaxFormCorrectionStatus,
  TaxFormSubmissionStatus,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";
import { getFillingStatusBadgeProps } from "../../../TaxFiling/components/FillingStatusBadge";
import { selector1099NECTotal } from "../../../TaxFiling/selectors/selector1099NECTotal";
import {
  toWSMoneyString,
  WSPillProps,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import { getStatusCustomDescription } from "../../utils/getStatusCustomDescription";

type SubmissionsTableProps = {
  taxForm: IPayeeTaxFormResponse;
  currentSubmissionId?: string;
  redirectTo: (taxFormId: string, submissionId: string) => void;
  viewer: TaxFormViewer;
};

type TableItem = {
  statusProps: WSPillProps;
  secondaryText?: string;
  amount: number;
};

export const RelatedSubmissions: React.FC<SubmissionsTableProps> = ({
  taxForm,
  currentSubmissionId,
  redirectTo,
  viewer
}) => {
  const submissions = taxForm.submissions || [];

  const orderedSubmissions = sortBy(
    submissions,
    "events.submittedAt"
  ).reverse();

  const hasMultipleSubmissions = submissions.length > 1;

  // const hasRejectedCorrections = taxForm.corrections?.some(
  //   correction => correction.status === TaxFormCorrectionStatus.Rejected
  // );
  const hasPendingCorrections = taxForm.corrections?.some(
    correction => correction.status === TaxFormCorrectionStatus.Pending
  );

  const tableData = orderedSubmissions.map((submission, index) => {
    let secondaryText = getStatusCustomDescription({
      submissions: taxForm.submissions,
      corrections: taxForm.corrections,
      currentSubmissionId: submission.taxFormSubmissionId
    });

    const _correction = taxForm.corrections?.find(
      correction => correction.taxFormCorrectionId === submission.correctionId
    );

    const statusProps =
      viewer === TaxFormViewer.payee
        ? [
            TaxFormSubmissionStatus.AcceptedByIrs,
            TaxFormSubmissionStatus.RejectedByIrs,
            TaxFormSubmissionStatus.SubmittedToIrs
          ].includes(submission.status)
          ? getFillingStatusBadgeProps({
              status: TaxFormSubmissionStatus.SubmittedToIrs
            })
          : getFillingStatusBadgeProps({
              status: submission.status,
              correctionStatus: _correction?.status
            })
        : getFillingStatusBadgeProps({
            status: submission.status,
            correctionStatus: _correction?.status
          });

    return {
      data: {
        statusProps,
        amount: selector1099NECTotal(submission as any) as number,
        secondaryText
      },
      id: submission.taxFormSubmissionId
    };
  });

  return (
    <WSTable<TableItem>
      tableData={tableData}
      onRowClick={data => {
        redirectTo(taxForm.taxFormId, data.id);
      }}
      columns={[
        {
          config: {
            gridTemplateSizeMax: "1fr",
            header: {
              text: "1099-NEC"
            }
          },
          renderFunction: ({ data, id }) => {
            return <WSTableCell text={toWSMoneyString(data.amount)} />;
          }
        },
        {
          config: {
            gridTemplateSizeMax: "1fr",
            header: {
              text: "Status"
            }
          },
          renderFunction: ({ data }) => {
            return (
              <WSTableCell
                pill={{ ...data.statusProps }}
                secondaryText={data.secondaryText}
              />
            );
          }
        },
        ...(currentSubmissionId
          ? ([
              {
                config: {
                  gridTemplateSizeMax: "0.25fr",
                  justify: "end"
                },
                renderFunction: ({ id }) => {
                  return (
                    <WSTableCell
                      text={" "}
                      icon={currentSubmissionId === id ? "check" : undefined}
                    />
                  );
                }
              }
            ] as WSTableColumn[])
          : [])
      ]}
    />
  );
};
