import {
  WSButton,
  WSButtons,
  WSElement,
  WSFlexBox,
  WSIconList,
  WSText,
  WSGrid,
  WSCard,
  WSInfoBox
} from "@wingspanhq/fe-component-library";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces/additionalData";
import React from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { track } from "../../../../utils/analytics";
import styles from "./bulkUploadPayables.module.scss";
import { BatchProcessingStrategy } from "@wingspanhq/payments/dist/interfaces/bulkPayable";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { useCreateBulkPayableBatch } from "../../../../modules/BulkImporter/query/bulkPayable/mutations";
import { preparePayablesCSVFile } from "../../../../modules/BulkImporter/routes/BulkImportPayableBatchDetails/preparePayablesCSVFile";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { CommonUseCasesPanel } from "../../bulkUploadCollaborators/CommonUseCasesPanel";
import { Layout } from "../../../../shared/components/Layout";
import { BulkUploadHeader } from "../../bulkUploadCollaborators/BulkUploadHeader";

export interface BulkUploadPayablesInfoProps {}

export interface DownloadPayable {
  email?: string;
  collaboratorId?: string;
  contractorId?: string;
  amount?: string;
  lineItemTitle: string;
  lineItemDescription?: string;
  lineItemHourlyRate?: string;
  lineItemHours?: string;
  reimbursable?: boolean;
  dueDate: Date;
  attachmentId?: string;
  paymentRefId?: string;
  paidDate?: Date;
  invoiceNotes?: string;
}
export interface UploadPayable extends DownloadPayable {
  $custom?: { [key: string]: string };
}

export const BulkUploadPayablesInfo: React.FC<
  BulkUploadPayablesInfoProps
> = () => {
  useBrowserPageTitle("Wingspan - Bulk upload payables");
  const history = useHistory();
  const [createBulkPayableBatch, createBulkPayableBatchMeta] =
    useCreateBulkPayableBatch();

  const queryLineItemCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });

  const lineItemCustomFields = queryLineItemCustomFields.data ?? [];

  const downloadExamplePayables = [
    {
      email: "wade@wignspan.app",
      collaboratorId: "",
      contractorId: "",
      amount: "$100.50",
      lineItemTitle: "Pay Period 03/01-03/15",
      lineItemDescription: "Website updates & copy",
      dueDate: "2022-12-02",
      invoiceNotes: "Website Re-brand Project",
      paidDate: "",
      attachmentId: "",
      paymentRefId: ""
    }
  ];
  const csvProps = preparePayablesCSVFile(
    "Wingspan_Bulk_Upload_Payables_Template.csv",
    downloadExamplePayables,
    lineItemCustomFields
  );

  const initiateBulkUpload = async () => {
    await createBulkPayableBatch(
      {
        // Note: Assuming default processing strategy as Merge
        processingStrategy: BatchProcessingStrategy.Merge
      },
      {
        onSuccess: response => {
          track("Payable Bulk Upload Started");
          history.push(
            `/member/invoices/payables/bulk-upload/${response.bulkPayableBatchId}/status`
          );
        }
      }
    );
  };

  const requiredFields = [
    "Email or external ID",
    "Amount (USD)",
    "Line item title",
    "Due date (or Pay date if paid)"
  ];

  const optionalFields = [
    "Line item description",
    "Invoice notes",
    "Attachment ID",
    "Payment reference ID"
  ];

  lineItemCustomFields.forEach(customField => {
    if (customField.required) {
      requiredFields.push(customField.name);
    } else {
      optionalFields.push(customField.name);
    }
  });

  return (
    <Layout customHeader={<BulkUploadHeader progress={(100 / 3) * 1} />} wide>
      <WSGrid gutter="L">
        <WSGrid.Item span={{ m: "7" }}>
          <WSText.Heading4 mb="M">
            Bulk upload payables with a spreadsheet
          </WSText.Heading4>
          <WSText.ParagraphSm color="gray500" mt="M" mb="L">
            Wingspan's payables bulk upload feature allows businesses to upload
            multiple payables at once.
          </WSText.ParagraphSm>

          <WSCard className={styles.panel} mb="2XL">
            <WSInfoBox className={styles.elem}>
              <ol>
                <li>
                  <WSText.ParagraphSm color="gray600" weight="medium">
                    Upload a spreadsheet of line items
                  </WSText.ParagraphSm>
                </li>
                <ul>
                  <li>
                    <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                      Upload a CSV or Excel spreadsheet of your line items.
                      Don’t worry if the spreadsheet has other details, but
                      please make sure it contains the following columns.
                    </WSText.ParagraphSm>
                  </li>
                </ul>
                <WSFlexBox mt="2XL" mb="XL">
                  <WSElement style={{ flex: 1 }}>
                    <WSText weight="medium" mb="M">
                      Required
                    </WSText>
                    <WSIconList.Checks
                      items={requiredFields}
                      iconProps={{
                        name: "check-circle",
                        color: "green400"
                      }}
                      textProps={{
                        kind: "ParagraphSm"
                      }}
                      mb="XL"
                    />
                  </WSElement>
                  <WSElement style={{ flex: 1 }}>
                    <WSText weight="medium" mb="M">
                      Optional
                    </WSText>
                    <WSIconList.Checks
                      items={optionalFields}
                      iconProps={{
                        name: "info-circle",
                        color: "gray500"
                      }}
                      textProps={{
                        kind: "ParagraphSm"
                      }}
                      mb="XL"
                    />
                  </WSElement>
                </WSFlexBox>
                <li>
                  <WSText.ParagraphSm color="gray600" weight="medium">
                    Review and import line items
                  </WSText.ParagraphSm>
                </li>
                <ul>
                  <li>
                    <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                      Review your imported line items to ensure their
                      information is correct.
                    </WSText.ParagraphSm>
                  </li>
                </ul>
              </ol>
            </WSInfoBox>
          </WSCard>

          <WSErrorMessage
            my="XL"
            contextKey={ErrorContextKey.Other}
            error={createBulkPayableBatchMeta.error}
          />
          <WSButtons mb="XL">
            <CSVLink
              {...csvProps}
              className={styles.csvLink}
              onClick={() => {
                initiateBulkUpload();
              }}
            >
              <WSButton.Secondary fullWidth icon="download">
                Download example
              </WSButton.Secondary>
            </CSVLink>
            <WSButton
              className={styles.nextBtn}
              icon="upload"
              name="importPayables"
              onClick={() => {
                initiateBulkUpload();
              }}
              loading={createBulkPayableBatchMeta.isLoading}
            >
              Upload Payables
            </WSButton>
          </WSButtons>

          <WSFlexBox.CenterY mb="L">
            <WSText.ParagraphSm singleLine color="gray500">
              Looking for past imports?
            </WSText.ParagraphSm>
            <WSButton.Link
              icon="details"
              rightIcon="chevron-right"
              size="M"
              name="viewRoutingNumber"
              onClick={() => history.push("/member/imports")}
            >
              See imports
            </WSButton.Link>
          </WSFlexBox.CenterY>
          <WSFlexBox.CenterY>
            <WSText.ParagraphSm singleLine color="gray500">
              Looking to bulk upload contractors?
            </WSText.ParagraphSm>
            <WSButton.Link
              icon="details"
              rightIcon="chevron-right"
              size="M"
              name="viewRoutingNumber"
              onClick={() =>
                history.push(
                  "/member/invoices/contacts/collaborators/bulk-create/info"
                )
              }
            >
              Bulk upload contractors
            </WSButton.Link>
          </WSFlexBox.CenterY>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "5" }}>
          <CommonUseCasesPanel />
        </WSGrid.Item>
      </WSGrid>
    </Layout>
  );
};
