import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  FlowSetup,
  SetupRouteComponentProps
} from "../../../../shared/components/FlowSetup";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { TAX_FILING_1099_FILING_TAX_FORM_DETAILS_REVIEW_CORRECTION_PATH } from "../../paths";
import { RouteReviewCorrectionRequest } from "./RouteReviewCorrectionRequest";
import { RouteSubmitCorrection } from "./RouteSubmitCorrection";
import { RouteEditCorrectionRequest } from "./RouteEditCorrectionRequest";
import { RouteSubmitCorrectionSuccess } from "../NewCorrection/RouteSubmitCorrectionSuccess";

type RouteReviewCorrectionFlowProps = RouteComponentProps<{
  taxFormId: string;
  submissionId: string;
}>;

export type SetupRouteReviewCorrectionFlowProps = SetupRouteComponentProps<{}>;

export const RouteReviewCorrectionFlow: React.FC<
  RouteReviewCorrectionFlowProps
> = ({
  match: {
    params: { taxFormId, submissionId },
    url
  }
}) => {
  const history = useHistory();
  const onBack = useGoBackOrToParent();
  const basePath =
    TAX_FILING_1099_FILING_TAX_FORM_DETAILS_REVIEW_CORRECTION_PATH;

  return (
    <>
      <FlowSetup
        basePath={basePath}
        routes={[
          {
            path: "/review",
            component: RouteReviewCorrectionRequest,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: context => {
              if (context?.isEdit) {
                history.push({
                  pathname: `${url}/submit-edit`,
                  state: context
                });
              } else if (context && "isForce" in context) {
                history.push(`${url}/submit?force=${context?.isForce}`);
              }
            }
          },
          {
            path: "/submit",
            component: RouteSubmitCorrection,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: context => {
              if (context?.isSuccess) {
                history.push({
                  pathname: `${url}/submit-success`,
                  state: context
                });
              } else if (context?.isEdit) {
                history.push({
                  pathname: `${url}/submit-edit`,
                  state: context
                });
              }
            }
          },
          {
            path: "/submit-edit",
            component: RouteEditCorrectionRequest,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: options => {}
          },
          {
            path: "/submit-success",
            component: RouteSubmitCorrectionSuccess,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: options => {}
          }
        ]}
      />
    </>
  );
};
