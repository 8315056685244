import defaultsDeep from "lodash/defaultsDeep";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

export const getTaxFormExtendedBySubmission = (
  taxForm: IPayerTaxFormResponse,
  submissionId: string
): IPayerTaxFormResponse => {
  const submission = taxForm.submissions?.find(
    submission => submission.taxFormSubmissionId === submissionId
  ) as ITaxFormSubmissionResponse;

  const submissionData = submission?.data;
  const taxFormSubmissionData = taxForm?.data;

  const data = defaultsDeep(
    { ...submissionData },
    { ...taxFormSubmissionData }
  );

  return {
    ...taxForm,
    data
  };
};
