import {
  toWSDateString,
  toWSMoney,
  WSCard,
  WSFlexBox,
  WSGrid,
  WSPill,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { IPayoutResponse } from "../../../../services/api/payments/payouts/types";
import { getPayoutsStatusPillProps } from "../../utils/getPayoutsStatusPillProps";
import { selectorIsPayoutItemVisible } from "../../utils/getPayoutTotalAmount";
import { getPayoutNet } from "../../utils/utils";
import { useWherePayoutModal } from "../WherePayoutModal/useWherePayoutModal";

export interface PayoutInfoPanelProps {
  payout: IPayoutResponse;
}

export const PayoutInfoPanel: React.FC<PayoutInfoPanelProps> = ({ payout }) => {
  const wherePayoutModal = useWherePayoutModal();

  const invoicesLength: number = useMemo(
    () =>
      payout?.payoutItems
        ? payout?.payoutItems.filter(selectorIsPayoutItemVisible).length
        : 0,
    [payout?.payoutItems]
  );

  const payoutTotalAmount = useMemo(() => getPayoutNet(payout), [payout]);

  return (
    <WSCard
      divider
      actions={{
        buttons: [
          {
            kind: "Link",
            label: "Where is my payout?",
            onClick: () => {
              wherePayoutModal.open();
            }
          }
        ]
      }}
    >
      <WSSectionToolbar mb="L" title="Payout total" />
      <WSGrid gutter={{ xs: "XL", m: "3XL" }}>
        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSText.Heading1 weight="book" mb="S" color="gray500">
              {toWSMoney(payoutTotalAmount).formattedValue}
            </WSText.Heading1>
          </WSFlexBox>
        </WSGrid.Item>

        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSPill mb="XS" {...getPayoutsStatusPillProps(payout)} />
            <WSText.ParagraphXs weight="book" color="gray500">
              {toWSDateString(payout.createdAt, "monthDayYear")}
            </WSText.ParagraphXs>
          </WSFlexBox>
        </WSGrid.Item>

        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSText.ParagraphSm weight="book" mb="S" color="gray500">
              Initiated date
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="book" color="gray700">
              {toWSDateString(payout.createdAt, "monthDayYear")}
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSGrid.Item>

        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSText.ParagraphSm weight="book" mb="S" color="gray500">
              Invoices
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="book" color="gray700">
              {invoicesLength}
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSGrid.Item>

        <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
          <WSFlexBox direction="column">
            <WSText.ParagraphSm weight="book" mb="S" color="gray500">
              Description
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="book" color="gray700">
              Wingspan Payout
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSGrid.Item>
      </WSGrid>
    </WSCard>
  );
};
