import {
  toWSMoneyString,
  WSCard,
  WSGrid,
  WSList
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../TaxCorrections/constants/corrections";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";
import { selectorPayerTaxFormDefaultTinType } from "../selectors/selectorPayerTaxFormDefaultTinType";
import { formatEIN } from "../../../shared/utils/formatEIN";
import { selectorPayerTaxFormDefaultStatus } from "../selectors/selectorPayerTaxFormDefaultStatus";
import { selectorTaxFormRecipientName } from "../selectors/selectorTaxFormRecipientName";
import { DataItem } from "../../../shared/components/DataItem";
import { selectorAddressString } from "../../../shared/selectors/selectorAddressString";
import { selectorTINVerificationStatusAlert } from "../../../shared/selectors/selectorTINVerificationStatusAlert";

export interface UnchangedRecipientDetailsProps {
  taxForm: IPayerTaxFormResponse;
  corrections: string[];
  onEdit?: (corrections: string[]) => void;
}

export const UnchangedRecipientDetails: React.FC<
  UnchangedRecipientDetailsProps
> = ({ taxForm, corrections, onEdit }) => {
  const tinStatus = selectorPayerTaxFormDefaultStatus(taxForm);
  const name = selectorTaxFormRecipientName(taxForm);
  return (
    <WSList gap="XL" mb="L">
      {!corrections.includes(CORRECTION_AMOUNT) ? (
        <WSCard
          header={{
            label: {
              text: "Amount (unchanged)",
              helperText:
                "These details will remain unchanged on the updated tax form"
            },
            value: {
              action: onEdit
                ? {
                    label: "Edit",
                    onClick: () => {
                      onEdit?.([CORRECTION_AMOUNT]);
                    }
                  }
                : undefined
            }
          }}
        >
          <DataItem
            label="1099-NEC Total"
            value={toWSMoneyString(taxForm?.data?.totalAmount)}
          />
        </WSCard>
      ) : null}
      {!corrections.includes(CORRECTION_TIN_OR_NAME) ? (
        <WSCard
          header={{
            label: {
              text: "Contractor details (unchanged)",
              helperText:
                "These details will remain unchanged on the updated tax form"
            },
            value: {
              action: onEdit
                ? {
                    label: "Edit",
                    onClick: () => {
                      onEdit?.([CORRECTION_TIN_OR_NAME]);
                    }
                  }
                : undefined
            }
          }}
        >
          <WSGrid gutter="2XL">
            <WSGrid.Item span={{ xs: "12", s: "6" }}>
              <DataItem label="Name" value={name} />
            </WSGrid.Item>
            <WSGrid.Item span={{ xs: "12", s: "6" }}>
              <DataItem
                label="Federal tax classification"
                value={selectorTaxClassificationLabel(
                  taxForm.data.w9Info?.companyStructure
                )}
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ xs: "12", s: "6" }}>
              <DataItem
                label="Taxpayer identification number"
                value={
                  selectorPayerTaxFormDefaultTinType(taxForm) ===
                  TinType.Business
                    ? `${
                        taxForm.data.w9Info?.ein
                          ? formatEIN(taxForm.data.w9Info.ein)
                          : taxForm.data.w9Info?.einLastFour
                          ? `••• ${taxForm.data.w9Info.einLastFour}`
                          : ""
                      } (Employer Identification Number)`
                    : selectorPayerTaxFormDefaultTinType(taxForm) ===
                      TinType.Individual
                    ? `••• ${taxForm.data.w9Info?.ssnLastFour} (Social Security Number)`
                    : "-"
                }
                alert={selectorTINVerificationStatusAlert(tinStatus)}
              />
            </WSGrid.Item>
          </WSGrid>
        </WSCard>
      ) : null}
      {!corrections.includes(CORRECTION_ADDRESS) ? (
        <WSCard
          header={{
            label: {
              text: "Address (unchanged)",
              helperText:
                "These details will remain unchanged on the updated tax form"
            },
            value: {
              action: onEdit
                ? {
                    label: "Edit",
                    onClick: () => {
                      onEdit?.([CORRECTION_ADDRESS]);
                    }
                  }
                : undefined
            }
          }}
        >
          <DataItem
            label="Address"
            value={selectorAddressString(taxForm.data.w9Info)}
          />
        </WSCard>
      ) : null}
    </WSList>
  );
};
