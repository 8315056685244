import { uniqBy } from "lodash";
import {
  IExternalDestination,
  IPayoutResponse,
  IPayoutTransfer
} from "../../../services/api/payments/payouts/types";
import { getFailedStatusCount } from "./getFailedStatusCount";
import { getDestinationName } from "./getDestinationName";
import {
  getTableStatus,
  PayoutTableStatus
} from "../utils/getPayoutsStatusPillProps";

export const getStatusDescriptionText = (
  payout: IPayoutResponse
): string | undefined => {
  const tableStatus = getTableStatus(payout);

  switch (tableStatus) {
    case PayoutTableStatus.Deposited: {
      const uniqueTransfers: IPayoutTransfer[] = uniqBy(
        [
          ...(payout.externalFundsTransfers || []),
          ...(payout.bankingFundsTransfer ? [payout.bankingFundsTransfer] : [])
        ].filter(transfer => transfer.destination),
        transfer => transfer.destination?.destinationId
      );

      if (uniqueTransfers.length === 0) return "";
      if (uniqueTransfers.length === 1) {
        return getDestinationName(
          uniqueTransfers[0].destination as IExternalDestination
        );
      }
      return `${uniqueTransfers.length} accounts`;
    }

    case PayoutTableStatus.Failed: {
      const { errorsLength, transfersLength } = getFailedStatusCount(payout);
      return `${errorsLength} of ${transfersLength}`;
    }

    // case PayoutTableStatus.InProgress:
    //   return "Est. • Feb 2, 2024";

    default:
      return undefined;
  }
};
