import { WSLoader } from "@wingspanhq/fe-component-library";
import { AccountStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_USERS_CLIENT } from "../../../../query/users/keys";
import { useAccounts, useClientQuery } from "../../../../query/users/queries";
import { usersService } from "../../../../services/users";
import { selectorAccountVerificationPending } from "../../../../shared/selectors/selectorAccountVerificationPending";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD } from "../../routes/RoutePaymentMethods";
import { ManageMethods, MethodValue } from "../ManageMethods";

type Props = {
  onNext: () => void;
};

export const ManagePaymentMethod: React.FC<Props> = ({ onNext }) => {
  const userId = useUserId();
  const history = useHistory();
  const queryAccounts = useAccounts();
  const queryClient = useClientQuery(userId);

  const accounts = useMemo(
    () =>
      (queryAccounts.data || []).filter(
        account =>
          (account.canBeUsedFor?.payments ||
            selectorAccountVerificationPending(account)) &&
          account.status !== AccountStatus.Inactive
      ),
    [queryAccounts.data]
  );

  const creditCards = useMemo(
    () => queryClient.data?.profile.savedPaymentMethods,
    [queryClient.data?.profile.savedPaymentMethods]
  );

  const initialValue = useMemo((): MethodValue | undefined => {
    if (queryClient.data?.profile.defaultPaymentMethod?.accountId) {
      return {
        id: queryClient.data.profile.defaultPaymentMethod.accountId,
        type: "Account"
      };
    }

    if (queryClient.data?.profile.defaultPaymentMethod?.paymentMethodId) {
      return {
        id: queryClient.data.profile.defaultPaymentMethod.paymentMethodId,
        type: "CreditCard"
      };
    }
  }, [
    queryClient.data?.profile.defaultPaymentMethod?.accountId,
    queryClient.data?.profile.defaultPaymentMethod?.paymentMethodId
  ]);

  const [mutate, meta] = useWSMutation<void, WSServiceError, MethodValue>(
    async data => {
      if (!["Account", "CreditCard"].includes(data.type)) {
        throw new Error("Not supported");
      }

      await usersService.client.update(userId, {
        clientId: userId,
        profile: {
          defaultPaymentMethod:
            data.type === "Account"
              ? {
                  accountId: data.id,
                  paymentMethodId: null
                }
              : {
                  accountId: null,
                  paymentMethodId: data.id
                }
        }
      });
    },
    { onSuccess: onNext, dependencies: [QUERY_USERS_CLIENT] }
  );

  if (queryAccounts.isLoading || queryClient.isLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <ManageMethods
      data={{
        accounts,
        creditCards
      }}
      header={{
        label: {
          text: "Manage payment method",
          helperText: "Saved methods, including default for autopay"
        }
      }}
      onAddNew={() => {
        history.push(PATH_SETTINGS_PAYMENT_METHODS_ADD_PAYMENT_METHOD);
      }}
      addNewLabel="Add new payment method"
      initialValue={initialValue}
      onSubmit={mutate}
      error={meta.error}
      isLoading={meta.isLoading}
    />
  );
};
