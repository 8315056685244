import { RouteSelectCorrectionType } from "./RouteSelectCorrectionType";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { RouteAddCorrectionValues } from "./RouteAddCorrectionValues";
import { RouteReviewAndSubmitCorrection } from "./RouteReviewAndSubmitCorrection";
import { RouteSubmitCorrectionSuccess } from "./RouteSubmitCorrectionSuccess";
import {
  FlowSetup,
  SetupRouteComponentProps
} from "../../../../shared/components/FlowSetup";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH } from "../../paths";

type StartNewCorrectionFlowProps = RouteComponentProps<{
  taxFormId: string;
  submissionId: string;
}>;

export type SetupNewCorrectionProps = SetupRouteComponentProps<{}>;

export const RouteNewCorrection: React.FC<StartNewCorrectionFlowProps> = ({
  match: {
    params: { taxFormId, submissionId },
    url
  }
}) => {
  const history = useHistory();
  const onBack = useGoBackOrToParent();
  const basePath = TAX_FILING_1099_FILING_TAX_FORM_DETAILS_NEW_CORRECTION_PATH;

  return (
    <>
      <FlowSetup
        basePath={basePath}
        routes={[
          {
            path: "/select",
            component: RouteSelectCorrectionType,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: corrections => {
              history.push({
                pathname: `${url}/add`,
                state: {
                  corrections
                }
              });
            }
          },
          {
            path: "/add",
            component: RouteAddCorrectionValues,
            basePath,
            onBack: () => {
              history.push({
                pathname: `${url}/select`
              });
            },
            onNext: context => {
              history.push({
                pathname: `${url}/review`,
                state: {
                  ...context
                }
              });
            }
          },
          {
            path: "/review",
            component: RouteReviewAndSubmitCorrection,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: context => {
              if (context?.isEdit) {
                history.push({
                  pathname: `${url}/add`,
                  state: context
                });
              } else {
                history.push({ pathname: `${url}/success`, state: context });
              }
            }
          },
          {
            path: "/success",
            component: RouteSubmitCorrectionSuccess,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: () => {
              onBack();
            }
          }
        ]}
      />
    </>
  );
};
