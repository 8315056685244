import {
  toWSDateString,
  WSFilters,
  WSPage,
  WSPageToolbar,
  WSSelect,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { WSQueries } from "../../../query/WSQuery";
import React from "react";
import { useBankStatementsList } from "../../../query/payments/queries";
import { DownloadBankStatementButton } from "../../PaymentMethods/components/BankStatements/DownloadBankStatementButton";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ARCHIVED_STATEMENTS, MAIN_FILTER_OPTIONS } from "../utils/constants";

export const RouteStatementArchived: React.FC<RouteComponentProps> = ({}) => {
  const history = useHistory();
  const queryBankStatements = useBankStatementsList();

  return (
    <WSPage>
      <WSPageToolbar
        title={"Statements"}
        titleAfter={
          <WSSelect
            placeholder="Year"
            hideClearAction={true}
            value={ARCHIVED_STATEMENTS}
            onChange={period => {
              if (period !== ARCHIVED_STATEMENTS) {
                history.push(`/member/statements`);
              }
            }}
            options={MAIN_FILTER_OPTIONS}
          />
        }
      />
      <WSQueries queries={{ queryBankStatements }}>
        {({ queryBankStatements: { data: bankStatements } }) => {
          const tableList = bankStatements.map(bankStatement => {
            return {
              data: bankStatement,
              id: bankStatement.id
            };
          });

          return (
            <>
              <WSFilters
                values={{}}
                onFilter={() => {}}
                info={{ count: tableList.length }}
              />
              <WSTable
                mt="XL"
                tableData={tableList}
                columns={[
                  {
                    config: {
                      header: {
                        text: "Account Id"
                      },
                      gridTemplateSizeMax: "0.25fr"
                    },
                    renderFunction: row => (
                      <WSTableCell text={row.data.accountId} />
                    )
                  },
                  {
                    config: {
                      header: {
                        text: "Name"
                      },
                      gridTemplateSizeMax: "0.75fr"
                    },
                    renderFunction: row => <WSTableCell text={row.data.name} />
                  },
                  {
                    config: {
                      header: {
                        text: "Date"
                      },
                      justify: "end"
                    },
                    renderFunction: row => (
                      <WSTableCell
                        text={toWSDateString(row.data.date, "monthDayYear")}
                      />
                    )
                  },
                  {
                    config: {
                      justify: "end"
                    },
                    renderFunction: row => (
                      <DownloadBankStatementButton data={row.data} />
                    )
                  }
                ]}
                bottomMessage={
                  bankStatements.length === 0
                    ? "You have no bank statements."
                    : undefined
                }
              />
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
