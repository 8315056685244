import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";
import { getTaxFormBySecureLink } from "../../../services/taxFormSubmissions";

const QUERY_TAX_FORM_SUBMISSION_BY_SECURE_LINK =
  "QUERY_TAX_FORM_SUBMISSION_BY_SECURE_LINK";

export const useTaxFormBySecureLink = (
  token: string,
  queryConfig?: QueryConfig<ITaxFormSubmissionResponse, unknown>
) =>
  useWSQuery<ITaxFormSubmissionResponse, WSServiceError>(
    [QUERY_TAX_FORM_SUBMISSION_BY_SECURE_LINK, { token }],
    () => getTaxFormBySecureLink(token),
    queryConfig
  );
