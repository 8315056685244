import {
  WSFlexBox,
  WSPill,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { IInvoiceLineItem } from "@wingspanhq/payments/dist/interfaces";
import { calculateLineItemDiscount, calculateLineItemTotal } from ".";
import { TotalsSection, TotalsSectionItem } from "../components/Totals/Totals";

export const getLineItemsTotals = (
  lineItems: IInvoiceLineItem[],
  is1099Flow?: boolean,
  currency?: string
): TotalsSection => {
  const lineItemsWithDiscounts: TotalsSectionItem[] = [];

  lineItems.forEach(lineItem => {
    lineItemsWithDiscounts.push([
      <>
        <WSFlexBox.CenterY wrap="nowrap">
          <WSText color="gray600">{lineItem.description}</WSText>
          {lineItem.reimbursableExpense ? (
            <WSPill py="NONE" ml="M" text="Reimbursable" />
          ) : null}
          {is1099Flow && lineItem.labels.taxForm1099 ? (
            <WSPill theme="blue" ml="M" text="Included in 1099" />
          ) : null}
        </WSFlexBox.CenterY>

        {lineItem.unit && (
          <WSText.ParagraphSm color="gray500">
            {lineItem.quantity} {lineItem.unit}(s) @{" "}
            {toWSMoneyString(lineItem.costPerUnit, "default", currency)}/
            {lineItem.unit}
          </WSText.ParagraphSm>
        )}
        {lineItem.detail && (
          <WSText.ParagraphSm color="gray500">
            {lineItem.detail}
          </WSText.ParagraphSm>
        )}
      </>,
      toWSMoneyString(calculateLineItemTotal(lineItem), "default", currency)
    ]);

    const discount = calculateLineItemDiscount(lineItem);

    if (discount) {
      lineItemsWithDiscounts.push([
        <WSText color="gray600">
          {lineItem.discount?.description}
          {lineItem.discount?.percentage
            ? ` (${lineItem.discount?.percentage}%)`
            : ""}
        </WSText>,
        toWSMoneyString(-discount)
      ]);
    }
  });

  return {
    items: lineItemsWithDiscounts
  };
};
