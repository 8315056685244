import {
  IMember,
  INewUser,
  IRedactedMember
} from "@wingspanhq/users/dist/lib/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";

export const selectorMemberName = (
  member: IMember | IRedactedMember
): string => {
  const names = wsName({
    member: member as IMember,
    user: member.user as INewUser
  });

  const name = names.getResolvedName();

  // old logic
  if (name) {
    return name;
  } else {
    if (member.profile?.company?.name) {
      return member.profile.company.name;
    } else if (member.user.profile?.firstName && member.user.profile.lastName) {
      return `${member.user?.profile?.firstName} ${member.user?.profile?.lastName}`;
    } else if (member.user.email) {
      return member.user.email;
    }

    return "";
  }
};
