import { WSBreadcrumbsItem } from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";
import { TAX_FILING_1099_FILING_DASHBOARD_PATH } from "../../paths";
import { selectorTaxFormRecipientName } from "../../selectors/selectorTaxFormRecipientName";
import { getTaxFormOrganizationName } from "./RouteTaxFormDetails/getTaxFormOrganizationName";
import { useCurrentTaxFilingYear } from "../../utils/useCurrentTaxFilingYear";
import { useUserId } from "../../../../query/hooks/helpers";

export const useBreadcrumbs = (taxForm?: IPayerTaxFormResponse) => {
  const history = useHistory();
  const isOrganizationExist = useXWingspanExpansion();
  const currentYear = useCurrentTaxFilingYear();
  const userId = useUserId();

  return useMemo(() => {
    if (!taxForm) {
      return;
    }

    const breadcrumbs: WSBreadcrumbsItem[] = [
      {
        label: `1099-filing dashboard (${currentYear})`,
        onClick: () => {
          history.push(TAX_FILING_1099_FILING_DASHBOARD_PATH);
        }
      }
    ];

    const organizationName = getTaxFormOrganizationName(taxForm);
    if (
      isOrganizationExist &&
      organizationName &&
      taxForm.clientId !== userId
    ) {
      breadcrumbs.push({
        label: organizationName,
        onClick: () => {
          history.push(
            TAX_FILING_1099_FILING_DASHBOARD_PATH +
              "/all?filter%5BclientId%5D%5B0%5D=" +
              taxForm.clientId
          );
        }
      });
    }

    const name = selectorTaxFormRecipientName(taxForm);
    breadcrumbs.push({
      label: name
    });

    return breadcrumbs;
  }, [history, isOrganizationExist, taxForm, userId]);
};
