import {
  EngagementType,
  IEngagement
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";
import { ListRequestQuery } from "../../utils/serviceHelper";
import { extendConfigWithXWingspanUserHeader } from "../../shared/utils/extendHeadersWithXWingspanUser";

export type EngagementListQueryResponse = {
  data: IEngagement[];
  summary: {
    listSize: number;
  };
};

export interface EngagementFilter {
  type?:
    | EngagementType
    | {
        in?: EngagementType[];
        notIn?: EngagementType[];
      };
  isDefault?: boolean;
}

export interface EngagementApiFilter extends EngagementFilter {}

export const getEngagements = async (
  query: ListRequestQuery<
    EngagementApiFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  orgPayerId: string | undefined
): Promise<EngagementListQueryResponse> => {
  const { data, headers } = await servicePayments.get(
    `/engagement`,
    extendConfigWithXWingspanUserHeader({ params: query }, orgPayerId)
  );

  return { summary: { listSize: Number(headers["x-ws-list-size"]) }, data };
};
