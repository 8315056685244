import { TinType } from "@wingspanhq/payments/dist/interfaces";
import * as Yup from "yup";
import {
  companyNameValidator,
  validatorFirstName,
  validatorLastName
} from "../../../utils/validators";
import { BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS } from "../../../shared/constants/member";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../constants/corrections";
import { TaxFormViewer } from "../../TaxDocuments/types";
import { getValidationSchemaAddress } from "../../Onboarding/components/FormPartialAddress";

export const getFormValidationSchema = (
  corrections: string[],
  country: string,
  ssnProvided?: boolean,
  taxFormViewer: TaxFormViewer = TaxFormViewer.Payee
) =>
  Yup.object().shape({
    ...(corrections.includes(CORRECTION_AMOUNT)
      ? {
          necTotalAmount: Yup.number()
            .required("Required")
            .typeError("Amount field is required"),
          amountCorrectionHelpText: Yup.string().required("Required")
        }
      : {}),

    ...(corrections.includes(CORRECTION_TIN_OR_NAME)
      ? {
          ssn: Yup.string().when("identificationNumberType", {
            is: TinType.Individual,
            then: ssnProvided
              ? Yup.string()
                  .trim()
                  .test("test-ssn", "Must be 9 digits", ssn => {
                    if (typeof ssn !== "string") return true;

                    return ssn.length > 0 ? ssn.length === 9 : true;
                  })
              : Yup.string().required("Required").length(9, "Must be 9 digits")
          }),
          firstName: Yup.string().when("identificationNumberType", {
            is: TinType.Individual,
            then: validatorFirstName.required("Required")
          }),
          lastName: Yup.string().when("identificationNumberType", {
            is: TinType.Individual,
            then: validatorLastName.required("Required")
          }),

          ein: Yup.string().when("identificationNumberType", {
            is: TinType.Business,
            then: Yup.string().required("Required")
          }),
          businessName: Yup.string().when("identificationNumberType", {
            is: TinType.Business,
            then: companyNameValidator.required("Required")
          }),
          disregardedEntityName: Yup.string(),
          taxClassification: Yup.string().when("identificationNumberType", {
            is: TinType.Individual,
            then: Yup.string().nullable(),
            otherwise: Yup.string()
              .oneOf(
                BUSINESS_COMPANY_STRUCTURE_SELECT_OPTIONS.map(x => x.value),
                "Select federal tax classification for business"
              )
              .nullable()
              .required("Required")
          }),
          ...(taxFormViewer === TaxFormViewer.Payer
            ? {}
            : {
                reasonForChange: Yup.string().required("Required"),
                otherReason: Yup.string().when("reasonForChange", {
                  is: "Other",
                  then: Yup.string().required("Required")
                })
              })
        }
      : {}),

    ...(corrections.includes(CORRECTION_ADDRESS)
      ? { formW9Address: getValidationSchemaAddress(country) }
      : {})
  });
