import { WSAlertProps } from "@wingspanhq/fe-component-library";

type VerificationStatusAlert =
  | "None"
  | "Pending"
  | "Failed"
  | "Verified"
  | "UpdateRequired";

export const selectorTINVerificationStatusAlert = (
  status?: VerificationStatusAlert
): WSAlertProps | undefined => {
  const statusMap: Record<VerificationStatusAlert, WSAlertProps | undefined> = {
    Verified: {
      theme: "success",
      icon: "check-circle",
      title: "IRS verified"
    },
    Pending: {
      theme: "info",
      icon: "time",
      title: "IRS verification pending"
    },
    Failed: {
      theme: "warning",
      icon: "alert",
      title: "IRS mismatch"
    },
    UpdateRequired: {
      theme: "warning",
      icon: "alert",
      title: "IRS update required"
    },
    None: undefined
  };

  if (status && statusMap[status]) {
    return statusMap[status];
  }

  return undefined;
};
