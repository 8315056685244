import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  TaxFormViewer as BETaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormViewer } from "../../TaxDocuments/types";

export function selectorTaxFormCorrectionIsOverwrittenByUser(
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse,
  taxFormViewer: TaxFormViewer
) {
  const { pendingCorrection } = taxForm;

  return (
    taxFormViewer === TaxFormViewer.Payer &&
    pendingCorrection &&
    pendingCorrection?.ownedBy === BETaxFormViewer.payee &&
    pendingCorrection?.eventActors?.editedBy
  );
}
