import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import {
  IPayeePayeePayeeOwnedDataResponse,
  IPayerPayeePayerOwnedDataResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayee";
import {
  IPayeeResponse,
  PayeeTaxDocumentSharePermission
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayerOrPayeeDefaultTinType = (
  payerPayeeOwnedData?:
    | IPayeePayeePayeeOwnedDataResponse
    | IPayerPayeePayerOwnedDataResponse
) => {
  const payeeW9Data = payerPayeeOwnedData?.payeeW9Data;

  return payeeW9Data?.tinType
    ? payeeW9Data.tinType
    : payeeW9Data?.ein
    ? TinType.Business
    : TinType.Individual;
};

export const selectorPayeeDefaultTinType = (payee: IPayeeResponse) => {
  if (
    payee.payeeOwnedData.shareTaxDocument ===
    PayeeTaxDocumentSharePermission.Allow
  ) {
    return selectorPayerOrPayeeDefaultTinType(payee.payeeOwnedData);
  } else {
    return selectorPayerOrPayeeDefaultTinType(payee.payerOwnedData);
  }
};
