import React, { useEffect } from "react";
import { EditLayout } from "../../../components/EditLayout";
import { OverlaySpinner } from "../../../../../components/OverlaySpinner";
import { track } from "../../../../../utils/analytics";
import { useCreateBulkCalculate1099Batch } from "../../../../BulkImporter/query/bulkCalculation1099/mutations";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { useGoToGenerateTaxFormDetails } from "../../../paths";

export const RouteInitGenerate1099NECAmounts: React.FC = () => {
  const currentYear = useCurrentTaxFilingYear();
  const [createBulk1099CalculateBatch] = useCreateBulkCalculate1099Batch();
  const goToDetails = useGoToGenerateTaxFormDetails();

  useEffect(() => {
    createBulk1099CalculateBatch(
      {
        labels: {
          filingYear: `${currentYear}`
        }
      },
      {
        onSuccess: bulkBatch => {
          track("1099 Filing Bulk 1099 Calculation Started");
          goToDetails(bulkBatch.bulkCalculation1099BatchId);
        }
      }
    );
  }, []);

  return (
    <EditLayout title="Generate tax forms">
      <OverlaySpinner />
    </EditLayout>
  );
};
