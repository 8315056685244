import { WSSelect } from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TAX_DOCUMENTS_YEAR_OPTIONS } from "../../utils/useCurrentTaxDocumentsYear";
import { useIsTaxDocumentsExist } from "../../utils/useIsTaxDocumentsExist";

type Props = {};

export const SelectTaxYear: React.FC<Props> = ({}) => {
  const location = useLocation();
  const history = useHistory();
  const showOldTaxDocuments = useIsTaxDocumentsExist(2023);

  const currentOption = TAX_DOCUMENTS_YEAR_OPTIONS.find(p =>
    location.pathname.startsWith(p.path)
  );

  const defaultValue =
    currentOption?.value || TAX_DOCUMENTS_YEAR_OPTIONS[0].value;

  return (
    <WSSelect
      hideClearAction={true}
      mode="single"
      value={defaultValue}
      onChange={newValue => {
        const newPath = TAX_DOCUMENTS_YEAR_OPTIONS.find(
          p => p.value === newValue
        )?.path;
        if (newPath) {
          history.push(newPath);
        }
      }}
      options={TAX_DOCUMENTS_YEAR_OPTIONS.filter(p =>
        showOldTaxDocuments ? true : p.value === defaultValue
      )}
    />
  );
};
