import { useUserId } from "../../../../query/hooks/helpers";
import { DataView } from "../../../../shared/components/DataView";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { stateTaxIdMapToArray } from "../../utils/stateTaxIdMapToArray";
import { useHistory } from "react-router-dom";
import { WSEmptyState } from "@wingspanhq/fe-component-library";
import flatten from "lodash/flatten";
import { WSQueries } from "../../../../query/WSQuery";
import { TAX_FILING_1099_FILING_UPDATE_STATE_FILING_SETTINGS_PATH } from "../../paths";

export const StateFilingInformation: React.FC<{}> = () => {
  const userId = useUserId();
  const history = useHistory();

  const qPayrollSettings = usePayrollSettings(userId);

  return (
    <WSQueries queries={{ qPayrollSettings }} renderLoader={() => null}>
      {({ qPayrollSettingsData }) => {
        const userStateTaxIds = stateTaxIdMapToArray(
          qPayrollSettingsData.calculationSettings1099?.stateTaxId ?? {}
        );

        return (
          <DataView
            loading={qPayrollSettings.isLoading}
            onEdit={() =>
              history.push(
                TAX_FILING_1099_FILING_UPDATE_STATE_FILING_SETTINGS_PATH
              )
            }
            title="State filing settings"
            data={flatten(
              userStateTaxIds.map(i => [
                {
                  label: "State",
                  value: i.stateLabel
                },
                {
                  label: "Tax ID number",
                  value: i.taxId
                }
              ])
            )}
          >
            {userStateTaxIds.length ? null : (
              <WSEmptyState
                title="State filing settings not set"
                type="documents"
                orientation="horizontal"
                description="Some states require a state Tax ID number to file 1099-NECs at the state level. If you would like to submit any 1099-NECs for state filing, click edit to add the applicable states and withholding or registration ID numbers."
              />
            )}
          </DataView>
        );
      }}
    </WSQueries>
  );
};
