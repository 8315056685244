import { useMemo } from "react";
import { InvoicesDateRangeFilter } from "../../../../../query/payments/queries";
import { useUrlQueryFilters } from "../../../../../utils/router";
import { PaginatedTable } from "../../../components/PaginatedTable";
import { Filters, Toolbar, getDateRange } from "../../../components/Toolbar";
import { useDownloadCsv } from "../../../hooks/useDownloadCsv";
import { useQueryInvoicesRows } from "../../../queries/useQueryInvoicesRows";
import { useQueryInvoicesRowsSummary } from "../../../queries/useQueryInvoicesRowsSummary";
import { InvoiceRowsQueryFilter } from "../../../service";

export const RouteIndex: React.FC = () => {
  const { defaultFilters, filters, setFilters } = useUrlQueryFilters<Filters>({
    memberClientId: "all",
    dueDateRange: InvoicesDateRangeFilter.All,
    customDueDateRange: [],
    paidDateRange: InvoicesDateRangeFilter.All,
    customPaidDateRange: []
  });

  const dueDateRange = useMemo(
    () => getDateRange(filters.dueDateRange, filters.customDueDateRange),
    [filters.dueDateRange, filters.customDueDateRange]
  );

  const paidDateRange = useMemo(
    () => getDateRange(filters.paidDateRange, filters.customPaidDateRange),
    [filters.paidDateRange, filters.customPaidDateRange]
  );

  const requestFilters = useMemo(
    (): InvoiceRowsQueryFilter => ({
      "labels.invoiceType": {
        "!=": "approvedInvoicesPayment"
      },
      memberClientId:
        filters.memberClientId === "all" ? undefined : filters.memberClientId,
      dueDate: {
        ">=": dueDateRange?.[0],
        "<=": dueDateRange?.[1]
      },
      "events.paidAt": {
        ">=": paidDateRange?.[0],
        "<=": paidDateRange?.[1]
      },
      memberId: filters.memberId,
      clientId: filters.clientId
    }),
    [
      dueDateRange,
      filters.clientId,
      filters.memberClientId,
      filters.memberId,
      paidDateRange
    ]
  );

  const queryRows = useQueryInvoicesRows({
    filter: requestFilters
  });
  const querySummary = useQueryInvoicesRowsSummary({
    filter: requestFilters
  });

  const { downloadCsv, isLoading } = useDownloadCsv();

  return (
    <>
      <Toolbar
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        count={querySummary.data?.listSize}
        totalAmount={querySummary.data?.totalValue}
        mb="L"
      />

      <PaginatedTable
        query={queryRows}
        onDownload={
          queryRows.data && queryRows.data.length > 0
            ? () => {
                downloadCsv({
                  filter: requestFilters
                });
              }
            : undefined
        }
        downloadLoading={isLoading}
      />
    </>
  );
};
