import { format } from "date-fns";
import { queryCache } from "react-query";
import { useWSMutation, WSMutationConfig } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";
import { QUERY_CUSTOMER_ENTITY } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { QUERY_VERIFICATION_MISSING_DATA } from "../../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { QUERY_VERIFICATIONS } from "../../../../query/onboarding/queries/useQueryVerifications";
import {
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "../../../../query/users/keys";
import {
  createCustomerEntity,
  getCustomerEntity,
  updateCustomerData
} from "../../../../services/api/onboarding/customer";
import { getVerificationMissingData } from "../../../../services/api/onboarding/missingFields";
import {
  components,
  operations
} from "../../../../services/api/onboarding/types";
import {
  getVerifications,
  performVerification
} from "../../../../services/api/onboarding/verifications";
import { usersService } from "../../../../services/users";
import {
  bankingAcknowledgements,
  internationalClearingAccountAcknowledgements
} from "../../../../shared/constants/acknowledgements";
import { isCountryUS } from "../../../../shared/selectors/selectorIsDomesticMember";
import { verificationService } from "../../../../shared/services/verificaiton";
import { updateAcknowledgements } from "../../../../shared/utils/updateAcknowledgements";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { phoneFormDataToE164 } from "../../components/FormPartialPhoneNumber";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { OnboardingModuleCreateAccount } from "../../types";
import {
  getBusinessData,
  getIndividualData,
  getRepresentativeData
} from "./helpers";
import { CreateAccountContext } from "./types";

type VerificationState = {
  status: components["schemas"]["VerificationStatus"];
  missingData: operations["getVerificationMissingData"]["responses"]["200"]["content"]["application/json"];
};

export const useMutationSubmit = (
  config?: WSMutationConfig<VerificationState, WSServiceError, void>
) => {
  const userId = useUserId();

  const [data] = useOnboardingContext<CreateAccountContext>();

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;

  return useWSMutation<VerificationState, WSServiceError, void>(
    async () => {
      if (!data.accountType?.type || !data.accountType?.country) {
        throw new Error("Invalid form data");
      }

      const user = await usersService.user.get(userId);

      let isExistingEntity = false;
      // 1. Create customer entity
      try {
        // Check if there is customer entity
        await getCustomerEntity();
        isExistingEntity = true;
      } catch (error) {
        // Create it if it doesn't exist
        await createCustomerEntity({
          country: data.accountType.country,
          type: data.accountType.type
        });
      }

      // 3. Get current state of verification
      const verificationLevel =
        onboardingModule?.options?.verificationLevel || "Tax";

      let verificationState: VerificationState;

      if (isExistingEntity) {
        verificationState = await getVerificationState(verificationLevel);

        // Do not proceed if status is already pending or verified
        if (
          verificationState.status === "Pending" ||
          verificationState.status === "Verified"
        ) {
          return verificationState;
        }
      }

      // 3. Update customer data
      if (data.accountType.type === "Individual") {
        // 3.1 Individual

        if (!data.personalInformation) {
          throw new Error("No personal information set");
        }

        // 3.1.2 Update individual data
        const invididualData = await getIndividualData(
          data.personalInformation,
          user,
          verificationLevel
        );

        await updateCustomerData("Entity", {
          customerData: invididualData
        });
      } else {
        // 3.1 Business

        if (!data.businessInformation) {
          throw new Error("No business information set");
        }
        if (!data.representative) {
          throw new Error("No representative set");
        }
        if (!data.accountType.country) {
          throw new Error("No country set");
        }

        // 3.1.1 Update business data
        const businessData = getBusinessData(
          data.businessInformation,
          data.accountType.country,
          user.email
        );

        await updateCustomerData("Entity", {
          customerData: businessData
        });

        // 3.1.2 Update representative data
        const representativeData = await getRepresentativeData(
          data.representative,
          user,
          verificationLevel
        );

        await updateCustomerData("Representative", {
          customerData: representativeData,
          ownershipStake:
            data.representative?.representative.ownershipStake ?? 0
        });

        // 3.1.3 Update beneficial owners data if provided
        const beneficialOwners = data.representative?.beneficialOwners || [];

        if (beneficialOwners.length > 0) {
          for (let i = 0; i < beneficialOwners.length; i++) {
            const beneficialOwner = beneficialOwners[i];
            await updateCustomerData(`Owner${i + 1}` as any, {
              customerData: {
                firstName: beneficialOwner.firstName || undefined,
                middleName: beneficialOwner.middleName || undefined,
                lastName: beneficialOwner.lastName || undefined,
                occupation: beneficialOwner.jobTitle || undefined,
                dateOfBirth: beneficialOwner.birthday
                  ? format(beneficialOwner.birthday, "yyyy-MM-dd")
                  : undefined,
                email: beneficialOwner.email || undefined,
                phoneNumber: beneficialOwner.phone
                  ? phoneFormDataToE164(beneficialOwner.phone)
                  : undefined,
                country: beneficialOwner.country || undefined,
                individualTaxId: beneficialOwner.taxId || undefined,
                addressLine1: beneficialOwner.address.addressLine1 || undefined,
                addressLine2: beneficialOwner.address.addressLine2 || undefined,
                city: beneficialOwner.address.city || undefined,
                region: beneficialOwner.address.state || undefined,
                postalCode: beneficialOwner.address.postalCode || undefined
              },
              ownershipStake: beneficialOwner.ownershipStake ?? 0
            });
          }
        }
      }

      // 4. Update acknowledgements
      if (verificationLevel === "Banking") {
        await updateAcknowledgements(bankingAcknowledgements);
      }
      if (verificationLevel === "Tax") {
        const country =
          data.accountType.type === "Individual"
            ? data.personalInformation.person.country
            : data.accountType.country;
        const isInternational = !isCountryUS(country);

        if (isInternational) {
          await updateAcknowledgements(
            internationalClearingAccountAcknowledgements
          );
        }
      }

      // 5. Perform verification
      await performVerification(verificationLevel);

      // 6. Update cache
      verificationState = await getVerificationState(verificationLevel);

      return verificationState;
    },
    {
      dependencies: [
        QUERY_CUSTOMER_ENTITY,
        QUERY_VERIFICATIONS,
        QUERY_VERIFICATION_MISSING_DATA,
        QUERY_NOTIFICATIONS_NOTIFICATIONS,
        QUERY_USERS_USER_PROFILE,
        QUERY_USERS_MEMBER_PROFILE
      ],
      ...config
    }
  );
};

async function getVerificationState(
  verificationLevel: "Tax" | "Banking" | "Enhanced"
) {
  const [verifications, missingData] = await Promise.all([
    getVerifications(),
    getVerificationMissingData(verificationLevel)
  ]);

  queryCache.setQueryData(
    [QUERY_VERIFICATION_MISSING_DATA, verificationLevel],
    missingData
  );
  queryCache.setQueryData(QUERY_VERIFICATIONS, verifications);

  const status = verificationService.getVerificationStatus(
    verificationLevel,
    verifications
  );

  return {
    status,
    missingData
  };
}
