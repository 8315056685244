import { FlowMEChangeContext } from "..";
import { useWSMutation, WSMutationConfig } from "../../../../../query/helpers";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { requestCustomerEntityChange } from "../../../../../services/api/onboarding/customer";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { WSServiceError } from "../../../../../utils/serviceHelper";

export const useMutationSubmitImmaterialLegalName = (
  config?: WSMutationConfig<void, WSServiceError, any>
) => {
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();
  const queryCustomerEntity = useQueryCustomerEntity();

  return useWSMutation(async () => {
    if (queryCustomerEntity.data?.type === "Individual") {
      await requestCustomerEntityChange({
        changeType: "Immaterial",
        effectiveAt: context.reason?.effectiveAt,
        reason: context.reason?.reason,
        customerData: {
          firstName: context.individualLegalName?.firstName || undefined,
          middleName: context.individualLegalName?.middleName || undefined,
          lastName: context.individualLegalName?.lastName || undefined
        }
      });
    } else {
      await requestCustomerEntityChange({
        changeType: "Immaterial",
        effectiveAt: context.reason?.effectiveAt,
        reason: context.reason?.reason,
        customerData: {
          legalBusinessName:
            context.businessLegalName?.legalBusinessName || undefined,
          doingBusinessAs: context.businessLegalName?.dba || undefined
        }
      });
    }

    setContext({ nameChanged: true });
  }, config);
};
