import { IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

/**
 * This function checks if the bulk batch items are being created or not
 * @param bulkBatch IBulkBatch
 * @returns boolean
 */
export function isFileUploaded(bulkBatch?: IBulkBatch): boolean {
  // If the bulk batch has a flatfileBatchId and bulkImporterFilename, it means that the file has been uploaded.
  return (
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileBatchId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.bulkImporterFilename]
  );
}

/**
 * This function checks if the bulk batch items are being created or not
 * @param bulkBatch IBulkBatch
 * @returns boolean
 */
export function isUploadInProgress(bulkBatch?: IBulkBatch): boolean {
  // If the bulk batch has a flatfileBatchId and flatfileJobId, it means the batch is being uploaded and the upload has not finished yet.
  return (
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileBatchId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileJobId] &&
    !bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.uploadFinishedAt]
  );
}

/**
 * This function checks if the creation of bulk batch items is finished or not
 * @param bulkBatch IBulkBatch
 * @returns boolean
 */
export function isUploadFinished(bulkBatch?: IBulkBatch): boolean {
  // If the bulk batch has a flatfileBatchId and flatfileJobId, and uploadFinishedAt, it means the batch has finished uploading.
  return (
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileBatchId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileJobId] &&
    !!bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.uploadFinishedAt]
  );
}
