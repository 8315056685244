import React, { useMemo, useState } from "react";
import { toWSMoneyString } from "../../../utils";
import { WSElement } from "../WSElement/WSElement.component";
import { WSPill } from "../common/WSPill/WSPill.component";
import { WSButton } from "../core/WSButton/WSButton.component";
import styles from "./WSFilters.module.scss";
import {
  FilterChangeHandler,
  WSFilter,
  WSFilterAmountRangeValue,
  WSFilterDateRangeValue,
  WSFilterMultiValue,
  WSFilterOption,
  WSFilterSingleValue,
  WSFilterType,
  WSFilterValues
} from "./types";
import { getDateRangeLabel } from "./utils/dateRange";

function getAmountRangeValueText(value?: WSFilterAmountRangeValue) {
  if (!value?.from && !value?.to) {
    return "–";
  }

  if (value.from && !value.to) {
    return `From ${toWSMoneyString(value.from)}`;
  }

  if (!value.from && value.to) {
    return `Up to ${toWSMoneyString(value.to)}`;
  }

  return `${toWSMoneyString(value.from)}–${toWSMoneyString(value.to)}`;
}

export type ActiveFilter = WSFilterOption & {
  filterName: string;
  filterType: WSFilterType;
};

export const useActiveFilters = (
  values: WSFilterValues,
  filters: WSFilter[]
) => {
  return useMemo(() => {
    const activeFilters: ActiveFilter[] = [];

    filters.forEach((filter) => {
      if (filter.nonCountable) {
        return;
      }

      const filterName = filter.name;
      const filterType = filter.type;
      const prefix = filter.titleInLabel ? `${filter.title}: ` : "";

      if (filterType === "daterange") {
        const filterValue = values[filterName] as
          | WSFilterDateRangeValue
          | undefined;

        if (filterValue !== undefined) {
          activeFilters.push({
            name: filterName,
            label: prefix + getDateRangeLabel(filterValue),
            filterName,
            filterType
          });
        }
      } else if (filterType === "checkbox") {
        const filterValue = (values[filterName] || []) as WSFilterMultiValue;

        filter.options?.forEach((option) => {
          if (filterValue.includes(option.name)) {
            activeFilters.push({
              ...option,
              label: prefix + option.label,
              filterName,
              filterType
            });
          }

          option.options?.forEach((subOption) => {
            if (filterValue.includes(subOption.name)) {
              activeFilters.push({
                ...subOption,
                label: prefix + subOption.label,
                filterName,
                filterType
              });
            }
          });
        });
      } else if (filterType === "radio") {
        const filterValue = values[filterName] as WSFilterSingleValue;

        filter.options?.forEach((option) => {
          if (filterValue === option.name) {
            activeFilters.push({
              ...option,
              label: prefix + option.label,
              filterName,
              filterType
            });
          }
        });
      } else if (filterType === "input") {
        const filterValue = values[filterName] as WSFilterSingleValue;

        if (filterValue) {
          activeFilters.push({
            name: filterName,
            label: prefix + filterValue,
            filterName,
            filterType
          });
        }
      } else if (filterType === "amountrange") {
        const filterValue = values[filterName] as WSFilterAmountRangeValue;
        const text = getAmountRangeValueText(filterValue);

        if (filterValue) {
          activeFilters.push({
            name: filterName,
            label: prefix + text,
            filterName,
            filterType
          });
        }
      }
    });

    return activeFilters;
  }, [filters, values]);
};

export type SelectedFiltersProps = {
  activeFilters: ActiveFilter[];
  values: WSFilterValues;
  onFilterChange: FilterChangeHandler;
  showHideButton?: boolean;
};

export const ActiveFilters: React.FC<SelectedFiltersProps> = ({
  activeFilters,
  values,
  onFilterChange,
  showHideButton
}) => {
  const [visible, setVisible] = useState(true);

  if (activeFilters.length === 0) {
    return null;
  }

  return (
    <WSElement mt="S">
      {showHideButton &&
        (visible ? (
          <WSButton.Link
            type="button"
            onClick={() => {
              setVisible(false);
            }}
            mb="S"
            size="S"
            rightIcon="hide"
            key="hide"
          >
            Hide filters
          </WSButton.Link>
        ) : (
          <WSButton.Link
            type="button"
            onClick={() => {
              setVisible(true);
            }}
            mb="S"
            size="S"
            rightIcon="view"
            key="show"
          >
            Show filters
          </WSButton.Link>
        ))}
      {visible && (
        <WSElement className={styles.activeFilters}>
          {activeFilters.map((option, index) => (
            <WSPill
              key={`${index}-${option.name}`}
              text={option.label}
              theme="neutral"
              onDismiss={() => {
                if (option.filterType === "daterange") {
                  onFilterChange(option.filterName, undefined);
                } else if (option.filterType === "amountrange") {
                  onFilterChange(option.filterName, undefined);
                } else if (option.filterType === "radio") {
                  onFilterChange(option.filterName, undefined);
                } else {
                  const filterValue = values[
                    option.filterName
                  ] as WSFilterMultiValue;

                  if (option.options?.length) {
                    const newValue = filterValue.filter((optionName) => {
                      return !option.options?.some(
                        (opt) => opt.name === optionName
                      );
                    });

                    onFilterChange(option.filterName, newValue);
                  } else if (option.filterType === "input") {
                    onFilterChange(option.filterName, undefined);
                  } else {
                    onFilterChange(
                      option.filterName,
                      filterValue.filter((value) => value !== option.name)
                    );
                  }
                }
              }}
            />
          ))}
        </WSElement>
      )}
    </WSElement>
  );
};
