import { InternalAccountType } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { components, operations } from "../types";
import { ListRequestQuery } from "../../../../utils/serviceHelper";
import { StatementQuerySort } from "../../../../modules/Statements/routes/RouteStatements";

export type IStatementResponse =
  operations["retrieveInternalAccountStatement"]["responses"]["200"]["content"]["application/json"];

export type IStatementListResponse =
  operations["listInternalAccountStatements"]["responses"]["200"]["content"]["application/json"];

export type IStatementEntryResponse =
  components["schemas"]["InternalAccountStatementEntry"];

export type IStatementInternalAccountType =
  components["schemas"]["InternalAccountType"];

export enum StatementStatus {
  Draft = "Draft",
  Available = "Available",
  Archived = "Archived"
}

export type IStatementsFilters = {
  accountType?: { in: InternalAccountType[] };
  status?: StatementStatus;
  startDate?: { ">="?: Date; "<="?: Date };
  endDate?: { ">="?: Date; "<="?: Date };
};

export type IStatementsRequestQuery = ListRequestQuery<
  IStatementsFilters,
  StatementQuerySort
>;
