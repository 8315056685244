import classNames from "classnames";
import React from "react";
import {
  WSElement,
  WSElementColorsType
} from "../WSElement/WSElement.component";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";
import { WSAvatar, WSAvatarProps, WSPill, WSPillProps } from "../common";
import { WSIcon, WSIconName } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSTableCell.module.scss";

type Props = {
  text?: string;
  secondaryText?: string;
  secondaryTextColor?: WSElementColorsType;
  icon?: WSIconName;
  avatar?: WSAvatarProps;
  pill?: WSPillProps;
  truncationOptions?: {
    text?: {
      allowTwoLines?: boolean;
    };
  };
  onClick?: React.MouseEventHandler<HTMLElement>;
  tooltip?: WSTooltipIconProps["tooltip"];
};

export const WSTableCell: React.FC<Props> = (props) => {
  if (
    !props.text &&
    !props.secondaryText &&
    !props.icon &&
    !props.avatar &&
    !props.pill
  ) {
    return (
      <WSElement className={styles.tableCell} onClick={props.onClick}>
        <WSText.ParagraphSm>–</WSText.ParagraphSm>
      </WSElement>
    );
  }

  const textElement = props.text && (
    <WSText.ParagraphSm
      className={classNames(
        styles.text,
        props.truncationOptions?.text?.allowTwoLines && styles.twoLines
      )}
    >
      {props.text}
    </WSText.ParagraphSm>
  );
  const secondaryTextElement = props.secondaryText && (
    <WSText.ParagraphXs
      className={classNames(styles.secondaryText, styles.twoLines)}
      color={props.secondaryTextColor || "gray400"}
    >
      {props.secondaryText}
    </WSText.ParagraphXs>
  );
  const iconElement = props.icon && (
    <WSIcon name={props.icon} size="M" color="gray500" />
  );
  const avatarElement = props.avatar && <WSAvatar {...props.avatar} size="S" />;
  const pillElement = props.pill && (
    <WSPill {...props.pill} truncate className={styles.pill} />
  );

  const tooltipElement = props.tooltip ? (
    <WSTooltipIcon tooltip={props.tooltip} />
  ) : undefined;

  return (
    <WSElement className={styles.tableCell} onClick={props.onClick}>
      {props.avatar ? avatarElement : props.icon ? iconElement : null}
      <WSElement className={styles.main}>
        {pillElement}
        {textElement}
        {secondaryTextElement}
      </WSElement>
      {props.avatar && props.icon ? iconElement : null}
      {tooltipElement}
    </WSElement>
  );
};
