import {
  IPayeeResponse,
  PayeeTaxDocumentSharePermission
} from "@wingspanhq/payments/dist/interfaces";

export const isPayeeProvidedInfo = (payee: IPayeeResponse) => {
  return (
    payee.payeeOwnedData.shareTaxDocument ===
    PayeeTaxDocumentSharePermission.Allow
  );
};
