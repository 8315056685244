import {
  IPayerTaxFormResponse,
  TaxFormDeliveryMethod
} from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { WSDataItemProps, WSGrid } from "@wingspanhq/fe-component-library";
import { DataItem } from "../../../../../shared/components/DataItem";
import { formatPhone } from "../../../../../shared/utils/formatPhone";

type Props = {
  taxForm: IPayerTaxFormResponse;
};

export const ContactInformation: React.FC<Props> = ({ taxForm }) => {
  const fields = useMemo(() => {
    const result: WSDataItemProps[] = [
      {
        label: "Email",
        value: taxForm.payee?.user.email
        // alert: getAlertByStatus(tinStatus)
      },
      {
        label: "Phone number",
        value: taxForm.payee?.user.phone?.number
          ? formatPhone(taxForm.payee?.user.phone?.number)
          : "-"
      },
      {
        label: "External ID",
        value: taxForm.payerPayee?.payerOwnedData?.payeeExternalId
      },
      {
        label: "Delivery preference",
        value:
          taxForm.data.deliveryMethod === TaxFormDeliveryMethod.Electronic
            ? "Electronic delivery"
            : "Mail delivery"
      }
    ];

    return result;
  }, [taxForm]);

  return (
    <WSGrid gutter="2XL">
      {fields.map((props, index) => (
        <WSGrid.Item
          key={`${props.label} + ${index}`}
          span={{ xs: "12", s: "6" }}
        >
          <DataItem {...props} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
