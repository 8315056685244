import { useHistory, useRouteMatch } from "react-router-dom";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { useBulkCalculate1099Batch } from "../../../BulkImporter/query/bulkCalculation1099/queries";
import {
  useCreateBulk1099SubmitBatchItem,
  useUpdateBulkCalculate1099Batch
} from "../../../BulkImporter/query/bulkCalculation1099/mutations";
import {
  IconListItem,
  WSButton,
  WSButtons,
  WSIconList,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { IBulkCalculation1099ItemCreate } from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";
import { useUserId } from "../../../../query/hooks/helpers";
import { CURRENT_YEAR } from "../../constants/currentYear";
import { BulkCalculation1099Type } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { WSQueries } from "../../../../query/WSQuery";
import { usePayerTaxFormsListSize } from "../../../../query/taxForm/queries/usePaginatedPayerTaxForms";
import { queryCache } from "react-query";
import {
  QUERY_SEARCH_TAX_FORM_ROWS,
  QUERY_SEARCH_TAX_FORM_ROWS_COUNT
} from "../../../../query/search/taxForm/keys";

export const RouteGenerate1099NECAmounts: React.FC = () => {
  const history = useHistory<{
    canSkip?: boolean;
  }>();
  const userId = useUserId();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const canSkip = history.location.state?.canSkip;

  const qAllTaxForms = usePayerTaxFormsListSize({
    year: CURRENT_YEAR
  });
  const qAutoSyncTaxForms = usePayerTaxFormsListSize({
    year: CURRENT_YEAR,
    filter: {
      recipientSyncEnabled: true
    }
  });

  const qCalculate1099BulkBatch = useBulkCalculate1099Batch(bulkBatchId);

  const [createBulk1099SubmitBatchItem, createBulk1099SubmitBatchItemMeta] =
    useCreateBulk1099SubmitBatchItem();

  const [updateBulkCalculate1099Batch, updateBulkCalculate1099BatchMeta] =
    useUpdateBulkCalculate1099Batch();

  const onContinue = async () => {
    const batchItemsRequest: IBulkCalculation1099ItemCreate = {
      clientId: userId,
      year: CURRENT_YEAR,
      calculationType: BulkCalculation1099Type.Balances
    };
    await createBulk1099SubmitBatchItem(
      {
        batchId: bulkBatchId,
        ...batchItemsRequest
      },
      {
        onSuccess: async () => {
          await updateBulkCalculate1099Batch(
            {
              id: bulkBatchId,
              data: {
                status: BulkStatus.Pending
              }
            },
            {
              onSuccess: () => {
                // invalidate query for tax form search
                queryCache.invalidateQueries(QUERY_SEARCH_TAX_FORM_ROWS);
                queryCache.invalidateQueries(QUERY_SEARCH_TAX_FORM_ROWS_COUNT);

                history.push(
                  `/member/1099-filing/generate-1099-nec-amounts/${bulkBatchId}/progress`
                );
              }
            }
          );
        },
        onError: () => {}
      }
    );
  };

  const gotoDashboard = () => {
    history.push("/member/1099-filing/dashboard");
  };

  return (
    <EditLayout title="Generate amounts and statuses">
      <WSQueries
        queries={{
          qCalculate1099BulkBatch,
          qAllTaxForms,
          qAutoSyncTaxForms
        }}
      >
        {({
          qCalculate1099BulkBatchData: calculate1099BulkBatch,
          qAllTaxFormsData: allTaxFormsCount,
          qAutoSyncTaxFormsData: autoSyncTaxFormsCount
        }) => {
          const overwrittenTaxFormsCount =
            allTaxFormsCount - autoSyncTaxFormsCount;
          return (
            <>
              <WSText.Heading4 mb="3XL">
                Generate 1099-NECs amounts and statuses
              </WSText.Heading4>
              <WSIconList
                mb="XL"
                items={[
                  {
                    description: `${allTaxFormsCount} recipient's 1099-NECs will be calculated in total`,
                    icon: {
                      name: "calculator",
                      color: "blue500",
                      size: "M"
                    }
                  },
                  {
                    description: `${autoSyncTaxFormsCount} recipient's auto-synced 1099-NECs will be calculated`,
                    icon: {
                      name: "user",
                      color: "blue500",
                      size: "M"
                    }
                  },
                  ...(overwrittenTaxFormsCount > 0
                    ? [
                        {
                          description: `${overwrittenTaxFormsCount} recipient's overwritten 1099-NECs will be calculated`,
                          icon: {
                            name: "edit-box",
                            color: "blue500",
                            size: "M"
                          }
                        } as IconListItem
                      ]
                    : [])
                ]}
              />
              <WSMessageBox.Info mb="XL" size="Large">
                <WSText mb="XL" color="gray500">
                  What to expect:
                </WSText>

                <WSText as="ol">
                  <WSText as="li" mb="M" color="gray500">
                    1. Auto-synced 1099-NECs (recipient provided information)
                    will be calculated by default
                  </WSText>
                  <WSText as="li" mb="M" color="gray500">
                    2. Manually overwritten 1099-NECs are calculated with
                    overwritten recipient information retained
                    <WSText mt="XS" ml="M" color="gray500">
                      • Overwritten information can be reverted on an individual
                      basis
                    </WSText>
                  </WSText>
                </WSText>
              </WSMessageBox.Info>

              <WSButtons forceFullWidth mb={canSkip ? "2XL" : "NONE"}>
                <WSButton.Primary
                  onClick={onContinue}
                  disabled={calculate1099BulkBatch.status !== BulkStatus.Open}
                  loading={
                    createBulk1099SubmitBatchItemMeta.isLoading ||
                    updateBulkCalculate1099BatchMeta.isLoading
                  }
                >
                  Generate amounts and statuses
                </WSButton.Primary>
                {canSkip ? (
                  <WSButton.Link onClick={gotoDashboard}>
                    Skip for now
                  </WSButton.Link>
                ) : (
                  <WSButton.Secondary onClick={gotoDashboard}>
                    Cancel
                  </WSButton.Secondary>
                )}
              </WSButtons>

              {canSkip ? (
                <WSText.ParagraphSm color="gray400">
                  If you skip for now, you can run 1099-NECs amounts and
                  statuses calculations later from the 1099 dashboard. New
                  recipients will remain in a pending state until calculations
                  are run.
                </WSText.ParagraphSm>
              ) : null}
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
