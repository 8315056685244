import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import { getCountryName } from "../../query/users/selectors";

export const selectorAddressString = (
  address: Partial<IAddress> | undefined,
  hideCountry: boolean = false,
  separator: string = "\n"
): string | undefined => {
  if (!address) {
    return undefined;
  }

  const { addressLine1, addressLine2, state, postalCode, city, country } =
    address || {};

  const stateAddress = [city ? `${city},` : null, state, postalCode]
    .filter(Boolean)
    .join(" ");

  const countryName = hideCountry
    ? undefined
    : country && getCountryName(country)
    ? getCountryName(country)
    : null;

  const result = [addressLine1, addressLine2, stateAddress, countryName]
    .filter(Boolean)
    .join(separator);

  if (result.trim() === "") {
    return undefined;
  }

  return result;
};
