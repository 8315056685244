import * as Yup from "yup";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import {
  companyNameValidator,
  validatorFirstName,
  validatorLastName
} from "../../../../../utils/validators";
import { validatorSsn } from "../../../../../shared/validators/validatorSsn";
import { validatorCompanyName } from "../../../../../shared/validators/validatorCompanyName";
import { validatorTaxId } from "../../../../../shared/validators/validatorTaxId";
import { validatorName } from "../../../../../shared/validators/validatorName";
import { getValidationSchemaAddress } from "../../../../Onboarding/components/FormPartialAddress";
import { validatorBusinessCompanyStructure } from "../../../../../shared/validators/validatorCompanyStructure";

export const getValidationSchema = (withTaxInfo: boolean) => {
  const validationSchema = Yup.object().shape({
    name: validatorName,
    companyName: companyNameValidator
  });

  if (withTaxInfo) {
    const taxInfoValidationSchema = Yup.object().shape({
      tinType: Yup.string()
        .oneOf([TinType.Individual, TinType.Business])
        .required("TIN type is required"),
      individual: Yup.object().when("tinType", {
        is: TinType.Individual,
        then: Yup.object()
          .shape({
            firstName: validatorFirstName.required("First name is required"),
            lastName: validatorLastName.required("Last name is required"),
            ssn: validatorSsn.required("SSN is required")
          })
          .required("Individual information is required")
      }),
      business: Yup.object().when("tinType", {
        is: TinType.Business,
        then: Yup.object()
          .shape({
            legalBusinessName: validatorCompanyName.required(
              "Legal business name is required"
            ),
            ein: validatorTaxId.required("EIN is required"),
            companyStructure: validatorBusinessCompanyStructure.required(
              "Tax classification is required"
            ),
            disregardedEntityName: validatorCompanyName
          })
          .required("Business information is required")
      }),
      address: getValidationSchemaAddress("US").concat(
        Yup.object().shape({
          country: Yup.string().required("Country is required")
        })
      )
    });

    return validationSchema.concat(taxInfoValidationSchema);
  }

  return validationSchema;
};
