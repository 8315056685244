import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";
import {
  FormRequestCorrectionData,
  ICorrectionType
} from "../../../../TaxCorrections/types";
import { getLast4 } from "../../../../../shared/utils/getLast4";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../../TaxCorrections/constants/corrections";

export function getCorrectionTypeFromPayerCorrection(
  taxForm: IPayeeTaxFormResponse | IPayerTaxFormResponse,
  payedCorrectedData: FormRequestCorrectionData
): ICorrectionType {
  let addressStateNotChanged = false;
  const corrections = [];

  const pendingCorrection = taxForm?.pendingCorrection;

  // type1 correction
  if (
    payedCorrectedData?.amountCorrectionHelpText?.trim() &&
    payedCorrectedData?.necTotalAmount >= 0 &&
    payedCorrectedData?.necTotalAmount !== pendingCorrection?.data?.totalAmount
  ) {
    corrections.push(CORRECTION_AMOUNT);
  }

  const correctionRequestTinData = {
    businessName: pendingCorrection?.data?.w9Info?.legalBusinessName,
    taxClassification: pendingCorrection?.data?.w9Info?.companyStructure,
    disregardedEntityName:
      pendingCorrection?.data?.w9Info?.disregardedEntityName,
    ein: pendingCorrection?.data?.w9Info?.ein,

    ssnLastFour: pendingCorrection?.data?.w9Info?.ssnLastFour,
    firstName: pendingCorrection?.data?.w9Info?.firstName,
    lastName: pendingCorrection?.data?.w9Info?.lastName
  };
  // type2 TIN or name correction

  if (
    (payedCorrectedData?.ein &&
      (payedCorrectedData?.businessName !==
        correctionRequestTinData?.businessName ||
        payedCorrectedData?.taxClassification !==
          correctionRequestTinData?.taxClassification ||
        payedCorrectedData?.disregardedEntityName !==
          correctionRequestTinData?.disregardedEntityName ||
        payedCorrectedData?.ein !== correctionRequestTinData?.ein)) ||
    (payedCorrectedData?.ssn &&
      (getLast4(payedCorrectedData?.ssn) !==
        correctionRequestTinData?.ssnLastFour ||
        payedCorrectedData?.firstName !== correctionRequestTinData?.firstName ||
        payedCorrectedData?.lastName !== correctionRequestTinData?.lastName))
  ) {
    corrections.push(CORRECTION_TIN_OR_NAME);
  }

  const correctionRequestAddress = {
    addressLine1: pendingCorrection?.data?.w9Info?.addressLine1,
    addressLine2: pendingCorrection?.data?.w9Info?.addressLine2,
    city: pendingCorrection?.data?.w9Info?.city,
    state: pendingCorrection?.data?.w9Info?.state,
    zipCode: pendingCorrection?.data?.w9Info?.postalCode,
    country: pendingCorrection?.data?.w9Info?.country
  };
  // type2 address correction
  if (
    payedCorrectedData?.formW9Address &&
    (payedCorrectedData?.formW9Address?.addressLine1 !==
      correctionRequestAddress.addressLine1 ||
      payedCorrectedData?.formW9Address?.addressLine2 !==
        correctionRequestAddress.addressLine2 ||
      payedCorrectedData?.formW9Address?.city !==
        correctionRequestAddress.city ||
      payedCorrectedData?.formW9Address?.state !==
        correctionRequestAddress.state ||
      payedCorrectedData?.formW9Address?.postalCode !==
        correctionRequestAddress.zipCode ||
      payedCorrectedData?.formW9Address?.country !==
        correctionRequestAddress.country)
  ) {
    corrections.push(CORRECTION_ADDRESS);
  }

  // type2 address correction -- state not changed
  if (
    payedCorrectedData?.formW9Address &&
    payedCorrectedData.formW9Address?.state === correctionRequestAddress?.state
  ) {
    addressStateNotChanged = true;
  }
  return {
    corrections,
    addressStateNotChanged
  };
}
