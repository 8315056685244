import { MutationResult } from "react-query";
import { ICurrencyRate } from "../../../../services/api/payments/invoices/types";
import { WSServiceError } from "../../../../utils/serviceHelper";
import styles from "./index.module.scss";
import {
  toWSDateString,
  useIsDesktop,
  WSButton,
  WSFlexBox,
  WSIcon,
  WSText,
  WSTooltip
} from "@wingspanhq/fe-component-library";
import { formatMoney } from "accounting";
import { useRef } from "react";
import cn from "classnames";
import { localStorageUtil } from "../../../../utils/localStorage";

export const convertToUSD = (amount: number, rate: number) => {
  return amount * rate;
};

export const currencyFormatter = (currency: string = "USD") =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency
  });

export const CurrencyStatus = ({
  getCurrencyRate,
  currencyRateMeta,
  currency,
  isHideStatus
}: {
  getCurrencyRate: () => void;
  currencyRateMeta: MutationResult<ICurrencyRate, WSServiceError>;
  currency: string;
  isHideStatus: boolean;
}) => {
  const tooltipIconRef = useRef<HTMLSpanElement>(null);

  if (isHideStatus) return null;

  return (
    <>
      {" "}
      {currencyRateMeta.isLoading ? (
        <WSFlexBox.CenterY wrap="nowrap" mt="M">
          <WSIcon block name="loader" size={"S"} color={"gray500"} mr="S" />
          <WSText.ParagraphSm color="gray500">
            Fetching exchange rate...
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      ) : currencyRateMeta.isError ? (
        <WSText.ParagraphSm mt="M" color="red400">
          {currencyRateMeta.error?.message}, please{" "}
          <WSButton
            kind="Link"
            size="S"
            onClick={() => {
              getCurrencyRate();
            }}
          >
            try again
          </WSButton>
        </WSText.ParagraphSm>
      ) : currencyRateMeta.data?.exchangeRate ? (
        <>
          <WSText.ParagraphSm mt="M" color="gray500">
            Exchange rate: USD $1 = {currency}{" "}
            {currencyFormatter(currency).format(
              1 / currencyRateMeta.data?.exchangeRate
            )}
          </WSText.ParagraphSm>
          <WSFlexBox.CenterY wrap="nowrap">
            <WSText.ParagraphSm color="gray500" mr="S">
              Updated:{" "}
              {toWSDateString(currencyRateMeta.data?.exchangeRateTimestamp)}
            </WSText.ParagraphSm>
            <WSIcon
              ref={tooltipIconRef}
              size="S"
              color="gray500"
              name="info-circle-fill"
            />
            <WSTooltip
              dark
              target={tooltipIconRef}
              title="Editing the amount (or adding new items) will automatically update the exchange rate to Google Finance’s latest exchange rates for that item. Input must be valid numerical values only."
            />
          </WSFlexBox.CenterY>
        </>
      ) : null}
    </>
  );
};

export const ConvertedValue = ({
  isLoading,
  exchangeRate,
  totalCost,
  onRemove
}: {
  isLoading: boolean;
  exchangeRate: number | undefined;
  totalCost: number;
  onRemove?: () => void;
}) => {
  const isDesktop = useIsDesktop();

  return (
    <>
      {isDesktop && (
        <WSIcon color="gray600" size="M" name="arrow-right" mr="S" />
      )}

      <WSFlexBox.CenterY
        mr={onRemove ? "M" : undefined}
        colorBackground="gray50"
        pl="S"
        pr="S"
        wrap="nowrap"
        justify="space-between"
        className={cn(styles.totalCostFieldContainer, styles.convertedValue)}
      >
        <WSText.Paragraph color="gray600">USD </WSText.Paragraph>
        <WSText.Paragraph color="gray600">
          {isLoading ? "" : exchangeRate ? formatMoney(totalCost) : "$ —.——"}
        </WSText.Paragraph>
        {isLoading && (
          <WSIcon block name="loader" size={"S"} color={"gray600"} mr="S" />
        )}
      </WSFlexBox.CenterY>
    </>
  );
};

export const setLocalStorageCurrency = (currency: string) => {
  localStorageUtil.setItem("invoiceCurrency", currency);
};
export const getLocalStorageCurrency = () => {
  return localStorageUtil.getItem("invoiceCurrency");
};
