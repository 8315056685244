import { WSService } from "../../../../utils/WSService";
import { ICurrencyRateRequest, ICurrencyRateResponse } from "./types";

const service = new WSService("/v2/payments/currency");

export const getCurrencyRate = async (
  conversionData: ICurrencyRateRequest
): Promise<ICurrencyRateResponse> => {
  const { data } = await service.post("/quotes", conversionData);

  return data;
};
