import { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { RouteStatementArchived } from "./routes/RouteArchived";
import { RouteStatements } from "./routes/RouteStatements";
import { ARCHIVED_STATEMENTS } from "./utils/constants";

export const StatementIndex: FC<RouteComponentProps> = () => {
  const routes = [
    {
      isExact: true,
      path: `/member/statements/${ARCHIVED_STATEMENTS}`,
      component: RouteStatementArchived
    },
    {
      path: "/member/statements",
      component: RouteStatements
    }
  ];

  return (
    <Switch>
      {routes.map(route => (
        <Route
          exact={route.isExact}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  );
};
