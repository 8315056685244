import { serviceSearch } from "../service";
import {
  IPayerTaxFormResponse,
  TaxFormCorrectionStatus
} from "@wingspanhq/payments/dist/interfaces";
import { ITaxFormRedactedSubmissionData } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { ITaxFormSubmissionResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

export interface ITaxFormRowSearchParams {
  luceneQueryString?: string;
  searchString?: string;
  expandOrganizationsForUserId?: string;
}

// TODO: clean up this interface
export type ITaxFormSearchRow = IPayerTaxFormResponse & {
  lastCorrectionStatus: TaxFormCorrectionStatus;
  submissions: ITaxFormRedactedSubmissionData[] | ITaxFormSubmissionResponse[];
  id: string;
};

export const getTaxFormRows = async (
  searchParams: ITaxFormRowSearchParams,
  params?: any
): Promise<{
  data: ITaxFormSearchRow[];
  summary: { listSize: number };
}> => {
  const { luceneQueryString, searchString, expandOrganizationsForUserId } =
    searchParams;
  const { data, headers } = await serviceSearch.get(`/tax-form-row`, {
    params: {
      ...(searchString ? { search: `${searchString}*` } : {}),
      ...(luceneQueryString ? { match: luceneQueryString } : {}),
      ...params
    },
    ...(expandOrganizationsForUserId
      ? {
          headers: {
            "x-wingspan-expansion": expandOrganizationsForUserId
          }
        }
      : {})
  });

  return { data, summary: { listSize: Number(headers["x-ws-list-size"]) } };
};
