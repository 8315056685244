import {
  WSAlert,
  WSButton,
  WSElement,
  WSEmptyState,
  WSGrid,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { WS_LINKS } from "../../../../types/wsLinks";

import { IPayrollSettings } from "@wingspanhq/payments/dist/interfaces";
import { IMember } from "@wingspanhq/users";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { useOptInTaxFilling } from "../../components/ModalOptInTaxFilling";
import { SelectTaxYear } from "../../components/SelectYear";
import { TaxFilingFAQ } from "../../components/TaxFilingFAQ/TaxFilingFAQ";
import {
  TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH,
  TAX_FILING_1099_FILING_SETTINGS_PATH,
  useGoToGenerateTaxFormProgress
} from "../../paths";
import { useCurrentTaxFilingYear } from "../../utils/useCurrentTaxFilingYear";
import { ContractorsInfo } from "./ContractorsInfo";
import styles from "./RoutePreview.module.scss";
import { AlertGenerationInProgress } from "../../components/AlertGenerationInProgress";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";
import { PayerPayeeEngagementSearchableStatus } from "../../../../services/search";
import { selectorCurrentYearFilingSettingsEnabled } from "../../selectors/selectorCurrentYearFilingSettingsEnabled";
import { BulkCalculation1099Type } from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { useActiveBatchItems } from "../../hooks/useCurrentCalculationBatchStatus";

const getIsReadyForGenerateTaxForms = (
  member: IMember,
  payrollSettings: IPayrollSettings,
  payeesCount: number
) => {
  const name = member?.profile.company?.legalBusinessName;
  const address =
    member?.profile.address?.addressLine1 &&
    member?.profile.address.city &&
    member?.profile.address.state &&
    member?.profile.address.postalCode;

  const calculationSettings =
    payrollSettings?.calculationSettings1099?.offPlatformPayments &&
    payrollSettings?.calculationSettings1099?.cardProcessingFees &&
    payrollSettings?.calculationSettings1099?.reimbursableExpenses;

  const supportInfo =
    member?.profile.company?.supportEmail &&
    member?.profile.company?.supportPhoneNumber;

  return !!(
    name &&
    address &&
    calculationSettings &&
    supportInfo &&
    payeesCount
  );
};

export const RoutePreview: React.FC = () => {
  const history = useHistory();
  const currentYear = useCurrentTaxFilingYear();
  const queryFeatureFlags = useFeatureFlags();

  const openTaxSettings = () => {
    history.push(TAX_FILING_1099_FILING_SETTINGS_PATH);
  };

  const openGenerateTaxForm = () => {
    history.push(TAX_FILING_1099_FILING_GENERATE_TAX_FORMS_INIT_PATH);
  };

  const optInCurrentYearFilling = useOptInTaxFilling(currentYear);
  const goToGenerationProgress = useGoToGenerateTaxFormProgress();

  const userId = useUserId();
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryMemberProfile = useMemberProfile(userId);
  const queryAllPayeeRowsSummary = usePayeeRowsSummaryQuery({
    filter: {
      searchableStatus: {
        "!=": PayerPayeeEngagementSearchableStatus.Inactive
      }
    }
  });

  const queryActiveBatchItems = useActiveBatchItems(currentYear);

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryPayrollSettings,
          queryMemberProfile,
          queryFeatureFlags,
          queryAllPayeeRowsSummary,
          queryActiveBatchItems
        }}
        onSuccess={({ queryPayrollSettings }) => {
          if (
            selectorCurrentYearFilingSettingsEnabled(
              queryPayrollSettings.data,
              currentYear
            ) === undefined
          ) {
            optInCurrentYearFilling();
          }
        }}
      >
        {({
          queryPayrollSettingsData,
          queryMemberProfileData,
          queryFeatureFlagsData,
          queryAllPayeeRowsSummaryData,
          queryActiveBatchItemsData
        }) => {
          const payeesCount = queryAllPayeeRowsSummaryData?.listSize || 0;
          const inProgressBatchItem = queryActiveBatchItemsData.find(
            item => item.calculationType === BulkCalculation1099Type.Balances
          );

          const isCurrentYearFilingEnabled =
            selectorCurrentYearFilingSettingsEnabled(
              queryPayrollSettingsData,
              currentYear
            );

          const isGenerateFormEnabled =
            !inProgressBatchItem &&
            isCurrentYearFilingEnabled &&
            getIsReadyForGenerateTaxForms(
              queryMemberProfileData,
              queryPayrollSettingsData,
              payeesCount
            ) &&
            queryFeatureFlagsData.nec1099season2024generation;

          return (
            <>
              <WSPageToolbar
                title="1099-NEC filing"
                actions={[
                  {
                    name: "generateForms",
                    onClick: openGenerateTaxForm,
                    label: "Generate tax forms",
                    buttonKind: "Primary",
                    visible: !!isGenerateFormEnabled
                  },
                  {
                    name: "beginFiling",
                    onClick: openTaxSettings,
                    buttonKind: isGenerateFormEnabled ? undefined : "Primary",
                    label: "Filing settings"
                  }
                ]}
                titleAfter={<SelectTaxYear />}
              />

              {!!inProgressBatchItem && (
                <AlertGenerationInProgress
                  mb="XL"
                  year={currentYear}
                  onView={() => {
                    goToGenerationProgress(
                      inProgressBatchItem.bulkCalculation1099BatchId
                    );
                  }}
                />
              )}

              {isCurrentYearFilingEnabled !== false ? (
                <WSList gap="2XL">
                  {isGenerateFormEnabled && (
                    <WSAlert
                      title="2024 tax forms generation available"
                      icon="info-circle"
                      theme="info"
                      action={{
                        text: "Generate tax forms for eligible contractors",
                        onClick: openGenerateTaxForm
                      }}
                    >
                      Form 1099-NECs can now be generated for eligible
                      contractors for 2024.
                    </WSAlert>
                  )}
                  <WSGrid>
                    <WSGrid.Item span={{ m: "8" }}>
                      <WSPanel>
                        <WSText.Paragraph weight="medium" color="gray600">
                          Preparing for 1099-NEC tax filing
                        </WSText.Paragraph>
                        <WSText.ParagraphSm color="gray500" mt="M">
                          Here are a few key reminders to prepare for tax filing
                          with Wingspan:
                        </WSText.ParagraphSm>

                        <WSPanel
                          noBorder
                          colorBackground="gray50"
                          px="2XL"
                          py="L"
                          mt="L"
                        >
                          <WSElement className={styles.infoList}>
                            <WSText.ParagraphSm weight="medium">
                              <ol>
                                <li>
                                  Confirm your tax filing settings
                                  <WSText.ParagraphSm color="gray500" mt="M">
                                    <ul>
                                      <li>
                                        Confirm your business information,
                                        calculation, and state filing settings.
                                        Update your settings from the 1099
                                        filing dashboard.
                                      </li>
                                      <li>
                                        Wingspan advises you to consult with a
                                        tax professional to choose the most
                                        suitable options for your business.
                                      </li>
                                    </ul>
                                  </WSText.ParagraphSm>
                                </li>
                                <WSButton.Secondary
                                  fullWidth
                                  mt="M"
                                  mb="L"
                                  onClick={openTaxSettings}
                                >
                                  Manage tax filing settings
                                </WSButton.Secondary>
                                <li>
                                  Contractor information
                                  <WSText.ParagraphSm color="gray500" mt="M">
                                    <ul>
                                      <li>
                                        Contractors must meet the eligible
                                        criteria for filing including minimum
                                        amounts, tax classification, and tax
                                        residency. Learn more about{" "}
                                        <a
                                          href={WS_LINKS.eligibilityCriteria}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          eligibility criteria
                                        </a>
                                        .
                                      </li>
                                      <li>
                                        To file with Wingspan, ensure that
                                        taxpayer information and payment
                                        information is complete and accurate
                                        before generating tax forms. Learn more
                                        about the{" "}
                                        <a
                                          href={WS_LINKS.taxFilingProcess}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          tax filing
                                        </a>{" "}
                                        process.
                                      </li>
                                      <li>
                                        Take advantage of Wingspan’s upload
                                        features to ensure your contractors’
                                        taxpayer and payments information is up
                                        to date and accurate by using the
                                        shortcuts below.
                                      </li>
                                    </ul>
                                  </WSText.ParagraphSm>
                                </li>
                                <WSElement mb="L">
                                  <WSGrid gutter="M">
                                    <WSGrid.Item span={{ m: "6" }}>
                                      <ContractorsInfo
                                        payeesCount={payeesCount}
                                      />
                                    </WSGrid.Item>
                                    <WSGrid.Item span={{ m: "6" }}>
                                      <WSPanel style={{ height: "100%" }}>
                                        <WSList gap="M">
                                          <WSText.Paragraph
                                            color="gray700"
                                            weight="medium"
                                          >
                                            Other useful links
                                          </WSText.Paragraph>
                                          <WSElement>
                                            <WSText.ParagraphSm
                                              color="gray500"
                                              mb="S"
                                            >
                                              Looking to bulk upload
                                              contractors?
                                            </WSText.ParagraphSm>
                                            <WSButton.Link
                                              size="S"
                                              icon="upload"
                                              rightIcon="chevron-right"
                                              onClick={() => {
                                                history.push(
                                                  "/member/imports/collaborators"
                                                );
                                              }}
                                            >
                                              Bulk upload contractors
                                            </WSButton.Link>
                                          </WSElement>
                                          <WSElement>
                                            <WSText.ParagraphSm
                                              color="gray500"
                                              mb="S"
                                            >
                                              Looking to bulk upload payments?
                                            </WSText.ParagraphSm>
                                            <WSButton.Link
                                              size="S"
                                              icon="upload"
                                              rightIcon="chevron-right"
                                              onClick={() => {
                                                history.push(
                                                  "/member/imports/payables"
                                                );
                                              }}
                                            >
                                              Bulk upload payments
                                            </WSButton.Link>
                                          </WSElement>
                                        </WSList>
                                      </WSPanel>
                                    </WSGrid.Item>
                                  </WSGrid>
                                </WSElement>
                                <li>
                                  Generate tax forms
                                  <WSText.ParagraphSm color="gray500" mt="M">
                                    <ul>
                                      <li>
                                        Beginning in January {currentYear + 1}{" "}
                                        (when all payments for {currentYear} are
                                        settled), Form 1099-NECs can be
                                        generated for eligible contractors in
                                        the U.S. only.
                                      </li>
                                    </ul>
                                  </WSText.ParagraphSm>
                                </li>
                                {isGenerateFormEnabled && (
                                  <WSButton.Secondary
                                    fullWidth
                                    mt="M"
                                    mb="L"
                                    onClick={openGenerateTaxForm}
                                  >
                                    Generate tax forms
                                  </WSButton.Secondary>
                                )}
                                <li>
                                  Submit for filing
                                  <WSText.ParagraphSm color="gray500" mt="M">
                                    <ul>
                                      <li>
                                        Only forms with filing status “Ready”
                                        will be submitted for filing with the
                                        IRS. We recommend reviewing forms with
                                        statuses “Action Required” and
                                        “Excluded” to ensure they have the
                                        correct information before filing.
                                      </li>
                                    </ul>
                                  </WSText.ParagraphSm>
                                </li>
                                <li>
                                  Manage corrections
                                  <WSText.ParagraphSm color="gray500">
                                    <ul>
                                      <li>
                                        After forms have been filed, contractors
                                        can request corrections to their Form
                                        1099-NEC if any errors are noticed. You
                                        can review the request, verify the
                                        corrections, and determine whether to
                                        accept the changes and submit to the IRS
                                        or reject the changes.
                                      </li>
                                    </ul>
                                  </WSText.ParagraphSm>
                                </li>
                              </ol>
                            </WSText.ParagraphSm>
                          </WSElement>
                        </WSPanel>
                      </WSPanel>
                    </WSGrid.Item>
                    <WSGrid.Item span={{ m: "4" }}>
                      <TaxFilingFAQ mt="S" />
                    </WSGrid.Item>
                  </WSGrid>
                </WSList>
              ) : (
                <EmptyStateOptOut onOptIn={optInCurrentYearFilling} />
              )}
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};

const EmptyStateOptOut: React.FC<{ onOptIn: () => void }> = props => {
  const currentYear = useCurrentTaxFilingYear();
  const currentYearDeadline = new Date(currentYear + 1, 1, 1);

  if (new Date() < currentYearDeadline) {
    return (
      <WSEmptyState
        type="tasks"
        orientation="horizontal"
        hasBorder
        fullWidth
        title={`You opted out of tax filing for ${currentYear}`}
        description={`You (or a team member) opted out of tax filing for the ${currentYear} tax year with Wingspan. You can opt-in to file with Wingspan for ${currentYear} before the IRS filing deadline of January 31, ${
          currentYear + 1
        }. After this date, no tax forms can be filed with Wingspan for the stated year if you opted out for that year.`}
        buttons={[
          {
            kind: "Secondary",
            label: "Opt-in to 2024 tax filing",
            onClick: props.onOptIn
          }
        ]}
      />
    );
  }

  return (
    <WSEmptyState
      type="tasks"
      orientation="horizontal"
      hasBorder
      fullWidth
      title={`You opted out of tax filing for ${currentYear}`}
      description={`The filing deadline for the year has passed. You (or a team member) opted out of tax filing for the ${currentYear} tax year with Wingspan. No tax forms can be filed with Wingspan for the stated year if you opted out for that year.`}
    />
  );
};
