import { WSAlert, WSMarginProps } from "@wingspanhq/fe-component-library";

interface Props extends WSMarginProps {
  year: number;
  onView: () => void;
}

export const AlertSubmissionInProgress: React.FC<Props> = ({
  year,
  onView,
  ...otherProps
}) => {
  return (
    <WSAlert
      theme="info"
      icon="info-circle"
      title={`${year} tax forms submission in progress`}
      action={{
        onClick: onView,
        text: "View progress"
      }}
      {...otherProps}
    >
      Form 1099-NECs is still being submitted to the IRS for filing. Depending
      on the volume of forms, this could take some time. Once the progress is
      complete, the forms will be updated below.
    </WSAlert>
  );
};
