import { useRouteMatch } from "react-router-dom";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { EditLayout } from "../../../components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import {
  WSButton,
  WSCard,
  WSEmptyState,
  WSInfoBox,
  WSList,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  useBulkCalculate1099Batch,
  useBulkCalculate1099BatchSummary
} from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import { formatNumber } from "accounting";
import React, { useEffect, useState } from "react";
import { queryCache } from "react-query";
import { useCurrentTaxFilingYear } from "../../../utils/useCurrentTaxFilingYear";
import { useGoToTaxFiling } from "../../../paths";
import { GenerateTaxFormsInfo } from "./GenerateTaxFormsInfo";
import { QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsSummaryQuery";
import { QUERY_TAX_FORM_SUBMISSION_ROWS } from "../../../../../query/search/taxForm/queries/useTaxFormSubmissionRowsQuery";

const POLL_TIME_IN_MS = 5000;

export const RouteGenerate1099NECAmountsProgress: React.FC = () => {
  const currentYear = useCurrentTaxFilingYear();
  const gotoDashboard = useGoToTaxFiling();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const [isBatchProcessed, setIsBatchProcessed] = useState(false);
  const queryCalculate1099BulkBatch = useBulkCalculate1099Batch(bulkBatchId, {
    refetchInterval: POLL_TIME_IN_MS,
    enabled: !isBatchProcessed
  });

  const queryBulkCalculate1099Summary = useBulkCalculate1099BatchSummary(
    bulkBatchId,
    {},
    { refetchInterval: POLL_TIME_IN_MS, enabled: !isBatchProcessed }
  );

  const status = queryCalculate1099BulkBatch.data?.status;

  useEffect(() => {
    const isBatchUploadFinished =
      status && [BulkStatus.Complete, BulkStatus.Failed].includes(status);
    if (isBatchUploadFinished) {
      setIsBatchProcessed(true);
      // refetch tax forms to refresh amounts & statuses on 1099 dashboard
      queryCache.invalidateQueries([QUERY_TAX_FORM_SUBMISSION_ROWS]);
      queryCache.invalidateQueries([QUERY_TAX_FORM_SUBMISSION_ROWS_SUMMARY]);
    }
  }, [status]);

  return (
    <EditLayout title="Generate amounts and statuses">
      <WSQueries
        queries={{
          queryCalculate1099BulkBatch,
          queryBulkCalculate1099Summary
        }}
      >
        {({
          queryCalculate1099BulkBatchData,
          queryBulkCalculate1099SummaryData
        }) => {
          const total = queryBulkCalculate1099SummaryData.totalTaxForms;
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;
          const importPercent = total ? (processed / total) * 100 : 0;
          const formattedTotalItems = total ? formatNumber(total) : "";

          const statusProgressMessageMap = {
            [BulkStatus.Open]: "Fetching all tax forms..",
            [BulkStatus.Pending]: "Preparing data..",
            [BulkStatus.Processing]: `Generating 1099 amounts & statuses for ${formattedTotalItems} tax forms`,
            [BulkStatus.Complete]: `Successfully generated amounts & statuses`,
            [BulkStatus.Failed]:
              "There was an error while generating some forms"
          };

          let progressText =
            statusProgressMessageMap[queryCalculate1099BulkBatchData.status];
          const isGenerationCompleted = [
            BulkStatus.Complete,
            BulkStatus.Failed
          ].includes(queryCalculate1099BulkBatchData.status);

          return (
            <WSList gap="XL">
              <WSCard
                header="Generate Form 1099-NECs amounts and statuses"
                divider
              >
                <WSList gap="XL">
                  {isGenerationCompleted ? (
                    <WSEmptyState
                      fullWidth
                      orientation="horizontal"
                      type="done"
                      title="Form 1099-NECs amounts and statuses successfully generated"
                      description={`1099-NECs amounts and statuses for ${formattedTotalItems} contractor 1099 forms for ${currentYear} were generated.`}
                    />
                  ) : (
                    <WSProgressBar
                      percent={importPercent}
                      text={progressText}
                      noAnimation={isBatchProcessed}
                    />
                  )}
                  {!isGenerationCompleted ? (
                    <>
                      <WSText.ParagraphSm weight="book" color="gray600">
                        We are generating {formattedTotalItems ?? "-"} Form
                        1099-NECs amounts and status for contractors for{" "}
                        {currentYear}. Depending on the volume of forms, this
                        could take some time.
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm weight="book" color="gray400" inline>
                        If you close this window while tax form generation is in
                        progress, it will appear on your filing dashboard until
                        the process is complete.{" "}
                        <WSButton.Link onClick={gotoDashboard}>
                          Minimize progress and return to filing dashboard.
                        </WSButton.Link>
                      </WSText.ParagraphSm>
                    </>
                  ) : null}
                </WSList>
              </WSCard>
              <WSButton
                onClick={gotoDashboard}
                fullWidth
                name="goto1099Dashboard"
                disabled={
                  ![BulkStatus.Complete, BulkStatus.Failed].includes(
                    queryCalculate1099BulkBatchData.status
                  )
                }
              >
                Go to dashboard
              </WSButton>
              <WSCard>
                <GenerateTaxFormsInfo />
              </WSCard>
            </WSList>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
