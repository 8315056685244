import {
  toWSMoney,
  toWSMoneyString,
  WSButton,
  WSButtonProps,
  WSCard,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSList,
  WSLoader,
  WSMarginProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { usePayoutQuery } from "../../../../query/payouts/queries/usePayoutQuery";
import { IPayoutResponse } from "../../../../services/api/payments/payouts/types";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { usePayoutInvoicesDetails } from "../../queries/usePayoutInvoicesDetails";
import { makeNegative } from "../../utils/makeNegative";
import { getPayoutInvoiceIds, getTotals } from "../../utils/utils";

export interface PayoutSummaryPanelProps extends WSMarginProps {
  payout: IPayoutResponse;
}

export const PayoutSummaryPanel: React.FC<PayoutSummaryPanelProps> = ({
  payout
}) => {
  const history = useHistory();
  const invoiceIds = useMemo(() => getPayoutInvoiceIds(payout), [payout]);

  const queryPayoutInvoicesDetails = usePayoutInvoicesDetails(invoiceIds);
  const queryPreviousPayout = usePayoutQuery(
    payout.previousSettlementId as string,
    {
      enabled: !!payout.previousSettlementId
    }
  );

  const previousPayout = queryPreviousPayout.data;

  const totals = useMemo(
    () => getTotals(payout, previousPayout),
    [payout, previousPayout]
  );

  if (queryPreviousPayout.isLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <WSCard
      divider
      header={{
        label: "Payout summary",
        value: {
          pill: queryPayoutInvoicesDetails.isLoading
            ? {
                text: "Loading",
                icon: "loader"
              }
            : undefined
        }
      }}
    >
      <WSList gap="2XL">
        {previousPayout && totals.previousFutureDebitItemsTotal !== 0 && (
          <WSElement>
            <Row
              label="Previously Held Items"
              value={toWSMoneyString(
                Math.abs(totals.previousFutureDebitItemsTotal)
              )}
              button={{
                label: "View",
                onClick: () => {
                  openInNewTab(
                    "/member/invoices/payouts/" + previousPayout.payoutId
                  );
                }
              }}
            />
          </WSElement>
        )}

        {totals.payoutItems.length > 0 && (
          <WSList>
            <Row accented label="Payout items" />

            <WSList gap="XS">
              {totals.payoutItems.map(item => {
                const invoice = queryPayoutInvoicesDetails.data?.find(
                  invoice => invoice.invoiceId === item.sourceId
                );

                return (
                  <Row
                    key={item.sourceId}
                    label={item.description || ""}
                    value={toWSMoneyString(item.amount)}
                    button={
                      invoice
                        ? {
                            label: "View #" + invoice.invoiceNumber,
                            onClick: () => {
                              openInNewTab(
                                "/member/invoices/" + invoice.invoiceId
                              );
                            }
                          }
                        : undefined
                    }
                  />
                );
              })}
            </WSList>
          </WSList>
        )}

        {totals.clearingItems.length > 0 && (
          <WSList>
            <Row accented label="Clearing items" />

            <WSList gap="XS">
              {totals.clearingItems.map(item => {
                const invoice = queryPayoutInvoicesDetails.data?.find(
                  invoice => invoice.invoiceId === item.sourceId
                );

                return (
                  <Row
                    key={item.sourceId}
                    label={item.description || ""}
                    value={toWSMoneyString(item.amount)}
                    button={
                      invoice
                        ? {
                            label: "View #" + invoice.invoiceNumber,
                            onClick: () => {
                              history.push(
                                "/member/invoices/" + invoice.invoiceId
                              );
                            }
                          }
                        : undefined
                    }
                  />
                );
              })}
            </WSList>
          </WSList>
        )}

        {totals.futureDebitItems.length > 0 && (
          <WSList>
            <Row accented label="Future debits" />

            <WSList gap="XS">
              {totals.futureDebitItems.map(item => {
                const invoice = queryPayoutInvoicesDetails.data?.find(
                  invoice => invoice.invoiceId === item.sourceId
                );

                return (
                  <Row
                    key={item.sourceId}
                    label={item.description || ""}
                    value={toWSMoneyString(makeNegative(item.amount))}
                    button={
                      invoice
                        ? {
                            label: "View #" + invoice.invoiceNumber,
                            onClick: () => {
                              history.push(
                                "/member/invoices/" + invoice.invoiceId
                              );
                            }
                          }
                        : undefined
                    }
                  />
                );
              })}
            </WSList>
          </WSList>
        )}

        {totals.clearingItems.length === 0 &&
          totals.futureDebitItems.length === 0 &&
          !!payout.futureDebits && (
            <Row
              label="Future debits"
              value={toWSMoneyString(makeNegative(payout.futureDebits))}
            />
          )}

        {totals.otherItemsTotal !== 0 && (
          <Row
            label="Other items"
            value={toWSMoneyString(totals.otherItemsTotal)}
          />
        )}
      </WSList>

      <WSDivider my="L" />

      <Row
        accented
        label="Net payout total"
        value={toWSMoney(totals.netTotal).formattedValue}
      />
    </WSCard>
  );
};

type RowProps = {
  label: string;
  value?: string;
  accented?: boolean;
  button?: WSButtonProps<"Link">;
};

const Row: React.FC<RowProps> = props => (
  <WSFlexBox gap="2XL" justify="space-between">
    <WSText.ParagraphSm
      weight={props.accented ? "medium" : undefined}
      color="gray500"
    >
      {props.label}

      {props.button && (
        <WSButton.Link rightIcon="open-tab" size="S" {...props.button} />
      )}
    </WSText.ParagraphSm>
    {props.value && (
      <WSText.ParagraphSm
        weight={props.accented ? "medium" : undefined}
        color="gray700"
      >
        {props.value}
      </WSText.ParagraphSm>
    )}
  </WSFlexBox>
);
