import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { usePayeeQuery } from "../../../../query/payee/queries/usePayeeQuery";
import {
  FlowSetup,
  SetupParams
} from "../../../../shared/components/FlowSetup";
import { Layout } from "../../../../shared/components/Layout";
import { useGoTo } from "../../../../shared/utils/useGoTo";
import { buildPayeeDetailsPath } from "../../utils";
import { EditPayeeTaxInfoMismatch } from "./EditPayeeTaxInfoMismatch";
import { EditPayeeTaxInfoVerification } from "./EditPayeeTaxInfoVerification";
import { EditPayeeTaxInfoVerificationPending } from "./EditPayeeTaxInfoVerificationPending";
import { EditPayeeTaxInfoVerificationSuccess } from "./EditPayeeTaxInfoVerificationSuccess";
import { FormEditPayeeInformation } from "./FormEditPayeeTaxInfo";

type RouteEditPayeeInformationProps = RouteComponentProps<{
  payeeId: string;
}>;

export interface EditPayeeSetupParams extends SetupParams {
  payee?: IPayeeResponse;
}

const EDIT_TAX_INFO_PATH = "";
const EDIT_TAX_INFO_PROCESSING_PATH = EDIT_TAX_INFO_PATH + "/processing";
const EDIT_TAX_INFO_PENDING_PATH = EDIT_TAX_INFO_PATH + "/pending";
const EDIT_TAX_INFO_COMPLETE_PATH = EDIT_TAX_INFO_PATH + "/complete";
const EDIT_TAX_INFO_MISMATCH_PATH = EDIT_TAX_INFO_PATH + "/mismatch";

export const RouteEditPayeeInformation: React.FC<
  RouteEditPayeeInformationProps
> = ({ match }) => {
  const goTo = useGoTo();
  const { payeeId } = match.params;

  const queryPayee = usePayeeQuery(payeeId);
  const basePath = match.url;
  const onBack = () => goTo(buildPayeeDetailsPath(payeeId));

  return (
    <Layout title="Edit Contractor Information">
      <WSQueries queries={{ queryPayee }}>
        {({ queryPayeeData }) => {
          return (
            <FlowSetup
              initialPath={EDIT_TAX_INFO_PATH}
              basePath={basePath}
              params={{ payee: queryPayeeData }}
              routes={[
                {
                  path: EDIT_TAX_INFO_PATH,
                  component: FormEditPayeeInformation,
                  exact: true,
                  onNext: context => {
                    if (context?.status === "TaxInfo") {
                      goTo(basePath + EDIT_TAX_INFO_PROCESSING_PATH);
                    } else {
                      onBack();
                    }
                  },
                  onBack
                },
                {
                  path: EDIT_TAX_INFO_MISMATCH_PATH,
                  component: EditPayeeTaxInfoMismatch,
                  onNext: onBack,
                  onBack: () => {
                    goTo(basePath);
                  }
                },
                {
                  path: EDIT_TAX_INFO_PROCESSING_PATH,
                  component: EditPayeeTaxInfoVerification,
                  onNext: context => {
                    if (context?.status === "Verified") {
                      goTo(basePath + EDIT_TAX_INFO_COMPLETE_PATH);
                    } else if (context?.status === "Failed") {
                      goTo(basePath + EDIT_TAX_INFO_MISMATCH_PATH);
                    } else {
                      goTo(basePath + EDIT_TAX_INFO_PENDING_PATH);
                    }
                  }
                },
                {
                  path: EDIT_TAX_INFO_COMPLETE_PATH,
                  component: EditPayeeTaxInfoVerificationSuccess,
                  onNext: onBack
                },
                {
                  path: EDIT_TAX_INFO_PENDING_PATH,
                  component: EditPayeeTaxInfoVerificationPending,
                  onNext: onBack
                }
              ]}
            />
          );
        }}
      </WSQueries>
    </Layout>
  );
};
