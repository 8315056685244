import React, { ReactNode } from "react";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { Layout, LayoutProps } from "../../../../shared/components/Layout";

interface EditLayoutProps extends LayoutProps {
  children: ReactNode;
  onBack?: () => void;
  title: string;
}

export const EditLayout: React.FC<EditLayoutProps> = ({
  title,
  children,
  onBack,
  ...props
}) => {
  useBrowserPageTitle(title);

  const goBackOrToParent = useGoBackOrToParent();
  const goBack = onBack || goBackOrToParent;

  return (
    <Layout title={title} onClose={goBack} {...props}>
      {children}
    </Layout>
  );
};
