import React from "react";
import {
  WSButton,
  WSElement,
  WSElementProps,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeTaxFormResponse,
  TaxFormDeliveryMethod,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { Field } from "../components/Field";
import { selectorPayeeTaxFormPayerName } from "../../../TaxCorrections/selectors/selectorPayeeTaxFormPayerName";
import { selectorPayeeTaxFormPayerDefaultTinType } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormPayerDefaultTinType";
import { formatEIN } from "../../../../shared/utils/formatEIN";
import { AddressText } from "../../../../shared/components/AddressText/AddressText";
import { formatPhone } from "../../../../shared/utils/formatPhone";
import { selectorTaxFormRecipientName } from "../../../1099NECFiling/selectors/selectorTaxFormRecipientName";
import { selectorTaxClassificationLabel } from "../../../../shared/selectors/selectorTaxClassificationLabel";
import { selectorPayeeTaxFormPayeeDefaultTinType } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormPayeeDefaultTinType";
import { selectorPayeeTaxFormSupportContacts } from "../../../TaxDocuments/selectors/selectorPayeeTaxFormSupportContacts";

type Props = { taxForm: IPayeeTaxFormResponse } & WSElementProps;

export const Summary: React.FC<Props> = ({ taxForm, ...elementProps }) => {
  const { supportEmail, supportPhone } =
    selectorPayeeTaxFormSupportContacts(taxForm);

  return (
    <WSElement {...elementProps}>
      <WSText weight="medium" mb="M">
        Payer’s contact information
      </WSText>
      <WSPanel mb="2XL">
        <Field label="Name" mb="XL">
          <WSText color="gray400">
            {selectorPayeeTaxFormPayerName(taxForm)}
          </WSText>
        </Field>

        <Field label="Tax Identification Number" mb="XL">
          <WSText.ParagraphSm color="gray400">
            {selectorPayeeTaxFormPayerDefaultTinType(taxForm) ===
            TinType.Business
              ? taxForm.payer?.profile?.company?.taxId
                ? `${formatEIN(
                    taxForm.payer?.profile?.company?.taxId
                  )} (Employer Identification Number)`
                : "Employer Identification Number (EIN)"
              : selectorPayeeTaxFormPayerDefaultTinType(taxForm) ===
                TinType.Individual
              ? `Social Security Number (SSN)`
              : `-`}
          </WSText.ParagraphSm>
        </Field>

        <Field label="Address" mb="XL">
          <WSText.ParagraphSm color="gray400" mb="M" as="pre">
            <AddressText {...taxForm.payer?.profile?.address} />
          </WSText.ParagraphSm>
        </Field>

        <Field label="Support email" mb="XL">
          <WSButton.Link
            onClick={() => openInNewTab(`mailto:${supportEmail}` as string)}
            type="button"
          >
            {supportEmail}
          </WSButton.Link>
        </Field>

        <Field label="Support phone">
          <WSText color="gray400">
            {supportPhone ? formatPhone(supportPhone) : "-"}
          </WSText>
        </Field>
      </WSPanel>

      <WSText weight="medium" mb="M">
        Recipient details
      </WSText>
      <WSPanel>
        <Field label="Name" mb="XL">
          <WSText color="gray400">
            {selectorTaxFormRecipientName(taxForm)}
          </WSText>
        </Field>

        <Field label="Email" mb="XL">
          <WSText color="gray400">{taxForm.payee?.user.email}</WSText>
        </Field>

        <Field label="Federal Tax Classification" mb="XL">
          <WSText color="gray400">
            {selectorTaxClassificationLabel(
              taxForm.data?.w9Info?.companyStructure ||
                taxForm.payee?.profile?.company?.structure
            )}
          </WSText>
        </Field>

        <Field label="Taxpayer Identification Number" mb="XL">
          <WSText color="gray400">
            {selectorPayeeTaxFormPayeeDefaultTinType(taxForm) ===
            TinType.Business
              ? "Employer Identification Number (EIN)"
              : selectorPayeeTaxFormPayeeDefaultTinType(taxForm) ===
                TinType.Individual
              ? "Social Security Number (SSN)"
              : `-`}
          </WSText>
        </Field>

        <Field label="Address" mb="XL">
          <WSText color="gray400">
            <AddressText
              {...(taxForm.data?.w9Info || taxForm.payee?.profile?.address)}
            />
          </WSText>
          {taxForm.data?.deliveryMethod ===
          TaxFormDeliveryMethod.Electronic ? null : (
            <WSMessageBox.Info
              mt="M"
              icon="envelope"
              title="Your 1099 will be delivered to the address listed above. You will also receive an electronic copy."
            />
          )}
        </Field>

        <Field label="Delivery preferences">
          <WSText color="gray500">
            {taxForm.data?.deliveryMethod === TaxFormDeliveryMethod.Electronic
              ? "Electronic delivery"
              : "Mail delivery"}
          </WSText>
        </Field>
      </WSPanel>
    </WSElement>
  );
};
