import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { useRouteMatch } from "react-router-dom";

import { SetupNewCorrectionProps } from "./index";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import { SelectCorrectionTypeForm } from "../../../TaxCorrections/components/SelectCorrectionTypeForm";
import { selectorTaxFormRecipientName } from "../../../1099NECFiling/selectors/selectorTaxFormRecipientName";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { useCurrentTaxFilingYear } from "../../utils/useCurrentTaxFilingYear";
import { CorrectionFlowHeader } from "../../../TaxCorrections/components/CorrectionFlowHeader";
import React from "react";
import { openIntercom } from "../../../../shared/utils/intercom";
import { useOnCancelNewCorrection } from "./useOnCancelNewCorrection";

interface SelectCorrectionTypeFormData {
  corrections: string[];
}

export const RouteSelectCorrectionType: React.FC<SetupNewCorrectionProps> = ({
  onNext,
  onBack
}) => {
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId, submissionId } = match.params;

  const currentYear = useCurrentTaxFilingYear();

  const queryTaxForm = usePayerTaxForm(taxFormId);

  const onFormSubmit = (data: SelectCorrectionTypeFormData) => {
    onNext(data.corrections);
  };

  const onCancel = useOnCancelNewCorrection(true);

  return (
    <EditLayout title="Start new correction" noBack onClose={onCancel}>
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => (
          <>
            <CorrectionFlowHeader
              year={currentYear}
              name={selectorTaxFormRecipientName(taxForm)}
            />

            <SelectCorrectionTypeForm
              taxForm={taxForm}
              taxFormViewer={TaxFormViewer.Payer}
              onCancel={onCancel}
              onContinue={onFormSubmit}
            />

            <WSText.ParagraphSm color="gray500">
              Have another issue with the 1099?
              <WSButton.Link
                size="S"
                rightIcon="chevron-right"
                onClick={openIntercom}
              >
                Contact support
              </WSButton.Link>
            </WSText.ParagraphSm>
          </>
        )}
      </WSQueries>
    </EditLayout>
  );
};
