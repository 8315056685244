import * as Yup from "yup";
import { FormPartialPersonType } from ".";
import { validatorInternationalTaxId } from "../../../../shared/validators/internationalTaxIdValidator";
import { validatorDob } from "../../../../shared/validators/validatorDob";
import { validatorName } from "../../../../shared/validators/validatorName";
import { validatorStringTextNumbersSymbols } from "../../../../shared/validators/validatorStringTextNumbersSymbols";
import { getValidationSchemaAddress } from "../FormPartialAddress";
import { validationSchemaPhoneNumber } from "../FormPartialPhoneNumber";

export const getValidationSchema = (
  type: FormPartialPersonType,
  options?: {
    showOwnershipStake?: boolean;
    requireTaxId?: boolean;
    meCountry?: string;
  }
) =>
  Yup.object().shape({
    firstName: validatorName.required("Required"),
    middleName: validatorName,
    lastName: validatorName.required("Required"),
    jobTitle: validatorStringTextNumbersSymbols.required("Required"),
    ownershipStake: options?.showOwnershipStake
      ? type === "BeneficialOwner"
        ? Yup.number()
            .nullable()
            .required("Required")
            .min(
              25,
              "This person is not a beneficial owner, please remove them as their information is not required"
            )
        : Yup.number().nullable().required("Required")
      : Yup.mixed(),
    representativeConfirmation: options?.showOwnershipStake
      ? Yup.boolean().when("ownershipStake", {
          is: (stake: number | null) => !stake || stake < 25,
          then: Yup.boolean().isTrue("Required")
        })
      : Yup.mixed(),
    birthday: validatorDob.required("Required"),
    email:
      type === "BeneficialOwner"
        ? Yup.string().email("Invalid email").required("Required")
        : Yup.mixed(),
    phone:
      type === "BeneficialOwner" ? validationSchemaPhoneNumber : Yup.mixed(),
    country: Yup.string().nullable().required("Required"),
    taxId: options?.requireTaxId
      ? validatorInternationalTaxId(
          "taxId",
          "Individual",
          options?.meCountry
        ).required("Required")
      : validatorInternationalTaxId(
          "taxId",
          "Individual",
          // Provide ME country only for individuals. For representative and BOs we should use in-form value
          type === "Individual" ? options?.meCountry : undefined
        ),

    address: Yup.lazy((_, context) => {
      const country = context?.parent?.country;
      return getValidationSchemaAddress(country);
    })
  });
