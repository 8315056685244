import { WSTableCell } from "@wingspanhq/fe-component-library";
import { TaxFormSubmissionRow } from "../../../services/search";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { selectorMemberName } from "../../../shared/selectors/selectorMemberName";
import React from "react";

export const OrganizationCell: React.FC<{
  taxFormSubmission: TaxFormSubmissionRow;
}> = ({ taxFormSubmission }) => {
  let name = "-";

  if (taxFormSubmission.payer) {
    const payer = taxFormSubmission.payer;
    const names = wsName({
      member: payer.member,
      user: payer.user
    });

    name =
      names.getResolvedName() || selectorMemberName(taxFormSubmission.payer);
  }

  return <WSTableCell text={name} />;
};
