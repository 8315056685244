import { WSElementProps } from "@wingspanhq/fe-component-library";
import React from "react";
import { mapPayablesTableToCSV } from "../../../Invoices/screens/payables/csvUtils";
import { PayablesFilter, paymentsService } from "../../../services/payments";
import { IPayeeRow } from "../../../services/search";
import { ButtonDownloadCsv } from "../../../shared/components/ButtonDownloadCsv";
import { getAllEntries } from "../../../utils/serviceHelper";

type Props = {
  payeeRows: IPayeeRow[];
  filters: PayablesFilter;
  fileName: string;
} & WSElementProps;

export const DownloadCsv: React.FC<Props> = ({
  payeeRows,
  filters,
  fileName,
  ...elementProps
}) => {
  return (
    <ButtonDownloadCsv
      getData={async () => {
        const payables = await getAllEntries(
          async (...args) =>
            (
              await paymentsService.payable.list(...(args as any))
            ).data,
          filters
        );

        return mapPayablesTableToCSV(
          payables.map(payable => ({
            payable,
            payeeRow: payeeRows.find(row => row.payeeId === payable.memberId)
          }))
        );
      }}
      fileName={fileName}
      {...elementProps}
    />
  );
};
