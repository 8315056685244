import {
  WSGrid,
  WSList,
  WSPage,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import { TaxFilingFAQ } from "../../components/TaxFilingFAQ/TaxFilingFAQ";
import React from "react";
import { useHistory } from "react-router-dom";
import { BusinessInformation } from "./BusinessInformation";
import { SupportInformation } from "./SupportInformation";
import { CalculationInformation } from "./CalculationInformation";
import { StateFilingInformation } from "./StateFilingInformation";
import { TAX_FILING_1099_FILING_PREVIEW_PATH } from "../../paths";
import { useCurrentTaxFilingYear } from "../../utils/useCurrentTaxFilingYear";

export type RouteTaxFilingSettingsProps = {};

export const RouteTaxFilingSettings: React.FC<
  RouteTaxFilingSettingsProps
> = ({}) => {
  const currentYear = useCurrentTaxFilingYear();
  const history = useHistory();

  return (
    <WSPage>
      <WSPageToolbar
        breadcrumbs={[
          {
            label: `1099-NEC filing ${currentYear}`,
            onClick: () => {
              history.push(TAX_FILING_1099_FILING_PREVIEW_PATH);
            }
          },
          {
            label: `${currentYear} Tax filing settings`
          }
        ]}
        title={`${currentYear} tax filing settings`}
      />
      <WSGrid>
        <WSGrid.Item span={{ m: "8" }}>
          <WSList gap="2XL">
            <BusinessInformation />
            <SupportInformation />
            <CalculationInformation />
            <StateFilingInformation />
          </WSList>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "4" }}>
          <TaxFilingFAQ mt="S" />
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
