import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";
import React, { useCallback, useEffect } from "react";
import { SetupParams } from ".";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { RecipientVerificationProgress } from "../../components/RecipientVerificationProgress";
import { selectorPayerTaxFormDefaultStatus } from "../../selectors/selectorPayerTaxFormDefaultStatus";

export const RouteMatchingTin: React.FC<
  SetupRouteComponentProps<SetupParams>
> = ({ onNext, onBack, params }) => {
  const taxFormId = params?.taxForm?.taxFormId || "";
  const queryPayerTaxForm = usePayerTaxForm(taxFormId);

  useEffect(() => {
    const interval = setInterval(async () => {
      const taxForm = await queryPayerTaxForm.refetch();
      if (taxForm) {
        const verificationStatus = selectorPayerTaxFormDefaultStatus(taxForm);

        if (verificationStatus === VerificationStatus.Verified) {
          onNext({ status: VerificationStatus.Verified, taxForm });
        } else if (verificationStatus === VerificationStatus.Failed) {
          onNext({ status: VerificationStatus.Failed, taxForm });
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [onNext, queryPayerTaxForm]);

  const onComplete = useCallback(() => {
    onNext({
      status: VerificationStatus.Pending,
      taxForm: queryPayerTaxForm.data
    });
  }, []);

  return (
    <EditLayout title="Add recipient" onBack={onBack}>
      <RecipientVerificationProgress ms={20000} onComplete={onComplete} />
    </EditLayout>
  );
};
