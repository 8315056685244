import {
  WSActions,
  WSDivider,
  WSElement,
  WSForm
} from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";

import { SetupNewCorrectionProps } from "./index";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";
import { EditLayout } from "../../../TaxCorrections/components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorTaxFormRecipientName } from "../../../1099NECFiling/selectors/selectorTaxFormRecipientName";
import { AmountCorrectionForm } from "../../../TaxCorrections/components/AmountCorrectionForm";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxCorrections/constants/corrections";
import { TINOrNameCorrectionForm } from "../../../TaxCorrections/components/TINOrNameCorrectionForm";
import { AddressCorrectionForm } from "../../../TaxCorrections/components/AddressCorrectionForm";
import {
  getDefaultValuesForCorrectionsForm,
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxCorrections/utils/getDefaultValuesForCorrectionsForm";
import { getCorrectionType } from "../../../TaxCorrections/utils/getCorrectionType";
import { TaxFormViewer } from "../../../TaxDocuments/types";
import { getFormValidationSchema } from "../../../TaxCorrections/utils/getFormValidationSchema";
import uniq from "lodash/uniq";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { usePayerForceSubmitTaxFormWarningModal } from "../../components/usePayerForceSubmitTaxFormWarningModal";
import { usePayerVoidOriginalTaxFormWarningModal } from "../../components/usePayerVoidOriginalTaxFormWarningModal";
import { CorrectionFlowHeader } from "../../../TaxCorrections/components/CorrectionFlowHeader";
import { useCurrentTaxFilingYear } from "../../utils/useCurrentTaxFilingYear";
import { FormRequestCorrectionData } from "../../../TaxCorrections/types";
import { useOnCancelNewCorrection } from "./useOnCancelNewCorrection";

export const RouteAddCorrectionValues: React.FC<SetupNewCorrectionProps> = ({
  onNext,
  onBack
}) => {
  const history = useHistory<{
    isEdit: boolean;
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string; submissionId: string }>();
  const { taxFormId } = match.params;
  const currentYear = useCurrentTaxFilingYear();
  const queryTaxForm = usePayerTaxForm(taxFormId);

  const isEdit = history.location.state?.isEdit;
  const corrections = uniq(history.location.state?.corrections ?? []);
  const correctionsFormData = history.location.state?.correctionsFormData;

  const onCancel = useOnCancelNewCorrection();

  const payerVoidOriginalTaxFormWarningModal =
    usePayerVoidOriginalTaxFormWarningModal();
  const payerForceSubmitTaxFormWarningModal =
    usePayerForceSubmitTaxFormWarningModal();

  const onFormSubmit = (
    data: FormRequestCorrectionData,
    options = { isForce: 0 }
  ) => {
    onNext({
      corrections,
      correctionsFormData: data,
      isForce: options.isForce
    });
  };

  return (
    <EditLayout title="Start new correction" noBack onClose={onCancel}>
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const recipientName = selectorTaxFormRecipientName(taxForm);
          const originalTinData = getPayeeTINorName(taxForm);
          const correctionTypeToFormComponentMap: Record<string, JSX.Element> =
            {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionForm
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  amount={taxForm.data?.totalAmount || 0}
                  recipientName={recipientName}
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionForm
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  originalTinData={originalTinData}
                  identificationNumberType={
                    originalTinData?.ein ? TinType.Business : TinType.Individual
                  }
                  showRequestedValue={false}
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionForm
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                />
              )
            };
          return (
            <>
              <CorrectionFlowHeader year={currentYear} name={recipientName} />

              <WSForm<FormRequestCorrectionData>
                defaultValues={
                  isEdit
                    ? correctionsFormData
                    : getDefaultValuesForCorrectionsForm(corrections, taxForm)
                }
                validationSchema={getFormValidationSchema(
                  corrections,
                  taxForm.data.w9Info?.country || "US",
                  false,
                  TaxFormViewer.Payer
                )}
                onSubmit={formData => {
                  const {
                    corrections,
                    isVoidType,
                    isCompanyStructureChangeOnly
                  } = getCorrectionType(taxForm, formData);

                  const formDataWithCountry: FormRequestCorrectionData = {
                    ...formData,
                    ...(formData.formW9Address
                      ? {
                          formW9Address: {
                            ...formData.formW9Address,
                            country: taxForm.data.w9Info?.country || "US"
                          }
                        }
                      : {})
                  };

                  if (!!isVoidType) {
                    payerVoidOriginalTaxFormWarningModal.open({
                      onContinue: () =>
                        onFormSubmit(formDataWithCountry, { isForce: 0 })
                    });
                  } else if (!!isVoidType && !isCompanyStructureChangeOnly) {
                    payerForceSubmitTaxFormWarningModal.open({
                      onContinue: () =>
                        onFormSubmit(formDataWithCountry, { isForce: 1 })
                    });
                  } else {
                    onFormSubmit(formDataWithCountry);
                  }
                }}
              >
                {corrections.map((correctionType, index) => {
                  const FormComponent =
                    correctionTypeToFormComponentMap[correctionType];
                  const isLast = index === corrections.length - 1;
                  return (
                    <WSElement mb="L" key={correctionType}>
                      {FormComponent}
                      {isLast ? null : <WSDivider my="XL" />}
                    </WSElement>
                  );
                })}
                <WSActions
                  alignment="fill"
                  buttons={[
                    {
                      kind: "Primary",
                      label: "Continue",
                      type: "submit"
                    },
                    {
                      kind: "Tertiary",
                      label: "Cancel",
                      type: "button",
                      onClick: onCancel
                    }
                  ]}
                />
              </WSForm>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
