import React from "react";
import {
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";

export interface PayoutsBetaModalProps extends WSElementProps {
  onClose: () => void;
}

export const PayoutsBetaModal: React.FC<PayoutsBetaModalProps> = ({
  onClose,
  ...elementProps
}) => {
  return (
    <WSElement {...elementProps}>
      <WSDivider mb="XL" />
      <WSText.ParagraphSm color="gray500" mb="L">
        The payouts feature provides visibility to batched payouts which
        aggregate payments paid out to you on a daily basis reducing the number
        of transactions to keep track of.
      </WSText.ParagraphSm>
      <WSElement mb="XL" colorBackground="gray50" p="M">
        <WSText.ParagraphSm color="gray500" mb="L">
          <b>Batched payouts:</b> payouts are sent in batches and details can be
          viewed from Nov. 1, 2024 onward.
        </WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500">
          <b>Instant payouts:</b> currently, instant payouts are not part of
          batched payouts. Instant payouts can be tracked on the invoicing page.
        </WSText.ParagraphSm>
      </WSElement>
      <WSDivider mb="L" />
      <WSFlexBox.CenterY justify="flex-end" wrap="nowrap">
        <WSButton.Primary
          size="M"
          fullWidth={true}
          name="Done"
          onClick={onClose}
        >
          Done
        </WSButton.Primary>
      </WSFlexBox.CenterY>
    </WSElement>
  );
};
